import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withProps } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import { showError } from '../../api/error';
import TasksMenu from './index';
import { ROUTES } from '../../utils/routes';

const GET_OBJECT_USERS_COUNT_QUERY = loader('../../graphql/queries/object/getObjectUsersCount.graphql');
const CLOSE_TASK_MUTATION = loader('../../graphql/queries/task/closeTask.graphql');
const DELETE_TASK_QUERY = loader('../../graphql/queries/task/delete.graphql');
const READ_TASK_MESSAGE_MUTATION = loader('../../graphql/queries/task/readTaskMessage.graphql');

const withCloseTaskMutation = graphql(CLOSE_TASK_MUTATION, {
  name: 'closeTask',
});

const withDeleteTask = graphql(DELETE_TASK_QUERY, { name: 'deleteTask' });

const withReadTaskMessage = graphql(READ_TASK_MESSAGE_MUTATION, { name: 'readTaskMessage' });

const withGetObjectUsersCountQuery = graphql(GET_OBJECT_USERS_COUNT_QUERY, {
  name: 'getObjectUsersCount',
  skip: ({ location: { pathname } }) => !matchPath(pathname, ROUTES.objectTasks),
  options: ({ location: { pathname } }) => ({
    variables: {
      objectId: matchPath(pathname, ROUTES.objectTasks).params.id,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getObjectUsersCount }) => {
    const {
      objectUsersCount,
      error,
      loading: isLoading,
    } = getObjectUsersCount;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      hasUsers: objectUsersCount?.aggregate.count > 0,
      isLoading,
    };
  },
});

const mergeProps = withProps(({ closeTask, readTaskMessage }) => ({
  onCloseTask: id => closeTask({ variables: { id } })
    .then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  onReadTaskMessage: id => readTaskMessage({ variables: { id } })
    .then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
}));

export default compose(
  withRouter,
  withCloseTaskMutation,
  withDeleteTask,
  withReadTaskMessage,
  mergeProps,
  withGetObjectUsersCountQuery,
)(TasksMenu);
