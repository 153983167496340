import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ListHeader from '../../../base/listHeader';

export default class FavoritesListHeader extends PureComponent {
  render() {
    const { onSearch, setOrder, orderBy } = this.props;

    return <ListHeader
      onSearch={onSearch}
      setOrder={setOrder}
      orderBy={orderBy}
    />;
  }

  static propTypes = {
    setOrder: PropTypes.func,
    onSearch: PropTypes.func,
    orderBy: PropTypes.object.isRequired,
  };

  static defaultProps = {
    setOrder() {},
    onSearch() {},
  };
}
