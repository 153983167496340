import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ObjectFiles from '../../objects/object/objectManagement/objectFiles/container';
import ApplicationTerms from '../../contract/profile/applicationTerms/container';
import { getProjectComponentStoragePath } from '../../../utils/storagePaths';
import ProjectComponentFiles from '../../objects/object/stageManagement/projectComponentList/files/container';
import { STAGE_FILE_TYPES } from '../../../utils/stage';
import { OBJECT_FILE_TYPES } from '../../../utils/object';

import './index.scss';

export default class VacancyProfile extends React.PureComponent {
  renderFiles = isTenders => {
    const {
      userId,
      vacancy: {
        object: {
          id: objectId,
          userId: objectUserId,
          projectComponents,
          files,
          isTariffActive,
        },
        isCompetitionStarted,
      },
    } = this.props;

    return isTenders
      ? <div className={classNames('object-files-container', { 'vacancy-competition': isCompetitionStarted })}>
        {(projectComponents || []).map(({ objectProjectComponentId, name, files }, index) => <ProjectComponentFiles
          {...(index === 0 && { title: 'Исходные данные по разделу' })}
          key={index}
          subTitle={name}
          files={files[STAGE_FILE_TYPES.initialData.type]}
          entityId={objectProjectComponentId}
          type={STAGE_FILE_TYPES.initialData.type}
          storagePath={getProjectComponentStoragePath(objectProjectComponentId, objectId)}
          showAddButton={isTariffActive && !isCompetitionStarted && userId === objectUserId}
          showDeleteButton={!isCompetitionStarted}
        />)}
      </div>
      : <ObjectFiles
        {...OBJECT_FILE_TYPES.technicalTask}
        {...(isCompetitionStarted && { className: 'vacancy-competition' })}
        objectId={objectId}
        files={files[OBJECT_FILE_TYPES.technicalTask.type]}
        showAddButton={!isCompetitionStarted && userId === objectUserId}
        showDeleteButton={!isCompetitionStarted}
      />;
  };

  render() {
    const {
      vacancy,
      taxSystems,
      admissionTypes,
      onUpdateContractTerms,
      onUpdateCompetitionType,
      onClickOpenChat,
      isTenders,
    } = this.props;
    const { contract, isCompetitionStarted, isPerformer, object } = vacancy;
    const { paymentTerms } = contract;

    return <>
      <div className="vacancy-profile-container">
        <div className="vacancy-profile-section">
          {this.renderFiles(isTenders)}
        </div>
        <div className="vacancy-profile-section">
          <ApplicationTerms
            object={object}
            paymentTermsProps={paymentTerms.props}
            taxSystems={taxSystems}
            admissionTypes={admissionTypes}
            isReadonly={isCompetitionStarted}
            isCompetition
            onSubmit={onUpdateContractTerms}
            onUpdateCompetitionType={onUpdateCompetitionType}
            isPerformer={isPerformer}
            withoutCancelButton
            initialValues={contract}
            onClickOpenChat={onClickOpenChat}
          />
        </div>
      </div>
    </>;
  }

  static propTypes = {
    vacancy: PropTypes.object.isRequired,
    taxSystems: PropTypes.array.isRequired,
    admissionTypes: PropTypes.array.isRequired,
    onChangeCompetitionState: PropTypes.func.isRequired,
    onUpdateContractTerms: PropTypes.func.isRequired,
    onUpdateCompetitionType: PropTypes.func.isRequired,
    onClickOpenChat: PropTypes.func,
    userId: PropTypes.number.isRequired,
    isTenders: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onClickOpenChat() {},
  }
}
