import React, { Component } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import Button from '../../../../base/button';
import { ICONS } from '../../../../../utils/icons';

import './index.scss';

export default class StageTreeItem extends Component {
  handleClickAdd = () => {
    const { onClickAdd, data, level } = this.props;
    onClickAdd(data, level + 1);
  };

  handleClickRemove = () => {
    const { onClickRemove, data } = this.props;
    onClickRemove(data);
  };

  handleClickEdit = parentProjectComponent => {
    const { onClickEdit, data, level } = this.props;
    onClickEdit(data, level, parentProjectComponent);
  };

  render() {
    const {
      data: {
        name,
        code,
        processedCode,
        childType,
        projectComponents,
        hasVacancies,
      }, parentProjectComponent,
    } = this.props;

    return <div className="tree-item-wrapper">
      <div className="section-block">
        <h6>{name}&nbsp;{code && `(${code})`}</h6>
        {!hasVacancies && projectComponents.length === 0 && <Button
          onClick={() => this.handleClickEdit(parentProjectComponent)}
          IconComponent={ICONS.pencil}
          isBorderless
        />}
      </div>
      <span className="item-code">Шифр: {processedCode}</span>
      <div className="item-actions">
        {childType && <Button
          className="add-button"
          onClick={this.handleClickAdd}
          IconComponent={ICONS.zoomPlus}
          isBorderless
        >
          {capitalize(childType.name)}
        </Button>}
        {!hasVacancies && <Button
          onClick={this.handleClickRemove}
          className='remove-button'
          IconComponent={ICONS.trash}
          isBorderless
        >
          Удалить
        </Button>}
      </div>
    </div>;
  }

  static propTypes = {
    level: PropTypes.number.isRequired,
    data: PropTypes.object,
    parentProjectComponent: PropTypes.object,
    onClickAdd: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickEdit: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    parentProjectComponent: {},
    onClickAdd() {},
    onClickRemove() {},
    onClickEdit() {},
  };
}
