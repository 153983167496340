import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Header from '../../header';
import ObjectInfo from '../../objectInfo';
import ObjectFiles from '../objectFiles/container';
import { ROUTES } from '../../../../../utils/routes';
import { goTo } from '../../../../../utils/menu';
import { OBJECT_FILE_TYPES } from '../../../../../utils/object';

import './index.scss';

export default class InitialData extends PureComponent {
  handleClickChangeProjectComposition = () => {
    goTo(ROUTES.projectComposition, { id: this.props.object.id });
  };

  render() {
    const {
      object,
      userId,
    } = this.props;
    const { id, files } = object;
    const isOwner = userId === object.userId;
    const isChiefEngineer = object.stages.some(stage => stage.chiefEngineer?.userId === userId);
    const isCustomerOrChief = isOwner || isChiefEngineer;

    return <div className="object-management-layout-container">
      <Header
        isOwnerOrChief={isCustomerOrChief}
        title="Исходные данные"
        onClickChangeProjectComposition={this.handleClickChangeProjectComposition}
      />
      <ObjectInfo
        isOwner={isOwner}
        object={object}
      />
      <div className="divider" />
      <ObjectFiles
        {...OBJECT_FILE_TYPES.technicalTask}
        objectId={id}
        files={files[OBJECT_FILE_TYPES.technicalTask.type]}
        showAddButton={isCustomerOrChief}
        isCustomerOrChief={isCustomerOrChief}
      />
      <div className="divider" />
      <ObjectFiles
        {...OBJECT_FILE_TYPES.agreement}
        objectId={id}
        files={files[OBJECT_FILE_TYPES.agreement.type]}
        showAddButton={isCustomerOrChief}
        isCustomerOrChief={isCustomerOrChief}
      />
      <div className="divider" />
      <ObjectFiles
        {...OBJECT_FILE_TYPES.otherDocument}
        objectId={id}
        files={files[OBJECT_FILE_TYPES.otherDocument.type]}
        showAddButton={isCustomerOrChief}
        isCustomerOrChief={isCustomerOrChief}
      />
    </div>;
  }

  static propTypes = {
    userId: PropTypes.number.isRequired,
    object: PropTypes.object.isRequired,
  };
}
