import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, matchPath } from 'react-router-dom';
import classNames from 'classnames';

import {
  ROUTES,
  DEFAULT_AUTHORIZED_ROUTE,
  DEFAULT_AUTHORIZED_FILLED_PROFILE_ROUTE,
} from '../../utils/routes';
import NotImplementedYet from '../../components/notImplementedYet';
import Menu from '../menu/container';
import {
  MENU_ITEMS,
  MAIN_MENU_NAME,
  OBJECT_MENU_NAME,
  PROFILE_MENU_NAME,
  FINANCE_CONTRACT_MENU_NAME,
  OBJECT_CONTRACT_MENU_NAME,
  VACANCY_MENU_NAME,
  TENDER_MENU_NAME,
  TARIFF_MENU_NAME,
  getFilteredMenu,
  isObjectMenu,
  BACK_BUTTON_TYPE,
  REMARKS_MENU_NAME,
} from '../../utils/menu';
import Profile from '../../pages/profile/edit/container';
import ObjectMenu from '../objectMenu/container';
import NotFound from '../../components/notFound';
import WebAnalyticsRoute from '../app/webAnalyticsRoute';
import BlockedObject from '../../pages/objects/blocked/container';

import './index.scss';

export default class LoggedInLayout extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // avoid re-render if path was not changed
    return this.props.location.pathname !== nextProps.location.pathname;
  }

  render() {
    const { isUserProfileFilled, location: { state, pathname }, isUserPasswordEmpty, isExternalProfile } = this.props;
    let defaultRoute = isUserProfileFilled ? DEFAULT_AUTHORIZED_FILLED_PROFILE_ROUTE : DEFAULT_AUTHORIZED_ROUTE;

    if (state?.from && state?.from.pathname !== '/') {
      defaultRoute = state.from;
    }

    if (!isExternalProfile && isUserPasswordEmpty) {
      defaultRoute = ROUTES.setPassword + '/' + localStorage.getItem('token');
    }

    return <div className="logged-in-layout">
      {isObjectMenu(pathname) ? <ObjectMenu /> : <Menu
        items={getFilteredMenu(pathname)}
        className="main-menu-layout"
      />}
      <div className={classNames('main-content', matchPath(pathname, ROUTES.blockedObject) && 'flexible')}>
        <Switch>
          <WebAnalyticsRoute path={ROUTES.blockedObject} component={BlockedObject} />
          {[
            ...MENU_ITEMS[REMARKS_MENU_NAME],
            ...MENU_ITEMS[FINANCE_CONTRACT_MENU_NAME],
            ...MENU_ITEMS[OBJECT_CONTRACT_MENU_NAME],
            ...MENU_ITEMS[OBJECT_MENU_NAME],
            ...MENU_ITEMS[PROFILE_MENU_NAME],
            ...MENU_ITEMS[VACANCY_MENU_NAME],
            ...MENU_ITEMS[TENDER_MENU_NAME],
            ...MENU_ITEMS[TARIFF_MENU_NAME],
            ...MENU_ITEMS[MAIN_MENU_NAME],
          ].map(({ route, type, exact, component = NotImplementedYet }, index) =>
            route && type !== BACK_BUTTON_TYPE && <WebAnalyticsRoute key={index} exact={exact} path={route} component={component} />)}
          <WebAnalyticsRoute path={ROUTES.notFound} component={NotFound} />
          <WebAnalyticsRoute path={ROUTES.profile} exact component={Profile} />
          <Redirect from={ROUTES.profile} to={ROUTES.notFound} />
          <Redirect
            from={pathname}
            to={defaultRoute}
          />
        </Switch>
      </div>
    </div>;
  }
}

LoggedInLayout.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isUserProfileFilled: PropTypes.bool,
  isUserPasswordEmpty: PropTypes.bool,
  isExternalProfile: PropTypes.bool,
};

LoggedInLayout.defaultProps = {
  isUserProfileFilled: false,
  isUserPasswordEmpty: false,
  isExternalProfile: false,
};
