import moment from 'moment';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import differenceBy from 'lodash/differenceBy';
import orderBy from 'lodash/orderBy';
import { getProjectComponentName } from '../../utils/projectComponent';

export const getTasksQuery = ({ userId, objectId, isOutgoing, isIncoming, isDelegated, isArchive, search }) => {
  const whereQuery = {
    _and: [
      {
        isClosed: { _eq: isArchive },
      },
      {
        _not: { remark: {} },
      },
      ...(objectId ? [{ objectId: { _eq: objectId } }] : []),
      ...(isOutgoing ? [{ authorId: { _eq: userId } }] : []),
      ...(isIncoming ? [{ assignedUserId: { _eq: userId } }] : []),
      ...(isDelegated
        ? [{
          taskMessages: {
            _and: [
              {
                userId: { _eq: userId },
              },
              {
                isReassignment: { _eq: true },
              },
            ],
          },
        }]
        : []),
      ...(isArchive
        ? [{
          _or: [
            {
              authorId: { _eq: userId },
            },
            {
              assignedUserId: { _eq: userId },
            },
            {
              taskMessages: { userId: { _eq: userId } },
            },
          ],
        }]
        : []),
    ],
  };

  if (search && search !== '') {
    const userSearchFields = {
      _or: [
        {
          firstName: { _ilike: search },
        },
        {
          lastName: { _ilike: search },
        },
        {
          patronymic: { _ilike: search },
        },
        {
          organizationContractDetails: {
            name: { _ilike: search },
          },
        },
      ],
    };
    search = `%${search}%`;

    whereQuery._and.push({
      _or: [
        { object: { name: { _ilike: search } } },
        { objectProjectComponent: { templateProjectComponent: { name: { _ilike: search } } } },
        ...(isIncoming ? [{ author: userSearchFields }] : isOutgoing ? [{ assignedUser: userSearchFields }] : []),
      ],
    });
  }

  return whereQuery;
};

export const prepareToInsertTask = ({ deadline, toUser, object, stage, projectComponent, message, files }) => ({
  assignedUserId: toUser,
  deadline: moment(deadline).set({ hour: 23, minute: 59, second: 59 }).toDate(),
  objectId: object,
  objectProjectComponentId: projectComponent || stage,
  taskMessages: {
    data: [{
      message,
      ...(files?.length > 0
        ? {
          taskMessageFiles: {
            data: files.filter(({ file }) => file?.remotePath).map(({ file }) => ({
              file: {
                data: file,
              },
            })),
          },
        }
        : {}),
    }],
  },
});

export const formatTasks = (tasks, userId) => (tasks || [])
  .map(task => {
    task.taskMessages.forEach(message => {
      message.isAuthor = message.userId === userId;
    });

    const authorLastMessage = orderBy(
      task.taskMessages.filter(message => message.userId !== task.assignedUserId && !message.isReassignment),
      ['id'],
      ['desc'],
    )[0] || task.taskMessages[0];

    const { taskMessages: [taskMessage, ...messages], object, objectProjectComponent, ...taskProps } = task;
    let relatedTo = {};
    let stageId = null;

    if (objectProjectComponent) {
      const {
        id: objectProjectComponentId,
        generatedCode,
        parentObjectProjectComponentId,
        root: { rootComponent },
      } = objectProjectComponent;

      const { id, name: customStageName, templateProjectComponent: { name: stageName } } = rootComponent;

      stageId = id;
      relatedTo = {
        placeholder: `${customStageName || stageName}`,
        objectId: object.id,
        stageId: id,
      };

      if (parentObjectProjectComponentId) {
        if (generatedCode) {
          const { processedName, processedCode } = generatedCode;
          relatedTo.placeholder += `. ${getProjectComponentName(processedName, processedCode)}`;
        }
        relatedTo.projectComponentId = objectProjectComponentId;
      }
    }

    return {
      ...taskProps,
      isAssignedUser: task.assignedUserId === userId,
      stageId,
      taskMessage,
      lastMessage: messages[messages.length - 1],
      messages,
      authorLastMessage,
      description: object.name,
      relatedTo,
      code: object.code,
    };
  });

export const prepareToInsertTaskMessages = ({ messageFiles, files, response, message, revisionMessage, task }) => ({
  taskId: task.id,
  message: revisionMessage || response || message,
  ...(messageFiles?.length > 0 || files?.length > 0
    ? {
      taskMessageFiles: {
        data: (messageFiles || files).filter(({ file }) => file?.remotePath).map(({ file }) => ({
          file: {
            data: file,
          },
        })),
      },
    }
    : {}),
});

export const prepareToInsertTaskMessageFiles = (taskMessageId, files, initialFiles) => files?.length > 0
  ? differenceWith(files.filter(({ file }) => file?.remotePath), initialFiles, isEqual)
    .map(({ file: { remotePath, originalName, size, title, mimeType } }) => ({
      taskMessageId,
      file: {
        data: {
          remotePath,
          originalName,
          mimeType,
          size,
          title,
        },
        on_conflict: {
          constraint: 'file_remotePath_key',
          update_columns: ['title', 'updatedAt'],
        },
      },
    }))
  : [];

export const prepareToDeleteTaskMessageFiles = (files, initialFiles) => files?.length > 0
  ? differenceBy(initialFiles, files, 'file.remotePath').map(({ file }) => file.id)
  : [];
