import React, { useState } from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import classNames from 'classnames';

import { setProjectComponentType, STAGE_FILE_TYPES } from '../../../../../../utils/stage';
import Container from '../../../../../base/container';
import DataGroup from '../../../../../base/dataGroup';
import ProjectComponentFiles from '../files/container';
import Hint from '../../../../../base/hint';
import { getFormattedDate } from '../../../../../../utils/date';
import { PDF_FILE_TYPE, RESULTS_BUNDLED_IN_PDF_MAX_FILE_SIZE_MB } from '../../../../../../utils/file';
import BundleLink from '../../../../../bundleLink';

export function VersionContainer({
  id,
  versionRefs,
  title,
  flags: {
    isLastVersion,
    isLastVersionOfCurrentPhase,
    isPerformer,
    isCurrentPerformer,
    worksCanBeAccepted,
    isEditable,
    hasActivePerformer,
    hasLastVersionBundledPdf,
    isCustomerOrChief,
  },
  projectComponentFilesProps: { files, type, considerationResult, responseTime, entityId, versionId, ...props },
  isDefaultExpanded,
  onClickCopyVersionFiles,
  objectId,
}) {
  const [isExpanded, setExpanded] = useState(isDefaultExpanded);
  const isLastVersionAndActivePerformer = isLastVersionOfCurrentPhase && isCurrentPerformer;
  const hasBundledPdf = files[STAGE_FILE_TYPES.resultBundledInPdf.type]?.length > 0;
  const hasSources = files[STAGE_FILE_TYPES.sourceInEditableFormat.type]?.length > 0;
  const isCompletenessCanBeAccepted = worksCanBeAccepted && hasSources;
  const resultInPdfFile = files[STAGE_FILE_TYPES.resultBundledInPdf.type] || [];
  return <Container
    id={id}
    ref={versionRefs.ref}
    className={classNames('version-container', { 'files-readonly': props.isReadonly })}
    isDefaultExpanded={isDefaultExpanded}
    shareExpandedState={setExpanded}
    headerContent={<h5>{title}</h5>}
    {...(isExpanded
      ? {
        footerContent: <>
          <div>
            <span>Результат рассмотрения {type.genitiveName}:</span>
            {considerationResult.filter(({ userId }) => userId).map(({ title, status }, index) =>
              <DataGroup key={index} title={title} isLineBreakAllowed>
                <span className={`colored ${status.color}`}>{status.title}</span>
              </DataGroup>)}
          </div>
          {responseTime && <div>
            <span>{responseTime.title}</span>
            <span className={classNames('colored', responseTime.style)}>{responseTime.time}</span>
          </div>}
        </>,
      }
      : {})}
    isHeaderClickable
  >
    <ProjectComponentFiles
      {...props}
      files={files[STAGE_FILE_TYPES.sourceInEditableFormat.type]}
      type={STAGE_FILE_TYPES.sourceInEditableFormat.type}
      isCustomerOrChief={isCustomerOrChief && isLastVersion}
      onClickCopyVersionFiles={onClickCopyVersionFiles}
      title={setProjectComponentType(STAGE_FILE_TYPES.sourceInEditableFormat.title, type.name)}
      entityId={entityId}
      versionId={versionId}
      containerProps={{
        ref: versionRefs.childRefs[0],
        isDefaultExpanded: isExpanded,
        previewContent: <Hint title={`${upperFirst(type.name)} "Редактируемый формат"`}>
          В данном блоке исполнитель загружает {type.accusativeName} в виде файлов в редактируемых форматах.
          Отсутствие загруженных файлов воспринимается системой как невыполненные работы.
        </Hint>,
        showPreviewAlways: true,
      }}
      {...({
        footerContent: <>
          {isCompletenessCanBeAccepted && <div className="files-status">
            {isLastVersionOfCurrentPhase && hasActivePerformer && <>
              <span>Комплектность подтверждена:</span>
              {considerationResult.map(({ title, completenessConfirmedBy }, index) =>
                <DataGroup key={index} title={title} isLineBreakAllowed>
                  {completenessConfirmedBy ? getFormattedDate(completenessConfirmedBy) : ''}
                </DataGroup>)}
            </>}
            <BundleLink
              linkTitle="Скачать все исходники одним архивом"
              linkParams={[
                { objectId },
                { projectComponentId: entityId },
                { fileType: STAGE_FILE_TYPES.sourceInEditableFormat.type },
              ]}
            />
          </div>}
        </>,
      })}
      showAddButton={isLastVersionAndActivePerformer}
      showDeleteButton={isEditable && isLastVersionAndActivePerformer}
      isLastVersion={!isPerformer || isLastVersionOfCurrentPhase}
      isContainer
    />
    <ProjectComponentFiles
      {...props}
      files={resultInPdfFile}
      type={STAGE_FILE_TYPES.resultBundledInPdf.type}
      title={setProjectComponentType(STAGE_FILE_TYPES.resultBundledInPdf.title, type.name)}
      entityId={entityId}
      versionId={versionId}
      isCustomerOrChief={isCustomerOrChief && isLastVersion}
      onClickCopyVersionFiles={onClickCopyVersionFiles}
      maxFileSize={RESULTS_BUNDLED_IN_PDF_MAX_FILE_SIZE_MB}
      containerProps={{
        ref: versionRefs.childRefs[1],
        isDefaultExpanded: isExpanded,
      }}
      {...(isLastVersionOfCurrentPhase && hasActivePerformer && {
        footerContent: <>
          {worksCanBeAccepted && <div className="files-status">
            <span>Дата скачивания файла:</span>
            {considerationResult.map(({ userId, title }, index) => {
              const userDownloadItem = resultInPdfFile[0]?.downloads.find(download => download.userId === userId);

              return <DataGroup key={index} title={title} isLineBreakAllowed>
                {userDownloadItem ? getFormattedDate(userDownloadItem.createdAt) : ''}
              </DataGroup>;
            })}
          </div>}
        </>,
      })}
      showAddButton={isEditable && isLastVersionAndActivePerformer && !hasBundledPdf}
      showDeleteButton={isEditable && isLastVersionAndActivePerformer}
      isLastVersion={!isPerformer || isLastVersionOfCurrentPhase || hasLastVersionBundledPdf}
      isContainer
      acceptedFileTypes={PDF_FILE_TYPE}
    />
  </Container>;
}

VersionContainer.propTypes = {
  id: PropTypes.string.isRequired,
  versionRefs: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  flags: PropTypes.object.isRequired,
  projectComponentFilesProps: PropTypes.object.isRequired,
  isDefaultExpanded: PropTypes.bool,
  isReadonly: PropTypes.bool,
  onClickCopyVersionFiles: PropTypes.func.isRequired,
  objectId: PropTypes.number.isRequired,
};

VersionContainer.defaultProps = {
  isDefaultExpanded: false,
  isReadonly: false,
};
