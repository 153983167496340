import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import Button from '../../../base/button';
import { ICONS } from '../../../../utils/icons';

import './index.scss';

export default class BalanceHeader extends PureComponent {
  render() {
    const { onWithdrawalRequestClick } = this.props;

    return <div className="balance-header">
      <h5>Реестр изменений баланса</h5>
      <div className="header-buttons">
        <Button IconComponent={ICONS.coin} onClick={noop} isBorderless>Пополнить баланс</Button>
        <Button IconComponent={ICONS.arrowRepeat} onClick={onWithdrawalRequestClick} isBorderless>Вывод средств</Button>
        <Button IconComponent={ICONS.circlePlus} onClick={noop} isBorderless>Скачать акт сверки</Button>
      </div>
    </div>;
  }

  static propTypes = {
    onWithdrawalRequestClick: PropTypes.func.isRequired,
  }
}
