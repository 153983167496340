import { compose, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import DeleteVacancyModal from './index';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';

const DELETE_VACANCY_MUTATION = loader('../../../graphql/queries/vacancy/delete.graphql');

const withDeleteVacancyMutation = graphql(DELETE_VACANCY_MUTATION, {
  name: 'deleteVacancy',
});

const mergeProps = withProps(({ id, contractId, deleteVacancy }) => ({
  onDeleteVacancy: () => deleteVacancy({
    variables: {
      id,
      contractId,
    },
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
}));

export default compose(
  withDeleteVacancyMutation,
  mergeProps,
)(DeleteVacancyModal);
