import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as MenuIcon } from '../../../../assets/icons/menu.svg';
import Button from '../../../base/button';
import { ROUTES } from '../../../../utils/routes';
import { goTo } from '../../../../utils/menu';

import { getFormattedNumber } from '../../../../utils/numbers';
import './index.scss';

export default class ProfileHeader extends PureComponent {
  goToFinanceRequisites = () => {
    goTo(ROUTES.financeContractRequisites);
  };

  goToTariffPlans = () => {
    goTo(ROUTES.currentTariffs);
  };

  goToFinanceBalance = () => {
    goTo(ROUTES.financeBalance);
  };

  render() {
    const { balance, isContractDetailsFilled } = this.props;

    return <div className="profile-header-layout">
      <div className="profile-header">
        <h1>Личный кабинет</h1>
      </div>
      <Button
        onClick={this.goToTariffPlans}
      >Тарифные планы
      </Button>
      <div className="profile-status">
        <Button
          className={classNames({ 'no-requisites': !isContractDetailsFilled })}
          IconComponent={MenuIcon}
          onClick={this.goToFinanceRequisites}
        >Реквизиты для договора
        </Button>
        <Button
          onClick={this.goToFinanceBalance}
        >Лицевой счёт: <span>{getFormattedNumber(balance)}</span>
        </Button>
      </div>
    </div>;
  }
}

ProfileHeader.propTypes = {
  balance: PropTypes.number.isRequired,
  isContractDetailsFilled: PropTypes.bool.isRequired,
};
