import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import FormGroupField from '../../../base/formGroup';
import UserInfo from '../../../base/userInfo';
import AddFilesSection from '../../../base/addFilesSection';

import './index.scss';

export default class Organization extends React.PureComponent {
  render() {
    const { taxSystems } = this.props;
    return <div className="organization form-card">
      <h5>Данные организации</h5>
      <Field
        name="name"
        component={FormGroupField}
        type="text"
        label="Наименование организации"
      />
      <Field
        name="organizationInn"
        component={FormGroupField}
        type="text"
        label="ИНН"
        mask="999999999999"
      />
      <Field
        name="kpp"
        component={FormGroupField}
        type="text"
        label="КПП"
        mask="999999999"
      />
      <Field
        name="ogrn"
        component={FormGroupField}
        type="text"
        label="ОГРН"
        mask="9999999999999"
      />
      <h6>Данные руководителя</h6>
      <UserInfo />
      <Field
        name="address"
        component={FormGroupField}
        type="text"
        label="Юридический адрес"
      />
      <Field
        name="taxSystemId"
        component={FormGroupField}
        type="select"
        data={taxSystems}
        label="Система налогообложения"
        placeholder="Выберите систему налогообложения"
        keepRemoveTag
      />
      <h6>Допуски</h6>
      <AddFilesSection
        name="admissions"
        label="Вид допуска"
        buttonText="Добавить документ (выписки и лицензии)"
      />
      <h6>Доверенности</h6>
      <AddFilesSection
        name="lettersOfAttorney"
        label="Вид доверенности"
        buttonText="Добавить доверенность"
      />
    </div>;
  }
}

Organization.propTypes = {
  taxSystems: PropTypes.array.isRequired,
};
