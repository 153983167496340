import { createAction } from 'redux-actions';
import { SubmissionError } from 'redux-form';

import history from '../../utils/history';
import { API_ERROR_MESSAGES, UI_ERROR_MESSAGES, UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import { removeAuthCookie, checkAuthCookie } from '../../utils/session';
import AuthorizedApi from '../../api/authorized';
import AuthApi from '../../api/auth';
import { ROUTES, ROOT_ROUTE } from '../../utils/routes';
import { checkNetworkError, showError } from '../../api/error';
import ApolloClient, { subscriptionClient } from '../../graphql/client';

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const SET_USER = 'SET_USER';

export const setAuthenticated = createAction(SET_AUTHENTICATED, isAuthenticated => ({ isAuthenticated }));
export const setSessionLoading = createAction(SET_SESSION_LOADING, isLoading => ({ isLoading }));
export const setUser = createAction(SET_USER);

export const checkAuthentication = () => dispatch => {
  dispatch(setSessionLoading(true));
  if (!checkAuthCookie()) {
    dispatch(setAuthenticated(false));
    dispatch(setSessionLoading(false));
    return;
  }
  return AuthorizedApi.claim().then(user => {
    dispatch(setAuthenticated(true));
    dispatch(setUser({ user }));
    return user;
  }).catch(() => dispatch(setAuthenticated(false)))
    .finally(() => dispatch(setSessionLoading(false)));
};

export const signUp = params => {
  return () => {
    return (params.isDemo ? AuthApi.signUpWithDemo(params) : AuthApi.signUp(params))
      .catch(error => {
        const submissionError = {};
        const errorMessage = error.message;

        if (errorMessage && errorMessage.isJoi) {
          if (errorMessage.details) {
            errorMessage.details.forEach(({ path, message }) => {
              submissionError[path] = UI_ERROR_MESSAGES[message] ? UI_ERROR_MESSAGES[message] : message;
            });
          }
        } else if (errorMessage === API_ERROR_MESSAGES.userEmailAlreadyRegistered) {
          submissionError.email = UI_ERROR_MESSAGES[API_ERROR_MESSAGES.userEmailAlreadyRegistered];
        } else if (errorMessage === API_ERROR_MESSAGES.unconfirmedEmailExists) {
          submissionError.email = UI_ERROR_MESSAGES[API_ERROR_MESSAGES.unconfirmedEmailExists];
        }

        if (Object.keys(submissionError).length > 0) {
          throw new SubmissionError(submissionError);
        } else {
          showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
          throw error;
        }
      });
  };
};

export const confirmRegistration = params => () => AuthApi.confirm(params)
  .then(checkAuthentication)
  .then(() => history.push(ROOT_ROUTE))
  .catch(error => {
    if (checkNetworkError(error)) {
      return;
    }
    history.push(ROUTES.signIn);
  });

export const confirmEmail = params => () => AuthApi.confirm(params)
  .then(checkAuthentication)
  .then(() => history.push(ROUTES.profile))
  .catch(error => {
    if (checkNetworkError(error)) {
      return;
    }
    history.push(ROUTES.signIn);
  });

export const signIn = ({ email, signInPassword, token, disableRedirect }, fromLocation) => () =>
  AuthApi.signIn({ email, password: signInPassword, token })
    .then(() => checkAuthentication)
    .then(() => !disableRedirect && history.push(ROOT_ROUTE, { from: fromLocation }))
    .catch(error => {
      const { thereIsNoAppropriateUser, thePasswordIsIncorrect } = API_ERROR_MESSAGES;
      const submissionError = {};
      const errorMessage = error.message;

      if (thereIsNoAppropriateUser === errorMessage) {
        submissionError.email = UI_ERROR_MESSAGES[thereIsNoAppropriateUser];
      } else if (thePasswordIsIncorrect === errorMessage) {
        submissionError.signInPassword = UI_ERROR_MESSAGES[thePasswordIsIncorrect];
      }

      if (Object.keys(submissionError).length > 0) {
        throw new SubmissionError(submissionError);
      } else {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.signIn);
      }
    });

export const setPassword = params => () => AuthApi.setPassword(params)
  .then(checkAuthentication)
  .then(() => history.push(ROOT_ROUTE))
  .catch(error => {
    const submissionError = {};
    const errorMessage = error.message;
    if (errorMessage) {
      submissionError.password = errorMessage;
    }
    if (Object.keys(submissionError).length > 0) {
      throw new SubmissionError(submissionError);
    } else {
      showError(error);
      throw error;
    }
  });

export const sendRecoverPasswordMail = params => {
  return () => {
    return AuthApi.recoverMessage(params)
      .catch(error => {
        const submissionError = {};
        const errorMessage = error.message;
        if (errorMessage) {
          submissionError.email = UI_ERROR_MESSAGES[errorMessage] || errorMessage;
        }
        if (Object.keys(submissionError).length > 0) {
          throw new SubmissionError(submissionError);
        } else {
          showError(error);
          throw error;
        }
      });
  };
};

export const signOut = () => dispatch => {
  AuthApi.signOut().then(() => {
    dispatch(setSessionLoading(true));
    removeAuthCookie();
    ApolloClient.cache.reset();
    localStorage.clear();
    subscriptionClient.close();
    dispatch(setAuthenticated(false));
    dispatch(setUser({ user: {} }));
    dispatch(setSessionLoading(false));
    history.push(ROUTES.signIn);
  });
};
