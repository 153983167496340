import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import { REDIRECT_TO_REQUISITES_TITLE } from '../../utils/buttonTitles';
import { ROUTES } from '../../utils/routes';
import ObjectsHeader from '../../components/objects/header';
import CreateObjectModal from './createObject';
import EditObjectModal from '../../components/modals/editObject/container';
import CreateEngineerVacancyModal from './object/stage/createEngineerVacancy';
import InfoModal from '../../components/modals/info';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';
import { CREATE_OBJECT_EMPTY_REQUISITES, HAS_NO_TARIFF_PLAN } from '../../utils/messages';

export default class ObjectsMenu extends PureComponent {
  state = {
    object: null,
    stageId: null,
    isCreateModalShown: false,
    predefinedTariffPlanId: null,
  };

  createObjectModalRef = React.createRef();
  editObjectModalRef = React.createRef();
  createEngineerVacancyModalRef = React.createRef();
  tariffPlansModalRef = React.createRef();
  contractDetailsModalRef = React.createRef();

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { predefinedTariffPlanId, isLoading, history } = this.props;

    if (predefinedTariffPlanId && !isLoading) {
      this.showCreateModal(predefinedTariffPlanId);
      // clear route state
      history.replace();
    }
  }

  goToTariffPlans = () => {
    this.props.history.push(ROUTES.currentTariffs);
  };

  goToContractDetails = () => {
    this.props.history.push(ROUTES.financeContractRequisites);
  };

  showCreateModal = predefinedTariffPlanId => {
    const {
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
      userTariffPlans,
    } = this.props;

    if ((!selfContractDetails && !organizationContractDetails) && userTariffPlans.some(({ isDemo }) => !isDemo)) {
      this.contractDetailsModalRef.current.toggleModal();
    } else if (userTariffPlans.length > 0) {
      this.createObjectModalRef.current.toggleModal();
      // TODO get rid of this ugliness and use ref. Everywhere!
      this.setState({ isCreateModalShown: true, predefinedTariffPlanId });
    } else {
      this.tariffPlansModalRef.current.toggleModal();
    }
  };

  handleCloseCreateModal = () => {
    this.setState({ predefinedTariffPlanId: null });
  };

  showEditModal = object => {
    this.editObjectModalRef.current.toggleModal();
    this.setState({ object });
  };

  showCreateEngineerVacancyModal = (object, stageId) => {
    this.setState({ object, stageId });
    this.createEngineerVacancyModalRef.current.toggleModal();
  };

  render() {
    const {
      isOwner,
      userTariffPlans,
      menu,
      isArbitration,
      isClosed,
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
    } = this.props;
    const { object, stageId, isCreateModalShown, predefinedTariffPlanId } = this.state;
    const isDemoMode = (!selfContractDetails && !organizationContractDetails) && object?.isDemoTariff;

    return <>
      <ObjectsHeader menu={menu} onClick={() => this.showCreateModal()} />
      <Switch>
        {menu.map(({ route, name, component: Component }, index) =>
          <WebAnalyticsRoute
            key={index}
            path={route}
            name={name}
            render={props => <Component
              {...props}
              isOwner={isOwner}
              isArbitration={isArbitration}
              isClosed={isClosed}
              showEditModal={this.showEditModal}
              showCreateEngineerVacancyModal={this.showCreateEngineerVacancyModal}
            />}
          />)}
        <Redirect to={ROUTES.involvedObjects} />
      </Switch>
      <CreateObjectModal
        onRef={this.createObjectModalRef}
        isSkip={!isCreateModalShown}
        userTariffPlans={userTariffPlans}
        selectedTariffPlanId={predefinedTariffPlanId}
        onClose={this.handleCloseCreateModal}
      />
      <EditObjectModal
        onRef={this.editObjectModalRef}
        data={object || {}}
        isSkip={!object}
      />
      <CreateEngineerVacancyModal
        onRef={this.createEngineerVacancyModalRef}
        object={object}
        stageId={stageId}
        isSkip={!stageId}
        isChiefEngineerOnly
        isDemoMode={isDemoMode}
      />
      <InfoModal
        onRef={this.tariffPlansModalRef}
        message={HAS_NO_TARIFF_PLAN}
        buttonTitle="Управление тарифными планами"
        onClick={this.goToTariffPlans}
      />
      <InfoModal
        onRef={this.contractDetailsModalRef}
        message={CREATE_OBJECT_EMPTY_REQUISITES}
        buttonTitle={REDIRECT_TO_REQUISITES_TITLE}
        onClick={this.goToContractDetails}
      />
    </>;
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    isOwner: PropTypes.bool.isRequired,
    isArbitration: PropTypes.bool.isRequired,
    isClosed: PropTypes.bool.isRequired,
    menu: PropTypes.array.isRequired,
    userTariffPlans: PropTypes.array,
    predefinedTariffPlanId: PropTypes.number,
    isLoading: PropTypes.bool,
    contractDetails: PropTypes.object,
    object: PropTypes.object,
  };

  static defaultProps = {
    userTariffPlans: [],
    predefinedTariffPlanId: false,
    isLoading: false,
    contractDetails: {},
    object: {},
  }
}
