import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Container from '../../../base/container';
import DataGroup from '../../../base/dataGroup';
import ProfileLink from '../../../base/profileLink';
import ProjectComponentLink from '../../../base/projectComponentLink';
import ProcessedCode from '../../../base/processedCode';
import Button from '../../../base/button';
import { getFormattedNumber } from '../../../../utils/numbers';
import { getFormattedDate } from '../../../../utils/date';
import { getContractorName } from '../../../../utils/name';
import Rating from '../../../base/rating';
import { WORK_PHASES } from '../../../hints';
import Section from '../../../base/section';
import { getFullContractSubject } from '../../../../graphql/model/contracts';
import { ICONS } from '../../../../utils/icons';
import { VACANCY_TYPE } from '../../../../utils/vacancyType';
import { CONTRACT_STATUSES } from '../../../../utils/contract';

import './index.scss';

class Item extends PureComponent {
  renderAmount = ({ totalAmount, balance: { billed, paid, balance } }) => <>
    <DataGroup title="Сумма договора" color="blue">
      {getFormattedNumber(totalAmount)}
    </DataGroup>
    <DataGroup title="Принято работ" color="green">
      {getFormattedNumber(billed)}
    </DataGroup>
    <DataGroup title="Оплачено" color="orange">
      {getFormattedNumber(paid)}
    </DataGroup>
    <DataGroup title="Баланс по договору" color="red">
      {getFormattedNumber(balance)}
    </DataGroup>
  </>;

  renderBreakContractButton = caption => <Button
    onClick={() => this.props.onClickBreakContract()}
    buttonProps={{
      type: 'button',
    }}
  >
    {caption}
  </Button>

  renderButton = () => {
    const { hideOpenContractButton, data: contract, onClickReview } = this.props;
    const { isActive, isArbitration, inDispute } = contract.status;
    const isContractFinished = !isActive && !isArbitration && !inDispute;
    let button = null;

    if (!hideOpenContractButton) {
      button = <Button
        onClick={() => this.props.goToContract(contract.id)}
      >
        Открыть договор
      </Button>;
    } else if (contract.status?.isActive) {
      button = this.renderBreakContractButton('Расторгнуть договор');
    } else if (inDispute) {
      button = this.renderBreakContractButton('В процессе расторжения');
    }

    return <>
      {isContractFinished && !contract.evaluation.value && <Button
        className="chat"
        onClick={() => onClickReview(contract)}
        IconComponent={ICONS.chat}
        isBorderless
      >
        Отзыв
      </Button>}
      {button}
    </>;
  };

  renderHeader = contract => {
    const {
      arbitration,
      subject,
      isPerformer,
      vacancyTypeName,
      role,
      status: { inDispute },
      object: { stageId, id: objectId, projectComponentId, isTariffActive },
    } = contract;
    const text = getFullContractSubject(isPerformer, subject, vacancyTypeName);
    const isContractInProcessOfTermination = inDispute || arbitration;
    const isPerformerOrLeadEngineer = [VACANCY_TYPE.lead, VACANCY_TYPE.performer].indexOf(role) > -1;

    return <div className="contract-item-header">
      <DataGroup title="Предмет договора" id="order" isLineBreakAllowed>
        <ProcessedCode isLoading={!subject}>
          {isContractInProcessOfTermination && isPerformerOrLeadEngineer
            ? <span>{text}</span>
            : <ProjectComponentLink
              text={text}
              objectId={objectId}
              stageId={stageId}
              projectComponentId={projectComponentId}
              isDisabled={!isTariffActive}
            />}
        </ProcessedCode>
      </DataGroup>
    </div>;
  };

  renderPreview = ({ phase, phaseDeadline, status, arbitration, evaluation, ...props }, isExpanded) => {
    const isContractSuspended = status.name === CONTRACT_STATUSES.suspended.name;

    return <div className="contract-item-preview">
      <Section>
        <DataGroup title="Этап" id="phase">{phase ? `${phase.name} - ${phase.description}` : ''}</DataGroup>
        {WORK_PHASES}
        {props.object.isTariffActive && (status.isActive
          ? <DataGroup title="До завершения этапа осталось, дней" id="deadline">{isContractSuspended
            ? CONTRACT_STATUSES.suspended.name
            : phaseDeadline}
          </DataGroup>
          : <DataGroup
            title="Оценка"
            id="rating"
            tooltipText={evaluation.review}
            showAlways={!!evaluation.review}
          >
            <Rating value={evaluation.value} />
          </DataGroup>)}
      </Section>
      <Section className="balance">
        {this.renderAmount(props)}
      </Section>
      {!isExpanded && this.renderFooter(props)}
    </div>;
  };

  renderFooter = ({ id, contractor, signedAt, status, object: { isTariffActive } }, isExpanded) => <Section className="contract-item-footer">
    <DataGroup title="Договор" id="contract">№{id} от {getFormattedDate(signedAt, false)}
      {!isExpanded && contractor.userId && <ProfileLink text={getContractorName(contractor)} userId={contractor.userId} />}
    </DataGroup>
    {isExpanded && (isTariffActive
      ? <DataGroup title="Статус" id="status">
        <span className={status?.kind}>{status?.name}</span>
      </DataGroup>
      : <div className="info">
        <ICONS.infoBold className="info-icon" />
        <p>Объект заблокирован в связи с окончанием тарифного плана</p>
      </div>)}
    {this.renderButton()}
  </Section>;

  renderProfileLink = (user, title, id) => <>
    <DataGroup title={title} id={id}>
      <ProfileLink text={getContractorName(user)} userId={user.userId} />
    </DataGroup>
    <DataGroup id="inn">(ИНН {user.inn})</DataGroup>
  </>

  renderChild = contract => {
    const {
      role,
      contractor,
      object: { name, code, objectType, stageName, region, address },
      isExpert,
      customer,
      employee,
    } = contract;
    return <>
      {this.renderPreview(contract, true)}
      <Section>
        <DataGroup title="Наименование объекта" id="objectName" isLineBreakAllowed>{name}</DataGroup>
        <div className="object-info">
          <DataGroup title="Тип объекта" id="objectType">{objectType}</DataGroup>
          <DataGroup title="Шифр объекта" id="objectCode">{code}</DataGroup>
          <DataGroup title="Стадия" id="objectStage">{stageName}</DataGroup>
          <DataGroup title="Регион работы" id="objectRegion">{region}</DataGroup>
          <DataGroup title="Адрес объекта" id="objectAddress">{address}</DataGroup>
        </div>
      </Section>
      <Section>
        <DataGroup title="Моя роль в проекте" id="role">{role}</DataGroup>
        {isExpert && customer && employee
          ? <>
            {this.renderProfileLink(customer, 'Заказчик', 'customer')}
            {this.renderProfileLink(employee, 'Специалист', 'employee')}
          </>
          : this.renderProfileLink(contractor, 'Контрагент', 'contractor')}
      </Section>
      {this.renderFooter(contract, true)}
    </>;
  };

  render() {
    const { data, style, isExpanded, setItemState, isExpansionDisabled } = this.props;

    if (!data) return null;

    return <div style={style} className="contracts-list-item">
      <Container
        className="contract-item"
        isDefaultExpanded={isExpanded}
        isExpansionDisabled={isExpansionDisabled}
        shareExpandedState={setItemState}
        headerContent={this.renderHeader(data)}
        previewContent={this.renderPreview(data)}
      >
        {this.renderChild(data)}
      </Container>
    </div>;
  }

  static propTypes = {
    data: PropTypes.object,
    style: PropTypes.object,
    isExpanded: PropTypes.bool,
    setItemState: PropTypes.func,
    isExpansionDisabled: PropTypes.bool,
    hideOpenContractButton: PropTypes.bool,
    goToContract: PropTypes.func,
    onClickBreakContract: PropTypes.func,
    onClickReview: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    style: {},
    isExpanded: false,
    setItemState() {},
    goToContract() {},
    onClickBreakContract() {},
    isExpansionDisabled: true,
    hideOpenContractButton: false,
    onClickReview() {},
  };
}

export default Item;
