import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table } from 'react-bootstrap';

import Container from '../container';
import { getFormattedNumber } from '../../../utils/numbers';
import TransactionItem, { gridProps } from './item';
import DataGroup from '../dataGroup';
import Grid from '../grid';
import { SCREEN } from '../../../utils/screen';
import WindowInnerWidthContext from '../../../contexts/windowInnerWidth';

import './index.scss';

export default class TransactionList extends PureComponent {
  state = {
    isExpanded: false,
  };

  itemExpandedStates = {};

  setItemState = index => isExpanded => {
    this.itemExpandedStates[index] = isExpanded;
  };

  getItemState = index => () => !!this.itemExpandedStates[index];

  handleExpandedState = isExpanded => {
    this.setState({ isExpanded });
    return this.setItemState(0);
  };

  renderHeader = (data, isExpanded) => {
    const isMobile = this.context <= SCREEN.md;

    return <div className="registry-list-header">
      {isExpanded
        ? (isMobile ? <h5>{data.title}</h5> : <h4>{data.title}</h4>)
        : this.renderFooter(data, true)}
    </div>;
  };

  renderFooter = ({ title, total: { income, outcome } }, isHeader) => {
    const isMobile = this.context <= SCREEN.md;

    return isMobile
      ? <>
        {isHeader && <div className="date"><h5>{title}</h5></div>}
        <div className="period-total">Итого за период:</div>
        <Grid
          className="period-total-grid"
          items={[{ income, outcome }]}
          gridProps={gridProps}
          showContainerToggle={false}
          useAlternateMobileContainer
        />
      </>
      : <Table className="grid">
        <tbody>
          <tr className="total">
            {isHeader && <td className="date"><h4>{title}</h4></td>}
            <td className={classNames({ 'period-total': isHeader })}>Итого за период{isHeader && ':'}</td>
            <td className="total-income colored green"><DataGroup>{getFormattedNumber(income)}</DataGroup></td>
            <td className="total-outcome colored red"><DataGroup>{getFormattedNumber(outcome)}</DataGroup></td>
            <td className="empty-column" />
          </tr>
        </tbody>
      </Table>;
  };

  render() {
    const { payments: { transactions, total }, title, gridProps, onClickItem } = this.props;
    const { isExpanded } = this.state;

    return <div className="registry-list-wrapper">
      <Container
        isDefaultExpanded={this.getItemState(0)()}
        shareExpandedState={this.handleExpandedState}
        className="registry-item"
        headerContent={this.renderHeader({ title, total }, isExpanded)}
        footerContent={isExpanded && this.renderFooter({ title, total }, false)}
      >
        {transactions.map((transaction, index) => <TransactionItem
          key={index}
          getItemState={this.getItemState(index + 1)}
          setItemState={this.setItemState(index + 1)}
          data={transaction}
          gridProps={gridProps}
          onClickItem={onClickItem}
        />)}
      </Container>
    </div>;
  }

  static contextType = WindowInnerWidthContext;

  static propTypes = {
    payments: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    gridProps: PropTypes.array.isRequired,
    onClickItem: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    gridProps: [],
    onClickItem() {},
  };
}
