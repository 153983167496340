import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';
import Button from '../button';
import './index.scss';

export default class CookiesNotification extends Component {
  render() {
    return <CookieConsent
      ButtonComponent={Button}
      buttonText="Все понятно"
      buttonId="cookieConsentButton"
      disableStyles
    >
      Наш сайт использует файлы cookie. Подробнее - в нашей&nbsp;
      <a
        className="link"
        href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Политике по обработке персональных данных
      </a>
    </CookieConsent>;
  }
}
