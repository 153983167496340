import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

export default class Checkbox extends PureComponent {
  render() {
    const { name, label, className, isDisabled, invalid, ...props } = this.props;
    return <div id={name} className={classNames('checkbox-container', { disabled: isDisabled, invalid }, className)}>
      <label>
        <input
          {...props}
          className="checkbox-item"
          type="checkbox"
          disabled={isDisabled}
        />
        {label && <span className={classNames('label', { disabled: isDisabled })}>{label}</span>}
      </label>
    </div>;
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    invalid: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    className: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    label: '',
    className: '',
    invalid: false,
  }
}
