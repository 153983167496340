import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StageRemarksMenu from '../../../base/menu';
import ProjectComponentFiles from '../stageManagement/projectComponentList/files/container';
import Registry from './registry';
import Drafts from '../../../../pages/objects/object/remarks/drafts/container';
import { REMARK_TYPES } from '../../../../graphql/model/remark';
import { CONTRACT_PHASES } from '../../../../utils/phase';

import './index.scss';

const STAGE_REMARKS_MENU_ITEMS = [
  {
    title: 'Замечания Заказчика/ГИПа',
  },
  {
    title: 'Замечания по экспертизе',
  },
];

export default class Remarks extends PureComponent {
  state = {
    selectedMenuItem: 0,
  };

  handleMenuClick = index => {
    this.setState({ selectedMenuItem: index });
  };

  render() {
    const { isPerformer, isAcceptor, storagePath, projectComponent } = this.props;
    const { selectedMenuItem } = this.state;
    const isExpertiseRemarks = selectedMenuItem === 1;
    const { id, remarks, files, isExpertiseRequired, vacancies } = projectComponent;
    const performerVacancy = vacancies.find(({ isActive, isPerformer }) => isActive && isPerformer);
    const stageRemarksMenuItems = (isExpertiseRequired && performerVacancy?.currentPhase?.key !== CONTRACT_PHASES.phase1
      ? STAGE_REMARKS_MENU_ITEMS
      : STAGE_REMARKS_MENU_ITEMS.slice(0, 1)
    ).map((item, index) => ({
      ...item,
      ...{ badgeCounter: index > 0 ? remarks.count.expertise : remarks.count.nonExpertise },
    }));

    return <div className="remarks-container">
      <div className="page-header-menu">
        <StageRemarksMenu
          items={stageRemarksMenuItems}
          onClick={this.handleMenuClick}
          setActive={index => index === selectedMenuItem}
          isTabMenu
        />
      </div>
      <div className="remarks-section">
        <ProjectComponentFiles
          entityId={id}
          title="Файлы с замечаниями прикрепленные к разделу"
          files={files[isExpertiseRemarks ? 'expertiseRemark' : 'remark']}
          type={isExpertiseRemarks ? 'expertiseRemark' : 'remark'}
          storagePath={storagePath}
          showAddButton={isAcceptor}
        />
      </div>
      {isAcceptor && <div className="remarks-section">
        <Drafts
          projectComponent={projectComponent}
          isExpertise={isExpertiseRemarks}
          currentPhaseId={performerVacancy?.currentPhase?.id}
        />
      </div>}
      <div className="remarks-section">
        <Registry
          projectComponent={projectComponent}
          isPerformer={isPerformer}
          remarks={remarks[REMARK_TYPES[selectedMenuItem]]}
        />
      </div>
    </div>;
  }

  static propTypes = {
    projectComponent: PropTypes.object.isRequired,
    storagePath: PropTypes.string.isRequired,
    isPerformer: PropTypes.bool.isRequired,
    isAcceptor: PropTypes.bool.isRequired,
  };
}
