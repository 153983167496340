import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../base/modal';
import Button from '../../base/button';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class DeleteVacancyModal extends React.PureComponent {
  state = {
    inProgress: false,
  };

  handleCancel = () => {
    this.props.onRef.current.toggleModal();
  };

  handleSuccess = () => {
    this.setState({ inProgress: true });
    this.props.onDeleteVacancy();
    this.setState({ inProgress: false });
    this.handleCancel();
    this.props.onAfterDelete();
  };

  render() {
    const { contractId, isLot, ...props } = this.props;
    const { inProgress } = this.state;

    return <Modal
      className="delete-vacancy-modal"
      title={`Вы действительно хотите удалить ${isLot ? 'Лот' : 'Вакансию'} №${contractId}?`}
      {...props}
    >
      <div className="action-buttons">
        <Button
          isFilled
          isLarge
          IconComponent={ICONS.check}
          isLoading={inProgress}
          onClick={this.handleSuccess}
        >
          Да
        </Button>
        <Button
          isLarge
          IconComponent={ICONS.notInterested}
          isLoading={inProgress}
          onClick={this.handleCancel}
        >
          Нет
        </Button>
      </div>
    </Modal>;
  }

  static propTypes = {
    contractId: PropTypes.number,
    onDeleteVacancy: PropTypes.func.isRequired,
    onAfterDelete: PropTypes.func,
    onRef: PropTypes.object.isRequired,
    isLot: PropTypes.bool,
  };

  static defaultProps = {
    contractId: null,
    isLot: false,
    onAfterDelete() {},
  };
}
