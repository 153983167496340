import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import BlockedObjectComponent from '../../../components/objects/blockedObject';
import { ROUTES } from '../../../utils/routes';
import { goTo } from '../../../utils/menu';

export default class BlockedObject extends Component {
  redirectTo = () => {
    goTo(ROUTES.objects);
  }

  render() {
    const { isLoading, isTariffActive, tariffPlan, objectId } = this.props;

    if (!isLoading && isTariffActive) {
      return <Redirect to={ROUTES.object.replace(':id', objectId)} />;
    }

    return <BlockedObjectComponent tariffPlan={tariffPlan} onClick={this.redirectTo} />;
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isTariffActive: PropTypes.bool.isRequired,
    tariffPlan: PropTypes.string,
    objectId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tariffPlan: '',
  }
}
