import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hoc';
import moment from 'moment';

import configureStore from './store/configureStore';
import apolloClient from './graphql/client';
import AppLayout from './layout/app';
import * as serviceWorker from './serviceWorker';
import CookiesNotification from './components/base/cookiesNotification';

import './style/theme.scss';

const store = configureStore();

moment.locale('ru');

const target = document.querySelector('#root');

render(
  <ApolloProvider client={apolloClient}>
    <CookiesNotification />
    <Provider store={store}>
      <AppLayout />
    </Provider>
  </ApolloProvider>,
  target,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
