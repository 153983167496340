import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import RemarksHeader from '../../../../components/objects/object/stageRemarks/header';
import Menu from '../../../../layout/objectProjectComponentMenu/container';
import Remarks from '../../../../components/objects/object/stageRemarks';
import ObjectInfo from '../../../../components/objects/object/objectInfo';
import { getRemarkStoragePath } from '../../../../utils/storagePaths';
import { ROUTES } from '../../../../utils/routes';
import Chat from '../../../../components/objects/object/stageManagement/projectComponentList/chat/container';

import './index.scss';

export default class RemarksMenu extends PureComponent {
  chatRef = React.createRef();

  handleOpenChat = () => {
    this.chatRef.current?.toggleChat();
  };

  render() {
    const { menu, isLoading, object, isPerformer, isAcceptor, projectComponent } = this.props;

    if (isLoading && !projectComponent?.id) {
      return null;
    } else if (!projectComponent) {
      return <Redirect to={ROUTES.notFound} />;
    }

    const { id, processedName, performers, engineers, type: { dativeName } } = projectComponent;

    return <div className="remarks-layout">
      <RemarksHeader
        title={processedName}
        dativeName={dativeName}
        users={[...engineers, ...performers]}
        onClickOpenChat={this.handleOpenChat}
      />
      <Menu items={menu} currentProjectComponentId={id} isRemarks />
      <ObjectInfo object={object} />
      <Remarks
        projectComponent={projectComponent}
        storagePath={getRemarkStoragePath(id, object.id)}
        isPerformer={isPerformer}
        isAcceptor={isAcceptor}
      />
      <Chat onRef={this.chatRef} title={`Чат по ${dativeName}`} id={id} />
    </div>;
  }

  static propTypes = {
    projectComponent: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    menu: PropTypes.array.isRequired,
    object: PropTypes.object.isRequired,
    isPerformer: PropTypes.bool.isRequired,
    isAcceptor: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    projectComponent: null,
  }
}
