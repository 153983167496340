import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import session from './session';
import sideBar from './sideBar';

export default () => combineReducers({
  form: formReducer,
  session,
  sideBar,
});
