import { pick, mapValues } from 'lodash';

const INTEGER_PROPS = [
  'kpp',
  'ogrn',
  'bankBik',
];

export const getGraphqlOrganizationContractDetails = (
  {
    __typename,
    admissions,
    lettersOfAttorney,
    initialFiles,
    taxSystem,
    userId,
    organizationInn,
    ...props
  },
) => ({
  ...props,
  inn: parseInt(organizationInn, 10),
  ...mapValues(pick(props, INTEGER_PROPS), value => parseInt(value, 10)),
});

export const getGraphqlOrganizationFiles = (files, admissions) => files
  .filter(({ remotePath }) => (admissions || []).find(({ file }) => file && file.remotePath === remotePath))
  .map(file => ({ fileId: file.id }));

export const getGraphqlOrganizationFilesToDelete = (deletedFiles, admissions = []) => deletedFiles
  .filter(({ remotePath }) => (admissions || []).find(({ file }) => file && file.remotePath === remotePath))
  .map(file => file.id);
