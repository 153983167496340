import React, { PureComponent } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import FinanceHeader from '../../components/finance/header';
import { MENU_ITEMS, OBJECT_MENU_ITEMS } from '../../utils/menu';
import NotImplementedYet from '../../components/notImplementedYet';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';
import { ROUTES } from '../../utils/routes';
import ContractReviewModal from '../../components/modals/reviewContract/container';
import Menu from '../../layout/menu/container';

export default class Finance extends PureComponent {
  financeMenu = MENU_ITEMS.mainMenu.find(menuItem => menuItem.route === ROUTES.finance).children;
  objectContractsMenu = OBJECT_MENU_ITEMS.find(menuItem => menuItem.route === ROUTES.objectContracts).children;

  state = {
    contract: {},
  };

  reviewModalRef = React.createRef();

  handleReviewClick = contract => {
    this.setState({ contract });
    this.reviewModalRef.current.toggleModal();
  };

  handleCloseReviewModal = () => {
    this.setState({ contract: {} });
  };

  renderHeader = () => this.props.objectId
    ? <div className="page-header-menu">
      <Menu items={this.objectContractsMenu} isTabMenu />
    </div>
    : <FinanceHeader
      menu={this.financeMenu}
      balance={this.props.balance}
      isLoading={this.props.isProfileAggregationSubscriptionLoading}
      isContractRequisitesPage={this.props.isContractRequisitesPage}
    />;

  render() {
    const { objectId, balance } = this.props;

    return <>
      {this.renderHeader()}
      <Switch>
        {(objectId ? this.objectContractsMenu : this.financeMenu).map(({ route, name, component: Component = NotImplementedYet }, index) =>
          <WebAnalyticsRoute
            key={index}
            path={route}
            name={name}
            render={props => <Component
              {...props}
              balance={balance}
              objectId={objectId}
              onClickReview={this.handleReviewClick}
            />}
          />)}
        <Redirect to={objectId ? ROUTES.currentObjectContracts : this.financeMenu[0].route} />
      </Switch>
      <ContractReviewModal onRef={this.reviewModalRef} contract={this.state.contract} onClose={this.handleCloseReviewModal} />
    </>;
  }

  static propTypes = {
    balance: PropTypes.number,
    objectId: PropTypes.string,
    subscription: PropTypes.func,
    isProfileAggregationSubscriptionLoading: PropTypes.bool,
    isContractRequisitesPage: PropTypes.bool,
  };

  static defaultProps = {
    subscription() {},
    balance: 0,
    isProfileAggregationSubscriptionLoading: false,
    isContractRequisitesPage: false,
    objectId: null,
  };
}
