import React from 'react';

import Hint from '../base/hint';

export const WORK_PHASES = (<Hint title="Этапы работ" placement="right">
  <p>Этап 0 - Ожидание оплаты</p>
  <p>Этап I - Работы</p>
  <p>Этап II - Экспертиза</p>
  <p>Этап III - Окончательная оплата</p>
</Hint>);
