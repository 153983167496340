import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import localization from 'moment/locale/ru';
import DatePickerComponent, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { getYear, getMonth } from 'date-fns';
import classNames from 'classnames';

import Button from '../button';
import Select from '../select';
import { ICONS } from '../../../utils/icons';
import InlineDatePicker from './inlineDatePicker';
import MultilineDatePicker from './multilineDatePicker';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

registerLocale('ru', ru);
setDefaultLocale('ru');
moment().locale('ru', localization);

const YEARS_LOWER_LIMIT = 50;
const YEARS_UPPER_LIMIT = 50;
const CURRENT_YEAR = new Date().getFullYear();
const YEARS = range(CURRENT_YEAR - YEARS_LOWER_LIMIT, CURRENT_YEAR + YEARS_UPPER_LIMIT, 1);
const MONTHS = moment.months();
const MULTILINE_X_OFFSET = '-3px';
const INLINE_X_OFFSET = '5px';

const prepareDropDownData = (array, isMonth) => array.map((item, index) => ({
  label: item.toString(),
  value: isMonth ? index : item.toString(),
}));

const YEARS_ITEMS = prepareDropDownData(YEARS);
const MONTHS_ITEMS = prepareDropDownData(MONTHS, true);
const MIN_YEAR = parseInt(YEARS_ITEMS[0].value);
const MAX_YEAR = parseInt(YEARS_ITEMS[YEARS_ITEMS.length - 1].value);

export default class DatePicker extends React.Component {
  state = {
    years: cloneDeep(YEARS_ITEMS),
    months: cloneDeep(MONTHS_ITEMS),
  };

  correctValue;
  datePickerRef = React.createRef();

  handleChange = (date, { target }) => {
    if (getYear(date) > MAX_YEAR) {
      date.setFullYear(MAX_YEAR);
    }

    if (target.type !== 'text' || target.value.length >= 10) {
      this.props.onChange(date);
    }

    this.correctValue = date;
  };

  getYearFromDate = date => {
    let year = getYear(date);

    if (year < MIN_YEAR) {
      year = MIN_YEAR;
    } else if (year > MAX_YEAR) {
      year = MAX_YEAR;
    }

    return year;
  };

  getMonthFromDate = date => {
    let month = getMonth(date);

    if (month < 0) {
      month = 0;
    } else if (month > 11) {
      month = 11;
    }

    return month;
  };

  updateSelectValues = date => {
    if (this.oldHeaderDate !== date) {
      const { months, years } = this.state;

      if (this.oldHeaderDate) {
        delete months[this.getMonthFromDate(this.oldHeaderDate)].isDefaultValue;
        delete years[YEARS.indexOf(this.getYearFromDate(this.oldHeaderDate))].isDefaultValue;
      }

      months[this.getMonthFromDate(date)].isDefaultValue = true;
      years[YEARS.indexOf(this.getYearFromDate(date))].isDefaultValue = true;

      this.oldHeaderDate = date;
    }
  };

  openDatePicker = () => {
    this.datePickerRef.current.setOpen(true);
  };

  closeDatePicker = () => {
    this.props.onChange(this.correctValue);
    this.datePickerRef.current.setOpen(false);
  };

  handleChangeSelectMonth = changeMonth => value => changeMonth(value);

  handleChangeSelectYear = changeYear => value => changeYear(value);

  renderHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    this.updateSelectValues(date);
    const { months, years } = this.state;

    return <div className="panel">
      <Button
        className="previous"
        onClick={decreaseMonth}
        isDisabled={prevMonthButtonDisabled}
        IconComponent={ICONS.arrow}
        isBorderless
      />
      <div className="selectors">
        <Select
          id="monthSelect"
          className="current-month"
          data={cloneDeep(months)}
          onChange={this.handleChangeSelectMonth(changeMonth)}
        />
        <Select
          id="yearSelect"
          className="current-year"
          data={cloneDeep(years)}
          onChange={this.handleChangeSelectYear(changeYear)}
        />
      </div>
      <Button
        className="next"
        onClick={increaseMonth}
        isDisabled={nextMonthButtonDisabled}
        IconComponent={ICONS.arrow}
        isBorderless
      />
    </div>;
  };

  render() {
    const {
      label,
      isMultiline,
      placeholder,
      placement,
      maxDate,
      minDate,
      value,
      isDisabled,
      name,
      invalid,
      onBlur,
      onFocus,
      dateFormat,
    } = this.props;
    let customInput;
    let selected;
    const popperModifiers = {
      offset: {
        enabled: true,
      },
    };

    if (isMultiline) {
      popperModifiers.offset.offset = MULTILINE_X_OFFSET;
      customInput = <MultilineDatePicker
        name={name}
        invalid={invalid}
        icon="date"
        type="text"
        label={label}
        isDisabled={isDisabled}
        onIconClick={this.openDatePicker}
        onCustomBlur={this.closeDatePicker}
      />;
    } else {
      popperModifiers.offset.offset = INLINE_X_OFFSET;
      customInput = <InlineDatePicker description={label} />;
    }

    if (value) {
      selected = value instanceof Date ? value : moment(value, [dateFormat.toUpperCase(), moment.ISO_8601]).toDate();

      if (isNaN(selected)) {
        selected = new Date(value);
      }
    }

    return <div
      className={classNames(
        'date-picker-container',
        isMultiline ? 'multiline' : 'inline',
        { disabled: isDisabled },
      )}
    >
      <DatePickerComponent
        ref={this.datePickerRef}
        selected={selected}
        onChange={this.handleChange}
        customInput={customInput}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        popperPlacement={placement}
        calendarClassName="date-picker-popover-container"
        renderCustomHeader={this.renderHeader}
        minDate={minDate}
        maxDate={maxDate}
        popperModifiers={popperModifiers}
        onBlur={onBlur}
        onFocus={onFocus}
        disabledKeyboardNavigation
        disabled={isDisabled}
      />
    </div>;
  }

  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    placement: PropTypes.string,
    placeholder: PropTypes.string,
    isMultiline: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    dateFormat: PropTypes.string,
    isDisabled: PropTypes.bool,
    // redux form
    name: PropTypes.string.isRequired,
    invalid: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    placement: 'top-end',
    isMultiline: false,
    maxDate: null,
    minDate: null,
    invalid: false,
    dateFormat: 'dd.MM.yyyy',
    isDisabled: false,
    onBlur() {},
    onFocus() {},
    onChange() {},
  };
}
