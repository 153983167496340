import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DataGroup from '../../../../../base/dataGroup';
import Button from '../../../../../base/button';
import { ICONS } from '../../../../../../utils/icons';
import Select from '../../../../../base/select';

const STATUSES = [
  {
    label: 'Положительное',
    value: 'positive',
  },
  {
    label: 'Отрицательное',
    value: 'negative',
    checked: true,
  },
];

export function StatusComponent({ status, isOwner, hasNotAcceptances, onChangeStatusToPositive }) {
  const [isEdit, setEdit] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [newStatus, setStatus] = useState(status);
  const isPositive = status === 'positive';

  const handleCancel = () => {
    setEdit(false);
  };

  const handleClickEdit = () => {
    setEdit(true);
  };

  const handleChangeStatus = () => {
    if (newStatus === 'positive') {
      setProgress(true);
      onChangeStatusToPositive();
      setProgress(false);
    }
    handleCancel();
  };

  return <div>
    {isPositive || !isEdit
      ? <>
        <DataGroup className={status}>
          {isPositive ? STATUSES[0].label : STATUSES[1].label}
        </DataGroup>
        {!isPositive && isOwner && hasNotAcceptances && <Button
          onClick={handleClickEdit}
          IconComponent={ICONS.pencil}
          isBorderless
        />}
      </>
      : <>
        <Select data={STATUSES} onChange={setStatus} />
        <div className="input-action-buttons">
          <Button
            onClick={handleChangeStatus}
            IconComponent={ICONS.success}
            isBorderless
            isLoading={inProgress}
          />
          <Button
            onClick={handleCancel}
            IconComponent={ICONS.failed}
            isBorderless
            isLoading={inProgress}
          />
        </div>
      </>}
  </div>;
}

StatusComponent.propTypes = {
  status: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
  hasNotAcceptances: PropTypes.bool.isRequired,
  onChangeStatusToPositive: PropTypes.func.isRequired,
};
