import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../../../layout/menu/container';
import Button from '../../base/button';
import { ICONS } from '../../../utils/icons';
import AuthorizedApi from '../../../api/authorized';
import { showError } from '../../../api/error';
import { FAVORITE_ADDED, INVITATION_SENT, UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import AddToFavoritesModal from '../../modals/addToFavorites/container';
import Confirm3OptionsModal from '../../modals/confirm/3options';
import { getSendInvitationSchema } from '../../modals/confirm/3options/schemas';
import CreateProfileModal from '../../modals/createProfile';
import InfoModal from '../../modals/info';

export default class FavoritesHeader extends PureComponent {
  state = {
    email: null,
  };

  addToFavoritesModalRef = React.createRef();
  engineerAddedModalRef = React.createRef();
  invitationSentModalRef = React.createRef();
  confirmWithOptionsModalRef = React.createRef();
  createProfileModalRef = React.createRef();

  handleClickAddNew = () => {
    if (this.addToFavoritesModalRef.current) {
      this.addToFavoritesModalRef.current.toggleModal();
    }
  };

  handleUnregisteredSuccessInvitation = async () => {
    const { email } = this.state;

    const { favoriteUserIsPublic } = await AuthorizedApi.addFavorite(email, this.props.isPerformer)
      .catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.addToFavorites);
        throw error;
      });

    this.confirmWithOptionsModalRef.current.toggleModal();
    this.showInfoModal(favoriteUserIsPublic);
  };

  handleSuccessInvitation = ({ favoriteUserIsPublic }) => {
    this.addToFavoritesModalRef.current.toggleModal();
    this.showInfoModal(favoriteUserIsPublic);
  };

  handleInvitationWithChoice = email => {
    this.setState({ email });
    this.addToFavoritesModalRef.current.toggleModal();
    this.confirmWithOptionsModalRef.current.toggleModal();
  };

  handleDismissInvitation = () => {
    this.confirmWithOptionsModalRef.current.toggleModal();
  };

  handleCreateProfile = () => {
    this.confirmWithOptionsModalRef.current.toggleModal();
    this.createProfileModalRef.current.toggleModal();
  };

  showInfoModal = isPublicUser => isPublicUser
    ? this.engineerAddedModalRef.current.toggleModal()
    : this.invitationSentModalRef.current.toggleModal();

  render() {
    const { menu, isPerformer } = this.props;

    return <div className="objects-header">
      <div className="page-header-menu">
        <Menu items={menu} isTabMenu />
        <Button
          IconComponent={ICONS.add}
          onClick={this.handleClickAddNew}
        >
          Добавить в избранное
        </Button>
      </div>
      <AddToFavoritesModal
        onRef={this.addToFavoritesModalRef}
        isPerformer={isPerformer}
        onSuccess={this.handleSuccessInvitation}
        onChoice={this.handleInvitationWithChoice}
      />
      <Confirm3OptionsModal
        onRef={this.confirmWithOptionsModalRef}
        {...getSendInvitationSchema(
          this.handleUnregisteredSuccessInvitation,
          this.handleDismissInvitation,
          this.handleCreateProfile,
        )}
      />
      <CreateProfileModal onRef={this.createProfileModalRef} />
      <InfoModal onRef={this.invitationSentModalRef} message={INVITATION_SENT} />
      <InfoModal onRef={this.engineerAddedModalRef} message={FAVORITE_ADDED} />
    </div>;
  }

  static propTypes = {
    menu: PropTypes.array.isRequired,
    isPerformer: PropTypes.bool.isRequired,
  };
}
