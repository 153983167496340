import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormText, FormControl } from 'react-bootstrap';
import Input from '../input';
import Select from '../select';
import classNames from 'classnames';
import Checkbox from '../checkbox';
import Switch from '../switch';
import DatePicker from '../datePicker';
import Rating from '../rating';

import './index.scss';

export default class FormGroupField extends PureComponent {
  renderErrorMessage(message, isShow) {
    if (!isShow) {
      return null;
    }

    return <FormControl.Feedback>{message}</FormControl.Feedback>;
  }

  render() {
    const {
      id,
      className,
      input,
      meta: { touched, error, warning, submitting },
      type,
      isDisabled,
      fieldClassName,
      onTopErrorMessage,
      withoutErrorMessage,
      ...custom
    } = this.props;
    const { name } = input;
    const isErrorShowing = !withoutErrorMessage && error && touched;
    let Field;

    switch (type) {
      case 'select': {
        Field = Select;
        break;
      }
      case 'switch': {
        Field = Switch;
        custom.isChecked = !!input.value;
        break;
      }
      case 'date': {
        Field = DatePicker;
        break;
      }
      case 'checkbox': {
        Field = Checkbox;
        break;
      }
      case 'rating': {
        Field = Rating;
        input.value = Number(input.value);
        break;
      }
      case 'textarea':
      default: {
        Field = Input;
        break;
      }
    }

    return <FormGroup id={id || name} className={classNames('form-group-field', className)}>
      {onTopErrorMessage && this.renderErrorMessage(error, isErrorShowing)}
      <Field
        className={fieldClassName}
        isDisabled={submitting || isDisabled}
        name={name}
        invalid={error && touched}
        type={type}
        {...input}
        {...custom}
      />
      {!onTopErrorMessage && this.renderErrorMessage(error, isErrorShowing)}
      {!error && warning && <FormText>{warning}</FormText>}
    </FormGroup>;
  }
}

FormGroupField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  fieldClassName: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  onTopErrorMessage: PropTypes.bool,
  withoutErrorMessage: PropTypes.bool,
};

FormGroupField.defaultProps = {
  id: null,
  type: 'text',
  className: '',
  fieldClassName: '',
  isDisabled: false,
  onTopErrorMessage: false,
  withoutErrorMessage: false,
};
