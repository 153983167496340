import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, defaultProps, withProps } from 'recompose';
import { toast } from 'react-toastify';

import Profile from './index';
import {
  API_ERROR_MESSAGES, EMAIL_CONFIRMATION_SENT,
  PASSWORD_CHANGED_SUCCESSFULLY,
  UI_DEFAULT_ERROR_MESSAGES,
  UI_ERROR_MESSAGES,
} from '../../../utils/messages';
import { showError } from '../../../api/error';
import { formatUser, getGraphqlUser } from '../../../graphql/model/user';
import { getUserRegionsToDelete, getGraphqlUserRegions } from '../../../graphql/model/userRegion';
import { getUserObjectTypesToDelete, getGraphqlUserObjectTypes } from '../../../graphql/model/userObjectType';
import {
  getUserSpecialtiesToDelete,
  getGraphqlUserSpecialties,
} from '../../../graphql/model/userSpecialty';
import { getUserProjectComponentsToDelete, getGraphqlUserProjectComponents } from '../../../graphql/model/userProjectComponent';
import { getGraphqlFilesToDelete, getGraphqlFiles } from '../../../graphql/model/file';
import { getGraphqlUserDocs } from '../../../graphql/model/userDocument';
import AuthorizedApi from '../../../api/authorized';
import withUserReferencesQuery from '../../../graphql/hoc/userReferences';
import withContractDetailsReferencesQuery from '../../../graphql/hoc/contractDetailsReferences';
import withUserBalanceReferencesSubscription from '../../../graphql/hoc/userBalanceReferences';
import {
  getUserChiefEngineerExpertiseExperience,
  getUserChiefEngineerExpertiseExperienceToDelete,
} from '../../../graphql/model/userChiefEngineerExpertiseExperience';

const GET_PROFILE_QUERY = loader('../../../graphql/queries/profile/get.graphql');
const GET_CHIEF_ENGINEER_EXPERTISE_EXPERIENCE_QUERY = loader('../../../graphql/queries/user/getChiefEngineerExpertiseExperience.graphql');
const UPDATE_PROFILE_MUTATION = loader('../../../graphql/queries/profile/update.graphql');
const UPDATE_PROFILE_IS_PUBLIC_MUTATION = loader('../../../graphql/queries/profile/updateIsPublic.graphql');
const INSERT_FILES_MUTATION = loader('../../../graphql/queries/file/insert.graphql');
const ON_PROFILE_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/profile/subscription.graphql');

const withUpdateProfileMutation = graphql(UPDATE_PROFILE_MUTATION, {
  name: 'updateProfile',
});

const withUpdateProfileIsPublicMutation = graphql(UPDATE_PROFILE_IS_PUBLIC_MUTATION, {
  name: 'updateProfileIsPublic',
});

const withInsertFilesMutation = graphql(INSERT_FILES_MUTATION, {
  name: 'insertFiles',
});

const scrollToEmailField = () => {
  document.getElementById('email').scrollIntoView({ behavior: 'smooth', block: 'center' });
};

const mapStateToProps = state => ({
  user: state.session.user,
  userId: state.session.user.id,
});

const mergeProps = withProps(({
  user,
  updateProfile,
  updateProfileIsPublic,
  insertFiles,
  isLoading,
  isUserReferencesLoading,
  isContractDetailsReferencesLoading,
  isProfileAggregationSubscriptionLoading,
}) => ({
  isLoading: isLoading || isUserReferencesLoading ||
    isContractDetailsReferencesLoading || isProfileAggregationSubscriptionLoading,
  onFileUpload: AuthorizedApi.uploadFile,
  onConfirmEmail: email => AuthorizedApi.confirmEmail(email)
    .then(() => toast.warn(EMAIL_CONFIRMATION_SENT))
    .catch(error => {
      if (error.message === API_ERROR_MESSAGES.userEmailAlreadyRegistered) {
        toast.error(UI_ERROR_MESSAGES[API_ERROR_MESSAGES.userEmailAlreadyRegistered]);
      }
    }),

  saveProfile: async props => {
    const { externalProfileId } = user;
    const { photo, userDocuments, id, initialUserFiles, currentPassword, newPassword } = props;

    if (newPassword && (!!externalProfileId || currentPassword)) {
      try {
        await AuthorizedApi.changePassword(id, currentPassword, newPassword);
        toast.success(PASSWORD_CHANGED_SUCCESSFULLY);
      } catch (error) {
        const { message } = error;

        if (error.message === 'The password is incorrect') {
          document.getElementById('currentPassword')
            .scrollIntoView({ behavior: 'smooth', block: 'center' });

          throw new SubmissionError({ currentPassword: UI_ERROR_MESSAGES[message] });
        }

        showError(error, UI_DEFAULT_ERROR_MESSAGES.server);
        throw error;
      }
    }

    if (photo || userDocuments) {
      const files = [
        ...(photo ? [{ ...photo, title: 'photo' }] : []),
        ...(userDocuments ? Object.keys(userDocuments)
          .filter(docName => docName !== '__typename' && userDocuments[docName])
          .map(docName => ({ ...userDocuments[docName], title: docName })) : []),
      ];

      if (files.length > 0 || initialUserFiles.length > 0) {
        const filesDelete = getGraphqlFilesToDelete(initialUserFiles, files.map(file => file.id));
        const changedFiles = await insertFiles({
          variables: {
            filesInsert: getGraphqlFiles(files),
            filesDelete: filesDelete.length > 0 ? filesDelete : [],
          },
        });

        props.files = changedFiles.data.insert_file.returning;
      }
    }

    return updateProfile({
      variables: {
        id,
        user: getGraphqlUser(props),
        userRegionsDelete: getUserRegionsToDelete(props),
        userRegionsInsert: getGraphqlUserRegions(props),
        userObjectTypesDelete: getUserObjectTypesToDelete(props),
        userObjectTypesInsert: getGraphqlUserObjectTypes(props),
        userSpecialtiesDelete: getUserSpecialtiesToDelete(props),
        userSpecialtiesInsert: getGraphqlUserSpecialties(props),
        userProjectComponentsDelete: getUserProjectComponentsToDelete(props),
        userProjectComponentsInsert: getGraphqlUserProjectComponents(props),
        userDocuments: getGraphqlUserDocs(props),
        userChiefEngineerExpertiseExperienceInsert: getUserChiefEngineerExpertiseExperience(props),
        userChiefEngineerExpertiseExperienceDelete: getUserChiefEngineerExpertiseExperienceToDelete(props),
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }

      return result;
    }).catch(async error => {
      if (/^.*(email) .*(registered).*/.test(error)) {
        scrollToEmailField();
        throw new SubmissionError({ email: UI_ERROR_MESSAGES[API_ERROR_MESSAGES.userEmailAlreadyRegistered] });
      } else if (/^.*(email) .*(unconfirmed).*/.test(error)) {
        scrollToEmailField();
        throw new SubmissionError({ email: UI_ERROR_MESSAGES[API_ERROR_MESSAGES.unconfirmedEmailExists] });
      } else {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        throw error;
      }
    });
  },
  onChangeIsPublic: isPublic => updateProfileIsPublic({
    variables: {
      id: user.id,
      isPublic,
    },
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
  }),
}));

const withGetProfileQuery = graphql(GET_PROFILE_QUERY, {
  name: 'getProfile',
  options: props => ({
    variables: {
      id: props.user.id,
    },
  }),
  props: ({ getProfile, ownProps: { userRoles } }) => {
    const {
      user = [],
      regions = [],
      specialties = [],
      objectTypes = [],
      error,
      loading,
    } = getProfile;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const currentUser = formatUser(user[0], userRoles);

    return {
      isLoading: loading,
      user: currentUser,
      userRoles,
      regions,
      specialties,
      objectTypes,
    };
  },
});

const withGetChiefEngineerExpertiseExperienceQuery = graphql(GET_CHIEF_ENGINEER_EXPERTISE_EXPERIENCE_QUERY, {
  name: 'getChiefEngineerExpertiseExperience',
  props: ({ getChiefEngineerExpertiseExperience }) => {
    const { chiefEngineerExpertiseExperience = [], error } = getChiefEngineerExpertiseExperience;
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      chiefEngineerExpertiseExperience,
    };
  },
});

const withProfileSubscription = graphql(ON_PROFILE_UPDATE_SUBSCRIPTION, {
  name: 'profileSub',
  skip: ({ user }) => !user?.id,
  options: props => ({
    variables: {
      id: props.user.id,
    },
  }),
  props: ({ profileSub: { user = {} }, ownProps }) => {
    const { photo, userDocuments } = user;

    return {
      user: {
        ...ownProps.user,
        ...formatUser(user, ownProps.userRoles),
        initialUserFiles: [
          ...(photo ? [photo.id] : []),
          ...(userDocuments
            ? Object.keys(userDocuments)
              .filter(docName => docName !== '__typename' && userDocuments[docName])
              .map(docName => userDocuments[docName].id)
            : []),
        ],
      },
    };
  },
});

export default compose(
  withUpdateProfileMutation,
  withUpdateProfileIsPublicMutation,
  withInsertFilesMutation,
  connect(mapStateToProps),
  defaultProps({
    fullUserRoles: true,
  }),
  withContractDetailsReferencesQuery,
  withUserReferencesQuery,
  withGetChiefEngineerExpertiseExperienceQuery,
  withGetProfileQuery,
  withUserBalanceReferencesSubscription,
  withProfileSubscription,
  mergeProps,
)(Profile);
