import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

export default class Watermark extends PureComponent {
  render() {
    const { position, filePath } = this.props;

    return <div className={classNames('watermark-container', 'watermark-' + position)}>
      <img src={filePath} alt="watermark" />
    </div>;
  }

  static propTypes = {
    position: PropTypes.string,
    filePath: PropTypes.string,
  };

  static defaultProps = {
    position: 'bottom-right',
    filePath: process.env.PUBLIC_URL + '/img/watermark.svg',
  };
}
