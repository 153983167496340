import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../../../layout/menu/container';
import { ICONS } from '../../../utils/icons';
import Button from '../../base/button';

import './index.scss';

export default class TasksHeader extends PureComponent {
  handleCreateTaskModal = () => this.props.onClickAddTask();

  render() {
    return <div className="tasks-header">
      <div className="page-header-menu">
        <Menu items={this.props.menu} isTabMenu />
        <Button
          IconComponent={ICONS.circlePlus}
          onClick={this.handleCreateTaskModal}
        >
          Добавить задачу
        </Button>
      </div>
    </div>;
  }

  static propTypes = {
    menu: PropTypes.array.isRequired,
    onClickAddTask: PropTypes.func.isRequired,
  };
}
