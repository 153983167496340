import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getFormattedDate } from '../../../utils/date';
import Container from '../../base/container';
import DataGroup from '../../base/dataGroup';
import Button from '../../base/button';
import Section from '../../base/section';
import Confirm2OptionsModal from '../../modals/confirm/2options';
import { getWarningUnpinObjectSchema } from '../../modals/confirm/2options/schemas';
import { INFO_MESSAGES } from '../../../utils/messages';

import './index.scss';

export default class TariffPlan extends React.PureComponent {
  warningModalRef = React.createRef();

  selectedObjectId = null;

  renderHeader = ({ name, isPaid, isPrepaid, userTariffPlanExpanded: { validUntil } }) => <div className="user-tariff-plan-header">
    <h4>{name}</h4>
    <DataGroup
      title="Срок действия тарифного плана"
      className={classNames('validity', { expired: new Date(validUntil).getTime() < Date.now() })}
    >
      {isPaid || !isPrepaid ? getFormattedDate(validUntil) : <span className="colored red">не оплачен</span>}
    </DataGroup>
  </div>;

  handleClick(id, name) {
    if (name) {
      this.selectedObjectId = id;
      this.toggleWarningModal();
      return;
    }
    this.props.onClickObject(id, this.props.tariffPlan.id);
  }

  toggleWarningModal = () => {
    this.warningModalRef.current && this.warningModalRef.current.toggleModal();
  };

  handleWarningSubmit = () => {
    const { tariffPlan: { id }, onClickObject } = this.props;

    if (this.selectedObjectId) {
      onClickObject(this.selectedObjectId, id);
    }

    this.toggleWarningModal();
  };

  renderChild = ({ id: tariffId, objectCount, objects }) => {
    const emptySlotsCount = objectCount - objects.length;

    return <>
      <h6>Доступные для использования слоты:</h6>
      {objects.concat(Array(emptySlotsCount > 0 ? emptySlotsCount : 0).fill({}))
        .map(({ id, name, code }, index) => <Section key={index}>
          <p className="object-name">{`${index + 1}. ${id ? `${name} (${code})` : 'Свободен'}`}</p>
          <div className="action-buttons">
            <Button
              onClick={() => this.handleClick(id, name)}
              isFilled={!id}
            >
              {name ? 'Открепить объект' : 'Создать объект'}
            </Button>
            {!id && <Button onClick={() => this.props.onClickAssignObject(tariffId)}>
              Прикрепить объект
            </Button>}
          </div>
        </Section>)}
    </>;
  };

  render() {
    const { tariffPlan, isExpanded, setItemState } = this.props;
    const { userTariffPlanExpanded: { isActive } } = tariffPlan;

    return tariffPlan && <Container
      className="user-tariff-plan-item"
      isDefaultExpanded={isExpanded}
      shareExpandedState={setItemState}
      isExpansionDisabled={(tariffPlan.isPrepaid && !tariffPlan.isPaid) || !isActive}
      headerContent={this.renderHeader(tariffPlan)}
      showToggle={(!tariffPlan.isPrepaid || tariffPlan.isPaid) && isActive}
    >
      {this.renderChild(tariffPlan)}
      <Confirm2OptionsModal
        onRef={this.warningModalRef}
        {...getWarningUnpinObjectSchema(this.handleWarningSubmit, this.toggleWarningModal)}
        info={INFO_MESSAGES.keepObjectData}
      />
    </Container>;
  }

  static propTypes = {
    tariffPlan: PropTypes.object,
    isExpanded: PropTypes.bool,
    setItemState: PropTypes.func,
    onClickObject: PropTypes.func.isRequired,
    onClickAssignObject: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tariffPlan: null,
    setItemState() {},
    isExpanded: false,
  };
}
