import moment from 'moment';
import pickBy from 'lodash/pickBy';

import {
  CONTRACT_DETAIL_TYPE,
  ORGANIZATION_CONTRACT_DETAIL_NAME,
  SELF_CONTRACT_DETAIL_NAME,
} from '../../utils/contractDetailType';
import { getFullName, getContractorName } from '../../utils/name';
import { denormalizeMoney } from '../../utils/money';
import { GENITIVE_VACANCY_TYPE, INSTRUMENTAL_VACANCY_TYPE, VACANCY_TYPE } from '../../utils/vacancyType';
import { convertRating, formatRating, formatEvaluation } from './rating';
import { getFormattedExperience } from '../../utils/date';
import { CONTRACT_PHASES } from '../../utils/phase';

const ORGANIZATION_IDENTIFIER = 'organization-';

const getContractDetailLabel = (contractDetails, type) => {
  if (!contractDetails) {
    return null;
  }
  return type === CONTRACT_DETAIL_TYPE.self ? getFullName(contractDetails) + getTaxSystemName(contractDetails)
    : contractDetails.name + getTaxSystemName(contractDetails);
};

const getContractDetailTypes = contractDetails => {
  const { selfContractDetails, organizationContractDetails } = contractDetails;
  return Object.keys(CONTRACT_DETAIL_TYPE).reduce((result, name) => {
    const currentContractDetails = name === SELF_CONTRACT_DETAIL_NAME ? selfContractDetails : organizationContractDetails;

    if (currentContractDetails) {
      const contractDetailLabel = getContractDetailLabel(currentContractDetails, CONTRACT_DETAIL_TYPE[name]);

      if (contractDetailLabel !== 'null') {
        result.push({
          value: name,
          label: CONTRACT_DETAIL_TYPE[name] + ' - ' + contractDetailLabel,
          isOrganization: name === ORGANIZATION_CONTRACT_DETAIL_NAME,
          taxSystemId: currentContractDetails.taxSystemId,
          taxSystemName: currentContractDetails.taxSystem?.name,
        });
      }
    }

    return result;
  }, []);
};

const getTaxSystemName = contractDetails => {
  const name = contractDetails.taxSystem?.name;
  return name ? ` (${name})` : '';
};

const clearContractDetails = ({ __typename, taxSystem, ...contractDetails }) => contractDetails;

export const getSelectedEmployee = (selectedUser, favoriteUsers) => {
  if (!selectedUser) {
    return {};
  }
  const regexp = new RegExp(`^${ORGANIZATION_IDENTIFIER}(\\d+)$`);
  const isOrganization = regexp.test(selectedUser);
  const employeeUserId = isOrganization ? Number(selectedUser.replace(regexp, '$1')) : selectedUser;

  const selectedFavoriteUser = favoriteUsers.find(({ favoriteUser }) => favoriteUser.id === employeeUserId).favoriteUser;
  let employeeContractDetails;

  if (!selectedFavoriteUser.organizationContractDetails && !selectedFavoriteUser.selfContractDetails) {
    const contractDetailsFields = ['id', 'firstName', 'lastName', 'patronymic', 'email', 'phone', 'isEmailConfirmed', 'isPhoneConfirmed'];

    employeeContractDetails = {
      [SELF_CONTRACT_DETAIL_NAME]: pickBy(selectedFavoriteUser, (value, key) => !!value && contractDetailsFields.indexOf(key) > -1),
      isOrganization: false,
    };
  } else if (isOrganization) {
    employeeContractDetails = {
      [ORGANIZATION_CONTRACT_DETAIL_NAME]: clearContractDetails(selectedFavoriteUser.organizationContractDetails),
      isOrganization: true,
    };
  } else {
    employeeContractDetails = {
      [SELF_CONTRACT_DETAIL_NAME]: clearContractDetails(selectedFavoriteUser.selfContractDetails),
      isOrganization: false,
    };
  }

  return { employeeUserId, employeeContractDetails };
};

export const getContractDetailsList = contractDetails =>
  contractDetails && contractDetails.length > 0 ? getContractDetailTypes(contractDetails[0]) : [];

export const getFavoriteContractDetails = users => {
  const contractDetails = [];
  (users || []).forEach(({ favoriteUser }) => {
    const { selfContractDetails, organizationContractDetails } = favoriteUser;

    if (selfContractDetails && selfContractDetails.firstName && selfContractDetails.lastName) {
      contractDetails.push({
        label: getContractDetailLabel(selfContractDetails, CONTRACT_DETAIL_TYPE.self),
        value: favoriteUser.id,
        isRequisitesFilled: true,
      });
    }
    if (organizationContractDetails && organizationContractDetails.name) {
      contractDetails.push({
        label: getContractDetailLabel(organizationContractDetails, CONTRACT_DETAIL_TYPE.organization),
        value: ORGANIZATION_IDENTIFIER + favoriteUser.id,
        isRequisitesFilled: true,
      });
    } else if (!selfContractDetails && !organizationContractDetails) {
      contractDetails.push({
        label: getFullName(favoriteUser) || favoriteUser.email,
        value: favoriteUser.id,
        isRequisitesFilled: false,
      });
    }
  });
  return contractDetails;
};

export const getSelectedContractDetails = (
  {
    firstName,
    lastName,
    patronymic,
    selfContractDetails,
    organizationContractDetails,
  },
  contractDetailType,
) => {
  let contractDetails;
  if (contractDetailType === SELF_CONTRACT_DETAIL_NAME) {
    contractDetails = {
      [SELF_CONTRACT_DETAIL_NAME]: clearContractDetails(selfContractDetails),
      isOrganization: false,
    };
  } else if (contractDetailType === ORGANIZATION_CONTRACT_DETAIL_NAME) {
    contractDetails = {
      [ORGANIZATION_CONTRACT_DETAIL_NAME]: clearContractDetails(organizationContractDetails),
      isOrganization: true,
    };
  } else {
    contractDetails = {
      [SELF_CONTRACT_DETAIL_NAME]: {
        firstName,
        lastName,
        patronymic,
      },
    };
  }
  return contractDetails;
};

export const prepareToInsertContract = ({
  employeeUserId,
  employeeContractDetails,
  vacancyTypeId,
  customerContractDetails,
  contractDetailType,
  isSafeDeal,
  paymentTerms,
  competitionTerms,
  totalAmount,
}) => {
  const selectedContractDetails = getSelectedContractDetails(customerContractDetails, contractDetailType);

  if (!employeeUserId) {
    if (!competitionTerms) {
      competitionTerms = {};
    }
    competitionTerms.isOpen = true;
  }

  return {
    employeeUserId,
    customerContractDetails: selectedContractDetails,
    employeeContractDetails,
    vacancyTypeId,
    isSafeDeal,
    paymentTerms,
    competitionTerms,
    ...(totalAmount ? { totalAmount: denormalizeMoney(totalAmount) } : {}),
  };
};

export const getContractorFromVacancy = vacancy => {
  if (!vacancy) {
    return null;
  }

  const {
    id: vacancyId,
    vacancyExpanded,
    contractId,
    isActive,
    isBreakPending,
    isPerformer,
    contract,
    workAcceptances,
    createdAt: vacancyCreatedAt,
  } = vacancy;
  const { employeeUserId: userId, employeeContractDetails } = vacancyExpanded || contract;

  const {
    isCompetitionStarted,
    employeeRating,
    paymentTerms,
    status,
    vacancyType,
    customerContractDetails,
    phase,
    phaseDeadline,
    termPenaltyDeadline,
    arbitration,
  } = contract;

  const employee = {
    vacancyCreatedAt,
    workAcceptances,
    isActive,
    userId,
    contractId: contractId || contract.id,
    vacancyId,
    isBreakPending,
    isCompetitionStarted,
    isPerformer,
    currentPhase: phase
      ? {
        ...phase,
        phaseDeadline,
        termPenaltyDeadline,
        isExpertisePhase: paymentTerms?.isExpertiseRequired && phase.key === CONTRACT_PHASES.phase2,
      }
      : null,
    role: {
      id: vacancyType.userRole.id,
      accusative: vacancyType.userRole.name,
      genitive: GENITIVE_VACANCY_TYPE[Object.keys(VACANCY_TYPE).find(type => VACANCY_TYPE[type] === vacancyType.userRole.name)],
      instrumental: INSTRUMENTAL_VACANCY_TYPE[Object.keys(VACANCY_TYPE).find(type => VACANCY_TYPE[type] === vacancyType.userRole.name)],
    },
    ...(customerContractDetails
      ? { customer: customerContractDetails.isOrganization ? customerContractDetails.organization : customerContractDetails.self }
      : {}),
  };

  if (userId && employeeContractDetails) {
    const { isOrganization, organization, self } = employeeContractDetails;

    Object.assign(employee,
      isOrganization ? organization : self,
      {
        isOrganization,
        rating: formatRating(employeeRating)[VACANCY_TYPE.performer],
        paymentTerms,
        contractStatus: status,
        arbitration,
      });
  }
  return employee;
};

export const getContractorFromContract = (contract, isCustomer, role) => {
  const contractorRole = isCustomer ? 'employee' : 'customer';
  const contractDetails = contract[`${contractorRole}ContractDetails`];
  if (!contractDetails) {
    return {};
  }
  const rating = formatRating(contract[`${contractorRole}Rating`])[role];

  const contractor = {
    isActive: contract.status?.isActive,
    userId: contract[`${contractorRole}UserId`],
    contractId: contract.id,
    isBreakPending: contract.vacancies.some(vacancy => vacancy.isBreakPending),
    rating,
    evaluation: formatEvaluation(contract, !isCustomer),
  };
  const { isOrganization, organization, self } = contractDetails;
  contractor.isOrganization = isOrganization;
  Object.assign(contractor, isOrganization ? organization : self);
  contractor.generatedName = getContractorName(contractor, false);
  return contractor;
};

export const getEmployee = (user, contractDetailType) => {
  const {
    id,
    firstName,
    lastName,
    patronymic,
    createdAt: favoriteUserCreatedAt,
    organizationContractDetails,
    selfContractDetails,
    objectTypes,
    specialties,
    userRegions,
    userProjectComponents,
    email: userEmail,
    isPublic,
    ratings,
  } = user;
  let contractDetails;
  // if participant has no access to another participant because of private profile
  if (!contractDetailType || !(selfContractDetails || organizationContractDetails)) {
    const { name, taxSystem } = organizationContractDetails || {};
    contractDetails = {
      firstName,
      lastName,
      patronymic,
      companyName: name,
      taxSystemName: taxSystem?.name,
    };
  } else if (contractDetailType === ORGANIZATION_CONTRACT_DETAIL_NAME) {
    contractDetails = {
      firstName: organizationContractDetails.firstName,
      lastName: organizationContractDetails.lastName,
      patronymic: organizationContractDetails.patronymic,
      companyName: organizationContractDetails.name,
      taxSystemName: organizationContractDetails.taxSystem?.name,
    };
  } else {
    contractDetails = {
      firstName: selfContractDetails.firstName,
      lastName: selfContractDetails.lastName,
      patronymic: selfContractDetails.patronymic,
      taxSystemName: selfContractDetails.taxSystem?.name,
    };
  }

  return {
    id,
    ...contractDetails,
    ratings: {
      ...formatRating(ratings),
      avg: convertRating(ratings[0]?.ratingExpanded?.avg || 0),
    },
    specialtyTypes: (specialties || []).map(({ specialty: { name } }) => name),
    objectTypes: (objectTypes || []).map(({ objectType: { name } }) => name),
    projectComponents: (userProjectComponents || []).map(({ projectComponent: { code } }) => code),
    regions: (userRegions || []).map(({ region: { name } }) => name),
    experience: getFormattedExperience(moment().diff(moment(favoriteUserCreatedAt), 'months')),
    email: userEmail,
    isPublic,
  };
};
