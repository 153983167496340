import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Menu from 'react-burger-menu/lib/menus/slide';

import MenuItems from '../menu/container';
import ObjectMenu from '../objectMenu/container';
import { getFilteredMenu, isObjectMenu } from '../../utils/menu';
import { ICONS } from '../../utils/icons';

import './index.scss';

export default class SideBar extends PureComponent {
  handleStateChange = ({ isOpen }) => {
    // TODO: remove selector
    const bodyClassList = document.querySelector('body')?.classList;

    if (bodyClassList) {
      bodyClassList[isOpen ? 'add' : 'remove']('no-scroll');
    }

    this.props.setSideBarVisibility(isOpen);
  };

  handleSelectMenuItem = () => {
    this.props.setSideBarVisibility(false);
  };

  render() {
    const { location, isMenuOpen, ...props } = this.props;

    return <Menu
      className="sidebar"
      customCrossIcon={React.createElement(ICONS.close)}
      isOpen={isMenuOpen}
      onStateChange={this.handleStateChange}
      {...props}
    >
      {isObjectMenu(location.pathname)
        ? <ObjectMenu onClickMenuItem={this.handleSelectMenuItem} />
        : <MenuItems
          items={getFilteredMenu(location.pathname)}
          onClickMenuItem={this.handleSelectMenuItem}
        />}
    </Menu>;
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
    isMenuOpen: PropTypes.bool,
    setSideBarVisibility: PropTypes.func,
  };

  static defaultProps = {
    isMenuOpen: false,
    setSideBarVisibility() {},
  };
}
