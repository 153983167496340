import { reduxForm, change } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ApplicationTerms from './index';
import { getSubmitValidation, getValidation } from '../../../../utils/validation';

const FORM_NAME = 'ApplicationTerms';

const mapDispatchToProps = {
  change,
};

const mergeProps = (stateProps, dispatchProps, { initialValues, isPerformer, ...ownProps }) => {
  if (initialValues.paymentTerms) {
    initialValues.paymentTerms.usePhases = initialValues.paymentTerms.usePhases || isPerformer;

    // insurance in case of failure when creating a vacancy
    if (!initialValues.paymentTerms.phase1) {
      initialValues.paymentTerms.phase1 = {
        deadline: ownProps.object.deadline,
      };
    }
  }

  return {
    ...dispatchProps,
    ...ownProps,
    isPerformer,
    initialValues,
  };
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps, mergeProps),
  reduxForm({
    form: FORM_NAME,
    validate: getValidation([
      'totalAmount',
      'paymentTerms.phase0.rate',
      'paymentTerms.phase1.rate',
      'paymentTerms.phase2.rate',
      'paymentTerms.phase3.rate',
      'paymentTerms.phase0.termLimit',
      'paymentTerms.remarksCreateTermLimit',
      'paymentTerms.remarksFixTermLimit',
      'paymentTerms.phase2.termLimit',
      'paymentTerms.phase3.termLimit',
      'paymentTerms.phase2.negativeExpertiseFine',
      'paymentTerms.month.paymentTermLimit',
      'paymentTerms.month.paymentDelayFine',
      'paymentTerms.worksDelayPenalty',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(ApplicationTerms);
