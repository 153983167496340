import difference from 'lodash/difference';

export const getGraphqlUserProjectComponents = ({ userProjectComponents, id }) => userProjectComponents
  ? userProjectComponents.map(projectComponentId => ({
    projectComponentId,
    userId: id,
  }))
  : [];

export const getUserProjectComponentsToDelete = ({ initialUserProjectComponents, userProjectComponents }) =>
  initialUserProjectComponents ? difference(initialUserProjectComponents, userProjectComponents) : [];
