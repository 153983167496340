import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import RatingsList from '../../../components/rating/list';
import RatingsListHeader from '../../../components/rating/list/header';
import Menu from '../../../components/base/menu';
import InfoModal from '../../../components/modals/info';
import { FAVORITE_ADDED } from '../../../utils/messages';
import InviteToContractModal from '../../../components/modals/inviteToContract/container';

import './index.scss';

export default class Ratings extends PureComponent {
  isSearchApplied = false;

  engineerAddedModalRef = React.createRef();
  ratingsListRef = React.createRef();
  inviteToContractModalRef = React.createRef();

  state = {
    order: null,
    performerGroups: [],
    invitedUserId: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { performerRatingGroups } = nextProps;
    const { performerGroups } = prevState;

    if (performerRatingGroups.length > 0 && performerRatingGroups.length !== performerGroups.length) {
      return {
        ...prevState,
        performerGroups: performerRatingGroups.map(({ id, name }) => ({
          id,
          title: name,
        })),
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { sort } = this.props;
    const { order } = this.state;

    if (!isEqual(order, prevState.order)) {
      sort(order);
    }
  }

  handleClickAddToFavorite = async email => {
    await this.props.addToFavorites(email);
    this.engineerAddedModalRef.current.toggleModal();
  };

  handleOnLoadMore = () => {
    const { fetchMore, ratings } = this.props;
    fetchMore(ratings.length);
  };

  setOrder = order => {
    this.setState({ order: { ...order } });

    if (order !== this.state.order) {
      this.props.sort(order);
    }
  };

  handleSearch = value => {
    this.props.search(value);
    this.isSearchApplied = !!value;
  };

  handleChangeGroup = index => {
    this.props.group(this.state.performerGroups[index].id);
    this.ratingsListRef.current.resetState();
  };

  handleInviteToContract = userId => {
    this.setState({ invitedUserId: userId });
    this.inviteToContractModalRef.current.toggleModal();
  }

  render() {
    const {
      count,
      isLoading,
      orderBy,
      ratings,
      currentRatingOrder,
      currentPerformerGroupId,
      userId,
    } = this.props;
    const { performerGroups, invitedUserId } = this.state;

    return <>
      {performerGroups.length > 0 && <Menu
        className="performer-group-menu"
        items={performerGroups}
        setActive={index => currentPerformerGroupId === performerGroups[index].id}
        onClick={this.handleChangeGroup}
        isTabMenu
      />}
      <RatingsListHeader
        setOrder={this.setOrder}
        onSearch={this.handleSearch}
        orderBy={orderBy}
      />
      <RatingsList
        onRef={this.ratingsListRef}
        userId={userId}
        items={ratings}
        count={count}
        isLoading={isLoading}
        currentRatingOrder={currentRatingOrder}
        isSearchApplied={this.isSearchApplied}
        onLoadMore={this.handleOnLoadMore}
        onClickAddToFavorite={this.handleClickAddToFavorite}
        onClickInviteToContract={this.handleInviteToContract}
        onClickRecommend={noop}
      />
      <InfoModal onRef={this.engineerAddedModalRef} message={FAVORITE_ADDED} />
      <InviteToContractModal onRef={this.inviteToContractModalRef} invitedUserId={invitedUserId} />
    </>;
  }

  static propTypes = {
    userId: PropTypes.number.isRequired,
    sort: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    group: PropTypes.func.isRequired,
    ratings: PropTypes.array,
    performerRatingGroups: PropTypes.array,
    currentRatingOrder: PropTypes.string,
    currentPerformerGroupId: PropTypes.number,
    count: PropTypes.number,
    isLoading: PropTypes.bool,
    fetchMore: PropTypes.func.isRequired,
    orderBy: PropTypes.object,
    addToFavorites: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    ratings: [],
    performerRatingGroups: [],
    currentRatingOrder: '',
    currentPerformerGroupId: 0,
    count: 0,
    orderBy: {},
  };
}
