import { reduxForm, SubmissionError } from 'redux-form';
import { compose, withProps } from 'recompose';

import { getSubmitValidation, getValidation } from '../../../utils/validation';
import AddToFavoritesModal from './index';
import AuthorizedApi from '../../../api/authorized';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES, UI_ERROR_MESSAGES } from '../../../utils/messages';

const FORM_NAME = 'addToFavoritesModal';

const mergeProps = withProps(({ isPerformer, onSuccess, onChoice, ...props }) => ({
  ...props,
  onSubmit: async ({ email }) => {
    let favoriteUserId = null;

    try {
      favoriteUserId = await AuthorizedApi.checkFavoriteUser(email, isPerformer);
    } catch (error) {
      if (error.statusCode === 409) {
        throw new SubmissionError({ email: UI_ERROR_MESSAGES[error.message] });
      } else if (error.statusCode !== 404) {
        return showError(error, UI_DEFAULT_ERROR_MESSAGES.addToFavorites);
      }
    }

    if (favoriteUserId) {
      try {
        const result = await AuthorizedApi.addFavorite(email, isPerformer);
        onSuccess(result);
      } catch (error) {
        return showError(error, UI_DEFAULT_ERROR_MESSAGES.sendInvitationToFavorites);
      }
    } else {
      onChoice(email);
    }
  },
}));

export default compose(
  mergeProps,
  reduxForm({
    form: FORM_NAME,
    validate: getValidation(['email']),
    onSubmitFail: getSubmitValidation,
  }),
)(AddToFavoritesModal);
