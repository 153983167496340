import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { showError } from '../../../api/error';
import UserTariffPlans from './index';
import { formatTariffPlans } from '../../../graphql/model/tariff';
import { ROUTES } from '../../../utils/routes';
import { deepArrayMerge } from '../../../utils/merge';

const GET_USER_TARIFF_PLANS_QUERY = loader('../../../graphql/queries/tariff/get.graphql');
const ON_USER_TARIFF_PLANS_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/tariff/subscription.graphql');
const UPDATE_OBJECT_MUTATION = loader('../../../graphql/queries/object/update.graphql');

const withUpdateObjectMutation = graphql(UPDATE_OBJECT_MUTATION, {
  name: 'updateObject',
});

const mapStateToProps = ({ session }) => ({
  userId: session.user?.id,
});

const withGetUserTariffPlansQuery = graphql(GET_USER_TARIFF_PLANS_QUERY, {
  name: 'getUserTariffPlans',
  skip: ({ userId }) => !userId,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getUserTariffPlans }) => {
    const {
      items = [],
      error,
      loading: isLoading,
    } = getUserTariffPlans;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const formattedTariffPlans = formatTariffPlans(items);

    return {
      tariffPlans: formattedTariffPlans,
      isLoading,
    };
  },
});

const withUserTariffPlansSubscription = graphql(ON_USER_TARIFF_PLANS_UPDATE_SUBSCRIPTION, {
  name: 'userTariffPlansSub',
  skip: ({ userId }) => !userId,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
  }),
  props: ({ userTariffPlansSub: { items = [] }, ownProps: { tariffPlans } }) => ({
    tariffPlans: deepArrayMerge(tariffPlans, items),
  }),
});

const mergeProps = withProps(({ updateObject, history }) => ({
  onClickObject: (id, tariffPlanId) => id
    ? updateObject({
      variables: {
        id,
        object: {
          userTariffPlanId: null,
        },
        stages: [],
      },
    })
    : history.push(ROUTES.myObjects, { tariffPlanId }),
}));

export default compose(
  connect(mapStateToProps),
  withGetUserTariffPlansQuery,
  withUserTariffPlansSubscription,
  withUpdateObjectMutation,
  withRouter,
  mergeProps,
)(UserTariffPlans);
