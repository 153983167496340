import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ContractFilesList from './files/list';
import Button from '../../base/button';
import { ICONS } from '../../../utils/icons';
import ContractFileWarningModal from '../../modals/contractFileWarning';
import AddContractFileModal from '../../modals/addContractFile/container';

import './index.scss';

export default class ContractDocumentation extends PureComponent {
  state = {
    item: {},
  };

  contractFileWarningRef = React.createRef();
  addContractFileRef = React.createRef();

  handleAddFileModal = () => {
    this.addContractFileRef.current.toggleModal();
  };

  handleOpenWarningModal = item => {
    this.setState({ item }, this.contractFileWarningRef.current.toggleModal);
  };

  handleRemoveItem = () => {
    const { item } = this.state;
    const { onRemoveFile, onRemoveSign } = this.context;

    return item.isSign ? onRemoveSign(item) : onRemoveFile(item);
  };

  render() {
    const { contractFileTypes, contractId, objectProjectComponentId, isTariffActive } = this.props;
    const { isAddFileSectionShown, file, item: { isSign } } = this.state;

    return <div className="documentation-layout">
      <ContractFilesList
        objectProjectComponentId={objectProjectComponentId}
        fileTypes={contractFileTypes}
        onClickRemove={this.handleOpenWarningModal}
      />
      {isTariffActive && <Button
        onClick={this.handleAddFileModal}
        IconComponent={ICONS.circlePlus}
        isBorderless
        isDisabled={isAddFileSectionShown && !file.remotePath}
      >
        Добавить документ
      </Button>}
      <AddContractFileModal onRef={this.addContractFileRef} contractId={contractId} />
      <ContractFileWarningModal onRef={this.contractFileWarningRef} isSign={isSign} onDelete={this.handleRemoveItem} />
    </div>;
  }

  static contextTypes = {
    onRemoveSign: PropTypes.func.isRequired,
    onRemoveFile: PropTypes.func.isRequired,
  };

  static propTypes = {
    contractId: PropTypes.number.isRequired,
    isTariffActive: PropTypes.bool.isRequired,
    objectProjectComponentId: PropTypes.number.isRequired,
    contractFileTypes: PropTypes.object.isRequired,
  };
}
