import { loader } from 'graphql.macro';
import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';
import { matchPath } from 'react-router-dom';
import { connect } from 'react-redux';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { showError } from '../../../api/error';
import Contracts from './index';
import {
  formatPublicProfileContracts,
  getPublicProfileContractsQuery,
} from '../../../graphql/model/contracts';
import { ROUTES } from '../../../utils/routes';
import { VACANCY_TYPE } from '../../../utils/vacancyType';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const ORDER_BY = {
  createdAt: {
    title: 'по дате',
    graphql: { createdAt: 'desc' },
  },
};

const PUBLIC_PROFILE_CONTRACTS_SUBSCRIPTION = loader('../../../graphql/queries/contract/publicProfileContractsListSubscription.graphql');
const PUBLIC_PROFILE_CONTRACTS_COUNT_SUBSCRIPTION = loader('../../../graphql/queries/contract/countPublicProfileContractsSubscription.graphql');

const mapStateToProps = ({ session }) => ({
  userId: session.user?.id,
});

const mergeProps = withProps(({ location, match }) => {
  const isCurrent = !!matchPath(location.pathname, ROUTES.currentProfileContracts);
  const employeeUserId = Number(match.params.id);
  const filterBy = vacancyType => getPublicProfileContractsQuery({ employeeUserId, isCurrent, vacancyType });

  return {
    isCurrent,
    employeeUserId,
    filterBy,
    where: filterBy(VACANCY_TYPE.chief),
  };
});

const withGetPublicProfileContractsSub = withResubscribe(PUBLIC_PROFILE_CONTRACTS_SUBSCRIPTION, {
  name: 'publicProfileContractsSub',
  skip: ({ employeeUserId }) => !employeeUserId,
  shouldResubscribe: true,
  options: ({ where, offset, limit, order }) => ({
    variables: {
      where,
      offset,
      limit,
      order,
    },
  }),
  props: ({ publicProfileContractsSub: { contracts = [], error, loading: isLoading }, ownProps: { userId } }, previousResult) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      // we return previous messages if resubscribe was started (has 0 messages on loading moment)
      contracts: isLoading && previousResult?.contracts.length > 0 ? previousResult.contracts : formatPublicProfileContracts(contracts, userId),
      isContractsLoading: isLoading,
    };
  },
});

const withGetPublicProfileContractsCountSub = withResubscribe(PUBLIC_PROFILE_CONTRACTS_COUNT_SUBSCRIPTION, {
  name: 'publicProfileContractsCountSub',
  skip: ({ employeeUserId }) => !employeeUserId,
  shouldResubscribe: true,
  options: ({ where }) => ({
    variables: {
      where,
    },
  }),
  props: ({ publicProfileContractsCountSub: { count, error, loading: isLoading }, ownProps: { isContractsLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      count: count ? count.aggregate.count : 0,
      isLoading: isContractsLoading && isLoading,
    };
  },
});

export default compose(
  connect(mapStateToProps),
  defaultProps({
    orderBy: ORDER_BY,
    offset: 0,
    limit: 10,
  }),
  mergeProps,
  withStateHandlers(
    ({ limit, orderBy }) => ({ step: limit, order: getOrder(orderBy) }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      filterBy: (_, { filterBy }) => value => ({ where: filterBy(value) }),
    },
  ),
  withGetPublicProfileContractsSub,
  withGetPublicProfileContractsCountSub,
)(Contracts);
