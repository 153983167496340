import { compose } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { reduxForm } from 'redux-form';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import MakeArbitrationDecisionModal from './index';

const GET_CONTRACT_BREAK_CHOICES_QUERY = loader('../../../graphql/queries/contract/getBreakChoices.graphql');
const UPDATE_ARBITRATION_MUTATION = loader('../../../graphql/queries/contract/updateArbitration.graphql');

const withUpdateArbitrationMutation = graphql(UPDATE_ARBITRATION_MUTATION, {
  name: 'updateArbitration',
});

const withGetContractBreakChoicesQuery = graphql(GET_CONTRACT_BREAK_CHOICES_QUERY, {
  name: 'getContractBreakChoices',
  options: ({ contractId }) => ({
    variables: {
      contractId,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }),
  skip: ({ contractId }) => !contractId,
  props: result => {
    const {
      getContractBreakChoices: {
        contractBreakChoices,
        loading,
      },
    } = result;

    return {
      contractBreakChoices,
      isChoicesLoading: loading,
    };
  },
});

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

const mergeProps = (
  { ...mapStateToProps },
  dispatchToProps,
  {
    updateArbitration,
    contractId,
    contractBreakChoices,
    isChoicesLoading,
    ...props
  },
) => {
  const customerBreakChoice = contractBreakChoices?.find(choice => choice.userId === choice.contract.customerUserId)?.option.name;
  const employeeBreakChoice = contractBreakChoices?.find(choice => choice.userId === choice.contract.employeeUserId)?.option.name;
  const initiatorBreakChoice = contractBreakChoices?.[0] || { contract: {} };
  const initiatorReason = initiatorBreakChoice.reason || '';
  const { customerUserId, employeeUserId } = initiatorBreakChoice.contract;

  return {
    ...mapStateToProps,
    ...props,
    contractId,
    initiatorReason,
    customerBreakChoice,
    employeeBreakChoice,
    guiltOptions: ['Виноват заказчик', 'Виноват исполнитель'].map((option, index) => ({
      value: index === 0 ? customerUserId : employeeUserId,
      label: option,
    })),
    isLoading: isChoicesLoading,
    onMakeArbitrationDecision: ({ guiltyUserId }) => updateArbitration({
      variables: {
        contractId,
        arbitration: {
          guiltyUserId,
        },
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  };
};

export default compose(
  withGetContractBreakChoicesQuery,
  withUpdateArbitrationMutation,
  connect(mapStateToProps, null, mergeProps),
  reduxForm({
    form: 'MakeArbitrationDecisionModal',
    validate: getValidation([
      'guiltyUserId',
    ]),
    onSubmitFail: getSubmitValidation,
    enableReinitialize: true,
  }),
)(MakeArbitrationDecisionModal);
