import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import noop from 'lodash/noop';

import Button from '../../../components/base/button';
import Organization from './organizationIdentification';
import BankDetails from './organizationBankDetails';
import { ICONS } from '../../../utils/icons';
import { getSubmitValidation, getValidation } from '../../../utils/validation';

class OrganizationContractDetails extends React.PureComponent {
  render() {
    const { handleSubmit, submitting, taxSystems } = this.props;

    return <Form onSubmit={handleSubmit}>
      <Organization taxSystems={taxSystems} />
      <BankDetails />
      <div className="actions">
        <Button
          isLarge
          isDisabled={submitting}
          isLoading={submitting}
          buttonProps={{
            type: 'submit',
          }}
        >
          Сохранить изменения
        </Button>
        <Button
          className="print"
          onClick={noop}
          IconComponent={ICONS.printer}
          isBorderless
        >
          Версия для печати
        </Button>
      </div>
    </Form>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    taxSystems: PropTypes.array,
  };

  static defaultProps = {
    taxSystems: [],
  };
}

export default reduxForm({
  enableReinitialize: true,
  form: 'organizationContractDetails',
  validate: getValidation([
    'name',
    'email',
    'phone',
    'organizationInn',
    'kpp',
    'ogrn',
    'bankAccount',
    'bankBik',
    'bankName',
    'correspondentAccount',
    'taxSystemId',
  ]),
  onSubmitFail: getSubmitValidation,
})(OrganizationContractDetails);
