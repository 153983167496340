import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setPassword, sendRecoverPasswordMail } from '../../store/actions/session';
import SetPassword from './index';

const mapDispatchToProps = {
  setPassword,
  sendRecoverPasswordMail,
};

export default withRouter(connect(null, mapDispatchToProps)(SetPassword));
