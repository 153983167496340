import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../base/modal';
import Button from '../../base/button';
import { PUBLIC_MENU, ROUTES } from '../../../utils/routes';

import './index.scss';

export default class PublicMenuModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  goToSignUp = () => {
    this.props.history.push(ROUTES.signUp);
    this.props.onRef.current.toggleModal();
  };

  goToSignIn = () => {
    this.props.history.push(ROUTES.signIn);
    this.props.onRef.current.toggleModal();
  };

  handleClickMenuItem = path => {
    window.location.href = process.env.REACT_APP_PROMO_SITE_LINK + path;
    this.props.onRef.current.toggleModal();
  };

  render() {
    const isAuthPage = [ROUTES.signIn, ROUTES.signUp].includes(this.props.location.pathname);

    return <Modal onRef={this.props.onRef} className="public-menu-modal" onClose={this.handleClose}>
      {PUBLIC_MENU.map(({ title, path }, index) =>
        <Button isBorderless onClick={() => this.handleClickMenuItem(path)} key={index}>{title}</Button>)}
      {!isAuthPage && <div className="auth-buttons">
        <Button id='sign-up-button' isBorderless onClick={this.goToSignUp}>Регистрация</Button>
        <Button id='sign-in-button' onClick={this.goToSignIn}>Вход</Button>
      </div>}
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    location: {},
  };
}
