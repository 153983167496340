import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';

export default class EditObjectModal extends React.Component {
  handleClose = this.props.reset;

  componentDidMount() {
    const { initialize, initialValues } = this.props;

    // Config prop "enableReinitialize" doesn't always work in some reasons, so we initialize form manually.
    initialize(initialValues);
  }

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  submit = async props => {
    await this.props.updateObject(props);
    this.handleCancel();
  };

  render() {
    const {
      handleSubmit,
      submitting,
      onRef,
      regions,
      data,
      initialValues,
      stageMinDeadline,
    } = this.props;
    const selectedStages = initialValues?.stages
      ? Object.values(initialValues.stages)
      : [];

    return <Modal
      className="edit-object-modal"
      title="Редактирование информации по объекту"
      onClose={this.handleClose}
      onRef={onRef}
    >
      <Form onSubmit={handleSubmit(this.submit)} className="edit-object-form">
        <Field
          name="name"
          component={FormGroupField}
          type="text"
          label="Наименование объекта"
        />
        <Field
          name="region"
          component={FormGroupField}
          type="select"
          data={regions.map(region => ({ ...region, checked: region.value === data?.regionId }))}
          label="Регион объекта"
        />
        <Field
          name="address"
          component={FormGroupField}
          type="text"
          label="Адрес объекта"
        />
        <Field
          name="code"
          component={FormGroupField}
          type="text"
          label="Шифр"
        />
        {selectedStages.length > 0 && <>
          <span className="stages-deadline-label">Срок окончания работ по Стадии</span>
          {selectedStages.map(({ id, name }, index) => <Field
            key={index}
            className="stage-deadline"
            name={`stageDeadline-${id}`}
            minDate={stageMinDeadline[id]}
            component={FormGroupField}
            type="date"
            label={name}
          />)}
        </>}
        <div className="actions">
          <Button
            className="secondary"
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            className="primary"
            isLoading={submitting}
            isLarge
            buttonProps={{
              type: 'submit',
            }}
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    updateObject: PropTypes.func.isRequired,
    regions: PropTypes.array,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onRef: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    stageMinDeadline: PropTypes.object,
    data: PropTypes.object.isRequired,
  };

  static defaultProps = {
    stageMinDeadline: {},
    regions: [],
  };
}
