import { DOCS } from '../../utils/userDocumentTypes';

export const getGraphqlUserDocs = ({ files, id }) => {
  const result = {};

  (files || []).forEach(file => {
    const { title } = file;

    if (DOCS[title]) {
      result[`${title}Id`] = file.id;
    }
  });

  return { ...result, userId: id };
};
