import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../base/modal';
import Button from '../../../base/button';
import { ICONS } from '../../../../utils/icons';
import { makeCancellable } from '../../../../utils/promise';

import '../index.scss';

export default class Confirm2OptionsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      buttonSuccessIsLoading: false,
      buttonDismissIsLoading: false,
    };

    this.cancellablePromise = null;
  }

  componentWillUnmount() {
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  }

  handleClick = (action, index) => () => {
    const isLoadingKey = `button${index}IsLoading`;
    this.setState({ [isLoadingKey]: true });
    this.cancellablePromise = makeCancellable(action());
    return this.cancellablePromise
      .promise
      .then(() => {
        this.setState({ [isLoadingKey]: false });
      })
      .catch(error => {
        if (error.isCancelled) {
          return null;
        }
        this.setState({ [isLoadingKey]: false });
      });
  };

  render() {
    const { title, onSuccess, successButtonTitle, onDismiss, dismissButtonTitle, hint, children, info, ...props } = this.props;
    const { buttonSuccessIsLoading, buttonDismissIsLoading } = this.state;

    return <Modal
      className="confirm-2-options-modal"
      {...props}
    >
      <h2 className="margin-bottom">{title}</h2>
      <div className="confirm-2-options-modal-body">
        {children}
      </div>
      {info && <div className="info">
        <ICONS.infoBold className="info-icon" /><p>{info}</p>
      </div>}
      <div className="action-buttons">
        <Button
          isFilled
          isLarge
          IconComponent={ICONS.check}
          isLoading={buttonSuccessIsLoading}
          onClick={this.handleClick(onSuccess, 'Success')}
        >
          {successButtonTitle}
        </Button>
        <Button
          isLarge
          IconComponent={ICONS.notInterested}
          isLoading={buttonDismissIsLoading}
          onClick={this.handleClick(onDismiss, 'Dismiss')}
        >
          {dismissButtonTitle}
        </Button>
      </div>
      {hint && <p className="hint">{hint}</p>}
    </Modal>;
  }

  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    children: PropTypes.node,
    successButtonTitle: PropTypes.string.isRequired,
    dismissButtonTitle: PropTypes.string.isRequired,
    hint: PropTypes.string,
    info: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: '',
    hint: '',
    info: '',
  };
}
