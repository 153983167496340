import { compose, defaultProps, withProps } from 'recompose';

import Contracts from './index';
import withUserReferencesQuery from '../../graphql/hoc/userReferences';
import { ROUTES } from '../../utils/routes';
import { ICONS } from '../../utils/icons';
import { MENU_ITEMS } from '../../utils/menu';
import React from 'react';

const USER_ROLES_DATA = {
  1: {
    icon: ICONS.engineer,
    title: 'Главные инженеры',
  },
  2: {
    icon: ICONS.leadEngineer,
    title: 'Ведущие инженеры',
  },
  3: {
    icon: ICONS.performer,
    title: 'Исполнители',
  },
  4: {
    icon: ICONS.businessman,
    title: 'Заказчики',
  },
};

const mergeProps = withProps(({ userRoles, isUserReferencesLoading }) => {
  const menuItem = MENU_ITEMS.mainMenu.find(menuItem => menuItem.route === ROUTES.ratings).children[0];

  return {
    isLoading: isUserReferencesLoading,
    menu: userRoles.sort((a, b) => a.id - b.id).map(userRole => ({
      ...USER_ROLES_DATA[userRole.id],
      route: menuItem.route.replace(':userRoleId', userRole.id),
      render: props => React.createElement(menuItem.component, {
        ...props,
        userRole,
      }),
    })),
  };
});

export default compose(
  defaultProps({
    fullUserRoles: true,
  }),
  withUserReferencesQuery,
  mergeProps,
)(Contracts);
