export const getFileLink = path =>
  process.env.REACT_APP_MEDIA_HOST + '/' + path;

export const getBundleLink = params => {
  let result = '/api/storage/downloadBundle?';

  params.forEach((param, index) => {
    if (Object.values(param)[0]) {
      result += `${params.length > 1 && index !== 0 ? '&' : ''}${Object.keys(
        param,
      )}=${Object.values(param)}`;
    }
  });

  return result;
};
