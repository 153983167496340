import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import ContractsHeader from '../../../components/profile/contracts/header';
import ContractsList from '../../../components/profile/contracts/list';
import InviteToContractModal from '../../../components/modals/inviteToContract/container';

export default class ProfileContracts extends PureComponent {
  state = {
    order: null,
    type: null,
  };

  inviteToContractModalRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    const { sort, filterBy } = this.props;
    const { order, type } = this.state;

    if (!isEqual(order, prevState.order)) {
      sort(order);
    }

    if (!isEqual(type, prevState.type)) {
      filterBy(type);
    }
  }

  handleOnLoadMore = () => {
    const { fetchMore, contracts } = this.props;
    fetchMore(contracts.length);
  };

  setOrder = order => {
    this.setState({ order });
  };

  filterBy = type => {
    this.setState({ type });
  };

  handleInviteToContract = () => {
    this.inviteToContractModalRef.current.toggleModal();
  }

  render() {
    const { orderBy, isCurrent, isLoading, count, contracts, employeeUserId } = this.props;

    return <>
      <ContractsHeader
        setOrder={this.setOrder}
        orderBy={orderBy}
        filterBy={this.filterBy}
        onClickInviteToContract={this.handleInviteToContract}
      />
      <ContractsList
        isCurrent={isCurrent}
        items={contracts}
        count={count}
        isLoading={isLoading}
        onLoadMore={this.handleOnLoadMore}
      />
      <InviteToContractModal onRef={this.inviteToContractModalRef} invitedUserId={employeeUserId} />
    </>;
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    contracts: PropTypes.array,
    count: PropTypes.number,
    employeeUserId: PropTypes.number.isRequired,
    orderBy: PropTypes.object.isRequired,
    sort: PropTypes.func,
    filterBy: PropTypes.func,
    fetchMore: PropTypes.func.isRequired,
    isCurrent: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    contracts: [],
    count: 0,
    sort() {},
    filterBy() {},
  }
}
