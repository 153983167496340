import { normalizeMoney, denormalizeMoney } from '../../utils/money';

const YEAR_MONTHS = 12;

export const formatTariffPlans = tariffPlans => (tariffPlans || [])
  .map(({ __typename, duration, cost, userTariffPlanExpanded, ...tariff }) => ({
    ...tariff,
    cost: normalizeMoney(cost),
    originalDuration: duration,
    userTariffPlanExpanded: userTariffPlanExpanded,
    duration: parseInt(duration, 10) * (duration.indexOf('year') > -1 ? YEAR_MONTHS : 1),
  }));

export const formatGraphqlTariffPlans = ({ id, __typename, originalDuration, userTariffPlanExpanded, cost, ...tariffPlan }) => [{
  ...tariffPlan,
  cost: denormalizeMoney(cost),
  duration: originalDuration,
}];

export const getTariffPlans = (tariffPlans, selectedTariffPlanId) => (tariffPlans || []).map(({ id, name, predefined, isDemo }) => ({
  value: id,
  label: name,
  isDemo,
  ...(selectedTariffPlanId && { checked: selectedTariffPlanId === id }),
}));
