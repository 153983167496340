import React, { PureComponent } from 'react';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

import FormGroupField from '../../../base/formGroup';
import FileUploader from '../../../base/fileUploader';
import RestrictedInformation from '../../restrictedInformation';

import './index.scss';

class OrganizationData extends PureComponent {
  title = 'Данные организации';

  renderFields = label => ({ fields }) => fields.length > 0 && <>
    <h5>{label}</h5>
    {fields.map((admissionFile, index) => (
      <React.Fragment key={index}>
        <Field
          name={`${admissionFile}.file.title`}
          component={FormGroupField}
          type="text"
          label={label}
          className="file-title"
          isReadonly
        />
        <Field
          name={`${admissionFile}.file`}
          component={FileUploader}
          type="text"
          className="file-uploader"
          storagePath=""
          isReadonly
        />
      </React.Fragment>
    ))}
  </>;

  renderContent() {
    return <>
      <Field
        name="organization.name"
        type="text"
        component={FormGroupField}
        label="Наименование"
        isReadonly
      />
      <Field
        name="organization.taxSystem"
        type="text"
        component={FormGroupField}
        label="Система налогообложения"
        isReadonly
      />
      <Field
        name="organization.inn"
        type="text"
        component={FormGroupField}
        label="ИНН"
        isReadonly
      />
      <FieldArray
        name="organization.admissions"
        component={this.renderFields('Допуски')}
      />
      <FieldArray
        name="organization.lettersOfAttorney"
        component={this.renderFields('Доверенности')}
      />
    </>;
  }

  render() {
    const { isPrivate } = this.props;

    if (isPrivate) {
      return <RestrictedInformation title={this.title} />;
    }

    return <div className="form-card organization-data-layout readonly">
      <h5>{this.title}</h5>
      {this.renderContent()}
    </div>;
  }

  static propTypes = {
    isPrivate: PropTypes.bool,
  };

  static defaultProps = {
    isPrivate: false,
  }
}

export default OrganizationData;
