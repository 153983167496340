import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import LoggedInLayout from './index';

const mapStateToProps = ({
  session: {
    user: {
      externalProfileId,
      isFilled,
      isPasswordEmpty,
    } = {},
  },
}) => ({
  isExternalProfile: !!externalProfileId,
  isUserProfileFilled: isFilled,
  isUserPasswordEmpty: isPasswordEmpty,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(LoggedInLayout);
