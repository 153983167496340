import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { isActiveEmployee } from '../../../../../../graphql/model/object';
import { STAGE_FILE_TYPES } from '../../../../../../utils/stage';
import { getFormattedDate, getTimeLeft } from '../../../../../../utils/date';
import Button from '../../../../../base/button';
import Hint from '../../../../../base/hint';
import { VersionContainer } from './item';
import { ICONS } from '../../../../../../utils/icons';
import { VACANCY_TYPE } from '../../../../../../utils/vacancyType';
import { ROUTES } from '../../../../../../utils/routes';
import { CONTRACT_PHASES } from '../../../../../../utils/phase';

const checkIfFileHasBeenDownloaded = (files, userId) => (files || [])
  .some(({ downloads }) => downloads.some(download => download.userId === userId));

export function Versions({
  versionsRefs,
  files,
  performer,
  isCurrentPerformer,
  isCurrentPhase,
  hasPhases,
  phaseId,
  projectComponent,
  selectedProjectComponentId,
  userId,
  storageFilesPath,
  showAddNewVersionButton,
  onClickTurnInWork,
  onClickAcceptWork,
  onOpenRemarksModal,
  onClickAddProjectComponentFilesVersion,
  onClickCopyVersionFiles,
}) {
  const {
    id: objectProjectComponentId,
    customer,
    engineers,
    performers,
    flattenedFiles,
    flattenedRemarks,
    type: { genitiveName: typeGenitiveName },
    lastFilesVersion,
    level,
    remarks: { count, ...remarks },
    objectId,
    root: { id: stageId },
  } = projectComponent;
  const { workAcceptances } = performer;
  const worksCanBeAccepted = !!workAcceptances &&
    !workAcceptances.find(({ isAccepted, phaseId: workAcceptancePhaseId }) => workAcceptancePhaseId === phaseId && isAccepted);
  const isAcceptor = customer.userId === userId || isActiveEmployee(engineers, userId);
  const isCustomerOrChief = customer.userId === userId ||
    engineers.some(engineer => engineer.isActive && engineer.role.accusative === VACANCY_TYPE.chief && engineer.userId === userId);
  const versions = Object.keys(files || {});
  const hasLastVersion = Number(versions[versions.length - 1]) === lastFilesVersion.number;
  const lastVersionWorkAcceptance = hasLastVersion && workAcceptances?.find(({ versionId }) => versionId === lastFilesVersion.id);
  const hasActivePerformer = performers.some(({ isActive }) => isActive);
  const isPerformer = performers.some(({ isActive, userId: performerUserId }) => isActive && performerUserId === userId);
  const activeRemarks = flattenedRemarks.filter(({ task, phaseId: remarkPhaseId }) => remarkPhaseId === phaseId && !task.isClosed);
  const isAllRemarksResponded = activeRemarks.every(({ task: { messages } }) =>
    messages[messages.length - 1].userId === performer.id);
  const isAtLeastOneResponsePositive = flattenedRemarks.length > 0
    ? flattenedRemarks.some(({ status, phaseId: remarkPhaseId }) => remarkPhaseId === phaseId && status.kind === 'positive')
    : true;
  const hasNewVersionAfterRemarkResponse = Object.values(remarks)
    .every(remarkType => remarkType.filter(({ task, phaseId: remarkPhaseId, status }) =>
      // It is required to create new version after remark review of positive status
      remarkPhaseId === phaseId && !task.isClosed && status.id === 1).every(({ task }) =>
      moment.utc(lastFilesVersion.createdAt).isAfter(task.lastPerformerMessage?.createdAt)),
    );
  const contractId = projectComponent.performers.find(({ isActive }) => isActive)?.contractId;

  const buttonProps = {
    completeness: null,
    stage: null,
  };
  const MakeRemarksButton = <Button
    className="left-side-button"
    IconComponent={ICONS.infoBold}
    isBorderless
    onClick={onOpenRemarksModal}
    buttonProps={{
      type: 'button',
    }}
  >
    Выставить замечания
  </Button>;
  let hasLastVersionBundledPdf = false;
  let hasLastVersionSources = false;
  let isCompletenessAccepted = false;

  if (files) {
    hasLastVersionBundledPdf = !!flattenedFiles.find(({ version, type }) =>
      type === STAGE_FILE_TYPES.resultBundledInPdf.type && version?.number === lastFilesVersion.number);
    hasLastVersionSources = !!flattenedFiles.find(({ version, type }) =>
      type === STAGE_FILE_TYPES.sourceInEditableFormat.type && version?.number === lastFilesVersion.number);
  }

  const canManageVersion = !lastVersionWorkAcceptance && hasLastVersion &&
    performer?.contractId === lastFilesVersion.contractId;
  const hasBothFilesAttached = hasLastVersionBundledPdf && hasLastVersionSources;

  const workAcceptanceStatus = {
    isAllRemarksResponded,
    isAtLeastOneResponsePositive,
    hasNewVersionAfterRemarkResponse,
    hasBothFilesAttached,
  };

  if (performer) {
    const { paymentTerms, isActive, currentPhase } = performer;
    const isPhase3 = currentPhase?.key === CONTRACT_PHASES.phase3;
    const isForceAcceptWork = isPhase3 && !lastVersionWorkAcceptance;

    if (isCurrentPhase) {
      if (isCurrentPerformer && canManageVersion) {
        buttonProps.stage = {
          text: `Сдать работу${paymentTerms ? ' по Этапу' : ''}`,
          onClick: () => onClickTurnInWork(
            { ...projectComponent },
            {
              title: 'Перейти к замечаниям',
              path: level === 1 ? ROUTES.projectComponentRemarks : ROUTES.projectComponentRemark,
              params: {
                id: objectId,
                stageId,
                projectComponentId: level === 1 ? objectProjectComponentId : selectedProjectComponentId,
                ...(level === 1 ? {} : { remarkId: objectProjectComponentId }),
              },
            },
            workAcceptanceStatus,
          ),
        };
      } else if (isAcceptor) {
        let isCompletenessCanBeAccepted;
        let isStageCanBeAccepted;

        if (worksCanBeAccepted && lastVersionWorkAcceptance) {
          isCompletenessCanBeAccepted = lastVersionWorkAcceptance.signs
            .filter(({ isSourcesConfirmation }) => isSourcesConfirmation)
            .every(sign => sign.userId !== userId);
          isStageCanBeAccepted = lastVersionWorkAcceptance.signs
            .filter(({ isSourcesConfirmation }) => !isSourcesConfirmation)
            .every(sign => sign.userId !== userId);
          isCompletenessAccepted = lastVersionWorkAcceptance.signs
            .find(sign => sign.userId === userId)?.isSourcesConfirmation;
        }

        if (isActive) {
          if (isCompletenessCanBeAccepted) {
            buttonProps.completeness = {
              text: 'Подтвердить комплектность',
              onClick: () => onClickAcceptWork({
                ...projectComponent,
                workAcceptanceId: lastVersionWorkAcceptance.id,
                isCompleteness: true,
              }),
            };
          }
          if (isPhase3 || isStageCanBeAccepted) {
            buttonProps.stage = {
              text: `Принять работу${paymentTerms ? ' по Этапу' : ''}`,
              onClick: () => onClickAcceptWork({
                ...projectComponent,
                contractId,
                isForceAcceptWork,
                workAcceptanceId: lastVersionWorkAcceptance.id,
                isCompleteness: false,
              }),
              isDisabled: !(isCompletenessAccepted || isPhase3),
            };
          }
        }
      }
    }
  }

  const renderVersions = <div className={classNames({ 'work-acceptance-buttons': versions.length === 0 })}>
    {versions.length > 0
      ? versions.map(versionNumber => {
        const versionFiles = files[versionNumber];
        const isLastVersion = Number(versionNumber) === lastFilesVersion.number;
        const isLastVersionOfCurrentPhase = isLastVersion && isCurrentPhase;
        const versionWorkAcceptance = workAcceptances?.find(workAcceptance => workAcceptance.versionId === versionFiles.id);
        const id = `${objectProjectComponentId}${phaseId ? `-${phaseId}` : ''}-${versionNumber}`;
        let responseTime = null;

        if (performer.currentPhase) {
          responseTime = getTimeLeft(isAllRemarksResponded ? performer.currentPhase.phaseDeadline : performer.currentPhase.termPenaltyDeadline);
        }

        versionFiles[STAGE_FILE_TYPES.sourceInEditableFormat.type]?.forEach(file => {
          file.alreadyInLastVersion = files[lastFilesVersion.number]
            ? !!files[lastFilesVersion.number][STAGE_FILE_TYPES.sourceInEditableFormat.type]?.find(
              lastVersionFile => lastVersionFile.fileId === file.fileId)
            : !!flattenedFiles.find(({ version, type, fileId }) =>
              version?.number === lastFilesVersion.number &&
              type === STAGE_FILE_TYPES.sourceInEditableFormat.type &&
              fileId === file.fileId);
        });

        if (!versionsRefs[id]) {
          versionsRefs[id] = {
            ref: React.createRef(),
            childRefs: [React.createRef(), React.createRef()],
            isLastVersion: isLastVersionOfCurrentPhase,
          };
        }

        return performer && <VersionContainer
          id={id}
          versionRefs={versionsRefs[id]}
          key={versionNumber}
          title={`Версия #${versionNumber} от ${getFormattedDate(versionFiles.createdAt, true)}`}
          isDefaultExpanded={isLastVersion}
          objectId={objectId}
          onClickCopyVersionFiles={onClickCopyVersionFiles}
          flags={{
            isLastVersion,
            isLastVersionOfCurrentPhase,
            isPerformer,
            isCurrentPerformer,
            hasActivePerformer,
            hasLastVersionBundledPdf,
            worksCanBeAccepted,
            isEditable: canManageVersion,
            isCustomerOrChief,
          }}
          projectComponentFilesProps={{
            isReadonly: !canManageVersion,
            isVersionGridProps: true,
            storagePath: storageFilesPath,
            entityId: objectProjectComponentId,
            files: versionFiles,
            type: projectComponent.type,
            versionId: lastFilesVersion.id,
            phaseId: performers.find(performer => performer.isActive)?.currentPhase?.id,
            responseTime: performer.currentPhase
              ? {
                title: isAllRemarksResponded ? 'Срок выставления замечаний / принятия выполненных работ:' : 'Срок отработки замечаний исполнителем:',
                time: isLastVersionOfCurrentPhase && (responseTime || 'Просрочено'),
                style: responseTime ? 'green' : 'red',
              }
              : null,
            considerationResult: [...engineers, customer].map(({ role, userId }) => {
              const status = {
                title: 'Ожидает',
                color: 'neutral',
              };

              if (isCurrentPhase && flattenedRemarks.length > 0 &&
                flattenedRemarks.some(({ task }) => !task.isClosed && task.authorId === userId)
              ) {
                status.title = 'Есть замечания';
                status.color = 'negative';
              } else if (versionWorkAcceptance?.signs.filter(sign => sign.userId === userId).length === 2) {
                status.title = 'Принял работу';
                status.color = 'positive';
              } else if (checkIfFileHasBeenDownloaded(versionFiles[STAGE_FILE_TYPES.sourceInEditableFormat.type], userId) ||
                checkIfFileHasBeenDownloaded(versionFiles[STAGE_FILE_TYPES.resultBundledInPdf.type], userId)
              ) {
                status.title = 'В процессе';
                status.color = 'gray';
              }

              return {
                userId,
                title: role.accusative,
                completenessConfirmedBy: versionWorkAcceptance?.signs.find(({ isSourcesConfirmation, userId: signUserId }) =>
                  isSourcesConfirmation && signUserId === userId,
                )?.createdAt || null,
                status,
              };
            }),
          }}
        />;
      })
      : isAcceptor && !isCurrentPerformer && isCurrentPhase && lastFilesVersion.id && MakeRemarksButton || !isCurrentPerformer && 'Нет данных'}
    <div className="action-buttons">
      {!lastVersionWorkAcceptance && showAddNewVersionButton && <div className="left-side-button">
        <Button onClick={() => onClickAddProjectComponentFilesVersion(workAcceptanceStatus)}>
          Добавить новую версию {typeGenitiveName}
        </Button>
        <Hint title={`Добавить новую версию ${typeGenitiveName}`}>
          Создает новый контейнер для загрузки обновленной документации. Запускает таймер приёма-передачи работ заново.
        </Hint>
      </div>}
      {isAcceptor && isCurrentPhase && hasLastVersion && MakeRemarksButton}
      {isAcceptor && hasLastVersion && !isCompletenessAccepted && buttonProps.stage && <Hint title="Прием работ">
        Прием работ возможен только после подтверждения комплектности
      </Hint>}
      {(!isCurrentPerformer || hasLastVersionBundledPdf && buttonProps.stage) && <div>
        {hasLastVersionBundledPdf && buttonProps.stage && <Button
          onClick={buttonProps.stage.onClick}
          isDisabled={buttonProps.stage.isDisabled}
        >
          {buttonProps.stage.text}
        </Button>}
        {isCurrentPerformer && <Hint title={buttonProps.stage.text}>
          Заказчик не сможет принять работы пока не будет нажата кнопка &quot;{buttonProps.stage.text}&quot;
        </Hint>}
      </div>}
      {worksCanBeAccepted && hasLastVersionSources && buttonProps.completeness &&
        <Button onClick={buttonProps.completeness.onClick}>
          {buttonProps.completeness.text}
        </Button>}
    </div>
  </div>;

  return hasPhases
    ? renderVersions
    : (versions.length > 0 || isCurrentPerformer && performer) &&
      React.createElement('div', { className: 'versions-layout' }, renderVersions);
}

Versions.propTypes = {
  versionsRefs: PropTypes.object,
  files: PropTypes.object,
  performer: PropTypes.object,
  isCurrentPerformer: PropTypes.bool,
  phaseId: PropTypes.number,
  hasPhases: PropTypes.bool,
  isCurrentPhase: PropTypes.bool,
  projectComponent: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  storageFilesPath: PropTypes.string.isRequired,
  showAddNewVersionButton: PropTypes.bool.isRequired,
  onClickTurnInWork: PropTypes.func.isRequired,
  onClickAcceptWork: PropTypes.func.isRequired,
  onOpenRemarksModal: PropTypes.func.isRequired,
  onClickAddProjectComponentFilesVersion: PropTypes.func.isRequired,
  onClickCopyVersionFiles: PropTypes.func.isRequired,
};

Versions.defaultProps = {
  versionsRefs: {},
  files: null,
  isCurrentPhase: true,
  phaseId: null,
  hasPhases: false,
  performer: null,
  isCurrentPerformer: false,
};
