import { formatBankBik, addLeadingZerosToValue } from '../../utils/contractDetails';
import { INDIVIDUAL_INN_LENGTH, INSURANCE_ACCOUNT_LENGTH, KPP_LENGTH, OGRN_LENGTH } from '../../utils/validation';

export const getContractDetails = ({
  id: userId,
  firstName,
  lastName,
  patronymic,
  email,
  phone,
  isPhoneConfirmed,
  isEmailConfirmed,
  selfContractDetails,
  organizationContractDetails,
}) => {
  const defaultUserDetails = {
    firstName,
    lastName,
    patronymic,
    email,
    phone,
    isPhoneConfirmed,
    isEmailConfirmed,
  };
  const { id: organizationUserId, inn: organizationInn, kpp, ogrn, ...organization } = organizationContractDetails || defaultUserDetails;
  const { id: selfUserId, inn: individualInn, insuranceAccount, ...self } = selfContractDetails || defaultUserDetails;

  const { admissions, lettersOfAttorney } = organization;

  Object.assign(organization, {
    bankBik: formatBankBik(organization.bankBik),
    organizationInn: addLeadingZerosToValue(organizationInn, INDIVIDUAL_INN_LENGTH),
    kpp: addLeadingZerosToValue(kpp, KPP_LENGTH),
    ogrn: addLeadingZerosToValue(ogrn, OGRN_LENGTH),
  });
  Object.assign(self, {
    bankBik: formatBankBik(self.bankBik),
    individualInn: addLeadingZerosToValue(individualInn, INDIVIDUAL_INN_LENGTH),
    insuranceAccount: addLeadingZerosToValue(insuranceAccount, INSURANCE_ACCOUNT_LENGTH),
  });

  organization.initialFiles = [admissions || [], lettersOfAttorney || []].reduce(
    (prev, curr) => [...prev, ...curr.map(({ file }) => file.id)],
    [],
  );

  return {
    userId,
    self,
    organization,
  };
};
