import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withProps } from 'recompose';

import Chat, { withGetChatMessages } from '../../../../chat/container';

const ON_CHAT_MESSAGES_UPDATE_SUBSCRIPTION =
  loader('../../../../../graphql/queries/chat/contractChatMessagesSubscription.graphql');
const ON_CHAT_MESSAGES_COUNT_UPDATE_SUBSCRIPTION =
  loader('../../../../../graphql/queries/chat/contractChatMessagesCountSubscription.graphql');
const INSERT_CHAT_MESSAGE_MUTATION = loader('../../../../../graphql/queries/chat/insertContractChatMessage.graphql');

const withInsertChatMessageMutation = graphql(INSERT_CHAT_MESSAGE_MUTATION, { name: 'insertMessageMutation' });

export default compose(
  withGetChatMessages({
    ON_CHAT_MESSAGES_UPDATE_SUBSCRIPTION,
    ON_CHAT_MESSAGES_COUNT_UPDATE_SUBSCRIPTION,
  }),
  withInsertChatMessageMutation,
  withProps(({ id, insertMessageMutation }) => ({
    onSendMessage: message => {
      insertMessageMutation({
        variables: {
          messageItem: {
            contractId: id,
            message,
          },
        },
      });
    },
  })),
)(Chat);
