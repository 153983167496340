import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import noop from 'lodash/noop';

import Button from '../../../../base/button';
import RegistryResponsesItem from './item';
import { ICONS } from '../../../../../utils/icons';
import CreateRemarkModal from '../../../../modals/createRemark/container';
import { getTimeLeft } from '../../../../../utils/date';

import './index.scss';

export default class Registry extends PureComponent {
  state = {
    selectedRemark: null,
    isRevisionModal: false,
    isEdit: false,
  };

  createResponseModalRef = React.createRef();

  handleClickAdd = remark => {
    this.setState({
      selectedRemark: {
        ...remark,
        isPerformer: this.props.isPerformer,
      },
    }, this.createResponseModalRef.current.toggleModal);
  };

  handleClickEdit = remark => {
    const { isPerformer } = this.props;
    const { task: { lastPerformerMessage, lastMessage } } = remark;
    const { files: lastPerformerMessageFiles, message: lastPerformerMessageText } = lastPerformerMessage;
    const { files: lastMessageFiles, message: lastMessageText } = lastMessage;
    let newState = {
      selectedRemark: {
        ...remark,
        response: lastPerformerMessageText,
        messageFiles: lastPerformerMessageFiles,
        isPerformer,
      },
      isEdit: true,
    };

    if (!isPerformer) {
      newState = {
        selectedRemark: {
          ...remark,
          response: lastPerformerMessageText,
          responseFiles: lastPerformerMessageFiles,
          revisionMessage: lastMessageText,
          messageFiles: lastMessageFiles,
        },
        isRevisionModal: true,
        isEdit: true,
      };
    }

    newState.selectedRemark.initialFiles = newState.selectedRemark.messageFiles;

    this.setState(newState, this.createResponseModalRef.current.toggleModal);
  };

  handleModalClose = () => {
    this.setState({
      selectedRemark: null,
      isRevisionModal: false,
      isEdit: false,
    });
  };

  handleSubmit = (data, isRevision, isEdit) => {
    const { projectComponent } = this.props;
    const { selectedRemark } = this.state;
    const { onAddResponse, onEditTaskMessage } = this.context;

    return isEdit
      ? onEditTaskMessage({ ...data, remarkId: selectedRemark.id, message: isRevision ? data.revisionMessage : data.response })
      : onAddResponse({ ...data, projectComponent }, isRevision);
  };

  handleClickReject = (remark, reviewId) => {
    const { files, message } = remark.task.lastPerformerMessage;

    this.setState({
      selectedRemark: {
        ...remark,
        response: message,
        responseFiles: files,
        reviewId,
      },
      isRevisionModal: true,
    }, this.createResponseModalRef.current.toggleModal);
  };

  handleClickAccept = remark =>
    this.context.onUpdateRemarkReview(remark, true);

  render() {
    const { remarks, isPerformer, projectComponent } = this.props;
    const { selectedRemark, isRevisionModal, isEdit } = this.state;
    const currentPerformer = projectComponent.performers.find(({ isActive }) => isActive);
    const hasNotResolvedRemarks = Object.values(remarks).some(remark => !remark.task.isClosed);
    const deadline = currentPerformer?.currentPhase?.termPenaltyDeadline;

    return <div className="registry-container">
      <div className="registry-responses-header">
        <div>
          <h5>Сводный реестр ответов на замечания по разделу</h5>
          {deadline && hasNotResolvedRemarks && <>
            {moment(deadline).diff(moment()) > 0
              ? <p>Для отработки замечаний исполнителем осталось <span className="deadline">
                {getTimeLeft(deadline)}</span>
              </p>
              : <p className="colored red">Срок отработки замечаний исполнителем истек.</p>}
          </>}
        </div>
        <Button
          className="btn-new-pdf"
          onClick={noop}
          IconComponent={ICONS.envelopeOutlined}
          isBorderless
        >
          Сформировать ПДФ
        </Button>
      </div>
      <div className="registry-responses-columns">
        <p className="column-count">№ п/п</p>
        <p className="column-remarks">Замечания</p>
        <p className="column-responses">Ответы на замечания</p>
        <p className="column-review-status">Статус рассмотрения</p>
      </div>
      {remarks.length === 0
        ? <p className="empty">Нет записей.</p>
        : <div className="registry-responses-list-wrapper">
          {remarks.map(remark => <RegistryResponsesItem
            key={remark.id}
            isPerformer={isPerformer}
            remark={remark}
            onClickAdd={this.handleClickAdd}
            onClickEdit={this.handleClickEdit}
            onClickAccept={this.handleClickAccept}
            onClickReject={this.handleClickReject}
          />)}
        </div>}
      <CreateRemarkModal
        onRef={this.createResponseModalRef}
        projectComponent={projectComponent}
        remarkResponse={selectedRemark}
        isRevision={isRevisionModal}
        onSubmit={this.handleSubmit}
        onClose={this.handleModalClose}
        isEdit={isEdit}
      />
    </div>;
  }

  static contextTypes = {
    onAddResponse: PropTypes.func.isRequired,
    onUpdateRemarkReview: PropTypes.func.isRequired,
    onEditTaskMessage: PropTypes.func.isRequired,
  };

  static propTypes = {
    projectComponent: PropTypes.object.isRequired,
    remarks: PropTypes.array.isRequired,
    isPerformer: PropTypes.bool.isRequired,
  };
}
