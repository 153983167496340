import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import GA4React from 'ga-4-react';
import PropTypes from 'prop-types';

import ErrorBoundary from '../../components/errorBoundary';

const { REACT_APP_YANDEX_METRIKA_ACCOUNT, REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } = process.env;

const WebAnalyticsRoute = ({ ...props }) => {
  const url = props.location.pathname;
  const { path } = props;
  useEffect(() => {
    if (!path || !url || !(REACT_APP_YANDEX_METRIKA_ACCOUNT && REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)) {
      return;
    }
    if (REACT_APP_YANDEX_METRIKA_ACCOUNT) {
      ym('hit', url);
    }
    if (REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      if (GA4React.isInitialized()) {
        const ga4 = GA4React.getGA4React();
        if (ga4) {
          ga4.pageview(url);
        }
      } else {
        const ga4react = new GA4React(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
        ga4react.initialize().then(ga4 => {
          ga4.pageview(url);
        }).catch(() => {});
      }
    }
  }, [url, path]);
  return <ErrorBoundary><Route {...props} /></ErrorBoundary>;
};

WebAnalyticsRoute.propTypes = {
  location: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};

WebAnalyticsRoute.defaultProps = {
  location: {},
};

export default withRouter(WebAnalyticsRoute);
