import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

import { ICONS } from '../../utils/icons';
import Button from '../../components/base/button';

import './index.scss';

const ICON_KIND = {
  neutral: 'info',
  positive: 'success',
  negative: 'failed',
};

export default class NotificationList extends React.Component {
  handleMarkReadClick = (event, data) => {
    event.stopPropagation();
    this.props.onMarkRead(data, true);
  };

  handleMarkReadAllClick = () => {
    const { notifications: { unread }, onMarkRead } = this.props;

    unread.forEach(data => {
      if (!data.wasRead) {
        onMarkRead(data, true);
      }
    });
  };

  renderNotifications = notifications => {
    const { onMarkRead } = this.props;

    return notifications.map(data => {
      const { id, message, kind, formattedDate, wasRead } = data;

      return <Dropdown.Item
        className={classNames({ unread: !wasRead })}
        key={id}
        eventKey={id}
        onSelect={() => { onMarkRead(data); }}
      >
        {React.createElement(ICONS[ICON_KIND[kind]], { className: classNames('icon', ICON_KIND[kind]) })}
        <div className="item-body">
          <p id="message">{message}</p>
          <p id="date">{formattedDate}</p>
          {!wasRead && <Button
            onClick={event => this.handleMarkReadClick(event, data)}
            id="mark-read-button"
            isBorderless
            IconComponent={ICONS.checkMarkSingle}
          >
            отметить прочитанным
          </Button>}
        </div>
      </Dropdown.Item>;
    });
  };

  renderHeader = () => {
    const { notifications: { read, unread } } = this.props;
    const totalCount = read.length + unread.length;

    return <Dropdown.Header className="empty">
      {
        totalCount === 0
          ? 'Список уведомлений пуст'
          : unread.length > 0 && <Button
            onClick={this.handleMarkReadAllClick}
            className="mark-read-button"
            isBorderless
            IconComponent={ICONS.checkMarkDouble}
          >
            Отметить все уведомления прочитанными
          </Button>
      }
    </Dropdown.Header>;
  };

  render() {
    const { notifications: { read, unread } } = this.props;
    const totalCount = read.length + unread.length;

    return <Dropdown className="notifications-dropdown">
      <Dropdown.Toggle id="dropdown-basic">
        {unread.length > 0 && <Badge pill variant="danger">{unread.length}</Badge>}
        <ICONS.notification className="icon" />
        <span className="title">Уведомления</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="notifications-dropdown-container">
        {(totalCount === 0 || unread.length > 0) && this.renderHeader()}
        {this.renderNotifications(unread)}
        {read.length > 0 && <Dropdown.Header>Прочитанные</Dropdown.Header>}
        {this.renderNotifications(read)}
      </Dropdown.Menu>
    </Dropdown>;
  }

  static propTypes = {
    notifications: PropTypes.object,
    onMarkRead: PropTypes.func,
  };

  static defaultProps = {
    notifications: {
      read: [],
      unread: [],
    },
    onMarkRead() {},
  };
}
