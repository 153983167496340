import { compose, withContext, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

import ContractDocumentation from './index';
import AuthorizedApi from '../../../api/authorized';

const INSERT_CONTRACT_FILE_SIGN_MUTATION = loader('../../../graphql/queries/contract/insertFileSign.graphql');
const DELETE_CONTRACT_FILE_SIGN_MUTATION = loader('../../../graphql/queries/contract/deleteFileSign.graphql');
const DELETE_FILE_MUTATION = loader('../../../graphql/queries/file/delete.graphql');
const UPDATE_FILE_MUTATION = loader('../../../graphql/queries/file/update.graphql');

const withInsertContractFileSignMutation = graphql(INSERT_CONTRACT_FILE_SIGN_MUTATION, { name: 'insertContractFileSign' });
const withDeleteContractFileSignMutation = graphql(DELETE_CONTRACT_FILE_SIGN_MUTATION, { name: 'deleteContractFileSign' });
const withDeleteFileMutation = graphql(DELETE_FILE_MUTATION, { name: 'deleteFile' });
const withUpdateFileMutation = graphql(UPDATE_FILE_MUTATION, { name: 'updateFile' });

const mergeProps = withProps(({
  contract: { id, contractFileTypes, object: { projectComponentId, isTariffActive } },
  insertContractFileSign,
  deleteContractFileSign,
  deleteFile,
  updateFile,
}) => ({
  isTariffActive,
  contractFileTypes,
  contractId: id,
  objectProjectComponentId: projectComponentId,
  onFileUpload: AuthorizedApi.uploadFile,
  onSign: contractFileId => insertContractFileSign({
    variables: {
      sign: {
        contractFileId,
      },
    },
  }),
  onRemoveSign: ({ fileId, userId }) => deleteContractFileSign({
    variables: {
      contractFileId: fileId,
      userId,
    },
  }),
  onRemoveFile: ({ fileId }) => deleteFile({
    variables: {
      fileIds: [fileId],
    },
  }),
  onEditFile: (fileId, title) => updateFile({
    variables: {
      id: fileId,
      file: {
        title,
      },
    },
  }),
}));

export default compose(
  withInsertContractFileSignMutation,
  withDeleteContractFileSignMutation,
  withDeleteFileMutation,
  withUpdateFileMutation,
  mergeProps,
  withContext(
    {
      onFileUpload: PropTypes.func.isRequired,
      onSign: PropTypes.func.isRequired,
      onRemoveSign: PropTypes.func.isRequired,
      onRemoveFile: PropTypes.func.isRequired,
      onEditFile: PropTypes.func.isRequired,
    },
    ({ onFileUpload, onSign, onRemoveSign, onRemoveFile, onEditFile }) => ({
      onFileUpload,
      onSign,
      onRemoveSign,
      onRemoveFile,
      onEditFile,
    }),
  ),
)(ContractDocumentation);
