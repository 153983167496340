import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from '../info';
import Mailto from '../../base/mailto';
import { ICONS } from '../../../utils/icons';
import { INFO_MESSAGES } from '../../../utils/messages';

import './index.scss';

const SALES_EMAIL = process.env.REACT_APP_SALES_EMAIL;

const SOCIAL_LINKS = [
  {
    SocialIcon: ICONS.facebookRound,
    link: process.env.REACT_APP_FACEBOOK_LINK,
  },
  {
    SocialIcon: ICONS.vkRound,
    link: process.env.REACT_APP_VK_LINK,
  },
  {
    SocialIcon: ICONS.instagramRound,
    link: process.env.REACT_APP_INSTAGRAM_LINK,
  },
  {
    SocialIcon: ICONS.youtubeRound,
    link: process.env.REACT_APP_YOUTUBE_LINK,
  },
];

export default class ThanksForPurchase extends React.PureComponent {
  render() {
    const { tariffPlan: { isPrepaid }, ...props } = this.props;

    return <InfoModal
      className="thanks-for-purchase-modal"
      {...props}
    >
      <h5>Благодарим за покупку!</h5>
      <p className="subtitle">Ссылка на оплату счета отправлена на вашу электронную почту</p>
      <div className="info">
        <ICONS.infoBold className="info-icon" />
        <p>{isPrepaid ? INFO_MESSAGES.prepaidTariff : INFO_MESSAGES.postpaidTariff}</p>
      </div>
      <p className="social">Чтобы быть в курсе новостей подпишись на наших страницах в социальных сетях</p>
      <div className="social-links">
        {SOCIAL_LINKS.map(({ SocialIcon, link }) => (
          <a key={link} href={link} target="_blank" rel="noopener noreferrer"><SocialIcon /></a>
        ))}
      </div>
      <p className="gift">
        Хочу <Mailto
          email={SALES_EMAIL}
          subject="Хочу подарок в обмен на публикацию"
          body="Здравствуйте, я хочу воспользоваться возможностью получить подарок в обмен на положительную публикацию о ПИР-Системе."
        >
          получить подарок
        </Mailto> за публикацию о ПИР-Системе в социальных сетях
      </p>
    </InfoModal>;
  }

  static propTypes = {
    tariffPlan: PropTypes.object.isRequired,
  };
}
