import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../base/button';
import Container from '../../../../base/container';
import { getFormattedDate } from '../../../../../utils/date';
import Grid from '../../../../base/grid';
import FileUploader from '../../../../base/fileUploader';
import { getObjectStoragePath } from '../../../../../utils/storagePaths';
import { StatusComponent } from './status';
import { ConsentComponent } from './consent';

export function ExpertiseContainer({
  expertise: { file, isOwner, projectComponents, noExpertiseProjectComponents, start },
  index,
  objectId,
  onOpenChangeStatusModal,
  onUpdateExpertise,
  onDeleteExpertise,
  onChangeStatusToPositive,
  onAddAcceptance,
  onClickDisagree,
}) {
  const [isExpanded, setExpanded] = useState(false);
  const expertiseGridProps = [
    {
      key: 'name',
      columnName: 'Наименование раздела',
      className: 'name',
    },
    {
      key: 'status',
      columnName: 'Статус заключения',
      className: 'status',
      render: (status, { projectComponentExpertiseId, isOwner, acceptances }) => <StatusComponent
        status={status}
        isOwner={isOwner}
        hasNotAcceptances={acceptances.length === 0}
        onChangeStatusToPositive={() => onChangeStatusToPositive(projectComponentExpertiseId)}
      />,
    },
    {
      key: 'actionButtons',
      columnName: 'Статус согласия',
      className: 'consent',
      render: (_, item) => <ConsentComponent
        item={item}
        onAddAcceptance={onAddAcceptance}
        onClickDisagree={onClickDisagree}
      />,
    },
  ];
  const noExpertiseGridProps = [
    {
      key: 'name',
      className: 'name',
      columnName: 'Перечень разделов требующих уточнения статуса',
    },
    {
      key: 'status',
      className: 'status',
      render: (_, item) => isOwner && <Button
        onClick={() => onOpenChangeStatusModal(item, file.id)}
        isBorderless
      >Изменить статус</Button>,
    },
  ];

  const handleChangeFile = newFile => {
    if (newFile) {
      onUpdateExpertise(file.id, newFile);
    } else {
      onDeleteExpertise(file.id);
    }
  };

  const renderExpertiseFooter = <FileUploader
    className="expertise-file"
    label="Ссылка на скачивание"
    storagePath={getObjectStoragePath(objectId)}
    input={{ value: file || {} }}
    isReadonly={!isOwner}
    onChange={handleChangeFile}
    showWarningModal
  />;

  return <div key={file.id} className="object-expertise-container">
    <Container
      headerContent={<h5>Заключение #{index + 1} от {getFormattedDate(moment(start), false)}</h5>}
      isDefaultExpanded={isExpanded}
      shareExpandedState={setExpanded}
      isHeaderClickable
      {...(isExpanded ? { footerContent: renderExpertiseFooter } : {})}
    >
      <Grid
        className="expertise-grid"
        items={projectComponents}
        gridProps={expertiseGridProps}
      />
      <Container
        headerContent={<h5>Разделы по которым нет данных</h5>}
        isHeaderClickable
      >
        <Grid
          className="no-expertise-grid"
          items={noExpertiseProjectComponents}
          gridProps={noExpertiseGridProps}
        />
      </Container>
    </Container>
  </div>;
}

ExpertiseContainer.propTypes = {
  expertise: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  onOpenChangeStatusModal: PropTypes.func.isRequired,
  onUpdateExpertise: PropTypes.func.isRequired,
  onDeleteExpertise: PropTypes.func.isRequired,
  onChangeStatusToPositive: PropTypes.func.isRequired,
  onAddAcceptance: PropTypes.func.isRequired,
  onClickDisagree: PropTypes.func.isRequired,
};
