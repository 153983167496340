import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BootstrapButton from 'react-bootstrap/Button';
import { Badge, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

import './index.scss';

const popperConfig = {
  modifiers: [
    {
      name: 'flip',
      enabled: true,
      options: {
        fallbackPlacements: ['top', 'right'],
        flipVariations: false,
      },
    },
  ],
};

export default class Button extends PureComponent {
  renderTooltip = text => <Tooltip className={React.isValidElement(text) ? 'hint-tooltip' : ''} id="buttonHint">
    {text}
  </Tooltip>;

  render() {
    const {
      className,
      children,
      IconComponent,
      isDisabled,
      onClick,
      isFilled,
      isBorderless,
      buttonProps,
      badge,
      isLoading,
      isLarge,
      tooltip,
      id,
      href,
      target,
    } = this.props;

    return <OverlayTrigger
      placement="auto"
      container={document.body}
      overlay={this.renderTooltip(tooltip)}
      // workaround for react-bootstrap warning https://github.com/react-bootstrap/react-bootstrap/issues/5027
      trigger={tooltip ? ['hover', 'hover'] : null}
      popperConfig={popperConfig}
    >
      <BootstrapButton
        {...(id ? { id } : {})}
        {...buttonProps}
        className={classNames('regular-button', className, {
          'icon-button': !!IconComponent,
          filled: isFilled,
          borderless: isBorderless,
          large: isLarge,
        })}
        variant="outline-primary"
        disabled={isLoading || isDisabled}
        onClick={onClick}
        href={href}
        target={target}
      >
        {!isLoading && IconComponent && <IconComponent className={classNames('icon', { 'indent-right': !!children })} />}
        {isLoading ? <Spinner animation="border" size="sm" /> : children}
        {Boolean(badge) && <Badge pill variant="danger">{badge}</Badge>}
      </BootstrapButton>
    </OverlayTrigger>;
  }
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  IconComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]),
  isDisabled: PropTypes.bool,
  isLarge: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isFilled: PropTypes.bool,
  isBorderless: PropTypes.bool,
  buttonProps: PropTypes.object,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  isLoading: PropTypes.bool,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  id: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
};

Button.defaultProps = {
  children: '',
  IconComponent: '',
  isDisabled: false,
  isBorderless: false,
  isLarge: false,
  isLoading: false,
  className: '',
  onClick() {},
  isFilled: false,
  buttonProps: {},
  badge: '',
  tooltip: '',
  id: '',
  href: null,
  target: null,
};
