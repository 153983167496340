import { compose } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import BreakContractModal from './index';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { reduxForm } from 'redux-form';
import { getSubmitValidation, getValidation } from '../../../utils/validation';

const BREAK_CONTRACT_IN_FAVOR_OF_CUSTOMER_ID = 1;

const GET_CONTRACT_BREAK_OPTIONS_QUERY = loader('../../../graphql/queries/contract/getContractBreakOptions.graphql');
const ON_CONTRACT_BREAK_CHOICES_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contract/breakChoicesSubscription.graphql');
const UPSERT_CONTRACT_BREAK_CHOICE_MUTATION = loader('../../../graphql/queries/contract/upsertContractBreakChoice.graphql');

const withUpsertContractBreakChoiceMutation = graphql(UPSERT_CONTRACT_BREAK_CHOICE_MUTATION, {
  name: 'upsertContractBreakChoice',
});

const withGetContractBreakOptionsQuery = graphql(GET_CONTRACT_BREAK_OPTIONS_QUERY, {
  name: 'getContractBreakOptions',
  skip: ({ contractId }) => !contractId,
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
  props: result => {
    const {
      getContractBreakOptions: {
        contractBreakOptions,
        loading,
      },
    } = result;

    return {
      contractBreakOptions,
      isOptionsLoading: loading,
    };
  },
});

const withGetContractBreakChoicesSub = graphql(ON_CONTRACT_BREAK_CHOICES_UPDATE_SUBSCRIPTION, {
  name: 'contractBreakChoicesSub',
  options: ({ contractId }) => ({
    variables: {
      contractId,
    },
  }),
  skip: ({ contractId }) => !contractId,
  props: ({ contractBreakChoicesSub: { contractBreakChoices, loading } }) => ({
    contractBreakChoices,
    isChoicesLoading: loading,
  }),
});

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

const mergeProps = (
  { userId, ...mapStateToProps },
  dispatchToProps,
  {
    upsertContractBreakChoice,
    contractId,
    contractBreakOptions,
    contractBreakChoices,
    isChoicesLoading,
    isOptionsLoading,
    initiatorReason,
    ...props
  },
) => {
  const initiatorChoice = contractBreakChoices?.find(choice => choice.userId !== userId);
  const userChoice = contractBreakChoices?.find(choice => choice.userId === userId);
  const initialValues = userChoice
    ? {
      contractBreakOptionId: userChoice.option.id,
      reason: userChoice.reason,
    }
    : {
      reason: initiatorReason || '',
    };

  return {
    ...mapStateToProps,
    ...props,
    initialValues,
    contractBreakOptions,
    initiatorReason: initiatorReason || initiatorChoice?.reason || '',
    initiatorBreakOption: initiatorChoice?.option.name,
    options: (contractBreakOptions || []).map(option => ({
      value: option.id,
      label: option.name,
      checked: option.id === initialValues?.contractBreakOptionId,
    })),
    isLoading: isChoicesLoading || isOptionsLoading,
    onBreakContract: ({ contractBreakOptionId, reason }) => upsertContractBreakChoice({
      variables: {
        choice: {
          contractId,
          optionId: contractBreakOptionId || BREAK_CONTRACT_IN_FAVOR_OF_CUSTOMER_ID,
          reason,
        },
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  };
};

export default compose(
  withGetContractBreakOptionsQuery,
  withGetContractBreakChoicesSub,
  withUpsertContractBreakChoiceMutation,
  connect(mapStateToProps, null, mergeProps),
  reduxForm({
    form: 'breakContractModal',
    validate: getValidation([
      'contractBreakOptionId',
    ]),
    onSubmitFail: getSubmitValidation,
    enableReinitialize: true,
  }),
)(BreakContractModal);
