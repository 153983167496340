import { withRouter, matchPath } from 'react-router-dom';
import { compose, withProps } from 'recompose';

import { ROUTES } from '../../utils/routes';
import ObjectProjectComponentMenu from './index';
import { getPath } from '../../utils/menu';

const mergeProps = withProps(({ location, history, items, currentProjectComponentId, isRemarks, ...props }) => {
  const { pathname } = location;

  return {
    ...props,
    currentProjectComponentId,
    goTo: id => {
      const matchedPath = matchPath(pathname, ROUTES.stage);
      const matchedPathParams = matchedPath?.params;

      if (isRemarks) {
        history.replace(getPath(ROUTES.projectComponentRemark, {
          ...matchedPathParams,
          projectComponentId: props.match.params.projectComponentId,
          remarkId: id,
        }));
      } else {
        history.replace(getPath(ROUTES.projectComponent, {
          ...matchedPathParams,
          projectComponentId: id,
        }));
      }
    },
  };
});

export default compose(
  withRouter,
  mergeProps,
)(ObjectProjectComponentMenu);
