import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import merge from 'lodash/merge';

import Profile from './index';
import { formatPublicProfile } from '../../../graphql/model/user';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import withUserReferencesQuery from '../../../graphql/hoc/userReferences';

const GET_PUBLIC_PROFILE_QUERY = loader('../../../graphql/queries/publicProfile/get.graphql');
const ON_PUBLIC_PROFILE_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/publicProfile/subscription.graphql');

const mapStateToProps = ({ session: { user = {} } }) => ({
  userId: user.id,
});

const withGetPublicProfileQuery = graphql(GET_PUBLIC_PROFILE_QUERY, {
  name: 'getProfile',
  skip: ({ userId }) => !userId,
  options: ({ match: { params: { id } }, userId }) => ({
    variables: {
      id: Number(id),
      inFavoriteUserId: userId,
    },
  }),
  props: ({ getProfile: { user_by_pk: user = {}, error, loading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryUser: user,
      queryLoading: loading,
    };
  },
});

// Without rating subscription
const withPublicProfileSubscription = graphql(ON_PUBLIC_PROFILE_UPDATE_SUBSCRIPTION, {
  name: 'publicProfileSub',
  skip: ({ userId }) => !userId,
  options: ({ match: { params: { id } }, userId }) => ({
    variables: {
      id: Number(id),
      inFavoriteUserId: userId,
    },
  }),
  props: ({ publicProfileSub: { user_by_pk: user = {}, error, loading }, ownProps: { queryUser, userRoles, queryLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      initialValues: user && formatPublicProfile(merge(queryUser, user[0]), userRoles) || {},
      isPrivate: !queryUser || !queryUser.id,
      isLoading: loading || queryLoading,
    };
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  withUserReferencesQuery,
  withGetPublicProfileQuery,
  withPublicProfileSubscription,
)(Profile);
