import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InfiniteList from '../../base/infiniteList';

import './index.scss';

export default class UserList extends React.PureComponent {
  render() {
    const { className, isFavorite, onRef, ...props } = this.props;

    return <InfiniteList
      {...props}
      {...(onRef ? { ref: onRef } : {})}
      className={classNames('users-list-layout', className)}
      isFavorite={isFavorite}
    />;
  }

  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    emptyMessage: PropTypes.string,
    isFavorite: PropTypes.bool,
    onRef: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    items: [],
    emptyMessage: 'Список пуст',
    isFavorite: false,
    onRef: null,
  };
}
