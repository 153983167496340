import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import { showError } from '../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';

const ON_USER_TARIFF_PLAN_REFERENCES_UPDATE_QUERY = loader('../../graphql/queries/tariff/getReferencesSubscription.graphql');

export default graphql(ON_USER_TARIFF_PLAN_REFERENCES_UPDATE_QUERY, {
  name: 'userTariffPlanReferencesSub',
  skip: ({ isSkip }) => isSkip,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
  }),
  props: ({ userTariffPlanReferencesSub: { userTariffPlan = [], loading, error } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      isUserTariffPlansLoading: loading,
      userTariffPlans: userTariffPlan,
    };
  },
});
