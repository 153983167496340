import difference from 'lodash/difference';

export const getGraphqlUserObjectTypes = ({ userObjectTypes, id }) => userObjectTypes
  ? userObjectTypes.map(objectTypeId => ({
    objectTypeId,
    userId: id,
  }))
  : [];

export const getUserObjectTypesToDelete = ({ initialUserObjectTypes, userObjectTypes }) => {
  return initialUserObjectTypes
    ? difference(initialUserObjectTypes, userObjectTypes)
    : [];
};
