import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field } from 'redux-form';

import Button from '../../base/button';
import Modal from '../../base/modal';
import FormGroupField from '../../base/formGroup';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import UserInfo from '../../base/userInfo';
import CompetenceConfirmation from '../../profile/edit/competenceConfirmation';

import './index.scss';

class CreateProfileModal extends React.PureComponent {
  render() {
    const { handleSubmit, submitting, invalid, pristine, regions, objectTypes, specialties, ...props } = this.props;

    return <Modal
      className="create-profile-modal"
      title="Создать профиль"
      {...props}
    >
      <Form onSubmit={handleSubmit} className="create-profile-modal-form">
        <UserInfo />
        <Field
          name="address"
          component={FormGroupField}
          type="text"
          label="Фактический адрес (для официальной переписки)"
        />
        <CompetenceConfirmation
          regions={regions}
          specialties={specialties}
          objectTypes={objectTypes}
          hiddenFields={[
            'userDocuments.expertAccreditationFile',
            'expertAccreditationNumber',
            'userProjectComponents',
            'comment',
          ]}
        />
        <div className="actions">
          <Button onClick={this.toggleModal} isBorderless isLarge>Отмена</Button>
          <Button
            isLarge
            isDisabled={pristine || submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    regions: PropTypes.array,
    objectTypes: PropTypes.array,
    specialties: PropTypes.array,
  };

  static defaultProps = {
    regions: [],
    objectTypes: [],
    specialties: [],
    handleSubmit() {},
    submitting: false,
  };
}

export default reduxForm({
  destroyOnUnmount: false,
  form: 'createProfileModal',
  validate: getValidation(['email']),
  onSubmitFail: getSubmitValidation,
})(CreateProfileModal);
