import { compose, withContext, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

import AuthorizedApi from '../../api/authorized';

const INSERT_FILES_MUTATION = loader('../queries/file/insert.graphql');
const UPDATE_FILES_MUTATION = loader('../queries/file/update.graphql');
const INSERT_PROJECT_COMPONENT_FILES_MUTATION = loader('../queries/object/insertProjectComponentFiles.graphql');
const INSERT_PROJECT_COMPONENT_FILES_VERSION_MUTATION = loader('../queries/object/insertProjectComponentFilesVersion.graphql');
const DELETE_PROJECT_COMPONENT_FILES_MUTATION = loader('../queries/object/deleteProjectComponentFiles.graphql');

const withInsertFilesMutation = graphql(INSERT_FILES_MUTATION, {
  name: 'uploadFiles',
});

const withInsertProjectComponentFilesMutation = graphql(INSERT_PROJECT_COMPONENT_FILES_MUTATION, {
  name: 'insertProjectComponentFiles',
});

const withUpdateFileMutation = graphql(UPDATE_FILES_MUTATION, {
  name: 'updateFile',
});

const withInsertProjectComponentFilesVersionMutation = graphql(INSERT_PROJECT_COMPONENT_FILES_VERSION_MUTATION, {
  name: 'insertProjectComponentFilesVersion',
});

const withDeleteProjectComponentFilesMutation = graphql(DELETE_PROJECT_COMPONENT_FILES_MUTATION, {
  name: 'deleteProjectComponentFiles',
});

const mergeProps = withProps((
  {
    objectProjectComponentId,
    versionId: externalVersionId,
    versionNumber,
    contractId,
    phaseId,
    uploadFiles,
    updateFile,
    insertProjectComponentFiles,
    insertProjectComponentFilesVersion,
    deleteProjectComponentFiles,
  },
) => {
  return {
    onFileUpload: AuthorizedApi.uploadFile,
    uploadFiles: files => uploadFiles({
      variables: {
        filesInsert: files,
        filesDelete: [],
      },
    }),
    insertFilesVersion: () => insertProjectComponentFilesVersion({
      variables: {
        version: {
          number: versionNumber,
          objectProjectComponentId: Number(objectProjectComponentId),
          contractId,
        },
      },
    }),
    insertFiles: (filesIds, type, versionId = externalVersionId) => insertProjectComponentFiles({
      variables: {
        files: filesIds.map(fileId => ({
          fileId,
          objectProjectComponentId: Number(objectProjectComponentId),
          type,
          ...(versionId ? { versionId } : {}),
          ...(phaseId ? { phaseId } : {}),
        })),
      },
    }),
    updateFile: (fileId, file) => updateFile({
      variables: {
        id: fileId,
        file,
      },
    }),
    deleteFile: id => deleteProjectComponentFiles({
      variables: {
        ids: [id],
      },
    }),
  };
});

export default compose(
  withInsertFilesMutation,
  withUpdateFileMutation,
  withInsertProjectComponentFilesMutation,
  withInsertProjectComponentFilesVersionMutation,
  withDeleteProjectComponentFilesMutation,
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
);
