import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import {
  getProjectComponentNames,
  PROJECT_COMPONENT_ORDER,
} from '../model/projectComponent';

const GET_PROJECT_COMPONENT_CHILDREN_QUERY = loader('../queries/object/getProjectComponentChildren.graphql');

export default graphql(GET_PROJECT_COMPONENT_CHILDREN_QUERY, {
  name: 'getProjectComponentChildren',
  skip: ({ projectComponentId }) => !projectComponentId,
  options: ({ projectComponentId }) => ({
    variables: {
      projectComponentId,
      projectComponentOrder: PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ getProjectComponentChildren: { projectComponents, loading: isLoading } }) => ({
    projectComponents: getProjectComponentNames(projectComponents),
    isLoading,
  }),
});
