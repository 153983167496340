export const getTaxSystems = (items, selectedItems) => {
  return (items || []).map(({ id, name, description, isOrganization }) => ({
    value: id,
    label: name,
    isOrganization,
    ...(selectedItems && { checked: selectedItems.indexOf(id) > -1 }),
    className: 'node-hint',
    actions: [{ className: 'hint-text', text: description }],
  }));
};
