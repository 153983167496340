import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import orderBy from 'lodash/orderBy';

import AssignObjectToTariffModal from './index';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';

const FORM_NAME = 'AssignObjectToTariff';
const formSelector = formValueSelector(FORM_NAME);

const GET_OBJECTS_LIST_BY_USER_QUERY = loader('../../../graphql/queries/object/getListByUser.graphql');
const ASSIGN_OBJECT_TO_TARIFF_MUTATION = loader('../../../graphql/queries/object/assignObjectToTariff.graphql');

const withAssignObjectToTariffMutation = graphql(ASSIGN_OBJECT_TO_TARIFF_MUTATION, {
  name: 'assignObjectToTariff',
});

const withGetObjectsListByUserQuery = graphql(GET_OBJECTS_LIST_BY_USER_QUERY, {
  name: 'getListByUser',
  skip: ({ userId, tariffPlanId }) => !userId || !tariffPlanId,
  options: ({ userId }) => ({
    variables: {
      userId,
      where: { userId: { _eq: userId } },
      withObjectTariffs: true,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getListByUser, ownProps: { tariffPlanId: selectedTariffPlanId } }) => {
    const {
      items = [],
      error,
      loading: isLoading,
    } = getListByUser;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      objects: orderBy(items
        .filter(({ userTariffPlan }) => userTariffPlan?.id !== selectedTariffPlanId)
        .map(({ name, id }) => ({ label: name, value: id })), ['label']),
      isLoading,
    };
  },
});

const mapStateToProps = state => ({
  userId: state.session.user.id,
  objectId: formSelector(state, 'object'),
});

const mergeProps = withProps(({ assignObjectToTariff, objectId }) => ({
  onAssignObject: tariffPlanId => {
    return assignObjectToTariff({
      variables: {
        objectId,
        tariffPlanId,
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    });
  },
}));

export default compose(
  connect(mapStateToProps),
  withAssignObjectToTariffMutation,
  withGetObjectsListByUserQuery,
  mergeProps,
  reduxForm({
    form: FORM_NAME,
  }),
)(AssignObjectToTariffModal);
