import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import cloneDeep from 'lodash/cloneDeep';

import FormGroupField from '../../../base/formGroup';
import { TIME_ZONES } from '../../../../utils/timezones';

import './index.scss';
import Button from '../../../base/button';
import { ICONS } from '../../../../utils/icons';

class AccountData extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      timezones: TIME_ZONES,
    };
  }

  componentDidMount() {
    const { timezone } = this.props.user;

    if (timezone) {
      this.setTimeZone(timezone);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { timezone } = this.props.user;
    const { timezone: prevTimezone } = prevProps.user;

    if (timezone !== prevTimezone) {
      this.setTimeZone(timezone);
    }
  }

  setTimeZone = selectedTimezone => {
    const timezones = cloneDeep(TIME_ZONES);

    timezones.forEach(timezone => {
      timezone.checked = timezone.value === selectedTimezone;
    });

    this.setState({ timezones });
  };

  render() {
    const { timezones } = this.state;
    const { user: { email, isEmailConfirmed }, confirmEmail, isValidEmail } = this.props;

    return <div className="form-card account-data-layout">
      <h5>Данные аккаунта</h5>
      <div className="email">
        <Field
          name="email"
          component={FormGroupField}
          type="text"
          label="E-mail"
        />
        {email && !isValidEmail && !isEmailConfirmed && <Button
          isBorderless
          IconComponent={ICONS.letter}
          onClick={() => confirmEmail(email)}
        >Подтвердить
        </Button>}
      </div>
      <Field
        name="timezone"
        component={FormGroupField}
        type="select"
        label="Часовой пояс"
        placeholder="Выберите ваш часовой пояс"
        data={timezones}
      />
      <Field
        name="currentPassword"
        component={FormGroupField}
        type="password"
        label="Текущий пароль"
      />
      <Field
        name="newPassword"
        component={FormGroupField}
        type="password"
        label="Новый пароль"
      />
      <Field
        name="repeatNewPassword"
        component={FormGroupField}
        type="password"
        label="Подтвердите новый пароль"
      />
    </div>;
  }
}

AccountData.propTypes = {
  user: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  isValidEmail: PropTypes.bool.isRequired,
};

export default AccountData;
