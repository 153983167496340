import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Tooltip } from 'react-bootstrap';

import './index.scss';

const popperConfig = {
  modifiers: [
    {
      name: 'flip',
      enabled: true,
      options: {
        fallbackPlacements: ['top', 'right'],
        flipVariations: false,
      },
    },
  ],
};

export default class OverflowTip extends Component {
  state = {
    isOverflowed: false,
    isHovered: false,
  };

  textElement = React.createRef();

  handleMouseOver = () => {
    this.overflowCalc(true);
  };

  handleMouseLeave = () => {
    this.overflowCalc(false);
  };

  overflowCalc = isHovered => {
    const { current } = this.textElement;
    if (!current) {
      return;
    }

    this.setState({
      isOverflowed: current.scrollWidth > current.clientWidth,
      isHovered,
    });
  };

  render () {
    const { children, className, tooltipText, showAlways } = this.props;
    const { isOverflowed, isHovered } = this.state;

    return <>
      <Overlay
        placement="auto"
        container={document.body}
        show={isHovered && (showAlways || isOverflowed)}
        target={this.textElement.current}
        popperConfig={popperConfig}
      >
        <Tooltip className={className} id={0}>
          {tooltipText || children}
        </Tooltip>
      </Overlay>
      <span
        ref={this.textElement}
        className="overflow-tip"
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </span>
    </>;
  }

  static propTypes = {
    tooltipText: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    className: PropTypes.string,
    showAlways: PropTypes.bool,
  };

  static defaultProps = {
    tooltipText: '',
    children: '',
    className: '',
    showAlways: false,
  }
}
