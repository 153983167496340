import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';

import {
  getObjects,
  setStageEngineers,
  setFlattenedStageProjectComponents,
  getObjectsQuery,
} from '../../../graphql/model/object';
import Objects from './index';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import {
  getObjectProjectComponentQuery,
  OBJECT_PROJECT_COMPONENT_ORDER,
} from '../../../graphql/model/projectComponent';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const ON_OBJECT_LIST_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/object/listSubscription.graphql');
const ON_OBJECT_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/object/countSubscription.graphql');

const ORDER_BY = {
  createdAt: {
    title: 'Дата создания',
    graphql: { createdAt: 'desc' },
    default: true,
  },
  deadline: {
    title: 'Срок сдачи',
    graphql: { deadline: 'asc' },
  },
};

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

const mergeProps = withProps(({ userId, isOwner, isArbitration, isClosed }) => ({
  where: getObjectsQuery({ userId, isOwner, isArbitration, isClosed }),
}));

const withObjectsSubscription = withResubscribe(ON_OBJECT_LIST_UPDATE_SUBSCRIPTION, {
  name: 'objectListSub',
  options: ({ userId, order, where, offset, limit }) => ({
    variables: {
      offset,
      limit,
      order,
      userId,
      where,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
      projectComponentWhere: getObjectProjectComponentQuery(userId),
    },
  }),
  props: ({ objectListSub: { items = [], error, loading: isLoading }, ownProps: { userId, isArbitration } }, previousResult) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const formattedObjects = getObjects(items, userId, isArbitration);
    if (formattedObjects) {
      formattedObjects.forEach(object => {
        setFlattenedStageProjectComponents(object.stages);
        setStageEngineers(object.stages);
        object.isChiefEngineer = object.stages.some(({ chiefEngineer }) => chiefEngineer?.userId === userId);
      });
    }

    return {
      // we return previous messages if resubscribe was started (has 0 messages on loading moment)
      objects: isLoading && previousResult?.objects.length > 0 ? previousResult.objects : formattedObjects,
      isLoading,
    };
  },
});

const withObjectsCountSubscription = withResubscribe(ON_OBJECT_COUNT_UPDATE_SUBSCRIPTION, {
  name: 'objectListCountSub',
  options: ({ where }) => ({
    variables: {
      where,
    },
  }),
  props: ({ objectListCountSub: { count, error, loading: isCountLoading }, ownProps: { isLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      count: count ? count.aggregate.count : 0,
      isLoading: isLoading && isCountLoading,
    };
  },
});

export default compose(
  connect(mapStateToProps),
  defaultProps({
    offset: 0,
    limit: 10,
    orderBy: ORDER_BY,
  }),
  mergeProps,
  withStateHandlers(
    ({ limit, orderBy, where }) => ({ step: limit, order: getOrder(orderBy), where }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      search: (_, { where, userId, isOwner }) => value => ({
        where: value ? getObjectsQuery({ userId, isOwner, isArbitration: false, search: `%${value}%` }) : where,
      }),
    },
  ),
  withObjectsSubscription,
  withObjectsCountSubscription,
)(Objects);
