import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../../components/base/menu';

class ObjectProjectComponentMenu extends PureComponent {
  getStatus = index => {
    const { items, currentProjectComponentId } = this.props;

    return currentProjectComponentId
      ? currentProjectComponentId === items[index].id
      : index === 0;
  };

  handleRedirect = index => {
    const { goTo, items } = this.props;

    return goTo(items[index].id);
  };

  render() {
    const { items } = this.props;

    return <div className="page-header-menu">
      <Menu items={items} setActive={this.getStatus} onClick={this.handleRedirect} isTabMenu />
    </div>;
  }
}

ObjectProjectComponentMenu.propTypes = {
  items: PropTypes.array.isRequired,
  goTo: PropTypes.func.isRequired,
  currentProjectComponentId: PropTypes.number,
};

ObjectProjectComponentMenu.defaultProps = {
  currentProjectComponentId: null,
};

export default ObjectProjectComponentMenu;
