import { compose, withContext, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

import AuthorizedApi from '../../../api/authorized';
import AddContractFileModal from './index';
import { getContractStoragePath } from '../../../utils/storagePaths';
import { reduxForm } from 'redux-form';
import { CONTRACT_FILE_TYPES } from '../../../utils/file';
import { getValidation } from '../../../utils/validation';

const INSERT_CONTRACT_FILE_MUTATION = loader('../../../graphql/queries/contract/insertFile.graphql');

const withInsertContractFileMutation = graphql(INSERT_CONTRACT_FILE_MUTATION, { name: 'insertContractFile' });

const mergeProps = withProps((
  {
    contractId,
    insertContractFile,
  },
) => ({
  types: Object.keys(CONTRACT_FILE_TYPES).map(type => ({
    label: CONTRACT_FILE_TYPES[type].title,
    value: type,
  })),
  storagePath: getContractStoragePath(contractId),
  onFileUpload: AuthorizedApi.uploadFile,
  insertFiles: ({ files, type }) => insertContractFile({
    variables: {
      files: files.map(({ file }) => ({
        contractId,
        type,
        file: {
          data: file,
        },
      })),
    },
  }),
}));

export default compose(
  withInsertContractFileMutation,
  mergeProps,
  reduxForm({
    form: 'addContractFilesForm',
    validate: getValidation(['type']),
  }),
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
)(AddContractFileModal);
