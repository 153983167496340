import { loader } from 'graphql.macro';
import { branch, compose, defaultProps, withProps, withStateHandlers } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { showError } from '../../../api/error';
import Vacancies from './index';
import { ROUTES } from '../../../utils/routes';
import { formatVacancies, getContractInvitationsQuery, getCompetitionQuery } from '../../../graphql/model/vacancies';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const ORDER_BY = {
  applicationDeadline: {
    title: 'Дата окончания приема заявок',
    graphql: { applicationDeadline: 'asc' },
  },
  deadline: {
    title: 'Дата сдачи работ',
    graphql: { deadline: 'asc' },
    default: true,
  },
  totalAmount: {
    title: 'Сумма',
    graphql: { totalAmount: 'desc' },
  },
};

const INVITATIONS_ORDER_BY = {
  applicationDeadline: {
    title: ORDER_BY.applicationDeadline.title,
    graphql: { contractExpanded: ORDER_BY.applicationDeadline.graphql },
  },
  deadline: {
    title: ORDER_BY.deadline.title,
    graphql: { contractExpanded: ORDER_BY.deadline.graphql },
  },
  totalAmount: {
    title: ORDER_BY.totalAmount.title,
    graphql: { contractExpanded: ORDER_BY.totalAmount.graphql },
  },
};

const ON_CONTRACTS_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contract/listSubscription.graphql');
const ON_CONTRACTS_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contract/countSubscription.graphql');
const ON_CONTRACT_INVITATIONS_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contractInvitation/listSubscription.graphql');
const ON_CONTRACT_INVITATIONS_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contractInvitation/countSubscription.graphql');

const mapStateToProps = ({ session }) => ({
  userId: session.user?.id,
});

const mergeProps = withProps(({ location, userId, isTenders }) => {
  const isInvolved = !!matchPath(location.pathname, isTenders ? ROUTES.involvedTenders : ROUTES.involvedVacancies);
  const isOwn = !!matchPath(location.pathname, isTenders ? ROUTES.myTenders : ROUTES.myVacancies);
  const isOpened = !!matchPath(location.pathname, isTenders ? ROUTES.openedTenders : ROUTES.openedVacancies);
  const isInvitations = !!matchPath(location.pathname, isTenders ? ROUTES.tenderInvitations : ROUTES.vacancyInvitations);

  return {
    isInvolved,
    isOwn,
    isOpened,
    isInvitations,
    orderBy: isInvitations ? INVITATIONS_ORDER_BY : ORDER_BY,
    where: (isInvitations ? getContractInvitationsQuery : getCompetitionQuery)({ userId, isTenders, isInvolved, isOwn, isOpened }),
    goToVacancy: contractId => {
      let vacancyContractProfileRoute = ROUTES.openedVacancyProfile;
      let tenderContractProfileRoute = ROUTES.openedTenderProfile;

      if (isOwn) {
        vacancyContractProfileRoute = ROUTES.myVacancyProfile;
        tenderContractProfileRoute = ROUTES.myTenderProfile;
      } else if (isInvolved) {
        vacancyContractProfileRoute = ROUTES.involvedVacancyProfile;
        tenderContractProfileRoute = ROUTES.involvedTenderProfile;
      } else if (isInvitations) {
        vacancyContractProfileRoute = ROUTES.vacancyInvitationProfile;
        tenderContractProfileRoute = ROUTES.tenderInvitationProfile;
      }

      return (isTenders ? tenderContractProfileRoute : vacancyContractProfileRoute).replace(':contractId', contractId);
    },
  };
});

const getProps = ({ contracts = [], error, loading: isLoading }, { userId, isInvitations }, previousResult) => {
  if (error) {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
  }

  return {
    // we return previous messages if resubscribe was started (has 0 messages on loading moment)
    vacancies: isLoading && previousResult?.vacancies.length > 0
      ? previousResult.vacancies
      : formatVacancies(isInvitations ? contracts.map(({ contractExpanded }) => contractExpanded) : contracts, userId, false),
    isLoading,
  };
};

const getCountProps = ({ count, error, loading: isCountLoading }, { isLoading }) => {
  if (error) {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
  }

  return {
    count: count ? count.aggregate.count : 0,
    isLoading: isCountLoading || isLoading,
  };
};

const contracts = compose(
  withResubscribe(ON_CONTRACTS_UPDATE_SUBSCRIPTION, {
    name: 'contractsSub',
    skip: ({ userId }) => !userId,
    options: ({ where, offset, limit, order, withBids }) => ({
      variables: {
        where,
        offset,
        limit,
        order,
        withBids,
      },
    }),
    props: ({ contractsSub, ownProps }, previousResult) => getProps(contractsSub, ownProps, previousResult),
  }),
  withResubscribe(ON_CONTRACTS_COUNT_UPDATE_SUBSCRIPTION, {
    name: 'contractsCountSub',
    skip: ({ userId }) => !userId,
    options: ({ where }) => ({
      variables: {
        where,
      },
    }),
    props: ({ contractsCountSub, ownProps }) => getCountProps(contractsCountSub, ownProps),
  }),
);

const contractInvitations = compose(
  withResubscribe(ON_CONTRACT_INVITATIONS_UPDATE_SUBSCRIPTION, {
    name: 'contractInvitationsSub',
    skip: ({ userId }) => !userId,
    options: ({ where, offset, limit, order, withBids }) => ({
      variables: {
        where,
        offset,
        limit,
        order,
        withBids,
      },
    }),
    props: ({ contractInvitationsSub, ownProps }, previousResult) => getProps(contractInvitationsSub, ownProps, previousResult),
  }),
  withResubscribe(ON_CONTRACT_INVITATIONS_COUNT_UPDATE_SUBSCRIPTION, {
    name: 'contractInvitationsCountSub',
    skip: ({ userId }) => !userId,
    options: ({ where }) => ({
      variables: {
        where,
      },
    }),
    props: ({ contractInvitationsCountSub, ownProps }) => getCountProps(contractInvitationsCountSub, ownProps),
  }),
);

export default compose(
  connect(mapStateToProps),
  withRouter,
  defaultProps({
    offset: 0,
    limit: 10,
    withBids: true,
  }),
  mergeProps,
  withStateHandlers(
    ({ limit, orderBy, where }) => ({ step: limit, order: getOrder(orderBy), where }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      search: (_, { where, userId, isTenders, isInvolved, isOwn, isOpened, isInvitations }) => value => ({
        where: value ? (isInvitations ? getContractInvitationsQuery : getCompetitionQuery)({ userId, isTenders, isInvolved, isOwn, isOpened, search: value }) : where,
      }),
    },
  ),
  branch(
    ({ isInvitations }) => isInvitations,
    contractInvitations,
    contracts,
  ),
)(Vacancies);
