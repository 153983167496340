import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import { InputGroup } from 'react-bootstrap';

import Hint from '../../base/hint';
import Modal from '../../base/modal';
import Button from '../../base/button';
import FileLink from '../../base/fileLink';
import DataGroup from '../../base/dataGroup';
import FormGroupField from '../../base/formGroup';
import AddFilesSection from '../../base/addFilesSection';
import { getFormattedDate } from '../../../utils/date';
import { getProjectComponentStoragePath, getRemarkStoragePath } from '../../../utils/storagePaths';

import './index.scss';

export default class CreateRemarkModal extends PureComponent {
  handleCancel = isCancelButton => () => {
    const { onRef, onClose, reset } = this.props;

    onClose();
    reset();

    if (isCancelButton) {
      onRef.current.toggleModal();
    }
  };

  handleSubmit = async data => {
    const { onSubmit, onRef, isRevision, isEdit, reset } = this.props;
    await onSubmit(data, isRevision, isEdit);
    reset();
    onRef.current.toggleModal();
  };

  renderReadOnlyField = (id, label, value) => <div
    id={id}
    className="form-group-field form-group custom-input labeled read-only"
  >
    <label>{label}</label>
    <InputGroup>
      <span className="string-container">{value}</span>
    </InputGroup>
  </div>;

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      remarkResponse,
      isRevision,
      initialValues,
      projectComponentId,
      objectId,
      isEdit,
      ...props
    } = this.props;
    let title = remarkResponse ? 'Ответ на замечание' : 'Добавить замечание';
    let buttonTitle = 'Создать';

    if (isRevision) {
      title = 'Отправить на доработку';
    }

    if (isEdit) {
      title = 'Изменить комментарий';
    }

    if (remarkResponse && !isEdit) {
      buttonTitle = 'Ответить';
    } else if (initialValues) {
      buttonTitle = 'Сохранить';
    }

    return <Modal {...props} onClose={this.handleCancel()} className="create-remark-modal" title={title}>
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          name="remark"
          component={FormGroupField}
          type="text"
          label="Замечание"
          placeholder="Введите замечание"
          isReadonly={!!remarkResponse}
        />
        {this.renderReadOnlyField(
          'createdAt',
          'Дата выставления',
          getFormattedDate(remarkResponse?.task.createdAt || new Date()),
        )}
        {this.renderReadOnlyField(
          'objectProjectComponent',
          'Прикреплено к разделу',
          initialValues.objectProjectComponent,
        )}
        {this.renderReadOnlyField(
          'performer',
          'Исполнитель раздела',
          initialValues.performer,
        )}
        {remarkResponse && <>
          <Field
            name="response"
            component={FormGroupField}
            type="textarea"
            label="Ответ на замечание"
            {...(isRevision && {
              format: value => <>
                <p>{value}</p>
                {remarkResponse.responseFiles?.length > 0 && <div className="remark-response-files">
                  <p>Дата ответа: <span>{getFormattedDate(remarkResponse.createdAt)}</span></p>
                  <DataGroup title="Прикрепленные файлы" className="files">
                    {remarkResponse.responseFiles.map(({ file }, index) => <FileLink key={index} file={file} showTitle />)}
                  </DataGroup>
                </div>}
              </>,
            })}
            isReadonly={isRevision}
          />
          {isRevision
            ? <Field
              name="revisionMessage"
              component={FormGroupField}
              type="text"
              label="Объяснение причины"
              placeholder="Введите объяснение причины"
            />
            : <Field
              name="isAgree"
              component={FormGroupField}
              type="switch"
              labelLeft={<>
                С замечаниями не согласен
                <Hint title="Позиция по замечанию">
                  Выбор позиции будет отображен в офицальном ответе на замечания экспертизе
                </Hint>
              </>}
              labelRight="С замечаниями согласен"
            />}
          {!isRevision && !isEdit && remarkResponse.files?.length > 0 && <DataGroup
            title="В раздел загружен файл"
            className="files form-group"
          >
            {remarkResponse.files.map((file, index) => <span key={index}>
              <FileLink file={file} showTitle />
            </span>)}
          </DataGroup>}
        </>}
        <div className="add-file form-group">
          {remarkResponse && !isRevision && !isEdit && <>
            <AddFilesSection
              name="documentationFiles"
              label="Документ"
              buttonText={<>
                Загрузить в раздел исправленную исполнительную документацию
                <Hint title="Исполнительная документация">
                  При загрузке файла исполнительной документации будет создана новая версия.
                  Файл будет добавлен в соответствующую секцию, согласно формату.
                  Если вы уже загрузили актуальную версию раздела в ответе на предыдущее замечание, то повторная загрузка не требуется. Если же ответ на замечание повлёк изменения версии, загрузите актуальную
                </Hint>
              </>}
              storagePath={getProjectComponentStoragePath(projectComponentId, objectId)}
            />
          </>}
          <AddFilesSection
            name="messageFiles"
            label="Официальное письмо"
            buttonText="Прикрепить официальное письмо"
            storagePath={getRemarkStoragePath(projectComponentId, objectId)}
          />
        </div>
        <div className="actions">
          <Button
            onClick={this.handleCancel(true)}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={submitting || pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            {buttonTitle}
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    isRevision: PropTypes.bool,
    remarkResponse: PropTypes.object,
    onRef: PropTypes.object,
    initialValues: PropTypes.object,
    projectComponentId: PropTypes.number.isRequired,
    objectId: PropTypes.number.isRequired,
    isEdit: PropTypes.bool,
    // redux form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    onClose() {},
    isEdit: false,
    isRevision: false,
    remarkResponse: null,
    initialValues: null,
  };
}
