import { compose } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import merge from 'lodash/merge';

import ContractMenu from './index';
import { ROUTES } from '../../utils/routes';
import { FINANCE_CONTRACT_MENU_NAME, MENU_ITEMS, OBJECT_CONTRACT_MENU_NAME } from '../../utils/menu';
import { formatContract, formatContracts } from '../../graphql/model/contracts';
import { showError } from '../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import withContractReferencesQuery from '../../graphql/hoc/contractReferences';
import { getContractQuery } from '../../graphql/model/vacancies';

const GET_CONTRACT_QUERY = loader('../../graphql/queries/contract/get.graphql');
const ON_CONTRACT_UPDATE_SUBSCRIPTION = loader('../../graphql/queries/contract/itemSubscription.graphql');

const mapStateToProps = ({ session }, { location }) => {
  const isObjectMenu = !!matchPath(location.pathname, ROUTES.object);
  const contractMenuRoute = matchPath(
    location.pathname,
    [
      ROUTES.currentObjectContract,
      ROUTES.currentFinanceContract,
      ROUTES.closedObjectContract,
      ROUTES.closedFinanceContract,
      ROUTES.financeContractRequisites,
      ROUTES.arbitrationFinanceContract,
      ROUTES.arbitrationObjectContract,
    ],
  );

  return {
    contractMenu: contractMenuRoute.path === ROUTES.financeContractRequisites
      ? []
      : MENU_ITEMS[isObjectMenu ? OBJECT_CONTRACT_MENU_NAME : FINANCE_CONTRACT_MENU_NAME]
        .find(menuItem => menuItem.route === contractMenuRoute?.path).children,
    defaultRoute: isObjectMenu ? ROUTES.currentObjectContractProfile : ROUTES.currentFinanceContractProfile,
    contractId: contractMenuRoute?.params.contractId,
    userId: session.user?.id,
  };
};

const withGetContractQuery = graphql(GET_CONTRACT_QUERY, {
  name: 'getContract',
  skip: ({ contractId }) => !contractId,
  options: ({ contractId, userId }) => ({
    variables: {
      where: getContractQuery(contractId, false, userId),
      withFiles: true,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getContract: { contract = [], error, loading: isLoading, variables } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryContract: contract[0],
      queryVariables: variables,
      isQueryLoading: isLoading,
    };
  },
});

const withGetContractSub = graphql(ON_CONTRACT_UPDATE_SUBSCRIPTION, {
  name: 'contractSub',
  skip: ({ contractId }) => !contractId,
  options: ({ queryVariables }) => ({
    variables: {
      where: queryVariables.where,
    },
  }),
  props: ({ contractSub: { contract = [{}], error, loading: isLoading }, ownProps: { queryContract, userId, taxSystems, isQueryLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    let formattedContract = {};

    if (queryContract) {
      const updatedContract = merge(queryContract, contract[0]);
      const baseContract = formatContracts([updatedContract], userId)[0];
      formattedContract = {
        ...baseContract,
        ...formatContract(updatedContract, taxSystems, userId),
      };
    }

    return {
      contract: formattedContract,
      isLoading: isLoading || isQueryLoading,
    };
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  withContractReferencesQuery,
  withGetContractQuery,
  withGetContractSub,
)(ContractMenu);
