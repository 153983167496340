export const VACANCY_TYPE = {
  chief: 'ГИП',
  lead: 'Ведущий инженер',
  performer: 'Исполнитель',
  customer: 'Заказчик',
  expert: 'Эксперт',
};

export const GENITIVE_VACANCY_TYPE = {
  chief: 'ГИПа',
  lead: 'Ведущего инженера',
  performer: 'Исполнителя',
  customer: 'Заказчика',
};

export const INSTRUMENTAL_VACANCY_TYPE = {
  chief: 'ГИПом',
  lead: 'Ведущим инженером',
  performer: 'Исполнителем',
  customer: 'Заказчиком',
};
