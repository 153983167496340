import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const GET_REMARK_REFERENCES_QUERY = loader('../../graphql/queries/remark/getReferences.graphql');

export default graphql(GET_REMARK_REFERENCES_QUERY, {
  name: 'getRemarkReferences',
  skip: props => props.isSkip,
  props: ({ getRemarkReferences: { remarkStatus, remarkReviewStatus } }) => ({
    remarkStatus,
    remarkReviewStatus,
  }),
});
