import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { showError } from '../../../api/error';
import Balance from './index';
import { formatTransactions } from '../../../graphql/model/transactions';
import withUserBalanceReferencesSubscription from '../../../graphql/hoc/userBalanceReferences';

const ON_TRANSACTIONS_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/transaction/listSubscription.graphql');

const mapStateToProps = ({ session }) => ({
  userId: session.user?.id,
});

const withGetTransactionsQuery = graphql(ON_TRANSACTIONS_UPDATE_SUBSCRIPTION, {
  name: 'transactionsSub',
  skip: ({ userId }) => !userId,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ transactionsSub: { transactions = [], error, loading: isLoading }, ownProps: { isProfileAggregationSubscriptionLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      transactions: formatTransactions(transactions),
      isLoading: isLoading || isProfileAggregationSubscriptionLoading,
    };
  },
});

export default compose(
  connect(mapStateToProps),
  withUserBalanceReferencesSubscription,
  withGetTransactionsQuery,
)(Balance);
