import difference from 'lodash/difference';

export const getGraphqlUserRegions = ({ userRegions, id }) => userRegions
  ? userRegions.map(regionId => ({
    regionId,
    userId: id,
  }))
  : [];

export const getUserRegionsToDelete = ({ initialUserRegions, userRegions }) => initialUserRegions
  ? difference(initialUserRegions, userRegions)
  : [];
