import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../utils/routes';

import './index.scss';

export default class ProfileLink extends PureComponent {
  render() {
    const { userId, text } = this.props;

    return <Link
      className="profile-link"
      to={{
        pathname: ROUTES.publicProfile.replace(':id', userId),
      }}
    >{text}</Link>;
  }

  static propTypes = {
    text: PropTypes.string,
    userId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    text: '',
  }
}
