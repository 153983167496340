import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import WorkBalance from './workBalance';
import ApplicationTerms from './applicationTerms/container';

import '../../finance/contracts/list/index.scss';

export default class ContractProfile extends React.PureComponent {
  render() {
    const { contract, taxSystems, admissionTypes, userId } = this.props;
    const { object, isPerformer, paymentTerms, balance, customer: { userId: customerUserId } } = contract;

    return <div className="contract-profile-container">
      <WorkBalance balance={balance} pay={noop} isCustomer={customerUserId === userId} />
      <div className="divider" />
      <ApplicationTerms
        object={object}
        paymentTermsProps={paymentTerms.props}
        isReadonly
        isCompetition={false}
        isPerformer={isPerformer}
        taxSystems={taxSystems}
        admissionTypes={admissionTypes}
        initialValues={contract}
      />
    </div>;
  }

  static propTypes = {
    contract: PropTypes.object.isRequired,
    taxSystems: PropTypes.array.isRequired,
    admissionTypes: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
  };
}
