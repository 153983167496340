import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import Loading from '../../components/base/loading';
import ContractHeader from '../../components/contract/header';
import NotImplementedYet from '../../components/notImplementedYet';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';
import { ROUTES } from '../../utils/routes';
import BreakContractModal from '../../components/modals/breakContract/container';
import ContractReviewModal from '../../components/modals/reviewContract/container';

export default class ContractMenu extends PureComponent {
  breakContractModalRef = React.createRef();
  reviewModalRef = React.createRef();

  showBreakContractModal = () => {
    this.breakContractModalRef.current.toggleModal();
  };

  handleContractReviewModal = () => {
    this.reviewModalRef.current.toggleModal();
  };

  render() {
    const {
      isLoading,
      contractMenu,
      defaultRoute,
      contract,
      taxSystems,
      admissionTypes,
      userId,
    } = this.props;

    if (Object.keys(contract).length === 0) {
      return isLoading ? <Loading /> : <Redirect to={ROUTES.notFound} />;
    }

    return <>
      <ContractHeader
        contract={contract}
        menu={contractMenu}
        onClickBreakContract={this.showBreakContractModal}
        onClickReview={this.handleContractReviewModal}
      />
      <div className="divider" />
      <Switch>
        {contractMenu.map(({ route, name, component: Component = NotImplementedYet }, index) => <WebAnalyticsRoute
          key={index}
          path={route}
          name={name}
          render={props => <Component
            {...props}
            contract={contract}
            taxSystems={taxSystems}
            admissionTypes={admissionTypes}
            userId={userId}
          />}
        />)}
        <Redirect to={defaultRoute} />
      </Switch>
      <BreakContractModal
        onRef={this.breakContractModalRef}
        contractId={contract.id}
        currentPhase={contract.phase}
      />
      <ContractReviewModal onRef={this.reviewModalRef} contract={contract} />
    </>;
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    contractMenu: PropTypes.array.isRequired,
    defaultRoute: PropTypes.string.isRequired,
    contract: PropTypes.object.isRequired,
    taxSystems: PropTypes.array.isRequired,
    admissionTypes: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isLoading: false,
  }
}
