export const STORAGE_PATHS = {
  profilePhoto: 'profile/photo/',
  profileDocs: 'profile/docs/',
  organizationContractProfile: 'contractProfile/organization/docs/',
};

export const getObjectStoragePath = objectId => `object/${objectId}/docs/`;

export const getProjectComponentStoragePath = (projectComponentId, objectId) =>
  `object/${objectId}/${projectComponentId}/docs/`;

export const getRemarkStoragePath = (projectComponentId, objectId) =>
  `object/${objectId}/${projectComponentId}/remarks/docs/`;

export const getContractStoragePath = contractId => `/contract/${contractId}/docs/`;

export const getTaskStoragePath = objectId => `object/${objectId}/task/docs/`;
