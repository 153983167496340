import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ICONS } from '../../../utils/icons';
import Button from '../../base/button';

import './index.scss';

export default class BlockedObjectComponent extends Component {
  render() {
    const { tariffPlan, onClick } = this.props;
    const headerMessage = tariffPlan ? `Срок продления тарифного плана ${tariffPlan} истек!` : 'Доступ к объекту закрыт!';
    const infoMessage = `${tariffPlan ? 'Доступ к объекту закрыт. ' : ''}Все загруженные файлы объекта удалены!`;

    return <div className="blocked-object-wrapper">
      <h6 className="font-weight-bold">{headerMessage}</h6>
      <div className="info">
        <ICONS.infoBold className="info-icon" />
        <p>{infoMessage}</p>
      </div>
      <div className="actions">
        <Button
          isLarge
          onClick={onClick}
        >
          Отмена
        </Button>
      </div>
    </div>;
  }

  static propTypes = {
    tariffPlan: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tariffPlan: null,
  }
}
