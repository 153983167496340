import { compose, withContext, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

import ObjectFiles from './index';
import AuthorizedApi from '../../../../../api/authorized';
import { getObjectStoragePath } from '../../../../../utils/storagePaths';

const INSERT_FILES_QUERY = loader('../../../../../graphql/queries/file/insert.graphql');
const INSERT_OBJECT_FILES_MUTATION = loader('../../../../../graphql/queries/object/insertFiles.graphql');
const DELETE_OBJECT_FILES_MUTATION = loader('../../../../../graphql/queries/object/deleteFiles.graphql');

const withInsertFilesMutation = graphql(INSERT_FILES_QUERY, {
  name: 'uploadFiles',
});

const withInsertObjectFilesMutation = graphql(INSERT_OBJECT_FILES_MUTATION, {
  name: 'insertObjectFiles',
});

const withDeleteObjectFilesMutation = graphql(DELETE_OBJECT_FILES_MUTATION, {
  name: 'deleteObjectFiles',
});

const mergeProps = withProps((
  {
    objectId,
    storagePath,
    uploadFiles,
    insertObjectFiles,
    deleteObjectFiles,
  },
) => ({
  storagePath: storagePath || getObjectStoragePath(objectId),
  onFileUpload: AuthorizedApi.uploadFile,
  uploadFiles: files => uploadFiles({
    variables: {
      filesInsert: files,
      filesDelete: [],
    },
  }),
  insertFiles: (filesIds, type) => insertObjectFiles({
    variables: {
      files: filesIds.map(fileId => ({
        fileId,
        objectId: Number(objectId),
        type,
      })),
    },
  }),
  deleteFile: id => deleteObjectFiles({
    variables: {
      ids: [id],
    },
  }),
}));

export default compose(
  withInsertFilesMutation,
  withInsertObjectFilesMutation,
  withDeleteObjectFilesMutation,
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
)(ObjectFiles);
