import moment from 'moment';
import transform from 'lodash/transform';
import { normalizeMoney } from '../../utils/money';

export const formatTransactions = transactions => {
  return transform(
    (transactions || []).reduce((result, { id, createdAt, description, contractId, contract, credit, debit }) => {
      const [month, year] = moment(createdAt).format('MMMM YYYY').split(' ');
      const payment = {
        id,
        createdAt,
        description,
        contractId,
        contractStatus: contract?.status || {},
        income: normalizeMoney(debit),
        outcome: normalizeMoney(credit),
      };

      if (result[year]) {
        if (result[year].transactions[month]) {
          result[year].transactions[month].items.push(payment);
          result[year].transactions[month].total.income += payment.income;
          result[year].transactions[month].total.outcome += payment.outcome;
        } else {
          result[year].transactions[month] = {
            items: [payment],
            total: {
              income: payment.income,
              outcome: payment.outcome,
            },
          };
        }
        result[year].total.income += payment.income;
        result[year].total.outcome += payment.outcome;
      } else {
        result[year] = {
          transactions: {
            [month]: {
              items: [payment],
              total: {
                income: payment.income,
                outcome: payment.outcome,
              },
            },
          },
          total: {
            income: payment.income,
            outcome: payment.outcome,
          },
        };
      }

      return result;
    }, {}),
    (result, { total, transactions }, year) => {
      result.push({
        year,
        payments: {
          total,
          transactions: transform(
            transactions,
            (result, transaction, month) => {
              result.push({
                month,
                ...transaction,
              });
            },
            [],
          ),
        },
      });
    },
    [],
  ).sort((a, b) => b.year - a.year);
};
