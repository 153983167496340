import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProjectComponentItem from './item';
import { getProjectComponentStoragePath } from '../../../../../utils/storagePaths';
import { goTo } from '../../../../../utils/menu';
import { ROUTES } from '../../../../../utils/routes';

import './index.scss';

export default class ProjectComponentList extends PureComponent {
  prevStageId;
  projectComponentRefs = this.props.items.reduce((refs, projectComponent) => {
    refs[projectComponent.id] = React.createRef();
    return refs;
  }, {});

  componentDidMount() {
    const { selectedProjectComponentId } = this.props;

    if (selectedProjectComponentId && this.projectComponentRefs[selectedProjectComponentId]) {
      this.projectComponentRefs[selectedProjectComponentId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  handleClickRemarks = remarkId => {
    const { objectId, stageId, projectComponentId } = this.props;

    goTo(ROUTES.projectComponentRemark, { id: objectId, stageId, projectComponentId: projectComponentId, remarkId });
  };

  render() {
    const {
      stageId,
      objectId,
      items,
      userId,
      selectedProjectComponentId,
      isExpert,
      onClickCreatePerformerVacancy,
      onClickDeleteVacancy,
      onClickTurnInWork,
      onClickAcceptWork,
      onClickBreakContract,
      onClickMakeArbitrationDecision,
      onClickCopyVersionFiles,
      onClickOpenChat,
      onCreateDataRequest,
      showInfoModal,
    } = this.props;

    if (stageId !== this.prevStageId) {
      this.prevStageId = stageId;
      this.itemExpandedStates = {};
    }

    return <div className="project-component-list-wrapper">
      {items.map((projectComponent, index) => <ProjectComponentItem
        onRef={this.projectComponentRefs[projectComponent.id]}
        key={index}
        projectComponent={projectComponent}
        selectedProjectComponentId={selectedProjectComponentId}
        storageFilesPath={getProjectComponentStoragePath(projectComponent.id, objectId)}
        userId={userId}
        isExpert={isExpert}
        isDefaultExpanded={selectedProjectComponentId === projectComponent.id}
        onClickCreatePerformerVacancy={onClickCreatePerformerVacancy}
        onClickDeleteVacancy={onClickDeleteVacancy}
        onClickTurnInWork={onClickTurnInWork}
        onClickAcceptWork={onClickAcceptWork}
        onClickBreakContract={onClickBreakContract}
        onClickRemarks={() => this.handleClickRemarks(projectComponent.id)}
        onClickMakeArbitrationDecision={onClickMakeArbitrationDecision}
        onClickCopyVersionFiles={onClickCopyVersionFiles}
        onClickOpenChat={onClickOpenChat}
        onCreateDataRequest={onCreateDataRequest}
        showInfoModal={showInfoModal}
      />)}
    </div>;
  }

  static propTypes = {
    stageId: PropTypes.number.isRequired,
    objectId: PropTypes.number.isRequired,
    selectedProjectComponentId: PropTypes.number.isRequired,
    items: PropTypes.array,
    projectComponentId: PropTypes.number,
    userId: PropTypes.number.isRequired,
    isExpert: PropTypes.bool,
    onClickCreatePerformerVacancy: PropTypes.func,
    onClickDeleteVacancy: PropTypes.func.isRequired,
    onClickTurnInWork: PropTypes.func,
    onClickAcceptWork: PropTypes.func,
    onClickBreakContract: PropTypes.func,
    onClickMakeArbitrationDecision: PropTypes.func,
    onClickOpenChat: PropTypes.func,
    onClickCopyVersionFiles: PropTypes.func.isRequired,
    onCreateDataRequest: PropTypes.func.isRequired,
    showInfoModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: [],
    isExpert: false,
    projectComponentId: null,
    engineers: [],
    onClickCreatePerformerVacancy() {},
    onClickTurnInWork() {},
    onClickAcceptWork() {},
    onClickBreakContract() {},
    onClickMakeArbitrationDecision() {},
    onClickOpenChat() {},
  };
}
