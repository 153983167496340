import set from 'lodash/set';

import { getEmployee } from './employee';
import { PERFORMER_ROLE_ID } from '../../pages/ratings/list/container';

// TODO: determine this id from database
const CUSTOMER_ROLE_ID = 4;
const EVALUATION_COEFFICIENT = 10;
export const convertRating = value => Math.ceil(value / 1000) / 2;

export const formatRating = (ratings, userRoles) => (ratings || []).reduce(
  (result, { value: balance, userRole: { name } }) =>
    Object.assign(result, { [name]: convertRating(balance) }),
  userRoles ? userRoles.reduce((result, role) => Object.assign(result, { [role]: 0 }), {}) : {},
);

export const normalizeEvaluation = evaluation => evaluation * 10;
export const convertEvaluation = evaluation => evaluation / EVALUATION_COEFFICIENT;
export const formatEvaluation = ({ customerEvaluation, employeeEvaluation, customerReview, employeeReview }, isCustomer, isPublicPage) => {
  const evaluation = isPublicPage || isCustomer ? customerEvaluation : employeeEvaluation;
  const review = isPublicPage || isCustomer ? customerReview : employeeReview;

  return {
    value: convertEvaluation(evaluation),
    review: review,
  };
};

const getIsInFavorite = (inFavorite, userRoleId) => {
  if (inFavorite.length === 0) {
    return true;
  } else if (inFavorite.length === 2) {
    return false;
  } else {
    const firstFavoriteIsPerformer = inFavorite[0].isPerformer;

    return userRoleId === PERFORMER_ROLE_ID ? !firstFavoriteIsPerformer : firstFavoriteIsPerformer;
  }
};

export const formatRatings = (ratings, userAggregation = []) => (ratings || [])
  .map(({ position, avg, id, value, user = {}, userRole }) => {
    const objectsCount = userAggregation.find(({ userId }) => userId === user?.id)?.objectsCount;
    return user
      ? ({
        id: user.id,
        showAddToFavoriteButton: user.isPublic && userRole.id !== CUSTOMER_ROLE_ID &&
          getIsInFavorite(user.inFavorite, userRole.id),
        ...set(getEmployee(user), 'ratings.position', position),
        isReadable: true,
        objectsCount,
      })
      : ({
        id,
        showAddToFavoriteButton: false,
        ratings: {
          ...formatRating([{ value, userRole }]),
          position: position,
          avg: convertRating(avg),
        },
      });
  });

export const getRatingsQuery = (userRoleId, performerRatingGroupId, search) => {
  const whereQuery = {
    _and: [
      {
        userRoleId: { _eq: userRoleId },
      },
      {
        performerRatingGroupId: { _eq: performerRatingGroupId },
      },
    ],
  };
  if (search && search !== '') {
    whereQuery._or = [
      {
        user: {
          _or: [
            {
              organizationContractDetails: {
                _or: [
                  {
                    name: { _ilike: search },
                  },
                  {
                    firstName: { _ilike: search },
                  },
                  {
                    lastName: { _ilike: search },
                  },
                  {
                    patronymic: { _ilike: search },
                  },
                  {
                    taxSystem: {
                      name: { _ilike: search },
                    },
                  },
                ],
              },
            },
            {
              selfContractDetails: {
                _or: [
                  {
                    firstName: { _ilike: search },
                  },
                  {
                    lastName: { _ilike: search },
                  },
                  {
                    patronymic: { _ilike: search },
                  },
                  {
                    taxSystem: {
                      name: { _ilike: search },
                    },
                  },
                ],
              },
            },
            {
              firstName: { _ilike: search },
            },
            {
              lastName: { _ilike: search },
            },
            {
              patronymic: { _ilike: search },
            },
          ],
        },
      },
    ];
  }
  return whereQuery;
};
