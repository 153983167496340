import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import noop from 'lodash/noop';

import Button from '../../../base/button';
import FormGroupField from '../../../base/formGroup';
import { getFormattedDate } from '../../../../utils/date';
import WorkConditions from '../workConditions';
import { ICONS } from '../../../../utils/icons';
import { VACANCY_TYPE } from '../../../../utils/vacancyType';

import './index.scss';

export default class ApplicationTerms extends React.PureComponent {
  getActionButton = () => {
    const {
      submitting,
      isCompetition,
      isReadonly,
    } = this.props;
    if (isCompetition && !isReadonly) {
      return <Button
        isDisabled={submitting}
        isLoading={submitting}
        buttonProps={{
          type: 'submit',
        }}
        isLarge
      >
        Сохранить
      </Button>;
    } else if (!isCompetition && isReadonly) {
      return <Button
        onClick={this.onSubmit}
        isLarge
        isDisabled
      >
        Внести изменения в договор
      </Button>;
    }
  };

  render() {
    const {
      initialValues,
      object,
      paymentTermsProps,
      handleSubmit,
      onUpdateCompetitionType,
      taxSystems,
      admissionTypes,
      isReadonly,
      isCompetition,
      isPerformer,
      change,
      onClickOpenChat,
    } = this.props;
    const { deadline, isTariffActive } = object;
    const chatButtonTitle = `Чат по ${initialValues.vacancyTypeName === VACANCY_TYPE.performer ? 'лоту' : 'вакансии'}`;

    return <>
      <Form onSubmit={handleSubmit}>
        <div className="contract-terms">
          <div className="header">
            {isCompetition
              ? <>
                <h5>Подробная информация о конкурсе</h5>
                {isTariffActive && <Button
                  className="chat"
                  onClick={onClickOpenChat}
                  IconComponent={ICONS.chat}
                  isBorderless
                >
                  {chatButtonTitle}
                </Button>}
                <div>
                  <span>Тип конкурса:</span>
                  <Field
                    name="competitionTerms.isOpen"
                    component={FormGroupField}
                    type="switch"
                    labelLeft="Закрытый"
                    labelRight="Открытый"
                    isDisabled={isReadonly}
                    onChange={onUpdateCompetitionType}
                  />
                </div>
              </>
              : <>
                <h5>Условия договора</h5>
                {isTariffActive && <span>
                  Дата окончания всех этапов&nbsp;
                  <span className="deadline colored">{getFormattedDate(deadline, true)}</span>
                </span>}
              </>}
          </div>
          <Field
            name="customer.generatedName"
            component={FormGroupField}
            type="text"
            label="Заказчик"
            isDisabled
            inputWarning={`(ИНН ${initialValues.customer.inn})`}
          />
          <Field
            name={initialValues.employee ? 'employee.generatedName' : 'vacancyTypeName'}
            component={FormGroupField}
            type="text"
            label={initialValues.employee ? 'Исполнитель работ' : 'Тип вакансии'}
            isDisabled
            {...(initialValues.employee ? { inputWarning: `(ИНН ${initialValues.employee.inn})` } : {})}
          />
          <Field
            name="involved"
            component={FormGroupField}
            type="textarea"
            label="Раздел"
            placeholder="Выбрать значение"
            isDisabled
          />
          <WorkConditions
            taxSystems={taxSystems}
            isReadonly={isReadonly}
            isCompetition={isCompetition}
            isPerformer={isPerformer}
            paymentTermsProps={paymentTermsProps}
            admissionTypes={admissionTypes}
            initialValues={initialValues}
            change={change}
          />
        </div>
        <div className="actions">
          {isTariffActive && this.getActionButton()}
          <Button
            className="print"
            onClick={noop}
            IconComponent={ICONS.printer}
            isBorderless
          >
            Версия для печати
          </Button>
        </div>
      </Form>
    </>;
  }

  static propTypes = {
    initialValues: PropTypes.object,
    object: PropTypes.object.isRequired,
    paymentTermsProps: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    isReadonly: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onUpdateCompetitionType: PropTypes.func,
    change: PropTypes.func.isRequired,
    taxSystems: PropTypes.array.isRequired,
    admissionTypes: PropTypes.array.isRequired,
    onClickOpenChat: PropTypes.func,
    isCompetition: PropTypes.bool,
    isPerformer: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: {},
    isCompetition: false,
    isPerformer: false,
    onUpdateCompetitionType() {},
    onClickOpenChat() {},
  };
}
