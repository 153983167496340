import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import isString from 'lodash/isString';
import get from 'lodash/get';
import set from 'lodash/set';
import findKey from 'lodash/findKey';

import Button from '../../base/button';
import Input from '../../base/input';
import { ICONS } from '../../../utils/icons';
import Menu from '../menu';

import './index.scss';

const getSortingGraphqlPath = (graphqlObject, path = '', result = []) => {
  Object.keys(graphqlObject).forEach(key => {
    if (!isString(graphqlObject[key])) {
      path += key + '.';
      path = getSortingGraphqlPath(graphqlObject[key], path, result);
    } else {
      result.push(path + key);
    }
  });

  return result;
};

class ListHeader extends PureComponent {
  constructor(props) {
    super(props);

    const { orderBy, onSearch } = this.props;
    const sortingName = findKey(orderBy, ({ default: defaultSort }) => defaultSort) || Object.keys(orderBy)[0];

    this.state = {
      selectedSorting: { name: sortingName, isAsc: this.getIsAsc(orderBy[sortingName].graphql, sortingName) },
      selectedMenuItem: 0,
    };

    if (onSearch) {
      this.throttleSearch = throttle(onSearch, 1000, { leading: false });
      this.searchRef = React.createRef();
    }
  }

  getIsAsc = (orderBy, sortingName) => get(orderBy, sortingName) === 'asc';

  handleSearch = event => {
    this.throttleSearch(event.target.value);
  };

  componentWillUnmount() {
    if (this.throttleSearch) {
      this.throttleSearch.cancel();
    }
  }

  handleSetOrder = name => () => {
    const { selectedSorting } = this.state;
    const { orderBy, setOrder } = this.props;
    const orders = getSortingGraphqlPath(orderBy[name].graphql);
    if (name === selectedSorting.name) {
      orders.forEach(path => {
        const currentOrder = get(orderBy[name].graphql, path);

        if (currentOrder === 'asc') {
          set(orderBy[name].graphql, path, 'desc');
        } else {
          set(orderBy[name].graphql, path, 'asc');
        }
      });
    }
    setOrder(orderBy[name].graphql);
    this.setState({ selectedSorting: { name, isAsc: this.getIsAsc(orderBy[name].graphql, orders[0]) } });
  };

  renderSortButtons() {
    const { orderBy } = this.props;
    const { selectedSorting } = this.state;

    return Object.keys(orderBy).map((name, index) => <Button
      className={classNames(
        'icon-right',
        orderBy[name].reverseIcon ? 'reverse-icon' : '',
        `${selectedSorting.name === name && 'selected'}`,
        `${get(orderBy[name].graphql, getSortingGraphqlPath(orderBy[name].graphql)[0])}`,
      )}
      isBorderless
      IconComponent={ICONS.sortDirection}
      key={index}
      onClick={this.handleSetOrder(name)}
    >
      {orderBy[name].title}
    </Button>);
  }

  renderAddNewButton({ icon, title, onClick }) {
    return <Button
      IconComponent={icon}
      onClick={onClick}
    >
      {title}
    </Button>;
  }

  renderFilter() {
    return <>
      <Button className="extended-filter" IconComponent={ICONS.filter} isBorderless>Расширенный фильтр</Button>
      <Input
        name="search"
        onChange={this.handleSearch}
        className="search"
        placeholder="Поиск"
        icon="search"
        onRef={ref => { this.searchRef = ref; }}
      />
    </>;
  }

  handleMenuClick = index => {
    this.setState({ selectedMenuItem: index });
    if (this.searchRef) {
      this.searchRef.value = '';
    }
    this.props.menu[index].onClick();
  };

  renderBottom() {
    return <div className="bottom">
      <div className="sort">
        <span>Сортировка:</span>
        {this.renderSortButtons()}
      </div>
      {this.throttleSearch && this.renderFilter()}
    </div>;
  }

  render() {
    const { addNewButton, menu } = this.props;
    const showTopFilters = menu.length > 0 || addNewButton;

    return <div className="list-header-layout">
      {showTopFilters && <div className="top">
        <div className="filters">
          {menu.length > 0 && <Menu
            items={menu}
            onClick={this.handleMenuClick}
            setActive={index => index === this.state.selectedMenuItem}
            isTabMenu
          />}
          {addNewButton && this.renderAddNewButton(addNewButton)}
        </div>
      </div>}
      {this.renderBottom()}
    </div>;
  }

  static propTypes = {
    onSearch: PropTypes.func,
    orderBy: PropTypes.object.isRequired,
    setOrder: PropTypes.func.isRequired,
    addNewButton: PropTypes.shape({
      icon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.node,
      ]),
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
    menu: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.node,
      ]),
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })),
  };

  static defaultProps = {
    onSearch: null,
    addNewButton: null,
    menu: [],
  };
}

export default ListHeader;
