import { handleActions } from 'redux-actions';
import {
  SET_SIDEBAR_VISIBILITY,
} from '../actions/sideBar';

const INITIAL_STATE = {
  isMenuOpen: false,
};

const ACTION_HANDLERS = {
  [SET_SIDEBAR_VISIBILITY]: (state, { payload: { isMenuOpen } }) => ({
    ...state,
    isMenuOpen,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
