import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { compose, defaultProps } from 'recompose';

const GET_CONTRACT_DETAILS_REFERENCES_QUERY = loader('../../graphql/queries/contract/contractDetailsReferences.graphql');
const ON_CONTRACT_DETAILS_REFERENCES_SUBSCRIPTION = loader('../../graphql/queries/contract/contractDetailsReferencesSubscription.graphql');

const query = graphql(GET_CONTRACT_DETAILS_REFERENCES_QUERY, {
  name: 'getContractDetailsReferences',
  skip: props => props.isSkip,
  options: ({ userId }) => ({
    variables: {
      userId: userId,
    },
  }),
  props: ({ getContractDetailsReferences: { user = [], loading } }) => ({
    isContractDetailsReferencesLoading: loading,
    contractDetails: user[0],
  }),
});

const sub = graphql(ON_CONTRACT_DETAILS_REFERENCES_SUBSCRIPTION, {
  name: 'contractDetailsReferencesSub',
  skip: ({ userId, isSkip, withSub }) => !userId || isSkip || !withSub,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
  }),
  props: ({ contractDetailsReferencesSub: { user = [] }, ownProps: { contractDetails } }) => ({
    contractDetails: {
      ...contractDetails,
      ...user[0],
    },
  }),
});

export default compose(
  defaultProps({
    withSub: false,
  }),
  query,
  sub,
);
