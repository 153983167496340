import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import Button from '../../base/button';
import { ICONS } from '../../../utils/icons';
import UserItem from '../../user/list/item';
import Section from '../../base/section';

export default class RatingItem extends UserItem {
  state = {
    isAddingFavoriteInProgress: false,
  }

  handleClickAdd = async () => {
    const { onClickAddToFavorite, data } = this.props;

    this.setState({ isAddingFavoriteInProgress: true });
    await onClickAddToFavorite(data.email);
    this.setState({ isAddingFavoriteInProgress: false });
  }

  renderActionButtons() {
    const { onClickRecommend, onClickInviteToContract, data: { id, showAddToFavoriteButton }, userId } = this.props;

    return userId !== id && <Section className="action-buttons">
      <Button
        onClick={() => onClickInviteToContract(id)}
        IconComponent={ICONS.sendLetter}
        isBorderless
      >
        Предложить сотрудничество
      </Button>
      <Button
        onClick={onClickRecommend}
        IconComponent={ICONS.thumbsUp}
        isBorderless
      >
        Рекомендовать
      </Button>
      {showAddToFavoriteButton && (this.state.isAddingFavoriteInProgress
        ? <Spinner animation="border" size="sm" variant="warning" />
        : <Button
          onClick={this.handleClickAdd}
          IconComponent={ICONS.add}
          isBorderless
        >
          Добавить в избранные специалисты
        </Button>
      )}
    </Section>;
  }

  render() {
    const { data } = this.props;

    return <UserItem
      {...this.props}
      userId={data.id}
      className="ratings-list-item-container"
      actionButtons={this.renderActionButtons(data)}
    />;
  }

  static propTypes = {
    userId: PropTypes.number.isRequired,
    data: PropTypes.object,
    isExpanded: PropTypes.bool.isRequired,
    showToggle: PropTypes.bool,
    onClickAddToFavorite: PropTypes.func,
    onClickInviteToContract: PropTypes.func,
    onClickRecommend: PropTypes.func,
    setItemState: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    showToggle: true,
    onClickAddToFavorite() {},
    onClickInviteToContract() {},
    onClickRecommend() {},
    setItemState() {},
  };
}
