import React from 'react';
import PropTypes from 'prop-types';

import FlexGrid from '../../../base/flexGrid';
import Button from '../../../base/button';
import Section from '../../../base/section';
import { getFormattedNumber } from '../../../../utils/numbers';

import './index.scss';

const GRID_PROPS = [
  {
    key: 'billed',
    className: 'colored green',
    columnName: 'Принято этапов на сумму',
    render: getFormattedNumber,
  },
  {
    key: 'paid',
    className: 'colored',
    columnName: 'Оплачено',
    render: getFormattedNumber,
  },
  {
    key: 'fine',
    columnName: 'Начислено штрафа',
    render: value => <span className={`colored ${value < 0 ? 'green' : 'red'}`}>{getFormattedNumber(value)}</span>,
  },
  {
    key: 'penalty',
    columnName: 'Начислено пени',
    render: value => <span className={`colored ${value < 0 ? 'green' : 'red'}`}>{getFormattedNumber(value)}</span>,
  },
  {
    key: 'balance',
    columnName: 'Баланс по договору',
    render: value => <span className={`colored ${value > 0 ? 'green' : 'red'}`}>
      {getFormattedNumber(Math.abs(value || 0))} ({value > 0 ? 'предоплата' : 'долг'})
    </span>,
  },
];

export default class WorkBalance extends React.PureComponent {
  render() {
    const { balance, pay, isCustomer } = this.props;

    return <div className="work-balance-container">
      <Section>
        <h5>Баланс принятых/оплаченных работ</h5>
      </Section>
      <Section>
        <FlexGrid
          gridProps={GRID_PROPS}
          items={[balance]}
          isBorderless
        />
      </Section>
      <Section>
        {isCustomer && <Button onClick={pay}>Оплатить</Button>}
      </Section>
    </div>;
  }

  static propTypes = {
    balance: PropTypes.object.isRequired,
    pay: PropTypes.func.isRequired,
    isCustomer: PropTypes.bool.isRequired,
  };
}
