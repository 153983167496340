import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import { ICONS } from '../../../utils/icons';

import './index.scss';

class AddToFavoritesModal extends React.Component {
  render() {
    const { handleSubmit, submitting, pristine, invalid, reset, ...props } = this.props;
    return <Modal
      className="add-to-favorites-modal"
      title="Мастер добавления"
      onClose={reset}
      {...props}
    >
      <p>Если указанный вами e-mail не зарегистрирован в системе, вам будет предложен выбор</p>
      <Form onSubmit={handleSubmit} className="add-to-favorites-form">
        <Field
          name="email"
          component={FormGroupField}
          type="text"
          label="Введите e-mail"
        />
        <Button
          isDisabled={submitting || pristine}
          isLoading={submitting}
          IconComponent={ICONS.search}
          buttonProps={{
            type: 'submit',
          }}
        >
          Отправить приглашение
        </Button>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
  };
}

export default AddToFavoritesModal;
