import React from 'react';

import UserList from '../../user/list';
import FavoriteItem from './item';

import './index.scss';

export default class FavoritesList extends UserList {
  render() {
    return <UserList
      {...this.props}
      className="favorites-list-layout"
      emptyMessage='Ваш список избранного пуст. Чтобы добавить в список избранного нажмите "Добавить в избранное"'
      itemComponent={FavoriteItem}
      isFavorite
    />;
  }
}
