import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import FormGroupField from '../formGroup';

class FullName extends PureComponent {
  render() {
    return <>
      <Field
        name="lastName"
        component={FormGroupField}
        type="text"
        label="Фамилия"
      />
      <Field
        name="firstName"
        component={FormGroupField}
        type="text"
        label="Имя"
      />
      <Field
        name="patronymic"
        component={FormGroupField}
        type="text"
        label="Отчество"
      />
    </>;
  }
}

export default FullName;
