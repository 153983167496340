import findLast from 'lodash/findLast';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

import { VACANCY_TYPE } from '../../utils/vacancyType';
import { getActiveEmployee } from './object';

export const REMARK_TYPES = {
  0: 'nonExpertise',
  1: 'expertise',
};

export const formatRemarks = (remarks, performers, employees, vacancies, customer, userId) => (remarks || [])
  .map(({ reviews, task: { messages, ...task }, ...remark }) => {
    const activePerformer = getActiveEmployee(performers) || {};
    let message = '';
    let lastMessage = {};

    if (messages.length > 0) {
      [{ message }, ...messages] = messages;
      lastMessage = messages[messages.length - 1];

      if (lastMessage) {
        lastMessage.isPerformer = !!(messages.length % 2);
      }
    }

    return {
      ...remark,
      remark: message,
      reviews: messages.length === 0
        ? [{
          role: VACANCY_TYPE.performer,
          status: {
            name: 'Ожидает решения',
            kind: 'neutral',
          },
          isMyRole: activePerformer?.userId === userId,
          isPerformer: true,
        }]
        : orderBy(
          employees
            .filter(({ isActive, isCustomer, userId }) => isCustomer || (isActive && userId))
            .map(({ userId: employeeUserId, role }) => {
              const review = reviews.find(review => review.userId === employeeUserId) || {
                status: {
                  name: 'На рассмотрении',
                  kind: 'neutral',
                },
                isMyRole: employeeUserId === userId,
              };

              return {
                role: role.accusative,
                isMyRole: review.userId === userId,
                ...review,
              };
            }),
          ['role'],
          ['desc'],
        ),
      task: {
        ...task,
        lastPerformerMessage: findLast(messages, message => performers.some(performer => performer.userId === message.userId)),
        lastMessage: lastMessage
          ? {
            ...lastMessage,
            amIOwner: lastMessage.userId === userId,
          }
          : {},
        messages: messages.map(({ files, ...message }, index) => ({
          ...message,
          // Every second message is a message from the performer
          title: `Комментарий ${[customer, ...vacancies]
            .find(({ userId, isPerformer }) =>
              (index % 2 ? !isPerformer : isPerformer) && userId === message.userId)?.role.genitive}`,
          files: files.map(({ file }) => ({
            ...file,
          })),
        })),
      },
    };
  }).reduce((result, remark) => {
    const activePerformer = getActiveEmployee(performers) || {};

    if (!remark.task.isClosed &&
      (isEmpty(remark.task.lastMessage) ? activePerformer?.userId === userId : !remark.task.lastMessage.amIOwner)) {
      result.count[remark.isExpertise ? REMARK_TYPES[1] : REMARK_TYPES[0]] += 1;
      result.count.total += 1;
    }

    remark.isExpertise ? result.expertise.push(remark) : result.nonExpertise.push(remark);
    return result;
  }, {
    [REMARK_TYPES[0]]: [],
    [REMARK_TYPES[1]]: [],
    count: { [REMARK_TYPES[0]]: 0, [REMARK_TYPES[1]]: 0, total: 0 },
  });

export const prepareToInsertRemarks = ({
  remarks,
  projectComponent: { id, performers, objectId },
  isExpertise,
  currentPhaseId,
}) => (remarks || []).map(({ remark }) => ({
  objectProjectComponentId: id,
  isExpertise,
  phaseId: currentPhaseId,
  task: {
    data: {
      objectId,
      assignedUserId: performers[0].userId,
      objectProjectComponentId: id,
      taskMessages: {
        data: [{
          message: remark,
        }],
      },
    },
  },
}));
