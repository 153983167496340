import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import capitalize from 'lodash/capitalize';
import classNames from 'classnames';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';

export default class ChangeProjectCompositionModal extends PureComponent {
  constructor(props) {
    super(props);

    this.throttleChangeCustomName = throttle(this.handleChangeCustomName, 1000, { leading: false });
  }

  componentWillUnmount() {
    this.throttleChangeCustomName.cancel();
  }

  handleCancel = () => {
    this.props.onRef.current.toggleModal();
  };

  handleChangeCustomName = event => {
    const { change, computeCode } = this.props;
    change('code', computeCode(event.target.value));
  };

  handleChangeProjectComponentId = id => {
    const { filteredProjectComponents, change, computeCode } = this.props;

    if (id) {
      const projectComponent = filteredProjectComponents.find(({ value }) => value === id);

      if (projectComponent) {
        change('code', computeCode(projectComponent.name, projectComponent.code));
      }

      change('customName', '');
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      filteredProjectComponents,
      onRef,
      object,
      parents,
      initialValues,
      maxDeadline,
      childType,
      selectedProjectComponentId,
      reset,
      hasVacancies,
    } = this.props;
    const projectComponentName = childType.accusativeName;
    const nameLabel = 'Выберите наименование';
    const codeLabel = 'Шифр';
    const actionName = Object.keys(initialValues).length > 0 ? 'Редактировать' : 'Добавить';
    const isEditing = Object.keys(initialValues).length > 0;
    const allowCustomName = parents.length > 1;

    return <Modal
      className="change-project-composition-modal"
      onRef={onRef}
      title={`${actionName} ${projectComponentName}`}
      onClose={reset}
    >
      {!Object.keys(initialValues).length && parents?.length > 1 &&
      <span className="change-project-composition-note">
        * При добавлении {childType.genitiveName} все ранее загруженные в {' '}
        {parents[parents.length - 1]?.typeName} файлы будут перемещены в
        {childType.gender === 'm' ? ' новый ' : ' новую '}
        {childType.accusativeName}.
      </span>}
      <Form
        onSubmit={handleSubmit}
        className={classNames('change-project-composition-modal-form')}
      >
        <Field
          name="object"
          component={FormGroupField}
          type="text"
          label="Объект"
          placeholder={object}
          isDisabled
        />
        {parents.map((parent, index) => <Field
          key={index}
          name={`level${index}`}
          component={FormGroupField}
          type="text"
          label={capitalize(parent.typeName)}
          placeholder={parent.name}
          isDisabled
        />)}
        <Field
          className="date"
          name="deadline"
          component={FormGroupField}
          type="date"
          label="Выберите срок окончания работы"
          maxDate={maxDeadline}
          minDate={new Date()}
          isMultiline
        />
        <Field
          name="code"
          component={FormGroupField}
          type="text"
          label={codeLabel}
          placeholder="Формируется автоматически"
          isDisabled
        />
        <Field
          name="projectComponentId"
          className={classNames({ 'full-size-field': !allowCustomName })}
          component={FormGroupField}
          type="select"
          label={nameLabel}
          placeholder={allowCustomName ? 'Добавить свой вариант' : 'Выбрать вариант'}
          data={filteredProjectComponents}
          onChange={this.handleChangeProjectComponentId}
          isDisabled={hasVacancies || filteredProjectComponents.length === 0}
        />
        {allowCustomName && <Field
          name="customName"
          component={FormGroupField}
          label="&nbsp;"
          type="text"
          placeholder="Введите наименование"
          onChange={this.throttleChangeCustomName}
          isDisabled={hasVacancies || !!selectedProjectComponentId}
        />}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={(pristine && !isEditing) || submitting}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            {actionName}
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    computeCode: PropTypes.func,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
    onRef: PropTypes.object,
    maxDeadline: PropTypes.instanceOf(Date),
    object: PropTypes.string,
    parents: PropTypes.array,
    childType: PropTypes.object,
    filteredProjectComponents: PropTypes.array,
    selectedProjectComponentId: PropTypes.number,
    hasVacancies: PropTypes.bool,
  };

  static defaultProps = {
    computeCode() {},
    filteredProjectComponents: [],
    selectedProjectComponentId: null,
    onRef: {
      current: null,
    },
    object: '',
    parents: [],
    childType: {},
    maxDeadline: null,
    initialValues: {},
    hasVacancies: false,
  };
}
