import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

import Loading from '../../../../components/base/loading';
import { ROUTES } from '../../../../utils/routes';
import ObjectExpertise from '../../../../components/objects/object/objectManagement/expertise';

export default class ObjectExpertiseFiles extends Component {
  render() {
    const { isLoading, object, userId } = this.props;

    if (isLoading && isEmpty(object)) {
      return <Loading />;
    } else if (!isLoading && (!object || Object.keys(object).length === 0)) {
      return <Redirect to={ROUTES.notFound} />;
    }

    return <ObjectExpertise
      object={object}
      userId={userId}
    />;
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    object: PropTypes.object,
    userId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    object: {},
  }
}
