import { change, formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ProfileForm from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import { getRegions } from '../../../graphql/model/region';
import { getSpecialties } from '../../../graphql/model/specialty';
import { getObjectTypes } from '../../../graphql/model/objectType';
import { getObjectTypeLabel, getProjectComponents } from '../../../graphql/model/projectComponent';
import { formatUserRoles } from '../../../graphql/model/userRoles';
import { formatChiefEngineerExpertiseExperience } from '../../../graphql/model/chiefEngineerExpertiseExperience';

const FORM_NAME = 'profile';
const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, {
  user,
  regions,
  specialties,
  objectTypes,
  userRoles,
  chiefEngineerExpertiseExperience,
}) => {
  const selectedProjectComponents = formSelector(state, 'userProjectComponents') || [];
  const selectedUserRegions = formSelector(state, 'userRegions') || [];
  const selectedUserSpecialties = formSelector(state, 'userSpecialties') || [];
  const selectedUserObjectTypes = formSelector(state, 'userObjectTypes') || [];
  const selectedRole = formSelector(state, 'role') || {};
  const selectedUserChiefEngineerExpertiseExperience = formSelector(state, 'userChiefEngineerExpertiseExperience') || [];

  return {
    initialValues: user,
    isValidEmail: state.session.user.email?.toLowerCase() !== user.email?.toLowerCase(),
    regions: getRegions(regions, selectedUserRegions),
    specialties: getSpecialties(specialties, selectedUserSpecialties),
    objectTypes: getObjectTypes(objectTypes, selectedUserObjectTypes),
    projectComponents: getProjectComponents(objectTypes, selectedProjectComponents).map(({ label, ...objectType }) => ({
      ...objectType,
      label: getObjectTypeLabel(label),
    })),
    selectedProjectComponents,
    selectedRole,
    userRoles: userRoles && formatUserRoles(userRoles, user.role?.id),
    chiefEngineerExpertiseExperience: chiefEngineerExpertiseExperience && formatChiefEngineerExpertiseExperience(
      chiefEngineerExpertiseExperience, selectedUserChiefEngineerExpertiseExperience,
    ),
  };
};

const mapDispatchToProps = {
  change,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: getValidation(['phone', 'email', 'newPassword', 'repeatNewPassword']),
    onSubmitFail: getSubmitValidation,
  }),
)(ProfileForm);
