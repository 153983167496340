import React from 'react';

import UserInfo from '../../../base/userInfo';

import './index.scss';

export default class Payee extends React.PureComponent {
  render() {
    return <div className="form-card payee-layout">
      <h5>Получатель платежа</h5>
      <UserInfo />
    </div>;
  }
}
