import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import map from 'lodash/map';
import classNames from 'classnames';

import FormGroupField from '../../../base/formGroup';
import FileUploader from '../../../base/fileUploader';
import RestrictedInformation from '../../restrictedInformation';
import { DOCS } from '../../../../utils/userDocumentTypes';
import { STORAGE_PATHS } from '../../../../utils/storagePaths';

import './index.scss';

export default class CompetenceConfirmation extends PureComponent {
  title = 'Подтверждение компетенции';

  getFieldProps = props => this.props.isReadonly ? {} : props;

  isFieldHidden = field => this.props.hiddenFields.indexOf(field) > -1;

  renderContent() {
    const { isReadonly, regions } = this.props;

    const selectFieldType = isReadonly ? 'text' : 'select';

    return <>
      {map(DOCS, ({ label, value }, index) => <Field
        key={index}
        className={classNames('competence-confirmation-file', { hidden: this.isFieldHidden(`userDocuments.${value}`) })}
        name={`userDocuments.${value}`}
        type="text"
        label={label}
        component={FileUploader}
        storagePath={STORAGE_PATHS.profileDocs}
        showWarningModal
        showInput
        isReadonly={isReadonly}
      />)}
      <Field
        name="expertAccreditationNumber"
        component={FormGroupField}
        className={classNames({ hidden: this.isFieldHidden('expertAccreditationNumber') })}
        type="text"
        label="Номер аттестата аккредитации эксперта (необязательно)"
        placeholder="Ввести значение"
        inputWarning="Не подтвержден"
      />
      <Field
        name="userRegions"
        component={FormGroupField}
        type={selectFieldType}
        label="Регион работы"
        data={regions}
        isReadonly={isReadonly}
        {...this.getFieldProps({ placeholder: 'Выбрать регион', isMultiSelect: true })}
      />
    </>;
  }

  render() {
    const { isReadonly, isPrivate } = this.props;

    if (isPrivate) {
      return <RestrictedInformation title={this.title} />;
    }

    return <div className={classNames('competence-confirmation-layout form-card', { readonly: isReadonly })}>
      <h5>{this.title}</h5>
      {this.renderContent()}
    </div>;
  }

  static propTypes = {
    regions: PropTypes.array,
    isReadonly: PropTypes.bool,
    isPrivate: PropTypes.bool,
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    regions: [],
    isReadonly: false,
    isPrivate: false,
    hiddenFields: [],
  }
}
