import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getStagePath } from '../../../utils/menu';

import './index.scss';

export default class ProjectComponentLink extends PureComponent {
  render() {
    const { text, objectId, stageId, projectComponentId, isDisabled } = this.props;

    return <Link
      className={classNames('project-component-link', { disabled: isDisabled, 'link-orange': !isDisabled })}
      to={{
        pathname: getStagePath({ id: objectId, stageId, projectComponentId }),
      }}
    >{text}</Link>;
  }

  static propTypes = {
    text: PropTypes.string,
    objectId: PropTypes.number.isRequired,
    stageId: PropTypes.number.isRequired,
    projectComponentId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    isDisabled: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    projectComponentId: null,
    isDisabled: false,
  }
}
