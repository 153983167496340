import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Menu from '../../../layout/menu/container';
import Button from '../../base/button';
import { goTo } from '../../../utils/menu';
import { ROUTES } from '../../../utils/routes';

import './index.scss';
import { getFormattedNumber } from '../../../utils/numbers';

export default class FinanceHeader extends PureComponent {
  goToFinanceBalance = () => {
    goTo(ROUTES.financeBalance);
  };

  render() {
    const { balance, isLoading, isContractRequisitesPage } = this.props;

    return <div className="finance-header">
      <div className={classNames('page-header-menu', { 'contract-requisites': isContractRequisitesPage })}>
        <Menu items={this.props.menu} isTabMenu />
        <Button onClick={this.goToFinanceBalance} isLoading={isLoading}>
          Лицевой счёт: <span>{getFormattedNumber(balance)}</span>
        </Button>
      </div>
    </div>;
  }
}

FinanceHeader.propTypes = {
  menu: PropTypes.array.isRequired,
  balance: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isContractRequisitesPage: PropTypes.bool.isRequired,
};
