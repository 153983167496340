import React from 'react';
import PropTypes from 'prop-types';

import { getBundleLink } from '../../utils/links';
import Button from '../base/button';
import { ReactComponent as BoxWithArrow } from '../../assets/icons/box-with-arrow.svg';

export default class BundleLink extends React.Component {
  render() {
    const { linkTitle, linkParams, isObjectMenu } = this.props;

    return <Button
      className="bundle-link"
      href={getBundleLink(linkParams)}
      target="_blank"
      IconComponent={BoxWithArrow}
      isBorderless={isObjectMenu}
    >
      {linkTitle}
    </Button>;
  }

  static propTypes = {
    linkTitle: PropTypes.string.isRequired,
    linkParams: PropTypes.array.isRequired,
    isObjectMenu: PropTypes.bool,
  };

  static defaultProps = {
    isObjectMenu: false,
  }
}
