import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../base/button';
import { ICONS } from '../../../../utils/icons';
import BundleLink from '../../../bundleLink';

import './index.scss';

export default class Header extends PureComponent {
  render() {
    const {
      title,
      buttonPlaceholder,
      onClickChangeProjectComposition,
      isOwnerOrChief,
      isObjectStage,
      onClickRemarks,
      badgeCounter,
      onClickOpenChat,
      objectId,
      stageId,
    } = this.props;

    return <div className="stage-header-wrapper">
      {title && <h1>{title}</h1>}
      {isObjectStage && isOwnerOrChief && <Button
        onClick={onClickChangeProjectComposition}
        buttonProps={{
          type: 'button',
        }}
      >
        {buttonPlaceholder}
      </Button>}
      {isObjectStage && <BundleLink
        linkTitle="Скачать архив Стадии"
        linkParams={[
          { objectId },
          { projectComponentId: stageId },
        ]}
      />}
      <div>
        {isObjectStage && <Button
          onClick={() => onClickOpenChat(true)}
          IconComponent={ICONS.chat}
          isBorderless
        >
          Чат по стадии
        </Button>}
        {onClickRemarks && <>
          <Button
            IconComponent={ICONS.infoBold}
            isBorderless
            onClick={onClickRemarks}
            badge={badgeCounter}
            buttonProps={{
              type: 'button',
            }}
          >
            Замечания по разделам
          </Button>
        </>}
      </div>
    </div>;
  }

  static propTypes = {
    title: PropTypes.string,
    buttonPlaceholder: PropTypes.string,
    badgeCounter: PropTypes.number,
    isOwnerOrChief: PropTypes.bool,
    isObjectStage: PropTypes.bool,
    objectId: PropTypes.number,
    stageId: PropTypes.number,
    onClickChangeProjectComposition: PropTypes.func.isRequired,
    onClickRemarks: PropTypes.func,
    onClickOpenChat: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    buttonPlaceholder: 'Изменить состав стадии',
    badgeCounter: 0,
    isOwnerOrChief: false,
    isObjectStage: false,
    objectId: null,
    stageId: null,
    onClickRemarks: null,
    onClickOpenChat: null,
  };
}
