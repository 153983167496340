import reverse from 'lodash/reverse';

import { getNameWithInitials } from '../../utils/name';
import { VACANCY_TYPE } from '../../utils/vacancyType';
import { PRIVATE_ACCOUNT } from '../../utils/validationMessages';

const getUserRole = (users, userId) => users.find(({ userId: componentUserId }) =>
  componentUserId === userId)?.contractExpanded.vacancyType.userRole.name || VACANCY_TYPE.customer;

export const parseChatMessages = pureMessages => reverse(pureMessages.map(({
  id,
  message,
  createdAt,
  userId,
  user,
  objectProjectComponent,
}) => ({
  id,
  userId,
  // 'objectProjectComponent' is used only for displaying Role in OPC chat therefore it is unnecessary on Vacancy page
  title: `${user ? getNameWithInitials(user) : PRIVATE_ACCOUNT} ${objectProjectComponent ? `(${getUserRole(objectProjectComponent?.objectProjectComponentUsers, userId)})` : ''}`,
  type: 'text',
  avatar: user && user.photo?.remotePath,
  text: message,
  date: new Date(createdAt),
})));
