import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ContractFileItem from './item';

import './index.scss';

export default class ContractFilesList extends PureComponent {
  render() {
    const { fileTypes, onClickRemove, objectProjectComponentId } = this.props;

    return Object.keys(fileTypes).length > 0 && <div className="contract-files-list-wrapper">
      {Object.keys(fileTypes)
        .sort((a, b) => fileTypes[a].order - fileTypes[b].order)
        .map((type, index) => <ContractFileItem
          key={index}
          title={fileTypes[type].title}
          type={type}
          files={fileTypes[type].files}
          onClickRemove={onClickRemove}
          objectProjectComponentId={objectProjectComponentId}
        />)}
    </div>;
  }

  static propTypes = {
    fileTypes: PropTypes.object,
    onClickRemove: PropTypes.func.isRequired,
    objectProjectComponentId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    fileTypes: {},
  };
}
