import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '../../base/button';
import UserItem from '../../user/list/item';
import Section from '../../base/section';
import { ICONS } from '../../../utils/icons';
import Rating from '../../base/rating';
import { getFormattedDate } from '../../../utils/date';
import { getFormattedNumber } from '../../../utils/numbers';

import './index.scss';

export default class VacancyProcess extends React.PureComponent {
  constructor(props) {
    super(props);

    this.userItemRefs = {};
    this.state = {
      ...props.vacancy.participants.map(({ id }) => ({
        [`isParticipant${id}Expanded`]: false,
      })),
    };
  }

  getRef = userId => {
    if (!this.userItemRefs[userId]) {
      this.userItemRefs[userId] = React.createRef();
    }
    return this.userItemRefs[userId];
  };

  setParticipantItemState = id => isExpanded => {
    this.setState({ [`isParticipant${id}Expanded`]: isExpanded });
  };

  getHeaderData({
    bid,
    offerType,
    user,
  }, index, isExpanded, userId) {
    const { taxSystemName, experience, ratings } = user || {};
    const collapsedHeaderData = [
      {
        title: 'Налогообложение',
        className: 'tax-system-name',
        value: taxSystemName,
      },
      {
        title: 'Стаж работы',
        className: 'experience',
        value: experience,
      },
      {
        className: 'rating',
        value: ratings && <Rating value={ratings.avg} />,
      },
    ];
    const defaultHeaderData = this.userItemRefs[userId].current?.getDefaultHeaderData(user, userId);

    return [
      {
        title: 'Участник',
        className: 'member',
        value: '#' + index,
      },
      {
        title: 'Предложение',
        className: `offer-type ${offerType.class}`,
        value: offerType?.name,
      },
      {
        title: 'Последняя ставка',
        className: 'bid',
        value: getFormattedNumber(bid.value),
      },
      {
        title: 'Время',
        className: 'date',
        value: getFormattedDate(bid.updatedAt, true),
      },
    ].concat(userId && defaultHeaderData
      ? (isExpanded ? this.userItemRefs[userId].current?.getDefaultHeaderData(user, userId) : collapsedHeaderData)
      : {},
    );
  }

  handleRemoveParticipant = userId => () => {
    delete this.userItemRefs[userId];

    this.setState({
      [`isParticipant${userId}Expanded`]: false,
    });
    return this.props.onClickRemoveParticipant(userId);
  };

  renderActionButtons = (userId, isRecommended) => {
    const { onClickChooseCompetitionWinner } = this.props;
    return <Section className="action-buttons">
      <Button
        onClick={this.handleRemoveParticipant(userId)}
        IconComponent={ICONS.removeFavoriteEngineer}
        isBorderless
      >
        Удалить из участников
      </Button>
      <Button
        onClick={() => onClickChooseCompetitionWinner(userId)}
        IconComponent={ICONS.sentence}
        isBorderless
      >
        Назначить победителем
      </Button>
      {isRecommended && <span>Рекомендовано</span>}
    </Section>;
  };

  render() {
    const {
      vacancy,
      onClickMakeBid,
      onClickRemoveBid,
      userId,
      isTenders,
    } = this.props;
    const { participants, object: { isTariffActive } } = vacancy;
    const isApplicationAllowed = moment().isSameOrBefore(vacancy.applicationDeadline, 'day');
    const isCustomer = userId === vacancy.customer.id;
    const hasBid = participants.some(participant => participant.userId === userId);

    return <div className="vacancy-process-container">
      {isTariffActive && (!isCustomer && isApplicationAllowed || hasBid) && <div className="vacancies-list-layout">
        {!isCustomer && isApplicationAllowed && <Button onClick={onClickMakeBid}>Предложить</Button>}
        {hasBid && <Button onClick={onClickRemoveBid}>Отозвать заявку</Button>}
      </div>}
      <h5>Участники {isTenders ? 'торгов' : 'конкурса'}</h5>
      <span>экран обновляется автоматически</span>
      <div className="users-list-layout">
        {participants.length > 0
          ? participants.map(({ userId: participantUserId, ...data }, index) => <UserItem
            ref={this.getRef(participantUserId)}
            key={index}
            userId={participantUserId}
            isExpansionDisabled={false}
            data={data.user || {}}
            showToggle={isCustomer || userId === participantUserId}
            headerData={this.getHeaderData(
              data,
              index + 1,
              this.state[`isParticipant${participantUserId}Expanded`],
              participantUserId,
            )}
            actionButtons={isCustomer && isTariffActive && this.renderActionButtons(participantUserId, false)}
            setItemState={this.setParticipantItemState(participantUserId)}
            isExpanded={userId ? this.state[`isParticipant${participantUserId}Expanded`] : false}
          />)
          : <div>Список участников пуст</div>}
      </div>
    </div>;
  }

  static propTypes = {
    vacancy: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    isTenders: PropTypes.bool.isRequired,
    onClickMakeBid: PropTypes.func,
    onClickRemoveBid: PropTypes.func,
    onClickRemoveParticipant: PropTypes.func,
    onClickChooseCompetitionWinner: PropTypes.func,
  };

  static defaultProps = {
    onClickMakeBid() {},
    onClickRemoveBid() {},
    onClickRemoveParticipant() {},
    onClickChooseCompetitionWinner() {},
    vacancyActionButton: null,
  };
}
