import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ICONS } from '../../../../../utils/icons';
import Button from '../../../../base/button';
import ProfileLink from '../../../../base/profileLink';
import Grid from '../../../../base/grid';
import { getPath, goTo } from '../../../../../utils/menu';
import { ROUTES } from '../../../../../utils/routes';
import { getContractProfileRoute } from '../../../../../pages/finance/contracts/container';

import './index.scss';

export default class EngineerList extends PureComponent {
  getGridProps() {
    const { isOwner, objectId, userId, onClickBreakContract } = this.props;
    const route = isOwner ? ROUTES.myVacancyProfile : ROUTES.openedVacancyProfile;

    return [
      {
        key: 'position',
        columnName: 'Должность',
        className: 'position',
        withTooltip: true,
      },
      {
        key: 'name',
        columnName: 'ФИО',
        className: 'employee',
        render: (name, { contractId, isCompetitionStarted, email, paymentTerms, ...engineer }) => {
          if (engineer.userId) {
            return <>
              <ProfileLink text={name || email} userId={engineer.userId} />
              {paymentTerms && <Button
                className="engineer-contract"
                onClick={() => goTo(getContractProfileRoute(
                  engineer.contractStatus?.isActive,
                  engineer.contractStatus?.isArbitration,
                  objectId,
                  contractId,
                ))}
                IconComponent={ICONS.contracts}
                isBorderless
              />}
            </>;
          } else if (isCompetitionStarted || isOwner) {
            return <Link
              className={classNames('vacancy-link', isCompetitionStarted ? 'link-green' : 'link-red')}
              to={{
                pathname: getPath(route, { contractId }),
              }}
            >
              {isCompetitionStarted ? 'Запущены конкурсные процедуры' : 'Неопубликованная вакансия'}
            </Link>;
          }
        },
      },
      {
        key: 'followed',
        columnName: 'Сопровождаемые разделы',
        className: 'followed',
        withTooltip: true,
      },
      {
        key: 'userId',
        columnName: '',
        className: 'finish-work',
        render: (id, engineer) => id && (isOwner || engineer.userId === userId) ? <Button
          className="btn-engineer-action"
          onClick={() => onClickBreakContract(engineer.contractId, engineer.currentPhase)}
          buttonProps={{
            type: 'button',
          }}
        >
          {engineer.isBreakPending ? 'В процессе расторжения' : 'Завершить сотрудничество'}
        </Button> : engineer.isBreakPending && <span>В процессе расторжения</span>,
      },
    ];
  }

  render() {
    const { engineers, onClickCreateEngineerVacancy, isOwner } = this.props;
    return <div className="engineer-list-container">
      <h5>Инженеры стадии</h5>
      <Grid
        items={engineers}
        gridProps={this.getGridProps()}
        useAlternateMobileContainer
      />
      {isOwner && <Button
        className="btn-new-engineer"
        onClick={onClickCreateEngineerVacancy}
        IconComponent={ICONS.circlePlus}
        isBorderless
      >
        Добавить инженера
      </Button>}
    </div>;
  }

  static propTypes = {
    engineers: PropTypes.array,
    isOwner: PropTypes.bool,
    objectId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    onClickCreateEngineerVacancy: PropTypes.func,
    onClickBreakContract: PropTypes.func,
  };

  static defaultProps = {
    isOwner: false,
    engineers: [],
    onClickCreateEngineerVacancy() {},
    onClickBreakContract() {},
  };
}
