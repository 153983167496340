import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropDownTreeSelect from 'react-dropdown-tree-select';
import concat from 'lodash/concat';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import 'react-dropdown-tree-select/dist/styles.css';
import './index.scss';

const REDUX_INPUT_FIELDS = ['value', 'onChange', 'onBlur', 'onDragStart', 'onDrop', 'onFocus', 'invalid'];

const getChildOptions = nodes =>
  (Array.isArray(nodes) ? nodes : [nodes]).reduce((result, currentNode) => {
    if (currentNode.children && currentNode.children.length > 0) {
      const children = getChildOptions(currentNode.children);
      result = concat(result, children);
    } else {
      result.push(currentNode.value);
    }

    return result;
  }, []);

const getOptions = (data, path) => {
  const pathNode = path.split('-').slice(1).reduce((result, currentIndex) =>
    result.children ? result.children[currentIndex] : result[currentIndex],
  data);

  return getChildOptions(pathNode);
};

export default class Select extends Component {
  shouldComponentUpdate(
    nextProps,
    nextState,
    nextContext,
  ) {
    const omittedNextProps = omit(nextProps, REDUX_INPUT_FIELDS);
    const omittedThisProps = omit(this.props, REDUX_INPUT_FIELDS);

    return !isEqual(omittedNextProps, omittedThisProps);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Hack to get rid of unnecessary update data of select component.
    // If something is selected and the component has updated, we will lose the selected items unless the data contains a 'checked' parameter.
    if (isEqual(prevProps.data, this.props.data) && prevProps.value === this.props.value) {
      this.props.data.forEach(item => {
        item.checked = item.value === this.props.value;
      });
    }
  }

  handleChange = (currentNode, selectedNodes) => {
    const { isMultiSelect, onChange, data } = this.props;

    let values = null;
    if (isMultiSelect) {
      values = selectedNodes.reduce((result, currNode) => concat(result, getOptions(data, currNode._id)), []);
    } else if (selectedNodes[0]) {
      values = selectedNodes[0].value;
    }

    onChange(values);
  };

  getPlaceholder = () => {
    const { placeholder, isMultiSelect } = this.props;
    return placeholder || (isMultiSelect ? 'Выбрать значения' : 'Выбрать значение');
  };

  render() {
    const {
      label,
      data,
      isMultiSelect,
      isDisabled,
      keepRemoveTag,
      className,
      onFocus,
      onBlur,
      dropDownSelectRef,
      id,
    } = this.props;

    return <div
      className={classNames(
        'select-tree-container',
        {
          'multi-select': isMultiSelect,
          'with-remove-tag': !isMultiSelect && keepRemoveTag,
        },
      )}
    >
      {label && <label>{label}</label>}
      <DropDownTreeSelect
        id={id}
        className={className}
        texts={{
          placeholder: this.getPlaceholder(),
          noMatches: 'Совпадений не найдено',
        }}
        data={data}
        mode={isMultiSelect ? 'multiSelect' : 'simpleSelect'}
        showPartiallySelected
        keepTreeOnSearch
        keepChildrenOnSearch
        disabled={isDisabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onAction={() => {}}
        onChange={this.handleChange}
        ref={dropDownSelectRef}
      />
    </div>;
  }
}

Select.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  id: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  keepRemoveTag: PropTypes.bool,
  className: PropTypes.string,
  dropDownSelectRef: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  // Redux props
  onChange: PropTypes.func,
};

Select.defaultProps = {
  id: '',
  label: '',
  placeholder: null,
  className: '',
  isMultiSelect: false,
  isDisabled: false,
  keepRemoveTag: false,
  dropDownSelectRef: null,
  value: null,
  onChange() {},
  onFocus() {},
  onBlur() {},
};
