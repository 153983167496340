import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import VacanciesList from '../../../components/vacancies/list';
import VacanciesListHeader from '../../../components/vacancies/listHeader';
import DeleteVacancyModal from '../../../components/modals/deleteVacancy/container';

export default class Vacancies extends PureComponent {
  isSearchApplied = false;
  state = {
    order: null,
    selectedVacancy: {},
  };

  deleteVacancyModalRef = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    const { sort } = this.props;
    const { order } = this.state;

    if (!isEqual(order, prevState.order)) {
      sort(order);
    }
  }

  handleOnLoadMore = () => {
    const { fetchMore, vacancies } = this.props;
    fetchMore(vacancies.length);
  };

  setOrder = order => {
    this.setState({ order: { ...order } });

    if (order !== this.state.order) {
      this.props.sort(order);
    }
  };

  handleSearch = value => {
    this.props.search(value);
    this.isSearchApplied = !!value;
  };

  showDeleteVacancyModal = vacancy => {
    this.setState({ selectedVacancy: vacancy });
    this.deleteVacancyModalRef.current.toggleModal();
  };

  render() {
    const { vacancies, orderBy, count, isLoading, goToVacancy } = this.props;
    const { selectedVacancy } = this.state;

    return <>
      <VacanciesListHeader
        setOrder={this.setOrder}
        onSearch={this.handleSearch}
        orderBy={orderBy}
      />
      <VacanciesList
        items={vacancies}
        count={count}
        isLoading={isLoading}
        isSearchApplied={this.isSearchApplied}
        onLoadMore={this.handleOnLoadMore}
        onClickOpenVacancy={goToVacancy}
        onClickDelete={this.showDeleteVacancyModal}
      />
      <DeleteVacancyModal
        onRef={this.deleteVacancyModalRef}
        id={selectedVacancy.vacancyId}
        contractId={selectedVacancy.id}
        onAfterDelete={this.goToVacancies}
        isLot={selectedVacancy.isPerformer}
      />
    </>;
  }

  static propTypes = {
    vacancies: PropTypes.array,
    sort: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    count: PropTypes.number,
    orderBy: PropTypes.object,
    fetchMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    goToVacancy: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vacancies: [],
    orderBy: {},
    count: 0,
  }
}
