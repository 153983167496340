import * as Cookies from 'es-cookie';

const COOKIE_NAME = 'session';

export const removeAuthCookie = () => {
  console.log('Remove Auth Cookie');

  Cookies.remove(COOKIE_NAME);
};

export const checkAuthCookie = () => {
  const cookie = Cookies.get(COOKIE_NAME);

  console.log('Check Auth Cookie', cookie);

  return !!cookie;
};
