import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

export default class Section extends React.PureComponent {
  render() {
    const { children, className } = this.props;

    return <div className={classNames('section', className)}>
      {children}
    </div>;
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };
}
