import axios from 'axios';
import { showError } from './error';
import { API_ERROR_MESSAGES } from '../utils/messages';

const getConfig = (method, url, config) => ({
  method,
  url,
  ...config,
});

export default class Base {
  constructor(baseUrl) {
    const host = `${process.env.REACT_APP_SERVER_API_HOST}`;

    this.apiClient = axios.create({
      baseURL: `${host}/${baseUrl}`,
      withCredentials: true,
    });
    this.apiClient.interceptors.response.use(null, async error => {
      let newError;
      const { response, message } = error;

      if (
        message === 'Network Error' ||
        message === 'Request failed with status code 504' ||
        !response ||
        response.status === 504
      ) {
        newError = {
          message: API_ERROR_MESSAGES.networkError,
          status: 504,
        };

        showError(error, API_ERROR_MESSAGES.networkError);
      } else {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { data, status } = response;
        if (typeof data === 'object' && data.message) {
          newError = data;
        } else {
          newError = {
            message: data,
            status: status,
          };
        }
        if (
          status === 401 &&
          typeof this.unauthorizedErrorHandler === 'function'
        ) {
          return await this.unauthorizedErrorHandler(error);
        }
      }

      return Promise.reject(newError);
    });
  }

  unauthorizedErrorHandler = null;

  post = (url, config = {}) => this.apiClient(getConfig('post', url, config));
  put = (url, config = {}) => this.apiClient(getConfig('put', url, config));
  get = (url, config = {}) => this.apiClient(getConfig('get', url, config));
  delete = (url, config = {}) =>
    this.apiClient(getConfig('delete', url, config));
}
