import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const GET_FAVORITE_LIST_QUERY = loader('../../graphql/queries/favorite/getList.graphql');

export default graphql(GET_FAVORITE_LIST_QUERY, {
  name: 'getFavoriteReferences',
  skip: props => props.isSkip,
  options: ({ userId }) => ({
    variables: {
      where: {
        userId: { _eq: userId },
        isConfirmed: { _eq: true },
      },
      order: { favoriteUser: { lastName: 'asc', firstName: 'asc', patronymic: 'asc' } },
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getFavoriteReferences: { items = [] } }) => ({
    ...items.reduce((acc, fav) => {
      fav.isPerformer ? acc.favoritePerformers.push(fav) : acc.favoriteEngineers.push(fav);
      return acc;
    }, { favoriteEngineers: [], favoritePerformers: [] }),
  }),
});
