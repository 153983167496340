import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import TransactionList from '../../../base/transactionList';
import DataGroup from '../../../base/dataGroup';
import Button from '../../../base/button';
import WindowInnerWidthContext from '../../../../contexts/windowInnerWidth';
import { getFormattedDate } from '../../../../utils/date';
import { getFormattedNumber } from '../../../../utils/numbers';
import { goTo } from '../../../../utils/menu';
import { getContractProfileRoute } from '../../../../pages/finance/contracts/container';
import { ICONS } from '../../../../utils/icons';
import { SCREEN } from '../../../../utils/screen';

import './index.scss';

export default class BalanceList extends PureComponent {
  gridProps = [
    {
      key: 'id',
      columnName: 'ID Транзакции',
      className: 'id',
    },
    {
      key: 'createdAt',
      columnName: 'Дата',
      className: 'date',
      render: createdAt => getFormattedDate(createdAt),
    },
    {
      key: 'description',
      columnName: 'Основание',
      className: 'description',
      render: description => <span>{description}</span>,
    },
    {
      key: 'income',
      columnName: 'Поступления',
      className: 'income',
      render: income => <DataGroup className="colored green">{getFormattedNumber(income)}</DataGroup>,
    },
    {
      key: 'outcome',
      columnName: 'Списания',
      className: 'outcome',
      render: outcome => <DataGroup className="colored red">{getFormattedNumber(outcome)}</DataGroup>,
    },
    {
      key: 'accordionButton',
      className: 'empty-column',
      render() {},
    },
  ];

  goToContract = ({ contractId, contractStatus: { isActive, isArbitration } }, event) => {
    const isMobile = this.context <= SCREEN.md;

    if (
      isMobile &&
      event &&
      (typeof event.target?.className !== 'string' || !event.target?.className?.includes('description'))
    ) {
      return;
    }

    goTo(getContractProfileRoute(isActive, isArbitration, null, contractId));
  }

  render() {
    const { transactions } = this.props;

    return <div className="balance-wrapper">
      {transactions.length > 0
        ? <>
          {transactions.map(({ year, payments }, index) => <TransactionList
            key={index}
            title={year}
            payments={payments}
            gridProps={this.gridProps}
            onClickItem={this.goToContract}
          />)}
          <div className="actions">
            <Button
              className="print"
              onClick={noop}
              IconComponent={ICONS.printer}
              isBorderless
            >
              Версия для печати
            </Button>
          </div>
        </>
        : <p className="empty">Нет записей.</p>}
    </div>;
  }

  static contextType = WindowInnerWidthContext;

  static propTypes = {
    transactions: PropTypes.array.isRequired,
  };

  static defaultProps = {
    transactions: {
      year: `${new Date().getFullYear()} год`,
    },
  };
}
