import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import AddTariffPlanModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import { formatGraphqlTariffPlans } from '../../../graphql/model/tariff';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';

const FORM_NAME = 'TariffPlans';
const formSelector = formValueSelector(FORM_NAME);

const INSERT_USER_TARIFF_PLAN_MUTATION = loader('../../../graphql/queries/tariff/insert.graphql');

const withInsertUserTariffPlanMutation = graphql(INSERT_USER_TARIFF_PLAN_MUTATION, { name: 'insertUserTariffPlan' });

const mapStateToProps = state => ({
  isSelfRequisitesSelected: formSelector(state, 'isSelfContractDetailsTypeSelected'),
  isChecked: formSelector(state, 'agreements.offer'),
});

const mergeProps = withProps(({ insertUserTariffPlan, contractDetails }) => {
  const { self } = contractDetails;
  return {
    onAddTariffPlan: tariffPlan => {
      return insertUserTariffPlan({
        variables: {
          tariffPlan: formatGraphqlTariffPlans(tariffPlan),
        },
      }).then(result => {
        if (result.errors) {
          throw result.errors;
        }
      }).catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        throw error;
      });
    },

    initialValues: {
      isSelfContractDetailsTypeSelected: !!self,
      agreements: { offer: false },
    },
  };
});

export default compose(
  withInsertUserTariffPlanMutation,
  connect(mapStateToProps),
  mergeProps,
  reduxForm({
    form: FORM_NAME,
    validate: getValidation([
      'agreements.offer',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(AddTariffPlanModal);
