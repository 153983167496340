import keys from 'lodash/keys';
import { matchPath } from 'react-router-dom';

import { ROUTES } from './routes';
import { ICONS } from './icons';
import history from './history';
import Favorites from '../pages/favorites/list/container';
import Finance from '../pages/finance/container';
import ContractDetails from '../pages/contractDetails/container';
import PublicProfile from '../pages/profile/view/container';
import ObjectStage from '../pages/objects/object/stage/container';
import ObjectInitialData from '../pages/objects/object/initialData/container';
import ObjectExpertise from '../pages/objects/object/expertise/container';
import ProjectComposition from '../pages/objects/object/projectComposition/container';
import Contracts from '../pages/profile/contracts/container';
import FinanceContracts from '../pages/finance/contracts/container';
import VacanciesMenu from '../pages/vacancies';
import VacanciesList from '../pages/vacancies/list/container';
import ContractProfile from '../components/contract/profile';
import ObjectsMenu from '../pages/objects/container';
import Objects from '../pages/objects/list/container';
import FavoritesMenu from '../pages/favorites';
import ContractMenu from '../pages/contract/container';
import VacancyMenu from '../pages/vacancies/item/container';
import VacancyProfile from '../pages/vacancies/item/profile/container';
import VacancyProcess from '../pages/vacancies/item/process/container';
import RatingsMenu from '../pages/ratings/container';
import Ratings from '../pages/ratings/list/container';
import TariffPlans from '../pages/tariffs/plans/container';
import UserTariffPlans from '../pages/tariffs/current/container';
import Balance from '../pages/finance/balance/container';
import ContractDocumentation from '../components/contract/documentation/container';
import RemarksMenu from '../pages/objects/object/remarks/container';
import ContractTransactionsList from '../components/contract/transactions/list';
import TasksMenu from '../pages/tasks/container';
import Tasks from '../pages/tasks/list/container';
import DownloadBundle from '../pages/downloadBundle';

export const OBJECT_MENU_NAME = 'objectMenu';
export const PROFILE_MENU_NAME = 'profileMenu';
export const OBJECT_CONTRACT_MENU_NAME = 'objectContractMenu';
export const FINANCE_CONTRACT_MENU_NAME = 'financeContractMenu';
export const VACANCY_MENU_NAME = 'vacancyMenu';
export const TENDER_MENU_NAME = 'tenderMenu';
export const TARIFF_MENU_NAME = 'tariffMenu';
export const REMARKS_MENU_NAME = 'remarksMenu';
export const MAIN_MENU_NAME = 'mainMenu';

export const TENDERS_MENU_TITLE = 'Торги';
export const VACANCIES_MENU_TITLE = 'Вакансии';

const getFundingLink = process.env.REACT_APP_GET_FUNDING_LINK;

export const getTasksMenuChildren = isObject => [
  {
    icon: ICONS.incoming,
    route: isObject ? ROUTES.objectIncomingTasks : ROUTES.incomingTasks,
    title: 'Входящие',
    component: Tasks,
  },
  {
    icon: ICONS.outgoing,
    route: isObject ? ROUTES.objectOutgoingTasks : ROUTES.outgoingTasks,
    title: 'Исходящие',
    component: Tasks,
  },
  {
    icon: ICONS.archive,
    route: isObject ? ROUTES.objectTasksArchive : ROUTES.tasksArchive,
    title: 'Архив',
    component: Tasks,
  },
  {
    icon: ICONS.delegated,
    route: isObject ? ROUTES.objectDelegatedTasks : ROUTES.delegatedTasks,
    title: 'Перепорученные',
    component: Tasks,
  },
];

export const OBJECT_MENU_ITEMS = [
  {
    title: 'Исходные данные',
    icon: ICONS.technicalTask,
    route: ROUTES.objectInitialData,
    component: ObjectInitialData,
  },
  {
    title: 'Экспертиза',
    icon: ICONS.observable,
    route: ROUTES.objectExpertise,
    component: ObjectExpertise,
  },
  {
    title: 'Задачи',
    icon: ICONS.tasks,
    badgeField: 'tasksCount',
    isForOwnerOnly: false,
    route: ROUTES.objectTasks,
    component: TasksMenu,
    children: getTasksMenuChildren(true),
  },
  {
    title: 'Договора',
    icon: ICONS.contracts,
    isForOwnerOnly: false,
    route: ROUTES.objectContracts,
    component: Finance,
    children: [
      {
        icon: ICONS.note,
        route: ROUTES.currentObjectContracts,
        title: 'Действующие',
        component: FinanceContracts,
      },
      {
        icon: ICONS.contract,
        route: ROUTES.closedObjectContracts,
        title: 'Завершенные',
        component: FinanceContracts,
      },
      {
        icon: ICONS.law,
        route: ROUTES.arbitrationObjectContracts,
        title: 'В Арбитраже',
        component: FinanceContracts,
      },
    ],
  },
  {
    title: 'Состав проекта',
    icon: ICONS.composition,
    isForOwnerAndChiefOnly: true,
    route: [ROUTES.projectComposition, ROUTES.projectCompositionStage],
    component: ProjectComposition,
  },
  {
    title: 'Создать архив объекта',
    icon: ICONS.boxWithArrow,
    route: ROUTES.downloadBundle,
    component: DownloadBundle,
  },
  // TODO: stage 2 features
  {
    title: 'ТЭП',
    icon: ICONS.law,
    isForOwnerOnly: false,
    route: ROUTES.technicalIndicators,
  },
  {
    title: 'Готовность',
    icon: ICONS.timer,
    isForOwnerOnly: false,
    route: ROUTES.readinessStatus,
  },
  {
    title: 'Официальная переписка',
    icon: ICONS.correspondence,
    isForOwnerOnly: false,
    route: ROUTES.correspondence,
  },
];

export const PUBLIC_PROFILE_MENU_ITEMS = [
  {
    icon: ICONS.user,
    route: ROUTES.publicProfile,
    title: 'Карточка специалиста',
    component: PublicProfile,
  },
  {
    icon: ICONS.nowInWork,
    route: ROUTES.currentProfileContracts,
    title: 'Сейчас в работе',
    component: Contracts,
  },
  {
    icon: ICONS.completed,
    route: ROUTES.closedProfileContracts,
    title: 'Выполненные работы',
    component: Contracts,
  },
  // TODO: stage 2 feature, consider to remove from this menu
  {
    icon: ICONS.customer,
    route: ROUTES.support,
    title: 'Служба поддержки',
  },
];

export const TARIFF_MENU_ITEMS = [
  {
    title: 'Действующие тарифы',
    icon: ICONS.lamp,
    route: ROUTES.currentTariffs,
    component: UserTariffPlans,
  },
  {
    title: 'Приобрести тарифный план',
    icon: ICONS.shopping,
    route: ROUTES.tariffs,
    component: TariffPlans,
  },
];

const getContractMenu = isObject => {
  const item = isObject ? 'Object' : 'Finance';

  return [
    {
      icon: ICONS.note,
      route: ROUTES[`current${item}Contract`],
      title: 'Действующие',
      component: ContractMenu,
      children: [
        {
          icon: ICONS.sheet,
          route: ROUTES[`current${item}ContractProfile`],
          title: 'Профиль договора',
          component: ContractProfile,
        },
        {
          icon: ICONS.files,
          route: ROUTES[`current${item}ContractDocumentation`],
          title: 'Документация',
          component: ContractDocumentation,
        },
        {
          icon: ICONS.cards,
          route: ROUTES[`current${item}ContractPaymentRegister`],
          title: 'Реестр оплат',
          component: ContractTransactionsList,
        },
      ],
    },
    {
      icon: ICONS.contract,
      route: ROUTES[`closed${item}Contract`],
      title: 'Завершенные',
      component: ContractMenu,
      children: [
        {
          icon: ICONS.sheet,
          route: ROUTES[`closed${item}ContractProfile`],
          title: 'Профиль договора',
          component: ContractProfile,
        },
        {
          icon: ICONS.files,
          route: ROUTES[`closed${item}ContractDocumentation`],
          title: 'Документация',
          component: ContractDocumentation,
        },
        {
          icon: ICONS.cards,
          route: ROUTES[`closed${item}ContractPaymentRegister`],
          title: 'Реестр оплат',
          component: ContractTransactionsList,
        },
      ],
    },
    {
      icon: ICONS.contract,
      route: ROUTES[`arbitration${item}Contract`],
      title: 'В арбитраже',
      component: ContractMenu,
      children: [
        {
          icon: ICONS.sheet,
          route: ROUTES[`arbitration${item}ContractProfile`],
          title: 'Профиль договора',
          component: ContractProfile,
        },
        {
          icon: ICONS.files,
          route: ROUTES[`arbitration${item}ContractDocumentation`],
          title: 'Документация',
        },
        {
          icon: ICONS.cards,
          route: ROUTES[`arbitration${item}ContractPaymentRegister`],
          title: 'Реестр оплат',
          component: ContractTransactionsList,
        },
      ],
    },
    ...(isObject
      ? []
      : [{
        icon: ICONS.menu,
        route: ROUTES.financeContractRequisites,
        title: 'Реквизиты для договора',
        component: Finance,
      }]),
  ];
};

const getVacancyMenu = isVacancy => {
  const item = isVacancy ? 'Vacancy' : 'Tender';
  const getChildren = status => [
    {
      icon: ICONS.sheet,
      route: ROUTES[`${status}Profile`],
      title: isVacancy ? 'Карточка вакансии' : 'Карточка лота',
      component: VacancyProfile,
    },
    {
      icon: ICONS.files,
      route: ROUTES[`${status}Process`],
      title: isVacancy ? 'Ход конкурса' : 'Ход торгов',
      component: VacancyProcess,
    },
  ];

  return [
    {
      icon: ICONS.note,
      route: ROUTES[`opened${item}`],
      title: `Открытые ${isVacancy ? 'вакансии' : 'торги'}`,
      component: VacancyMenu,
      children: getChildren(`opened${item}`),
    },
    {
      icon: ICONS.menu,
      route: ROUTES[`involved${item}`],
      title: 'Принимаю участие',
      component: VacancyMenu,
      children: getChildren(`involved${item}`),
    },
    {
      icon: ICONS.contract,
      route: ROUTES[`my${item}`],
      title: 'Созданные мною',
      component: VacancyMenu,
      children: getChildren(`my${item}`),
    },
    {
      icon: ICONS.closedDoor,
      route: ROUTES[`${item.toLowerCase()}Invitation`],
      title: 'Приглашения',
      component: VacancyMenu,
      children: getChildren(`${item.toLowerCase()}Invitation`),
    },
  ];
};

export const BACK_BUTTON_TYPE = 'backButton';
export const EXTERNAL_LINK_TYPE = 'externalLink';

const BACK_TO_MAIN_MENU_ITEM = {
  icon: ICONS.arrowBack,
  title: 'Возврат в главное меню',
  type: BACK_BUTTON_TYPE,
  className: 'back-menu',
};

export const MENU_ITEMS = {
  [MAIN_MENU_NAME]: [
    {
      icon: ICONS.portfolio,
      route: ROUTES.objects,
      title: 'Объекты',
      component: ObjectsMenu,
      children: [
        {
          icon: ICONS.users,
          route: ROUTES.involvedObjects,
          title: 'Принимаю участие',
          component: Objects,
        },
        {
          icon: ICONS.drawing,
          route: ROUTES.myObjects,
          title: 'Созданные мной',
          component: Objects,
        },
        {
          icon: ICONS.archive,
          route: ROUTES.closedObjects,
          title: 'Завершенные',
          component: Objects,
        },
        {
          icon: ICONS.law,
          route: ROUTES.arbitrationObjects,
          title: 'В арбитраже',
          component: Objects,
        },
      ],
    },
    {
      icon: ICONS.sheet,
      route: ROUTES.tasks,
      title: 'Мои задачи',
      component: TasksMenu,
      children: getTasksMenuChildren(),
    },
    {
      icon: ICONS.chart,
      route: ROUTES.tenders,
      title: TENDERS_MENU_TITLE,
      component: VacanciesMenu,
      children: [
        {
          icon: ICONS.openDoor,
          route: ROUTES.openedTenders,
          title: 'Открытые торги',
          component: VacanciesList,
        },
        {
          icon: ICONS.drawing,
          route: ROUTES.myTenders,
          title: 'Созданные',
          component: VacanciesList,
        },
        {
          icon: ICONS.success,
          route: ROUTES.involvedTenders,
          title: 'Участвую',
          component: VacanciesList,
        },
        {
          icon: ICONS.closedDoor,
          route: ROUTES.tenderInvitations,
          title: 'Приглашения',
          component: VacanciesList,
        },
      ],
    },
    {
      icon: ICONS.users,
      route: ROUTES.vacancies,
      title: VACANCIES_MENU_TITLE,
      component: VacanciesMenu,
      children: [
        {
          icon: ICONS.businessman,
          route: ROUTES.openedVacancies,
          title: 'Открытые вакансии',
          component: VacanciesList,
        },
        {
          icon: ICONS.drawing,
          route: ROUTES.myVacancies,
          title: 'Созданные',
          component: VacanciesList,
        },
        {
          icon: ICONS.success,
          route: ROUTES.involvedVacancies,
          title: 'Участвую',
          component: VacanciesList,
        },
        {
          icon: ICONS.closedDoor,
          route: ROUTES.vacancyInvitations,
          title: 'Приглашения',
          component: VacanciesList,
        },
      ],
    },
    {
      icon: ICONS.paperAndPencil,
      route: ROUTES.finance,
      title: 'Договора',
      component: Finance,
      children: [
        {
          icon: ICONS.note,
          route: ROUTES.currentFinanceContracts,
          title: 'Действующие',
          component: FinanceContracts,
        },
        {
          icon: ICONS.contract,
          route: ROUTES.closedFinanceContracts,
          title: 'Завершенные',
          component: FinanceContracts,
        },
        {
          icon: ICONS.law,
          route: ROUTES.arbitrationFinanceContracts,
          title: 'В арбитраже',
          component: FinanceContracts,
        },
        {
          icon: ICONS.menu,
          route: ROUTES.financeContractRequisites,
          title: 'Реквизиты',
          component: ContractDetails,
        },
        {
          route: ROUTES.financeBalance,
          component: Balance,
        },
      ],
    },
    {
      icon: ICONS.star,
      route: ROUTES.favorites,
      title: 'Избранные специалисты',
      component: FavoritesMenu,
      children: [
        {
          icon: ICONS.engineer,
          route: ROUTES.favoriteEngineers,
          title: 'Инженеры',
          component: Favorites,
        },
        {
          icon: ICONS.performer,
          route: ROUTES.favoritePerformers,
          title: 'Исполнители',
          component: Favorites,
        },
      ],
    },
    {
      icon: ICONS.table,
      route: ROUTES.ratings,
      title: 'Рейтинговая таблица',
      component: RatingsMenu,
      children: [
        {
          route: ROUTES.roleRatings,
          component: Ratings,
        },
      ],
    },
    {
      icon: ICONS.coin,
      route: getFundingLink,
      title: 'Получить финансирование',
      component: 'none',
      type: EXTERNAL_LINK_TYPE,
    },
    {
      icon: ICONS.cloud,
      route: ROUTES.cloudArchive,
      title: 'Облачный архив',
    },
    {
      icon: ICONS.law,
      route: ROUTES.arbitration,
      title: 'Арбитраж',
    },
    {
      icon: ICONS.customer,
      route: ROUTES.support,
      title: 'Служба поддержки',
    },
    {
      icon: ICONS.settings,
      route: ROUTES.settings,
      title: 'Настройки',
    },
    {
      icon: ICONS.contact,
      route: ROUTES.contacts,
      title: 'Контакты',
    },
  ],
  [PROFILE_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.favorites,
      exact: true,
    },
    ...PUBLIC_PROFILE_MENU_ITEMS,
  ],
  [OBJECT_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.objects,
      exact: true,
    },
    {
      route: [ROUTES.projectComponent, ROUTES.stage, ROUTES.stages],
      component: ObjectStage,
    },
    ...OBJECT_MENU_ITEMS,
  ],
  [OBJECT_CONTRACT_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.objectContracts,
      exact: true,
    },
    ...getContractMenu(true),
  ],
  [FINANCE_CONTRACT_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.financeContracts,
      exact: true,
    },
    ...getContractMenu(),
  ],
  [VACANCY_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.vacancies,
      exact: true,
    },
    ...getVacancyMenu(true),
  ],
  [TENDER_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.tenders,
      exact: true,
    },
    ...getVacancyMenu(),
  ],
  [TARIFF_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      route: ROUTES.profile,
      exact: true,
    },
    ...TARIFF_MENU_ITEMS,
  ],
  [REMARKS_MENU_NAME]: [
    {
      ...BACK_TO_MAIN_MENU_ITEM,
      title: 'Возврат в стадию',
      route: ROUTES.projectComponent,
      exact: true,
    },
    {
      route: [ROUTES.projectComponentRemark, ROUTES.projectComponentRemarks],
      component: RemarksMenu,
    },
  ],
};

export const getStagePath = (params, isRemarks) => {
  let route = ROUTES.stage;

  if (isRemarks) {
    route = params.remarkId ? ROUTES.projectComponentRemark : ROUTES.projectComponentRemarks;
  } else if (params.projectComponentId) {
    route = ROUTES.projectComponent;
  }

  return getPath(
    route,
    params,
  );
};

export const goToObjectMenuItem = (id, stageId) => {
  goTo(getStagePath({ id, stageId }));
};

export const getPath = (pathname, params) => {
  if (params) {
    keys(params).forEach(key => {
      pathname = pathname.replace(`:${key}`, params[key]);
    });
  }

  return pathname.replace(/:[a-zA-Z]+/g, '');
};

export const goTo = (pathname, params, search) => {
  history.push({
    pathname: getPath(pathname, params),
    search,
  });
};

export const redirectToExternalSite = url => {
  window.open(url, '_blank');
};

const findMatchedPath = (menuItems, pathname) => menuItems.some(menuItem =>
  menuItem.type !== BACK_BUTTON_TYPE && (
    menuItem.route && matchPath(pathname, { path: menuItem.route, exact: true }) ||
    menuItem.children && findMatchedPath(menuItem.children, pathname)
  ),
);

export const getFilteredMenu = pathname => {
  const currentMenuName = Object.keys(MENU_ITEMS)
    .find(menuName => findMatchedPath(MENU_ITEMS[menuName], pathname));

  return MENU_ITEMS[currentMenuName || MAIN_MENU_NAME]
    .filter(({ type, component }) => !!component || type === BACK_BUTTON_TYPE);
};

export const isObjectMenu = pathname =>
  !matchPath(pathname, { path: [ROUTES.currentObjectContract, ROUTES.closedObjectContract] }) &&
  !!matchPath(pathname, { strict: true, path: ROUTES.object + '/' });
