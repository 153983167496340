import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import AddFilesSection from '../../base/addFilesSection';
import FormGroupField from '../../base/formGroup';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class AddContractFileModal extends React.PureComponent {
  state = {
    isEmpty: false,
  };

  closeModal = () => {
    this.props.onRef.current.toggleModal();
  };

  handleSubmit = async data => {
    const { onRef, insertFiles, reset } = this.props;
    const files = (data.files || []).filter(({ file }) => file?.remotePath);

    if (files.length === 0) {
      this.setState({ isEmpty: true });
    } else {
      await insertFiles({ ...data, files });
      reset();
      onRef.current.toggleModal();
    }
  };

  render() {
    const { onRef, storagePath, handleSubmit, types, pristine, submitting } = this.props;
    const { isEmpty } = this.state;

    return <Modal
      onRef={onRef}
      className="add-contract-files-modal"
      title="Добавить файл"
    >
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          name="type"
          component={FormGroupField}
          type="select"
          data={types}
          label="Тип файлов"
          placeholder="Выберите тип загружаемых файлов"
        />
        <AddFilesSection
          name="files"
          storagePath={storagePath}
          buttonText="Добавить файл(ы)"
        />
        {isEmpty && <div className="info">
          <ICONS.infoBold className="info-icon" />
          <p>Необходимо загрузить хотя бы один файл</p>
        </div>}
        <div className="action-buttons">
          <Button
            onClick={this.closeModal}
            isLarge
            isBorderless
          >Отмена</Button>
          <Button
            buttonProps={{
              type: 'submit',
            }}
            isLarge
            isDisabled={pristine || submitting}
            isLoading={submitting}
          >Добавить</Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    insertFiles: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    storagePath: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: {
        toggleModal() {},
      },
    },
  };
}
