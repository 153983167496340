import { CONTRACT_STATUSES } from './contract';

export const PHASE_STATUSES = {
  passed: {
    message: 'Этап пройден',
    color: 'green',
  },
  inProgress: {
    message: 'В процессе',
    color: 'orange',
  },
  expired: {
    color: 'red',
  },
  notStarted: {
    message: 'Этап не наступил',
  },
  notPassed: {
    message: 'Этап не пройден',
    color: 'red',
  },
  suspended: {
    message: CONTRACT_STATUSES.suspended.name,
    color: 'red',
  },
};

export const CONTRACT_PHASES = {
  phase0: 'phase0',
  phase1: 'phase1',
  phase2: 'phase2',
  phase3: 'phase3',
  phase4: 'phase4',
};
