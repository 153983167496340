import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import ObjectsList from '../../../components/objects/list';
import ObjectsListHeader from '../../../components/objects/list/header';

export default class Objects extends PureComponent {
  isSearchApplied = false;

  state = {
    order: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { sort } = this.props;
    const { order } = this.state;

    if (!isEqual(order, prevState.order)) {
      sort(order);
    }
  }

  setOrder = order => {
    this.setState({ order: { ...order } });

    if (order !== this.state.order) {
      this.props.sort(order);
    }
  };

  handleOnLoadMore = () => {
    const { fetchMore, objects } = this.props;
    fetchMore(objects.length);
  };

  handleSearch = value => {
    this.props.search(value);
    this.isSearchApplied = !!value;
  };

  render() {
    const {
      objects,
      count,
      isLoading,
      orderBy,
      isOwner,
      showEditModal,
      showCreateEngineerVacancyModal,
    } = this.props;

    return <>
      <ObjectsListHeader
        setOrder={this.setOrder}
        onSearch={this.handleSearch}
        orderBy={orderBy}
      />
      <ObjectsList
        items={objects}
        count={count}
        isLoading={isLoading}
        isSearchApplied={this.isSearchApplied}
        onLoadMore={this.handleOnLoadMore}
        isOwner={isOwner}
        onClickEdit={showEditModal}
        onClickCreateChiefEngineerVacancy={showCreateEngineerVacancyModal}
      />
    </>;
  }

  static propTypes = {
    sort: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    objects: PropTypes.array,
    count: PropTypes.number,
    isLoading: PropTypes.bool,
    isOwner: PropTypes.bool.isRequired,
    orderBy: PropTypes.object,
    fetchMore: PropTypes.func.isRequired,
    showEditModal: PropTypes.func,
    showCreateEngineerVacancyModal: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    objects: [],
    count: 0,
    networkStatus: 0,
    orderBy: {},
    showEditModal() {},
    showCreateEngineerVacancyModal() {},
  };
}
