import React from 'react';
import PropTypes from 'prop-types';
import maxBy from 'lodash/maxBy';

import Container from '../../base/container';
import DataGroup from '../../base/dataGroup';
import Button from '../../base/button';
import ProfileLink from '../../base/profileLink';
import Rating from '../../base/rating';
import Section from '../../base/section';
import Hint from '../../base/hint';
import ProjectComponentLink from '../../base/projectComponentLink';
import ProcessedCode from '../../base/processedCode';
import { getFormattedDate } from '../../../utils/date';
import { getFormattedNumber } from '../../../utils/numbers';
import { ICONS } from '../../../utils/icons';
import { getFullContractSubject } from '../../../graphql/model/contracts';
import { VACANCY_TYPE } from '../../../utils/vacancyType';

export default class VacancyItem extends React.PureComponent {
  handleClickEdit = () => {
    const { onClickEdit, data } = this.props;

    onClickEdit(data);
  };

  setField = (isProfile, isSubjectField) => {
    const {
      userId,
      data: {
        isPerformer,
        subject,
        vacancyTypeName,
        object: {
          userId: objectUserId,
          id: objectId,
          name: objectName,
          stageId,
          projectComponents,
          isTariffActive,
        },
      },
    } = this.props;

    const isChiefEngineerVacancy = vacancyTypeName === VACANCY_TYPE.chief;
    const subjectText = getFullContractSubject(isPerformer, subject, vacancyTypeName);
    const subjectField = <DataGroup title={isPerformer ? 'Предмет торгов' : 'Конкурс по вакансии'} isLineBreakAllowed>
      <ProcessedCode isLoading={!subject}>
        {userId === objectUserId
          ? <ProjectComponentLink
            text={subjectText}
            objectId={objectId}
            stageId={stageId}
            projectComponentId={isChiefEngineerVacancy ? '' : projectComponents[0].objectProjectComponentId}
            isDisabled={!isTariffActive}
          />
          : <span>{subjectText}</span>}
      </ProcessedCode>
    </DataGroup>;
    const objectNameField = <DataGroup title="Наименование объекта" className="object-name" isLineBreakAllowed>
      {objectName}
    </DataGroup>;

    if (isProfile) {
      return isSubjectField ? objectNameField : subjectField;
    } else {
      return isSubjectField ? subjectField : objectNameField;
    }
  };

  renderHeader = () => {
    const { data, isProfile, onClickDelete } = this.props;
    const { customer: { id: customerUserId }, contract, userId, employeeUserId, isCompetitionStarted, object } = data;

    return <div className="vacancy-header">
      {this.setField(isProfile, true)}
      {userId === customerUserId && <>
        {/* Contract validation is required for a vacancy list where we don't need to show the edit button. The contract only exists on the profile page. */}
        {contract && isCompetitionStarted && object.isTariffActive && <Button
          IconComponent={ICONS.pencil}
          onClick={this.handleClickEdit}
          isBorderless
          buttonProps={{
            name: 'editButton',
          }}
        />}
        {!contract && !employeeUserId && <Button
          IconComponent={ICONS.trash}
          onClick={() => onClickDelete(data)}
          isBorderless
          buttonProps={{ name: 'deleteButton' }}
        />}
      </>}
    </div>;
  };

  renderFooter = ({ id, applicationDeadline, phase, isPerformer, object }) => {
    const { isTariffActive, stageId, stageDeadline, projectComponents } = object;
    const isChiefEngineerVacancy = stageId === projectComponents[0].objectProjectComponentId;
    const deadline = isChiefEngineerVacancy ? stageDeadline : maxBy(projectComponents, 'deadline').deadline;
    const { actionButton, onClickOpenVacancy } = this.props;
    return <Section className="footer">
      <div>
        <DataGroup title={isPerformer ? 'Лот' : 'Вакансия'} className="lot">{id}</DataGroup>
        <DataGroup title="Окончание приема заявок" className="application-deadline">
          {applicationDeadline && getFormattedDate(applicationDeadline, true)}
        </DataGroup>
        <DataGroup title="Этап" className="phase">
          {phase}
          <Hint title="Этапы:">
            - Редактирование условий<br />
            - Торги/Конкурс<br />
            - Выбор победителя<br />
          </Hint>
        </DataGroup>
        <DataGroup title="Срок завершения всех этапов" className="deadline">{getFormattedDate(deadline, true)}</DataGroup>
      </div>
      {isTariffActive && (actionButton || onClickOpenVacancy && <Button target="_blank" href={onClickOpenVacancy(id)}>
        {isPerformer ? 'Открыть ЛОТ' : 'Открыть вакансию'}
      </Button>)}
    </Section>;
  };

  renderPreview = vacancy => {
    const { object: { region, type, stage }, lowestBid } = vacancy;

    return <div className="vacancy-preview">
      <Section>
        <DataGroup title="Регион работы" className="region">{region}</DataGroup>
        <DataGroup title="Тип объекта" className="type">{type}</DataGroup>
        <DataGroup title="Стадия" className="stage">{stage}</DataGroup>
        <DataGroup title="Наименьшая ставка" className="bid">{getFormattedNumber(lowestBid)}</DataGroup>
      </Section>
      {this.renderFooter(vacancy)}
    </div>;
  };

  renderChild = vacancy => {
    const { isProfile } = this.props;
    const { object, lowestBid, paymentType, totalAmount, vacancyTypeName, customer, isPerformer } = vacancy;
    const {
      region,
      address,
      code,
      type,
      stage,
    } = object;
    const { id: customerId, name: customerName, rating } = customer;

    return <>
      <Section>
        <DataGroup title="Регион работы" className="region">{region}</DataGroup>
        <DataGroup title="Тип объекта" className="type">{type}</DataGroup>
        <DataGroup title="Стадия" className="stage">{stage}</DataGroup>
        <DataGroup title="Наименьшая ставка" className="bid">{getFormattedNumber(lowestBid)}</DataGroup>
      </Section>
      <Section>
        {this.setField(isProfile, false)}
      </Section>
      <Section>
        <DataGroup title="Адрес объекта" className="address">{address}</DataGroup>
        <DataGroup title="Условия оплаты" className="payment-type">{paymentType}</DataGroup>
        <DataGroup title="Условия победы" className="win-terms">
          Наименьшая ставка
          <Hint title="Наименьшая ставка">
            Данный параметр показывает какая сумма из предложенных участниками является наименьшей
          </Hint>
        </DataGroup>
        <DataGroup title="Шифр объекта" className="code">{code}</DataGroup>
      </Section>
      <Section className="customer">
        <DataGroup title="Начальная стоимость">{getFormattedNumber(totalAmount)}</DataGroup>
        <div>
          <DataGroup title="Заказчик"><ProfileLink userId={customerId} text={customerName} /></DataGroup>
          <Rating value={rating} />
        </div>
      </Section>
      {isPerformer && <Section>
        <DataGroup title="Назначение торгов">{vacancyTypeName}</DataGroup>
      </Section>}
      {this.renderFooter(vacancy)}
    </>;
  };

  render() {
    const { data, style, isExpanded, setItemState, isExpansionDisabled } = this.props;

    return <div style={style} className="vacancies-list-item-container">
      <Container
        className="vacancy-item"
        isDefaultExpanded={isExpanded}
        isExpansionDisabled={isExpansionDisabled}
        shareExpandedState={setItemState}
        headerContent={this.renderHeader(data)}
        previewContent={this.renderPreview(data)}
      >
        {this.renderChild(data)}
      </Container>
    </div>;
  }

  static propTypes = {
    userId: PropTypes.number.isRequired,
    data: PropTypes.object,
    style: PropTypes.object,
    isExpanded: PropTypes.bool,
    setItemState: PropTypes.func,
    isExpansionDisabled: PropTypes.bool,
    isProfile: PropTypes.bool,
    actionButton: PropTypes.node,
    onClickOpenVacancy: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    style: {},
    setItemState() {},
    isExpanded: false,
    isExpansionDisabled: true,
    isProfile: false,
    actionButton: null,
    onClickOpenVacancy: null,
    onClickEdit() {},
    onClickDelete() {},
  };
}
