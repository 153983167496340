import moment from 'moment';

import { getEmployee } from './employee';

export const formatFavorites = (items, userAggregation = []) => (items || []).map(({
  id: favoriteId,
  isConfirmed,
  isEmailSent,
  favoriteUser,
  createdAt,
  email,
}) => {
  const objectsCount = userAggregation.find(({ userId }) => userId === favoriteUser?.id)?.objectsCount;
  const favorite = {
    favoriteId,
    isConfirmed,
    isEmailSent,
    email,
    invitationDate: moment(createdAt).format('DD.MM.YYYY'),
    ratings: {},
  };

  if (favoriteUser) {
    Object.assign(favorite, {
      favoriteUserId: favoriteUser.id,
      objectsCount,
      ...getEmployee(favoriteUser),
    });
  }

  return favorite;
});

export const getFavoritesQuery = (userId, isPerformer, search) => {
  const whereQuery = {
    isPerformer: { _eq: isPerformer },
    userId: { _eq: userId },
  };
  if (search && search !== '') {
    whereQuery._or = [
      {
        email: {
          _ilike: search,
        },
      },
      {
        favoriteUser: {
          _or: [
            {
              firstName: { _ilike: search },
            },
            {
              lastName: { _ilike: search },
            },
            {
              patronymic: { _ilike: search },
            },
            {
              organizationContractDetails: {
                name: { _ilike: search },
              },
            },
          ],
        },
      },
    ];
  }
  return whereQuery;
};
