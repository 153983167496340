import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

// fix svg issue for mobile browsers: https://github.com/facebook/react/issues/17441
const StarIcon = ({ id, ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id={`starMask-${id}`}>
      <path id={`path${id}`} fill="white" d="M15.653 7.28235C15.9679 6.97542 16.0791 6.52496 15.9432 6.10621C15.8071 5.68747 15.4524 5.38855 15.0167 5.32512L11.1426 4.7622C10.9776 4.73817 10.835 4.63469 10.7613 4.48507L9.02933 0.974937C8.83486 0.580543 8.44014 0.335449 8.00026 0.335449C7.56069 0.335449 7.16597 0.580543 6.9715 0.974937L5.23918 4.48539C5.16549 4.63501 5.0226 4.73849 4.8576 4.76252L0.983514 5.32544C0.548111 5.38855 0.193125 5.68779 0.056962 6.10653C-0.0788811 6.52528 0.0322923 6.97574 0.34723 7.28267L3.15027 10.0149C3.26978 10.1315 3.32456 10.2994 3.29637 10.4634L2.6351 14.3215C2.57647 14.6611 2.66553 14.9914 2.88532 15.2519C3.22685 15.6578 3.82308 15.7815 4.29981 15.531L7.76445 13.7093C7.90927 13.6333 8.09157 13.634 8.23606 13.7093L11.701 15.531C11.8695 15.6197 12.0493 15.6646 12.2348 15.6646C12.5734 15.6646 12.8945 15.514 13.1152 15.2519C13.3353 14.9914 13.424 14.6605 13.3654 14.3215L12.7038 10.4634C12.6756 10.2991 12.7304 10.1315 12.8499 10.0149L15.653 7.28235Z" />
    </mask>
    <linearGradient id={`halfStarGradient-${id}`} x1="0%" y1="0%" x2="50%" y2="0%">
      <stop offset="1" stopColor="#F28536" />
      <stop offset="1" stopColor="#CECECE" />
    </linearGradient>
    <g mask={`url(#starMask-${id})`}>
      <rect x="0" y="0" width="16" height="16" />
      <rect id={`halfStar-${id}`} x="0" y="0" width="16" height="16" fill={`url(#halfStarGradient-${id})`} opacity="0" />
    </g>
  </svg>
);

StarIcon.propTypes = {
  id: PropTypes.number.isRequired,
};

const MIN_RATING_VALUE_FOR_SET = 0.5;

export default class Rating extends React.PureComponent {
  state = {
    value: 0,
  };

  componentDidMount() {
    this.initRating();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.initRating();
    }
  }

  initRating = () => {
    const { value } = this.props;

    this.setState({ value });
  };

  getRating = (event, index) => {
    const targetRect = event.target.getBoundingClientRect();
    const isHalf = Math.round(Math.abs(event.clientX - targetRect.left)) < targetRect.width / 2;

    return isHalf ? index - 0.5 : index;
  };

  handleClick = index => event => {
    const { value, onChange } = this.props;
    const rating = this.getRating(event, index);

    onChange(value === rating ? MIN_RATING_VALUE_FOR_SET : rating);
  };

  handleMouseOver = index => event => {
    this.setState({ value: this.getRating(event, index) });
  };

  renderStars(value) {
    const stars = [];
    const { isDisabled } = this.props;

    for (let index = 1; index <= 5; index++) {
      let filledClass;

      if (value >= index) {
        filledClass = 'filled';
      } else if (value < index && value > index - 1) {
        filledClass = 'half-filled';
      }

      stars.push(
        <StarIcon
          id={Date.now()}
          key={index}
          className={classNames('star', filledClass)}
          {...(!isDisabled ? {
            onMouseOver: this.handleMouseOver(index),
            onMouseMove: this.handleMouseOver(index),
            onMouseLeave: this.initRating,
            onClick: this.handleClick(index),
          } : {})}
        />,
      );
    }

    return stars;
  }

  renderRatingContainer = () => {
    const { value } = this.state;
    const { className, count, isDisabled } = this.props;
    return <div className={classNames('rating-container', className, { disabled: isDisabled })}>
      {this.renderStars(value)}
      {typeof count === 'number' && <span className="count">({count})</span>}
    </div>;
  };

  render() {
    const { label } = this.props;

    return <>
      {label
        ? <div className="rating-wrapper">
          <label>{label}</label>
          {this.renderRatingContainer()}
        </div>
        : this.renderRatingContainer()}
    </>;
  }

  static propTypes = {
    value: PropTypes.number,
    count: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: 0,
    count: null,
    className: '',
    label: '',
    isDisabled: true,
    onChange() {},
  };
}
