import { compose, defaultProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';

import Drafts from './index';
import { showError } from '../../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../../utils/messages';
import {
  prepareToInsertRemarks,
} from '../../../../../graphql/model/remark';

const INSERT_REMARK_QUERY = loader('../../../../../graphql/queries/remark/insert.graphql');

const withInsertRemarkMutation = graphql(INSERT_REMARK_QUERY, {
  name: 'insertRemark',
});

const mapStateToProps = ({ session: { user } }) => ({
  userId: user.id,
});

const mergeProps = (
  { userId },
  dispatchProps,
  {
    insertRemark,
    ...ownProps
  },
) => ({
  ...ownProps,
  onAddRemarks: data => insertRemark({
    variables: {
      remarks: prepareToInsertRemarks(data),
    },
  }).then(result => {
    if (result.errors) {
      throw result.errors;
    }
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
});

export default compose(
  withRouter,
  defaultProps({
    fullUserRoles: true,
  }),
  withInsertRemarkMutation,
  connect(mapStateToProps, null, mergeProps),
)(Drafts);
