import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';

export default class EditVacancyModal extends React.Component {
  handleClose = this.props.reset;

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleUpdateContract = async props => {
    await this.props.onUpdateContract(props);
    this.handleCancel();
  };

  render() {
    const {
      handleSubmit,
      submitting,
      onRef,
      isPerformer,
    } = this.props;

    return <Modal
      className="edit-vacancy-modal"
      title={`Редактирование информации по ${isPerformer ? 'лоту' : 'вакансии'}`}
      onClose={this.handleClose}
      onRef={onRef}
    >
      <Form onSubmit={handleSubmit(this.handleUpdateContract)} className="edit-vacancy-form">
        <Field
          name="subject"
          component={FormGroupField}
          type="text"
          label="Предмет торгов"
          isReadonly
        />
        <Field
          name="object.name"
          component={FormGroupField}
          type="text"
          label="Наименование объекта"
          isReadonly
        />
        <Field
          name="object.address"
          component={FormGroupField}
          type="text"
          label="Адрес объекта"
          isReadonly
        />
        <Field
          name="object.type"
          component={FormGroupField}
          type="text"
          label="Тип объекта"
          isReadonly
        />
        <Field
          name="object.stage"
          component={FormGroupField}
          type="text"
          label="Стадия"
          isReadonly
        />
        <Field
          name="deadline"
          component={FormGroupField}
          type="date"
          label="Срок завершения всех этапов"
          isMultiline
          isDisabled
        />
        <Field
          name="applicationDeadline"
          component={FormGroupField}
          type="date"
          label="Окончание приема заявок"
          minDate={new Date()}
          isMultiline
        />
        <div className="actions">
          <Button
            className="secondary"
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            className="primary"
            isLoading={submitting}
            isLarge
            buttonProps={{
              type: 'submit',
            }}
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onUpdateContract: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onRef: PropTypes.object.isRequired,
    isPerformer: PropTypes.bool.isRequired,
  };
}
