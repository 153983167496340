import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import withObjectFilesMutationReferences from '../../../graphql/hoc/objectFilesMutationReferences';

import AddProjectComponentFilesVersion from './index';

export default compose(
  withObjectFilesMutationReferences,
  reduxForm({
    form: 'addProjectComponentFilesVersionModal',
  }),
)(AddProjectComponentFilesVersion);
