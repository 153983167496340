import difference from 'lodash/difference';

export const getUserChiefEngineerExpertiseExperience = ({ userChiefEngineerExpertiseExperience: experience, id }) => experience
  ? experience.map(experienceId => ({
    experienceId,
    userId: id,
  }))
  : [];

export const getUserChiefEngineerExpertiseExperienceToDelete = ({
  initialUserChiefEngineerExpertiseExperience,
  userChiefEngineerExpertiseExperience,
}) => initialUserChiefEngineerExpertiseExperience
  ? difference(initialUserChiefEngineerExpertiseExperience, userChiefEngineerExpertiseExperience)
  : [];
