export const VALIDATION_MESSAGES = {
  passwordTooShort: 'Пароль должен содержать минимум 5 символов',
  passwordTooWeak: 'Пароль слишком простой. Используйте минимум 1 заглавную и 1 строчную букву, 1 цифру и 1 специальный знак',
  repeatPassword: 'Подтвердите пароль',
  repeatNewPassword: 'Подтвердите новый пароль',
  passwordsMismatch: 'Пароли не совпадают',
  invalidEmail: 'Некорректный e-mail',
  invalidCipher: 'Неправильный шифр. Используйте только буквы, цифры и символы ./-',
  requiredEmail: 'Введите e-mail',
  requiredPassword: 'Введите пароль',
  requiredFirstName: 'Введите имя',
  requiredLastName: 'Введите фамилию',
  requiredValidPhone: 'Введите корректный телефон',
  requiredName: 'Введите наименование',
  requiredObject: 'Укажите объект',
  requiredStage: 'Укажите стадию',
  requiredProjectComponent: 'Укажите раздел',
  requiredCustomName: 'Введите наименование',
  requiredAddress: 'Введите адрес',
  requiredDeadline: 'Введите срок окончания работы',
  requiredApplicationDeadline: 'Введите срок окончания приема заявок',
  requiredObjectType: 'Выберите тип объекта',
  requiredProjectComponents: 'Выберите работы',
  requiredRegion: 'Выберите регион',
  requiredChiefEngineer: 'Выберите ГИПа',
  requiredCipher: 'Введите шифр',
  requiredContractDetailType: 'Выберите реквизиты для договора',
  requiredContractFileType: 'Выберите тип загружаемых файлов',
  requiredTariffPlan: 'Выберите тарифный план',
  requiredInn: 'Введите ИНН',
  requiredInsuranceAccount: 'Введите СНИЛС',
  requiredBankBik: 'Введите БИК',
  requiredBankName: 'Введите наименование банка',
  requiredBankAccount: 'Введите Расчетный (лицевой) счет',
  requiredTaxSystemId: 'Выберите систему налогообложения',
  requiredTaskMessage: 'Введите текст задачи',
  requiredContract: 'Выберите лот/вакансию',
  invalidInn: 'Некорректный ИНН',
  invalidInsuranceAccount: 'Некорректный СНИЛС',
  invalidBankAccount: 'Некорректный расчетный (лицевой) счет',
  invalidBankBik: 'Некорректный БИК',
  invalidKpp: 'Некорректный КПП',
  invalidOgrn: 'Некорректный ОГРН',
  invalidCorrespondentAccount: 'Некорректный Корреспондентский счет',
  setStart: 'Укажите дату захода на экспертизу',
  setExpertiseFile: 'Загрузите файл результатов экспертизы',
  setExpertiseLinks: 'Укажите разделы экспертизы',
  fileShouldBeLessThan: 'Размер файла не должен превышать MAX_FILE_SIZE_MB МБайт',
  requiredEmployee: 'Выберите назначаемого',
  description: 'Введите текст',
  remark: 'Введите замечание',
  request: 'Введите запрос документации',
  response: 'Введите ответ на замечание',
  revisionMessage: 'Введите объяснение причины',
  requiredValue: 'Введите значение',
  requiredOption: 'Веберите значения',
  requiredBreakContractOption: 'Выберите условие',
  requiredAgreements: 'Необходимо отметить ваше согласие',
  paymentTermsTotalRate: 'Общая сумма процентов должна составлять 100%',
  bidAmountLessMinCommission: 'Ставка не может быть меньше суммы комиссии от минимальной суммы сделки',
  balanceLessValue: 'Текущая сумма недоступна для вывода средств',
  reassignmentUserIsRequired: 'Выберите пользователя для перепоручения',
  valueIsRequired: 'Выберите сумму',
  documentAddressIsRequired: 'Введите адрес',
  requiredGuiltyUserId: 'Выберите виноватую сторону',
};

export const PRIVATE_ACCOUNT = 'Закрытый профиль';
