import React from 'react';
import PropTypes from 'prop-types';

import InfiniteList from '../../base/infiniteList';
import Item from './item';

import './index.scss';

export default class ObjectsList extends React.PureComponent {
  render() {
    const { isOwner } = this.props;
    const emptyMessage = isOwner
      ? 'Список Ваших объектов пуст. Для создания нажмите "Добавить новый объект"'
      : 'Список объектов пуст';

    return <InfiniteList
      className="objects-list-layout"
      emptyMessage={emptyMessage}
      itemComponent={Item}
      {...this.props}
    />;
  }

  static propTypes = {
    items: PropTypes.array,
    isOwner: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
    isOwner: true,
  };
}
