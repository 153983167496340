import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import StageManagement from '../../../../components/objects/object/stageManagement';
import CreateEngineerVacancyModal from './createEngineerVacancy';
import CreatePerformerVacancyModal from './createPerformerVacancy';
import BreakContractModal from '../../../../components/modals/breakContract/container';
import Confirm2OptionsModal from '../../../../components/modals/confirm/2options';
import Loading from '../../../../components/base/loading';
import { goTo } from '../../../../utils/menu';
import { ROUTES } from '../../../../utils/routes';
import { getTurnInWorkSchema, getAcceptWorkSchema } from '../../../../components/modals/confirm/2options/schemas';
import InfoModal from '../../../../components/modals/info';
import { CREATE_VACANCY_EMPTY_REQUISITES, INFO_MESSAGES } from '../../../../utils/messages';
import DeleteVacancyModal from '../../../../components/modals/deleteVacancy/container';
import MakeArbitrationDecisionModal from '../../../../components/modals/makeArbitrationDecision/container';
import CopyFileModal from '../../../../components/modals/copyFile/container';
import Chat from '../../../../components/objects/object/stageManagement/projectComponentList/chat/container';

export default class ObjectStage extends Component {
  state = {
    selectedProjectComponent: null,
    selectedContract: {},
    selectedVacancyId: null,
    selectedFileId: null,
    stageId: null,
    isCreateEngineerVacancyModalShown: false,
    infoModalMessage: '',
    optionalButtonProps: null,
  };

  createEngineerVacancyModalRef = React.createRef();
  createPerformerVacancyModalRef = React.createRef();
  deleteVacancyInfoModalRef = React.createRef();
  contractDetailsModalRef = React.createRef();
  turnInWorkModalRef = React.createRef();
  acceptWorkModalRef = React.createRef();
  breakContractModalRef = React.createRef();
  makeArbitrationDecisionModalRef = React.createRef();
  copyFileModalRef = React.createRef();
  opcChatRef = React.createRef();
  stageChatRef = React.createRef();
  respondToRemarksModalRef = React.createRef();

  componentDidUpdate() {
    const { projectComponent, projectComponentId, isLoading, stageId } = this.props;
    const isOpcChatUrl = this.props.location.pathname.includes('projectComponents');
    const isChatOpen = !!new URLSearchParams(document.location.search.substring(1)).get('chat');

    if (!isLoading && stageId && isChatOpen && projectComponent) {
      let currentProjectComponent = projectComponent;

      if (projectComponentId !== projectComponent?.id) {
        currentProjectComponent = projectComponent.flattenedProjectComponents.find(opc => opc.id === projectComponentId);
      }

      isOpcChatUrl && currentProjectComponent
        ? this.handleOpenOpcChat(currentProjectComponent)
        : this.handleOpenStageChat();
      window.history.replaceState(null, '', this.props.location.pathname);
    }
  }

  goToContractDetails = () => {
    goTo(ROUTES.financeContractRequisites);
    window.scrollTo(0, 0);
  };

  handleClickChangeProjectComposition = () => {
    const { stageId, object: { id, stages } } = this.props;

    goTo(ROUTES.projectCompositionStage, { id, stageId: stages[stageId].projectComponentId });
  };

  handleClickRemarks = () => {
    const { stageId, object: { id }, projectComponent } = this.props;

    goTo(ROUTES.projectComponentRemark, { id, stageId, projectComponentId: projectComponent.id });
  };

  showCreateEngineerVacancyModal = () => {
    const {
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
      object: { isDemoTariff },
    } = this.props;

    if ((!selfContractDetails && !organizationContractDetails) && !isDemoTariff) {
      this.contractDetailsModalRef.current.toggleModal();
    } else {
      this.createEngineerVacancyModalRef.current.toggleModal();
      this.setState({ isCreateEngineerVacancyModalShown: true });
    }
  };

  showCreatePerformerVacancyModal = projectComponent => {
    const {
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
      object: { isDemoTariff },
    } = this.props;

    if ((!selfContractDetails && !organizationContractDetails) && !isDemoTariff) {
      this.contractDetailsModalRef.current.toggleModal();
    } else {
      this.setState({ selectedProjectComponent: projectComponent });
      this.createPerformerVacancyModalRef.current.toggleModal();
    }
  };

  toggleDeleteVacancyModal = ({ id, contractId }) => {
    this.setState({ selectedContract: { id: contractId }, selectedVacancyId: id });
    this.deleteVacancyInfoModalRef.current.toggleModal();
  };

  showTurnInWorkModal = projectComponent => {
    this.setState({ selectedProjectComponent: projectComponent });
    this.turnInWorkModalRef.current.toggleModal();
  };

  showAcceptWorkModal = projectComponent => {
    this.setState({ selectedProjectComponent: projectComponent });
    this.acceptWorkModalRef.current.toggleModal();
  };

  showBreakContractModal = (contractId, currentPhase) => {
    this.setState({ selectedContract: { id: contractId, currentPhase } });
    this.breakContractModalRef.current.toggleModal();
  };

  showMakeArbitrationDecisionModal = contractId => {
    this.setState({ selectedContract: { id: contractId } });
    this.makeArbitrationDecisionModalRef.current.toggleModal();
  };

  showInfoModal = (message, optionalButtonProps) => {
    this.setState({ message, optionalButtonProps });
    this.respondToRemarksModalRef.current.toggleModal();
  };

  handleClickInfoModalOptionalButton = () => {
    this.respondToRemarksModalRef.current.toggleModal();
    this.props.history.push({ search: '?showAddVersionModal=true' });
  };

  handleClickTurnInWork = (projectComponent, optionalButtonProps, {
    isAllRemarksResponded,
    isAtLeastOneResponsePositive,
    hasNewVersionAfterRemarkResponse,
    hasBothFilesAttached,
  }) => {
    if (!hasBothFilesAttached) {
      this.showInfoModal(INFO_MESSAGES.bothVersionFileTypesRequired);
    } else if (isAllRemarksResponded && (hasNewVersionAfterRemarkResponse || !isAtLeastOneResponsePositive)) {
      this.showTurnInWorkModal(projectComponent);
    } else if (isAllRemarksResponded) {
      this.showInfoModal(
        INFO_MESSAGES.createNewVersionFirst,
        {
          title: 'Добавить новую версию',
          onClickOptionalButton: this.handleClickInfoModalOptionalButton,
        },
      );
    } else this.showInfoModal(INFO_MESSAGES.workCannotBeHandedOverDueToRemarks, optionalButtonProps);
  };

  handleTurnInWork = async () => {
    await this.props.onTurnInWork(this.state.selectedProjectComponent);
    this.turnInWorkModalRef.current.toggleModal();
  };

  handleAcceptWork = async () => {
    const { onAcceptWork, onForceAcceptWork } = this.props;
    const { contractId, isForceAcceptWork, ...projectComponent } = this.state.selectedProjectComponent;

    if (contractId && isForceAcceptWork) {
      await onForceAcceptWork(contractId);
    } else {
      await onAcceptWork(projectComponent);
    }
    this.acceptWorkModalRef.current.toggleModal();
  };

  handleCopyVersionFile = fileId => {
    this.setState({ selectedFileId: fileId });
    this.copyFileModalRef.current.toggleModal();
  };

  handleCloseCopyFileModal = () => {
    this.setState({ selectedFileId: null });
  };

  handleOpenStageChat = () => {
    this.setState({ stageId: this.props.stageId });
    this.opcChatRef.current.toggleChat(false);
    this.stageChatRef.current.toggleChat(true);
  };

  handleCloseStageChat = () => {
    this.setState({ stageId: null });
  };

  handleOpenOpcChat = projectComponent => {
    this.setState({ selectedProjectComponent: projectComponent });
    this.stageChatRef.current.toggleChat(false);
    this.opcChatRef.current.toggleChat(true);
  };

  handleCloseOpcChat = () => {
    this.setState({ selectedProjectComponentId: null });
  };

  render() {
    const {
      isLoading,
      object,
      stageId,
      userId,
      projectComponentId,
      projectComponent,
      isExpert,
      onCreateDataRequest,
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
      object: { isDemoTariff },
    } = this.props;

    const {
      selectedProjectComponent,
      selectedContract,
      selectedVacancyId,
      selectedFileId,
      isCreateEngineerVacancyModalShown,
      optionalButtonProps,
    } = this.state;

    const { id: opcId, type, processedCode: opcProcessedCode } = selectedProjectComponent || {};

    if (isLoading || !stageId) {
      return <Loading />;
    } else if (!isLoading && (!object || Object.keys(object).length === 0 || !object.stages[stageId])) {
      return <Redirect to={ROUTES.notFound} />;
    } else if (!isLoading && !projectComponent) {
      return <Redirect to={ROUTES.projectComponents.replace(':id', object.id).replace(':stageId', stageId)} />;
    }

    const currentStage = object.stages[stageId];
    const stageName = currentStage.name;
    const isBreakPending = currentStage.stageEngineers
      .find(({ contractId }) => contractId === selectedContract.id)?.isBreakPending;
    const isDemoMode = (!selfContractDetails && !organizationContractDetails) && isDemoTariff;

    return <>
      <StageManagement
        projectComponent={projectComponent}
        object={object}
        stageId={stageId}
        selectedProjectComponentId={projectComponentId}
        userId={userId}
        isExpert={isExpert}
        onClickCreateEngineerVacancy={this.showCreateEngineerVacancyModal}
        onClickCreatePerformerVacancy={this.showCreatePerformerVacancyModal}
        onClickDeleteVacancy={this.toggleDeleteVacancyModal}
        onClickChangeProjectComposition={this.handleClickChangeProjectComposition}
        onClickRemarks={this.handleClickRemarks}
        onClickTurnInWork={this.handleClickTurnInWork}
        onClickAcceptWork={this.showAcceptWorkModal}
        onClickBreakContract={this.showBreakContractModal}
        onClickMakeArbitrationDecision={this.showMakeArbitrationDecisionModal}
        onClickCopyVersionFiles={this.handleCopyVersionFile}
        onClickOpenOpcChat={this.handleOpenOpcChat}
        onClickOpenStageChat={this.handleOpenStageChat}
        onCreateDataRequest={onCreateDataRequest}
        showInfoModal={this.showInfoModal}
      />
      <CreateEngineerVacancyModal
        onRef={this.createEngineerVacancyModalRef}
        object={object}
        stageId={stageId}
        isSkip={!isCreateEngineerVacancyModalShown}
        isDemoMode={isDemoMode}
      />
      <CreatePerformerVacancyModal
        onRef={this.createPerformerVacancyModalRef}
        object={object}
        stageId={stageId}
        isSkip={!selectedProjectComponent}
        projectComponent={selectedProjectComponent}
        isDemoMode={isDemoMode}
      />
      <Confirm2OptionsModal
        onRef={this.turnInWorkModalRef}
        {...getTurnInWorkSchema(
          selectedProjectComponent,
          this.handleTurnInWork,
          () => this.turnInWorkModalRef.current.toggleModal(),
        )}
      />
      <Confirm2OptionsModal
        onRef={this.acceptWorkModalRef}
        {...getAcceptWorkSchema(
          selectedProjectComponent,
          this.handleAcceptWork,
          () => this.acceptWorkModalRef.current.toggleModal(),
          selectedProjectComponent?.isCompleteness,
        )}
      />
      <BreakContractModal
        onRef={this.breakContractModalRef}
        contractId={selectedContract.id}
        currentPhase={selectedContract.currentPhase}
        isBreakPending={isBreakPending}
      />
      <InfoModal
        onRef={this.contractDetailsModalRef}
        message={CREATE_VACANCY_EMPTY_REQUISITES}
        buttonTitle="Перейти на форму заполнения реквизитов"
        onClick={this.goToContractDetails}
      />
      <DeleteVacancyModal
        onRef={this.deleteVacancyInfoModalRef}
        id={selectedVacancyId}
        contractId={selectedContract.id}
        isLot
      />
      <MakeArbitrationDecisionModal
        onRef={this.makeArbitrationDecisionModalRef}
        contractId={selectedContract.id}
      />
      <CopyFileModal
        onRef={this.copyFileModalRef}
        fileId={selectedFileId}
        onClose={this.handleCloseCopyFileModal}
      />
      <Chat
        id={opcId}
        title={`Чат по ${type?.dativeName} "${opcProcessedCode}"`}
        onRef={this.opcChatRef}
        onCloseOpcChat={this.handleCloseOpcChat}
      />
      <Chat
        id={this.state.stageId}
        isStageChat
        title={`Чат "${stageName}"`}
        onRef={this.stageChatRef}
        onCloseStageChat={this.handleCloseStageChat}
      />
      <InfoModal
        message={this.state.message}
        optionalButtonProps={optionalButtonProps}
        onRef={this.respondToRemarksModalRef}
      />
    </>;
  }

  static propTypes = {
    userId: PropTypes.number.isRequired,
    isExpert: PropTypes.bool,
    projectComponent: PropTypes.object,
    isLoading: PropTypes.bool,
    object: PropTypes.object,
    stageId: PropTypes.number,
    projectComponentId: PropTypes.number,
    taxSystems: PropTypes.array,
    contractDetails: PropTypes.object,
    vacancyTypeIds: PropTypes.object,
    dataRequests: PropTypes.array,
    subscription: PropTypes.func,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onTurnInWork: PropTypes.func,
    onAcceptWork: PropTypes.func,
    onForceAcceptWork: PropTypes.func,
    onCreateDataRequest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoading: true,
    stageId: null,
    isExpert: false,
    projectComponentId: null,
    projectComponent: null,
    object: {},
    taxSystems: [],
    contractDetails: {},
    vacancyTypeIds: {},
    dataRequests: [],
    subscription() {},
    onTurnInWork() {},
    onAcceptWork() {},
    onForceAcceptWork() {},
  };
}
