import Base from './base';
import { ROUTES } from '../utils/routes';

const BASE_URL = process.env.REACT_APP_SERVER_API_PATH;

export class PublicApi extends Base {
  constructor() {
    super(BASE_URL);

    this.apiClient.interceptors.response.use(response =>
      Promise.resolve(response.data ? response.data : null),
    );
  }

  getPublicTariffPlans = () => this.get(ROUTES.publicTariffs);
}

export default new PublicApi();
