import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector } from 'redux-form';
import { compose, withProps, withContext } from 'recompose';

import AuthorizedApi from '../../../../api/authorized';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import { getSelectedProjectComponents } from '../../../../graphql/model/projectComponent';
import { getSubmitValidation, getValidation } from '../../../../utils/validation';
import UploadExpertiseModal from './index';
import { prepareToInsertExpertise } from '../../../../graphql/model/expertise';

const FORM_NAME = 'uploadExpertiseForm';

const getFormValue = formValueSelector(FORM_NAME);

const INSERT_EXPERTISE_MUTATION = loader('../../../../graphql/queries/object/insertExpertiseFile.graphql');
const withInsertExpertiseMutation = graphql(INSERT_EXPERTISE_MUTATION, {
  name: 'insertExpertise',
});

const mapStateToProps = (state, ownProps) => {
  const selectedPositives = getFormValue(state, 'links.positive') || [];
  const selectedNegatives = getFormValue(state, 'links.negative') || [];
  const { object } = ownProps;
  const exclusionProjectComponentIds = object.expertise.reduce((result, expertiseFile) => [
    ...result,
    ...expertiseFile.projectComponents.map(({ id }) => id),
  ], []);

  return {
    linksPositive: getSelectedProjectComponents(object.stages, selectedPositives, selectedNegatives.concat(exclusionProjectComponentIds), true),
    linksNegative: getSelectedProjectComponents(object.stages, selectedNegatives, selectedPositives.concat(exclusionProjectComponentIds), true),
    object,
  };
};

const mergeProps = withProps(({ insertExpertise, object, onRef }) => ({
  onRef,
  objectId: object.id,
  onFileUpload: AuthorizedApi.uploadFile,
  onUploadExpertise: data => insertExpertise({
    variables: {
      expertise: prepareToInsertExpertise(object, data),
    },
  }).then(result => {
    if (result.errors) {
      throw result.errors;
    }
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
}));

export default compose(
  withInsertExpertiseMutation,
  connect(mapStateToProps),
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
  reduxForm({
    form: FORM_NAME,
    validate: getValidation(['start', 'expertiseFile', 'links.positive', 'links.negative']),
    onSubmitFail: getSubmitValidation,
  }),
)(UploadExpertiseModal);
