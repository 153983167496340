import { withStateHandlers, branch, lifecycle, compose, defaultProps, withProps } from 'recompose';
import { connect } from 'react-redux';
import keyBy from 'lodash/keyBy';
import { matchPath } from 'react-router-dom';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { ROUTES } from '../../../utils/routes';
import { showError } from '../../../api/error';
import TariffPlans from './index';
import { formatTariffPlans } from '../../../graphql/model/tariff';
import PublicApi from '../../../api/public';

import withContractDetailsReferencesQuery from '../../../graphql/hoc/contractDetailsReferences';
import {
  CONTRACT_DETAIL_TYPE,
  ORGANIZATION_CONTRACT_DETAIL_NAME,
  SELF_CONTRACT_DETAIL_NAME,
} from '../../../utils/contractDetailType';

const mapStateToProps = (state, ownProps) => ({
  userId: state.session.user.id,
  isAuthorized: !!state.session.user.id,
  isPublicTariffs: !!matchPath(ownProps.location.pathname, ROUTES.publicTariffs),
});

const mergeProps = withProps(({ contractDetails = {} }) => {
  const filteredContractDetails = Object.keys(contractDetails)
    .filter(key => key !== '__typename' && contractDetails[key])
    .map(key => ({
      type: key.indexOf('self') > -1 ? SELF_CONTRACT_DETAIL_NAME : ORGANIZATION_CONTRACT_DETAIL_NAME,
      name: key.indexOf('self') > -1
        ? CONTRACT_DETAIL_TYPE[SELF_CONTRACT_DETAIL_NAME]
        : CONTRACT_DETAIL_TYPE[ORGANIZATION_CONTRACT_DETAIL_NAME],
    }));

  return {
    contractDetails: filteredContractDetails.length > 0
      ? keyBy(filteredContractDetails, 'type')
      : null,
  };
});

export default compose(
  defaultProps({
    withSub: true,
  }),
  connect(mapStateToProps),
  withStateHandlers(
    () => ({
      tariffPlans: [],
    }),
    {
      setTariffPlans: () => tariffPlans => ({
        tariffPlans,
      }),
    },
  ),
  lifecycle({
    componentDidMount() {
      PublicApi.getPublicTariffPlans()
        .then(tariffPlans => {
          this.props.setTariffPlans(formatTariffPlans(tariffPlans));
        })
        .catch(error => {
          showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        });
    },
  }),
  branch(
    ({ isAuthorized }) => isAuthorized,
    compose(withContractDetailsReferencesQuery, mergeProps),
  ),
)(TariffPlans);
