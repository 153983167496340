import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import Loading from '../../../components/base/loading';
import WebAnalyticsRoute from '../../../layout/app/webAnalyticsRoute';
import ContractHeader from '../../../components/contract/header';
import NotImplementedYet from '../../../components/notImplementedYet';
import { noop } from 'lodash';
import Button from '../../../components/base/button';
import PublishVacancyModal from '../../../components/modals/publishVacancy/container';
import Confirm2OptionsModal from '../../../components/modals/confirm/2options';
import EditVacancyModal from '../../../components/modals/editVacancy/container';
import { getRevokeVacancySchema } from '../../../components/modals/confirm/2options/schemas';
import { ROUTES } from '../../../utils/routes';
import DeleteVacancyModal from '../../../components/modals/deleteVacancy/container';
import { goTo } from '../../../utils/menu';
import VacancyItem from '../../../components/vacancies/list/itemContainer';
import { ICONS } from '../../../utils/icons';
import Chat from '../../../components/contract/profile/applicationTerms/chat/container';

import './index.scss';

export default class VacancyMenu extends PureComponent {
  publishVacancyModalRef = React.createRef();
  revokeVacancyModalRef = React.createRef();
  editVacancyModalRef = React.createRef();
  deleteVacancyModalRef = React.createRef();
  chatRef = React.createRef();

  state = {
    isContractExpanded: false,
  };

  setItemState = isExpanded => {
    this.setState({ isContractExpanded: isExpanded });
  };

  componentDidUpdate() {
    const { isChatOpen, vacancy, history } = this.props;
    const urlParams = new URLSearchParams(this.props.location.search.slice(1));

    if (isChatOpen && vacancy?.contract?.id) {
      this.handleOpenChat();
      urlParams.delete('chat');
      history.replace(urlParams);
    }
  }

  showPublishVacancyModal = () => {
    this.publishVacancyModalRef.current.toggleModal();
  };

  showRevokeVacancyModal = () => {
    this.revokeVacancyModalRef.current.toggleModal();
  };

  handleOpenChat = () => {
    this.chatRef.current?.toggleChat();
  };

  handlePublishVacancy = data => this.props.onChangeCompetitionState({ ...data, isCompetitionStarted: true });

  handleRevokeVacancy = () => this.props.onChangeCompetitionState({ isCompetitionStarted: false, applicationDeadline: null })
    .then(() => {
      this.revokeVacancyModalRef.current.toggleModal();
    }).catch(noop);

  showEditVacancyModal = () => {
    this.editVacancyModalRef.current.toggleModal();
  };

  showDeleteVacancyModal = () => {
    this.deleteVacancyModalRef.current.toggleModal();
  };

  goToVacancies = () => {
    goTo(this.props.vacancy.isPerformer ? ROUTES.tenders : ROUTES.vacancies);
  };

  getActionButton = () => {
    const { vacancy: { isPerformer, object, isCompetitionStarted, employeeUserId }, userId } = this.props;

    if (userId === object.userId) {
      return <>
        {isCompetitionStarted
          ? <Button onClick={this.showRevokeVacancyModal}>
            {isPerformer ? 'Снять с торгов' : 'Отменить конкурс'}
          </Button>
          : <Button onClick={this.showPublishVacancyModal}>
            {isPerformer ? 'Разместить на торги' : 'Разместить вакансию'}
          </Button>}
        {!employeeUserId && <Button
          IconComponent={ICONS.trash}
          onClick={this.showDeleteVacancyModal}
          isBorderless
          buttonProps={{ name: 'deleteButton' }}
        />}
      </>;
    }
  };

  render() {
    const {
      isLoading,
      vacancyMenu,
      defaultRoute,
      vacancy,
      userId,
      isTenders,
      onChangeCompetitionState,
    } = this.props;

    if (isLoading && !vacancy?.id) {
      return <Loading />;
    } else if (!isLoading && !vacancy.id) {
      return <Redirect to={ROUTES.notFound} />;
    }

    return <>
      <ContractHeader menu={vacancyMenu} />
      <div className="vacancies-list-layout">
        <VacancyItem
          data={vacancy}
          setItemState={this.setItemState}
          isExpansionDisabled={false}
          isExpanded={this.state.isContractExpanded}
          actionButton={this.getActionButton()}
          onClickEdit={this.showEditVacancyModal}
          isProfile
        />
      </div>
      <Switch>
        {vacancyMenu.map(({ route, name, component: Component = NotImplementedYet }, index) => <WebAnalyticsRoute
          key={index}
          path={route}
          name={name}
          render={props => <Component
            {...props}
            vacancy={vacancy}
            userId={userId}
            isTenders={isTenders}
            onChangeCompetitionState={onChangeCompetitionState}
            onClickOpenChat={this.handleOpenChat}
          />}
        />)}
        <Redirect to={defaultRoute} />
      </Switch>
      <PublishVacancyModal
        onRef={this.publishVacancyModalRef}
        onPublishVacancy={this.handlePublishVacancy}
        vacancy={vacancy}
        isPerformer={isTenders}
      />
      <Confirm2OptionsModal
        onRef={this.revokeVacancyModalRef}
        {...getRevokeVacancySchema(
          isTenders,
          this.handleRevokeVacancy,
          () => this.revokeVacancyModalRef.current.toggleModal(),
        )}
      />
      <EditVacancyModal
        onRef={this.editVacancyModalRef}
        data={vacancy || {}}
        isSkip={!vacancy}
      />
      <DeleteVacancyModal
        onRef={this.deleteVacancyModalRef}
        id={vacancy.vacancyId}
        contractId={vacancy.contract.id}
        onAfterDelete={this.goToVacancies}
        isLot={vacancy.isPerformer}
      />
      <Chat onRef={this.chatRef} title={`Чат по ${isTenders ? 'лоту' : 'вакансии'}`} id={vacancy.contract.id} />
    </>;
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    isChatOpen: PropTypes.bool,
    vacancyMenu: PropTypes.array.isRequired,
    defaultRoute: PropTypes.string.isRequired,
    vacancy: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    isTenders: PropTypes.bool.isRequired,
    onChangeCompetitionState: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    isChatOpen: false,
  }
}
