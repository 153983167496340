import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import FullName from '../fullName';
import FormGroupField from '../formGroup';

class UserInfo extends PureComponent {
  render() {
    return <>
      <FullName />
      <Field
        name="email"
        component={FormGroupField}
        type="text"
        label="E-mail"
      />
      <Field
        name="phone"
        component={FormGroupField}
        type="text"
        label="Контактный телефон"
        mask="+7 (999)-999-9999"
      />
    </>;
  }
}

export default UserInfo;
