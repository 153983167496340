export const DOCS = {
  // key and value must match database userDocuments field
  diplomaFile: {
    label: 'Документ подтверждающий специализацию (диплом)',
    value: 'diplomaFile',
  },
  qualificationFile: {
    label: 'Документ подтверждающий квалификацию (актуальный)',
    value: 'qualificationFile',
  },
  nrsNotificationFile: {
    label: 'Уведомление о включении сведений в НРС (необязательно)',
    value: 'nrsNotificationFile',
  },
  expertAccreditationFile: {
    label: 'Аттестат аккредитованного эксперта (необязательно)',
    value: 'expertAccreditationFile',
  },
};
