import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import FavoritesList from '../../../components/favorites/list';
import FavoritesListHeader from '../../../components/favorites/list/header';
import InviteToContractModal from '../../../components/modals/inviteToContract/container';

export default class Favorites extends PureComponent {
  isSearchApplied = false;

  listRef = React.createRef();
  inviteToContractModalRef = React.createRef();

  state = {
    order: null,
    invitedUserId: null,
  };

  handleOnLoadMore = () => {
    const { fetchMore, favorites } = this.props;
    fetchMore(favorites.length);
  };

  setOrder = order => {
    this.setState({ order: { ...order } });

    if (order !== this.state.order) {
      this.props.sort(order);
    }
  };

  handleClickRemove = async id => {
    const { deleteFavorites } = this.props;

    await deleteFavorites({
      variables: {
        ids: [id],
      },
    });
  };

  handleSearch = value => {
    this.props.search(value);
    this.isSearchApplied = !!value;
  };

  handleInviteToContract = userId => {
    this.setState({ invitedUserId: userId });
    this.inviteToContractModalRef.current.toggleModal();
  }

  render() {
    const { count, isLoading, orderBy } = this.props;
    const { favorites, isPerformer } = this.props;

    return <>
      <FavoritesListHeader
        setOrder={this.setOrder}
        onSearch={this.handleSearch}
        orderBy={orderBy}
      />
      <FavoritesList
        onRef={this.listRef}
        items={favorites}
        count={count}
        isLoading={isLoading}
        isSearchApplied={this.isSearchApplied}
        onLoadMore={this.handleOnLoadMore}
        onClickRemove={this.handleClickRemove}
        onClickInviteToContract={this.handleInviteToContract}
        onClickThumbsUp={noop}
        isPerformer={isPerformer}
      />
      <InviteToContractModal onRef={this.inviteToContractModalRef} invitedUserId={this.state.invitedUserId} />
    </>;
  }

  static propTypes = {
    favorites: PropTypes.array,
    count: PropTypes.number,
    isLoading: PropTypes.bool,
    isPerformer: PropTypes.bool.isRequired,
    deleteFavorites: PropTypes.func.isRequired,
    sort: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    orderBy: PropTypes.object,
  };

  static defaultProps = {
    isLoading: false,
    favorites: [],
    count: 0,
    orderBy: {},
    networkStatus: 0,
  };
}
