import { normalizeMoney } from '../../utils/money';

export const getBidRates = bidRates => {
  return (bidRates || []).map(({ minAmount, maxAmount, rate, minCommission }) => ({
    minAmount: normalizeMoney(minAmount),
    maxAmount: normalizeMoney(maxAmount),
    rate,
    minCommission: normalizeMoney(minCommission),
  }));
};
