import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export default class RestrictedInformation extends React.PureComponent {
  render() {
    return <div className="restricted-information">
      <h5>{this.props.title}</h5>
      <span className="hint">Пользователь закрыл информацию в данном блоке</span>
    </div>;
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
  };
}
