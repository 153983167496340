import React from 'react';
import PropTypes from 'prop-types';

import { getFullName } from '../../../utils/name';

import Button from '../../base/button';
import { ICONS } from '../../../utils/icons';
import UserItem from '../../user/list/item';
import Confirm2OptionsModal from '../../modals/confirm/2options';
import { getRemoveFromFavoritesSchema } from '../../modals/confirm/2options/schemas';
import ProfileLink from '../../base/profileLink';
import Section from '../../base/section';

export default class FavoriteItem extends UserItem {
  removeFromFavoritesModalRef = React.createRef();

  toggleRemoveFavoriteModal = () => {
    this.removeFromFavoritesModalRef.current.toggleModal();
  };

  handleSuccessRemove = async () => {
    const { onClickRemove, afterRemove, data } = this.props;
    await onClickRemove(data.favoriteId);
    afterRemove();
  };

  renderActionButtons() {
    const { data, onClickThumbsUp, onClickInviteToContract } = this.props;
    return <Section className="action-buttons">
      <Button
        onClick={() => onClickInviteToContract(data.favoriteUserId)}
        IconComponent={ICONS.sendLetter}
        isBorderless
      >
        Предложить сотрудничество
      </Button>
      <Button
        onClick={onClickThumbsUp}
        IconComponent={ICONS.thumbsUp}
        isBorderless
      >
        Рекомендовать
      </Button>
      <Button
        onClick={this.toggleRemoveFavoriteModal}
        IconComponent={ICONS.removeFavoriteEngineer}
        isBorderless
        buttonProps={{
          name: 'removeFromFavoriteButton',
        }}
      >
        Убрать из избранного
      </Button>
    </Section>;
  }

  renderPendingHeader({
    favoriteUserId: userId,
    email,
    invitationDate,
    companyName,
    ...item
  }) {
    const fullName = getFullName(item);
    return [
      {
        title: 'Получатель приглашения',
        className: 'recipient',
        value: userId && fullName ? <ProfileLink text={fullName} userId={userId} /> : email,
      },
      {
        title: 'Отправлено',
        className: 'sent',
        value: invitationDate,
      },
      {
        title: 'Статус',
        className: 'status',
        value: <span className="colored red">не рассмотрено</span>,
      },
    ];
  }

  render() {
    const { data, isPerformer, showToggle } = this.props;

    return Object.assign(data, { isReadable: data.isConfirmed || data.isPublic }) && <>
      <UserItem
        {...this.props}
        userId={data.favoriteUserId || 0}
        className="favorite-item"
        showToggle={showToggle}
        headerData={data.isPublic || data.isConfirmed ? [] : this.renderPendingHeader(data)}
        actionButtons={this.renderActionButtons()}
      />
      <Confirm2OptionsModal
        onRef={this.removeFromFavoritesModalRef}
        {...getRemoveFromFavoritesSchema(
          data,
          isPerformer,
          this.handleSuccessRemove,
          this.toggleRemoveFavoriteModal,
        )}
      />
    </>;
  }

  static propTypes = {
    data: PropTypes.object,
    showToggle: PropTypes.bool,
    showContent: PropTypes.bool,
    afterRemove: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickInviteToContract: PropTypes.func,
    onClickThumbsUp: PropTypes.func,
    setItemState: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    showToggle: true,
    showContent: true,
    afterRemove() {},
    onClickThumbsUp() {},
    onClickInviteToContract() {},
    onClickRemove() {},
    setItemState() {},
  };
}
