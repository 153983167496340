import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import SideBar from './index';
import { setSideBarVisibility } from '../../store/actions/sideBar';

const mapStateToProps = ({
  sideBar: {
    isMenuOpen,
  },
}) => ({
  isMenuOpen,
});

const mapDispatchToProps = dispatch => ({
  setSideBarVisibility: isOpen => dispatch(setSideBarVisibility(isOpen)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SideBar);
