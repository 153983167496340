import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../base/modal';
import Switch from '../../../base/switch';
import { ICONS } from '../../../../utils/icons';
import Button from '../../../base/button';

import './index.scss';

export default class ChangeExpertiseStatusModal extends React.PureComponent {
  state = {
    inProgress: false,
    isPositiveConclusion: true,
  }

  toggleChangeStatus = value => {
    this.setState({
      isPositiveConclusion: value,
    });
  }

  handleSubmit = async () => {
    this.setState({ inProgress: true });
    await this.props.updateExpertise(this.state.isPositiveConclusion);
    this.setState({ inProgress: false }, this.closeModal);
  }

  closeModal = () => {
    this.props.onRef.current.toggleModal();
  }

  render() {
    const { inProgress, isPositiveConclusion } = this.state;

    return <Modal
      className="change-expertise-status-modal"
      {...this.props}
    >
      <h5>Результат прохождения экспертизы</h5>
      <Switch
        id="changeStatusSwitch"
        labelLeft="Отрицательное заключение"
        labelRight="Положительное заключение"
        onChange={this.toggleChangeStatus}
        isChecked={isPositiveConclusion}
      />
      <div className="info">
        <ICONS.infoBold className="info-icon" />
        <p>Данное действие повлияет на подведение итогов по работе исполнителя</p>
      </div>
      <div className="action-buttons">
        <Button onClick={this.closeModal} isLarge isBorderless>Отмена</Button>
        <Button onClick={this.handleSubmit} isLarge isLoading={inProgress}>Изменить статус</Button>
      </div>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    updateExpertise: PropTypes.func.isRequired,
  };
}
