import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import noop from 'lodash/noop';

import Button from '../../../components/base/button';
import Payee from '../../../components/finance/contractDetails/payee';
import SelfIdentification from '../../../components/finance/contractDetails/selfIdentification';
import SelfBankDetails from '../../../components/finance/contractDetails/selfBankDetails';
import { ICONS } from '../../../utils/icons';
import { getSubmitValidation, getValidation } from '../../../utils/validation';

class SelfContractDetails extends React.PureComponent {
  render() {
    const { handleSubmit, submitting, taxSystems } = this.props;

    return <Form onSubmit={handleSubmit}>
      <Payee />
      <SelfIdentification />
      <SelfBankDetails taxSystems={taxSystems} />
      <div className="actions">
        <Button
          isLarge
          isDisabled={submitting}
          isLoading={submitting}
          buttonProps={{
            type: 'submit',
          }}
        >
          Сохранить изменения
        </Button>
        <Button
          className="print"
          onClick={noop}
          IconComponent={ICONS.printer}
          isBorderless
        >
          Версия для печати
        </Button>
      </div>
    </Form>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    taxSystems: PropTypes.array,
  };

  static defaultProps = {
    taxSystems: [],
  };
}

export default reduxForm({
  enableReinitialize: true,
  form: 'selfContractDetails',
  validate: getValidation([
    'firstName',
    'lastName',
    'email',
    'phone',
    'individualInn',
    'insuranceAccount',
    'bankAccount',
    'bankBik',
    'bankName',
    'taxSystemId',
  ]),
  onSubmitFail: getSubmitValidation,
})(SelfContractDetails);
