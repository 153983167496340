import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Loading from '../../../../../base/loading';
import Button from '../../../../../base/button';
import OverflowTip from '../../../../../base/overflowTip';

export function ConsentComponent({ item, onAddAcceptance, onClickDisagree }) {
  const { status, acceptances, isCustomer, isOwner } = item;
  const [inProgress, setProgress] = useState(false);

  const handleClickAgree = roleId => async () => {
    setProgress(true);
    await onAddAcceptance({
      objectProjectComponentExpertiseId: item.projectComponentExpertiseId,
      isAccepted: true,
      userRoleId: roleId,
    });
    setProgress(false);
  };

  const handleClickDisagree = () => onClickDisagree(item);

  return status === 'negative' && <>
    {acceptances.map(({ id, role, roleId, status: { name, kind, hint }, isMyRole }, index) => <React.Fragment key={index}>
      <p>{role}:</p>
      {isMyRole && !isCustomer && !isOwner && kind === 'neutral'
        ? inProgress
          ? <Loading />
          : <div className="action-buttons">
            <Button onClick={handleClickAgree(roleId)} isBorderless>Согласен</Button>
            <Button onClick={handleClickDisagree} isBorderless>Не согласен</Button>
          </div>
        : <p className={kind}>{hint ? <OverflowTip tooltipText={hint} showAlways>{name}</OverflowTip> : name}</p>}
    </React.Fragment>)}
  </>;
}

ConsentComponent.propTypes = {
  item: PropTypes.object.isRequired,
  onAddAcceptance: PropTypes.func.isRequired,
  onClickDisagree: PropTypes.func.isRequired,
};
