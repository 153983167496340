import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import CreatePerformerVacancyModal from '../../../../components/modals/createVacancy/performerContainer';
import {
  getFavoriteContractDetails,
  prepareToInsertContract,
  getContractDetailsList,
  getSelectedEmployee,
} from '../../../../graphql/model/employee';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import withVacancyReferencesQuery from '../../../../graphql/hoc/vacancyReferences';
import withFavoriteReferencesQuery from '../../../../graphql/hoc/favoriteReferences';

const INSERT_CONTRACT_MUTATION = loader('../../../../graphql/queries/contract/insert.graphql');
const UPDATE_OBJECT_PROJECT_COMPONENT = loader('../../../../graphql/queries/object/updateProjectComponent.graphql');

const withInsertContractMutation = graphql(INSERT_CONTRACT_MUTATION, {
  name: 'insertContract',
});
const withUpdateObjectProjectComponent = graphql(UPDATE_OBJECT_PROJECT_COMPONENT, { name: 'updateObjectProjectComponent' });

const mergeProps = withProps(({
  favoritePerformers,
  projectComponent,
  vacancyTypeIds,
  stageId,
  updateObjectProjectComponent,
  insertVacancies,
  ...props
}) => {
  const { contractDetails, object, insertContract } = props;
  if (object && projectComponent) {
    const currentStage = object.stages[stageId];
    const { name: stageName } = currentStage;
    const { id: objectProjectComponentId, deadline, processedName: projectComponentName, type } = projectComponent;

    const customerContractDetails = getContractDetailsList(contractDetails);
    return {
      ...props,
      deadline,
      stageName,
      projectComponentName,
      projectComponentType: type,
      favoriteEmployees: getFavoriteContractDetails(favoritePerformers),
      contractDetailTypes: customerContractDetails,
      onCreateVacancy: ({
        contractDetailType,
        isSafeDeal,
        paymentTerms,
        competitionTerms,
        employee,
        totalAmount,
        deadline,
      }) => {
        const { employeeUserId, employeeContractDetails } = getSelectedEmployee(employee, favoritePerformers);
        return insertContract({
          variables: {
            contract: prepareToInsertContract({
              employeeUserId,
              employeeContractDetails,
              vacancyTypeId: vacancyTypeIds.performer,
              customerContractDetails: contractDetails[0],
              contractDetailType,
              isSafeDeal,
              paymentTerms,
              competitionTerms,
              totalAmount,
            }),
            projectComponentIds: [objectProjectComponentId],
            deadline,
            isPerformer: true,
          },
        }).then(result => {
          if (result.errors) {
            throw result.errors;
          }
          return updateObjectProjectComponent({
            variables: {
              id: objectProjectComponentId,
              item: {
                isExpertiseRequired: paymentTerms?.isExpertiseRequired,
              },
            },
          });
        }).then(result => {
          if (result.errors) {
            throw result.errors;
          }
        }).catch(error => {
          showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
          throw error;
        });
      },
    };
  }
});

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

export default compose(
  connect(mapStateToProps),
  withVacancyReferencesQuery,
  withFavoriteReferencesQuery,
  withInsertContractMutation,
  withUpdateObjectProjectComponent,
  mergeProps,
)(CreatePerformerVacancyModal);
