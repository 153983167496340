import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../button';

import './index.scss';

class MenuItem extends PureComponent {
  render () {
    const { IconComponent, isActive, onClick, children, badge, className, isBorderless, isDisabled } = this.props;
    return <Button
      IconComponent={IconComponent}
      className={classNames(className, 'menu-item', {
        active: isActive,
      })}
      onClick={onClick}
      isBorderless={isBorderless}
      isDisabled={isDisabled}
      badge={badge}
    >
      <div className="text-wrapper">
        {children}
      </div>
    </Button>;
  }
}

MenuItem.propTypes = {
  IconComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  isBorderless: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  IconComponent: '',
  children: '',
  badge: '',
  isActive: false,
  onClick() {},
  className: '',
  isBorderless: false,
  isDisabled: false,
};

export default MenuItem;
