import React from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import { compose } from 'recompose';

import Button from '../../base/button';
import PersonalData from '../edit/personalData';
import OrganizationData from './organization';
import CompetenceConfirmation from '../edit/competenceConfirmation';
import Specialization from '../edit/specialization';

import '../edit/index.scss';

class PublicProfileForm extends React.PureComponent {
  render() {
    const {
      handleSubmit,
      submitting,
      isPrivate,
      initialValues: {
        id,
        isInFavorite,
        userSpecialties,
        userProjectComponents,
        userObjectTypes,
        userChiefEngineerExpertiseExperience,
      },
      publicSettings,
      onClickInviteToContract,
    } = this.props;

    return <Form onSubmit={handleSubmit} className="profile-form">
      <PersonalData
        isReadonly
        className="view"
        isPrivate={isPrivate || !isInFavorite && publicSettings && !publicSettings.personalData}
      />
      <OrganizationData isPrivate={isPrivate || !isInFavorite && publicSettings && !publicSettings.organizationData} />
      <CompetenceConfirmation
        hiddenFields={['expertAccreditationNumber']}
        isReadonly
        isPrivate={isPrivate || !isInFavorite && publicSettings && !publicSettings.competenceConfirmation}
      />
      <Specialization
        userSpecialties={userSpecialties}
        userProjectComponents={userProjectComponents}
        userObjectTypes={userObjectTypes}
        userChiefEngineerExpertiseExperience={userChiefEngineerExpertiseExperience}
        isReadonly
        isPrivate={isPrivate || !isInFavorite && publicSettings && !publicSettings.specialization}
      />
      {!isPrivate && <Button
        onClick={() => onClickInviteToContract(id)}
        isLarge
        isLoading={submitting}
      >
        Предложить сотрудничество
      </Button>}
    </Form>;
  }
}

PublicProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClickInviteToContract: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  publicSettings: PropTypes.object,
};

PublicProfileForm.defaultProps = {
  publicSettings: null,
};

export default compose(
  reduxForm({
    form: 'publicProfile',
    enableReinitialize: true,
  }),
)(PublicProfileForm);
