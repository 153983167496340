import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const GET_USER_REFERENCES_QUERY = loader('../../graphql/queries/user/getReferences.graphql');

export default graphql(GET_USER_REFERENCES_QUERY, {
  name: 'getUserReferences',
  skip: props => props.isSkip,
  props: ({ getUserReferences: { userRole, loading }, ownProps: { fullUserRoles } }) => ({
    isUserReferencesLoading: loading,
    userRoles: (fullUserRoles ? userRole : userRole?.map(({ name }) => name)) || [],
  }),
});
