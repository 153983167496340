import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '../../../../base/grid';
import Button from '../../../../base/button';
import { ICONS } from '../../../../../utils/icons';
import { REMARK_TYPES } from '../../../../../graphql/model/remark';
import Input from '../../../../base/input';
import { CONTRACT_PHASES } from '../../../../../utils/phase';
import { INFO_MESSAGES } from '../../../../../utils/messages';

import './index.scss';

function Remark({ remark, onRemove, onSave }) {
  const [value, setValue] = useState('');
  const [isEditable, setEditable] = useState(false);

  const handleChange = value => {
    setValue(value);
  };

  const handleEdit = () => {
    setEditable(true);
  };

  const handleSave = () => {
    onSave(value);

    if (isEditable) {
      setEditable(false);
    }
  };

  return <div className="remark">
    {remark && !isEditable
      ? <>
        <p>{remark}</p>
        <div className="action-buttons">
          <Button
            className="btn-edit-remark"
            onClick={handleEdit}
            IconComponent={ICONS.pencil}
            isBorderless
          />
          <Button
            className="btn-remove-remark"
            onClick={onRemove}
            IconComponent={ICONS.trash}
            isBorderless
          />
        </div>
      </>
      : <>
        <Input
          type="textarea"
          className="add-remark-field"
          placeholder="Введите замечание..."
          onChange={handleChange}
          value={value}
        />
        <Button
          className="btn-remove-remark"
          onClick={handleSave}
        >Сохранить</Button>
      </>}
  </div>;
}

Remark.propTypes = {
  remark: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

Remark.defaultProps = {
  remark: '',
};

export default class RemarksGrid extends PureComponent {
  state = {
    remarks: {
      [REMARK_TYPES[0]]: [],
      [REMARK_TYPES[1]]: [],
    },
    type: REMARK_TYPES[0],
  };

  gridProps = [
    {
      key: 'index',
      columnName: '№ п/п',
      className: '',
      render: index => `${index + 1}`,
      withTooltip: true,
    },
    {
      key: 'remark',
      columnName: 'Содержание замечания',
      className: '',
      render: (remark, { index }) => <Remark
        remark={remark}
        onRemove={this.handleRemoveRemark(index)}
        onSave={this.handleSaveRemark(index)}
      />,
    },
  ];

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      type: nextProps.isExpertise ? REMARK_TYPES[0] : REMARK_TYPES[1],
    };
  }

  initState = () => {
    const { type, remarks } = this.state;

    remarks[type] = [];

    this.setState({ ...remarks });
  };

  getRemarks = () => this.state.remarks[this.props.isExpertise ? REMARK_TYPES[0] : REMARK_TYPES[1]]
    .filter(({ remark }) => remark?.length > 0);

  handleRemoveRemark = idx => () => {
    const { remarks, type } = this.state;
    const { enableSendToRegistry } = this.props;

    remarks[type] = remarks[type]
      .filter(({ index }) => index !== idx)
      .map((remark, index) => ({ ...remark, index }));

    this.setState({
      remarks: { ...remarks },
    });

    if (enableSendToRegistry) {
      enableSendToRegistry(remarks[type].length > 0);
    }
  };

  handleSaveRemark = idx => remark => {
    const { remarks, type } = this.state;
    const { enableSendToRegistry } = this.props;

    remarks[type][idx].remark = remark;

    this.setState({ remarks: { ...remarks } });

    if (enableSendToRegistry) {
      enableSendToRegistry(remarks[type].length > 0);
    }
  };

  handleAddRemark = () => {
    const { remarks, type } = this.state;

    remarks[type].push({ index: remarks[type].length });

    this.setState({ remarks: { ...remarks } });
  };

  render() {
    const { isExpertise, projectComponent: { vacancies } } = this.props;
    const { remarks, type } = this.state;
    const typeRemarks = remarks[type];
    const currentPhaseKey = vacancies.find(({ isActive, isPerformer }) => isActive && isPerformer)?.currentPhase?.key;
    const isExpertiseTabOnPhase3 = isExpertise && currentPhaseKey === CONTRACT_PHASES.phase3;
    const isAddButtonDisabled = typeRemarks.length > 0 && !typeRemarks[typeRemarks.length - 1].remark || isExpertiseTabOnPhase3;
    const infoMessageText = isExpertiseTabOnPhase3
      ? INFO_MESSAGES.addingExpertiseRemarksOnlyOnPhase2
      : INFO_MESSAGES.beSureSendToRegistryButtonIsClicked;

    return <>
      <div className="info">
        <div><ICONS.infoBold className="info-icon" /></div>
        <p>{infoMessageText}</p>
      </div>
      <Grid
        items={typeRemarks}
        gridProps={this.gridProps}
        showContainerToggle={false}
        useAlternateMobileContainer
      />
      <Button
        className="btn-new-remark"
        onClick={this.handleAddRemark}
        IconComponent={ICONS.circlePlus}
        isBorderless
        isDisabled={isAddButtonDisabled}
      >
        Добавить пункт
      </Button>
    </>;
  }

  static propTypes = {
    isExpertise: PropTypes.bool.isRequired,
    projectComponent: PropTypes.object,
    enableSendToRegistry: PropTypes.func,
  };

  static defaultProps = {
    enableSendToRegistry() {},
    projectComponent: {},
  };
}
