import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Switch from '../../components/base/switch';
import Loading from '../../components/base/loading';
import { EMPTY_CONTRACT_DETAILS_WARNING } from '../../utils/messages';
import { CONTRACT_DETAIL_TYPE } from '../../utils/contractDetailType';
import SelfContractDetails from '../../components/finance/contractDetails/self';
import OrganizationContractDetails from '../../components/finance/contractDetails/organization';

import './index.scss';

export default class ContractDetails extends React.PureComponent {
  state = {
    isSelf: true,
    isSelfResident: true,
    isOrganizationResident: true,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValues: { self, organization } } = this.props;
    const { isSelf } = this.state;

    const contractDetails = isSelf ? self : organization;

    if (contractDetails && typeof contractDetails.isResident === 'boolean' && !prevProps.initialValues[isSelf ? 'self' : 'organization']) {
      this.handleResidentFormSwitchChange(contractDetails.isResident);
    }
  }

  handleSubmit = props => this.props.onSubmit(props, this.state.isSelf);

  handleFormSwitchChange = isChecked => {
    this.setState({ isSelf: isChecked });
  };

  handleResidentFormSwitchChange = isChecked => {
    const { isSelf } = this.state;

    this.setState({ [isSelf ? 'isSelfResident' : 'isOrganizationResident']: isChecked });
  };

  render() {
    const { isSelf, isSelfResident, isOrganizationResident } = this.state;
    const { taxSystems, initialValues, isContractDetailsFilled, isLoading } = this.props;
    const isResident = isSelf ? isSelfResident : isOrganizationResident;

    if (isLoading) {
      return <Loading />;
    }

    return <div className="contract-details-page">
      <div className={classNames('top', { single: isContractDetailsFilled }, { 'self-contract': isSelf })}>
        {!isContractDetailsFilled &&
          <span className="emptyWarning">{EMPTY_CONTRACT_DETAILS_WARNING}</span>}
        {<Switch
          id="contractDetailsResidentSwitch"
          isChecked={isResident}
          onChange={this.handleResidentFormSwitchChange}
          labelLeft="Нерезидент РФ"
          labelRight="Резидент РФ"
        />}
        <Switch
          id="contractDetailsTypeSwitch"
          isChecked={isSelf}
          onChange={this.handleFormSwitchChange}
          labelLeft={CONTRACT_DETAIL_TYPE.organization}
          labelRight={CONTRACT_DETAIL_TYPE.self}
        />
      </div>
      <div className="contract-details-content">
        {isSelf
          ? <SelfContractDetails
            taxSystems={taxSystems.self}
            onSubmit={this.handleSubmit}
            initialValues={{ ...initialValues.self, ...{ isResident } }}
          />
          : <OrganizationContractDetails
            taxSystems={taxSystems.organization}
            onSubmit={this.handleSubmit}
            initialValues={{ ...initialValues.organization, ...{ isResident } }}
          />}
      </div>
    </div>;
  }

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    taxSystems: PropTypes.object,
    initialValues: PropTypes.object,
    isContractDetailsFilled: PropTypes.bool,
  };

  static defaultProps = {
    taxSystems: {
      organization: [],
      self: [],
    },
    initialValues: {},
    isContractDetailsFilled: true,
  };
}
