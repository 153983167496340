import React from 'react';
import { Form, Field } from 'redux-form';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import Input from '../../base/input';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class MakeArbitrationDecisionModal extends React.PureComponent {
  handleClose = () => {
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleMakeDecision = data => {
    const { onMakeArbitrationDecision, onRef } = this.props;
    onMakeArbitrationDecision(data).then(() => {
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      customerBreakChoice,
      employeeBreakChoice,
      initiatorReason,
      guiltOptions,
      ...props
    } = this.props;
    return <Modal {...props} className="make-arbitration-decision-modal" title="Вынесение решений по спору">
      <p className="warning">
        <ICONS.infoBold />
        <span>
          Предупреждение! Данное действие подтверждает, что у эксперта есть соответствующая аккредитация в области спора дающая ему право делать экспертные заключения по текущему вопросу. В случае необходимости эксперт готов за отдельную плату предоставить для суда, оформленную в соответствии с действующим законодательством РФ, письменную рецензию.
        </span>
      </p>
      <Form onSubmit={handleSubmit(this.handleMakeDecision)}>
        <div className="form-group customer-break-choice">
          <Input
            name="customerBreakChoice"
            label="Позиция Заказчика"
            isDisabled
            value={customerBreakChoice}
          />
        </div>
        <div className="form-group employee-break-choice">
          <Input
            name="employeeBreakChoice"
            label="Позиция Исполнителя"
            isDisabled
            value={employeeBreakChoice}
          />
        </div>
        <div className="form-group initiator-reason">
          <Input
            name="initiatorReason"
            label="Причина инициатора расторжения договора"
            isDisabled
            value={initiatorReason}
          />
        </div>
        <Field
          name="guiltyUserId"
          component={FormGroupField}
          type="select"
          label="Ваше решение по спору"
          placeholder="Выберите вариант"
          data={guiltOptions}
        />
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine || submitting}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Вынести решение
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    onMakeArbitrationDecision: PropTypes.func.isRequired,
    onRef: PropTypes.object,
    customerBreakChoice: PropTypes.string,
    employeeBreakChoice: PropTypes.string,
    initiatorReason: PropTypes.string,
    guiltOptions: PropTypes.array,
    arbitrationId: PropTypes.number,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    customerBreakChoice: '',
    employeeBreakChoice: '',
    initiatorReason: '',
    guiltOptions: [],
    arbitrationId: null,
  };
}
