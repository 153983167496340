import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProfileHeader from '../../../components/profile/edit/header';
import ProfileForm from '../../../components/profile/edit/container';
import Loading from '../../../components/base/loading';

export default class Profile extends PureComponent {
  getChildContext() {
    const { onFileUpload } = this.props;

    return {
      onFileUpload,
    };
  }

  static childContextTypes = {
    onFileUpload: PropTypes.func.isRequired,
  };

  render() {
    const {
      isLoading,
      user,
      userRoles,
      balance,
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
      regions,
      specialties,
      objectTypes,
      saveProfile,
      chiefEngineerExpertiseExperience,
      onChangeIsPublic,
      onConfirmEmail,
    } = this.props;

    const isContractDetailsFilled = !!(selfContractDetails || organizationContractDetails);

    if (isLoading) {
      return <Loading />;
    }

    return <div className="profile-layout">
      <ProfileHeader
        balance={balance}
        isContractDetailsFilled={isContractDetailsFilled}
      />
      <ProfileForm
        user={user}
        userRoles={userRoles}
        regions={regions}
        specialties={specialties}
        objectTypes={objectTypes}
        saveProfile={saveProfile}
        chiefEngineerExpertiseExperience={chiefEngineerExpertiseExperience}
        onChangeIsPublic={onChangeIsPublic}
        onConfirmEmail={onConfirmEmail}
        isContractDetailsFilled={isContractDetailsFilled}
      />
    </div>;
  }

  static propTypes = {
    balance: PropTypes.number.isRequired,
    saveProfile: PropTypes.func.isRequired,
    onChangeIsPublic: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    userRoles: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    chiefEngineerExpertiseExperience: PropTypes.array.isRequired,
    specialties: PropTypes.array.isRequired,
    objectTypes: PropTypes.array.isRequired,
    onConfirmEmail: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    onFileUpload: PropTypes.func.isRequired,
    contractDetails: PropTypes.object,
  };

  static defaultProps = {
    isLoading: false,
    isValidEmail: true,
    contractDetails: {},
  }
}
