import { connect } from 'react-redux';

import { signIn, signUp } from '../../store/actions/session';
import Auth from './index';

const mapDispatchToProps = {
  signIn,
  signUp,
};

const mergeProps = (stateProps, { signIn, ...dispatchProps }, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  signIn(values) {
    return signIn(values, ownProps.location.state?.from);
  },
});

export default connect(null, mapDispatchToProps, mergeProps)(Auth);
