import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { connect } from 'react-redux';
import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';

import Favorites from './index';
import { formatFavorites, getFavoritesQuery } from '../../../graphql/model/favorite';
import withUserAggregationReferencesQuery from '../../../graphql/hoc/userAggregationReferences';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const DELETE_FAVORITES_QUERY = loader('../../../graphql/queries/favorite/delete.graphql');
const ON_FAVORITE_LIST_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/favorite/listSubscription.graphql');
const ON_FAVORITE_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/favorite/countSubscription.graphql');

const withDeleteFavorites = graphql(DELETE_FAVORITES_QUERY, {
  name: 'deleteFavorites',
});

const ORDER_BY = {
  rating: {
    title: 'Рейтинг',
    graphql: { favoriteUser: { userAggregation: { averageRating: { avg: 'asc' } } } },
  },
  objectCount: {
    title: 'Кол-во выполненных работ',
    graphql: { favoriteUser: { objects_aggregate: { count: 'asc' } } },
  },
  experience: {
    title: 'Стаж',
    graphql: { favoriteUser: { createdAt: 'asc' } },
    reverseIcon: true,
    default: true,
  },
};

const mapStateToProps = ({ session: { user: { id } } }) => ({
  userId: id,
});

const mergeProps = withProps(({ userId, isPerformer }) => ({
  where: getFavoritesQuery(userId, isPerformer),
}));

const withGetFavoritesSub = withResubscribe(ON_FAVORITE_LIST_UPDATE_SUBSCRIPTION, {
  name: 'favoritesSub',
  shouldResubscribe: true,
  options: ({ where, order, offset, limit }) => ({
    variables: {
      where,
      offset,
      limit,
      order,
    },
  }),
  props: ({ favoritesSub: { items = [], loading: isLoading }, ownProps: { userAggregation } }, previousResult) => ({
    // we return previous messages if resubscribe was started (has 0 messages on loading moment)
    favorites: isLoading && previousResult?.favorites.length > 0 ? previousResult.favorites : formatFavorites(items, userAggregation),
    isLoading,
  }),
});

const withGetFavoritesCountSub = withResubscribe(ON_FAVORITE_COUNT_UPDATE_SUBSCRIPTION, {
  name: 'favoritesCountSub',
  options: ({ where }) => ({
    variables: {
      where,
    },
  }),
  props: ({ favoritesCountSub: { count, loading: isCountLoading }, ownProps: { isLoading } }) => ({
    isLoading: isLoading && isCountLoading,
    count: count ? count.aggregate.count : 0,
  }),
});

export default compose(
  withDeleteFavorites,
  withUserAggregationReferencesQuery,
  connect(mapStateToProps),
  defaultProps({
    orderBy: ORDER_BY,
    offset: 0,
    limit: 10,
  }),
  mergeProps,
  withStateHandlers(
    ({ limit, orderBy, where }) => ({ step: limit, order: getOrder(orderBy), where }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      search: (_, { where, userId, isPerformer }) => value => ({
        where: value ? getFavoritesQuery(userId, isPerformer, `%${value}%`) : where,
      }),
    },
  ),
  withGetFavoritesSub,
  withGetFavoritesCountSub,
)(Favorites);
