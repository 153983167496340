import findIndex from 'lodash/findIndex';
import mergeWith from 'lodash/mergeWith';
import differenceBy from 'lodash/differenceBy';

export const deepArrayMerge = (array1, array2, key = 'id') => {
  const result = [];
  const newItems = differenceBy(array2, array1, key);

  for (const item1Key in array1) {
    const item1 = array1[item1Key];
    const item2Index = findIndex(array2, { [key]: item1[key] });

    if (item2Index > -1) {
      // assign if exist in second array
      // set to index from second array because of possible sorting
      result[item2Index] = { ...item1, ...array2[item2Index] };
    }
  }

  // add if new item of second array exist
  if (newItems.length > 0) {
    newItems.forEach(item => {
      const index = findIndex(array2, { [key]: item[key] });
      result.splice(index, 0, item);
    });
  }

  return result;
};

export const deepMerge = (object1, object2) => mergeWith(object1, object2, (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return deepArrayMerge(objValue, srcValue);
  }
});
