import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../base/modal';
import Button from '../../base/button';

import './index.scss';

export default class ContractFileWarningModal extends React.PureComponent {
  state = {
    inProgress: false,
  }

  closeModal = () => {
    this.props.onRef.current.toggleModal();
  }

  handleDelete = async () => {
    const { onRef, onDelete } = this.props;

    this.setState({ inProgress: true });
    await onDelete();
    this.setState({ inProgress: false }, onRef.current.toggleModal);
  };

  render() {
    const { isSign, ...props } = this.props;
    const { inProgress } = this.state;

    return <Modal
      className="contract-file-warning-modal"
      title="Мастер удаления"
      {...props}
    >
      <p>Вы действительно хотите удалить {isSign ? 'подпись' : 'файл'}?</p>
      <div className="action-buttons">
        <Button onClick={this.closeModal} isLarge isBorderless isLoading={inProgress}>Отмена</Button>
        <Button onClick={this.handleDelete} isLarge isLoading={inProgress}>Удалить</Button>
      </div>
    </Modal>;
  }

  static propTypes = {
    isSign: PropTypes.bool,
    onRef: PropTypes.object,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    isSign: false,
    onRef: {
      current: {
        toggleModal() {},
      },
    },
    onDelete() {},
  };
}
