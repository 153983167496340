import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import ContractsHeader from '../../../components/finance/contracts/header';
import ContractsList from '../../../components/finance/contracts/list';

export default class FinanceContracts extends PureComponent {
  isSearchApplied = false;
  state = {
    order: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { sort } = this.props;
    const { order } = this.state;

    if (!isEqual(order, prevState.order)) {
      sort(order);
    }
  }

  handleOnLoadMore = () => {
    const { fetchMore, contracts } = this.props;
    fetchMore(contracts.length);
  };

  setOrder = order => {
    this.setState({ order: { ...order } });

    if (order !== this.state.order) {
      this.props.sort(order);
    }
  };

  handleSearch = value => {
    this.props.search(value);
    this.isSearchApplied = !!value;
  };

  render() {
    const { contracts, orderBy, count, isLoading, goToContract, onClickReview } = this.props;

    return <>
      <ContractsHeader
        setOrder={this.setOrder}
        onSearch={this.handleSearch}
        orderBy={orderBy}
      />
      <ContractsList
        items={contracts}
        count={count}
        isLoading={isLoading}
        isSearchApplied={this.isSearchApplied}
        onLoadMore={this.handleOnLoadMore}
        goToContract={goToContract}
        onClickReview={onClickReview}
      />
    </>;
  }

  static propTypes = {
    contracts: PropTypes.array,
    sort: PropTypes.func,
    search: PropTypes.func,
    count: PropTypes.number,
    orderBy: PropTypes.object,
    fetchMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    goToContract: PropTypes.func.isRequired,
    onClickReview: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contracts: [],
    orderBy: {},
    count: 0,
    sort() {},
    search() {},
  }
}
