import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import AddFilesSection from '../../base/addFilesSection';
import { getTaskStoragePath } from '../../../utils/storagePaths';

import './index.scss';

export default class TaskModal extends React.PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (differenceWith(prevProps.users, this.props.users, isEqual).length > 0 &&
      !this.props.users.find(user => user.checked)
    ) {
      this.handleChangeUser();
    }
  }

  handleChangeObject = () => {
    this.props.change('stage', null);

    this.handleChangeStage();
  };

  handleChangeStage = () => {
    this.props.change('projectComponent', null);
  };

  handleChangeUser = () => {
    this.props.change('toUser', null);
  };

  handleClose = () => {
    const { reset, onClose } = this.props;

    onClose();
    reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleCreateTask = async data => {
    const {
      isEdit,
      isAnswer,
      isReassign,
      onCreateTask,
      onEditTask,
      onAnswerTask,
      onReassignTask,
      goToTasks,
    } = this.props;
    let action = onCreateTask;

    if (isEdit) {
      action = onEditTask;
    } else if (isAnswer) {
      action = onAnswerTask;
    } else if (isReassign) {
      action = onReassignTask;
    }

    await action(data);

    if (isReassign) {
      goToTasks();
    }

    this.handleCancel();
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      objects,
      users,
      reassignmentUsers,
      stages,
      projectComponents,
      isMessage,
      isEdit,
      isAnswer,
      isRevision,
      isReassign,
      presetObjectId,
      objectId,
      hasDeadline,
      ...props
    } = this.props;
    const isMessageOnly = isAnswer || isReassign;
    let title = 'Создать задачу';
    let submitButtonTitle = 'Добавить';
    let toUserFieldTitle = 'Кому ставится задача';
    let objectFieldTitle = 'Привязать к объекту';

    if (isEdit) {
      title = isMessage ? 'Редактировать сообщение' : 'Редактировать задачу';
      submitButtonTitle = 'Сохранить';
    } else if (isAnswer) {
      if (isRevision) {
        title = 'Отправить задачу на доработку';
        submitButtonTitle = 'Отправить';
        toUserFieldTitle = 'Исполнитель задачи';
        objectFieldTitle = 'Привязано к объекту';
      } else {
        title = 'Ответ на задачу';
        submitButtonTitle = 'Ответить';
        toUserFieldTitle = 'От кого задача';
        objectFieldTitle = 'Привязано к объекту';
      }
    } else if (isReassign) {
      title = 'Перепоручить задачу';
      submitButtonTitle = 'Перепоручить';
      toUserFieldTitle = 'Поставщик задачи';
      objectFieldTitle = 'Привязано к объекту';
    }

    return <Modal {...props} onClose={this.handleClose} className="create-task-modal" title={title}>
      <Form onSubmit={handleSubmit(this.handleCreateTask)}>
        {isMessageOnly && <Field
          name="name"
          component={FormGroupField}
          type="text"
          label="Наименование"
          isDisabled
        />}
        <Field
          name="object"
          component={FormGroupField}
          type="select"
          label={objectFieldTitle}
          placeholder="Выберите объект"
          data={objects}
          isDisabled={isEdit || isMessageOnly || !!presetObjectId}
          onChange={this.handleChangeObject}
        />
        <Field
          name="code"
          component={FormGroupField}
          type="text"
          label="Шифр объекта"
          isDisabled
        />
        <Field
          name="stage"
          component={FormGroupField}
          type="select"
          label="Указать стадию"
          placeholder="Выберите стадию"
          keepRemoveTag
          data={stages}
          isDisabled={stages.length === 0 || isEdit || isMessageOnly}
          onChange={this.handleChangeStage}
        />
        <Field
          name="projectComponent"
          component={FormGroupField}
          type="select"
          label="Выбрать раздел"
          placeholder="Выберите раздел"
          keepRemoveTag
          data={projectComponents}
          isDisabled={projectComponents.length === 0 || isEdit || isMessageOnly}
        />
        <Field
          name="toUser"
          className={isReassign ? 'short-field' : ''}
          component={FormGroupField}
          type="select"
          label={toUserFieldTitle}
          data={users}
          isDisabled={users.length === 0 || isEdit || isMessageOnly}
        />
        {isReassign && <Field
          name="reassignmentUser"
          component={FormGroupField}
          type="select"
          label="Перепоручить"
          data={reassignmentUsers}
        />}
        {hasDeadline && <Field
          name="deadline"
          className={isReassign ? 'short-field' : ''}
          component={FormGroupField}
          label="Срок окончания задачи"
          type="date"
          minDate={new Date()}
          isMultiline
          isDisabled={isMessageOnly}
        />}
        <Field
          name="message"
          component={FormGroupField}
          type="textarea"
          label="Ввести текст"
          placeholder="Введите текст задачи"
        />
        {!isReassign && <div className="add-file">
          <AddFilesSection
            name="files"
            label="Описание документа"
            buttonText="Прикрепить файл(ы)"
            storagePath={getTaskStoragePath(objectId)}
            isDisabled={!objectId}
          />
        </div>}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine || !objectId}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            {submitButtonTitle}
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onCreateTask: PropTypes.func.isRequired,
    onEditTask: PropTypes.func.isRequired,
    onAnswerTask: PropTypes.func.isRequired,
    onReassignTask: PropTypes.func.isRequired,
    goToTasks: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onRef: PropTypes.object,
    objects: PropTypes.array,
    stages: PropTypes.array,
    projectComponents: PropTypes.array,
    users: PropTypes.array,
    reassignmentUsers: PropTypes.array,
    hasDeadline: PropTypes.bool,
    isMessage: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    isAnswer: PropTypes.bool.isRequired,
    isRevision: PropTypes.bool.isRequired,
    isReassign: PropTypes.bool.isRequired,
    presetObjectId: PropTypes.number,
    objectId: PropTypes.number,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    projectComponents: [],
    stages: [],
    objects: [],
    users: [],
    reassignmentUsers: [],
    presetObjectId: null,
    objectId: null,
    hasDeadline: false,
  };
}
