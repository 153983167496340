import { BANK_BIK_LENGTH } from './validation';

export const CONTRACT_DETAILS_TYPES = {
  self: { name: 'self' },
  organization: { name: 'organization' },
};

export const formatBankBik = bankBik => {
  if (!bankBik) {
    return bankBik;
  }

  return String(bankBik).length < BANK_BIK_LENGTH ? String(bankBik).padStart(BANK_BIK_LENGTH, '0') : bankBik;
};

export const addLeadingZerosToValue = (value, targetLength) =>
  value && String(value).length < targetLength ? String(value).padStart(targetLength, '0') : value;
