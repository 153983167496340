import React from 'react';
import logo from '../../assets/images/footer-logo.svg';

import './index.scss';

export default class Footer extends React.PureComponent {
  render() {
    return <div className="footer-layout">
      <div className="footer-content">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="links">
          <a
            href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Политика обработки персональных данных
          </a>
          <a
            href={process.env.REACT_APP_PUBLIC_OFFER_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Публичная оферта
          </a>
        </div>
        <span className="all-rights-reserved">All rights reserved 2019 © ООО «ПИР-Система»</span>
      </div>
    </div>;
  }
}
