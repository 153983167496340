import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field, getFormSyncErrors } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Button from '../base/button';
import FormGroupField from '../base/formGroup';
import { getSubmitValidation, getValidation } from '../../utils/validation';
import { oauthServices } from '../../pages/auth';

import './index.scss';

class SignUpForm extends Component {
  render() {
    const { handleSubmit, submitting, pristine, isAgreementsAccepted } = this.props;

    return <div className="sign-up-form-wrapper">
      <Form id='signUp' onSubmit={handleSubmit} className="sign-up-form">
        <Field
          name="email"
          component={FormGroupField}
          type="text"
          label="Укажите ваш e-mail"
        />
        <Field
          name="password"
          component={FormGroupField}
          type="password"
          label="Введите пароль"
        />
        <Field
          name="repeatPassword"
          component={FormGroupField}
          type="password"
          label="Подтвердите пароль"
        />
        <Field
          name="agreements.offer"
          component={FormGroupField}
          type="checkbox"
          label={<>Соглашаюсь с условиями <a
            className="link"
            href={process.env.REACT_APP_PUBLIC_OFFER_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Публичной оферты
          </a></>}
          onTopErrorMessage
        />
        <Field
          name="agreements.personalInformation"
          component={FormGroupField}
          type="checkbox"
          label={<>Даю согласие на обработку своих персональных данных согласно <a
            className="link"
            href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Политике ООО “ГК ПИРС”
          </a></>}
          withoutErrorMessage
        />
        <Field
          name="agreements.isAdult"
          component={FormGroupField}
          type="checkbox"
          label="Подтверждаю, что являюсь совершеннолетним"
          withoutErrorMessage
        />
        <Field
          name="agreements.newsletters"
          component={FormGroupField}
          type="checkbox"
          label="Не возражаю против получения рассылок"
          withoutErrorMessage
        />
        <Button
          className="button"
          isFilled
          isLarge
          isDisabled={submitting || pristine}
          isLoading={submitting}
          buttonProps={{
            type: 'submit',
          }}
        >Зарегистрироваться
        </Button>
        {oauthServices(!isAgreementsAccepted)}
      </Form>
    </div>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool,
    isAgreementsAccepted: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    submitting: false,
  }
}

const FORM_NAME = 'signUp';

const mapStateToProps = state => ({
  isAgreementsAccepted: !getFormSyncErrors(FORM_NAME)(state)?.agreements,
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
    validate: getValidation([
      'email',
      'password',
      'repeatPassword',
      'agreements.offer',
      'agreements.personalInformation',
      'agreements.isAdult',
      'agreements.newsletters',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(SignUpForm);
