import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field } from 'redux-form';
import Button from '../base/button';
import { getSubmitValidation, getValidation } from '../../utils/validation';
import FormGroupField from '../base/formGroup';

import './index.scss';

class RecoverPasswordForm extends Component {
  render() {
    const { handleSubmit, submitting, pristine } = this.props;

    return <div className="recover-password-form-wrapper">
      <div className="recover-password-form-container">
        <h6 className="recover-head">Восстановление пароля</h6>
        <Form onSubmit={handleSubmit} className="recover-password-form">
          <div className="w-100">
            <Field
              name="email"
              component={FormGroupField}
              type="text"
              label="Ваш e-mail"
            />
          </div>
          <div className="w-50">
            <Button
              isFilled
              isLarge
              isDisabled={pristine || submitting}
              buttonProps={{
                type: 'submit',
              }}
            >Отправить
            </Button>
          </div>
        </Form>
      </div>
    </div>;
  }
}

RecoverPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
};

RecoverPasswordForm.defaultProps = {
  submitting: false,
};

export default reduxForm({
  form: 'recoverPassword',
  validate: getValidation(['email']),
  onSubmitFail: getSubmitValidation,
})(RecoverPasswordForm);
