import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withProps, branch } from 'recompose';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { getObjects } from '../../../../graphql/model/object';
import ProjectComposition from './index';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import { ICONS } from '../../../../utils/icons';
import withObjectStagesReferencesQuery from '../../../../graphql/hoc/objectStagesReferences';
import { ROUTES } from '../../../../utils/routes';
import {
  getObjectProjectComponentQuery,
  OBJECT_PROJECT_COMPONENT_ORDER,
} from '../../../../graphql/model/projectComponent';
import { deepMerge } from '../../../../utils/merge';

const GET_STAGES_QUERY = loader('../../../../graphql/queries/object/getStages.graphql');
const GET_OBJECT_QUERY = loader('../../../../graphql/queries/object/get.graphql');
const ON_OBJECT_UPDATE_SUBSCRIPTION = loader('../../../../graphql/queries/object/projectCompositionSubscription.graphql');
const INSERT_OBJECT_PROJECT_COMPONENT = loader('../../../../graphql/queries/object/insertProjectComponent.graphql');
const UPDATE_OBJECT_PROJECT_COMPONENT = loader('../../../../graphql/queries/object/updateProjectComponent.graphql');
const DELETE_OBJECT_PROJECT_COMPONENT = loader('../../../../graphql/queries/object/deleteProjectComponent.graphql');

const withInsertObjectProjectComponent = graphql(INSERT_OBJECT_PROJECT_COMPONENT, { name: 'insertObjectProjectComponent' });
const withUpdateObjectProjectComponent = graphql(UPDATE_OBJECT_PROJECT_COMPONENT, { name: 'updateObjectProjectComponent' });
const withDeleteObjectProjectComponent = graphql(DELETE_OBJECT_PROJECT_COMPONENT, { name: 'deleteObjectProjectComponent' });

const withGetObject = graphql(GET_OBJECT_QUERY, {
  name: 'getObject',
  skip: ({ pathParams: { id }, userId }) => !id || !userId,
  options: ({ pathParams: { id }, userId }) => ({
    variables: {
      id,
      userId,
      withFiles: true,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ getObject: { object = [], loading, error } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryObject: object,
      queryLoading: loading,
    };
  },
});

const withObjectSubscription = graphql(ON_OBJECT_UPDATE_SUBSCRIPTION, {
  name: 'objectProjectCompositionSub',
  skip: ({ pathParams: { id }, userId }) => !id || !userId,
  options: ({ pathParams: { id }, userId }) => ({
    variables: {
      id,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
      projectComponentWhere: getObjectProjectComponentQuery(userId),
    },
  }),
  props: ({ objectProjectCompositionSub: { object = [], loading, error }, ownProps: { userId, queryObject, queryLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      object: queryObject[0] && getObjects([deepMerge(queryObject[0], object[0])], userId)[0] || {},
      isLoading: queryLoading || loading,
    };
  },
});

const withGetStages = graphql(GET_STAGES_QUERY, {
  name: 'getStages',
  options: () => ({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ getStages: { stages, loading }, ownProps: { object: { typeId } } }) => ({
    objectMenuItems: (stages || [])
      .filter(stage => stage.objectTypeId === typeId)
      .map(stage => ({ ...stage, projectComponentId: stage.id })),
    isStagesLoading: loading,
  }),
});

const getError = error => {
  showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
  throw error;
};

const getResult = result => {
  if (result.errors) {
    throw result.errors;
  }
};

const mergeProps = withProps(({
  insertObjectProjectComponent,
  updateObjectProjectComponent,
  deleteObjectProjectComponent,
  objectMenuItems = [],
  chiefEngineerStages,
  userId,
  pathParams,
  object,
}) => {
  const { stages: objectStages, userId: objectUserId } = object;
  const isOwner = objectUserId === userId;
  const templateStages = isOwner
    ? objectMenuItems
    : (chiefEngineerStages || []).map(({ id }) => objectMenuItems.find(item => item.id === id));
  let currentStage = {};
  let selectedTemplateStageId = parseInt(pathParams.stageId, 10);

  if (objectStages && templateStages.length > 0) {
    if (selectedTemplateStageId) {
      const currentTemplateStage = templateStages.find(stage => stage.id === selectedTemplateStageId);
      currentStage = objectStages.find(stage => stage.projectComponentId === selectedTemplateStageId) ||
        { childType: currentTemplateStage?.childType };
    } else if (objectStages.length > 0) {
      currentStage = objectStages[0];
      selectedTemplateStageId = currentStage.projectComponentId;
    } else if (templateStages.length > 0) {
      selectedTemplateStageId = templateStages[0].id;
      currentStage.childType = templateStages[0].childType;
    }
  }

  return {
    isOwner,
    templateStages: templateStages
      .map(({ projectComponentId, name, icon, childType, type }) => ({
        id: projectComponentId,
        title: name,
        icon: ICONS[icon],
        childType,
        type,
      })),
    currentStage,
    selectedTemplateStageId,
    insertObjectProjectComponent: item => insertObjectProjectComponent({
      variables: {
        items: [item],
      },
    }).then(getResult).catch(getError),
    updateObjectProjectComponent: (id, item) => updateObjectProjectComponent({
      variables: {
        id,
        item,
      },
    }).then(getResult).catch(getError),
    deleteObjectProjectComponent: id => deleteObjectProjectComponent({
      variables: {
        id,
      },
    }).then(getResult).catch(getError),
  };
});

const mapStateToProps = ({ session: { user: { id } } }, { location: { pathname } }) => ({
  userId: id,
  pathParams: matchPath(pathname, [ROUTES.projectCompositionStage, ROUTES.projectComposition]).params,
});

export default compose(
  connect(mapStateToProps),
  withGetObject,
  withObjectSubscription,
  withInsertObjectProjectComponent,
  withUpdateObjectProjectComponent,
  withDeleteObjectProjectComponent,
  branch(
    ({ isLoading, object }) => !(isLoading && isEmpty(object)),
    branch(
      // Object owner can see all stages, but chief engineer only your own.
      ({ object, userId }) => object.userId === userId,
      withGetStages,
      withObjectStagesReferencesQuery,
    ),
  ),
  mergeProps,
)(ProjectComposition);
