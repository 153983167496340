import { reduxForm } from 'redux-form';
import { compose, withContext, withProps } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

import WithdrawalRequestModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import AuthorizedApi from '../../../api/authorized';
import { getFormattedDate } from '../../../utils/date';
import { prepareToInsertWithdrawal } from '../../../graphql/model/withdrawal';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';

const INSERT_WITHDRAWAL_MUTATION = loader('../../../graphql/queries/withdrawal/insert.graphql');

const FORM_NAME = 'WithdrawalRequestForm';

const withInsertWithdrawalMutation = graphql(INSERT_WITHDRAWAL_MUTATION, {
  name: 'insertWithdrawal',
});

const mapStateToProps = state => ({
  userId: state.session.user.id,
});

const mergeProps = withProps(({ insertWithdrawal, balance }) => ({
  initialValues: {
    // Need for validation
    balance,
    date: getFormattedDate(new Date(), true),
    isDocumentRequired: false,
  },
  onFileUpload: AuthorizedApi.uploadFile,
  onCreateWithdrawal: data => insertWithdrawal({
    variables: prepareToInsertWithdrawal(data),
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
}));

export default compose(
  withInsertWithdrawalMutation,
  connect(mapStateToProps),
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: getValidation([
      'withdrawalAmount',
      'documentAddress',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(WithdrawalRequestModal);
