import { toast } from 'react-toastify';
import { API_ERROR_MESSAGES as API_ERROR_MESSAGES_MAP, UI_ERROR_MESSAGES } from '../utils/messages';

const API_ERROR_MESSAGES = Object.values(API_ERROR_MESSAGES_MAP);

export const showError = ({ message }, defaultMessage) => {
  if (message && API_ERROR_MESSAGES.indexOf(message) > -1) {
    toast.error(UI_ERROR_MESSAGES[message]);
  } else if (defaultMessage) {
    toast.error(defaultMessage);
  }
};

export const checkNetworkError = error => {
  if (error.status !== 504) {
    return false;
  }

  showError(error);
  return true;
};
