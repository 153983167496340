import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

import Loading from '../loading';
import Container from '../container';
import DataGroup from '../dataGroup';
import { SCREEN } from '../../../utils/screen';
import WindowInnerWidthContext from '../../../contexts/windowInnerWidth';

import './index.scss';

export default class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      roleId: '',
      isSearchInputActive: false,
    };
  }

  renderBody = () => {
    const { isLoading, items, gridProps, onClickItem, noItemsMessage } = this.props;
    let body = <tr>
      <td className="empty" colSpan={gridProps.length}>
        {noItemsMessage}
      </td>
    </tr>;

    if (isLoading) {
      body = <tr>
        <td className="spinner" colSpan={gridProps.length}>
          <Loading />
        </td>
      </tr>;
    } else if (items.length > 0) {
      body = items.map((item, rowIndex) => (
        <tr onClick={() => onClickItem(item)} key={rowIndex}>
          {gridProps.map((gridProp, colIndex) => {
            const { key, render, className = '', withTooltip } = gridProp;
            let value = isNil(item[key]) ? '' : item[key];

            if (render) {
              value = render(value, item);
            }

            return <td key={colIndex} className={className} id={key}>
              {withTooltip ? <DataGroup>{value}</DataGroup> : value}
            </td>;
          })}
        </tr>
      ));
    }

    return body;
  };

  renderGrid = () => {
    const { gridProps, isBorderless, footer, enableRowHover } = this.props;

    return <Table bordered={!isBorderless} hover={enableRowHover}>
      <thead>
        <tr>
          {gridProps.map(({ className, columnName, onlyCellClass }, index) =>
            <th key={index} className={onlyCellClass ? '' : className}>{columnName}</th>)}
        </tr>
      </thead>
      <tbody>
        {this.renderBody()}
        {footer}
      </tbody>
    </Table>;
  };

  renderContainerHeader = (item, props) => (
    <div className="container-header-item">
      {props.map((gridProp, colIndex) => {
        const { key, render, className = '', withTooltip } = gridProp;
        let value = isNil(item[key]) ? '' : item[key];
        if (render) {
          value = render(value, item);
        }
        return <div className={classNames('column', className)} key={colIndex}>
          {withTooltip ? <DataGroup>{value}</DataGroup> : value}
        </div>;
      })}
    </div>
  );

  renderMobileGrid = () => {
    const { items, gridProps, isLoading, useAlternateMobileContainer, onClickItem, noItemsMessage, mobileFooter, showContainerToggle } = this.props;
    const isSmallDisplay = this.context <= SCREEN.sm;
    const containerProps = gridProps.slice(0, isSmallDisplay ? 1 : 2);

    let grid = <div className="empty">
      <span>{noItemsMessage}</span>
    </div>;

    if (isLoading) {
      grid = <div className="spinner">
        <Loading />
      </div>;
    } else if (items.length > 0) {
      const currentGridProps = useAlternateMobileContainer ? gridProps.slice(containerProps.length) : gridProps;
      let hasAction = false;
      const renderColumn = (item, gridProps = currentGridProps) => gridProps.map((gridProp, colIndex) => {
        const { key, render, className = '', withTooltip, columnName } = gridProp;
        let value = isNil(item[key]) ? '' : item[key];
        let actionButtons;

        if (hasAction && key === 'actions') {
          return null;
        }

        if (render) {
          value = render(value, item);
        }

        if (useAlternateMobileContainer && gridProp.hasAction) {
          hasAction = true;
          const actionProps = gridProps.find(currentProp => currentProp.key === 'actions');

          if (actionProps && actionProps.render) {
            actionButtons = actionProps.render(value, item);
          }
        }

        return (columnName || value) ? <div className={classNames('section', className)} key={colIndex}>
          <span className={classNames({ 'empty-cell': useAlternateMobileContainer && !columnName })}>
            {columnName}{!useAlternateMobileContainer && columnName ? ':' : ''}
          </span>
          <div className={classNames({ 'actions-cell': gridProp.hasAction })}>
            {withTooltip ? <DataGroup>{value}</DataGroup> : value}
            {actionButtons ? <div className="actions">{actionButtons}</div> : ''}
          </div>
        </div> : null;
      });
      grid = items.map((item, rowIndex) => <div onClick={event => onClickItem(item, event)} key={rowIndex}>
        {useAlternateMobileContainer
          ? <Container
            className="mobile-grid-item"
            headerContent={this.renderContainerHeader(item, containerProps)}
            {...(isSmallDisplay && { previewContent: renderColumn(item, [currentGridProps[0]]) })}
            showToggle={showContainerToggle}
          >
            {renderColumn(item)}
          </Container>
          : renderColumn(item)}
      </div>);
    }

    return <>
      {useAlternateMobileContainer && <div className="mobile-container-header">
        {containerProps.map(({ columnName }, index) => (
          <div className="column-name" key={index}>{columnName}</div>
        ))}
      </div>}
      {grid}
      {mobileFooter}
    </>;
  };

  render() {
    const { className, useAlternateMobileContainer } = this.props;
    const isMobile = this.context <= SCREEN.md;

    return <div className={classNames('grid', { mobile: isMobile }, { 'alternate-mobile-container': useAlternateMobileContainer }, className)}>
      {isMobile
        ? this.renderMobileGrid()
        : this.renderGrid()}
    </div>;
  }

  static contextType = WindowInnerWidthContext

  static propTypes = {
    items: PropTypes.array.isRequired,
    gridProps: PropTypes.array.isRequired,
    footer: PropTypes.node,
    mobileFooter: PropTypes.node,
    isLoading: PropTypes.bool,
    onClickItem: PropTypes.func,
    isBorderless: PropTypes.bool,
    useAlternateMobileContainer: PropTypes.bool,
    enableRowHover: PropTypes.bool,
    showContainerToggle: PropTypes.bool,
    className: PropTypes.string,
    noItemsMessage: PropTypes.string,
  }

  static defaultProps = {
    items: [],
    gridProps: [],
    footer: null,
    mobileFooter: null,
    isLoading: false,
    onClickItem() {},
    isBorderless: false,
    useAlternateMobileContainer: false,
    enableRowHover: true,
    showContainerToggle: true,
    className: '',
    noItemsMessage: 'Нет записей',
  }
}
