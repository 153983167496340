import difference from 'lodash/difference';

export const getGraphqlFiles = files =>
  files.map(({ remotePath, originalName, size, mimeType, title }) => ({
    remotePath,
    originalName,
    size,
    mimeType,
    title,
  }));

export const getGraphqlFilesToDelete = (initialUserFiles, files) => initialUserFiles
  ? difference(initialUserFiles, files)
  : [];

export const getObjectFiles = (files, userId) => (files || []).reduce((result, { id, type, file, user }) => {
  const resultFile = {
    ...file,
    user,
    id: id || file.id,
    fileId: file.id,
    isLinkOwner: user?.id === userId,
  };

  if (result[type]) {
    result[type].push(resultFile);
  } else {
    result[type] = [resultFile];
  }

  return result;
}, {});
