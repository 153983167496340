import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../base/button';
import FileUploader from '../../../../../base/fileUploader';
import Input from '../../../../../base/input';
import Confirm2OptionsModal from '../../../../../modals/confirm/2options';
import ProfileLink from '../../../../../base/profileLink';
import FileLink from '../../../../../base/fileLink';
import Hint from '../../../../../base/hint';
import { getRemoveFileSchema } from '../../../../../modals/confirm/2options/schemas';
import { formatFileSize, DEFAULT_MAX_FILE_SIZE_MB } from '../../../../../../utils/file';
import { getFormattedDate } from '../../../../../../utils/date';
import { getNameWithInitials } from '../../../../../../utils/name';
import { SCREEN } from '../../../../../../utils/screen';
import { ICONS } from '../../../../../../utils/icons';
import Container from '../../../../../base/container';
import Grid from '../../../../../base/grid';
import WindowInnerWidthContext from '../../../../../../contexts/windowInnerWidth';
import { PRIVATE_ACCOUNT } from '../../../../../../utils/validationMessages';
import BundleLink from '../../../../../bundleLink';
import { CONTRACT_PHASES } from '../../../../../../utils/phase';

import './index.scss';

const isFileExist = file => file && file.remotePath;

function EditableField({ onRef, defaultValue, isEditable }) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = event => {
    setValue(event.target.value);
  };

  return isEditable
    ? <Input
      ref={onRef}
      type="text"
      onChange={handleChange}
      value={value}
    />
    : <div>{defaultValue}</div>;
}

EditableField.propTypes = {
  onRef: PropTypes.object,
  defaultValue: PropTypes.string,
  isEditable: PropTypes.bool,
};

EditableField.defaultProps = {
  onRef: {
    current: null,
  },
  defaultValue: '',
  isEditable: false,
};

// TODO: refactor adaptive design according to remarks
export default class ProjectComponentFiles extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addedFiles: [],
      areFilesAdding: false,
      entityId: props.entityId,
    };

    this.editableFieldRefs = props.files.reduce((result, file) => {
      result[file.fileId] = React.createRef();
      return result;
    }, {});

    this.containerRef = React.createRef();
  }

  static getDerivedStateFromProps({ entityId }, { entityId: prevUniqueId }) {
    if (entityId !== prevUniqueId) {
      return {
        addedFiles: [],
        areFilesAdding: false,
        entityId,
      };
    }

    return null;
  }

  componentDidUpdate({ files: prevFiles, entityId: prevPropsUniqueId }, { entityId: prevStateUniqueId }) {
    const { files, entityId } = this.props;
    const { entityId: stateUniqueId } = this.props;
    if (prevFiles.length < files.length) {
      files.forEach(file => {
        if (!this.editableFieldRefs[file.fileId]) {
          this.editableFieldRefs[file.fileId] = React.createRef();
        }
      });
    }

    if (entityId !== prevPropsUniqueId && stateUniqueId !== prevStateUniqueId) {
      this.containerRef.current?.closeContainer();
    }
  }

  gridProps = [
    {
      key: 'createdAt',
      columnName: 'Дата загрузки',
      className: 'date',
      render: createdAt => getFormattedDate(createdAt),
      withTooltip: true,
    },
    {
      key: 'title',
      columnName: 'Наименование',
      className: 'title',
    },
    {
      key: 'originalName',
      columnName: 'Файл',
      className: 'file',
      render: (originalName, file) => <FileLink
        file={file}
        showRemoveButton={this.props.showDeleteButton}
        {...({
          onRemoveFile: file.isLinkOwner ? this.toggleWarning : null,
          onCopyFile: this.props.isCustomerOrChief && this.props.onClickCopyVersionFiles ? this.props.onClickCopyVersionFiles : null,
        })}
      />,
    },
    {
      key: 'size',
      columnName: 'Размер',
      className: 'size',
      render: size => formatFileSize(size),
      withTooltip: true,
    },
    {
      key: 'user',
      columnName: 'Кто загрузил',
      className: 'owner',
      render: (user, file) => user
        ? <ProfileLink text={getNameWithInitials(user)} userId={file.userId} />
        : PRIVATE_ACCOUNT,
      withTooltip: true,
    },
  ];

  getVersionGridProps = () => {
    const { isLastVersion, showDeleteButton, onClickCopyVersionFiles } = this.props;
    const isSmallDisplay = this.context <= SCREEN.sm;

    return [
      {
        key: 'createdAt',
        columnName: 'Время загрузки',
        className: 'date',
        render: createdAt => getFormattedDate(createdAt, true),
      },
      {
        key: 'originalName',
        columnName: 'Описание документа',
        className: 'file',
        render: (originalName, file) => <>
          <EditableField
            onRef={this.editableFieldRefs[file.fileId]}
            defaultValue={file.title || originalName}
            isEditable={file.isEditable}
          />
          {!isSmallDisplay && <>
            <FileLink title="Ссылка на скачивание" file={file} />
            {!isLastVersion && !file.alreadyInLastVersion && <Button
              IconComponent={ICONS.copy}
              isBorderless
              onClick={() => this.handleCopyFile(file)}
            >
              Скопировать файл в актуальную версию
            </Button>}
          </>}
        </>,
      },
      {
        key: 'size',
        columnName: 'Размер',
        className: 'size',
        render: size => formatFileSize(size),
        hasAction: this.context > SCREEN.sm,
        withTooltip: true,
      },
      ...(isSmallDisplay ? [{
        key: 'fileLink',
        columnName: 'Ссылка на скачивание',
        className: 'file',
        render: (_, file) => <FileLink file={file} />,
      }] : []),
      ...(!showDeleteButton && isSmallDisplay ? [{
        key: 'actions',
        className: 'actions',
        render: (_, file) => !isLastVersion && !file.alreadyInLastVersion && <Button
          IconComponent={ICONS.copy}
          isBorderless
          onClick={() => this.handleCopyFile(file)}
        />,
      }] : []),
      ...([{
        key: 'actions',
        className: 'actions',
        render: (_, file) => <>
          {showDeleteButton && <>
            {file.isEditable
              ? <>
                <Button
                  onClick={() => this.handleUpdateFile(file.fileId)}
                  IconComponent={ICONS.success}
                  isBorderless
                />
                <Button
                  onClick={() => this.toggleEdit(file.id)}
                  IconComponent={ICONS.failed}
                  isBorderless
                />
              </>
              : <>
                <Button
                  onClick={() => this.toggleEdit(file.id)}
                  IconComponent={ICONS.pencil}
                  isBorderless
                />
                <Button
                  onClick={() => this.toggleWarning(file)}
                  IconComponent={ICONS.trash}
                  isBorderless
                />
              </>}
            {isSmallDisplay && !isLastVersion && !file.alreadyInLastVersion && <Button
              IconComponent={ICONS.copy}
              isBorderless
              onClick={() => this.handleCopyFile(file)}
            />}
          </>}
          {onClickCopyVersionFiles && <>
            <Button
              IconComponent={ICONS.copy}
              onClick={() => onClickCopyVersionFiles(file.fileId)}
              tooltip={<div className="hint-tooltip">
                <div className="hint-tooltip-title">
                  Скопировать файл в исходные данные другого раздела или объекта
                </div>
                Позволяет предоставить доступ к файлу исполнителям смежных разделов. После появления новой версии раздела ранее скопированные файлы не обновляются.
              </div>}
              isBorderless
            />
          </>}
        </>,
      }]),
    ];
  };

  removingFile = null;

  removeFileWarningModalRef = React.createRef();

  toggleWarning = (file = null) => {
    this.removingFile = file;
    this.removeFileWarningModalRef.current?.toggleModal();
  };

  toggleEdit = fileId => {
    this.props.files.forEach(file => {
      if (file.id === fileId) {
        file.isEditable = !file.isEditable;
      }
    });
    this.forceUpdate();
  };

  handleRemoveFile = () => {
    this.props.deleteFile(this.removingFile.id);
    this.toggleWarning();
  };

  handleCopyFile = file => this.props.insertFiles([file.fileId], this.props.type);

  handleSaveFiles = async () => {
    const { uploadFiles, insertFiles, type, versionId } = this.props;
    const { addedFiles } = this.state;

    this.setState({
      areFilesAdding: true,
    });

    const result = await uploadFiles(addedFiles.filter(file => file.remotePath));

    await insertFiles(result.data.insert_file.returning.map(file => file.id), type, versionId);

    this.setState({
      addedFiles: [],
      areFilesAdding: false,
    });
  };

  handleUpdateFile = fileId => this.props.updateFile(fileId, { title: this.editableFieldRefs[fileId].current.props.value });

  handleChangeFile = (file, index) => {
    const { addedFiles } = this.state;

    if (file) {
      addedFiles[index] = {
        ...addedFiles[index],
        ...file,
      };
    } else {
      addedFiles.splice(index, 1);
    }

    this.setState({
      addedFiles: [...addedFiles],
    });
  };

  handleUploadFiles = files => {
    const { addedFiles } = this.state;

    if (files.length > 1) {
      files.forEach(file => {
        addedFiles.push(file);
      });
    } else {
      addedFiles.push(files);
    }

    this.setState({
      addedFiles: [...addedFiles],
    });
  };

  handleChangeFileTitle = (event, index) => {
    const { addedFiles } = this.state;

    addedFiles[index].title = event.target.value;

    this.setState({
      addedFiles: [...addedFiles],
    });
  };

  renderFileAddingSection = () => {
    const { addedFiles } = this.state;
    const { maxFileSize, storagePath, acceptedFileTypes } = this.props;

    return <div className="files-list">
      {addedFiles.map((file, index) => (
        <React.Fragment key={index}>
          <div className="form-group-field file-title form-group">
            <Input
              name="projectComponentFile"
              type="text"
              label="Файл"
              placeholder="Введите описание документа"
              onChange={value => this.handleChangeFileTitle(value, index)}
            />
          </div>
          <FileUploader
            className="file-uploader"
            storagePath={storagePath}
            maxFileSize={maxFileSize}
            input={{ value: file || {} }}
            onChange={value => this.handleChangeFile(value, index)}
            acceptedFileTypes={acceptedFileTypes}
          />
        </React.Fragment>
      ))}
    </div>;
  };

  renderHeader = () => {
    const { title, subTitle } = this.props;

    return <>
      {title && <h5>
        {title.placeholder
          ? <>
            {title.placeholder}
            <Hint title={title.tooltipHeader}>{title.hint}</Hint>
          </>
          : title}
      </h5>}
      {subTitle && <h6>{subTitle}</h6>}
    </>;
  };

  renderAdditionalButtons = () => {
    const {
      onClickDataRequest,
      isPerformer,
      dataRequests,
      entityId,
      type,
      objectId,
      hasDownloadBundleLink,
      title: { downloadBundleButton },
      currentPhase,
    } = this.props;
    const activeDataRequest = dataRequests.find(({ isActive }) => isActive);
    const isPhase0 = currentPhase?.key === CONTRACT_PHASES.phase0;

    return <div className="additional-buttons">
      {!isPhase0 && <>
        {activeDataRequest
          ? <span>{`Запрос документации отправлен ${getFormattedDate(activeDataRequest?.createdAt)}`}</span>
          : isPerformer && onClickDataRequest && <Button
            id="addDataRequestButton"
            onClick={onClickDataRequest}
            IconComponent={ICONS.infoBold}
          >
            Запросить недостающие данные
          </Button>}
      </>}
      {hasDownloadBundleLink && <BundleLink
        linkTitle={downloadBundleButton}
        linkParams={[
          { objectId },
          { projectComponentId: entityId },
          { fileType: type },
        ]}
      />}
    </div>;
  };

  renderFooter = () => {
    const { showAddButton, footerContent, maxFileSize, storagePath, acceptedFileTypes, isDisabled, isReadonly, type } = this.props;
    const { addedFiles, areFilesAdding } = this.state;
    const lastFile = addedFiles[addedFiles.length - 1];
    const areFilesToSaveExist = isFileExist(lastFile) || isFileExist(addedFiles[addedFiles.length - 2]);

    return <>
      {showAddButton && <>
        {this.renderFileAddingSection()}
        <div className="action-buttons">
          <>
            <FileUploader
              name={type}
              className="file-uploader"
              storagePath={storagePath}
              maxFileSize={maxFileSize}
              input={{ value: {} }}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              acceptedFileTypes={acceptedFileTypes}
              multiple
              buttonText="Добавить файл(ы)"
              onChange={value => this.handleUploadFiles(value)}
            />
            {areFilesToSaveExist && <Button
              className="btn-option save-button"
              onClick={this.handleSaveFiles}
              buttonProps={{
                type: 'button',
              }}
              isLoading={areFilesAdding}
            >
              Сохранить файл(ы)
            </Button>}
          </>
          {this.renderAdditionalButtons()}
        </div>
      </>}
      {footerContent}
    </>;
  };

  renderGrid = () => {
    const { files, showAddButton, showDeleteButton, footerContent, isVersionGridProps } = this.props;
    const isSmallDisplay = this.context <= SCREEN.sm;
    let gridProps = isVersionGridProps ? this.getVersionGridProps() : this.gridProps;

    if (isSmallDisplay) {
      gridProps = [...gridProps];

      if (isVersionGridProps) {
        // for small resolution we have to move the first column to the second position on version grid
        gridProps.splice(1, 0, gridProps.splice(0, 1)[0]);
      } else {
        // for small resolution we have to delete the first column
        gridProps.splice(0, 1);
      }
    }

    return <>
      <Grid
        className={isVersionGridProps ? 'version-grid' : ''}
        gridProps={gridProps}
        items={files}
        {...({
          footer: <tr className="file-grid-footer">
            <td colSpan={gridProps.length}>
              {showAddButton || footerContent
                ? this.renderFooter()
                : <div className="action-buttons">{this.renderAdditionalButtons()}</div>}
            </td>
          </tr>,
          mobileFooter: <div className="file-grid-footer mobile">
            {this.renderFooter()}
          </div>,
        })}
        useAlternateMobileContainer
      />
      {showDeleteButton && <Confirm2OptionsModal
        onRef={this.removeFileWarningModalRef}
        {...getRemoveFileSchema(this.handleRemoveFile, this.toggleWarning)}
      />}
    </>;
  };

  render() {
    const { isContainer, containerProps } = this.props;

    return isContainer
      ? <Container
        onRef={this.containerRef}
        className="project-component-files"
        headerContent={this.renderHeader()}
        isHeaderClickable
        {...containerProps}
      >
        {this.renderGrid()}
      </Container>
      : <div className="project-component-files">
        {this.renderHeader()}
        {this.renderGrid()}
      </div>;
  }

  static contextType = WindowInnerWidthContext;

  static propTypes = {
    entityId: PropTypes.number,
    files: PropTypes.array,
    title: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    subTitle: PropTypes.string,
    storagePath: PropTypes.string.isRequired,
    uploadFiles: PropTypes.func.isRequired,
    insertFiles: PropTypes.func.isRequired,
    updateFile: PropTypes.func,
    deleteFile: PropTypes.func.isRequired,
    maxFileSize: PropTypes.number,
    showAddButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    isContainer: PropTypes.bool,
    isVersionGridProps: PropTypes.bool,
    isLastVersion: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isReadonly: PropTypes.bool,
    footerContent: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    type: PropTypes.string.isRequired,
    containerProps: PropTypes.object,
    acceptedFileTypes: PropTypes.string,
    isCustomerOrChief: PropTypes.bool,
    isPerformer: PropTypes.bool,
    dataRequests: PropTypes.array,
    objectId: PropTypes.number,
    hasDownloadBundleLink: PropTypes.bool,
    versionId: PropTypes.number,
    currentPhase: PropTypes.object,
    onClickCopyVersionFiles: PropTypes.func,
    onClickDataRequest: PropTypes.func,
  };

  static defaultProps = {
    entityId: 0,
    title: '',
    subTitle: '',
    files: [],
    maxFileSize: DEFAULT_MAX_FILE_SIZE_MB,
    showAddButton: true,
    showDeleteButton: true,
    isVersionGridProps: false,
    isLastVersion: false,
    isContainer: false,
    isDisabled: false,
    isReadonly: false,
    footerContent: '',
    containerProps: {},
    acceptedFileTypes: null,
    updateFile() {},
    isCustomerOrChief: false,
    isPerformer: false,
    dataRequests: [],
    objectId: null,
    hasDownloadBundleLink: false,
    versionId: null,
    currentPhase: {},
    onClickCopyVersionFiles: null,
    onClickDataRequest: null,
  };
}
