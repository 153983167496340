import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { TIME_ZONES } from '../../utils/timezones';
import {
  getCurrentTime,
  getCurrentTimezone,
  getCurrentTimezoneNumeric,
  getCurrentTimezoneCode,
} from '../../utils/date';
import Select from '../base/select';

import './index.scss';

class HeaderTime extends PureComponent {
  constructor(props) {
    super(props);
    const timezoneID = getCurrentTimezone();
    this.state = {
      timezoneID,
      time: getCurrentTime(timezoneID),
      isDropDownSelectOpen: false,
    };
    this.setTimezone(timezoneID);
    this.interval = setInterval(this.onInterval, 1000);
    this.dropDownSelectRef = React.createRef();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setTimezone = timezoneId => {
    const timezone = TIME_ZONES.find(({ value }) => {
      return timezoneId === value;
    });
    this.title = timezone.label + ' UTC' + getCurrentTimezoneNumeric(timezoneId);
    const timezoneSymbol = getCurrentTimezoneCode(timezoneId);
    this.timezone = timezoneSymbol.search(/[+-]/) ? timezoneSymbol : 'UTC' + timezoneSymbol;
  };

  onInterval = () => {
    const { timezoneID } = this.state;

    this.setState({ time: getCurrentTime(timezoneID) });
  };

  onChangeTimezone = timezone => {
    if (!timezone) {
      return;
    }
    this.setState({ time: getCurrentTime(timezone), timezoneID: timezone });
    this.setTimezone(timezone);
    this.handleCloseDropDown();
  };

  handleShowDropDown = ({ target }) => {
    const { isDropDownSelectOpen } = this.state;

    if (['search', 'tag-item'].indexOf(target.className) > -1) {
      return;
    }

    this.setState({ isDropDownSelectOpen: !isDropDownSelectOpen });
    this.dropDownSelectRef.current.onTrigger();
  };

  handleCloseDropDown = () => {
    this.setState({ isDropDownSelectOpen: false });
  };

  render() {
    const { time, isDropDownSelectOpen } = this.state;

    return <div
      className={classNames('header-time-main', { show: isDropDownSelectOpen })}
      onClick={this.handleShowDropDown}
    >
      <div className="header-time-clock arrow">
        <p className="header-time-text">{time}</p>
        <Clock className="header-time-logo" />
      </div>
      <p className="header-timezone-text">{this.timezone}</p>
      <Select
        className="header-time-select"
        data={TIME_ZONES}
        onChange={this.onChangeTimezone}
        onBlur={this.handleCloseDropDown}
        placeholder="Выберите часовой пояс"
        dropDownSelectRef={this.dropDownSelectRef}
      />
    </div>;
  }
}

export default HeaderTime;
