import React from 'react';
import PropTypes from 'prop-types';

import UserList from '../../user/list';
import RatingItem from './item';

import './index.scss';

export default class RatingsList extends UserList {
  render() {
    return <UserList
      {...this.props}
      className="ratings-list-layout"
      emptyMessage="Список рейтингов пуст"
      itemComponent={RatingItem}
    />;
  }

  static propTypes = {
    items: PropTypes.array,
    count: PropTypes.number,
  };

  static defaultProps = {
    items: [],
    count: 0,
  };
}
