import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, matchPath, withRouter } from 'react-router-dom';

import { MENU_ITEMS, TENDERS_MENU_TITLE, VACANCIES_MENU_TITLE } from '../../utils/menu';
import NotImplementedYet from '../../components/notImplementedYet';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';
import { ROUTES } from '../../utils/routes';
import VacanciesHeader from '../../components/vacancies/header';

class VacanciesMenu extends PureComponent {
  isTenders = !!matchPath(this.props.location.pathname, ROUTES.tenders);
  menu = MENU_ITEMS.mainMenu.find(menuItem => menuItem.title === (this.isTenders ? TENDERS_MENU_TITLE : VACANCIES_MENU_TITLE)).children;

  render() {
    return <>
      <VacanciesHeader menu={this.menu} />
      <Switch>
        {this.menu.map(({ route, name, component: Component = NotImplementedYet }, index) =>
          <WebAnalyticsRoute
            key={index}
            path={route}
            name={name}
            render={props => <Component {...props} isTenders={this.isTenders} />}
          />)}
        <Redirect to={this.isTenders ? ROUTES.openedTenders : ROUTES.openedVacancies} />
      </Switch>
    </>;
  }

  static propTypes = {
    location: PropTypes.object,
  };

  static defaultProps = {
    location: {
      pathname: '',
    },
  }
}

export default withRouter(VacanciesMenu);
