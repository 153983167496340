import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';

export default class CopyFileModal extends React.PureComponent {
  handleClose = () => {
    const { reset, onClose } = this.props;

    reset();
    onClose();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  onSubmit = async data => {
    await this.props.onCopyFile(data);
    this.handleCancel();
  };

  render() {
    const {
      onRef,
      handleSubmit,
      pristine,
      submitting,
      type,
      objects,
      stages,
      projectComponents,
      types,
      selectedObjectId,
      selectedStageId,
      isObjectType,
    } = this.props;

    return <Modal
      onRef={onRef}
      className="copy-version-files-modal"
      title="Копирование файла"
      onClose={this.handleClose}
    >
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="object"
          component={FormGroupField}
          type="select"
          label="Выбор объекта"
          placeholder="Открыть список объектов"
          data={objects}
          keepRemoveTag
        />
        <Field
          name="stage"
          component={FormGroupField}
          type="select"
          label="Выбор стадии"
          placeholder="Открыть список стадий"
          data={stages}
          isDisabled={!selectedObjectId || isObjectType}
          keepRemoveTag
        />
        <Field
          name="projectComponent"
          component={FormGroupField}
          type="select"
          label="Выбор раздела куда скопировать"
          placeholder="Открыть список разделов"
          data={projectComponents}
          isDisabled={!selectedStageId || isObjectType}
          keepRemoveTag
        />
        {!type && <Field
          name="type"
          component={FormGroupField}
          type="select"
          label="Выбор типа копируемого файла"
          placeholder="Открыть список типов"
          data={types}
          isDisabled={types.length === 0}
          keepRemoveTag
        />}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Добавить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    onCopyFile: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    type: PropTypes.string,
    objects: PropTypes.array,
    stages: PropTypes.array,
    projectComponents: PropTypes.array,
    types: PropTypes.array,
    selectedObjectId: PropTypes.number,
    selectedStageId: PropTypes.number,
    isObjectType: PropTypes.bool,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    onClose() {},
    type: null,
    objects: [],
    stages: [],
    projectComponents: [],
    types: [],
    selectedObjectId: null,
    selectedStageId: null,
    isObjectType: false,
  };
}
