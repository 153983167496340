import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TariffPlan from '../../../components/tariff/list';
import Loading from '../../../components/base/loading';
import Button from '../../../components/base/button';
import { goTo } from '../../../utils/menu';
import { ROUTES } from '../../../utils/routes';
import AssignObjectToTariffModal from '../../../components/modals/assignObjectToTariff/container';

import './index.scss';

export default class UserTariffPlans extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tariffPlanId: null,
    };
  }

  unsubscribe = null;
  itemExpandedStates = {};

  assignObjectModalRef = React.createRef();

  componentDidMount() {
    this.unsubscribe = this.props.subscription();
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  setItemState = index => isExpanded => {
    this.itemExpandedStates[index] = isExpanded;
  };

  getItemState = index => !!this.itemExpandedStates[index];

  handleClickAddTariff = () => {
    goTo(ROUTES.tariffs);
  };

  handleOpenAssignObjectModal = tariffPlanId => {
    this.setState({ tariffPlanId });
    this.assignObjectModalRef.current.toggleModal();
  };

  handleCloseAssignObjectModal = () => {
    this.setState({ tariffPlanId: null });
  };

  render() {
    const { tariffPlans, isLoading, onClickObject } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return <div className="user-tariff-plan">
      <div className="user-tariff-plan-main-header">
        <h1>Управление тарифными планами</h1>
        <Button onClick={this.handleClickAddTariff}>Купить новый тариф</Button>
      </div>
      {tariffPlans.length === 0
        ? <p className="empty-list-message">
          Список ваших тарифных планов пуст. Для добавления нажмите &quot;Купить новый тариф&quot;
        </p>
        : tariffPlans.map((tariffPlan, index) => <TariffPlan
          key={index}
          tariffPlan={tariffPlan}
          isExpanded={this.getItemState(index)}
          setItemState={this.setItemState(index)}
          onClickObject={onClickObject}
          onClickAssignObject={this.handleOpenAssignObjectModal}
        />)}
      <AssignObjectToTariffModal
        tariffPlanId={this.state?.tariffPlanId}
        onRef={this.assignObjectModalRef}
        onCloseModal={this.handleCloseAssignObjectModal}
      />
    </div>;
  }

  static propTypes = {
    tariffPlans: PropTypes.array,
    subscription: PropTypes.func,
    onClickObject: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    tariffPlans: [],
    subscription() {},
  }
}
