import { compose, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import VacancyProfile from '../../../../components/vacancies/profile';
import { showError } from '../../../../api/error';
import { denormalizeMoney } from '../../../../utils/money';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import withContractReferencesQuery from '../../../../graphql/hoc/contractReferences';
import { getTaxSystems } from '../../../../graphql/model/taxSystems';
import { getAdmissionTypes } from '../../../../graphql/model/admissionTypes';

const UPDATE_CONTRACT_MUTATION = loader('../../../../graphql/queries/contract/update.graphql');
const UPDATE_OBJECT_PROJECT_COMPONENT = loader('../../../../graphql/queries/object/updateProjectComponent.graphql');

const withUpdateContractMutation = graphql(UPDATE_CONTRACT_MUTATION, { name: 'updateContract' });
const withUpdateObjectProjectComponent = graphql(UPDATE_OBJECT_PROJECT_COMPONENT, { name: 'updateObjectProjectComponent' });

const mergeProps = withProps(({
  taxSystems,
  admissionTypes,
  updateContract,
  updateObjectProjectComponent,
  ...props
}) => {
  const { vacancy } = props;
  const contractId = vacancy.contract.id;
  return {
    ...props,
    taxSystems: getTaxSystems(taxSystems, vacancy.contract.competitionTerms?.taxSystems),
    admissionTypes: getAdmissionTypes(admissionTypes, vacancy.contract.competitionTerms?.admissionTypes),
    onUpdateCompetitionType: value => {
      const competitionTerms = vacancy.contract.competitionTerms;
      return updateContract({
        variables: {
          id: contractId,
          contract: {
            competitionTerms: {
              ...competitionTerms,
              isOpen: value,
            },
          },
        },
      }).catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        throw error;
      });
    },
    onUpdateContractTerms: ({
      isSafeDeal,
      totalAmount,
      paymentTerms: { props, ...paymentTerms },
      competitionTerms,
    }) => updateContract({
      variables: {
        id: contractId,
        contract: {
          isSafeDeal,
          totalAmount: denormalizeMoney(totalAmount),
          paymentTerms,
          competitionTerms,
        },
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }
      if (vacancy.isPerformer) {
        return updateObjectProjectComponent({
          variables: {
            id: vacancy.object.projectComponents[0].id,
            item: {
              isExpertiseRequired: paymentTerms.isExpertiseRequired,
            },
          },
        }).then(result => {
          if (result.errors) {
            throw result.errors;
          }
        });
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  };
});

export default compose(
  withUpdateContractMutation,
  withContractReferencesQuery,
  withUpdateObjectProjectComponent,
  mergeProps,
)(VacancyProfile);
