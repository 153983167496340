import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MessageBox, SystemMessage } from 'react-chat-elements';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import Button from '../base/button';
import Input from '../base/input';
import Loading from '../base/loading';
import { ICONS } from '../../utils/icons';
import { SCROLL_TIME_DELAY } from '../base/infiniteList';
import { getFileLink } from '../../utils/links';
import DefaultPhoto from '../../assets/images/default-photo.svg';

import 'react-chat-elements/dist/main.css';
import './index.scss';

const randomColorGenerator = () => '#' + (Math.random() * 0xfffff * 10 ** 6).toString(16).slice(0, 6);

const HEIGHT_RATIO = 1.2;
const ENTER_KEY_CODE = 13;

export default class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showScrollToBottomButton: false,
      newMessageText: '',
      isShown: false,
      previousScrollHeight: 0,
    };

    props.onRef.current = this;
    this.messageListRef = React.createRef();
    this.colorMapByUser = {};
    this.throttledHandleScroll = () => {};
  }

  componentDidMount() {
    this.isChatMounted = true;
    this.throttledHandleScroll = throttle(this.handleScroll, SCROLL_TIME_DELAY, { leading: false });
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this.isChatMounted = false;
    this.throttledHandleScroll.cancel();
  }

  updateScrollHeight(value) {
    this.setState({ previousScrollHeight: value });
  }

  componentDidUpdate(prevProps) {
    const { messages: newMessages, userId } = this.props;
    const { messages: oldMessages } = prevProps;

    if (oldMessages[oldMessages.length - 1]?.id < newMessages[newMessages.length - 1]?.id) {
      const { scrollTop, scrollHeight, clientHeight } = this.messageListRef.current;
      const lastMessage = newMessages[newMessages.length - 1];

      if (lastMessage && lastMessage.userId === userId ||
        scrollHeight - clientHeight * HEIGHT_RATIO < scrollTop) {
        this.scrollToBottom(oldMessages.length !== 0 ? scrollHeight : null);
      }

      this.updateScrollHeight(scrollHeight);
    }
  }

  handleLoadMore = () => {
    const { fetchMore, messages } = this.props;

    fetchMore(messages.length);
  };

  scrollToBottom = positionX => {
    if (this.messageListRef.current) {
      const messageList = this.messageListRef.current;
      messageList.scrollTo({ top: positionX || messageList.scrollHeight });
    }
  };

  handleScroll = () => {
    if (!this.messageListRef.current) {
      return;
    }

    const { count, messages } = this.props;
    const { showScrollToBottomButton } = this.state;
    const { scrollTop, scrollHeight, clientHeight } = this.messageListRef.current;

    if (showScrollToBottomButton !== (scrollHeight - clientHeight * HEIGHT_RATIO > scrollTop)) {
      this.setState(() => ({ showScrollToBottomButton: !showScrollToBottomButton }));
    }

    if (count > messages.length && scrollTop < clientHeight) {
      this.handleLoadMore();
    }
  };

  toggleChat = forceShowChat => {
    const { isStageChat, onCloseOpcChat, onCloseStageChat } = this.props;
    const { isShown } = this.state;

    if (!forceShowChat) {
      isStageChat ? onCloseStageChat() : onCloseOpcChat();
    }

    if (!this.isChatMounted) {
      return;
    }

    if (forceShowChat ?? !isShown) {
      this.scrollToBottom();
    }

    this.setState({ isShown: forceShowChat ?? !isShown });
  };

  handleSendMessage = () => {
    const { onSendMessage } = this.props;
    const { newMessageText } = this.state;

    if (!newMessageText) {
      return;
    }

    onSendMessage(newMessageText);
    this.setState({ newMessageText: '' });
  };

  handleKeypress = ({ charCode }) => {
    if (charCode === ENTER_KEY_CODE) {
      this.handleSendMessage();
    }
  };

  getNameColor = userId => {
    if (!this.colorMapByUser[userId]) {
      this.colorMapByUser[userId] = randomColorGenerator();
    }

    return this.colorMapByUser[userId];
  };

  handleMessageChange = event => {
    event.persist();
    this.setState(() => ({ newMessageText: event.target.value }));
  };

  render() {
    const { userId, messages, className, title, count, isLoading, id } = this.props;
    const { isShown, newMessageText, showScrollToBottomButton } = this.state;

    const isInputsDisabled = isLoading && messages.length === 0;

    if (!id) return null;

    return <div className={classNames('chat-window', className, { show: isShown })}>
      <div className="chat-header">
        {title && <span className="chat-title">{title}</span>}
        <Button
          className="close"
          IconComponent={ICONS.close}
          isBorderless
          tooltip="Закрыть"
          onClick={() => this.toggleChat(false)}
        />
      </div>
      <div className="message-list-container" ref={this.messageListRef} onScroll={this.throttledHandleScroll}>
        {isLoading && <Loading className={classNames({ 'load-more': messages.length > 0 })} />}
        {messages.length === count && <SystemMessage text="Начало переписки" />}
        {messages.map(message =>
          <MessageBox
            key={message.id}
            {...message}
            {...({
              avatar: message.avatar ? getFileLink(message.avatar) : DefaultPhoto,
              position: message.userId === userId ? 'right' : 'left',
            })}
            titleColor={this.getNameColor(message.userId)}
            dateString={moment(message.date).fromNow()}
          />)}
        {showScrollToBottomButton &&
          <Button
            isFilled
            IconComponent={ICONS.arrowRight}
            onClick={() => this.scrollToBottom()}
            tooltip="К последним сообщениям"
          />}
      </div>
      <div className="chat-footer">
        <Input
          isDisabled={isInputsDisabled}
          onChange={this.handleMessageChange}
          onKeyPress={this.handleKeypress}
          value={newMessageText}
          placeholder="Написать сообщение..."
        />
        <Button isDisabled={isInputsDisabled} onClick={this.handleSendMessage} isFilled>Отправить</Button>
      </div>
    </div>;
  }

  static propTypes = {
    className: PropTypes.string,
    count: PropTypes.number,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isLoading: PropTypes.bool,
    isStageChat: PropTypes.bool,
    messages: PropTypes.array,
    title: PropTypes.string,
    userId: PropTypes.number.isRequired,
    fetchMore: PropTypes.func,
    onCloseOpcChat: PropTypes.func,
    onCloseStageChat: PropTypes.func,
    onRef: PropTypes.object,
    onSendMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    count: null,
    id: null,
    messages: [],
    title: '',
    isStageChat: false,
    isLoading: false,
    fetchMore() {},
    onCloseOpcChat() {},
    onCloseStageChat() {},
    onRef: {
      current: null,
    },
  };
}
