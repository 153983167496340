import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field } from 'redux-form';
import { Link } from 'react-router-dom';

import Button from '../base/button';
import { getSubmitValidation, getValidation } from '../../utils/validation';
import FormGroupField from '../base/formGroup';
import { ROUTES } from '../../utils/routes';

import './index.scss';

class SignInForm extends Component {
  render() {
    const { handleSubmit, submitting, pristine } = this.props;

    return <div className="sign-in-form-wrapper">
      <Form onSubmit={handleSubmit} className="sign-in-form">
        <Field
          name="email"
          component={FormGroupField}
          type="text"
          label="Ваш e-mail"
        />
        <Field
          name="signInPassword"
          component={FormGroupField}
          type="password"
          label="Пароль"
        />
        <Link className="link forgot-password" to={ROUTES.recover}>Я забыл пароль</Link>
        <Button
          className="button"
          isFilled
          isLarge
          isDisabled={submitting || pristine}
          isLoading={submitting}
          buttonProps={{
            type: 'submit',
          }}
        >Войти
        </Button>
      </Form>
    </div>;
  }
}

SignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
};

SignInForm.defaultProps = {
  submitting: false,
};

export default reduxForm({
  form: 'signIn',
  validate: getValidation(['email', 'signInPassword']),
  onSubmitFail: getSubmitValidation,
})(SignInForm);
