import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import classNames from 'classnames';
import { Badge } from 'react-bootstrap';

import MenuItem from '../menuItem';

import './index.scss';

export default class Menu extends PureComponent {
  getStatus = (index, route) => {
    const { location, setActive, items } = this.props;
    const { pathname } = location;
    const { children } = items[index];

    return typeof setActive === 'function'
      ? setActive(index)
      : !!matchPath(pathname, { path: route, exact: true }) ||
        children && children.some(({ route: childRoute }) => !!matchPath(pathname, { path: childRoute, exact: true }));
  };

  handleClick = (index, route, type) => {
    const { onClick, goTo } = this.props;

    return typeof onClick === 'function' ? onClick(index) : goTo(route, type);
  };

  renderMenuItem = ({ route, title, badgeCounter, icon, type, className }, index) => title && <MenuItem
    key={index}
    IconComponent={icon}
    isActive={this.getStatus(index, route)}
    onClick={() => this.handleClick(index, route, type)}
    className={className}
    isBorderless
  >
    {badgeCounter > 0 && <Badge pill variant="danger">{badgeCounter}</Badge>}
    {title}
  </MenuItem>;

  render() {
    const { items, children, isTabMenu, className } = this.props;
    const [firstMenuItem, ...menuItems] = items;

    return <div className={classNames(className, { 'tab-menu': isTabMenu })}>
      {firstMenuItem && this.renderMenuItem(firstMenuItem, 0)}
      {children}
      {menuItems.map((item, index) => this.renderMenuItem(item, index + 1))}
    </div>;
  }

  static propTypes = {
    items: PropTypes.array.isRequired,
    goTo: PropTypes.func,
    location: PropTypes.object,
    children: PropTypes.node,
    isTabMenu: PropTypes.bool,
    className: PropTypes.string,
    setActive: PropTypes.func,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    children: null,
    isTabMenu: false,
    goTo() {},
    location: {},
    className: '',
    setActive: null,
    onClick: null,
  }
}
