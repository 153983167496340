import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OverflowTip from '../overflowTip';

import './index.scss';

const NO_VALUE_PLACEHOLDER = '-';
const getValue = (value, defVal = NO_VALUE_PLACEHOLDER) =>
  value !== undefined && value !== null && value !== '' ? value : defVal;

export default class DataGroup extends PureComponent {
  renderText = () => {
    const { children, color } = this.props;

    return typeof children === 'object'
      ? children
      : <span className={classNames(color, { colored: !!color })}>{getValue(children)}</span>;
  };

  render() {
    const { id, title, className, isLineBreakAllowed, tooltipText, showAlways } = this.props;

    return <div {...(id && { id })} className={classNames('data-group', className)}>
      {title && <span>{title}:</span>}
      {isLineBreakAllowed ? this.renderText() : <div className="tooltip-wrapper">
        <OverflowTip className="data-group-tooltip" tooltipText={tooltipText} showAlways={showAlways}>
          {this.renderText()}
        </OverflowTip>
      </div>}
    </div>;
  }
}

DataGroup.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  isLineBreakAllowed: PropTypes.bool,
  tooltipText: PropTypes.string,
  showAlways: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  color: PropTypes.oneOf(['red', 'orange', 'green', 'gray', 'blue']),
};

DataGroup.defaultProps = {
  id: '',
  title: '',
  className: '',
  children: '',
  isLineBreakAllowed: false,
  color: null,
  tooltipText: '',
  showAlways: false,
};
