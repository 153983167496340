import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../base/modal';
import { Form, Field } from 'redux-form';

import Button from '../../../base/button';
import FormGroupField from '../../../base/formGroup';
import FileUploader from '../../../base/fileUploader';
import { ICONS } from '../../../../utils/icons';
import { getObjectStoragePath } from '../../../../utils/storagePaths';
import { noop } from 'lodash';

import './index.scss';

export default class UploadExpertise extends React.PureComponent {
  handleClose = () => {
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleUploadExpertise = data => {
    const { onUploadExpertise, onRef } = this.props;
    onUploadExpertise(data).then(() => {
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  render () {
    const {
      handleSubmit,
      submitting,
      pristine,
      onRef,
      linksPositive,
      linksNegative,
      objectId,
    } = this.props;

    return <Modal
      onRef={onRef}
      className="upload-expertise-modal"
      title="Загрузка заключения экспертизы"
    >
      <Form onSubmit={handleSubmit(this.handleUploadExpertise)} className="upload-expertise-modal-form">
        <Field
          name="start"
          component={FormGroupField}
          type="date"
          label="Дата вынесения решения"
          maxDate={new Date()}
          isMultiline
        />
        <Field
          name="expertiseFile"
          type="text"
          label="Выберите файл заключения экспертизы"
          component={FileUploader}
          storagePath={getObjectStoragePath(objectId)}
          showInput
        />
        <Field
          name="links.positive"
          component={FormGroupField}
          type="select"
          label="Разделы с положительным заключением"
          data={linksPositive}
          isMultiSelect
        />
        <Field
          name="links.negative"
          component={FormGroupField}
          type="select"
          label="Разделы с отрицательным заключением"
          data={linksNegative}
          isMultiSelect
        />
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine || submitting}
            isLoading={submitting}
            IconComponent={ICONS.plus}
            buttonProps={{
              type: 'submit',
            }}
          >
            Добавить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    objectId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    onUploadExpertise: PropTypes.func.isRequired,
    linksPositive: PropTypes.array,
    linksNegative: PropTypes.array,
  };

  static defaultProps = {
    onRef: React.createRef(),
    linksPositive: [],
    linksNegative: [],
  };
}
