import { handleActions } from 'redux-actions';
import {
  SET_AUTHENTICATED,
  SET_SESSION_LOADING,
  SET_USER,
} from '../actions/session';

const INITIAL_STATE = {
  isAuthenticated: false,
  isLoading: false,
  user: {},
};

const ACTION_HANDLERS = {
  [SET_AUTHENTICATED]: (state, { payload: { isAuthenticated } }) => ({
    ...state,
    isAuthenticated,
  }),
  [SET_SESSION_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
  [SET_USER]: (state, { payload: { user } }) => ({
    ...state,
    user,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
