import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BalanceHeader from '../../../components/finance/balance/header';
import Loading from '../../../components/base/loading';
import BalanceList from '../../../components/finance/balance/list';
import WithdrawalRequestModal from '../../../components/modals/withdrawalRequest/container';
import InfoModal from '../../../components/modals/info';
import { WITHDRAWAL_NOT_POSSIBLE } from '../../../utils/messages';

export default class Balance extends PureComponent {
  withdrawalRequestModalRef = React.createRef();
  withdrawalInfoModalRef = React.createRef();

  handleWithdrawalRequestClick = () => {
    if (this.props.balance > 0) {
      this.withdrawalRequestModalRef.current.toggleModal();
    } else {
      this.withdrawalInfoModalRef.current.toggleModal();
    }
  };

  render() {
    const { transactions, isLoading, balance } = this.props;
    if (isLoading) {
      return <Loading />;
    }

    return <>
      <BalanceHeader onWithdrawalRequestClick={this.handleWithdrawalRequestClick} />
      <BalanceList transactions={transactions} />
      <WithdrawalRequestModal onRef={this.withdrawalRequestModalRef} balance={balance} />
      <InfoModal onRef={this.withdrawalInfoModalRef} message={WITHDRAWAL_NOT_POSSIBLE} />
    </>;
  }

  static propTypes = {
    balance: PropTypes.number.isRequired,
    transactions: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    transactions: [],
  }
}
