import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { ROUTES } from '../../utils/routes';
import { checkAuthentication } from '../../store/actions/session';
import Loading from '../../components/base/loading';
import WebAnalyticsRoute from './webAnalyticsRoute';

class AuthRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  async componentDidMount() {
    const { isAuthenticated, isLoading, checkAuthentication: dispatchCheckAuthentication } = this.props;
    if (isLoading) {
      return;
    }
    if (!isAuthenticated) {
      await dispatchCheckAuthentication();
    }
    this.setState({ isLoaded: true });
  }

  render() {
    const {
      isAuthenticated,
      isLoading,
      location,
      component: Component,
      ...routeProps
    } = this.props;

    if (!this.state.isLoaded || isLoading) {
      return <Loading className="auth-loading" />;
    }

    return (
      <WebAnalyticsRoute
        {...routeProps}
        render={props =>
          isAuthenticated
            ? <Component {...props} />
            : <Redirect to={{ pathname: ROUTES.signIn, state: { from: location } }} />}
      />
    );
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
    component: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
    isAuthenticated: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    checkAuthentication: PropTypes.func.isRequired,
  }

  static defaultProps = {
    component: () => {},
  }
}

const mapStateToProps = ({ session: { isAuthenticated, isLoading } }) => ({
  isAuthenticated,
  isLoading,
});

export default withRouter(connect(mapStateToProps, { checkAuthentication })(AuthRoute));
