import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form, Field } from 'redux-form';
import Button from '../base/button';
import { getSubmitValidation, getValidation } from '../../utils/validation';
import FormGroupField from '../base/formGroup';

import './index.scss';

class SetPasswordForm extends Component {
  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return <div className="change-password-form-wrapper">
      <h6 className="mb-5 text-center font-weight-bold">Установить пароль</h6>

      <div className="change-password-form-container">
        <Form onSubmit={handleSubmit} className="change-password-form">
          <Field
            name="password"
            component={FormGroupField}
            type="password"
            label="Введите пароль"
          />
          <Field
            name="repeatPassword"
            component={FormGroupField}
            type="password"
            label="Подтвердите пароль"
          />
          <Button
            className="button w-100"
            isFilled
            isLarge
            isDisabled={pristine || submitting}
            buttonProps={{
              type: 'submit',
            }}
          >Сохранить
          </Button>
        </Form>
      </div>
    </div>;
  }
}

SetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
};

SetPasswordForm.defaultProps = {
  submitting: false,
};

export default reduxForm({
  form: 'setPassword',
  validate: getValidation(['password', 'repeatPassword']),
  onSubmitFail: getSubmitValidation,
})(SetPasswordForm);
