import React, { PureComponent } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import RatingsHeader from '../../components/rating/header';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';

export default class RatingsMenu extends PureComponent {
  render() {
    const { menu, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    return <>
      <RatingsHeader menu={menu} />
      <Switch>
        {menu.map(({ route, name, render }, index) =>
          <WebAnalyticsRoute key={index} path={route} name={name} render={render} />)}
        <Redirect to={menu[0].route} />
      </Switch>
    </>;
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    menu: PropTypes.array.isRequired,
  }
}
