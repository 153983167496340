import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { compose, withProps } from 'recompose';
import { reduxForm } from 'redux-form';

import { getSubmitValidation, getValidation } from '../../../utils/validation';
import EditVacancyModal from '../editVacancy';

const UPDATE_CONTRACT_MUTATION = loader('../../../graphql/queries/contract/update.graphql');

const withUpdateContractMutation = graphql(UPDATE_CONTRACT_MUTATION, { name: 'updateContract' });

const mergeProps = withProps(({ data, updateContract }) => {
  return {
    initialValues: data,
    isPerformer: data.isPerformer,
    onUpdateContract: ({ applicationDeadline }) => {
      return updateContract({
        variables: {
          id: data.contract.id,
          contract: {
            applicationDeadline,
          },
        },
      }).catch(error => {
        throw error;
      });
    },
  };
});

export default compose(
  withUpdateContractMutation,
  mergeProps,
  reduxForm({
    form: 'editVacancy',
    enableReinitialize: true,
    validate: getValidation(['applicationDeadline']),
    onSubmitFail: getSubmitValidation,
  }),
)(EditVacancyModal);
