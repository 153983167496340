import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import flattenDeep from 'lodash/flattenDeep';
import keyBy from 'lodash/keyBy';

import CreateEngineerVacancyModal from '../../../../components/modals/createVacancy/engineerContainer';
import {
  getFavoriteContractDetails,
  prepareToInsertContract,
  getContractDetailsList,
  getSelectedEmployee,
} from '../../../../graphql/model/employee';
import { getSelectedProjectComponents } from '../../../../graphql/model/projectComponent';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import withVacancyReferencesQuery from '../../../../graphql/hoc/vacancyReferences';
import withFavoriteReferencesQuery from '../../../../graphql/hoc/favoriteReferences';

const INSERT_CONTRACT_MUTATION = loader('../../../../graphql/queries/contract/insert.graphql');

const withInsertContractMutation = graphql(INSERT_CONTRACT_MUTATION, {
  name: 'insertContract',
});

const mergeProps = withProps(({ favoriteEngineers, vacancyTypeIds, ...props }) => {
  const { insertContract, contractDetails, object, stageId, isChiefEngineerOnly } = props;
  if (object && stageId) {
    if (Array.isArray(object.stages)) {
      object.stages = keyBy(object.stages, 'id');
    }

    const customerContractDetails = getContractDetailsList(contractDetails);
    const currentStage = object.stages[stageId];
    const { stageEngineers, deadline, chiefEngineer, name: stageName } = currentStage;
    const exclude = flattenDeep(stageEngineers.filter(({ isBreakPending }) => !isBreakPending)
      .map(({ projectComponentIds }) => projectComponentIds));

    return {
      ...props,
      deadline,
      stageName,
      favoriteEmployees: getFavoriteContractDetails(favoriteEngineers),
      contractDetailTypes: customerContractDetails,
      isChiefEngineerAssigned: !!chiefEngineer,
      projectComponents: getSelectedProjectComponents(currentStage.projectComponents, [], exclude),
      onCreateVacancy: ({
        isChiefEngineer,
        contractDetailType,
        isSafeDeal,
        paymentTerms,
        competitionTerms,
        employee,
        projectComponents,
        totalAmount,
        deadline,
      }) => {
        const { employeeUserId, employeeContractDetails } = getSelectedEmployee(employee, favoriteEngineers);
        const assignChiefEngineer = isChiefEngineer || isChiefEngineerOnly;
        const projectComponentIds = [];

        if (assignChiefEngineer) {
          projectComponentIds.push(currentStage.id);
        } else if (projectComponents) {
          projectComponents.forEach(projectComponent => {
            projectComponentIds.push(projectComponent.id);
          });
        }

        return insertContract({
          variables: {
            contract: prepareToInsertContract({
              employeeUserId,
              employeeContractDetails,
              vacancyTypeId: assignChiefEngineer ? vacancyTypeIds.chief : vacancyTypeIds.lead,
              customerContractDetails: contractDetails[0],
              contractDetailType,
              isSafeDeal,
              paymentTerms,
              competitionTerms,
              totalAmount,
            }),
            projectComponentIds,
            deadline,
            isPerformer: false,
          },
        }).catch(error => {
          showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
          throw error;
        });
      },
    };
  }
});

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

export default compose(
  connect(mapStateToProps),
  withVacancyReferencesQuery,
  withFavoriteReferencesQuery,
  withInsertContractMutation,
  mergeProps,
)(CreateEngineerVacancyModal);
