import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import Header from './index';
import { setSideBarVisibility } from '../../store/actions/sideBar';
import { showError } from '../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import { getNotifications } from '../../graphql/model/notifications';
import { withResubscribe } from '../../graphql/utils';

const ON_HEADER_UPDATE_USER_SUBSCRIPTION = loader('../../graphql/queries/header/userSubscription.graphql');
const ON_HEADER_UPDATE_NOTIFICATION_SUBSCRIPTION = loader('../../graphql/queries/header/notificationSubscription.graphql');
const UPDATE_NOTIFICATION = loader('../../graphql/queries/header/updateNotification.graphql');

const withUpdateNotification = graphql(UPDATE_NOTIFICATION, { name: 'updateNotification' });

const mapStateToProps = ({ session: { user = {}, isAuthenticated } }) => ({
  userId: user.id,
  isAuthenticated,
});

const withNotificationsSubscription = graphql(ON_HEADER_UPDATE_NOTIFICATION_SUBSCRIPTION, {
  name: 'notificationsSub',
  skip: ({ userId, isAuthenticated }) => !userId || !isAuthenticated,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ notificationsSub: { notifications, error, loading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      isLoading: loading,
      notifications: getNotifications(notifications),
    };
  },
});

const withProfileSubscription = withResubscribe(ON_HEADER_UPDATE_USER_SUBSCRIPTION, {
  name: 'headerProfileSubscription',
  skip: ({ userId, isAuthenticated }) => !userId || !isAuthenticated,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
  }),
  props: ({ headerProfileSubscription: { user = [{}], error, loading }, ownProps: { isLoading: isNotificationsLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      isLoading: loading || isNotificationsLoading,
      user: user[0],
    };
  },
});

const mergeProps = withProps(({ updateNotification }) => ({
  updateNotification: (id, notification) => updateNotification({
    variables: {
      id,
      notification,
    },
  }).then(result => {
    if (result.errors) {
      throw result.errors;
    }
  }).catch(error => {
    throw error;
  }),
}));

const mapDispatchToProps = dispatch => ({
  openSideBarMenu: () => dispatch(setSideBarVisibility(true)),
});

export default compose(
  withRouter,
  withUpdateNotification,
  mergeProps,
  connect(mapStateToProps, mapDispatchToProps),
  withProfileSubscription,
  withNotificationsSubscription,
)(Header);
