import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split, ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { toast } from 'react-toastify';

import { removeAuthCookie } from '../utils/session';
import { ROUTES } from '../utils/routes';

import { CHECK_YOUR_INTERNET_CONNECTION } from '../utils/messages';

const {
  REACT_APP_HASURA_API_HOST,
  REACT_APP_HASURA_WS_HOST,
  REACT_APP_HASURA_API_PATH,
} = process.env;

const UNAUTHORIZED_AUTH_HOOK_EXT_CODE = ['access-denied'];

const authLink = new ApolloLink((operation, forward) => forward(operation));

const httpLink = new HttpLink({
  uri: REACT_APP_HASURA_API_HOST + REACT_APP_HASURA_API_PATH,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: `${REACT_APP_HASURA_WS_HOST}${REACT_APP_HASURA_API_PATH}`,
  options: {
    reconnect: true,
    lazy: true,
  },
});

export const subscriptionClient = wsLink.subscriptionClient;

const errorLink = onError(error => {
  const { networkError, response, operation, forward } = error;
  if (
    response?.errors?.length === 1 &&
    UNAUTHORIZED_AUTH_HOOK_EXT_CODE.includes(
      response.errors[0].extensions?.code,
    )
  ) {
    removeAuthCookie();
    window.sessionStorage.setItem('sessionExpired', 'true');
    window.location = ROUTES.signIn;
    return;
  }
  if (networkError) {
    toast.error(CHECK_YOUR_INTERNET_CONNECTION);
    return;
  }

  return forward(operation);
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = authLink.concat(
  split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  ),
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, link]),
  connectToDevTools: process.env.NODE_ENV === 'development',
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

export default client;
