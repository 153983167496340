import { reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import ContractReviewModal from './index';
import { getFormattedDate } from '../../../utils/date';
import { getFullName } from '../../../utils/name';
import { normalizeEvaluation } from '../../../graphql/model/rating';

const FORM_NAME = 'ReviewContract';

const INSERT_CONTRACT_EVALUATION_MUTATION = loader('../../../graphql/queries/contract/insertEvaluation.graphql');

const withInsertContractEvaluationMutation = graphql(INSERT_CONTRACT_EVALUATION_MUTATION, {
  name: 'insertContractEvaluation',
});

const mergeProps = withProps(({ insertContractEvaluation, contract }) => ({
  initialValues: {
    subject: contract.subject,
    contractor: getFullName(contract.contractor || {}),
    totalAmount: contract.totalAmount,
    deadline: getFormattedDate(contract.deadline),
    isRecommended: true,
    evaluation: 0.5,
  },
  onCreateReview: ({ review, evaluation, isRecommended }) => insertContractEvaluation({
    variables: {
      evaluation: [{
        contractId: contract.id,
        review,
        // Normalize to integer
        evaluation: normalizeEvaluation(evaluation),
        isRecommended,
      }],
    },
  }),
}));

export default compose(
  withInsertContractEvaluationMutation,
  mergeProps,
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  }),
)(ContractReviewModal);
