import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import FormGroupField from '../../../base/formGroup';

import './index.scss';

export default class SelfBankDetails extends React.PureComponent {
  render() {
    return <div className="form-card self-bank-details-layout">
      <h5>Банковские реквизиты</h5>
      <Field
        name="bankAccount"
        component={FormGroupField}
        type="text"
        label="Расчетный (лицевой) счет"
        mask="99999999999999999999"
      />
      <Field
        name="bankBik"
        component={FormGroupField}
        type="text"
        label="БИК"
        mask="999999999"
      />
      <Field
        name="bankName"
        component={FormGroupField}
        type="text"
        label="Наименование банка"
      />
      <Field
        name="bankPurposeOfPayment"
        component={FormGroupField}
        type="text"
        label="Назначение платежа (если банк получателя выставляет такие требования)"
      />
      <Field
        name="taxSystemId"
        component={FormGroupField}
        type="select"
        data={this.props.taxSystems}
        label="Система налогообложения"
        placeholder="Выберите систему налогообложения"
        keepRemoveTag
      />
    </div>;
  }
}

SelfBankDetails.propTypes = {
  taxSystems: PropTypes.array.isRequired,
};
