export const getProjectComponentName = (name, code) => `${name}${code ? ` (${code})` : ''}`;

export const formatProjectComponentsForSelect = (projectComponents, selectedProjectComponentId) => (projectComponents || [])
  .map(({ id, generatedCode: { level, order, processedName, processedCode }, createdAt }) => ({
    label: getProjectComponentName(processedName, processedCode),
    value: id,
    checked: selectedProjectComponentId === id,
    level,
    order,
    createdAt,
  }));
