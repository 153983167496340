import React from 'react';
import PropTypes from 'prop-types';

import InfiniteList from '../../base/infiniteList';
import VacancyItem from './itemContainer';

import './index.scss';

export default class VacanciesList extends React.PureComponent {
  render() {
    const { isPerformer } = this.props;

    return <InfiniteList
      className="vacancies-list-layout"
      emptyMessage={`Список ${isPerformer ? 'торгов' : 'вакансий'} пуст`}
      itemComponent={VacancyItem}
      {...this.props}
    />;
  }

  static propTypes = {
    items: PropTypes.array,
    isPerformer: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
    isPerformer: false,
  };
}
