import { compose, withProps } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { connect } from 'react-redux';
import merge from 'lodash/merge';

import VacancyMenu from './index';
import { ROUTES } from '../../../utils/routes';
import {
  MENU_ITEMS,
  TENDER_MENU_NAME,
  VACANCY_MENU_NAME,
} from '../../../utils/menu';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { formatVacancy, getContractQuery } from '../../../graphql/model/vacancies';

const GET_CONTRACT_QUERY = loader('../../../graphql/queries/contract/get.graphql');
const ON_CONTRACT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contract/itemSubscription.graphql');
const UPDATE_CONTRACT_MUTATION = loader('../../../graphql/queries/contract/update.graphql');

const withUpdateContractMutation = graphql(UPDATE_CONTRACT_MUTATION, { name: 'updateContract' });

const mapStateToProps = ({ session: { user: { id } } }, { location }) => {
  const isTenders = !!matchPath(location.pathname, ROUTES.tenders);
  const vacancyMenuRoute = matchPath(
    location.pathname,
    [
      ROUTES.openedTender,
      ROUTES.myTender,
      ROUTES.involvedTender,
      ROUTES.tenderInvitation,
      ROUTES.openedVacancy,
      ROUTES.myVacancy,
      ROUTES.involvedVacancy,
      ROUTES.vacancyInvitation,
    ],
  );
  const contractId = vacancyMenuRoute?.params.contractId;

  return {
    vacancyMenu: MENU_ITEMS[isTenders ? TENDER_MENU_NAME : VACANCY_MENU_NAME]
      .find(menuItem => menuItem.route === vacancyMenuRoute?.path).children,
    defaultRoute: isTenders ? ROUTES.openedTenderProfile : ROUTES.openedVacancyProfile,
    contractId,
    userId: id,
    isTenders,
  };
};

const withGetContractQuery = graphql(GET_CONTRACT_QUERY, {
  name: 'getContract',
  skip: ({ contractId }) => !contractId,
  options: ({ contractId, userId }) => ({
    variables: {
      where: getContractQuery(contractId, true, userId),
      withBids: true,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getContract: { contract = [], error, loading, variables } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryContract: contract[0],
      queryLoading: loading,
      queryVariables: variables,
    };
  },
});

const withGetContractSub = graphql(ON_CONTRACT_UPDATE_SUBSCRIPTION, {
  name: 'contractSub',
  skip: ({ contractId }) => !contractId,
  options: ({ queryVariables }) => ({
    variables: {
      where: queryVariables.where,
    },
  }),
  props: ({ contractSub: { contract = [{}], error, loading: isLoading }, ownProps: { queryContract, userId, queryLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      vacancy: queryContract && formatVacancy(merge(queryContract, contract[0]), userId) || {},
      isLoading: isLoading || queryLoading,
    };
  },
});

const mergeProps = withProps(({ updateContract, location, contractId }) => ({
  isChatOpen: !!matchPath(location.search, '?chat=open'),
  onChangeCompetitionState: ({ isCompetitionStarted, applicationDeadline }) => updateContract({
    variables: {
      id: contractId,
      contract: {
        isCompetitionStarted,
        applicationDeadline,
      },
    },
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
}));

export default compose(
  withRouter,
  connect(mapStateToProps),
  withGetContractQuery,
  withGetContractSub,
  withUpdateContractMutation,
  mergeProps,
)(VacancyMenu);
