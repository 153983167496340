import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import Container from '../container';
import Grid from '../grid';
import DataGroup from '../dataGroup';
import { getFormattedNumber } from '../../../utils/numbers';
import { SCREEN } from '../../../utils/screen';
import WindowInnerWidthContext from '../../../contexts/windowInnerWidth';

import './index.scss';

export const gridProps = [
  {
    key: 'income',
    columnName: 'Поступления',
    className: 'total-income colored green',
    render: income => getFormattedNumber(income),
  },
  {
    key: 'outcome',
    columnName: 'Списание',
    className: 'total-outcome colored red',
    render: outcome => getFormattedNumber(outcome),
  },
];

export default class TransactionItem extends PureComponent {
  state = {
    isExpanded: false,
  };

  renderHeader = (data, isExpanded) => {
    const isMobile = this.context <= SCREEN.md;

    return <div className="transaction-item-header">
      {isExpanded
        ? <h6>{data.month}</h6>
        : isMobile
          ? this.renderFooter(data, false, true)
          : <Table className="grid">
            <tbody>
              {this.renderFooter(data, false, true)}
            </tbody>
          </Table>}
    </div>;
  };

  renderFooter = ({ month, total: { income, outcome } }, isGrid, isHeader) => {
    const isMobile = this.context <= SCREEN.md;

    return isMobile
      ? <>
        {isHeader && <div className="month"><h6>{month}</h6></div>}
        <Grid
          className="total"
          items={[{ income, outcome }]}
          gridProps={gridProps}
          showContainerToggle={false}
          useAlternateMobileContainer
        />
      </>
      : <tr className="total">
        {isHeader && <td className="month"><h6>{month}</h6></td>}
        <td colSpan={isGrid ? 3 : 1} className="period-total">Итого за период:</td>
        <td className="total-income colored green"><DataGroup>{getFormattedNumber(income)}</DataGroup></td>
        <td className="total-outcome colored red"><DataGroup>{getFormattedNumber(outcome)}</DataGroup></td>
        <td className="empty-column" />
      </tr>;
  };

  handleExpandedState = isExpanded => {
    this.setState({ isExpanded });
    this.props.setItemState(isExpanded);
  };

  render() {
    const {
      onRef,
      data,
      getItemState,
      gridProps,
      onClickItem,
    } = this.props;

    return <div {...(onRef && { ref: onRef })} className="transaction-item-wrapper">
      <Container
        isDefaultExpanded={getItemState()}
        shareExpandedState={this.handleExpandedState}
        className="transaction-item"
        headerContent={this.renderHeader(data, this.state.isExpanded)}
      >
        <Grid
          items={data.items}
          gridProps={gridProps}
          onClickItem={onClickItem}
          footer={this.renderFooter(data, true)}
          useAlternateMobileContainer
        />
      </Container>
    </div>;
  }

  static contextType = WindowInnerWidthContext;

  static propTypes = {
    onRef: PropTypes.object,
    data: PropTypes.object,
    gridProps: PropTypes.array,
    onClickItem: PropTypes.func,
    setItemState: PropTypes.func,
    getItemState: PropTypes.func,
  };

  static defaultProps = {
    onRef: null,
    data: {},
    gridProps: [],
    onClickItem() {},
    setItemState() {},
    getItemState() {},
  };
}
