import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Hint from '../../../../base/hint';
import Button from '../../../../base/button';
import Section from '../../../../base/section';
import FileLink from '../../../../base/fileLink';
import Container from '../../../../base/container';
import DataGroup from '../../../../base/dataGroup';
import { getFormattedDate } from '../../../../../utils/date';
import { ICONS } from '../../../../../utils/icons';
import { SCREEN } from '../../../../../utils/screen';
import WindowInnerWidthContext from '../../../../../contexts/windowInnerWidth';

export default class RegistryResponsesItem extends PureComponent {
  handleClickAdd = () => {
    const { onClickAdd, remark } = this.props;

    return onClickAdd(remark);
  };

  handleClickEdit = () => {
    const { onClickEdit, remark } = this.props;

    return onClickEdit(remark);
  };

  renderAttachments = files => files.length > 0 &&
    <DataGroup title="Прикрепленные файлы" className="message-files">
      {files.map((file, index) => <span key={index}>
        <FileLink file={file.file || file} showTitle />
      </span>)}
    </DataGroup>;

  renderCountRemarks = remark => {
    const { id, remark: remarkMessage, task: { createdAt } } = remark;

    return <>
      <p className="item-group column-count">№{id}</p>
      <div className="item-group column-remarks">
        <p>{remarkMessage}</p>
        <p>Дата выставления: <span>{getFormattedDate(createdAt)}</span></p>
      </div>
    </>;
  };

  renderResponses = remark => {
    const { status: { name, kind }, task: { lastPerformerMessage } } = remark;

    return <>
      <div className="item-group column-responses">
        <div className="response-status">
          <p className={kind}>{name}</p>
          <Hint title="Ответ на замечание">
            В блок “Ответы на замечания” копируется последняя версия ответа исполнителя
          </Hint>
        </div>
        {lastPerformerMessage && <>
          <p>{lastPerformerMessage.message}</p>
          {this.renderAttachments(lastPerformerMessage.files)}
        </>}
      </div>
    </>;
  };

  renderReviewStatus = remark => {
    const { reviews, task: { lastMessage, isClosed } } = remark;
    const { onClickAccept, onClickReject, isPerformer } = this.props;
    const hasNegativeReview = reviews.some(({ status }) => status.kind === 'negative');

    return <>
      <div className="item-group column-review-status">
        {reviews.map(({ id, role, status: { name, kind }, userRole, isMyRole, isPerformer }, index) => <React.Fragment
          key={index}
        >
          <p>{role}:</p>
          {!isClosed && isMyRole && kind === 'neutral' && !isPerformer ? <div className="action-buttons">
            <Button onClick={() => onClickAccept(remark, userRole?.id)} isBorderless>Принять</Button>
            {!hasNegativeReview &&
            <Button onClick={() => onClickReject(remark, id)} isBorderless>Отправить на доработку</Button>}
          </div>
            : <p className={kind}>{name}</p>}
        </React.Fragment>)}
        {isPerformer && !lastMessage.amIOwner && !isClosed && <Button
          onClick={this.handleClickAdd}
          IconComponent={ICONS.circlePlus}
          isBorderless
        >Добавить ответ</Button>}
      </div>
    </>;
  };

  renderHeader = (remark, isMobile) => (
    <div className="review-item-header">
      {this.renderCountRemarks(remark)}
      {!isMobile && <>
        {this.renderResponses(remark)}
        {this.renderReviewStatus(remark)}
      </>}
    </div>
  );

  renderContainerHeader = remark => (
    <div className="review-item-header">
      <Section className="column-count-section">
        {this.renderPreview(remark)}
      </Section>
      <Section>
        <p className="column-responses">Ответы на замечания</p>
        {this.renderResponses(remark)}
      </Section>
      <Section>
        <p className="column-review-status">Статус рассмотрения</p>
        {this.renderReviewStatus(remark)}
      </Section>
    </div>
  );

  renderPreview = ({ id }) => (
    <>
      <p className="column-count-title">№ п/п</p>
      <p className="column-count">№{id}</p>
    </>
  );

  renderMobileChild = remark => {
    const { remark: { task: { messages } } } = this.props;
    const hasMessage = messages.length > 0;

    return <>
      <Container
        className="registry-review-item"
        headerContent={this.renderContainerHeader(remark)}
        isExpansionDisabled={!hasMessage}
        showToggle={hasMessage}
      >
        {this.renderChild()}
      </Container>
    </>;
  };

  renderChild = () => {
    const { remark: { task: { messages, lastMessage, isClosed } }, isPerformer } = this.props;

    return <>
      {messages.map(({ title, message, createdAt, files }, index) => <Section key={index} className="message">
        <div className="empty-block" />
        <div className="item-group column-remarks">
          <p>{title}</p>
          <p>Дата: <span>{getFormattedDate(createdAt)}</span></p>
        </div>
        <div className="item-group column-responses">
          <p>{message}</p>
          {this.renderAttachments(files)}
        </div>
        <div className={classNames('item-group column-review-status', { invisible: messages.length > (index + 1) })}>
          {lastMessage.amIOwner && !isClosed && <Button
            onClick={this.handleClickEdit}
            IconComponent={ICONS.pencil}
            isBorderless
          />}
          {isPerformer && !lastMessage.isPerformer && !isClosed && <Button
            onClick={this.handleClickAdd}
            IconComponent={ICONS.circlePlus}
            isBorderless
          >Добавить ответ</Button>}
        </div>
      </Section>)}
    </>;
  };

  render() {
    const { remark } = this.props;
    const { id, task: { messages } } = remark;
    const hasMessage = messages.length > 0;
    const isMobile = this.context <= SCREEN.md;

    return <div className="registry-review-items-container">
      <Container
        id={id}
        className={classNames('registry-review-items', { 'no-messages': isMobile && !hasMessage })}
        headerContent={this.renderHeader(remark, isMobile)}
        isExpansionDisabled={!isMobile && !hasMessage}
        showToggle={isMobile || hasMessage}
        previewContent={this.renderPreview(remark)}
      >
        {isMobile ? this.renderMobileChild(remark) : this.renderChild()}
      </Container>
    </div>;
  }

  static contextType = WindowInnerWidthContext;

  static propTypes = {
    remark: PropTypes.object.isRequired,
    isPerformer: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickAccept: PropTypes.func.isRequired,
    onClickReject: PropTypes.func.isRequired,
  };
}
