import { formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { getSubmitValidation, getValidation } from '../../../utils/validation';
import ChangeProjectCompositionModal from './index';

const FORM_NAME = 'ChangeProjectCompositionModal';
const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, { projectComponents, existingProjectComponentIds = [], initialValues = {} }) => ({
  selectedProjectComponentId: formSelector(state, 'projectComponentId'),
  filteredProjectComponents: (projectComponents || [])
    .filter(({ value }) => existingProjectComponentIds.indexOf(value) === -1 || initialValues.projectComponentId === value)
    .map(projectComponent => ({
      ...projectComponent,
      checked: initialValues.projectComponentId === projectComponent.value,
    })),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: getValidation(['deadline'], ['projectComponentId', 'customName']),
    onSubmitFail: getSubmitValidation,
  }),
)(ChangeProjectCompositionModal);
