import React from 'react';
import FormGroupField from '../../../base/formGroup';
import { Field } from 'redux-form';

import './index.scss';

export default class SelfIdentification extends React.PureComponent {
  render() {
    return <div className="form-card self-identification-layout">
      <h5>Идентификационные данные</h5>
      <Field
        name="address"
        component={FormGroupField}
        type="text"
        label="Адрес для официальной переписки"
      />
      <Field
        name="individualInn"
        component={FormGroupField}
        type="text"
        label="ИНН"
        mask="999999999999"
        className="half-field"
      />
      <Field
        name="insuranceAccount"
        component={FormGroupField}
        type="text"
        label="СНИЛС"
        mask="999-999-999 99"
      />
      <Field
        name="passport"
        component={FormGroupField}
        type="text"
        label="Паспорт № серия"
      />
      <Field
        name="passportIssuer"
        component={FormGroupField}
        type="text"
        label="Кем выдан"
      />
      <Field
        name="passportDate"
        component={FormGroupField}
        type="date"
        label="Когда выдан"
        maxDate={new Date()}
        isMultiline
      />
    </div>;
  }
}
