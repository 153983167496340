import { reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import moment from 'moment';

import EditObjectModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import withObjectReferencesQuery from '../../../graphql/hoc/objectReferences';

const UPDATE_OBJECT_MUTATION = loader('../../../graphql/queries/object/update.graphql');

const withUpdateObjectMutation = graphql(UPDATE_OBJECT_MUTATION, {
  name: 'updateObject',
});

const mergeProps = withProps(({ data, updateObject }) => ({
  initialValues: data?.stages
    ? Object.values(data.stages).reduce((result, { id, deadline }) => Object.assign(result, {
      [`stageDeadline-${id}`]: deadline,
    }), data)
    : {},
  stageMinDeadline: data?.stages
    ? Object.values(data.stages).reduce((result, { id, projectComponents }) => Object.assign(result, {
      [id]: moment.max(
        moment.min(projectComponents.map(({ deadline }) => moment(deadline))),
        moment(),
      ).toDate(),
    }), {})
    : {},
  updateObject: ({ id: objectId, name, code, region, address, ...restFields }) => {
    const stages = Object.values(data.stages);
    return updateObject({
      variables: {
        id: objectId,
        object: {
          name,
          code,
          ...(typeof region === 'number' && { regionId: region }),
          address,
        },
        stages: stages.map(({ id, projectComponentId, type: { id: projectComponentTypeId } }) => ({
          objectId,
          projectComponentId,
          projectComponentTypeId,
          deadline: restFields[`stageDeadline-${id}`],
        })),
      },
    }).catch(error => {
      throw error;
    });
  },
}));

export default compose(
  withObjectReferencesQuery,
  withUpdateObjectMutation,
  mergeProps,
  reduxForm({
    form: 'editObject',
    enableReinitialize: true,
    validate: getValidation(['name', 'region', 'address', 'code', 'deadline']),
    onSubmitFail: getSubmitValidation,
  }),
)(EditObjectModal);
