import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import TaskListHeader from '../../../components/tasks/listHeader';
import TaskList from '../../../components/tasks/list';

export default class Tasks extends PureComponent {
  isSearchApplied = false;
  state = {
    order: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { sort } = this.props;
    const { order } = this.state;

    if (!isEqual(order, prevState.order)) {
      sort(order);
    }
  }

  handleOnLoadMore = () => {
    const { fetchMore, tasks } = this.props;
    fetchMore(tasks.length);
  };

  setOrder = order => {
    this.setState({ order: { ...order } });

    if (order !== this.state.order) {
      this.props.sort(order);
    }
  };

  handleSearch = value => {
    this.props.search(value);
    this.isSearchApplied = !!value;
  };

  render() {
    const {
      menu,
      tasks,
      orderBy,
      count,
      isLoading,
      isIncoming,
      onClickReadTaskMessage,
      onClickTaskAction,
      onClickCloseTaskModal,
      onClickRemoveTaskModal,
      onClickCopyTaskFile,
    } = this.props;

    return <>
      <TaskListHeader
        menu={menu}
        setOrder={this.setOrder}
        onSearch={this.handleSearch}
        orderBy={orderBy}
      />
      <TaskList
        items={tasks}
        isIncoming={isIncoming}
        count={count}
        isLoading={isLoading}
        isSearchApplied={this.isSearchApplied}
        onLoadMore={this.handleOnLoadMore}
        onClickReadTaskMessage={onClickReadTaskMessage}
        onClickTaskAction={onClickTaskAction}
        onClickCloseTaskModal={onClickCloseTaskModal}
        onClickRemoveTaskModal={onClickRemoveTaskModal}
        onClickCopyTaskFile={onClickCopyTaskFile}
      />
    </>;
  }

  static propTypes = {
    tasks: PropTypes.array,
    menu: PropTypes.array.isRequired,
    sort: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    count: PropTypes.number,
    orderBy: PropTypes.object,
    isLoading: PropTypes.bool,
    isIncoming: PropTypes.bool.isRequired,
    onClickReadTaskMessage: PropTypes.func.isRequired,
    onClickTaskAction: PropTypes.func.isRequired,
    onClickCloseTaskModal: PropTypes.func.isRequired,
    onClickRemoveTaskModal: PropTypes.func.isRequired,
    onClickCopyTaskFile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tasks: [],
    isAuthor: false,
    orderBy: {},
    count: 0,
    isLoading: true,
  }
}
