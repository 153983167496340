import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withProps, withContext } from 'recompose';

import CreateObjectModal from '../../components/modals/createObject/container';
import {
  prepareToInsertObject,
  prepareToInsertObjectStages,
} from '../../graphql/model/object';
import {
  getFavoriteContractDetails,
  getContractDetailsList,
} from '../../graphql/model/employee';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import { showError } from '../../api/error';
import AuthorizedApi from '../../api/authorized';
import withObjectReferencesQuery from '../../graphql/hoc/objectReferences';
import withVacancyReferencesQuery from '../../graphql/hoc/vacancyReferences';
import withFavoriteReferencesQuery from '../../graphql/hoc/favoriteReferences';
import { getTariffPlans } from '../../graphql/model/tariff';

const INSERT_OBJECT_MUTATION = loader('../../graphql/queries/object/insert.graphql');
const INSERT_OBJECT_PROJECT_COMPONENT = loader('../../graphql/queries/object/insertProjectComponent.graphql');

const withInsertObjectMutation = graphql(INSERT_OBJECT_MUTATION, { name: 'insertObject' });
const withInsertObjectProjectComponent = graphql(INSERT_OBJECT_PROJECT_COMPONENT, { name: 'insertObjectProjectComponent' });

const mergeProps = withProps(({ favoriteEngineers, userTariffPlans, selectedTariffPlanId, ...props }) => {
  const { insertObject, insertObjectProjectComponent, contractDetails, objectTypes, vacancyTypeIds } = props;
  const customerContractDetails = getContractDetailsList(contractDetails);

  return {
    ...props,
    userTariffPlans: getTariffPlans(userTariffPlans, selectedTariffPlanId),
    initialValues: {
      ...(selectedTariffPlanId && { tariffPlan: selectedTariffPlanId }),
    },
    onFileUpload: AuthorizedApi.uploadFile,
    favoriteEngineers: getFavoriteContractDetails(favoriteEngineers),
    contractDetailTypes: customerContractDetails.map(item => ({
      ...item,
      checked: item.length === 1,
    })),
    onCreateObject: data => insertObject({
      variables: {
        object: prepareToInsertObject(data),
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }

      const object = result.data.insert_object.returning[0];
      return insertObjectProjectComponent({
        variables: {
          items: prepareToInsertObjectStages(
            data,
            object.id,
            objectTypes,
            favoriteEngineers,
            contractDetails[0],
            vacancyTypeIds,
          ),
        },
      });
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  };
});

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

export default compose(
  withObjectReferencesQuery,
  connect(mapStateToProps),
  withVacancyReferencesQuery,
  withFavoriteReferencesQuery,
  withInsertObjectMutation,
  withInsertObjectProjectComponent,
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
)(CreateObjectModal);
