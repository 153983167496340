import React from 'react';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

import FormGroupField from '../../base/formGroup';
import FileUploader from '../../base/fileUploader';
import { STORAGE_PATHS } from '../../../utils/storagePaths';
import { DEFAULT_MAX_FILE_SIZE_MB } from '../../../utils/file';

import './index.scss';

export default class AddFilesSection extends React.PureComponent {
  handleUploadFiles = (value, fields) => {
    if (value.length > 1) {
      value.forEach(item => {
        fields.push({ file: item });
      });
    } else {
      fields.push({ file: value });
    }
  };

  renderFields = ({ fields }) => {
    const { storagePath, label, placeholder, buttonText, maxFileSize, acceptedFileTypes, isDisabled, name } = this.props;

    return <>
      {fields.map((fileGroup, index) => (
        <React.Fragment key={index}>
          <Field
            name={`${fileGroup}.file.title`}
            placeholder={placeholder}
            component={FormGroupField}
            type="text"
            label={label}
            className="file-title"
          />
          <Field
            name={`${fileGroup}.file`}
            component={FileUploader}
            type="text"
            className="file-uploader"
            storagePath={storagePath}
            maxFileSize={maxFileSize}
            acceptedFileTypes={acceptedFileTypes}
          />
        </React.Fragment>
      ))}
      <FileUploader
        name={name}
        className="file-uploader add-button"
        storagePath={storagePath}
        input={{ value: {} }}
        isDisabled={isDisabled}
        buttonText={buttonText || 'Добавить файл(ы)'}
        multiple
        onChange={value => this.handleUploadFiles(value, fields)}
      />
    </>;
  };

  render() {
    const { name } = this.props;

    return <FieldArray
      name={name}
      component={this.renderFields}
      rerenderOnEveryChange
    />;
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    storagePath: PropTypes.string,
    maxFileSize: PropTypes.number,
    acceptedFileTypes: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    placeholder: 'Введите наименование файла',
    storagePath: STORAGE_PATHS.organizationContractProfile,
    maxFileSize: DEFAULT_MAX_FILE_SIZE_MB,
    acceptedFileTypes: null,
    isDisabled: false,
  }
}
