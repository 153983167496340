import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../base/button';
import Menu from '../../base/menu';
import { getWordEnding } from '../../../utils/text';
import { getFormattedNumber } from '../../../utils/numbers';

import './index.scss';

const TARIFF_PLANS_DURATION = {
  month: 1,
  halfYear: 6,
  year: 12,
};

const TARIFF_PLAN_MENU_ITEMS = [
  {
    title: 'Тарифы на полгода',
  },
  {
    title: 'Тарифы на год',
  },
];

const getPaidPlan = isPrepaid => isPrepaid ? 'предоплата' : 'постоплата';

export default class TariffPlansList extends React.PureComponent {
  state = {
    selectedMenuItem: 0,
  };

  handleMenuClick = index => {
    this.setState({ selectedMenuItem: index });
  };

  renderHeader = () => <div className="tariff-plans-header">
    <h1>Тарифные планы</h1>
    <hr />
    <h6>Перечисленные лицензии открывают функционал Заказчика: Создавать объекты, проводить по ним конкурсы вакансий и
      подбор исполнителей разделов</h6>
    <ul>
      <li>Тарифные планы отличаются между собой количеством одновременно активных объектов.</li>
      <li>Пользователь может приобрести несколько “Тарифных планов” и распределить их между объектами в личном
        кабинете по свему усмотрению.</li>
      <li>Неиспользованный период “Тарифного плана” можно использовать для создания нового объекта, если
        предварительно отключить от него завершенный объект.</li>
      <li>Если тарифный план не продлевается по истечению его срока, доступ к прикрепленным к нему объектам
        прекращается, а загруженные по этим объектам файлы удаляются.</li>
    </ul>
  </div>;

  renderMonthlyTariffPlan = monthlyTariffPlan => {
    if (!monthlyTariffPlan) {
      return null;
    }

    const { showTariffPlanModal } = this.props;
    const { name, cost, objectCount } = monthlyTariffPlan;

    return <div className="monthly-tariff-plan-item">
      <div className="title">
        <h5>{name}</h5>
        <p>1-ый месяц Демо-доступ без оплаты. Оплата начинается со 2-го месяца пользования</p>
      </div>
      <div className="cost">
        <p className="price">{getFormattedNumber(cost)} <span>рублей</span></p>
        <p className="object">(за один объект в месяц)</p>
        <p className="description">Себестоимость ведения<br />{objectCount} объекта в год на этом тарифе<br />
          составит {getFormattedNumber(cost * TARIFF_PLANS_DURATION.year)} рублей</p>
      </div>
      <Button isFilled isLarge onClick={() => showTariffPlanModal(monthlyTariffPlan)}>Выбрать</Button>
    </div>;
  };

  renderTariffPlanItems = (monthlyTariffPlan, tariffPlansDuration) => {
    const { tariffPlans, showTariffPlanModal } = this.props;
    const filteredTariffPlans = tariffPlans.filter(({ duration }) => duration === tariffPlansDuration);
    const isYearTariff = tariffPlansDuration === TARIFF_PLANS_DURATION.year;

    return filteredTariffPlans.length === 0
      ? <p className="empty-list-message">Список тарифных планов пуст.</p>
      : <div className="tariff-plans-container">
        {filteredTariffPlans.map((tariffPlan, index) => {
          const { name, isPrepaid, cost, objectCount } = tariffPlan;
          const costObjectPerMonth = cost / objectCount / tariffPlansDuration;
          const costObjectPerYear = costObjectPerMonth * TARIFF_PLANS_DURATION.year;
          const monthlyTariffCostObjectPerYear = monthlyTariffPlan.cost * TARIFF_PLANS_DURATION.year;
          const benefit = monthlyTariffCostObjectPerYear - costObjectPerYear;
          const benefitPercentage = benefit * 100 / monthlyTariffCostObjectPerYear;

          return <div key={index} className="tariff-plan-item">
            <div className="title">
              <h5>{name}</h5>
              <p>({getPaidPlan(isPrepaid)})</p>
            </div>
            <div className="cost">
              <p className="package">Стоимость пакета</p>
              <p className="price">{getFormattedNumber(cost)} <span>рублей</span></p>
              <p className="object">
                (за {objectCount} {getWordEnding('объект', objectCount)} в {isYearTariff ? 'год' : 'полгода'})
              </p>
            </div>
            <p className={classNames('description', { invisible: isYearTariff && index === 0 })}>
              Себестоимость ведения<br />1 объекта в год на этом тарифе
              <br />составит {getFormattedNumber(costObjectPerYear)} рублей</p>
            <p className="benefit">
              Выгода {benefitPercentage}%<br />Стоимость объекта {getFormattedNumber(costObjectPerMonth)} руб/мес
              <br />Выгода с 1-го объекта в течение<br />года: {getFormattedNumber(benefit)} руб
            </p>
            <Button isFilled isLarge onClick={() => showTariffPlanModal(tariffPlan)}>Выбрать</Button>
          </div>;
        })}
      </div>;
  };

  render() {
    const { tariffPlans } = this.props;
    const { selectedMenuItem } = this.state;
    const monthlyTariffPlan = tariffPlans.find(({ duration }) => duration === TARIFF_PLANS_DURATION.month);
    const tariffPlansDuration = selectedMenuItem ? TARIFF_PLANS_DURATION.year : TARIFF_PLANS_DURATION.halfYear;

    return <div className="tariff-plans-layout">
      {this.renderHeader()}
      {this.renderMonthlyTariffPlan(monthlyTariffPlan)}
      <Menu
        className="tariff-plans-menu"
        items={TARIFF_PLAN_MENU_ITEMS}
        onClick={this.handleMenuClick}
        setActive={index => index === selectedMenuItem}
      />
      {this.renderTariffPlanItems(monthlyTariffPlan, tariffPlansDuration)}
    </div>;
  }

  static propTypes = {
    tariffPlans: PropTypes.array.isRequired,
    showTariffPlanModal: PropTypes.func.isRequired,
  };
}
