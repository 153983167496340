import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import maxBy from 'lodash/maxBy';
import classNames from 'classnames';

import Button from '../../base/button';
import MenuItem from '../../base/menuItem';
import { ICONS } from '../../../utils/icons';
import { getFormattedDate } from '../../../utils/date';
import { getContractorName } from '../../../utils/name';
import { OBJECT_MENU_ITEMS, goToObjectMenuItem, goTo, getPath } from '../../../utils/menu';
import { VACANCY_TYPE } from '../../../utils/vacancyType';
import Container from '../../base/container';
import DataGroup from '../../base/dataGroup';
import ProfileLink from '../../base/profileLink';
import Section from '../../base/section';
import ProjectComponentLink from '../../base/projectComponentLink';
import ProcessedCode from '../../base/processedCode';
import { ROUTES } from '../../../utils/routes';
import { getProjectComponentName } from '../../../utils/projectComponent';

import './index.scss';

const ArrowIcon = ICONS.arrowRight;

class Item extends React.PureComponent {
  handleMenuClick = (objectId, stageId) => () => {
    goToObjectMenuItem(objectId, stageId);
  };

  handleClickEdit = () => {
    const { onClickEdit, data } = this.props;

    onClickEdit(data);
  };

  handleClickCreateChiefEngineerVacancy = stageId => {
    const { onClickCreateChiefEngineerVacancy, data } = this.props;
    onClickCreateChiefEngineerVacancy(data, stageId);
  };

  renderChiefEngineer(engineer, ownerUserId, stageId) {
    const { isOwner, data: { isTariffActive } } = this.props;
    if (isTariffActive) {
      if (engineer && engineer.userId) {
        const { userId } = engineer;
        return <DataGroup><ProfileLink text={getContractorName(engineer, true)} userId={userId} /></DataGroup>;
      } else if (engineer && engineer.contractId) {
        const text = engineer.isCompetitionStarted ? 'Запущены конкурсные процедуры' : 'Неопубликованная вакансия';
        return engineer.isCompetitionStarted || isOwner
          ? <Link to={{ pathname: getPath(ROUTES.openedTenderProfile, { contractId: engineer.contractId }) }}>{text}</Link>
          : text;
      } else if (isOwner) {
        return <Button onClick={() => this.handleClickCreateChiefEngineerVacancy(stageId)}>Назначить</Button>;
      } else {
        return <DataGroup><ProfileLink text="Функции ГИПа заказчик оставил за собой" userId={ownerUserId} /></DataGroup>;
      }
    }
  }

  renderObjectMenuItems = object => OBJECT_MENU_ITEMS
    .filter(({ component, isForOwnerOnly, isForOwnerAndChiefOnly }) =>
      !!component &&
      (isForOwnerOnly
        ? this.props.isOwner
        : !isForOwnerAndChiefOnly || this.props.isOwner || this.props.data.isChiefEngineer),
    )
    .map(({ title, icon, route, badgeField }, index) => <MenuItem
      key={index}
      onClick={() => goTo(Array.isArray(route) ? route[0] : route, { id: object.id })}
      IconComponent={icon}
      badge={badgeField && object[badgeField]}
      isBorderless
      isDisabled={!object.isTariffActive}
    >
      {title}
    </MenuItem>);

  renderHeader = ({ name, isTariffActive }) => <div className="object-header">
    <DataGroup title="Наименование объекта" isLineBreakAllowed>{name}</DataGroup>
    {this.props.isOwner && isTariffActive && <Button
      IconComponent={ICONS.pencil}
      onClick={this.handleClickEdit}
      isBorderless
      buttonProps={{
        name: 'editButton',
      }}
    />}
  </div>;

  renderPreview = ({ createdAt, deadline, code, type, ...object }) => {
    const creationDate = getFormattedDate(createdAt);
    const deadlineDate = getFormattedDate(deadline);

    return <div className="object-preview">
      <Section>
        <DataGroup title="Дата создания" id="date">{creationDate}</DataGroup>
        <DataGroup title="Срок сдачи" id="deadline">{deadlineDate}</DataGroup>
        <DataGroup title="Шифр" id="code">{code}</DataGroup>
        <DataGroup title="Тип" id="type">{type}</DataGroup>
      </Section>
      <Section>
        <DataGroup className="object-menu-list" isLineBreakAllowed>{this.renderObjectMenuItems(object)}</DataGroup>
      </Section>
      {!object.isTariffActive && <Section>
        <div className="info">
          <ICONS.infoBold className="info-icon" />
          <p>Объект заблокирован в связи с окончанием тарифного плана</p>
        </div>
      </Section>}
    </div>;
  };

  renderEmployees(projectComponents, objectId, stageId, employeeType, showNotAssigned = false) {
    return projectComponents.map(({ id, flattenedProjectComponents }) =>
      flattenedProjectComponents.map(({ name, processedCode, [employeeType]: employees }, index) => {
        const { isOwner } = this.props;
        const lastEmployee = maxBy(employees, 'vacancyCreatedAt');
        let link = <p>Без исполнителя</p>;
        let isAssigned = false;

        if (lastEmployee) {
          const { userId, isCompetitionStarted, contractId, contractStatus } = lastEmployee;
          isAssigned = true;

          if (userId) {
            link = contractStatus && contractStatus.isArbitration ? <DataGroup color='red'>{contractStatus.name}</DataGroup>
              : <DataGroup><ProfileLink text={getContractorName(lastEmployee)} userId={userId} /></DataGroup>;
          } else {
            const isPerformer = employeeType === 'performers';
            let route;

            if (isOwner) {
              route = isPerformer ? ROUTES.myTenderProfile : ROUTES.myVacancyProfile;
            } else {
              route = isPerformer ? ROUTES.openedTenderProfile : ROUTES.openedVacancyProfile;
            }

            const text = isCompetitionStarted
              ? 'Запущены конкурсные процедуры'
              : isPerformer ? 'Неопубликованный ЛОТ' : 'Неопубликованная вакансия';
            link = (isCompetitionStarted || isOwner) && <DataGroup><Link
              className={classNames('vacancy-link', isCompetitionStarted ? 'link-green' : 'link-red')}
              to={{ pathname: getPath(route, { contractId }) }}
            >{text}</Link></DataGroup>;
          }
        }

        return (isAssigned || showNotAssigned) && <div key={index} className="pair-fields">
          <DataGroup tooltipText={getProjectComponentName(name, processedCode)} showAlways>
            <ProcessedCode isLoading={!processedCode}>
              <ProjectComponentLink
                text={processedCode}
                objectId={objectId}
                stageId={stageId}
                projectComponentId={id}
                isDisabled={!this.props.data.isTariffActive}
              />
            </ProcessedCode>
          </DataGroup>
          {link}
        </div>;
      }),
    );
  }

  renderChild = object => {
    const {
      id: objectId,
      userId,
      createdAt,
      deadline,
      region,
      address,
      type,
      code,
      stages,
      isTariffActive,
    } = object;
    const { isOwner } = this.props;
    const creationDate = getFormattedDate(createdAt);
    const deadlineDate = getFormattedDate(deadline);

    return <>
      <Section>
        <DataGroup title="Дата создания" id="date">{creationDate}</DataGroup>
        <DataGroup title="Срок сдачи" id="deadline">{deadlineDate}</DataGroup>
        <DataGroup title="Шифр" id="code">{code}</DataGroup>
        <DataGroup title="Тип" id="type">{type}</DataGroup>
      </Section>
      <Section>
        <DataGroup className="object-menu-list" isLineBreakAllowed>
          {this.renderObjectMenuItems(object)}
        </DataGroup>
      </Section>
      <Section>
        <DataGroup title="Регион работы" id="region">{region}</DataGroup>
        <DataGroup title="Адрес объекта" id="address">{address}</DataGroup>
        <DataGroup title="Роль в объекте" id="role">{isOwner ? 'Заказчик' : VACANCY_TYPE.performer}</DataGroup>
      </Section>
      {stages && Object.values(stages).map(({ id, name, icon, chiefEngineer, projectComponents }, index) =>
        <Section className="project-component" key={index}>
          <MenuItem
            onClick={this.handleMenuClick(objectId, id)}
            IconComponent={ICONS[icon]}
            isBorderless
            isDisabled={!isTariffActive}
          >
            {name}<ArrowIcon />
          </MenuItem>
          <DataGroup title="Разделы" className="performer-list" isLineBreakAllowed>
            <div className="list-of-pairs">
              {this.renderEmployees(projectComponents, objectId, id, 'performers', true)}
            </div>
          </DataGroup>
          <DataGroup title={VACANCY_TYPE.chief} className="chief-engineer" isLineBreakAllowed>
            {this.renderChiefEngineer(chiefEngineer, userId, id)}
          </DataGroup>
          <DataGroup title="Ведущие инженеры" className="engineer-list" isLineBreakAllowed>
            <div className="list-of-pairs">
              {this.renderEmployees(projectComponents, objectId, id, 'engineers')}
            </div>
          </DataGroup>
        </Section>,
      )}
      {!isTariffActive && <Section>
        <div className="info">
          <ICONS.infoBold className="info-icon" />
          <p>Объект заблокирован в связи с окончанием тарифного плана</p>
        </div>
      </Section>}
    </>;
  };

  render() {
    const { data, style, isExpanded, setItemState } = this.props;

    return <div style={style} className="objects-list-item-container">
      <Container
        className="object-item"
        isDefaultExpanded={isExpanded}
        isExpansionDisabled
        shareExpandedState={setItemState}
        headerContent={this.renderHeader(data)}
        previewContent={this.renderPreview(data)}
      >
        {this.renderChild(data)}
      </Container>
    </div>;
  }

  static propTypes = {
    data: PropTypes.object,
    style: PropTypes.object,
    isExpanded: PropTypes.bool,
    setItemState: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    isOwner: PropTypes.bool,
    onClickCreateChiefEngineerVacancy: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    style: {},
    setItemState() {},
    isExpanded: false,
    isOwner: true,
    onClickCreateChiefEngineerVacancy() {},
  };
}

export default Item;
