import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import maxBy from 'lodash/maxBy';
import classNames from 'classnames';

import Container from '../../../../base/container';
import DatePicker from '../../../../base/datePicker';
import Button from '../../../../base/button';
import Rating from '../../../../base/rating';
import { ICONS } from '../../../../../utils/icons';
import { getContractorName } from '../../../../../utils/name';
import ProjectComponentFiles from './files/container';
import { setProjectComponentType, STAGE_FILE_TYPES } from '../../../../../utils/stage';
import ProfileLink from '../../../../base/profileLink';
import { VACANCY_TYPE } from '../../../../../utils/vacancyType';
import DataGroup from '../../../../base/dataGroup';
import { ROUTES } from '../../../../../utils/routes';
import { getPath, goTo } from '../../../../../utils/menu';
import { getActiveEmployee } from '../../../../../graphql/model/object';
import AddProjectFilesVersionModal from '../../../../modals/addProjectComponentFilesVersion/container';
import RemarkDraftsModal from '../../../../../pages/objects/object/remarks/drafts/container';
import { getContractProfileRoute } from '../../../../../pages/finance/contracts/container';
import { Versions } from './versions';
import { getProjectComponentName } from '../../../../../utils/projectComponent';
import { INFO_MESSAGES } from '../../../../../utils/messages';
import DataRequestModal from '../../../../modals/dataRequest';
import TaskModal from '../../../../modals/task/container';
import { CONTRACT_PHASES } from '../../../../../utils/phase';
import { CONTRACT_STATUSES } from '../../../../../utils/contract';

import './index.scss';

export const checkEmployeeResponsibleForAgreements = (employees, userId) => employees
  .some(({ userId: employeeUserId, paymentTerms, isActive }) =>
    employeeUserId === userId && isActive && (!paymentTerms || paymentTerms.special && !paymentTerms.special.isCustomersCoordinationWithServicesResponsibility));

export const checkEmployeeResponsibleForInitialData = (employees, userId) => employees
  .some(({ userId: employeeUserId, paymentTerms, isActive, isPerformer }) => {
    return isPerformer
      ? employeeUserId === userId && isActive && paymentTerms?.special && !paymentTerms?.special?.isCustomersInitialDataResponsibility
      : employeeUserId === userId && isActive && (!paymentTerms || paymentTerms.special && !paymentTerms.special.isCustomersInitialDataResponsibility);
  });

class ProjectComponentItem extends PureComponent {
  state = {
    isExpanded: this.props.isDefaultExpanded,
    newTask: {},
  };

  containersRefs = {};
  versionsRefs = {};

  addProjectComponentFilesVersionModalRef = React.createRef();
  remarkDraftsModalRef = React.createRef();
  dataRequestModalRef = React.createRef();
  createTaskModalRef = React.createRef();

  componentDidUpdate() {
    const { history: { location } } = this.props;

    const params = new URLSearchParams(location.search);
    const isAddVersionModalOpened = params.get('showAddVersionModal');

    if (isAddVersionModalOpened) {
      this.addProjectComponentFilesVersionModalRef.current.toggleModal();
      params.delete('showAddVersionModal');
      this.props.history.replace(params);
    }
  }

  handleOpenRemarkDraftsModal = () => {
    this.remarkDraftsModalRef.current.toggleModal();
  };

  handleOpenDataRequestModal = () => {
    this.dataRequestModalRef.current.toggleModal();
  };

  handleOpenTaskModal = newTask => {
    this.setState({ newTask }, () => {
      if (this.createTaskModalRef.current) {
        this.createTaskModalRef.current.toggleModal();
      }
    });
  };

  handleCloseTaskModal = () => {
    this.setState({ newTask: {} });
  };

  renderPerformerName = performer => {
    const { projectComponent: { customer: { userId: customerUserId } }, userId: currentUserId } = this.props;
    const isOwner = currentUserId === customerUserId;
    const { userId, contractId, isCompetitionStarted } = performer;
    const text = isCompetitionStarted ? 'Запущены конкурсные процедуры' : 'Неопубликованный ЛОТ';
    const route = isOwner ? ROUTES.myTenderProfile : ROUTES.openedTenderProfile;
    if (userId) {
      return <ProfileLink
        text={getContractorName(performer)}
        userId={userId}
      />;
    } else if (contractId && (isCompetitionStarted || isOwner)) {
      return <Link
        className={classNames('vacancy-link', isCompetitionStarted ? 'link-green' : 'link-red')}
        to={{
          pathname: getPath(route, { contractId }),
        }}
      >
        {text}
      </Link>;
    }
  };

  renderPerformerActions = ({
    contractStatus,
    arbitration,
    userId: performerUserId,
    contractId,
    currentPhase,
    vacancyId,
    isBreakPending,
  }) => {
    const {
      onClickDeleteVacancy,
      onClickCreatePerformerVacancy,
      onClickBreakContract,
      onClickMakeArbitrationDecision,
      userId,
      projectComponent,
      isExpert,
    } = this.props;
    const isCustomer = userId === projectComponent.customer.userId;
    const isPerformer = performerUserId === userId;
    const renderPerformerButton = (buttonCaption, onClick) => <Button
      className="performer-action"
      onClick={onClick}
      buttonProps={{
        type: 'button',
      }}
    >
      {buttonCaption}
    </Button>;
    const renderContractStatusText = (name, kind) => <span
      className={classNames('performer-action colored', { red: kind === 'negative', green: kind === 'positive' })}
    >
      {name}
    </span>;
    let actions = '';

    if (contractId && contractStatus) {
      const { kind, name, isArbitration, inDispute } = contractStatus;
      if (isCustomer || isPerformer) {
        if (contractStatus.isActive) {
          actions = renderPerformerButton(
            'Завершить сотрудничество',
            () => onClickBreakContract(contractId, currentPhase),
          );
        } else if (inDispute && isBreakPending) {
          actions = renderPerformerButton(
            'В процессе расторжения',
            () => onClickBreakContract(contractId, currentPhase),
          );
        } else {
          actions = renderContractStatusText(name, kind);
        }
      } else if (isExpert && isArbitration && !arbitration.guiltyUserId) {
        actions = renderPerformerButton(
          'Вынести решение',
          () => onClickMakeArbitrationDecision(contractId),
        );
      } else {
        actions = renderContractStatusText(name, kind);
      }
    } else if (contractId && !performerUserId && isCustomer) {
      actions = renderPerformerButton(
        'Удалить',
        () => onClickDeleteVacancy({ id: vacancyId, contractId }),
      );
    } else if (isCustomer) {
      actions = renderPerformerButton(
        'Добавить',
        () => onClickCreatePerformerVacancy(projectComponent),
      );
    }

    return actions;
  };

  renderHeader = () => {
    const { projectComponent, onClickRemarks, onClickOpenChat } = this.props;
    const {
      processedName,
      processedCode,
      deadline,
      performers,
      remarks,
      type: {
        dativeName,
      },
    } = projectComponent;

    if (!performers.some(performer => !performer.userId || performer.isActive)) {
      performers.push({});
    }

    const isContractSuspended = performers.some(({ isActive, contractStatus }) => isActive && contractStatus?.name === CONTRACT_STATUSES.suspended.name);

    return <div className="project-component-header">
      <h5>{processedName}</h5>
      <DataGroup title="Шифр" className="code">{processedCode}</DataGroup>
      <div className="project-component-action-row">
        {isContractSuspended
          ? <DataGroup
            title="Срок окончания работ"
            className={classNames('deadline', { suspended: isContractSuspended })}
          >
            {CONTRACT_STATUSES.suspended.name}
          </DataGroup>
          : <DatePicker
            name="deadline"
            value={deadline}
            label="Срок окончания работ"
            isDisabled
          />}
        <Button
          className="project-component-header-action chat"
          IconComponent={ICONS.chat}
          isBorderless
          onClick={() => onClickOpenChat(projectComponent)}
        >
          Чат по {dativeName}
        </Button>
        {performers[0].userId && <Button
          className="project-component-header-action"
          onClick={onClickRemarks}
          badge={remarks.count.total}
          IconComponent={ICONS.infoBold}
          isBorderless
        >
          Замечания по {dativeName}
        </Button>}
      </div>
    </div>;
  };

  handleClickAddProjectComponentFilesVersion = ({ isAllRemarksResponded, isAtLeastOneResponsePositive }) => {
    const {
      projectComponent: { id: projectComponentId, objectId, root: { id: stageId }, level },
      selectedProjectComponentId,
      showInfoModal,
    } = this.props;

    if (!isAllRemarksResponded) {
      showInfoModal(
        INFO_MESSAGES.versionCannotBeCreatedDueToRemarks, {
          title: 'Перейти к замечаниям',
          path: level === 1 ? ROUTES.projectComponentRemarks : ROUTES.projectComponentRemark,
          params: {
            id: objectId,
            stageId,
            projectComponentId: level === 1 ? projectComponentId : selectedProjectComponentId,
            ...(level === 1 ? {} : { remarkId: projectComponentId }),
          },
        },
      );
    } else if (!isAtLeastOneResponsePositive || isAllRemarksResponded) {
      this.addProjectComponentFilesVersionModalRef.current.toggleModal();
    }
  };

  renderPhaseContainer = ({ id, name, key }, performer, isCurrentPerformer, showAddNewVersionButton, isLastPerformer) => {
    const {
      projectComponent,
      userId,
      storageFilesPath,
      onClickTurnInWork,
      onClickAcceptWork,
      onClickCopyVersionFiles,
      selectedProjectComponentId,
    } = this.props;

    if (!projectComponent.isExpertiseRequired && key === CONTRACT_PHASES.phase2 || key === CONTRACT_PHASES.phase4) {
      return;
    }

    const { id: objectProjectComponentId, lastFilesVersion, files } = projectComponent;
    const phaseFiles = files[performer.contractId] && files[performer.contractId][key] || {};
    const containerId = `${objectProjectComponentId}-${performer.contractId}-${id}`;
    const hasPhaseContent = !isEmpty(phaseFiles) || (isCurrentPerformer
      ? showAddNewVersionButton
      : lastFilesVersion.contractId === performer.contractId);
    let isPhaseHasNotStarted = null;
    let phaseStatus = 'Отсутствует исполнитель';
    let isCurrentPhase = false;

    if (performer) {
      const { currentPhase, isActive } = performer;

      phaseStatus = 'Этап не пройден';

      if (!currentPhase || currentPhase.id > id) {
        phaseStatus = 'Этап пройден';
      } else if (currentPhase.id < id) {
        phaseStatus = 'Этап не наступил';
        isPhaseHasNotStarted = true;
      } else if (currentPhase.id === id && isActive) {
        isCurrentPhase = true;
      }
    }

    if (!this.containersRefs[containerId]) {
      this.containersRefs[containerId] = {
        ref: React.createRef(),
        isActivePerformer: performer.isActive,
        isCurrentPhase,
        hasPhaseContent,
      };
    }

    return <Container
      id={containerId}
      ref={this.containersRefs[containerId].ref}
      isDefaultExpanded={isLastPerformer && isCurrentPhase && hasPhaseContent}
      className="phase-container"
      key={id}
      headerContent={<>
        <h5>{name}</h5>
        <span>{phaseStatus}</span>
      </>}
      showToggle={!!performer && !isPhaseHasNotStarted && hasPhaseContent}
    >
      <Versions
        versionsRefs={this.versionsRefs}
        files={phaseFiles}
        performer={performer}
        isCurrentPerformer={isCurrentPerformer}
        isCurrentPhase={isCurrentPhase}
        phaseId={performer?.currentPhase?.id}
        hasPhases
        projectComponent={projectComponent}
        selectedProjectComponentId={selectedProjectComponentId}
        userId={userId}
        storageFilesPath={storageFilesPath}
        showAddNewVersionButton={isCurrentPhase && showAddNewVersionButton}
        onClickTurnInWork={onClickTurnInWork}
        onClickAcceptWork={onClickAcceptWork}
        onClickCopyVersionFiles={onClickCopyVersionFiles}
        onOpenRemarksModal={this.handleOpenRemarkDraftsModal}
        onClickAddProjectComponentFilesVersion={this.handleClickAddProjectComponentFilesVersion}
      />
    </Container>;
  };

  renderVersions = performer => {
    const {
      projectComponent,
      userId,
      storageFilesPath,
      onClickTurnInWork,
      onClickAcceptWork,
      onClickCopyVersionFiles,
      selectedProjectComponentId,
    } = this.props;
    const { currentPhase, paymentTerms, contractId } = performer;
    const showPhases = currentPhase || paymentTerms;
    let isCurrentPerformer = false;

    if (performer) {
      isCurrentPerformer = performer.userId === userId && performer.isActive;
    }

    const showAddNewVersionButton = isCurrentPerformer &&
      (!performer.currentPhase || performer.currentPhase.key === CONTRACT_PHASES.phase1 ||
        projectComponent.flattenedRemarks.some(({ task, phaseId }) =>
          phaseId === currentPhase.id && !task.isClosed && !task.messages.includes(({ userId }) => userId === performer.userId)));
    const isLastPerformer = maxBy(projectComponent.performers, performer => performer.contractId)?.userId === performer.userId;

    return showPhases
      ? this.context.phases.map(phase =>
        this.renderPhaseContainer(phase, performer, isCurrentPerformer, showAddNewVersionButton, isLastPerformer))
      : <Versions
        versionsRefs={this.versionsRefs}
        performer={performer}
        isCurrentPerformer={isCurrentPerformer}
        files={projectComponent.files[contractId]}
        projectComponent={projectComponent}
        selectedProjectComponentId={selectedProjectComponentId}
        userId={userId}
        storageFilesPath={storageFilesPath}
        showAddNewVersionButton={showAddNewVersionButton}
        onClickTurnInWork={onClickTurnInWork}
        onClickAcceptWork={onClickAcceptWork}
        onClickCopyVersionFiles={onClickCopyVersionFiles}
        onOpenRemarksModal={this.handleOpenRemarkDraftsModal}
        onClickAddProjectComponentFilesVersion={this.handleClickAddProjectComponentFilesVersion}
      />;
  };

  handleToggle = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });

    if (isExpanded) {
      forEach(this.versionsRefs, ({ isLastVersion }, key) => {
        if (isLastVersion) {
          this.versionsRefs[key].childRefs.forEach(ref => {
            ref.current?.handleExpandToggle(true);
          });
          this.versionsRefs[key].ref.current?.handleExpandToggle(true);
        } else {
          this.versionsRefs[key].childRefs.forEach(ref => {
            ref.current?.handleExpandToggle(false);
          });
          this.versionsRefs[key].ref.current?.handleExpandToggle(false);
        }
      });

      forEach(this.containersRefs, ({ isActivePerformer, isCurrentPhase, hasPhaseContent }, key) => {
        if (!isActivePerformer) {
          this.containersRefs[key].ref.current?.handleExpandToggle(false);
        } else if (isCurrentPhase && hasPhaseContent) {
          this.containersRefs[key].ref.current?.handleExpandToggle(true);
        }
      });
    }
  };

  render() {
    const {
      onRef,
      projectComponent,
      storageFilesPath,
      userId,
      isDefaultExpanded,
      onClickCopyVersionFiles,
      onCreateDataRequest,
    } = this.props;
    const { newTask } = this.state;

    const {
      id,
      processedName,
      processedCode,
      type,
      files,
      performers,
      engineers,
      lastFilesVersion,
      customer,
      hasVacancies,
      vacancies,
      objectId,
      dataRequests,
      root,
      deadline,
    } = projectComponent;
    const isOwner = userId === customer.userId;
    const isChiefEngineer = engineers
      .some(engineer => engineer.userId === userId && engineer.role.accusative === VACANCY_TYPE.chief);
    const isEngineerResponsibleForAgreements = checkEmployeeResponsibleForAgreements(engineers, userId);
    const isEngineerResponsibleForInitialData = checkEmployeeResponsibleForInitialData(engineers, userId);
    const isPerformerResponsibleForAgreements = checkEmployeeResponsibleForAgreements(performers, userId);
    const isPerformerResponsibleForInitialData = checkEmployeeResponsibleForInitialData(performers, userId);
    const activePerformer = getActiveEmployee(performers);
    const isPerformer = userId === activePerformer?.userId;
    const { currentPhase, userId: performerUserId, contractId } = activePerformer || {};
    const hasCompetitionStartedVacancy = vacancies.some(vacancy => vacancy.isCompetitionStarted);
    const hasInitialDataFile = !!files[STAGE_FILE_TYPES.initialData.type];
    const hasProjectComponentAgreementFile = !!files[STAGE_FILE_TYPES.projectComponentAgreement.type];

    return <div {...(onRef && { ref: onRef })} className="project-component-wrapper">
      <Container
        id={id}
        isDefaultExpanded={isDefaultExpanded}
        shareExpandedState={this.handleToggle}
        className="project-component"
        headerContent={this.renderHeader()}
      >
        {performers
          .sort((a, b) => a.contractId - b.contractId)
          .map(({ rating, ...performer }, index) => <React.Fragment key={index}>
            <div className="header-performer-row">
              <DataGroup title={VACANCY_TYPE.performer} className="performer">
                {this.renderPerformerName(performer)}
              </DataGroup>
              {performer.userId && <div>
                {performer.paymentTerms && <Button
                  className="performer-contract"
                  onClick={() => goTo(getContractProfileRoute(
                    performer.contractStatus?.isActive,
                    performer.contractStatus?.isArbitration,
                    objectId,
                    performer.contractId,
                  ))}
                  IconComponent={ICONS.contracts}
                  isBorderless
                />}
                {rating > 0 && <Rating value={rating} />}
                {performer.contractStatus?.isActive && <Button
                  id="quickTaskButton"
                  className="quick-task"
                  onClick={() => this.handleOpenTaskModal({
                    objectId,
                    stageId: root.id,
                    objectProjectComponentId: id,
                    deadline,
                    assignedUserId: performer.userId,
                  })}
                  IconComponent={ICONS.outgoing}
                  isBorderless
                />}
              </div>}
              {this.renderPerformerActions(performer)}
            </div>
            {typeof performer.isActive === 'boolean' && !performer.isActive && <div className="version-section" key={index}>
              {this.renderVersions(performer)}
            </div>}
          </React.Fragment>)}
        <ProjectComponentFiles
          entityId={id}
          objectId={objectId}
          files={files[STAGE_FILE_TYPES.initialData.type]}
          type={STAGE_FILE_TYPES.initialData.type}
          title={STAGE_FILE_TYPES.initialData.title}
          storagePath={storageFilesPath}
          showAddButton={isOwner || isChiefEngineer || isEngineerResponsibleForInitialData || isPerformerResponsibleForInitialData}
          showDeleteButton={!(hasCompetitionStartedVacancy || hasVacancies)}
          isContainer
          isCustomerOrChief={isOwner || isChiefEngineer}
          isPerformer={isPerformer}
          dataRequests={dataRequests}
          currentPhase={currentPhase}
          hasDownloadBundleLink={hasInitialDataFile}
          downloadBundleButtonTitle={STAGE_FILE_TYPES.initialData.title.downloadBundleButton}
          onClickCopyVersionFiles={onClickCopyVersionFiles}
          onClickDataRequest={this.handleOpenDataRequestModal}
        />
        {activePerformer && this.renderVersions(activePerformer)}
        <ProjectComponentFiles
          entityId={id}
          objectId={objectId}
          files={files[STAGE_FILE_TYPES.projectComponentAgreement.type]}
          type={STAGE_FILE_TYPES.projectComponentAgreement.type}
          title={setProjectComponentType(STAGE_FILE_TYPES.projectComponentAgreement.title, type.dativeName)}
          storagePath={storageFilesPath}
          showAddButton={isOwner || isChiefEngineer || isEngineerResponsibleForAgreements || isPerformerResponsibleForAgreements}
          isContainer
          isCustomerOrChief={isOwner || isChiefEngineer}
          hasDownloadBundleLink={hasProjectComponentAgreementFile}
          downloadBundleButtonTitle={STAGE_FILE_TYPES.projectComponentAgreement.title.downloadBundleButton}
          onClickCopyVersionFiles={onClickCopyVersionFiles}
        />
      </Container>
      {performerUserId === userId
        ? <AddProjectFilesVersionModal
          onRef={this.addProjectComponentFilesVersionModalRef}
          objectProjectComponentId={id}
          versionNumber={lastFilesVersion.number + 1}
          phaseId={currentPhase?.id}
          contractId={contractId}
          typeName={type.name}
          title={`Добавить новую версию для ${getProjectComponentName(processedName, processedCode)}`}
          storagePath={storageFilesPath}
        />
        : <RemarkDraftsModal
          onRef={this.remarkDraftsModalRef}
          isModal
          projectComponent={projectComponent}
          isExpertise={!!currentPhase?.isExpertisePhase}
          currentPhaseId={currentPhase?.id}
        />}
      <DataRequestModal onRef={this.dataRequestModalRef} onSubmit={onCreateDataRequest} projectComponent={projectComponent} />
      {!!newTask.objectId && <TaskModal
        onRef={this.createTaskModalRef}
        task={newTask}
        isMessage={false}
        isEdit={false}
        isAnswer={false}
        isReassign={false}
        goToTasks={() => {}}
        onClose={this.handleCloseTaskModal}
      />}
    </div>;
  }

  static contextTypes = {
    phases: PropTypes.array.isRequired,
  };

  static propTypes = {
    onRef: PropTypes.object,
    projectComponent: PropTypes.object.isRequired,
    storageFilesPath: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    selectedProjectComponentId: PropTypes.number.isRequired,
    isDefaultExpanded: PropTypes.bool,
    isExpert: PropTypes.bool,
    history: PropTypes.object.isRequired,
    onClickCreatePerformerVacancy: PropTypes.func,
    onClickDeleteVacancy: PropTypes.func.isRequired,
    onClickTurnInWork: PropTypes.func,
    onClickAcceptWork: PropTypes.func,
    onClickBreakContract: PropTypes.func,
    onClickRemarks: PropTypes.func,
    onClickOpenChat: PropTypes.func,
    onClickMakeArbitrationDecision: PropTypes.func,
    onClickCopyVersionFiles: PropTypes.func.isRequired,
    onCreateDataRequest: PropTypes.func.isRequired,
    showInfoModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: null,
    isDefaultExpanded: false,
    isExpert: false,
    onClickCreatePerformerVacancy() {},
    onClickTurnInWork() {},
    onClickAcceptWork() {},
    onClickBreakContract() {},
    onClickMakeArbitrationDecision() {},
    onClickRemarks() {},
    onClickOpenChat() {},
  };
}

export default withRouter(ProjectComponentItem);
