import { compose, defaultProps, withContext } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RemarksMenu from './index';
import { ROUTES } from '../../../../utils/routes';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import {
  getActiveEmployee,
  getObjects,
  isActiveEmployee,
  setFlattenedStageProjectComponents,
} from '../../../../graphql/model/object';
import { GENITIVE_VACANCY_TYPE, VACANCY_TYPE } from '../../../../utils/vacancyType';
import {
  formatRemarks,
} from '../../../../graphql/model/remark';
import { STAGE_FILE_TYPES } from '../../../../utils/stage';
import {
  prepareToDeleteTaskMessageFiles,
  prepareToInsertTaskMessageFiles,
  prepareToInsertTaskMessages,
} from '../../../../graphql/model/tasks';

import withRemarkReferencesQuery from '../../../../graphql/hoc/remarkReferences';
import withUserReferencesQuery from '../../../../graphql/hoc/userReferences';
import withExpertUserReferencesQuery from '../../../../graphql/hoc/expertUserReferences';
import {
  getObjectProjectComponentQuery,
  OBJECT_PROJECT_COMPONENT_ORDER,
} from '../../../../graphql/model/projectComponent';
import { deepMerge } from '../../../../utils/merge';

const GET_REMARKS = loader('../../../../graphql/queries/remark/get.graphql');
const ON_REMARKS_UPDATE = loader('../../../../graphql/queries/remark/getSubscription.graphql');
const INSERT_PROJECT_COMPONENT_FILES_MUTATION = loader('../../../../graphql/queries/object/insertProjectComponentFiles.graphql');
const INSERT_TASK_MESSAGE_QUERY = loader('../../../../graphql/queries/task/insertMessage.graphql');
const UPDATE_REMARK_MUTATION = loader('../../../../graphql/queries/remark/update.graphql');
const INSERT_REMARK_REVIEW_MUTATION = loader('../../../../graphql/queries/remark/insertReview.graphql');
const UPDATE_TASK_MESSAGE_MUTATION = loader('../../../../graphql/queries/task/updateTaskMessage.graphql');

const withInsertProjectComponentFilesMutation = graphql(INSERT_PROJECT_COMPONENT_FILES_MUTATION, {
  name: 'insertProjectComponentFiles',
});

const withUpdateRemarkMutation = graphql(UPDATE_REMARK_MUTATION, {
  name: 'updateRemark',
});

const withInsertRemarkReviewMutation = graphql(INSERT_REMARK_REVIEW_MUTATION, {
  name: 'insertRemarkReview',
});

const withInsertTaskMessageMutation = graphql(INSERT_TASK_MESSAGE_QUERY, {
  name: 'insertTaskMessage',
});

const withUpdateTaskMessageMutation = graphql(UPDATE_TASK_MESSAGE_MUTATION, {
  name: 'updateTaskMessage',
});

const mapStateToProps = ({ session: { user } }) => ({
  userId: user.id,
});

const mergeProps = (
  { userId },
  dispatchProps,
  {
    location,
    updateRemark,
    insertRemarkReview,
    insertTaskMessage,
    updateTaskMessage,
    insertProjectComponentFiles,
    remarkStatus,
    remarkReviewStatus,
    userRoles,
  },
) => {
  const remarksMenuProps = matchPath(location.pathname, [
    ROUTES.projectComponentRemark,
    ROUTES.projectComponentRemarks,
  ])?.params;
  const { id, stageId, projectComponentId, remarkId } = remarksMenuProps;

  return {
    userId,
    objectId: parseInt(id, 10),
    stageId: parseInt(stageId, 10),
    projectComponentId: parseInt(projectComponentId, 10),
    remarkId: parseInt(remarkId, 10),
    onAddResponse: ({ documentationFiles, id: responseRemarkId, isAgree, isPerformer, projectComponent, ...data }, isRevision) => {
      const promises = [];

      if (documentationFiles?.length > 0 && documentationFiles[0].file.remotePath) {
        const { performers, lastFilesVersion } = projectComponent;
        const activePerformer = getActiveEmployee(performers);
        const files = documentationFiles.map(({ file }) => ({
          ...file,
          type: file.mimeType.indexOf('pdf') > -1
            ? STAGE_FILE_TYPES.resultBundledInPdf.type
            : STAGE_FILE_TYPES.sourceInEditableFormat.type,
        }));
        promises.push(insertProjectComponentFiles({
          variables: {
            files: files.map(({ type, ...file }) => ({
              objectProjectComponentId: data.objectProjectComponentId,
              type,
              file: {
                data: file,
              },
              phaseId: activePerformer.currentPhase?.id || null,
              version: {
                data: {
                  number: lastFilesVersion.number + 1,
                  objectProjectComponentId: data.objectProjectComponentId,
                  contractId: lastFilesVersion.contractId,
                },
              },
            })),
          },
        }));
      }

      promises.push(insertTaskMessage({
        variables: {
          messages: prepareToInsertTaskMessages(data),
        },
      }));

      if (isRevision) {
        promises.push(insertRemarkReview({
          variables: {
            remarkReview: {
              remarkId: responseRemarkId,
              userRoleId: userRoles.find(({ name }) => name === data.reviews.find(({ isMyRole }) => isMyRole).role).id,
              reviewStatusId: remarkReviewStatus.find(({ kind }) => kind === 'negative').id,
            },
          },
        }));
      } else {
        promises.push(updateRemark({
          variables: {
            id: responseRemarkId,
            remark: {
              statusId: remarkStatus.find(({ kind }) => kind === (isAgree ? 'positive' : 'negative')).id,
            },
          },
        }));
      }

      return Promise.all(promises)
        .then(result => {
          if (result.errors) {
            throw result.errors;
          }
        }).catch(error => {
          showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
          throw error;
        });
    },
    onUpdateRemarkReview: (remark, isApproved) => insertRemarkReview({
      variables: {
        remarkReview: {
          remarkId: remark.id,
          userRoleId: userRoles.find(({ name }) => name === remark.reviews.find(({ isMyRole }) => isMyRole).role).id,
          reviewStatusId: remarkReviewStatus.find(({ kind }) => kind === isApproved ? 'positive' : 'negative').id,
        },
      },
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
    onEditTaskMessage: ({ task, message, initialFiles, messageFiles, isAgree, ...data }) => Promise.all([
      updateTaskMessage({
        variables: {
          id: task.lastMessage.id,
          taskMessage: {
            message,
          },
          taskMessageFilesToInsert: prepareToInsertTaskMessageFiles(task.lastMessage.id, messageFiles, initialFiles),
          taskMessageFilesToDelete: prepareToDeleteTaskMessageFiles(messageFiles, initialFiles),
        },
      }).then(result => {
        if (result.errors) {
          throw result.errors;
        }
      }),
      updateRemark({
        variables: {
          id: data.remarkId,
          remark: {
            statusId: remarkStatus.find(({ kind }) => kind === (isAgree ? 'positive' : 'negative')).id,
          },
        },
      }).then(result => {
        if (result.errors) {
          throw result.errors;
        }
      }),
    ]).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  };
};

const withGetObjectStageProjectComponentsQuery = graphql(GET_REMARKS, {
  name: 'getRemarks',
  skip: ({ objectId }) => !objectId,
  options: ({ objectId, userId }) => ({
    variables: {
      id: objectId,
      userId,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getRemarks: { object = [], error, loading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryObject: object,
      queryLoading: loading,
    };
  },
});

const withGetObjectStageProjectComponentsSubscription = graphql(ON_REMARKS_UPDATE, {
  name: 'remarksSub',
  skip: ({ objectId }) => !objectId,
  options: ({ objectId, userId }) => ({
    variables: {
      id: objectId,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
      projectComponentWhere: getObjectProjectComponentQuery(userId),
    },
  }),
  props: ({ remarksSub: { object = [], error, loading }, ownProps: { queryObject, queryLoading, userId, stageId, projectComponentId, remarkId, isExpert } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    let formattedObject = {};
    let projectComponents = [];
    let projectComponent = null;

    if (queryObject.length > 0) {
      formattedObject = getObjects([deepMerge(queryObject[0], object[0])], userId, isExpert)[0];
      const objectStage = formattedObject.stages.find(({ id }) => id === stageId);

      if (objectStage) {
        setFlattenedStageProjectComponents([objectStage]);
        projectComponents = objectStage.projectComponents
          .find(({ id }) => id === projectComponentId)?.flattenedProjectComponents || [];
        projectComponent = remarkId ? projectComponents.find(({ id }) => id === remarkId) : projectComponents[0];

        if (projectComponent) {
          projectComponent.objectId = formattedObject.id;
          const stage = formattedObject.stages.find(({ id }) => id === stageId);

          projectComponent.engineers = [
            ...(stage?.chiefEngineer ? [stage.chiefEngineer] : []),
            ...(projectComponent.engineers.length > 0 ? projectComponent.engineers : []),
          ];
          projectComponent.customer = {
            userId: formattedObject.userId,
            isCustomer: true,
            role: {
              accusative: VACANCY_TYPE.customer,
              genitive: GENITIVE_VACANCY_TYPE.customer,
            },
          };
          projectComponent.remarks = formatRemarks(
            projectComponent.remarks,
            projectComponent.performers,
            [...projectComponent.engineers, projectComponent.customer],
            [...stage.vacancies, ...projectComponent.vacancies],
            projectComponent.customer,
            userId,
          );
        }
      }
    }

    return {
      menu: projectComponents.map(({ id, processedCode }) => ({ id, title: processedCode })),
      object: formattedObject,
      projectComponent,
      isPerformer: (projectComponent?.performers || [])
        .some(({ userId: employeeUserId, isActive, isPerformer }) => isPerformer && isActive && employeeUserId === userId),
      isAcceptor: formattedObject.userId === userId || isActiveEmployee(projectComponent?.engineers, userId),
      isLoading: queryLoading || loading,
    };
  },
});

export default compose(
  withRouter,
  defaultProps({
    fullUserRoles: true,
  }),
  withRemarkReferencesQuery,
  withUserReferencesQuery,
  withUpdateRemarkMutation,
  withInsertRemarkReviewMutation,
  withInsertTaskMessageMutation,
  withUpdateTaskMessageMutation,
  withInsertProjectComponentFilesMutation,
  connect(mapStateToProps, null, mergeProps),
  withExpertUserReferencesQuery,
  withGetObjectStageProjectComponentsQuery,
  withGetObjectStageProjectComponentsSubscription,
  withContext(
    {
      onAddResponse: PropTypes.func.isRequired,
      onUpdateRemarkReview: PropTypes.func.isRequired,
      onEditTaskMessage: PropTypes.func.isRequired,
    },
    ({
      onAddResponse,
      onUpdateRemarkReview,
      onEditTaskMessage,
    }) => ({
      onAddResponse,
      onUpdateRemarkReview,
      onEditTaskMessage,
    }),
  ),
)(RemarksMenu);
