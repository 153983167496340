import mapValues from 'lodash/mapValues';
import upperFirst from 'lodash/upperFirst';

export const STAGE_FILE_TYPES = {
  initialData: {
    type: 'initialData',
    title: {
      placeholder: 'Исходные данные',
      tooltipHeader: 'Раздел "Исходные данные"',
      hint: 'В данном блоке ГИП и заказчик загружают всю необходимую для процесса проектирования исходную документацию',
      downloadBundleButton: 'Скачать исходные данные одним архивом',
    },
  },
  sourceInEditableFormat: {
    type: 'sourceInEditableFormat',
    title: 'PROJECT_COMPONENT в редактируемом формате',
  },
  resultBundledInPdf: {
    type: 'resultBundledInPdf',
    title: 'PDF файл',
  },
  projectComponentAgreement: {
    type: 'projectComponentAgreement',
    title: {
      placeholder: 'Согласования по PROJECT_COMPONENT',
      tooltipHeader: 'Согласования по PROJECT_COMPONENT',
      hint: 'Сюда загружаются сканы полученных в процессе разработки документации согласований',
      downloadBundleButton: 'Скачать согласования одним архивом',
    },
  },
};

export const setProjectComponentType = (title, type) => typeof title === 'string'
  ? upperFirst(title.replace('PROJECT_COMPONENT', type))
  : mapValues(title, value => value.replace('PROJECT_COMPONENT', type));
