import { getFullName } from '../../../utils/name';

const prepareTitle = (favoriteTypeName, titleFunc, user) =>
  titleFunc(user.userId, user.fullName || getFullName(user) || user.email, favoriteTypeName);

export const prepareInvitationToFavoriteTitle = (isPerformer, ...args) =>
  prepareTitle(isPerformer ? 'Исполнителя' : 'Инженера', ...args);

export const prepareRemoveFromFavoriteTitle = (isPerformer, ...args) =>
  prepareTitle(isPerformer ? 'исполнителей' : 'инженеров', ...args);
