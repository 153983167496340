import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export default class Mailto extends PureComponent {
  render() {
    const { email, subject = '', body = '', children } = this.props;
    let params = subject || body ? '?' : '';

    if (subject) {
      params += `subject=${encodeURIComponent(subject)}`;
    }

    if (body) {
      params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
    }

    return <a className="mailto" href={`mailto:${email}${params}`}>{children}</a>;
  }

  static propTypes = {
    email: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    subject: PropTypes.string,
    body: PropTypes.string,
  };

  static defaultProps = {
    subject: '',
    body: '',
  }
}
