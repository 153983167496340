import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../base/button';
import Rating from '../../../../base/rating';
import ProfileLink from '../../../../base/profileLink';
import { getContractorName } from '../../../../../utils/name';
import { ICONS } from '../../../../../utils/icons';

import './index.scss';

export default class RemarksHeader extends PureComponent {
  render() {
    const { title, dativeName, users, onClickOpenChat } = this.props;

    return <div className="stage-remarks-header">
      <h1>Замечания</h1>
      <h5>{title}</h5>
      <div className="user-info">
        <div>
          {users.map((user, index) => user.isActive && user.userId && <div key={index} className="header-user-row">
            <div className="user">
              <span>{user.role.accusative}: </span>
              <ProfileLink
                text={getContractorName(user)}
                userId={user.userId}
              />
            </div>
            {user.userId && <Rating value={user.rating} />}
          </div>)}
        </div>
        <Button
          className="chat"
          onClick={onClickOpenChat}
          IconComponent={ICONS.chat}
          isBorderless
        >
          Чат по {dativeName}
        </Button>
      </div>
    </div>;
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    dativeName: PropTypes.string,
    onClickOpenChat: PropTypes.func,
  };

  static defaultProps = {
    dativeName: '',
    onClickOpenChat() {},
  }
}
