import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { checkAuthentication, signIn, setPassword } from '../../store/actions/session';
import CheckInvitation from './index';

const mapStateToProps = ({ session: { isLoading } }) => ({
  isLoading,
});

const mapDispatchToProps = {
  checkAuthentication,
  signIn,
  setPassword,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CheckInvitation);
