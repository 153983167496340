import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { getBundleLink } from '../../utils/links';
import PropTypes from 'prop-types';

class DownloadBundle extends PureComponent {
  componentDidMount() {
    window.open(getBundleLink([{ objectId: this.props.match.params.id }]));
  }

  render() {
    return <div className="downloadBundle">
      <h3>Формирование архива, пожалуйста, подождите...</h3>
      <p>Если скачивание не началось, нажмите на ссылку ниже</p>
      <a
        href={getBundleLink([{ objectId: this.props.match.params.id }])}
        target="_blank"
        rel="noopener noreferrer"
      >
        Скачать архив по объекту
      </a>
    </div>;
  }
}

DownloadBundle.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(DownloadBundle);
