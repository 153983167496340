import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Container from '../../base/container';
import Rating from '../../base/rating';
import { getFullName } from '../../../utils/name';
import DataGroup from '../../base/dataGroup';
import ProfileLink from '../../base/profileLink';
import Section from '../../base/section';
import { VACANCY_TYPE } from '../../../utils/vacancyType';

import './index.scss';

export default class UserItem extends React.PureComponent {
  static RATING_ORDER = [
    {
      title: 'Выбирался в роли ведущего инженера:',
      name: VACANCY_TYPE.lead,
    },
    {
      title: 'Выбирался в роли главного инженера:',
      name: VACANCY_TYPE.chief,
    },
    {
      title: 'Выбирался в роли исполнителя:',
      name: VACANCY_TYPE.performer,
    },
    {
      title: 'Выступал заказчиком:',
      name: VACANCY_TYPE.customer,
    },
  ];

  getDefaultHeaderData = ({
    ratings,
    companyName,
    taxSystemName,
    objectsCount,
    experience,
    ...item
  }, userId) => {
    const { currentRatingOrder, isFavorite } = this.props;
    const fullName = getFullName(item);

    return [
      userId && fullName
        ? {
          title: 'ФИО',
          className: 'full-name',
          value: <ProfileLink text={fullName} userId={userId} />,
        }
        : {
          title: 'Email',
          className: 'email',
          value: item?.email,
        },
      {
        title: 'Компания',
        className: 'company-name',
        value: companyName,
      },
      {
        title: 'Объектов в работе',
        className: 'objects-count',
        value: objectsCount,
      },
      {
        title: 'Позиция в рейтинге',
        className: 'rating-position',
        value: ratings.position || '-',
      },
      {
        title: 'Налогообложение',
        className: 'tax-system-name',
        value: taxSystemName,
      },
      {
        title: 'Стаж работы',
        className: 'experience',
        value: experience,
      },
      {
        className: classNames('rating', { favorite: isFavorite }),
        value: <Rating value={currentRatingOrder ? ratings[currentRatingOrder] : ratings.avg} />,
      },
    ];
  };

  renderHeader(data) {
    const { isFavorite } = this.props;

    return <div className={classNames('user-item-header', { 'rating-user-header': !isFavorite })}>
      <div className="data-row">
        {data.map(({ title, value, className }, i) =>
          <DataGroup key={i} title={title} className={className}>{value}</DataGroup>,
        )}
      </div>
    </div>;
  }

  renderDetailedRating(ratings) {
    return UserItem.RATING_ORDER.reduce(
      (result, { name, title }, index) => result.concat(
        <div key={index} className="data-group">
          <span>{title}</span>
          <div><Rating value={ratings[name]} /></div>
        </div>,
      ), []);
  }

  renderContent({
    specialtyTypes = [],
    objectTypes = [],
    ratings = {},
    projectComponents = [],
    regions = [],
  }) {
    const { isFavorite } = this.props;

    return <div className={classNames('user-item-content', { 'rating-user-content': !isFavorite })}>
      <Section>
        <DataGroup title="Специализация">{specialtyTypes.length ? specialtyTypes.join(', ') : ''}</DataGroup>
        <DataGroup title="Типы объектов">{objectTypes.length ? objectTypes.join(', ') : ''}</DataGroup>
      </Section>
      <Section>
        <div className="data-row">
          <div className="detailed-rating">
            {this.renderDetailedRating(ratings)}
          </div>
        </div>
        <div className="project-components">
          <DataGroup title="Выполняет разделы">{projectComponents.length ? projectComponents.join(', ') : ''}</DataGroup>
          <DataGroup title="Регионы работы">{regions.length ? regions.join(', ') : ''}</DataGroup>
        </div>
      </Section>
    </div>;
  }

  render() {
    const {
      data,
      style,
      setItemState,
      isExpanded,
      isExpansionDisabled,
      className,
      showToggle,
      headerData,
    } = this.props;

    return <div style={style} className={classNames('users-list-item-container', className)}>
      <Container
        isDefaultExpanded={isExpanded}
        isExpansionDisabled={isExpansionDisabled}
        shareExpandedState={setItemState}
        className="user-item"
        headerContent={this.renderHeader(headerData.length > 0
          ? headerData
          : this.getDefaultHeaderData(data, this.props.userId))}
        showToggle={showToggle}
      >
        {('isReadable' in data ? data.isReadable : data.isPublic) && this.renderContent(data)}
        {this.props.actionButtons}
      </Container>
    </div>;
  }

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    style: PropTypes.object,
    setItemState: PropTypes.func,
    showToggle: PropTypes.bool,
    isFavorite: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isExpansionDisabled: PropTypes.bool,
    headerData: PropTypes.array,
    actionButtons: PropTypes.node,
    userId: PropTypes.number.isRequired,
    currentRatingOrder: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    data: null,
    isFavorite: false,
    isExpanded: false,
    isExpansionDisabled: true,
    style: {},
    setItemState() {},
    showToggle: false,
    headerData: [],
    actionButtons: null,
    currentRatingOrder: '',
  };
}
