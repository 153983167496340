export const OBJECT_FILE_TYPES = {
  technicalTask: {
    type: 'technicalTask',
    title: 'Техническое задание на объект',
  },
  agreement: {
    type: 'agreement',
    title: 'Согласования на объект',
  },
  otherDocument: {
    type: 'otherDocument',
    title: 'Прочие документы',
  },
};
