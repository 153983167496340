import { VACANCY_TYPE } from '../../utils/vacancyType';

export const getVacancyTypeIdsMap = vacancyTypes => {
  const vacancyTypeIds = {};
  Object.keys(VACANCY_TYPE).forEach(vacancyTypeName => {
    const foundVacancyType = (vacancyTypes || []).find(vacancyType => vacancyType.userRole.name === VACANCY_TYPE[vacancyTypeName]);
    if (foundVacancyType) {
      vacancyTypeIds[vacancyTypeName] = foundVacancyType.id;
    }
  });
  return vacancyTypeIds;
};
