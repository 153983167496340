import { formValueSelector, reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import CreateVacancyModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';

const FORM_NAME = 'CreatePerformerVacancyModal';
const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  selectedStageDeadline: formSelector(state, 'deadline'),
  selectedPhase1Deadline: formSelector(state, 'paymentTerms.phase1.deadline'),
});

const mergeProps = withProps(props => ({
  isPerformer: true,
  initialValues: {
    deadline: props.deadline,
    isSafeDeal: true,
    paymentTerms: {
      usePhases: true,
      isExpertiseRequired: true,
      phase1: {
        deadline: props.deadline,
      },
      special: {
        isCustomersCoordinationWithServicesResponsibility: false,
        isCustomersInitialDataResponsibility: false,
      },
    },
  },
}));

export default compose(
  connect(mapStateToProps),
  mergeProps,
  reduxForm({
    form: 'CreatePerformerVacancyModal',
    enableReinitialize: true,
    validate: getValidation([
      'contractDetailType',
      'employee',
      'totalAmount',
      'paymentTerms.phase0.rate',
      'paymentTerms.phase1.rate',
      'paymentTerms.phase2.rate',
      'paymentTerms.phase3.rate',
      'paymentTerms.phase0.termLimit',
      'paymentTerms.phase1.deadline',
      'paymentTerms.remarksFixTermLimit',
      'paymentTerms.remarksCreateTermLimit',
      'paymentTerms.phase2.termLimit',
      'paymentTerms.phase3.termLimit',
      'paymentTerms.phase2.negativeExpertiseFine',
      'paymentTerms.worksDelayPenalty',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(CreateVacancyModal);
