import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import noop from 'lodash/noop';

import Modal from '../../base/modal';
import { validateDeadline } from '../../../utils/validation';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import WorkConditions from '../../contract/profile/workConditions';
import Switch from '../../base/switch';
import Hint from '../../base/hint';

import './index.scss';

export default class CreateObjectModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showWorkConditions: false,
      assignChiefEngineer: false,
    };
  }

  toggleShowWorkConditions = value => {
    const { initialValues, change } = this.props;

    this.setState({
      showWorkConditions: value,
    });
    change('contractDetailType', '');
    change('paymentTerms', initialValues.paymentTerms);
    change('isSafeDeal', initialValues.isSafeDeal);
  };

  toggleAssignChiefEngineer = value => {
    this.setState({
      assignChiefEngineer: value,
    });
    this.props.change('chiefEngineer', '');
  };

  handleClose = () => {
    this.props.reset();
    this.setState({ showWorkConditions: false, assignChiefEngineer: false });
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleCreateObject = data => {
    const { onCreateObject, onRef } = this.props;
    onCreateObject(data).then(() => {
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      objectTypes,
      favoriteEngineers,
      regions,
      userTariffPlans,
      contractDetails: {
        selfContractDetails,
        organizationContractDetails,
      },
      contractDetailTypes,
      selectedObjectType,
      selectedStages,
      selectedStageDeadline,
      projectComponents,
      taxSystems,
      change,
      initialValues,
      selectedTariffPlanId,
      ...props
    } = this.props;
    const { showWorkConditions, assignChiefEngineer } = this.state;
    const selectedStagesCount = selectedStages.length;
    const allowedToAssignChiefEngineer = selectedStagesCount === 1 && !!selectedStageDeadline;
    const isDemoTariff = userTariffPlans.find(({ value: tariffPlanId }) => tariffPlanId === selectedTariffPlanId)?.isDemo;
    const isDemoMode = (!selfContractDetails && !organizationContractDetails) && isDemoTariff;

    return <Modal {...props} className="create-object-modal" title="Создать объект">
      <Form onSubmit={handleSubmit(this.handleCreateObject)}>
        <Field
          name="tariffPlan"
          component={FormGroupField}
          type="select"
          label="Тарифный план"
          data={userTariffPlans}
        />
        <Field
          name="name"
          component={FormGroupField}
          type="text"
          label="Наименование"
          placeholder="Введите наименование объекта"
        />
        <Field
          name="region"
          component={FormGroupField}
          type="select"
          label="Регион объекта"
          data={regions}
        />
        <Field
          name="address"
          component={FormGroupField}
          type="text"
          label="Адрес объекта"
          placeholder="Введите адрес объекта"
        />
        <Field
          name="code"
          component={FormGroupField}
          type="text"
          label="Шифр объекта"
          placeholder="Введите шифр"
        />
        <Field
          name="objectType"
          component={FormGroupField}
          type="select"
          label="Тип объекта"
          placeholder="Выберите типы объектов"
          data={objectTypes}
        />
        <Field
          name="projectComponents"
          component={FormGroupField}
          type="select"
          label="Состав проекта"
          placeholder="Выбрать состав проекта"
          data={projectComponents}
          isDisabled={!selectedObjectType}
          isMultiSelect
        />
        {selectedStagesCount > 0 && <>
          <span className="stages-deadline-label">Срок окончания работ по Стадии</span>
          {selectedStages.map(({ id, name }, index) => <Field
            key={index}
            className="stage-deadline"
            name={`stageDeadline-${id}`}
            component={FormGroupField}
            validate={validateDeadline}
            type="date"
            label={name}
            minDate={new Date()}
          />)}
        </>}
        <div className="chief-engineer-section">
          <Switch
            labelLeft={<>
              Оставить функции ГИПа за собой
              <Hint title="Функция ГИПа">
                Если при создании объекта заказчик оставляет функцию ГИПа за собой, то он будет являтся координатором
                работы всех исполнителей. Соответствующая информация будет размещена в информационных блоках каждого
                раздела. В любой момент заказчик может переназначить на эту роль другого специалиста из своего избранного
                списка или разместить вакансию на конкурс.
              </Hint>
            </>}
            labelRight={<>Выбрать ГИПа из избранного
              <Hint title="Выбор ГИПа">
                Если объект создается сразу с несколькими стадиями, тогда следует создавать вакансии инженеров непосредственно в самой стадии.
              </Hint>
            </>}
            onChange={this.toggleAssignChiefEngineer}
            isChecked={allowedToAssignChiefEngineer && assignChiefEngineer}
            isDisabled={isDemoMode || !allowedToAssignChiefEngineer}
            className="assign-chief-engineer"
            id="selectChiefEngineerSwitch"
          />
          {allowedToAssignChiefEngineer && assignChiefEngineer && <Field
            name="chiefEngineer"
            component={FormGroupField}
            type="select"
            label="Выбрать специалиста из избранного"
            data={favoriteEngineers}
            keepRemoveTag
          />}
        </div>
        {allowedToAssignChiefEngineer && assignChiefEngineer && <>
          <Switch
            labelLeft="Назначить без договора"
            labelRight="Создать условия договора"
            onChange={this.toggleShowWorkConditions}
            isChecked={showWorkConditions}
            className="show-work-details form-group"
            id="paymentTerms"
            isDisabled={isDemoMode}
          />
          {showWorkConditions && <>
            <Field
              name="contractDetailType"
              component={FormGroupField}
              type="select"
              label="Реквизиты для договора"
              data={contractDetailTypes}
            />
            <WorkConditions
              isCompetition={false}
              taxSystems={taxSystems}
              change={change}
              initialValues={initialValues}
            />
          </>}
        </>}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={submitting || pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Добавить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    onCreateObject: PropTypes.func.isRequired,
    onRef: PropTypes.object,
    objectTypes: PropTypes.array,
    contractDetailTypes: PropTypes.array,
    favoriteEngineers: PropTypes.array,
    projectComponents: PropTypes.array,
    selectedStages: PropTypes.array,
    selectedStageDeadline: PropTypes.instanceOf(Date),
    selectedObjectType: PropTypes.number,
    regions: PropTypes.array,
    taxSystems: PropTypes.array,
    userTariffPlans: PropTypes.array.isRequired,
    change: PropTypes.func,
    initialValues: PropTypes.object,
    contractDetails: PropTypes.array,
    selectedTariffPlanId: PropTypes.number,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    regions: [],
    selectedObjectType: null,
    projectComponents: [],
    selectedStages: [],
    selectedStageDeadline: null,
    selectedTariffPlanId: null,
    objectTypes: [],
    contractDetailTypes: [],
    favoriteEngineers: [],
    taxSystems: [],
    initialValues: {},
    contractDetails: [],
    change() {},
  };
}
