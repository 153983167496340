import { formValueSelector, reduxForm } from 'redux-form';
import { compose, setPropTypes, withProps } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import InviteToContractModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { OBJECT_PROJECT_COMPONENT_ORDER } from '../../../graphql/model/projectComponent';
import { getFullContractSubject } from '../../../graphql/model/contracts';

const GET_OBJECTS_LIST_BY_USER_QUERY = loader('../../../graphql/queries/object/getListByUser.graphql');
const INSERT_CONTRACT_INVITATION_MUTATION = loader('../../../graphql/queries/contractInvitation/insert.graphql');
const GET_CONTRACT_LIST_BY_USER_QUERY = loader('../../../graphql/queries/contractInvitation/getListByUser.graphql');

const FORM_NAME = 'InviteToContractForm';
const formSelector = formValueSelector(FORM_NAME);

const withContractInvitationListByUser = graphql(GET_CONTRACT_LIST_BY_USER_QUERY, {
  name: 'getContractInvitationListByUser',
  skip: props => props.isSkip,
  options: ({ userId, invitedUserId }) => ({
    variables: {
      userId,
      invitedUserId,
    },
  }),
  props: ({ getContractInvitationListByUser: { items, refetch } }) => ({
    contractInvitations: items || [],
    refetchContractInvitations: refetch,
  }),
});

const withInsertContractInvitationMutation = graphql(INSERT_CONTRACT_INVITATION_MUTATION, {
  name: 'insertContractInvitation',
});

const withGetObjectsListByUserQuery = graphql(GET_OBJECTS_LIST_BY_USER_QUERY, {
  name: 'getListByUser',
  options: ({ userId }) => ({
    variables: {
      userId,
      where: { userId: { _eq: userId } },
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
      withProjectComponentContracts: true,
    },
    fetchPolicy: 'network-only',
  }),
  props: ({ getListByUser, ownProps }) => {
    const { items = [], error, loading: isLoading } = getListByUser;
    const { selectedObjectId, contractInvitations } = ownProps;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const objects = items.filter(({ projectComponents }) => projectComponents.some(({ vacancies }) => vacancies.length > 0));
    let contracts = [];

    if (selectedObjectId) {
      const selectedObject = objects.find(({ id }) => selectedObjectId === id);

      if (selectedObject) {
        contracts = selectedObject.projectComponents.reduce((result, { vacancies }) => {
          result = result.concat(vacancies
            .filter(({ contractExpanded }) => !contractInvitations.find(({ contractId }) => contractId === contractExpanded.id))
            .map(({ contractExpanded }) => contractExpanded));
          return result;
        }, []);
      }
    }

    return {
      objects: orderBy(
        objects.map(({ name, code, id }) => ({ label: `${name} ${code}`, value: id, checked: selectedObjectId === id })),
        ['label'],
      ),
      contracts: orderBy(
        contracts.map(({ id, subject, vacancyType }) => ({
          label: getFullContractSubject(vacancyType.isPerformer, subject, vacancyType.userRole.name),
          value: id,
        })),
        ['label'],
      ),
      isLoading,
    };
  },
});

const mapStateToProps = state => ({
  userId: state.session.user.id,
  selectedObjectId: formSelector(state, 'object'),
});

const mergeProps = withProps(({ insertContractInvitation, invitedUserId, refetchContractInvitations }) => ({
  onClickInviteToContract: ({ contract }) => insertContractInvitation({
    variables: {
      invitation: {
        contractId: contract,
        invitedUserId,
      },
    },
  }).then(result => {
    if (result.errors) {
      throw result.errors;
    }

    return refetchContractInvitations();
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
}));

export default compose(
  setPropTypes({
    invitedUserId: PropTypes.number,
  }),
  connect(mapStateToProps),
  withContractInvitationListByUser,
  withGetObjectsListByUserQuery,
  withInsertContractInvitationMutation,
  mergeProps,
  reduxForm({
    form: FORM_NAME,
    validate: getValidation(['object', 'contract']),
    onSubmitFail: getSubmitValidation,
  }),
)(InviteToContractModal);
