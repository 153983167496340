import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../../base/modal';
import Button from '../../base/button';
import { goTo } from '../../../utils/menu';

import './index.scss';

export default class InfoModal extends React.PureComponent {
  toggleModal = () => {
    const { onRef, onClick } = this.props;

    onRef.current.toggleModal();
    onClick();
  };

  handleClickOptionalButton = () => {
    const { optionalButtonProps: { path, params, onClickOptionalButton } } = this.props;

    if (onClickOptionalButton) {
      onClickOptionalButton();
    } else {
      goTo(path, params);
    }
  };

  render() {
    const { message, buttonTitle, optionalButtonProps, children, className, ...props } = this.props;

    return <Modal
      className={classNames('info-modal', className)}
      {...props}
    >
      <>
        {message && <p className="message-text">{message}</p>}
        {children}
        <div className="action-buttons">
          {optionalButtonProps && <Button
            onClick={this.handleClickOptionalButton}
            isFilled
            isLarge
          >
            {optionalButtonProps.title}
          </Button>}
          <Button onClick={this.toggleModal} isLarge>{buttonTitle}</Button>
        </div>
      </>
    </Modal>;
  }

  static propTypes = {
    message: PropTypes.string,
    children: PropTypes.node,
    onRef: PropTypes.object,
    onClick: PropTypes.func,
    buttonTitle: PropTypes.string,
    optionalButtonProps: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    message: '',
    children: '',
    className: '',
    onRef: {
      current: {
        toggleModal() {},
      },
    },
    onClick() {},
    buttonTitle: 'Закрыть',
    optionalButtonProps: null,
  };
}
