import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../../../layout/menu/container';
import Button from '../../base/button';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class ObjectsHeader extends PureComponent {
  render() {
    const { menu, onClick } = this.props;

    return <div className="objects-header">
      <div className="page-header-menu">
        <Menu items={menu} isTabMenu />
        <Button
          IconComponent={ICONS.circlePlus}
          onClick={onClick}
        >
          Добавить новый объект
        </Button>
      </div>
    </div>;
  }

  static propTypes = {
    menu: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
  };
}
