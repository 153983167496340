import { getProjectComponentProps } from './projectComponent';
import mapValues from 'lodash/mapValues';
import sumBy from 'lodash/sumBy';

import { getProjectComponentName } from '../../utils/projectComponent';
import { PHASE_STATUSES } from '../../utils/phase';
import { areNumbersEqual } from '../../utils/numbers';
import { INVOICE_TYPES } from '../../utils/invoice';
import { normalizeMoney } from '../../utils/money';
import { getContractorFromContract } from './employee';
import { VACANCY_TYPE } from '../../utils/vacancyType';
import { getTimeLeft } from '../../utils/date';
import { CONTRACT_FILE_TYPES } from '../../utils/file';
import { formatTransactions } from './transactions';
import { formatEvaluation } from './rating';
import { CONTRACT_STATUSES } from '../../utils/contract';

const formatBalance = transactions => (transactions || []).reduce((result, transaction) => {
  let { billed, paid, penalty, fine, balance } = result;
  let { credit, debit, invoiceType: { name: type } } = transaction;
  credit = normalizeMoney(credit);
  debit = normalizeMoney(debit);
  if (type === INVOICE_TYPES.penalty) {
    penalty += credit;
  } else if (type === INVOICE_TYPES.fine) {
    fine += credit;
  } else if (type === INVOICE_TYPES.regular) {
    billed += debit;
    paid += credit;
  } else if (type === INVOICE_TYPES.deposit) {
    paid += debit;
  }
  balance = paid - billed + penalty + fine;

  return {
    billed,
    paid,
    penalty,
    fine,
    balance,
  };
}, { billed: 0, paid: 0, penalty: 0, fine: 0, balance: 0 });

const transformContractTransactions = transactions => {
  return transactions.map(transaction => {
    return transaction.invoiceType.name === INVOICE_TYPES.deposit
      ? {
        ...transaction,
        credit: transaction.debit,
        debit: 0,
      } : transaction;
  });
};

export const formatContracts = (contracts, userId, withInvolved = true) => (contracts || [])
  .map(contract => {
    const {
      id,
      subject,
      vacancyType: {
        isPerformer,
        userRole: {
          name: vacancyTypeName,
        },
      },
      status,
      paymentTerms,
      objectContract,
      vacancies,
      totalAmount,
      phase,
      phaseDeadline,
      deadline,
      customerUserId,
      createdAt,
      updatedAt,
      signedAt,
      isSafeDeal,
      contractFiles,
      transactions,
      arbitration,
    } = contract;
    const { object, objectExperts } = objectContract || {};
    const daysLeftToPhaseDeadline = getTimeLeft(phaseDeadline);
    const isCustomer = customerUserId === userId;
    const isArbitration = !!arbitration?.id;
    const isExpert = objectExperts?.some(({ userId: expertUserId }) => expertUserId === userId);
    const role = isExpert
      ? VACANCY_TYPE.expert
      : isCustomer ? VACANCY_TYPE.customer : vacancyTypeName;
    let workPhaseDeadline = '';

    if (phaseDeadline) {
      workPhaseDeadline = daysLeftToPhaseDeadline || 'Просрочено';
    }
    if (!vacancies[0]) {
      return null;
    }
    const objectProjectComponent = vacancies[0].objectProjectComponent;
    const stageName = objectProjectComponent.root.rootComponent.templateProjectComponent.name;
    const stageId = objectProjectComponent.root.rootComponent.id;
    const stageDeadline = objectProjectComponent.root.rootComponent.deadline;

    return {
      id,
      subject,
      vacancyTypeName,
      role,
      isPerformer,
      status,
      paymentTerms,
      phase,
      arbitration,
      isArbitration,
      isExpert,
      contractor: getContractorFromContract(contract, isCustomer, role),
      evaluation: formatEvaluation(contract, isCustomer),
      phaseDeadline: workPhaseDeadline,
      deadline: new Date(deadline),
      totalAmount: normalizeMoney(totalAmount),
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
      signedAt: new Date(signedAt),
      customer: getContractorFromContract(contract, false, role),
      employee: getContractorFromContract(contract, true, role),
      object: object
        ? {
          ...object,
          objectType: object.objectType.name,
          region: object.region.name,
          stageName,
          stageId,
          stageDeadline,
          projectComponentId: objectProjectComponent.id,
          projectComponentDeadline: objectProjectComponent.deadline,
        }
        : {},
      involved: withInvolved && vacancies.map(item => getProjectComponentProps(item.objectProjectComponent).processedCode).join(', '),
      isSafeDeal,
      contractFileTypes: (contractFiles || []).reduce((result, { id, type, contractFileSigns, file }) => {
        const resultFile = {
          id,
          file,
          isOwner: file.userId === userId,
          signs: contractFileSigns.map(sign => ({
            ...sign,
            isMySign: sign.user.id === userId,
            role: sign.user.id === customerUserId ? VACANCY_TYPE.customer : vacancyTypeName,
          })),
        };

        if (result[type]) {
          result[type].files.push(resultFile);
        } else {
          result[type] = {
            ...CONTRACT_FILE_TYPES[type],
            files: [resultFile],
          };
        }

        return result;
      }, {}),
      balance: formatBalance(transactions),
    };
  });

export const formatPublicProfileContracts = contracts => (contracts || [])
  .map(contract => {
    const {
      createdAt,
      deadline,
      stage,
      section,
    } = contract;

    return {
      ...contract,
      deadline: new Date(deadline),
      createdAt: new Date(createdAt),
      stageName: stage?.replace('Стадия ', ''),
      involved: section,
      evaluation: formatEvaluation(contract, false, true),
    };
  });

export const formatContract = (contract, taxSystems, userId) => {
  const {
    id,
    vacancies,
    competitionTerms,
    paymentTerms,
    phase,
    phaseDeadline,
    transactions,
    isSafeDeal,
    totalAmount,
    customerUserId,
    vacancyType: {
      userRole: {
        name: vacancyTypeName,
      },
    },
    status,
  } = contract;
  const { isActive: isActiveContract, name: contractStatusName } = status || {};
  const {
    isExpertiseRequired,
    usePhases,
    remarksFixTermLimit,
    worksDelayPenalty,
    special,
    remarksCreateTermLimit,
    ...phases
  } = paymentTerms || {};
  const { key: currentPhase } = phase || {};
  const penaltyTransactions = transactions.filter(({ invoiceType, debit }) => invoiceType.name === INVOICE_TYPES.penalty && debit === 0);
  const isContractSuspended = contractStatusName === CONTRACT_STATUSES.suspended.name;

  return {
    id,
    totalAmount: normalizeMoney(totalAmount),
    vacancyTypeName,
    customer: getContractorFromContract(contract, false, VACANCY_TYPE.customer),
    isSafeDeal,
    evaluation: formatEvaluation(contract, customerUserId === userId),
    paymentTerms: {
      ...paymentTerms,
      props: {
        ...mapValues(phases, (phase, phaseKey) => {
          const timeLeft = getTimeLeft(phaseDeadline);
          let phaseStatus = PHASE_STATUSES.notStarted;
          let termLimitConfig = {
            message: phaseKey > currentPhase
              ? 'Этап не наступил'
              : timeLeft && isContractSuspended
                ? CONTRACT_STATUSES.suspended.name
                : `Осталось: ${timeLeft}` || '',
          };

          if (phaseKey < currentPhase) {
            phaseStatus = PHASE_STATUSES.passed;
            termLimitConfig = PHASE_STATUSES.passed;
          } else if (phaseKey === currentPhase) {
            if (isActiveContract) {
              if (isContractSuspended) {
                phaseStatus = PHASE_STATUSES.suspended;
              } else {
                phaseStatus = PHASE_STATUSES.inProgress;
              }
            } else {
              phaseStatus = PHASE_STATUSES.notPassed;
            }
          }

          termLimitConfig.color = phaseStatus.color;

          const delayFineConfig = {
            message: 'Начислено: ' + normalizeMoney(sumBy(
              transactions.filter(({ invoiceType, debit }) => invoiceType.name === INVOICE_TYPES.fine && debit === 0),
              'credit',
            )),
            color: PHASE_STATUSES.expired.color,
          };

          return {
            rate: {
              ...phaseStatus,
            },
            termLimit: termLimitConfig,
            negativeExpertiseFine: delayFineConfig,
            paymentDelayFine: delayFineConfig,
          };
        }),
        worksDelayPenalty: {
          message: `Начислено: ${normalizeMoney(sumBy(penaltyTransactions, 'credit'))} (${penaltyTransactions.length} дн)`,
          color: PHASE_STATUSES.inProgress.color,
        },
      },
    },
    competitionTerms: {
      ...competitionTerms,
      ...(taxSystems && {
        taxSystems: (competitionTerms?.taxSystems || [])
          .map(taxSystemId => taxSystems.find(({ id }) => areNumbersEqual(id, taxSystemId))?.name)
          .join(', '),
      }),
    },
    involved: vacancies.map(({ objectProjectComponent }) => {
      const { processedName, processedCode } = getProjectComponentProps(objectProjectComponent);

      return getProjectComponentName(processedName, processedCode);
    }).join(', '),
    payments: formatTransactions(transformContractTransactions(transactions.filter(transaction => transaction.userId === userId)))
      .reduce((result, transaction) => {
        const { payments, year } = transaction;

        payments.transactions.forEach(transaction => {
          transaction.month = `${transaction.month} ${year}`;
        });

        result.transactions = result.transactions.concat(payments.transactions);
        result.total.income += payments.total.income;
        result.total.outcome += payments.total.outcome;

        return result;
      }, {
        transactions: [],
        total: {
          income: 0,
          outcome: 0,
        },
      }),
    balance: formatBalance(transactions),
  };
};

export const getContractsQuery = ({ userId, isCurrent, isArbitration, inDispute, objectId, search }) => {
  const whereQuery = {
    _and: [
      {
        _or: [
          { employeeUserId: { _eq: userId } },
          { customerUserId: { _eq: userId } },
          { objectContract: { objectExperts: { userId: { _eq: userId } } } },
        ],
      },
      {
        employeeUserId: { _is_null: false },
      },
      {
        paymentTerms: { _is_null: false },
      },
      {
        status: {
          _and: [
            {
              isActive: { _eq: isCurrent },
            },
            {
              _or: [
                {
                  isArbitration: { _eq: isArbitration },
                },
                {
                  inDispute: { _eq: inDispute },
                },
              ],
            },
          ],
        },
      },
      ...(objectId
        ? [{
          objectContract: { object: { id: { _eq: objectId } } },
        }]
        : []),
    ],
  };

  if (search && search !== '') {
    whereQuery._and.push({
      _or: [
        { subject: { _ilike: search } },
        { customerOrganizationName: { _ilike: search } },
        { customerEmail: { _ilike: search } },
        { customerName: { _ilike: search } },
        { employeeOrganizationName: { _ilike: search } },
        { employeeEmail: { _ilike: search } },
        { employeeName: { _ilike: search } },
        { objectContract: { object: { name: { _ilike: search } } } },
        { vacancyType: { userRole: { name: { _ilike: search } } } },
      ],
    });
  }

  return whereQuery;
};

export const getPublicProfileContractsQuery = ({ employeeUserId, isCurrent, vacancyType }) => ({
  _and: [
    {
      employeeUserId: { _eq: employeeUserId },
    },
    {
      employeeUserId: { _is_null: false },
    },
    {
      isActive: { _eq: isCurrent },
    },
    {
      vacancyType: { _eq: vacancyType },
    },
  ],
});

export const getFullContractSubject = (isPerformer, subject, vacancyTypeName) =>
  isPerformer ? `Разработка ${subject}` : `${vacancyTypeName}: ${subject}`;
