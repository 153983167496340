import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import { change } from 'redux-form';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import 'cropperjs/dist/cropper.css';

import Modal from '../base/modal';
import Button from '../base/button';
import { ICONS } from '../../utils/icons';
import { getFileLink } from '../../utils/links';
import { DEFAULT_MAX_FILE_SIZE_MB } from '../../utils/file';
import { VALIDATION_MESSAGES } from '../../utils/validationMessages';
import { STORAGE_PATHS } from '../../utils/storagePaths';

import './index.scss';

const CROPPED_CANVAS_HEIGHT = 400;

class LoadImage extends PureComponent {
  constructor(props) {
    super(props);

    this.dropzoneRef = {};
    this.cropperRef = {};
    this.modalRef = React.createRef();
    this.state = {
      file: {},
      previewFile: null,
      isUploading: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps.input;
    const oldPreviewFile = prevState.previewFile || {};

    if (value.remotePath !== oldPreviewFile.remotePath) {
      return {
        ...prevState,
        previewFile: value,
      };
    }

    return null;
  }

  removeImage = () => {
    this.setState({
      file: null,
      previewFile: null,
    });
    this.change(null);
  };

  closeModal = () => {
    this.modalRef.current.toggleModal();
  };

  onDrop = acceptedFiles => {
    const file = acceptedFiles[0];

    if (!file) {
      return;
    }

    if (file.size > DEFAULT_MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.warn(VALIDATION_MESSAGES.fileShouldBeLessThan.replace('MAX_FILE_SIZE_MB', DEFAULT_MAX_FILE_SIZE_MB));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        file: {
          name: file.name,
          type: file.type,
          base64: reader.result,
        },
      });
      this.closeModal();
    };
    reader.readAsDataURL(file);
  };

  change = photo => {
    const { meta: { form, dispatch }, input: { name } } = this.props;

    dispatch(change(form, name, photo));
  };

  cropImage = async () => {
    const { file: { name, type } } = this.state;
    this.setState({ isUploading: true });
    const croppedCanvas = this.cropperRef.getCroppedCanvas({ height: CROPPED_CANVAS_HEIGHT });
    if (croppedCanvas && 'toDataURL' in croppedCanvas) {
      const cropResult = croppedCanvas.toDataURL();
      const file = await this.context.onFileUpload({ name, type, cropResult }, STORAGE_PATHS.profilePhoto);
      this.change(file);
    }
    this.setState({ isUploading: false });
    this.closeModal();
  };

  renderPreviewImage = () => {
    const { previewFile } = this.state;

    if (!previewFile) {
      return;
    }

    return <img className="preview" src={getFileLink(previewFile.remotePath)} alt="фото" />;
  };

  renderModal = () => {
    const { file, isUploading } = this.state;

    return <Modal
      className="cropper"
      onRef={this.modalRef}
      title="Загрузка изображения"
    >
      <>
        <Cropper
          src={file.base64}
          background={false}
          autoCropArea={1}
          aspectRatio={1}
          guides={false}
          ref={cropper => { this.cropperRef = cropper; }}
          style={{ height: CROPPED_CANVAS_HEIGHT, width: '100%' }}
        />
        <div className="actions">
          <Button
            className="secondary"
            onClick={this.closeModal}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            className="primary"
            onClick={this.cropImage}
            isLoading={isUploading}
            isLarge
          >
            Сохранить
          </Button>
        </div>
      </>
    </Modal>;
  };

  render() {
    const { isReadonly } = this.props;
    const { file, previewFile } = this.state;

    return <div className={classNames('image-loader-layout', { readonly: isReadonly })}>
      {isReadonly
        ? this.renderPreviewImage()
        : <>
          <Dropzone
            ref={ref => { this.dropzoneRef = ref; }}
            accept="image/*"
            noClick
            multiple={false}
            onDrop={this.onDrop}
          >
            {({ getRootProps, getInputProps }) => <section>
              <div {...getRootProps()}>
                <input id="profilePhoto" {...getInputProps()} />
                {this.renderPreviewImage()}
                {!previewFile && <p className="image-loader-text">
                  Перетащите сюда свою фотографию или нажмите на кнопку «загрузить»
                </p>}
                {!previewFile && <Button
                  onClick={this.dropzoneRef.open}
                >
                  Загрузить
                </Button>}
              </div>
            </section>}
          </Dropzone>
          {previewFile && <ICONS.trash className="remove-icon" onClick={this.removeImage} />}
          {file && this.renderModal()}
        </>}
    </div>;
  }
}

LoadImage.contextTypes = {
  onFileUpload: PropTypes.func,
};

LoadImage.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
  }).isRequired,
  isReadonly: PropTypes.bool,
};

LoadImage.defaultProps = {
  isReadonly: false,
};

export default LoadImage;
