import { compose } from 'recompose';
import { matchPath } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';

import BlockedObject from './index';
import { ROUTES } from '../../../utils/routes';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';

const GET_BLOCKED_OBJECT_QUERY = loader('../../../graphql/queries/object/getBlockedObject.graphql');

const withGetBlockedObjectQuery = graphql(GET_BLOCKED_OBJECT_QUERY, {
  name: 'getBlockedObject',
  skip: ({ location: { pathname } }) => !matchPath(pathname, ROUTES.blockedObject)?.params?.id,
  options: ({ location: { pathname } }) => ({
    variables: {
      id: matchPath(pathname, ROUTES.blockedObject).params.id,
    },
  }),
  props: ({ getBlockedObject: { object = [{}], error, loading }, ownProps: { location: { pathname } } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const { userTariffPlan, isTariffActive = false } = object[0];

    return {
      isLoading: loading,
      isTariffActive,
      objectId: matchPath(pathname, ROUTES.blockedObject).params.id,
      tariffPlan: userTariffPlan?.name ? userTariffPlan.name.match(/(«.*?»)/g)[0] : null,
    };
  },
});

export default compose(
  withGetBlockedObjectQuery,
)(BlockedObject);
