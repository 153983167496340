import { loader } from 'graphql.macro';
import { getObjectMenuQuery, getProjectComponentChildrenQuery } from '../model/object';
import { showError } from '../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import { OBJECT_PROJECT_COMPONENT_ORDER } from '../model/projectComponent';
import { withResubscribe } from '../utils';

const GET_OBJECT_STAGES_REFERENCES_QUERY = loader('../../graphql/queries/object/getObjectStagesReferences.graphql');

const getObjectMenuItems = projectComponents => (projectComponents || [])
  .map(({ id, projectComponentId, templateProjectComponent: { icon }, generatedCode, children }) => ({
    id,
    projectComponentId,
    name: generatedCode && (generatedCode.processedCode || generatedCode.processedName),
    icon,
    order: generatedCode?.order,
    level: generatedCode?.level,
    children: getObjectMenuItems(children),
  }));

export default withResubscribe(GET_OBJECT_STAGES_REFERENCES_QUERY, {
  name: 'getObjectStagesReferences',
  shouldResubscribe: true,
  skip: ({ pathParams, isSkip }) => isSkip || !pathParams || !pathParams.id,
  options: ({ pathParams, userId }) => ({
    variables: {
      id: Number(pathParams.id),
      where: getObjectMenuQuery(userId),
      projectComponentChildrenWhere: getProjectComponentChildrenQuery(userId),
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
  }),
  props: ({ getObjectStagesReferences: { object = [], error, loading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const objectMenuItems = getObjectMenuItems(object[0]?.projectComponents);

    return {
      isStagesLoading: loading,
      isTariffActive: object[0]?.isTariffActive,
      objectMenuItems,
      objectUserId: object[0]?.userId,
      chiefEngineerStages: object[0]?.projectComponents
        .filter(({ vacancies }) => vacancies.length > 0)
        .map(({ id, vacancies }) => ({
          id,
          employeeUserId: vacancies[0].contract.employeeUserId,
        })) || [],
    };
  },
});
