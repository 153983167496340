import React, { PureComponent } from 'react';

import './index.scss';

export default class NotFound extends PureComponent {
  render() {
    return <div className="not-found-container">
      <h1>Страница не найдена!</h1>
    </div>;
  }
}
