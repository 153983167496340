import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tree, TreeNode } from 'react-organizational-chart';
import StageTreeItem from './item';
import Button from '../../../../base/button';
import { ICONS } from '../../../../../utils/icons';
import { isMacOs } from '../../../../../utils/browser';

import './index.scss';

const MIN_LINE_WIDTH = '1px';
const MAX_LINE_WIDTH = '2px';
const LINE_BORDER_RADIUS = '5px';
const LINE_COLOR = '#F28536';
const MIN_ZOOM = 90;
const MAX_ZOOM = 160;
const ZOOM_STEP = 10;
const ZOOM_POWER_DIVIDER = 6;

export default class ProjectCompositionTree extends Component {
  state = {
    zoom: this.props.defaultZoom,
  };

  pressedScroll = false;
  startMouseX = 0;
  startMouseY = 0;
  initialZoomForGesture = this.state.zoom;

  componentDidMount() {
    const options = { passive: false };
    this.scrollerNode.addEventListener('gesturestart', this.handleGestureStart, options);
    this.scrollerNode.addEventListener('gestureend', this.handleGestureChange, options);
    this.scrollerNode.addEventListener('gesturechange', this.handleGestureChange, options);
    this.scrollerNode.addEventListener('wheel', this.handleMouseWheel, options);
    this.scrollerNode.addEventListener('mousewheel', this.handleMouseWheel, options);
    this.scrollerNode.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  componentWillUnmount() {
    this.scrollerNode.removeEventListener('gesturestart', this.handleGestureStart);
    this.scrollerNode.removeEventListener('gestureend', this.handleGestureChange);
    this.scrollerNode.removeEventListener('gesturechange', this.handleGestureChange);
    this.scrollerNode.removeEventListener('wheel', this.handleMouseWheel);
    this.scrollerNode.removeEventListener('mousewheel', this.handleMouseWheel);
    this.scrollerNode.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleRef = node => {
    this.scrollerNode = node;
  };

  handleMouseDown = event => {
    this.startMouseX = event.clientX;
    this.startMouseY = event.clientY;
    this.pressedScroll = true;

    document.body.style.cursor = 'move';
    event.preventDefault();
  };

  handleMouseMove = event => {
    if (this.pressedScroll) {
      const { zoom } = this.state;
      const endX = event.clientX;
      const endY = event.clientY;
      const deltaX = (this.startMouseX - endX) / (zoom / 100);
      const deltaY = (this.startMouseY - endY) / (zoom / 100);

      this.scrollerNode.scrollLeft = this.scrollerNode.scrollLeft + deltaX;
      this.scrollerNode.scrollTop = this.scrollerNode.scrollTop + deltaY;
      this.startMouseX = endX;
      this.startMouseY = endY;
      event.preventDefault();
    }
  };

  handleMouseUp = event => {
    if (this.pressedScroll) {
      this.pressedScroll = false;
      document.body.style.cursor = '';
      event.preventDefault();
    }
  };

  handleGestureStart = event => {
    this.initialZoomForGesture = this.state.zoom;
    this.handleGestureChange(event);
  };

  handleGestureChange = event => {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.setZoom(this.initialZoomForGesture * event.scale);
  };

  handleMouseWheel = event => {
    if (event.ctrlKey || !isMacOs()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.setZoom(this.state.zoom - event.deltaY / ZOOM_POWER_DIVIDER);
    }
  };

  setZoom = zoom => {
    if (zoom < MIN_ZOOM) {
      zoom = MIN_ZOOM;
    } else if (zoom > MAX_ZOOM) {
      zoom = MAX_ZOOM;
    }

    this.setState({ zoom });
  };

  addZoom = () => {
    this.setZoom(this.state.zoom + ZOOM_STEP);
  };

  reduceZoom = () => {
    this.setZoom(this.state.zoom - ZOOM_STEP);
  };

  renderTreeItem = (data, level, parentProjectComponent) => {
    const { onClickAdd, onClickEdit, onClickRemove } = this.props;

    return <StageTreeItem
      data={data}
      parentProjectComponent={parentProjectComponent}
      level={level}
      onClickAdd={onClickAdd}
      onClickRemove={onClickRemove}
      onClickEdit={onClickEdit}
    />;
  };

  renderChild = (item, level, parentProjectComponent) => <TreeNode
    key={item.id}
    label={this.renderTreeItem(item, level, parentProjectComponent)}
  >
    {item.projectComponents && item.projectComponents.map(child => this.renderChild(child, level + 1, item))}
  </TreeNode>;

  renderRoot = item => <Tree
    key={item.id}
    lineWidth={this.state.zoom > MIN_ZOOM ? MIN_LINE_WIDTH : MAX_LINE_WIDTH}
    lineColor={LINE_COLOR}
    lineBorderRadius={LINE_BORDER_RADIUS}
    label={this.renderTreeItem(item, 1, this.props.parentProjectComponent)}
  >
    {item.projectComponents && item.projectComponents.map(child => this.renderChild(child, 2, item))}
  </Tree>;

  renderAddStageChild = () => {
    const { onClickAddStageChild, parentProjectComponent: { childType: { name: childTypeName } } } = this.props;
    return <div className="tree-item-wrapper add-section">
      <Button
        onClick={onClickAddStageChild}
        IconComponent={ICONS.circlePlus}
        isBorderless
      >
        Добавить {childTypeName}
      </Button>
    </div>;
  };

  render() {
    const { items } = this.props;
    const { zoom } = this.state;
    const scale = zoom / 100;
    const areaStyle = {
      zoom: scale,
      MozTransform: `scale(${scale})`,
    };

    return <div className="tree-wrapper">
      <div className="tree-scroller" ref={this.handleRef}>
        <div className="tree-area" style={areaStyle}>
          {items.map(this.renderRoot)}
          <Tree label={this.renderAddStageChild()} />
        </div>
      </div>
      <div className="zoom-panel">
        <Button
          onClick={this.addZoom}
          IconComponent={ICONS.zoomPlus}
          isDisabled={zoom >= MAX_ZOOM}
          isBorderless
        />
        <Button
          onClick={this.reduceZoom}
          IconComponent={ICONS.zoomMinus}
          isDisabled={zoom <= MIN_ZOOM}
          isBorderless
        />
      </div>
    </div>;
  }

  static propTypes = {
    items: PropTypes.array,
    parentProjectComponent: PropTypes.object,
    defaultZoom: PropTypes.number,
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickAddStageChild: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    parentProjectComponent: {},
    defaultZoom: 100,
    onClickAdd() {},
    onClickEdit() {},
    onClickRemove() {},
    onClickAddStageChild() {},
  };
}
