export const CONTRACT_STATUSES = {
  awaitingPayment: {
    id: 1,
    name: 'Ожидание оплаты',
    kind: 'neutral',
    isActive: true,
    isArbitration: false,
  },
  activePaid: {
    id: 2,
    name: 'Действующий оплаченный',
    kind: 'positive',
    isActive: true,
    isArbitration: false,
  },
  doneInTime: {
    id: 3,
    name: 'Выполнен в срок',
    kind: 'positive',
    isActive: false,
    isArbitration: false,
  },
  doneLate: {
    id: 4,
    name: 'Выполнен c опозданием',
    kind: 'negative',
    isActive: false,
    isArbitration: false,
  },
  terminatedByMutualAgreement: {
    id: 5,
    name: 'Расторгнут по обоюдному согласию',
    kind: 'neutral',
    isActive: false,
    isArbitration: false,
  },
  inArbitration: {
    id: 6,
    name: 'В процессе спора',
    kind: 'negative',
    isActive: false,
    isArbitration: true,
  },
  terminatedByArbitration: {
    id: 7,
    name: 'Расторгнут по итогам спора',
    kind: 'negative',
    isActive: false,
    isArbitration: false,
  },
  notPaidInTime: {
    id: 8,
    name: 'Не выполнены обязательства по оплате',
    kind: 'negative',
    isActive: false,
    isArbitration: false,
  },
  active: {
    id: 9,
    name: 'Не выполнены обязательства по оплате',
    kind: 'positive',
    isActive: true,
    isArbitration: false,
  },
  inTerminationProcess: {
    id: 10,
    name: 'В процессе расторжения',
    kind: 'negative',
    isActive: true,
    isArbitration: false,
  },
  suspended: {
    id: 11,
    name: 'Приостановлен',
    kind: 'neutral',
    isActive: true,
    isArbitration: false,
  },
};
