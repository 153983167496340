import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getFileLink } from '../../../utils/links';
import Button from '../button';
import { ICONS } from '../../../utils/icons';
import DataGroup from '../dataGroup';

import './index.scss';

export default class FileLink extends PureComponent {
  render() {
    const { title, file, onRemoveFile, onCopyFile, showTitle, showRemoveButton } = this.props;
    const { id, fileId, title: fileTitle, remotePath, originalName } = file;

    return <div className="file-link">
      <DataGroup title={title}>
        <a
          href={getFileLink(remotePath)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {showTitle ? fileTitle || originalName : originalName}
        </a>
      </DataGroup>
      {onCopyFile && <Button
        id="copyFileButton"
        className="btn-copy-file"
        onClick={() => onCopyFile(fileId || id)}
        IconComponent={ICONS.copy}
        isBorderless
      />}
      {onRemoveFile && showRemoveButton && <Button
        id="removeFileButton"
        className="btn-remove-file"
        onClick={() => onRemoveFile(file)}
        IconComponent={ICONS.trash}
        isBorderless
      />}
    </div>;
  }

  static propTypes = {
    file: PropTypes.object.isRequired,
    title: PropTypes.string,
    onRemoveFile: PropTypes.func,
    onCopyFile: PropTypes.func,
    showTitle: PropTypes.bool,
    showRemoveButton: PropTypes.bool,
  };

  static defaultProps = {
    onRemoveFile: null,
    onCopyFile: null,
    showTitle: false,
    showRemoveButton: true,
    title: '',
  };
}
