import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from '../../../base/modal';
import Button from '../../../base/button';
import { ICONS } from '../../../../utils/icons';
import { makeCancellable } from '../../../../utils/promise';

import '../index.scss';

const emptyFunc = () => {};

const OPTION_ICONS = [
  ICONS.check,
  ICONS.notInterested,
  ICONS.plus,
];

export default class Confirm3OptionsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const buttons = {};
    props.options.forEach((option, index) => {
      buttons[`button${index}IsLoading`] = false;
    });

    this.state = {
      ...buttons,
    };

    this.cancellablePromise = null;
  }

  componentWillUnmount() {
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  }

  handleClick = (action, index) => () => {
    const isLoadingKey = `button${index}IsLoading`;
    this.setState({ [isLoadingKey]: true });
    this.cancellablePromise = makeCancellable(action());
    return this.cancellablePromise
      .promise
      .then(() => {
        this.setState({ [isLoadingKey]: false });
      })
      .catch(error => {
        if (error.isCancelled) {
          return null;
        }
      });
  };

  renderOptions() {
    return this.props.options.map(({ buttonTitle, hint, action = emptyFunc }, index) => <React.Fragment key={index}>
      <Button
        isLarge
        isFilled={index === 0}
        IconComponent={OPTION_ICONS[index]}
        isLoading={this.state[`button${index}IsLoading`]}
        onClick={this.handleClick(action, index)}
      >
        {buttonTitle}
      </Button>
      {hint && <p className="hint">{hint}</p>}
    </React.Fragment>);
  }

  render() {
    const { title, description, ...props } = this.props;

    return <Modal
      className="confirm-2-options-modal confirm-3-options-modal"
      {...props}
    >
      <h2 className={classNames({ 'margin-bottom': !description })}>{title}</h2>
      {description && <p className="description">{description}</p>}
      {this.renderOptions()}
    </Modal>;
  }

  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    description: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      buttonTitle: PropTypes.string.isRequired,
      hint: PropTypes.string,
      action: PropTypes.func,
    })).isRequired,
  };

  static defaultProps = {
    description: '',
  };
}
