import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';

import FormGroupField from '../../../base/formGroup';
import LoadImage from '../../../loadImage';
import FullName from '../../../base/fullName';
import Button from '../../../base/button';
import RestrictedInformation from '../../restrictedInformation';

import './index.scss';

class PersonalData extends PureComponent {
  title = 'Личные данные';

  renderContent() {
    const { isPhoneConfirmed, isReadonly } = this.props;

    return <>
      <FullName />
      <Field
        name="phone"
        component={FormGroupField}
        type="text"
        label="Контактный телефон"
        mask="+7 (999)-999-9999"
        isReadonly={isReadonly}
        {...(!isPhoneConfirmed && { inputWarning: 'Не подтвержден' })}
      />
      <div id="local-time-button" className="form-group-field form-group additional-button">
        <Button>Показать местное время пользователя</Button>
      </div>
    </>;
  }

  render() {
    const { isReadonly, isPrivate } = this.props;

    if (isPrivate) {
      return <RestrictedInformation title={this.title} />;
    }

    return <div className={classNames('form-card personal-data-layout', { readonly: isReadonly })}>
      <Field
        name="photo"
        component={LoadImage}
        type="text"
        label="Имя"
        isReadonly={isReadonly}
      />
      <h5>{this.title}</h5>
      {this.renderContent()}
    </div>;
  }
}

PersonalData.propTypes = {
  isReadonly: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isPhoneConfirmed: PropTypes.bool,
  className: PropTypes.string,
};

PersonalData.defaultProps = {
  isReadonly: false,
  isPhoneConfirmed: false,
  className: '',
  isPrivate: false,
};

export default PersonalData;
