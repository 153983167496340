import { compose, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import ChangeExpertiseStatusModal from './index';

const INSERT_PROJECT_COMPONENT_EXPERTISE_MUTATION = loader('../../../../graphql/queries/object/insertProjectComponentExpertise.graphql');

const withInsertProjectComponentExpertiseMutation = graphql(INSERT_PROJECT_COMPONENT_EXPERTISE_MUTATION, {
  name: 'insertProjectComponentExpertise',
});

const mergeProps = withProps(({ projectComponent: { versionId, name, fileId }, insertProjectComponentExpertise }) => ({
  title: name || '',
  updateExpertise: isPositive => insertProjectComponentExpertise({
    variables: {
      expertise: {
        objectExpertiseFileId: fileId,
        objectProjectComponentVersionId: versionId,
        isPositive,
      },
    },
  }),
}));

export default compose(
  withInsertProjectComponentExpertiseMutation,
  mergeProps,
)(ChangeExpertiseStatusModal);
