import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormGroupField from '../../../base/formGroup';
import Hint from '../../../base/hint';
import PaymentTerms from '../paymentTerms';
import { getFormattedNumber } from '../../../../utils/numbers';

import './index.scss';

export default class WorkConditions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPhasesTermsOfPayment: props.initialValues?.paymentTerms?.usePhases || false,
      isExpertiseRequired: props.initialValues?.paymentTerms?.isExpertiseRequired || false,
    };
  }

  toggleTermsOfPayment = showPhasesTermsOfPayment => {
    this.setState({
      showPhasesTermsOfPayment,
    });
  };

  toggleIsExpertiseRequired = isExpertiseRequired => {
    const { change } = this.props;

    this.setState({
      isExpertiseRequired,
    }, () => {
      // empty string removes «key: value» from redux form state
      change('paymentTerms.phase2', isExpertiseRequired ? {} : '');
    });
  };

  getTotalAmountLabel = () => {
    const { isCompetition, isPerformer } = this.props;
    const { showPhasesTermsOfPayment } = this.state;
    if (isCompetition) {
      return isPerformer ? <>
        Начальная ставка (сумма договора)
        <Hint title="Размер гонорара">
          Общая сумма договора за всю поручаемую специалисту работу
        </Hint>
      </> : <>
        Начальная ставка (сумма с которой начинается конкурс)
        <Hint title="Начальная ставка">
          Нулевое значение запускает процедуру конкурса “Предложение лучшей цены“
        </Hint>
      </>;
    } else if (isPerformer) {
      return 'Стоимость работ (сумма договора)';
    } else {
      return showPhasesTermsOfPayment ? 'Сумма договора (размер гонорара за всю работу)' : 'Размер ежемесячного оклада';
    }
  };

  render() {
    const {
      taxSystems,
      admissionTypes,
      isCompetition,
      isPerformer,
      paymentTermsProps,
      isReadonly,
      isEngineeringSurveyStage,
      selectedStageDeadline,
      projectComponentName,
    } = this.props;
    const { showPhasesTermsOfPayment, isExpertiseRequired } = this.state;

    return <div className="work-conditions">
      <h5>Условия работы</h5>
      <div className="form-line">
        <span>Защита в экспертизе</span>
        <Field
          name="paymentTerms.isExpertiseRequired"
          component={FormGroupField}
          labelLeft={<>
            Не требуется
            <Hint title="Защита в экспертизе">
              Позиция “не требуется” отключает Этап II и все взаимосвязанные с ним пункты договора.
              По завершению Этапа I сразу наступает Этап III.
            </Hint>
          </>}
          labelRight="Требуется"
          type="switch"
          isDisabled={isReadonly}
          onChange={this.toggleIsExpertiseRequired}
          isChecked={isExpertiseRequired}
        />
      </div>
      <div className="form-line">
        <span>Ответственность за достаточность и достоверность собранных исходных данных</span>
        <Field
          name="paymentTerms.special.isCustomersInitialDataResponsibility"
          component={FormGroupField}
          labelLeft={<>
            На специалисте
            <Hint title="Ответственность за достаточность и достоверность собранных исходных данных">
              В данном пункте оговаривается ответственность сторон в случае возникновения последствий связанных с
              комплектностью и достоверностью исходных данных.
            </Hint>
          </>}
          labelRight="На заказчике"
          type="switch"
          isDisabled={isReadonly}
        />
      </div>
      <div className="form-line">
        <span>Ответственность за согласования выполненных разделов со службами</span>
        <Field
          name="paymentTerms.special.isCustomersCoordinationWithServicesResponsibility"
          component={FormGroupField}
          labelLeft="На специалисте"
          labelRight="На заказчике"
          type="switch"
          isDisabled={isReadonly}
        />
      </div>
      <div className="form-line">
        <span>Оплата работы</span>
        <Field
          name="isSafeDeal"
          component={FormGroupField}
          labelLeft={<>
            Напрямую
            <Hint title="Оплата работы">
              Работа через депозит подразумевает “Безопасную сделку”, в которой заказчик перечисляет 100% аванс
              на счет Платформы, а исполнитель получает оплату в полном объёме только в случае выполнения всех
              своих обязательств. При выборе такого варианта заказчик сохраняет свои средства, а исполнитель имеет
              дополнительную мотивацию.<br /><br />
              * Функция оплаты напрямую временно заблокирована.
              Если у вас есть производственная необходимость в её разблокировке, просим подать заявку на contact@pir-s.cloud
            </Hint>
          </>}
          labelRight="Депозит"
          type="switch"
          isDisabled
        />
      </div>
      {!isPerformer && <div className="form-line">
        <span>Условия оплаты</span>
        <Field
          name="paymentTerms.usePhases"
          component={FormGroupField}
          labelLeft={<>
            Ежемесячно
            <Hint title="Условия оплаты">
              Аванс должен быть перечислен заказчиком перед началом будущего периода иначе действие договора
              приостанавливается.<br />
              Два варианта:<br />
              1. Ежемесячно оговоренный оклад.<br />
              2. Распределенная по этапам оговоренная сторонами сумма гонорара.<br /><br />
              * Функция ежемесячной оплаты временно заблокирована. Если у вас есть производственная необходимость в
              её разблокировке, просим подать заявку на contact@pir-s.cloud
            </Hint>
          </>}
          labelRight="Этапы"
          type="switch"
          onChange={this.toggleTermsOfPayment}
          isDisabled
        />
      </div>}
      {!isPerformer && <div className="form-line">
        <span>Оплата коммандировочных</span>
        <Field
          name="paymentTerms.special.isCustomersRepaymentOfBusinessTrips"
          component={FormGroupField}
          labelLeft={<>
            Влючены в расценку
            <Hint title="Оплата командировочных">
              Если оплата командировочных не включена в расценку, заказчик берёт на себя обязательства
              компенсировать 100% понесенных специалистом расходов на проживание, питание и транспорт при исполнении
              обязательств по заключенному договору. Заказчик обязуется в течении 3-х банковских дней с момента
              предоставления специалистом подтверждающих квитанций, путём загрузки их в раздел документации
              соответствующего договора, перечислить необходимую сумму на счет специалиста. При отсутствии
              подтверждающих квитанций заказчик оставляет за собой право не оплачивать понесенные расходы.
              В случае возникновения каких-либо споров, стороны должны руководствоваться законными нормами и правилами.
            </Hint>
          </>}
          labelRight="На заказчике"
          type="switch"
          isDisabled={isReadonly}
        />
      </div>}
      <div className="form-line">
        <span>Источник финансирования</span>
        <Field
          name="paymentTerms.special.isPrivateSourceOfFunding"
          component={FormGroupField}
          labelLeft={<>
            Бюджетные деньги
            <Hint title="Источник финансирования">
              Данный пункт даёт возможность нанимаемой стороне понимать степень сложности предстоящих работ
              и формат требований, предъявляемых к документации.
            </Hint>
          </>}
          labelRight="Частный"
          type="switch"
          isDisabled={isReadonly}
        />
      </div>
      {isPerformer && <div className="form-line">
        <span>{isEngineeringSurveyStage ? 'Исполнительная смета' : 'Ведомости объёмов работ'}</span>
        <Field
          name="paymentTerms.special.isNotWorkSheetRequired"
          component={FormGroupField}
          labelLeft={<>
            Требуются
            <Hint title={isEngineeringSurveyStage ? 'Исполнительная смета' : 'Ведомости объёмов работ'}>
              В данном пункте оговаривается необходимость&nbsp;
              {isEngineeringSurveyStage ? 'исполнительной сметы' : 'ведомостей объёмов работ'}
            </Hint>
          </>}
          labelRight="Не требуются"
          type="switch"
          isDisabled={isReadonly}
        />
      </div>}
      <Field
        name="totalAmount"
        component={FormGroupField}
        type={isReadonly ? 'text' : 'number'}
        min={0}
        maxLength={10}
        label={this.getTotalAmountLabel()}
        {...(isReadonly && { format: value => value ? getFormattedNumber(value) : '0' })}
        placeholder="Ввести значение"
        isDisabled={isReadonly}
        isBordered={isReadonly}
      />
      <Field
        name="paymentTerms.phase1.deadline"
        component={FormGroupField}
        type="date"
        label={<>
          Дата окончания Этапа I, Прием-передача выполненных работ, не позднее
          <Hint>{`Дата окончания Этапа I не может быть позднее срока выполнения работ по ${projectComponentName || 'стадии'}`}</Hint>
        </>}
        isMultiline
        isDisabled={isReadonly}
        minDate={new Date()}
        maxDate={selectedStageDeadline}
      />
      {isCompetition && <>
        <h6>Требования к участникам</h6>
        <Field
          name="competitionTerms.taxSystems"
          component={FormGroupField}
          type="select"
          data={taxSystems}
          isMultiSelect
          label={<>
            Системы налогообложения соискателей, допущенные к участию
            <Hint title="Ограничения по системе налогооблажения">
              Данный пункт оговаривает ограничения для участников.
              Принять участие смогут лишь те, у кого в реквизитах прописаны указанные системы налогообложения.
            </Hint>
          </>}
          placeholder={isReadonly ? 'нет' : ''}
          isDisabled={isReadonly}
        />
        <Field
          name="competitionTerms.minRating"
          component={FormGroupField}
          type="rating"
          label={<>
            Ограничение по рейтингу соискателей
            <Hint title="Ограничения по рейтингу">
              Данный пункт оговаривает размер ограничения для участников, которые не имеют соответствующего рейтинга.
            </Hint>
          </>}
          isDisabled={isReadonly}
        />
        <Field
          name="competitionTerms.admissionTypes"
          component={FormGroupField}
          type="select"
          data={admissionTypes}
          isMultiSelect
          label={<>
            Обязательное наличие допусков к определенным видам работ
            <Hint title="Требования к наличию допусков">
              Данный пункт оговаривает требования к обязательному наличию у участников допусков к
              определенным видам работ.
            </Hint>
          </>}
          placeholder={isReadonly ? 'нет' : ''}
          isDisabled={isReadonly}
        />
      </>}
      <PaymentTerms
        showPhasesTermsOfPayment={showPhasesTermsOfPayment}
        isExpertiseRequired={isExpertiseRequired}
        isPerformer={isPerformer}
        isReadonly={isReadonly}
        isCompetition={isCompetition}
        paymentTermsProps={paymentTermsProps}
      />
    </div>;
  }

  static propTypes = {
    isCompetition: PropTypes.bool,
    taxSystems: PropTypes.array,
    admissionTypes: PropTypes.array,
    isPerformer: PropTypes.bool,
    isReadonly: PropTypes.bool,
    paymentTermsProps: PropTypes.object,
    initialValues: PropTypes.object,
    isEngineeringSurveyStage: PropTypes.bool.isRequired,
    selectedStageDeadline: PropTypes.instanceOf(Date),
    projectComponentName: PropTypes.string,
    change: PropTypes.func.isRequired,
  };

  static defaultProps = {
    taxSystems: [],
    admissionTypes: [],
    isCompetition: false,
    isPerformer: false,
    isReadonly: false,
    paymentTermsProps: null,
    initialValues: null,
    isEngineeringSurveyStage: false,
    selectedStageDeadline: null,
    projectComponentName: '',
  };
}
