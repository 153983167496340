import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import noop from 'lodash/noop';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import Hint from '../../base/hint';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class WithdrawalRequestModal extends React.PureComponent {
  state = {
    isDocumentRequired: this.props.initialValues.isDocumentRequired,
  };

  handleClose = () => {
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleChangeDocumentRequired = isDocumentRequired => {
    this.setState({ isDocumentRequired });
  };

  onSubmit = async data => {
    this.props.onCreateWithdrawal(data).then(() => {
      this.handleCancel();
    }).catch(noop);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      ...props
    } = this.props;
    return <Modal
      {...props}
      className="withdrawal-operation-modal"
      title="Заявление на вывод средств"
      onClose={this.handleClose}
    >
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="date"
          component={FormGroupField}
          type="text"
          label="Дата выставления"
          isDisabled
        />
        <Field
          name="withdrawalAmount"
          component={FormGroupField}
          type="number"
          label="Сумма"
        />
        <label>Закрывающие (бухгалтерские) документы</label>
        <Field
          name="isDocumentRequired"
          component={FormGroupField}
          type="switch"
          labelLeft={<>
            Не требуются
            <Hint title="Отказ от бумажной версии бухгалтерских документов">
              По умолчанию ПИР-Система использует электронный документооборот и обмен бумажными версиями не осуществляет
            </Hint>
          </>}
          labelRight={<>
            Требуются
            <Hint title="Запрос бухгалтерских документов">
              Закрывающие документы будут отправлены по указанному адресу
            </Hint>
          </>}
          onChange={this.handleChangeDocumentRequired}
        />
        {this.state.isDocumentRequired && <Field
          name="documentAddress"
          component={FormGroupField}
          type="text"
          label="Адрес для отправки закрывающих документов (обязательно)"
        />}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Отправить
          </Button>
          <Button
            id="attachOfficialLetter"
            onClick={noop}
            IconComponent={ICONS.clip}
            isBorderless
          >
            Прикрепить официальное письмо
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    onCreateWithdrawal: PropTypes.func.isRequired,
    onRef: PropTypes.object,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
  };
}
