export const getFullName = ({ lastName, firstName, patronymic }) => {
  const fullName = [];

  if (lastName) {
    fullName.push(lastName);
  }

  if (firstName) {
    fullName.push(firstName);
  }

  if (patronymic) {
    fullName.push(patronymic);
  }
  return fullName.join(' ');
};

const getInitial = name => name ? name[0] + '.' : '';

export const getNameWithInitials = ({ lastName, firstName, patronymic, email }) => {
  if (!(firstName || lastName || patronymic)) {
    return email;
  }

  if (!lastName) {
    return getFullName({ firstName, patronymic });
  }

  return `${lastName} ${getInitial(firstName)}${getInitial(patronymic)}`;
};

export const getContractorName = (contractor, isFull = false) => {
  const { isOrganization, name: organizationName } = contractor;
  let name;
  if (isOrganization) {
    name = organizationName;
  } else if (isFull) {
    name = getFullName(contractor);
  } else {
    name = getNameWithInitials(contractor);
  }
  return name;
};
