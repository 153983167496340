import { matchPath, withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import ObjectMenu from './index';
import { BACK_BUTTON_TYPE, getStagePath, OBJECT_MENU_NAME, MENU_ITEMS, REMARKS_MENU_NAME } from '../../utils/menu';
import { ROUTES } from '../../utils/routes';

import withObjectStagesReferencesQuery from '../../graphql/hoc/objectStagesReferences';
import withExpertUserReferencesQuery from '../../graphql/hoc/expertUserReferences';

const mergeProps = withProps(({
  location,
  history,
  objectUserId,
  isRemarks,
  pathParams,
  userId,
  objectMenuItems,
  chiefEngineerStages,
  isStagesLoading,
  onClickMenuItem,
  isTariffActive,
  isExpert = false,
}) => ({
  objectId: pathParams?.id,
  isTariffActive: !!isTariffActive || isExpert,
  objectMenuItems: isRemarks && objectMenuItems.length > 0
    ? objectMenuItems.find(menuItem => menuItem.id === Number(pathParams.stageId)).children
    : objectMenuItems,
  location,
  isLoading: isStagesLoading,
  stagePath: !isStagesLoading && !!matchPath(location.pathname, { path: ROUTES.stages, exact: true })
    ? getStagePath(Object.assign(pathParams, { stageId: objectMenuItems[0].id }))
    : '',
  items: isRemarks
    ? [
      {
        ...MENU_ITEMS[REMARKS_MENU_NAME][0],
        route: ROUTES.projectComponent
          .replace(':id', pathParams.id)
          .replace(':stageId', pathParams.stageId)
          .replace(':projectComponentId', pathParams.projectComponentId),
      },
    ]
    : MENU_ITEMS[OBJECT_MENU_NAME].filter(({ type, component, isForOwnerOnly, isForOwnerAndChiefOnly }) => {
      const isAllowed = !(isForOwnerOnly || isForOwnerAndChiefOnly) || objectUserId === userId;
      const isAllowedForChief = !isForOwnerAndChiefOnly || !!chiefEngineerStages
        .find(({ employeeUserId }) => employeeUserId === userId);
      return (isAllowed || isAllowedForChief) && !!component || type === BACK_BUTTON_TYPE;
    }),
  goTo: id => () => {
    if (onClickMenuItem) {
      onClickMenuItem();
    }

    if (isRemarks ? id === Number(pathParams.projectComponentId) : id === Number(pathParams.stageId)) {
      return;
    }

    history.replace(
      getStagePath(Object.assign(pathParams, isRemarks ? { projectComponentId: id, remarkId: null } : { stageId: id }), isRemarks),
    );
  },
  getStatus: id => Number(isRemarks
    ? pathParams.projectComponentId
    : matchPath(location.pathname, ROUTES.stage)?.params.stageId,
  ) === id,
}));

const mapStateToProps = ({ session }, { location: { pathname } }) => {
  const remarksPath = matchPath(pathname, [ROUTES.projectComponentRemark, ROUTES.projectComponentRemarks]);

  return {
    userId: session.user.id,
    isRemarks: !!remarksPath,
    pathParams: (remarksPath || matchPath(pathname, ROUTES.object)).params,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withExpertUserReferencesQuery,
  withObjectStagesReferencesQuery,
  mergeProps,
)(ObjectMenu);
