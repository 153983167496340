import { compose, withProps } from 'recompose';

import ProjectComponentFiles from './index';

import withObjectFilesMutationReferences from '../../../../../../graphql/hoc/objectFilesMutationReferences';

export default compose(
  withProps(({ entityId }) => ({
    objectProjectComponentId: entityId,
  })),
  withObjectFilesMutationReferences,
)(ProjectComponentFiles);
