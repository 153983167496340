import React, { useContext, useState, useRef, useEffect } from 'react';
import { parser, getApolloContext } from '@apollo/react-common';
import {
  calculateVariablesFromProps,
  defaultMapPropsToOptions,
  defaultMapPropsToSkip,
  getDisplayName,
  GraphQLBase,
} from '@apollo/react-hoc/lib/hoc-utils';
import { __assign, __extends } from 'tslib';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { equal as isEqual } from '@wry/equality';
import { invariant } from 'ts-invariant';
import cloneDeep from 'lodash/cloneDeep';

const OperationData = (function () {
  function OperationData(options, context) {
    this.isMounted = false;
    this.previousOptions = {};
    this.options = options || {};
    this.context = context || {};
  }
  OperationData.prototype.getOptions = function () {
    return this.options;
  };
  OperationData.prototype.setOptions = function (newOptions, storePrevious) {
    if (storePrevious === undefined) {
      storePrevious = false;
    }
    if (storePrevious && !isEqual(this.options, newOptions)) {
      this.previousOptions = this.options;
    }
    this.options = newOptions;
  };
  OperationData.prototype.unmount = function () {
    this.isMounted = false;
  };
  OperationData.prototype.refreshClient = function () {
    const client = (this.options && this.options.client) || (this.context && this.context.client);
    invariant(!!client, 'Could not find "client" in the context or passed in as an option. ' +
      'Wrap the root component in an <ApolloProvider>, or pass an ' +
      'ApolloClient instance in via options.');
    let isNew = false;
    if (client !== this.client) {
      isNew = true;
      this.client = client;
      this.cleanup();
    }
    return {
      client: this.client,
      isNew: isNew,
    };
  };
  return OperationData;
}());

const SubscriptionData = (function (_super) {
  __extends(SubscriptionData, _super);
  function SubscriptionData(props) {
    const { options, context, setResult } = props;
    const _this = _super.call(this, options, context) || this;
    _this.currentObservable = {};
    _this.setResult = setResult;
    _this.initialize(options);
    return _this;
  }
  SubscriptionData.prototype.execute = function (result) {
    if (this.getOptions().skip === true) {
      this.cleanup();
      return {
        loading: false,
        error: undefined,
        data: undefined,
        variables: this.getOptions().variables,
      };
    }
    let currentResult = result;
    if (this.refreshClient().isNew) {
      currentResult = this.getLoadingResult();
    }
    let shouldResubscribe = this.getOptions().shouldResubscribe;
    if (typeof shouldResubscribe === 'function') {
      shouldResubscribe = !!shouldResubscribe(this.getOptions());
    }
    if (shouldResubscribe !== false &&
      this.previousOptions &&
      Object.keys(this.previousOptions).length > 0 &&
      (this.previousOptions.subscription !== this.getOptions().subscription ||
        !isEqual(this.previousOptions.variables, this.getOptions().variables) ||
        this.previousOptions.skip !== this.getOptions().skip)
    ) {
      this.cleanup();
      currentResult = this.getLoadingResult();
    }
    this.initialize(this.getOptions());
    this.startSubscription();
    this.previousOptions = this.getOptions();

    return {
      ...currentResult,
      variables: this.getOptions().variables,
    };
  };
  SubscriptionData.prototype.afterExecute = function () {
    this.isMounted = true;
  };
  SubscriptionData.prototype.cleanup = function () {
    this.endSubscription();
    delete this.currentObservable.query;
  };
  SubscriptionData.prototype.initialize = function (options) {
    if (this.currentObservable.query || this.getOptions().skip === true) {
      return;
    }
    this.currentObservable.query = this.refreshClient().client.subscribe({
      query: options.subscription,
      variables: options.variables,
      fetchPolicy: options.fetchPolicy,
    });
  };
  SubscriptionData.prototype.startSubscription = function () {
    if (this.currentObservable.subscription) {
      return;
    }
    this.currentObservable.subscription = this.currentObservable.query.subscribe({
      next: this.updateCurrentData.bind(this),
      error: this.updateError.bind(this),
      complete: this.completeSubscription.bind(this),
    });
  };
  SubscriptionData.prototype.getLoadingResult = function () {
    return {
      loading: true,
      error: undefined,
      data: undefined,
    };
  };
  SubscriptionData.prototype.updateResult = function (result) {
    if (this.isMounted) {
      this.setResult(result);
    }
  };
  SubscriptionData.prototype.updateCurrentData = function (result) {
    const onSubscriptionData = this.getOptions().onSubscriptionData;
    this.updateResult({
      data: result.data,
      loading: false,
      error: undefined,
    });
    if (onSubscriptionData) {
      onSubscriptionData({
        client: this.refreshClient().client,
        subscriptionData: result,
      });
    }
  };
  SubscriptionData.prototype.updateError = function (error) {
    this.updateResult({
      error: error,
      loading: false,
    });
  };
  SubscriptionData.prototype.completeSubscription = function () {
    const onSubscriptionComplete = this.getOptions().onSubscriptionComplete;
    if (onSubscriptionComplete) {
      onSubscriptionComplete();
    }
    this.endSubscription();
  };
  SubscriptionData.prototype.endSubscription = function () {
    if (this.currentObservable.subscription) {
      this.currentObservable.subscription.unsubscribe();
      delete this.currentObservable.subscription;
    }
  };
  return SubscriptionData;
}(OperationData));

function useSubscription(subscription, { variables, ...options }) {
  const context = useContext(getApolloContext());
  const updatedOptions = {
    ...(options || {}),
    ...subscription,
    variables: cloneDeep(variables),
  };
  const [result, setResult] = useState({
    loading: !updatedOptions.skip,
    error: undefined,
    data: undefined,
  });
  const subscriptionDataRef = useRef();
  if (!subscriptionDataRef.current) {
    subscriptionDataRef.current = new SubscriptionData({
      options: updatedOptions,
      context: context,
      setResult: setResult,
    });
  }
  const subscriptionData = subscriptionDataRef.current;
  subscriptionData.setOptions(updatedOptions, true);
  subscriptionData.context = context;
  useEffect(() => subscriptionData.afterExecute());
  // eslint-disable-next-line
  useEffect(() => subscriptionData.cleanup.bind(subscriptionData), []);

  return subscriptionData.execute(result);
}

function Subscription(props) {
  const result = useSubscription(props.subscription, props);
  return props.children && result ? props.children(result) : null;
}
Subscription.propTypes = {
  subscription: PropTypes.object.isRequired,
  variables: PropTypes.object,
  children: PropTypes.func,
  onSubscriptionData: PropTypes.func,
  onSubscriptionComplete: PropTypes.func,
  shouldResubscribe: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export function withResubscribe(document, operationOptions = {}) {
  const operation = parser(document);
  const options = operationOptions.options ? operationOptions.options : defaultMapPropsToOptions;
  const skip = operationOptions.skip ? operationOptions.skip : defaultMapPropsToSkip;
  const alias = operationOptions.alias ? operationOptions.alias : 'Apollo';
  const shouldResubscribe = typeof operationOptions.shouldResubscribe === 'function'
    ? operationOptions.shouldResubscribe
    : () => operationOptions.shouldResubscribe;

  let mapPropsToOptions = options;
  if (typeof mapPropsToOptions !== 'function') {
    mapPropsToOptions = () => options;
  }

  let mapPropsToSkip = skip;
  if (typeof mapPropsToSkip !== 'function') {
    mapPropsToSkip = () => skip;
  }

  let lastResultProps;

  return function (WrappedComponent) {
    const graphQLDisplayName = alias + '(' + getDisplayName(WrappedComponent) + ')';
    const GraphQL = (function (_super) {
      __extends(GraphQL, _super);

      function GraphQL(props) {
        const _this = _super.call(this, props) || this;
        _this.state = { resubscribe: shouldResubscribe({}, props) };
        return _this;
      }

      GraphQL.prototype.updateResubscribe = function (prevProps) {
        this.setState({
          resubscribe: shouldResubscribe(prevProps, this.props),
        });
      };

      GraphQL.prototype.componentDidUpdate = function (prevProps) {
        if (shouldResubscribe && shouldResubscribe(prevProps, this.props) !== this.state.resubscribe) {
          this.updateResubscribe(prevProps);
        }
      };

      GraphQL.prototype.render = function () {
        const _this = this;
        let props = this.props;
        const shouldSkip = mapPropsToSkip(props);
        const opts = shouldSkip
          ? Object.create(null)
          : mapPropsToOptions(props);

        if (!shouldSkip && !opts.variables && operation.variables.length > 0) {
          opts.variables = calculateVariablesFromProps(operation, props);
        }

        return <Subscription
          {...opts}
          displayName={graphQLDisplayName}
          skip={shouldSkip}
          subscription={document}
          shouldResubscribe={this.state.resubscribe}
        >
          {({ data, ...rest }) => {
            if (operationOptions.withRef) {
              _this.withRef = true;
              props = Object.assign({}, props, {
                ref: _this.setWrappedInstance,
              });
            }
            if (shouldSkip) {
              return (React.createElement(WrappedComponent, __assign({}, props, {})));
            }

            const result = Object.assign(rest, data || {});
            const name = operationOptions.name || 'data';
            let childProps = { [name]: result };
            if (operationOptions.props) {
              const newResult = { [name]: result, ownProps: props };
              lastResultProps = operationOptions.props(newResult, lastResultProps);
              childProps = lastResultProps;
            }

            return React.createElement(WrappedComponent, __assign({}, props, childProps));
          }}
        </Subscription>;
      };
      GraphQL.displayName = graphQLDisplayName;
      GraphQL.WrappedComponent = WrappedComponent;

      return GraphQL;
    }(GraphQLBase));

    return hoistNonReactStatics(GraphQL, WrappedComponent, {});
  };
}
