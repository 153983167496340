import upperFirst from 'lodash/upperFirst';

import { getProjectComponentName } from '../../utils/projectComponent';

const ALL_IN_PARENT_PROJECT_COMPONENT_LABEL = 'Все разделы ';

export const getObjectTypeLabel = objectTypeName => {
  return ALL_IN_PARENT_PROJECT_COMPONENT_LABEL + objectTypeName.slice(0, -1).toLowerCase() + 'х объектов';
};

export const getStageLabel = (stageName, childTypeName) => {
  return `Все ${childTypeName}ы ${stageName}`;
};

export const getProjectComponentLabel = ({ name, code, type, fixedPrefix }) => `${fixedPrefix ? `${upperFirst(type.name)} ${fixedPrefix}. ` : ''}${name} (${code})`;

export const getProjectComponentProps = ({
  id,
  templateProjectComponent,
  createdAt,
  generatedCode,
}) => ({
  id,
  initialName: generatedCode?.combinedName ? (generatedCode.combinedName.replace(generatedCode.prefix, '').trim()) : '',
  name: generatedCode?.combinedName || '',
  processedName: generatedCode?.processedName || '',
  code: templateProjectComponent?.nameReference?.code || templateProjectComponent?.code,
  processedCode: generatedCode?.processedCode || '',
  order: generatedCode?.level || 0,
  level: generatedCode?.level || 0,
  childType: templateProjectComponent?.childType || null,
  createdAt,
});

export const getProjectComponents = (items, selectedItems = []) => (items || []).map(objectType => ({
  value: objectType.id,
  label: objectType.name,
  expanded: objectType.stages.some(stage =>
    stage.children.some(projectComponent =>
      selectedItems.indexOf(projectComponent.id) > -1)),
  children: objectType.stages.reduce((accumulator, { children, ...stage }) => {
    accumulator.push({
      label: getStageLabel(stage.name, stage.childType.name),
      value: stage.id,
      name: stage.name,
      projectComponentTypeId: stage.type.id,
      expanded: children.some(projectComponent => {
        return selectedItems.indexOf(projectComponent.id) > -1;
      }),
      children: children.map(projectComponent => ({
        value: projectComponent.id,
        projectComponentTypeId: projectComponent.type.id,
        label: getProjectComponentLabel(projectComponent),
        ...(selectedItems && { checked: selectedItems.indexOf(projectComponent.id) > -1 }),
      })),
    });
    return accumulator;
  }, []),
}));

export const getSelectedProjectComponents = (projectComponents, checkedItems, exclusion, withVersion) =>
  (projectComponents || []).filter(projectComponent => !exclusion.includes(projectComponent.id) &&
    !exclusion.some(item => item.id === projectComponent.id) &&
    // Remove all projectComponents without version
    (!withVersion || projectComponent.projectComponents.length > 0 || projectComponent.lastFilesVersion?.number !== 0),
  )
    .map(item => ({
      value: {
        id: item.id,
        name: item.name,
        code: item.code,
        deadline: item.deadline,
        createdAt: item.createdAt,
        processedCode: item.processedCode,
        level: item.level,
        versionId: item.lastFilesVersion?.id,
      },
      label: getProjectComponentName(item.name, item.code) +
        (withVersion && item.lastFilesVersion && item.lastFilesVersion.number !== 0 ? ` Версия №${item.lastFilesVersion.number}` : ''),
      checked: checkedItems.some(checkedItem => checkedItem === item || checkedItem.id === item.id),
      expanded: true,
      children: item.projectComponents && getSelectedProjectComponents(item.projectComponents, checkedItems, exclusion, withVersion),
      isNode: item.projectComponents && item.projectComponents.length > 0,
    }))
    .filter(item => !item.isNode || item.children.length > 0);

export const getCode = (code = '', index, parentCode) => {
  const projectComponentIndex = index + 1;

  if (!parentCode) {
    return `${code}`;
  }

  const codeParts = parentCode.split('-');
  const suffix = codeParts.length > 1 ? codeParts.pop() : '';
  return (
    codeParts
      .concat(code, `${suffix ? suffix + '.' + projectComponentIndex : projectComponentIndex}`)
      .join('-')
      .replace('--', '-')
  );
};

export const getProjectComponentNames = items => (items || [])
  .map(({ id, name, code, nameReference, order, type, fixedPrefix }) => {
    if (nameReference) {
      name = nameReference.name;
      code = nameReference.code;
      order = nameReference.order;
    }

    return {
      label: getProjectComponentLabel({ name, code, type, fixedPrefix }),
      value: id,
      name,
      code,
      order,
    };
  });

export const getChildren = (parents, projectComponents) =>
  (parents || []).reduce((result, projectComponent) => {
    const children = projectComponents.filter(({ parentObjectProjectComponentId }) => parentObjectProjectComponentId === projectComponent.id);

    if (children.length > 0) {
      result = result.concat(getChildren(children, projectComponents));
    } else {
      result.push(projectComponent);
    }

    return result;
  }, []);

export const OBJECT_PROJECT_COMPONENT_ORDER = [
  { generatedCode: { level: 'asc' } },
  { generatedCode: { order: 'asc' } },
  { createdAt: 'asc' },
  { id: 'asc' },
];

export const PROJECT_COMPONENT_ORDER = [
  { nameReference: { order: 'asc' } },
  { nameReference: { name: 'asc' } },
  { order: 'asc' },
  { name: 'asc' },
];

export const getObjectProjectComponentQuery = userId => ({
  _or: [
    {
      objectProjectComponentUsers: {
        _or: [
          {
            _and: [
              {
                userId: { _eq: userId },
              },
              {
                isActive: { _eq: true },
              },
            ],
          },
          {
            objectProjectComponentExperts: {
              userId: { _eq: userId },
            },
          },
        ],
      },
    },
    {
      object: { userId: { _eq: userId } },
    },
  ],
});
