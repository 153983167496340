import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import VacancyProcess from '../../../../components/vacancies/process';
import MakeBidModal from '../../../../components/modals/makeBid/container';
import Confirm2OptionsModal from '../../../../components/modals/confirm/2options';
import {
  getRemoveBidSchema,
  getRemoveParticipantSchema,
  getChooseCompetitionWinnerSchema,
} from '../../../../components/modals/confirm/2options/schemas';
import { goTo } from '../../../../utils/menu';
import { ROUTES } from '../../../../utils/routes';
import { REDIRECT_TO_REQUISITES_TITLE } from '../../../../utils/buttonTitles';
import { MAKE_BID_EMPTY_REQUISITES } from '../../../../utils/messages';
import InfoModal from '../../../../components/modals/info';

export default class VacancyProcessPage extends PureComponent {
  makeBidModalRef = React.createRef();
  removeBidModalRef = React.createRef();
  removeParticipantModalRef = React.createRef();
  chooseCompetitionWinnerModalRef = React.createRef();
  contractDetailsModalRef = React.createRef();

  state = {
    selectedParticipantUserId: null,
  };

  goToContractDetails = () => {
    goTo(ROUTES.financeContractRequisites);
  };

  showMakeBidModal = () => {
    const { contractDetails: [contractDetails] } = this.props;
    const { selfContractDetails, organizationContractDetails } = contractDetails;

    if (!selfContractDetails && !organizationContractDetails) {
      this.contractDetailsModalRef.current.toggleModal();
    } else {
      this.makeBidModalRef.current.toggleModal();
    }
  };

  showRemoveParticipantModal = participantUserId => {
    this.setState({ selectedParticipantUserId: participantUserId });
    this.removeParticipantModalRef.current.toggleModal();
  };

  handleRemoveParticipant = () => {
    return this.props.onRemoveParticipant(this.state.selectedParticipantUserId).then(() => {
      this.removeParticipantModalRef.current.toggleModal();
    });
  };

  showRemoveBidModal = () => {
    this.removeBidModalRef.current.toggleModal();
  };

  handleRemoveBid = () => {
    return this.props.onRemoveBid().then(() => {
      this.removeBidModalRef.current.toggleModal();
    });
  };

  showChooseCompetitionWinnerModal = participantUserId => {
    this.setState({ selectedParticipantUserId: participantUserId });
    this.chooseCompetitionWinnerModalRef.current.toggleModal();
  };

  handleChooseCompetitionWinner = () => this.props.onChooseCompetitionWinner(this.state.selectedParticipantUserId).then(() => {
    this.chooseCompetitionWinnerModalRef.current.toggleModal();
    goTo(ROUTES.currentFinanceContract.replace(':contractId', this.props.vacancy.contract.id));
  }).catch(noop);

  render() {
    const {
      vacancy,
      onMakeBid,
      bidRates,
      userId,
      contractDetailTypes,
      isTenders,
      competitionTerms,
    } = this.props;

    const isPirsOwnerVacancy = vacancy.contract.customer.id === Number(process.env.REACT_APP_PIRS_OWNER_USER_ID);

    return <>
      <VacancyProcess
        vacancy={vacancy}
        onClickMakeBid={this.showMakeBidModal}
        userId={userId}
        isTenders={isTenders}
        onClickRemoveBid={this.showRemoveBidModal}
        onClickRemoveParticipant={this.showRemoveParticipantModal}
        onClickChooseCompetitionWinner={this.showChooseCompetitionWinnerModal}
      />
      <MakeBidModal
        onRef={this.makeBidModalRef}
        onMakeBid={onMakeBid}
        currentBidAmount={vacancy.lowestBid}
        isSafeDeal={vacancy.contract.isSafeDeal}
        bidRates={bidRates}
        contractDetailTypes={contractDetailTypes}
        competitionTerms={competitionTerms}
        isPirsOwnerVacancy={isPirsOwnerVacancy}
      />
      <Confirm2OptionsModal
        onRef={this.removeParticipantModalRef}
        {...getRemoveParticipantSchema(
          this.handleRemoveParticipant,
          () => this.removeParticipantModalRef.current.toggleModal(),
        )}
      />
      <Confirm2OptionsModal
        onRef={this.removeBidModalRef}
        {...getRemoveBidSchema(
          this.handleRemoveBid,
          () => this.removeBidModalRef.current.toggleModal(),
        )}
      />
      <Confirm2OptionsModal
        onRef={this.chooseCompetitionWinnerModalRef}
        {...getChooseCompetitionWinnerSchema(
          isTenders,
          this.handleChooseCompetitionWinner,
          () => this.chooseCompetitionWinnerModalRef.current.toggleModal(),
        )}
      />
      <InfoModal
        onRef={this.contractDetailsModalRef}
        message={MAKE_BID_EMPTY_REQUISITES}
        buttonTitle={REDIRECT_TO_REQUISITES_TITLE}
        onClick={this.goToContractDetails}
      />
    </>;
  }

  static propTypes = {
    vacancy: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    isTenders: PropTypes.bool.isRequired,
    onMakeBid: PropTypes.func.isRequired,
    onRemoveBid: PropTypes.func.isRequired,
    onRemoveParticipant: PropTypes.func.isRequired,
    onChooseCompetitionWinner: PropTypes.func.isRequired,
    bidRates: PropTypes.array.isRequired,
    contractDetailTypes: PropTypes.array.isRequired,
    competitionTerms: PropTypes.object,
    contractDetails: PropTypes.array,
  };

  static defaultProps = {
    contractDetails: [],
    competitionTerms: {
      taxSystems: [],
      minRating: 0,
    },
  }
}
