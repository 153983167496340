import React from 'react';
import { Field } from 'redux-form';

import FormGroupField from '../../../base/formGroup';

import './index.scss';

export default class BankDetails extends React.PureComponent {
  render() {
    return <div className="form-card bank-details">
      <h5>Банковские реквизиты</h5>
      <Field
        name="bankAccount"
        component={FormGroupField}
        type="text"
        label="Расчетный (лицевой) счет"
        className="half-field"
        mask="99999999999999999999"
      />
      <Field
        name="bankBik"
        component={FormGroupField}
        type="text"
        label="БИК"
        className="half-field"
        mask="999999999"
      />
      <Field
        name="bankName"
        component={FormGroupField}
        type="text"
        label="Наименование банка"
      />
      <Field
        name="correspondentAccount"
        component={FormGroupField}
        type="text"
        label="Корреспондентский счет"
        className="half-field"
        mask="99999999999999999999"
      />
    </div>;
  }
}
