import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const GET_CONTRACT_REFERENCES_QUERY = loader('../../graphql/queries/contract/getReferences.graphql');

export default graphql(GET_CONTRACT_REFERENCES_QUERY, {
  name: 'getContractReferences',
  skip: props => props.isSkip,
  props: ({ getContractReferences: { taxSystems = [], admissionTypes = [] } }) => ({
    taxSystems,
    admissionTypes,
  }),
});
