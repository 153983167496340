import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TariffPlansList from '../../../components/tariff/plans';
import AddTariffPlanModal from '../../../components/modals/addTariffPlan/container';
import InfoModal from '../../../components/modals/info';
import ThanksForPurchase from '../../../components/modals/thanksForPurchase';
import {
  CREATE_TARIFF_PLAN_EMPTY_REQUISITES,
  YOU_MUST_BE_AUTHORIZED,
} from '../../../utils/messages';
import { goTo } from '../../../utils/menu';
import { REDIRECT_TO_AUTH, REDIRECT_TO_REQUISITES_TITLE } from '../../../utils/buttonTitles';
import { ROUTES } from '../../../utils/routes';

export default class TariffPlans extends PureComponent {
  state = {
    tariffPlan: {},
  };

  tariffPlanAddedInfoModalRef = React.createRef();
  redirectToAuthInfoModalRef = React.createRef();
  addTariffPlanModalRef = React.createRef();
  contractDetailsModalRef = React.createRef();

  handleTariffPlanModal = tariffPlan => {
    const { isAuthorized, contractDetails, isPublicTariffs } = this.props;

    if (isPublicTariffs && !isAuthorized) {
      this.redirectToAuthInfoModalRef.current.toggleModal();
    } else {
      this.setState({ tariffPlan });

      if (contractDetails) {
        this.addTariffPlanModalRef.current.toggleModal();
      } else {
        this.contractDetailsModalRef.current.toggleModal();
      }
    }
  };

  handleTariffPlanAddedInfoModal = () => {
    this.tariffPlanAddedInfoModalRef.current.toggleModal();
  };

  goToContractDetails = () => {
    goTo(ROUTES.financeContractRequisites);
  };

  goToAuthorizedTariffs = () => {
    goTo(ROUTES.tariffs);
  };

  goToCurrentTariffs = () => {
    goTo(ROUTES.currentTariffs);
  };

  render() {
    const { tariffPlans, contractDetails } = this.props;
    const { tariffPlan } = this.state;

    return <>
      <TariffPlansList
        tariffPlans={tariffPlans}
        showTariffPlanModal={this.handleTariffPlanModal}
      />
      {contractDetails
        ? <AddTariffPlanModal
          onRef={this.addTariffPlanModalRef}
          onAfterSubmit={this.handleTariffPlanAddedInfoModal}
          contractDetails={contractDetails}
          tariffPlan={tariffPlan}
        />
        : <InfoModal
          onRef={this.contractDetailsModalRef}
          message={CREATE_TARIFF_PLAN_EMPTY_REQUISITES}
          buttonTitle={REDIRECT_TO_REQUISITES_TITLE}
          onClick={this.goToContractDetails}
        />}
      <ThanksForPurchase
        onRef={this.tariffPlanAddedInfoModalRef}
        tariffPlan={tariffPlan}
        onClick={this.goToCurrentTariffs}
      />
      <InfoModal
        onRef={this.redirectToAuthInfoModalRef}
        message={YOU_MUST_BE_AUTHORIZED}
        buttonTitle={REDIRECT_TO_AUTH}
        onClick={this.goToAuthorizedTariffs}
      />
    </>;
  }

  static propTypes = {
    tariffPlans: PropTypes.array,
    isAuthorized: PropTypes.bool.isRequired,
    isPublicTariffs: PropTypes.bool.isRequired,
    contractDetails: PropTypes.object,
  };

  static defaultProps = {
    tariffPlans: [],
    subscriptionVariables: {},
    contractDetails: null,
  }
}
