export const DEFAULT_MAX_FILE_SIZE_MB = 100;
export const DEFAULT_ACCEPTED_FILE_TYPES = 'image/x-png, image/jpeg, application/pdf';
export const RESULTS_BUNDLED_IN_PDF_MAX_FILE_SIZE_MB = 150;
export const PDF_FILE_TYPE = 'application/pdf';

export const formatFileSize = bytes => {
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = -1;

  do {
    bytes = bytes / 1024;
    i++;
  } while (bytes > 1024);

  return Math.max(bytes, 0.1).toFixed(1) + byteUnits[i];
};

export const CONTRACT_FILE_TYPES = {
  contract: {
    title: 'Договор',
    order: 1,
  },
  technicalTask: {
    title: 'Техническое задание',
    order: 2,
  },
  certificateOfCompletion: {
    title: 'Акт выполненных работ',
    order: 3,
  },
  supplementaryAgreement: {
    title: 'Дополнительное соглашение',
    order: 4,
  },
};
