import React, { PureComponent } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import moment from 'moment';

import { getFormattedExperience } from '../../../../utils/date';
import UserRating from '../../../base/userRating';

import './index.scss';

class ProfileHeader extends PureComponent {
  render() {
    const { createdAt, rating, isPrivate } = this.props;

    return <div className="view-profile-header-layout">
      <h1>Профиль</h1>
      {!isPrivate && <div className="profile-status">
        <span>
          Опыт работы: {getFormattedExperience(moment().diff(moment(createdAt), 'months'))}
        </span>
        <UserRating ratingSettings={rating} />
      </div>}
    </div>;
  }
}

ProfileHeader.propTypes = {
  createdAt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    instanceOf(Date),
  ]),
  rating: PropTypes.object,
  isPrivate: PropTypes.bool,
};

ProfileHeader.defaultProps = {
  createdAt: new Date(),
  rating: {},
  isPrivate: false,
};

export default ProfileHeader;
