import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import MenuItem from '../../components/base/menuItem';
import { ICONS } from '../../utils/icons';
import Menu from '../menu/container';
import { ROUTES } from '../../utils/routes';

export default class ObjectMenu extends PureComponent {
  handleClickMenuItem = id => this.props.goTo(id)();

  getStages = () => {
    const { objectMenuItems, getStatus } = this.props;

    return <>
      {objectMenuItems.map(({ id, name, icon }, index) => <MenuItem
        key={index}
        IconComponent={ICONS[icon]}
        isActive={getStatus(id)}
        onClick={() => this.handleClickMenuItem(id)}
        isBorderless
      >
        {name}
      </MenuItem>)}
    </>;
  };

  render() {
    const { items, stagePath, className, objectMenuItems, isLoading, isTariffActive, objectId, onClickMenuItem } = this.props;

    if (!isLoading && !isTariffActive) {
      return <Redirect to={ROUTES.blockedObject.replace(':id', objectId)} />;
    } else if (stagePath) {
      return <Redirect to={stagePath} />;
    } else if (!isLoading && objectMenuItems.length === 0) {
      return <Redirect to={ROUTES.notFound} />;
    }

    return <Menu
      className={className}
      items={items}
      onClickMenuItem={onClickMenuItem}
    >
      {this.getStages()}
    </Menu>;
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isTariffActive: PropTypes.bool.isRequired,
    objectId: PropTypes.string.isRequired,
    stagePath: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    objectMenuItems: PropTypes.array.isRequired,
    goTo: PropTypes.func.isRequired,
    getStatus: PropTypes.func.isRequired,
    className: PropTypes.string,
    onClickMenuItem: PropTypes.func,
  };

  static defaultProps = {
    className: 'main-menu-layout',
    onClickMenuItem() {},
  }
}
