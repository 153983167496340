import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PublishVacancytModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';

const FORM_NAME = 'publishVacancyModal';

export default compose(
  reduxForm({
    form: FORM_NAME,
    validate: getValidation([
      'applicationDeadline',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(PublishVacancytModal);
