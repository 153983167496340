import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProjectComponentFiles from '../../stageManagement/projectComponentList/files';
import Container from '../../../../base/container';

import './index.scss';

export default class ObjectFiles extends PureComponent {
  render() {
    const {
      className,
      files,
      uploadFiles,
      insertFiles,
      deleteFile,
      storagePath,
      type,
      title,
      showAddButton,
      showDeleteButton,
      isCustomerOrChief,
    } = this.props;

    return <Container
      className={classNames('object-files-container', className)}
      headerContent={<h5>{title}</h5>}
    >
      <ProjectComponentFiles
        files={files}
        type={type}
        showAddButton={showAddButton}
        showDeleteButton={showDeleteButton}
        storagePath={storagePath}
        uploadFiles={uploadFiles}
        insertFiles={insertFiles}
        deleteFile={deleteFile}
        isCustomerOrChief={isCustomerOrChief}
      />
    </Container>;
  }

  static propTypes = {
    title: PropTypes.string,
    files: PropTypes.array,
    uploadFiles: PropTypes.func.isRequired,
    insertFiles: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    storagePath: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    showAddButton: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    isCustomerOrChief: PropTypes.bool,
  };

  static defaultProps = {
    title: 'Согласования на объект',
    files: [],
    className: '',
    showAddButton: true,
    showDeleteButton: true,
    isCustomerOrChief: false,
  };
}
