import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmEmail } from '../../store/actions/session';

class ConfirmEmail extends Component {
  async componentDidMount() {
    const { match, confirmEmail: dispatchConfirmEmail } = this.props;
    await dispatchConfirmEmail(match.params);
  }

  render() {
    return <div>...Loading</div>;
  }
}

ConfirmEmail.propTypes = {
  match: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { confirmEmail })(ConfirmEmail));
