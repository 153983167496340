import * as moment from 'moment';

export const prepareToInsertDataRequest = (data, message) => {
  const { id: objectProjectComponentId, objectId, customer, vacancies } = data;
  const remarksCreateTermLimit = vacancies.find(({ isActive, isPerformer }) =>
    isActive && isPerformer).paymentTerms?.remarksCreateTermLimit;
  const deadline = remarksCreateTermLimit ? moment().add(remarksCreateTermLimit, 'days').toDate() : null;
  return {
    objectProjectComponentId,
    task: {
      data: {
        deadline,
        objectId,
        assignedUserId: customer.userId,
        objectProjectComponentId,
        taskMessages: {
          data: [{
            message,
          }],
        },
      },
    },
  };
};
