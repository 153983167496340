import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'redux-form';

import Switch from '../../base/switch';
import Button from '../../base/button';
import PersonalData from './personalData';
import AccountData from './accountData';
import CompetenceConfirmation from './competenceConfirmation';
import PrivacySettings from './privacySettings';
import UserRating from '../../base/userRating';
import Specialization from './specialization';
import { ICONS } from '../../../utils/icons';
import { EMPTY_CONTRACT_DETAILS_WARNING } from '../../../utils/messages';

import './index.scss';

export default class ProfileForm extends PureComponent {
  constructor(props) {
    super(props);

    const { user: { isPublic }, initialValues: { privacySettings } } = props;

    this.state = {
      isPublic,
      isPrivacySettingsAlreadySet: !!privacySettings,
    };
  }

  submit = async props => {
    await this.props.saveProfile(props);
  };

  handleIsPublicSwitch = value => {
    this.setState({ isPublic: value });
    this.props.onChangeIsPublic(value);
  };

  render() {
    const {
      handleSubmit,
      user,
      userRoles,
      regions,
      specialties,
      objectTypes,
      projectComponents,
      selectedProjectComponents,
      selectedRole,
      submitting,
      change: reduxFormChange,
      onConfirmEmail,
      isValidEmail,
      isContractDetailsFilled,
      initialValues,
      chiefEngineerExpertiseExperience,
      userChiefEngineerExpertiseExperience,
    } = this.props;

    const { isPublic } = this.state;

    return <Form onSubmit={handleSubmit(this.submit)} className="profile-form">
      <UserRating ratingSettings={user.ratings} />
      {!isContractDetailsFilled && <div className="info">
        <ICONS.infoBold className="info-icon" />
        <p>{EMPTY_CONTRACT_DETAILS_WARNING}</p>
      </div>}
      <div className="profile-content">
        <PersonalData isPhoneConfirmed={initialValues.isPhoneConfirmed} />
        <AccountData user={user} confirmEmail={onConfirmEmail} isValidEmail={isValidEmail} />
        <CompetenceConfirmation
          regions={regions}
          hiddenFields={['expertAccreditationNumber']}
        />
        <Specialization
          specialties={specialties}
          objectTypes={objectTypes}
          projectComponents={projectComponents}
          selectedProjectComponents={selectedProjectComponents}
          selectedRole={selectedRole}
          userRoles={userRoles}
          chiefEngineerExpertiseExperience={chiefEngineerExpertiseExperience}
          userChiefEngineerExpertiseExperience={userChiefEngineerExpertiseExperience}
          change={reduxFormChange}
        />
        <Switch
          id="isPublicSwitch"
          isChecked={isPublic}
          onChange={this.handleIsPublicSwitch}
          labelLeft="Закрытый профиль"
          labelRight="Открытый профиль"
        />
        {isPublic && <PrivacySettings />}
        <Button
          buttonProps={{
            type: 'submit',
          }}
          isLarge
          isDisabled={submitting}
          isLoading={submitting}
        >Сохранить изменения
        </Button>
      </div>
    </Form>;
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    userRoles: PropTypes.array,
    regions: PropTypes.array.isRequired,
    specialties: PropTypes.array.isRequired,
    objectTypes: PropTypes.array.isRequired,
    projectComponents: PropTypes.array.isRequired,
    selectedProjectComponents: PropTypes.array,
    selectedRole: PropTypes.object,
    saveProfile: PropTypes.func.isRequired,
    chiefEngineerExpertiseExperience: PropTypes.array.isRequired,
    userChiefEngineerExpertiseExperience: PropTypes.array,
    onChangeIsPublic: PropTypes.func.isRequired,
    onConfirmEmail: PropTypes.func.isRequired,
    isValidEmail: PropTypes.bool.isRequired,
    isContractDetailsFilled: PropTypes.bool,
    // redux form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
  };

  static defaultProps = {
    selectedProjectComponents: [],
    selectedRole: {},
    isContractDetailsFilled: false,
    userRoles: [],
    userChiefEngineerExpertiseExperience: [],
  }
}
