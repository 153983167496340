import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

import './index.scss';

export default class Loading extends PureComponent {
  render() {
    return <div className={classNames('loading-wrapper', this.props.className)}>
      <Spinner animation="border" />
    </div>;
  }

  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };
}
