import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, reduxForm } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import { getSubmitValidation, getValidation } from '../../../utils/validation';

import './index.scss';

class DataRequestModal extends PureComponent {
  handleCancel = isCancelButton => () => {
    const { onRef, onClose, reset } = this.props;

    onClose();
    reset();

    if (isCancelButton) {
      onRef.current.toggleModal();
    }
  };

  handleSubmit = () => {
    const { onSubmit, projectComponent, onRef, reset } = this.props;
    onSubmit(projectComponent);
    reset();
    onRef.current.toggleModal();
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      onRef,
      projectComponent,
    } = this.props;

    const { type: { dativeName }, processedCode } = projectComponent;

    return <Modal
      onClose={this.handleCancel()}
      className="create-data-request-modal"
      title={`Запрос документации по ${dativeName} ${processedCode}`}
      onRef={onRef}
    >
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          name="request"
          component={FormGroupField}
          type="textarea"
          label="Запрос"
          placeholder="Введите содержание запроса"
        />
        <div className="actions">
          <Button
            isLarge
            isDisabled={submitting || pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Создать
          </Button>
          <Button
            onClick={this.handleCancel(true)}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onClose: PropTypes.func,
    onRef: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    projectComponent: PropTypes.object,
    // redux form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    onClose() {},
    remarkResponse: null,
    projectComponent: {},
  };
}

export default reduxForm({
  form: 'dataRequestModal',
  validate: getValidation(['request']),
  onSubmitFail: getSubmitValidation,
})(DataRequestModal);
