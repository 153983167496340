import { mapValues, pick } from 'lodash';

const INTEGER_PROPS = [
  'bankBik',
];

export const getGraphqlSelfContractDetails = (
  {
    __typename,
    userId,
    taxSystem,
    initialFiles,
    individualInn,
    ...props
  },
) => ({
  ...props,
  inn: parseInt(individualInn, 10),
  ...mapValues(pick(props, INTEGER_PROPS), value => parseInt(value, 10)),
});
