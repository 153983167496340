import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withContext, withProps } from 'recompose';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import differenceBy from 'lodash/differenceBy';
import partition from 'lodash/partition';

import ContractDetails from './index';
import { getTaxSystems } from '../../graphql/model/taxSystems';
import { showError } from '../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import AuthorizedApi from '../../api/authorized';
import { getContractDetails } from '../../graphql/model/contractDetails';
import { getGraphqlFiles, getGraphqlFilesToDelete } from '../../graphql/model/file';
import {
  getGraphqlOrganizationContractDetails,
  getGraphqlOrganizationFiles,
  getGraphqlOrganizationFilesToDelete,
} from '../../graphql/model/organizationContractDetails';
import { getGraphqlSelfContractDetails } from '../../graphql/model/selfContractDetails';

const GET_CONTRACT_DETAILS_QUERY = loader('../../graphql/queries/contract/getContractDetails.graphql');
const UPDATE_SELF_CONTRACT_DETAILS_MUTATION = loader('../../graphql/queries/contract/upsertSelfContractDetails.graphql');
const UPDATE_ORGANIZATION_CONTRACT_DETAILS_MUTATION = loader('../../graphql/queries/contract/upsertOrganizationContractDetails.graphql');
const INSERT_FILES_MUTATION = loader('../../graphql/queries/file/insert.graphql');

const withUpdateSelfContractDetailsMutation = graphql(UPDATE_SELF_CONTRACT_DETAILS_MUTATION, {
  name: 'updateSelfContractDetails',
});
const withUpdateOrganizationContractDetailsMutation = graphql(UPDATE_ORGANIZATION_CONTRACT_DETAILS_MUTATION, {
  name: 'updateOrganizationContractDetails',
});
const withInsertFilesMutation = graphql(INSERT_FILES_MUTATION, {
  name: 'insertFiles',
});

const mapStateToProps = ({ session: { user: { id } } }) => ({ userId: id });

const mergeProps = withProps(({
  updateSelfContractDetails,
  updateOrganizationContractDetails,
  insertFiles,
  refetch,
  ...props
}) => ({
  ...props,
  onFileUpload: AuthorizedApi.uploadFile,
  onSubmit: async (props, isSelf) => {
    let mutation;
    const variables = {};

    if (isSelf) {
      mutation = updateSelfContractDetails;
      variables.self = getGraphqlSelfContractDetails(props);
    } else {
      mutation = updateOrganizationContractDetails;
      const { admissions, lettersOfAttorney, initialFiles } = props;
      variables.organization = getGraphqlOrganizationContractDetails(props);
      let insertedFiles = [];
      let deletedFiles = [];

      if (admissions || lettersOfAttorney) {
        const preparedFiles = [admissions || [], lettersOfAttorney || []].reduce((previous, current) => {
          previous = [
            ...previous,
            ...current.filter(({ file }) => file && file.remotePath).map(({ file }) => file),
          ];
          return previous;
        }, []);

        if (preparedFiles.length > 0 || initialFiles.length > 0) {
          const filesDelete = getGraphqlFilesToDelete(initialFiles, preparedFiles.map(file => file.id));

          const changedFiles = await insertFiles({
            variables: {
              filesInsert: getGraphqlFiles(preparedFiles),
              filesDelete: filesDelete.length > 0 ? filesDelete : [],
            },
          });

          insertedFiles = differenceBy(changedFiles.data.insert_file.returning, preparedFiles, 'id');
          deletedFiles = changedFiles.data.delete_file.returning;
        }
      }
      Object.assign(variables, {
        admissionsInsert: getGraphqlOrganizationFiles(insertedFiles, admissions),
        admissionsDelete: getGraphqlOrganizationFilesToDelete(deletedFiles, admissions),
        lettersInsert: getGraphqlOrganizationFiles(insertedFiles, lettersOfAttorney),
        lettersDelete: getGraphqlOrganizationFilesToDelete(deletedFiles, lettersOfAttorney),
      });
    }
    return mutation({
      variables,
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }

      return refetch();
    }).catch(async error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    });
  },
}));

const getSplittedTaxSystems = taxSystems => {
  const [organization, self] = partition(taxSystems, 'isOrganization');

  return {
    organization,
    self,
  };
};

const withGetContractDetailsQuery = graphql(GET_CONTRACT_DETAILS_QUERY, {
  name: 'getContractDetails',
  options: ({ userId }) => ({
    variables: {
      userId,
    },
  }),
  props: ({ getContractDetails: { taxSystems, user = [], error, loading, refetch } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const contractDetails = user[0];
    const { selfContractDetails, organizationContractDetails } = contractDetails || {};

    return {
      initialValues: contractDetails ? getContractDetails(contractDetails) : {},
      isLoading: loading,
      refetch,
      isContractDetailsFilled: !contractDetails || !!(selfContractDetails || organizationContractDetails),
      taxSystems: taxSystems && getSplittedTaxSystems(getTaxSystems(
        taxSystems,
        contractDetails ? [selfContractDetails?.taxSystemId, organizationContractDetails?.taxSystemId] : [],
      )) || {},
    };
  },
});

export default compose(
  withUpdateSelfContractDetailsMutation,
  withUpdateOrganizationContractDetailsMutation,
  withInsertFilesMutation,
  connect(mapStateToProps),
  withGetContractDetailsQuery,
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
)(ContractDetails);
