import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { EXTERNAL_LINK_TYPE, redirectToExternalSite } from '../../utils/menu';

import Menu from './index';

const getCommonPart = (path, route) => {
  const splittedPath = path.slice(1).split('/');
  const splittedRoute = route.slice(1).split('/');
  let routeParams = null;

  for (const index in splittedRoute) {
    const routePart = splittedRoute[index];
    const pathPart = splittedPath[index];

    if (routePart.startsWith(':') && Number(index) !== 0) {
      if (routeParams) {
        routeParams[routePart.slice(1)] = pathPart;
      } else {
        routeParams = {
          [routePart.slice(1)]: pathPart,
        };
      }
    } else if (routePart !== pathPart) {
      return routeParams;
    }
  }
  return routeParams;
};

const mergeProps = withProps(({ location, history, onClickMenuItem, ...props }) => ({
  ...props,
  location,
  goTo: (route = '', type) => {
    const { pathname } = location;
    route = Array.isArray(route) ? route[0] : route;
    const commonParams = getCommonPart(pathname, route);

    if (type === EXTERNAL_LINK_TYPE) {
      redirectToExternalSite(route);
      return null;
    }

    if (onClickMenuItem) {
      onClickMenuItem();
    }

    if (route.includes(':contractId') && (!commonParams?.contractId || !route.match(/:contractId\/\w*/))) {
      route = route.replace('/:contractId', '');
    }

    if (commonParams) {
      Object.keys(commonParams).forEach(param => {
        route = route.replace(`:${param}`, commonParams[param]);
      });

      history.replace(route);
    } else if (route && pathname !== route) {
      history.push(route);
    }
  },
}));

export default compose(
  withRouter,
  mergeProps,
)(Menu);
