export const ROUTES = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  signUpWithDemo: '/sign-up-with-demo',
  confirmRegistration: '/confirm-registration',
  confirmEmail: '/confirm-email',
  checkInvitation: '/check-invitation',
  public: '/public',

  profile: '/profile',
  profiles: '/profiles/:id',
  get publicProfile() {
    return `${this.profiles}/public`;
  },
  get profileContracts() {
    return `${this.profiles}/contracts`;
  },
  get currentProfileContracts() {
    return `${this.profileContracts}/current`;
  },
  get closedProfileContracts() {
    return `${this.profileContracts}/closed`;
  },

  favorites: '/favorites',
  get favoriteEngineers() {
    return `${this.favorites}/engineers`;
  },
  get favoritePerformers() {
    return `${this.favorites}/performers`;
  },

  tenders: '/tenders',
  get openedTenders() {
    return `${this.tenders}/opened`;
  },
  get openedTender() {
    return `${this.openedTenders}/:contractId`;
  },
  get openedTenderProfile() {
    return `${this.openedTender}/profile`;
  },
  get openedTenderProcess() {
    return `${this.openedTender}/process`;
  },
  get myTenders() {
    return `${this.tenders}/my`;
  },
  get myTender() {
    return `${this.myTenders}/:contractId`;
  },
  get myTenderProfile() {
    return `${this.myTender}/profile`;
  },
  get myTenderProcess() {
    return `${this.myTender}/process`;
  },
  get involvedTenders() {
    return `${this.tenders}/involved`;
  },
  get involvedTender() {
    return `${this.involvedTenders}/:contractId`;
  },
  get involvedTenderProfile() {
    return `${this.involvedTender}/profile`;
  },
  get involvedTenderProcess() {
    return `${this.involvedTender}/process`;
  },
  get tenderInvitations() {
    return `${this.tenders}/invitations`;
  },
  get tenderInvitation() {
    return `${this.tenderInvitations}/:contractId`;
  },
  get tenderInvitationProfile() {
    return `${this.tenderInvitation}/profile`;
  },
  get tenderInvitationProcess() {
    return `${this.tenderInvitation}/process`;
  },

  finance: '/finance',
  get financeBalance() {
    return `${this.finance}/balance`;
  },
  get financeContracts() {
    return `${this.finance}/contracts`;
  },
  get currentFinanceContracts() {
    return `${this.financeContracts}/current`;
  },
  get currentFinanceContract() {
    return `${this.currentFinanceContracts}/:contractId`;
  },
  get currentFinanceContractProfile() {
    return `${this.currentFinanceContract}/profile`;
  },
  get currentFinanceContractDocumentation() {
    return `${this.currentFinanceContract}/documentation`;
  },
  get currentFinanceContractPaymentRegister() {
    return `${this.currentFinanceContract}/payment-register`;
  },
  get closedFinanceContracts() {
    return `${this.financeContracts}/closed`;
  },
  get closedFinanceContract() {
    return `${this.closedFinanceContracts}/:contractId`;
  },
  get closedFinanceContractProfile() {
    return `${this.closedFinanceContract}/profile`;
  },
  get closedFinanceContractDocumentation() {
    return `${this.closedFinanceContract}/documentation`;
  },
  get closedFinanceContractPaymentRegister() {
    return `${this.closedFinanceContract}/payment-register`;
  },
  get arbitrationFinanceContracts() {
    return `${this.financeContracts}/arbitration`;
  },
  get arbitrationFinanceContract() {
    return `${this.arbitrationFinanceContracts}/:contractId`;
  },
  get arbitrationFinanceContractProfile() {
    return `${this.arbitrationFinanceContract}/profile`;
  },
  get arbitrationFinanceContractDocumentation() {
    return `${this.arbitrationFinanceContract}/documentation`;
  },
  get arbitrationFinanceContractPaymentRegister() {
    return `${this.arbitrationFinanceContract}/payment-register`;
  },
  get financeContractRequisites() {
    return `${this.finance}/requisites`;
  },

  tasks: '/tasks',
  get outgoingTasks() {
    return `${this.tasks}/outgoing`;
  },
  get incomingTasks() {
    return `${this.tasks}/incoming`;
  },
  get delegatedTasks() {
    return `${this.tasks}/delegated`;
  },
  get tasksArchive() {
    return `${this.tasks}/archive`;
  },

  vacancies: '/vacancies',
  get openedVacancies() {
    return `${this.vacancies}/opened`;
  },
  get openedVacancy() {
    return `${this.openedVacancies}/:contractId`;
  },
  get openedVacancyProfile() {
    return `${this.openedVacancy}/profile`;
  },
  get openedVacancyProcess() {
    return `${this.openedVacancy}/process`;
  },
  get myVacancies() {
    return `${this.vacancies}/my`;
  },
  get myVacancy() {
    return `${this.myVacancies}/:contractId`;
  },
  get myVacancyProfile() {
    return `${this.myVacancy}/profile`;
  },
  get myVacancyProcess() {
    return `${this.myVacancy}/process`;
  },
  get involvedVacancies() {
    return `${this.vacancies}/involved`;
  },
  get involvedVacancy() {
    return `${this.involvedVacancies}/:contractId`;
  },
  get involvedVacancyProfile() {
    return `${this.involvedVacancy}/profile`;
  },
  get involvedVacancyProcess() {
    return `${this.involvedVacancy}/process`;
  },
  get vacancyInvitations() {
    return `${this.vacancies}/invitations`;
  },
  get vacancyInvitation() {
    return `${this.vacancyInvitations}/:contractId`;
  },
  get vacancyInvitationProfile() {
    return `${this.vacancyInvitation}/profile`;
  },
  get vacancyInvitationProcess() {
    return `${this.vacancyInvitation}/process`;
  },

  ratings: '/ratings',
  get roleRatings() {
    return `${this.ratings}/:userRoleId`;
  },

  recover: '/recover',
  setPassword: '/set-password',

  tariffs: '/tariffs',
  get currentTariffs() {
    return `${this.tariffs}/current`;
  },
  get publicTariffs() {
    return `${this.public}${this.tariffs}`;
  },

  objects: '/objects',
  get myObjects() {
    return `${this.objects}/my`;
  },
  get involvedObjects() {
    return `${this.objects}/involved`;
  },
  get closedObjects() {
    return `${this.objects}/closed`;
  },
  get arbitrationObjects() {
    return `${this.objects}/arbitration`;
  },
  get object() {
    return `${this.objects}/:id`;
  },
  get blockedObject() {
    return `${this.object}/locked`;
  },
  get downloadBundle() {
    return `${this.object}/downloadBundle`;
  },
  get stages() {
    return `${this.object}/stages`;
  },
  get stage() {
    return `${this.stages}/:stageId`;
  },
  get projectComponents() {
    return `${this.stage}/projectComponents`;
  },
  get projectComponent() {
    return `${this.projectComponents}/:projectComponentId`;
  },
  get projectComponentRemarks() {
    return `${this.projectComponent}/remarks`;
  },
  get projectComponentRemark() {
    return `${this.projectComponentRemarks}/:remarkId`;
  },
  get objectInitialData() {
    return `${this.object}/initial-data`;
  },
  get objectExpertise() {
    return `${this.object}/expertise`;
  },
  get projectComposition() {
    return `${this.object}/project-composition`;
  },
  get projectCompositionStage() {
    return `${this.projectComposition}/:stageId`;
  },

  get objectTasks() {
    return `${this.object}/tasks`;
  },
  get objectOutgoingTasks() {
    return `${this.objectTasks}/outgoing`;
  },
  get objectIncomingTasks() {
    return `${this.objectTasks}/incoming`;
  },
  get objectDelegatedTasks() {
    return `${this.objectTasks}/delegated`;
  },
  get objectTasksArchive() {
    return `${this.objectTasks}/archive`;
  },
  get objectContracts() {
    return `${this.object}/contracts`;
  },
  get currentObjectContracts() {
    return `${this.objectContracts}/current`;
  },
  get currentObjectContract() {
    return `${this.currentObjectContracts}/:contractId`;
  },
  get currentObjectContractProfile() {
    return `${this.currentObjectContract}/profile`;
  },
  get currentObjectContractDocumentation() {
    return `${this.currentObjectContract}/documentation`;
  },
  get currentObjectContractPaymentRegister() {
    return `${this.currentObjectContract}/payment-register`;
  },
  get closedObjectContracts() {
    return `${this.objectContracts}/closed`;
  },
  get closedObjectContract() {
    return `${this.closedObjectContracts}/:contractId`;
  },
  get closedObjectContractProfile() {
    return `${this.closedObjectContract}/profile`;
  },
  get closedObjectContractDocumentation() {
    return `${this.closedObjectContract}/documentation`;
  },
  get closedObjectContractPaymentRegister() {
    return `${this.closedObjectContract}/payment-register`;
  },
  get arbitrationObjectContracts() {
    return `${this.objectContracts}/arbitration`;
  },
  get arbitrationObjectContract() {
    return `${this.arbitrationObjectContracts}/:contractId`;
  },
  get arbitrationObjectContractProfile() {
    return `${this.arbitrationObjectContract}/profile`;
  },
  get arbitrationObjectContractDocumentation() {
    return `${this.arbitrationObjectContract}/documentation`;
  },
  get arbitrationObjectContractPaymentRegister() {
    return `${this.arbitrationObjectContract}/payment-register`;
  },

  notFound: '/not-found',

  // TODO: stage 2 features
  cloudArchive: '/cloud-archive',
  support: '/support',
  settings: '/settings',
  contacts: '/contacts',
  policy: '/policy',

  get openedTenderRecommendations() {
    return `${this.openedTender}/recommendations`;
  },
  get myTenderRecommendations() {
    return `${this.myTender}/payment-recommendations`;
  },
  get involvedTenderRecommendations() {
    return `${this.involvedTender}/recommendations`;
  },
  get openedVacancyRecommendations() {
    return `${this.openedVacancy}/recommendations`;
  },
  get myVacancyRecommendations() {
    return `${this.myVacancy}/recommendations`;
  },
  get involvedVacancyRecommendations() {
    return `${this.involvedVacancy}/recommendations`;
  },
  get authorsSupervision() {
    return `${this.object}/authors-supervision`;
  },
  get technicalIndicators() {
    return `${this.object}/technical-indicators`;
  },
  get readinessStatus() {
    return `${this.object}/readiness-status`;
  },
  get correspondence() {
    return `${this.object}/correspondence`;
  },
};

export const DEFAULT_AUTHORIZED_ROUTE = ROUTES.profile;
export const DEFAULT_AUTHORIZED_FILLED_PROFILE_ROUTE = ROUTES.objects;
export const ROOT_ROUTE = '/';
export const PUBLIC_MENU = [
  {
    title: 'Для чего нужна "ПИР-Система',
    path: '/#features',
  },
  {
    title: 'Для кого ПИР-Система',
    path: '/#for-whom',
  },
  {
    title: 'С нами вы сможете',
    path: '/#withus',
  },
  {
    title: 'Используя ПИР-Систему вы получаете',
    path: '/#youget',
  },
  {
    title: 'Почему надо использовать ПИР-Систему',
    path: '/#why',
  },
  {
    title: 'Контакты',
    path: '/#contacts',
  },
  {
    title: 'Публикации',
    path: '/#articles',
  },
  {
    title: 'Видеоинструкции',
    path: '/video_instructions',
  },
  {
    title: 'Часто задаваемые вопросы',
    path: '/faq',
  },
];
