import { getProjectComponentName } from '../../utils/projectComponent';

export const setObjectExpertise = (object, userId) => {
  const { expertise, stages, userId: objectUserId } = object;

  for (const expertiseItem of expertise) {
    const { links, file } = expertiseItem;

    expertiseItem.projectComponents = [];
    expertiseItem.noExpertiseProjectComponents = [];

    stages.forEach(({ projectComponents, chiefEngineer }) => {
      projectComponents.forEach(({ flattenedProjectComponents }) => {
        flattenedProjectComponents.forEach(({ id, processedName, processedCode, lastFilesVersion, root, engineers, performers, vacancies }) => {
          const expertiseProjectComponent = links.find(({ version: { objectProjectComponentId } }) => objectProjectComponentId === id);
          const expertiseName = `${root.templateProjectComponent.name}. ${getProjectComponentName(processedName, processedCode)}`;
          const vacancy = vacancies.find(({ userId: vacancyUserId, isActive }) => (vacancyUserId === userId) && isActive);

          if (expertiseProjectComponent) {
            const employees = [...(chiefEngineer ? [chiefEngineer] : []), ...engineers, ...performers].filter(({ isActive }) => isActive);
            const userRoleId = employees.find(employee => employee.userId === userId)?.role.id;

            expertiseItem.projectComponents.push({
              id,
              projectComponentExpertiseId: expertiseProjectComponent.id,
              name: expertiseName,
              status: expertiseProjectComponent.isPositive ? 'positive' : 'negative',
              createdAt: file.createdAt,
              userRoleId,
              acceptances: employees
                .filter(({ isActive, userId }) => isActive && userId)
                .map(({ role, userId: employeeUserId }) => {
                  const acceptance = expertiseProjectComponent.acceptances
                    .find(acceptance => acceptance.userId === employeeUserId && acceptance.userRoleId === role.id);
                  const status = {
                    name: 'На рассмотрении',
                    kind: 'neutral',
                  };

                  if (acceptance) {
                    status.name = acceptance.isAccepted ? 'Согласен' : 'Не согласен';
                    status.kind = acceptance.isAccepted ? 'positive' : 'negative';
                    status.hint = acceptance.reason;
                  }

                  return {
                    ...(acceptance ? { id: acceptance.id } : {}),
                    status,
                    role: role.accusative,
                    roleId: role.id,
                    isMyRole: employeeUserId === userId,
                  };
                }),
              isCustomer: objectUserId === userId,
              isOwner: file.userId === userId,
              phase: vacancy?.currentPhase,
              contractId: vacancy?.contractId,
            });
          // Check projectComponent has version and no expertise
          } else if (lastFilesVersion.id && expertise.every(expertiseFile =>
            expertiseFile.links.every(({ version: { objectProjectComponentId } }) => objectProjectComponentId !== id))
          ) {
            expertiseItem.noExpertiseProjectComponents.push({
              id,
              versionId: lastFilesVersion.id,
              name: expertiseName,
              code: processedCode,
            });
          }
        });
      });
    });
  }
};

export const prepareToInsertExpertise = (object, { links: { positive, negative }, expertiseFile, start }) => ({
  objectId: object.id,
  file: { data: expertiseFile },
  start,
  objectProjectComponentExpertise: {
    data: [
      ...(positive || []).map(({ versionId }) => ({
        objectProjectComponentVersionId: versionId,
        isPositive: true,
      })),
      ...(negative || []).map(({ versionId }) => ({
        objectProjectComponentVersionId: versionId,
        isPositive: false,
      })),
    ],
  },
});
