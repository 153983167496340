import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Container from '../../../../base/container';
import Button from '../../../../base/button';
import ChangeExpertiseStatusModal from '../../../../modals/expertise/changeExpertiseStatus/container';
import { ICONS } from '../../../../../utils/icons';
import UploadExpertiseModal from '../../../../modals/expertise/uploadExpertise/container';
import Header from '../../header';
import ObjectInfo from '../../objectInfo';
import { goTo } from '../../../../../utils/menu';
import { ROUTES } from '../../../../../utils/routes';
import { ExpertiseContainer } from './item';
import BreakContractModal from '../../../../modals/breakContract/container';

import './index.scss';

const EXPERTISE_DISAGREE = 'Несогласие с отрицательным заключением экспертизы';

export default class ObjectExpertise extends PureComponent {
  changeExpertiseStatusRef = React.createRef();
  uploadExpertiseModalRef = React.createRef();
  breakContractModalRef = React.createRef();

  state = {
    selectedObjectProjectComponent: {},
  };

  handleClickChangeProjectComposition = () => {
    goTo(ROUTES.projectComposition, { id: this.props.object.id });
  };

  showUploadExpertiseModal = () => this.uploadExpertiseModalRef.current.toggleModal();

  handleOpenChangeStatusModal = (item, fileId) => {
    this.setState({
      selectedObjectProjectComponent: {
        ...item,
        fileId,
      },
    });
    this.changeExpertiseStatusRef.current.toggleModal();
  };

  handleClickDisagree = item => {
    this.setState({ selectedObjectProjectComponent: item });
    this.breakContractModalRef.current.toggleModal();
  };

  handleAddAcceptance = async () => {
    const { onAddAcceptance } = this.context;
    const { selectedObjectProjectComponent: { projectComponentExpertiseId, userRoleId } } = this.state;

    await onAddAcceptance({
      objectProjectComponentExpertiseId: projectComponentExpertiseId,
      isAccepted: false,
      reason: EXPERTISE_DISAGREE,
      userRoleId,
    });
  };

  renderExpertise = isOwnerOrChief => <Container
    headerContent={<h5>Заключения экспертизы</h5>}
    isDefaultExpanded
    showToggle={false}
  >
    {this.props.object.expertise.map((expertise, index) => <ExpertiseContainer
      key={index}
      expertise={expertise}
      index={index}
      objectId={this.props.object.id}
      onOpenChangeStatusModal={this.handleOpenChangeStatusModal}
      onUpdateExpertise={this.context.onUpdateExpertiseFile}
      onDeleteExpertise={this.context.onDeleteExpertiseFile}
      onChangeStatusToPositive={this.context.onChangeStatusToPositive}
      onAddAcceptance={this.context.onAddAcceptance}
      onClickDisagree={this.handleClickDisagree}
    />)}
    {isOwnerOrChief && <Button
      onClick={this.showUploadExpertiseModal}
      IconComponent={ICONS.circlePlus}
      isBorderless
    >
      Добавить заключение Экспертизы
    </Button>}
  </Container>;

  render() {
    const { object, userId } = this.props;
    const { selectedObjectProjectComponent } = this.state;
    const isOwner = userId === object.userId;
    const isOwnerOrChief = isOwner || !!object.stages.find(({ chiefEngineer }) => chiefEngineer?.userId === userId);

    return <div className="object-management-layout-container">
      <Header
        isOwnerOrChief={isOwnerOrChief}
        title="Экспертиза"
        onClickChangeProjectComposition={this.handleClickChangeProjectComposition}
      />
      <ObjectInfo
        isOwner={isOwner}
        object={object}
      />
      <div className="divider" />
      {this.renderExpertise(isOwnerOrChief)}
      <ChangeExpertiseStatusModal
        onRef={this.changeExpertiseStatusRef}
        projectComponent={selectedObjectProjectComponent}
      />
      <UploadExpertiseModal
        onRef={this.uploadExpertiseModalRef}
        object={object}
      />
      <BreakContractModal
        onRef={this.breakContractModalRef}
        onAfterSubmit={this.handleAddAcceptance}
        initiatorReason={EXPERTISE_DISAGREE}
        currentPhase={selectedObjectProjectComponent.phase}
        contractId={selectedObjectProjectComponent.contractId}
      />
    </div>;
  }

  static contextTypes = {
    onUpdateExpertiseFile: PropTypes.func.isRequired,
    onDeleteExpertiseFile: PropTypes.func.isRequired,
    onChangeStatusToPositive: PropTypes.func.isRequired,
    onAddAcceptance: PropTypes.func.isRequired,
  };

  static propTypes = {
    object: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
  };
}
