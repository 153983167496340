import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../../../layout/menu/container';
import ContractItem from '../../finance/contracts/list/item';

export default class ContractHeader extends PureComponent {
  state = {
    isContractExpanded: false,
  };

  setItemState = isExpanded => {
    this.setState({ isContractExpanded: isExpanded });
  };

  render() {
    const { contract, onClickBreakContract, onClickReview } = this.props;
    const { isContractExpanded } = this.state;

    return <>
      <div className="page-header-menu">
        <Menu items={this.props.menu} isTabMenu />
      </div>
      {contract && <>
        <div className="contracts-list-layout">
          <ContractItem
            data={contract}
            setItemState={this.setItemState}
            isExpanded={isContractExpanded}
            isExpansionDisabled={false}
            hideOpenContractButton
            onClickBreakContract={onClickBreakContract}
            onClickReview={onClickReview}
          />
        </div>
      </>}
    </>;
  }

  static propTypes = {
    contract: PropTypes.object,
    menu: PropTypes.array.isRequired,
    onClickBreakContract: PropTypes.func,
    onClickReview: PropTypes.func,
  };

  static defaultProps = {
    onClickBreakContract: null,
    contract: null,
    onClickReview() {},
  }
}
