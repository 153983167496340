import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup } from 'react-bootstrap';
import { change, startSubmit, stopSubmit } from 'redux-form';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Input from '../input';
import Button from '../button';
import Confirm2OptionsModal from '../../modals/confirm/2options';
import { getRemoveFileSchema } from '../../modals/confirm/2options/schemas';
import { ICONS } from '../../../utils/icons';
import { DEFAULT_MAX_FILE_SIZE_MB, DEFAULT_ACCEPTED_FILE_TYPES } from '../../../utils/file';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { getFileLink } from '../../../utils/links';

import './index.scss';

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: {},
      isUploading: false,
    };
  }

  removeFileWarningModalRef = React.createRef();

  toggleWarning = () => {
    this.removeFileWarningModalRef.current?.toggleModal();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps.input;
    const { file: { remotePath: oldRemotePath } } = prevState;

    if (value.remotePath !== oldRemotePath) {
      return {
        ...prevState,
        file: value,
      };
    }

    return null;
  }

  handleUploadClick = () => {
    if (this.fileInput) {
      this.fileInput.value = null;
      this.fileInput.click();
    }
  };

  handleFileInputRef = ref => {
    this.fileInput = ref;
  };

  change(newValue) {
    const { meta: { form, dispatch }, input: { name, value }, onChange, multiple } = this.props;
    const { id, ...currentValue } = value;
    newValue = multiple && newValue || (newValue ? { ...currentValue, ...newValue } : null);

    if (dispatch) {
      dispatch(change(form, name, newValue));
    } else {
      onChange(newValue);
    }
  }

  removeFile = () => {
    this.toggleWarning();
    this.setState({ file: { originalName: '' } });
    this.fileInput.value = null;
    this.change(null);
  };

  handleChangeInput = event => {
    const { value } = event.target;
    const { meta: { dispatch, form }, input: { name } } = this.props;
    if (value) {
      dispatch(change(form, name, { title: value }));
    }
  };

  handleChange = async event => {
    const files = event.target.files;
    const { maxFileSize, meta: { form, dispatch } } = this.props;

    if (!files) {
      return;
    }

    if (files.size > maxFileSize * 1024 * 1024) {
      toast.warn(VALIDATION_MESSAGES.fileShouldBeLessThan.replace('MAX_FILE_SIZE_MB', maxFileSize));
      return;
    }

    this.setState({ isUploading: true });
    if (dispatch) {
      dispatch(startSubmit(form));
    }
    const uploadedFiles = await this.context.onFileUpload(files, this.props.storagePath).finally(() => {
      this.setState({
        isUploading: false,
      });
      if (dispatch) {
        dispatch(stopSubmit(form));
      }
    });
    this.change(uploadedFiles);
  };

  render() {
    const {
      className,
      label,
      showInput,
      showCustomNameInput,
      acceptedFileTypes,
      isReadonly,
      isDisabled,
      input,
      showWarningModal,
      meta: { error, touched },
      multiple,
      buttonText,
      name: inputName,
    } = this.props;
    const { file: { originalName, remotePath } } = this.state;
    const { name } = input;

    return <FormGroup
      className={classNames(
        'form-group-field',
        'file-uploader-component',
        { 'with-events': isReadonly },
        className,
      )}
      id={name}
    >
      <div className={classNames('file-uploader', { 'file-uploader-hidden': !originalName, 'show-text': showInput })}>
        {!multiple && showCustomNameInput && <Input
          name={`${name}.title`}
          className={classNames({ 'uploaded-file': originalName })}
          type="text"
          label={label}
          onChange={this.handleChangeInput}
        />}
        {(!showCustomNameInput || (showCustomNameInput && originalName)) && <div className="file-preview">
          {!showCustomNameInput && <label>{label}</label>}
          <a
            className={classNames('uploaded-file', { disabled: !remotePath })}
            href={getFileLink(remotePath)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>{originalName}</div>
          </a>
        </div>}
        <Input
          name={name || inputName}
          className="file-uploader-field"
          onRef={this.handleFileInputRef}
          type="file"
          onChange={this.handleChange}
          accept={acceptedFileTypes}
          multiple={multiple}
        />
        {!isReadonly && <div className="file-uploader-actions">
          <Button
            className="reload-file"
            onClick={this.handleUploadClick}
            IconComponent={ICONS.refresh}
            isBorderless
            isLoading={this.state.isUploading}
            tooltip="Загрузить файл"
          />
          <Button
            className="remove-file"
            onClick={showWarningModal ? this.toggleWarning : this.removeFile}
            IconComponent={ICONS.trash}
            isBorderless
            isDisabled={this.state.isUploading}
            tooltip="Удалить файл"
          />
        </div>}
      </div>
      {!originalName && !isReadonly && <Button
        className="file-uploader-button"
        IconComponent={multiple && ICONS.circlePlus}
        isBorderless={multiple}
        onClick={this.handleUploadClick}
        isLoading={this.state.isUploading}
        isDisabled={isDisabled}
      >
        {multiple ? buttonText : 'Загрузить'}
      </Button>}
      {error && touched && <FormControl.Feedback>{error}</FormControl.Feedback>}
      {showWarningModal && <Confirm2OptionsModal
        onRef={this.removeFileWarningModalRef}
        {...getRemoveFileSchema(this.removeFile, this.toggleWarning)}
      />}
    </FormGroup>;
  }

  static contextTypes = {
    onFileUpload: PropTypes.func,
  };

  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    }).isRequired,
    meta: PropTypes.shape({
      dispatch: PropTypes.func,
      form: PropTypes.string,
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    showInput: PropTypes.bool,
    showCustomNameInput: PropTypes.bool,
    isReadonly: PropTypes.bool,
    isDisabled: PropTypes.bool,
    showWarningModal: PropTypes.bool,
    maxFileSize: PropTypes.number,
    acceptedFileTypes: PropTypes.string,
    storagePath: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    label: 'Файл',
    showInput: false,
    showCustomNameInput: false,
    isReadonly: false,
    isDisabled: false,
    showWarningModal: false,
    multiple: false,
    buttonText: '',
    className: '',
    name: '',
    maxFileSize: DEFAULT_MAX_FILE_SIZE_MB,
    acceptedFileTypes: DEFAULT_ACCEPTED_FILE_TYPES,
    meta: {},
    onChange() {},
  }
}
