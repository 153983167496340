import { graphql } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import { compose, defaultProps, withStateHandlers } from 'recompose';

import Chat from './index';
import { parseChatMessages } from '../../graphql/model/chat';
import { withResubscribe } from '../../graphql/utils';

const sub = subscription => withResubscribe(subscription, {
  name: 'chatMessagesSub',
  skip: ({ id }) => !id,
  shouldResubscribe: true,
  options: ({ id, offset, limit }) => ({
    variables: {
      offset,
      limit,
      id,
    },
  }),
  props: ({ chatMessagesSub: { messages = [], loading: isLoading }, ownProps }, previousResult) => ({
    // we return previous messages if resubscribe was started (has 0 messages on loading moment)
    messages: isLoading && previousResult?.messages.length > 0 ? previousResult.messages : parseChatMessages(messages),
    isLoading,
  }),
});

const countSub = subscription => graphql(subscription, {
  name: 'chatMessagesCountSub',
  skip: ({ id }) => !id,
  options: ({ id }) => ({
    variables: { id },
  }),
  props: ({ chatMessagesCountSub: { count, loading: isLoading }, ownProps: { isLoading: isMessagesLoading } }) => ({
    count: count ? count.aggregate.count : 0,
    isLoading: isMessagesLoading && isLoading,
  }),
});

export const withGetChatMessages = ({
  ON_CHAT_MESSAGES_UPDATE_SUBSCRIPTION,
  ON_CHAT_MESSAGES_COUNT_UPDATE_SUBSCRIPTION,
}) => compose(
  defaultProps({
    offset: 0,
    limit: 50,
  }),
  withStateHandlers(
    ({ limit }) => ({ step: limit }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
    },
  ),
  sub(ON_CHAT_MESSAGES_UPDATE_SUBSCRIPTION),
  countSub(ON_CHAT_MESSAGES_COUNT_UPDATE_SUBSCRIPTION),
);

const mapStateToProps = ({ session }) => ({
  userId: session.user.id,
});

export default connect(mapStateToProps)(Chat);
