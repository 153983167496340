import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

export default class Switch extends PureComponent {
  renderLabel(text, isDisabled) {
    if (!text) {
      return null;
    }

    return <span className={classNames('label', { disabled: this.props.isToggle ? !isDisabled : isDisabled })}>
      {text}
    </span>;
  }

  handleChange = () => {
    const { isChecked, onChange, input } = this.props;
    onChange(!isChecked);
    input && input.onChange(!isChecked);
  };

  render() {
    const { id, labelLeft, labelRight, isChecked, isToggle, className, isDisabled } = this.props;
    return <div
      id={id}
      className={classNames('switch-container', { 'custom-toggle': isToggle, disabled: isDisabled }, className)}
    >
      {this.renderLabel(labelLeft, isChecked)}
      <label>
        <input
          type="checkbox"
          className="switch"
          checked={isChecked}
          disabled={isDisabled}
          onChange={this.handleChange}
        />
        <div className="visual-switch">
          <div />
        </div>
      </label>
      {this.renderLabel(labelRight, !isChecked)}
    </div>;
  }

  static propTypes = {
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isToggle: PropTypes.bool,
    labelLeft: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    labelRight: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    onChange: PropTypes.func,
    input: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    id: 'switch',
    isChecked: false,
    isDisabled: false,
    isToggle: false,
    labelLeft: '',
    labelRight: '',
    onChange() {},
    input: null,
    className: '',
  }
}
