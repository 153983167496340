import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../base/modal';
import { Form, Field } from 'redux-form';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import noop from 'lodash/noop';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class PublishVacancyModal extends React.PureComponent {
  handleClose = () => {
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handlePublishVacancy = data => {
    const { onPublishVacancy, onRef } = this.props;
    onPublishVacancy(data).then(() => {
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      isPerformer,
      vacancy,
      ...props
    } = this.props;
    const title = isPerformer ? 'Опубликовать лот' : 'Опубликовать вакансию';

    return <Modal {...props} className="publish-vacancy-modal" title={title}>
      <p className="warning">
        <ICONS.infoBold />
        <span>Победитель должен быть выбран заказчиком в течении суток после окончания приёма заявок, после вакансия становится не активна</span>
      </p>
      <Form onSubmit={handleSubmit(this.handlePublishVacancy)}>
        <Field
          name="applicationDeadline"
          component={FormGroupField}
          type="date"
          label="Окончание приема заявок"
          maxDate={new Date(vacancy.deadline)}
          minDate={new Date()}
          isMultiline
        />
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine || submitting}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Разместить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    onPublishVacancy: PropTypes.func.isRequired,
    onRef: PropTypes.object,
    vacancy: PropTypes.object.isRequired,
    isPerformer: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
  };
}
