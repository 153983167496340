import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';
import classNames from 'classnames';

import Button from '../button';
import { ICONS } from '../../../utils/icons';

import 'cropperjs/dist/cropper.css';
import './index.scss';
import Loading from '../loading';

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false,
    };

    props.onRef.current = this;
  }

  toggleModal = () => {
    const { isShown } = this.state;

    this.setState({ isShown: !isShown }, () => {
      if (isShown) {
        this.props.onClose();
      }
    });
  };

  render() {
    const { children, title, className, isLoading } = this.props;
    const { isShown } = this.state;

    return <BootstrapModal
      show={isShown}
      onHide={this.toggleModal}
      className={classNames('base-modal', className)}
      backdrop="static"
      centered
    >
      <BootstrapModal.Header>
        {title && <h3>{title}</h3>}
        <Button
          buttonProps={{
            name: 'closeModalDialogButton',
          }}
          onClick={this.toggleModal}
          IconComponent={ICONS.close}
          isBorderless
        />
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {isLoading && <div className="loading-screen"><Loading /></div>}
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>;
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onRef: PropTypes.object,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  children: '',
  className: '',
  onRef: {
    current: null,
  },
  onClose() {},
  isLoading: false,
};

export default Modal;
