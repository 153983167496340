import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import noop from 'lodash/noop';
import values from 'lodash/values';

import Modal from '../../base/modal';
import Switch from '../../base/switch';
import FormGroupField from '../../base/formGroup';
import Button from '../../base/button';
import { VACANCY_TYPE } from '../../../utils/vacancyType';
import WorkConditions from '../../contract/profile/workConditions';
import VacancyObjectDetails from './vacancyObjectDetails';
import Hint from '../../base/hint';

import './index.scss';

export default class CreateVacancyModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const { object, isDemoMode } = this.props;

    this.state = {
      showWorkConditions: object ? !isDemoMode : true,
      assignFromFavorites: object ? isDemoMode : false,
      isChiefEngineer: this.props.initialValues.isChiefEngineer && !this.props.isChiefEngineerAssigned,
      favoriteEmployees: [],
    };
  }

  componentDidUpdate(prevProps, prevState): void {
    if (!prevProps.object && this.props.object) {
      this.updateState();
    }
  }

  static getDerivedStateFromProps({ isChiefEngineerAssigned, favoriteEmployees }, { isChiefEngineer, showWorkConditions }) {
    return {
      favoriteEmployees: showWorkConditions ? favoriteEmployees.filter(employee => employee.isRequisitesFilled) : favoriteEmployees,
      ...(isChiefEngineer && isChiefEngineerAssigned ? { isChiefEngineer: false } : {}),
    };
  }

  updateState = () => {
    this.setState({
      showWorkConditions: !this.props.isDemoMode,
      assignFromFavorites: this.props.isDemoMode,
    });
  };

  toggleShowWorkConditions = value => {
    const { initialValues, change } = this.props;

    this.setState({
      showWorkConditions: value,
    });

    change('contractDetailType', '');
    change('paymentTerms', initialValues.paymentTerms);
    change('isSafeDeal', initialValues.isSafeDeal);
    change('competitionTerms', '');
    change('paymentTerms.phase1.deadline', value ? initialValues.paymentTerms.phase1.deadline : null);
  };

  toggleAssignFromFavorites = value => {
    const { isDemoMode } = this.props;
    this.setState({
      assignFromFavorites: value,
      showWorkConditions: !isDemoMode && !value ? !value : this.state.showWorkConditions,
    });
    this.props.change('employee', '');
  };

  toggleIsChiefEngineer = value => {
    this.setState({
      isChiefEngineer: value,
    });
  };

  handleCreateVacancy = data => {
    const { onCreateVacancy, onRef } = this.props;
    const { showWorkConditions } = this.state;
    const paymentTerms = showWorkConditions ? data.paymentTerms || {} : null;
    const competitionTerms = showWorkConditions ? data.competitionTerms || {} : null;
    onCreateVacancy({ ...data, paymentTerms, competitionTerms }).then(() => {
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  handleClose = () => {
    this.props.reset();
    if (!this.props.isDemoMode) {
      this.setState({
        showWorkConditions: true,
        assignFromFavorites: false,
        isChiefEngineer: false,
      });
    }
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      onRef,
      projectComponents,
      contractDetailTypes,
      object,
      stageName,
      deadline,
      selectedPhase1Deadline,
      selectedStageDeadline,
      projectComponentName,
      projectComponentType,
      isChiefEngineerAssigned,
      isChiefEngineerOnly,
      taxSystems,
      admissionTypes,
      isPerformer,
      change,
      initialValues,
      isDemoMode,
    } = this.props;
    const { showWorkConditions, isChiefEngineer, assignFromFavorites, favoriteEmployees } = this.state;

    return <Modal
      onRef={onRef}
      className="create-vacancy-modal"
      title={isPerformer
        ? `Добавить исполнителя ${projectComponentType.genitiveName}`
        : `Добавить вакансию ${isChiefEngineerOnly ? 'ГИПа' : 'инженера'} на объект`}
    >
      <Form onSubmit={handleSubmit(this.handleCreateVacancy)}>
        <VacancyObjectDetails
          object={object}
          stageName={stageName}
          deadline={deadline}
          selectedPhase1Deadline={selectedPhase1Deadline}
          projectComponent={{ name: projectComponentName, type: projectComponentType }}
        />
        {!isPerformer && !isChiefEngineerOnly && <Field
          name="isChiefEngineer"
          component={FormGroupField}
          type="switch"
          labelRight={VACANCY_TYPE.chief}
          labelLeft={VACANCY_TYPE.lead}
          onChange={this.toggleIsChiefEngineer}
          isChecked={isChiefEngineer}
          isDisabled={isChiefEngineerAssigned}
        />}
        {!isPerformer && !isChiefEngineer && <Field
          name="projectComponents"
          component={FormGroupField}
          type="select"
          label="Выбрать поручаемые работы"
          placeholder="Выбрать поручаемые работы"
          data={projectComponents}
          isMultiSelect
        />}
        <Switch
          id="assignFromFavorites"
          labelLeft={isPerformer ? 'Разместить ЛОТ на торги' : 'Разместить вакансию на конкурс'}
          labelRight={<>
            Назначить из избранного
            <Hint title="Условия договора">
              При назначении из избранного, минуя конкурс, условия договора начинают действовать сразу после того,
              как вторая сторона примет их. После акцептирования условий, соответствующий договор появится в вашем
              списке действующих договоров
            </Hint>
          </>}
          isDisabled={isDemoMode}
          onChange={this.toggleAssignFromFavorites}
          isChecked={assignFromFavorites}
          className="assign-from-favorites form-group"
        />
        {assignFromFavorites
          ? <Field
            name="employee"
            component={FormGroupField}
            type="select"
            label="Выбрать специалиста из избранного"
            data={favoriteEmployees}
            keepRemoveTag
          />
          : <div className="employee-placeholder" />}
        <Switch
          id="workConditions"
          labelLeft={<>
            Назначить без договора
            <Hint title="Условия договора">
              {`При выборе ${isPerformer ? '"Разместить ЛОТ на торги"' : '"Разместить вакансию на конкурс"'} создание условий договора обязательно.`}
            </Hint>
          </>}
          labelRight="Создать условия договора"
          onChange={this.toggleShowWorkConditions}
          isChecked={!isDemoMode && (showWorkConditions || !assignFromFavorites)}
          isDisabled={!assignFromFavorites || isDemoMode}
          className="show-work-details form-group"
        />
        {showWorkConditions && <>
          <Field
            name="contractDetailType"
            component={FormGroupField}
            type="select"
            label="Реквизиты для договора"
            data={contractDetailTypes}
          />
          <WorkConditions
            taxSystems={taxSystems}
            admissionTypes={admissionTypes}
            isCompetition={!assignFromFavorites}
            isPerformer={isPerformer}
            isEngineeringSurveyStage={values(object?.stages).find(({ name }) => name === stageName)?.icon === 'stageResearch'}
            initialValues={initialValues}
            selectedStageDeadline={selectedStageDeadline}
            projectComponentName={projectComponentType.dativeName}
            change={change}
          />
        </>}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine || submitting}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Добавить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    projectComponents: PropTypes.array,
    contractDetailTypes: PropTypes.array,
    object: PropTypes.object,
    stageName: PropTypes.string,
    deadline: PropTypes.instanceOf(Date),
    selectedStageDeadline: PropTypes.instanceOf(Date),
    selectedPhase1Deadline: PropTypes.instanceOf(Date),
    projectComponentName: PropTypes.string,
    projectComponentType: PropTypes.object,
    isChiefEngineerAssigned: PropTypes.bool,
    isChiefEngineerOnly: PropTypes.bool,
    favoriteEmployees: PropTypes.array,
    taxSystems: PropTypes.array,
    admissionTypes: PropTypes.array,
    onCreateVacancy: PropTypes.func,
    isPerformer: PropTypes.bool,
    change: PropTypes.func,
    initialValues: PropTypes.object,
    isDemoMode: PropTypes.bool,
  };

  static defaultProps = {
    onRef: React.createRef(),
    handleSubmit() {},
    projectComponents: [],
    contractDetailTypes: [],
    object: null,
    stageName: '',
    deadline: null,
    selectedStageDeadline: null,
    selectedPhase1Deadline: null,
    projectComponentName: '',
    projectComponentType: {
      name: '',
      accusativeName: '',
      dativeName: '',
      genitiveName: '',
    },
    isChiefEngineerAssigned: false,
    isChiefEngineerOnly: false,
    favoriteEmployees: [],
    taxSystems: [],
    admissionTypes: [],
    onCreateVacancy() {},
    isPerformer: false,
    change() {},
    initialValues: {},
    isDemoMode: null,
  };
}
