import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Header from '../header';
import ObjectInfo from '../objectInfo';
import EngineerList from './engineerList';
import ProjectComponentList from './projectComponentList';
import Menu from '../../../../layout/objectProjectComponentMenu/container';
import { ROUTES } from '../../../../utils/routes';

import './index.scss';

export default class StageManagement extends PureComponent {
  render() {
    const {
      object,
      stageId,
      selectedProjectComponentId,
      projectComponent,
      onClickChangeProjectComposition,
      onClickRemarks,
      onClickCreateEngineerVacancy,
      onClickCreatePerformerVacancy,
      onClickDeleteVacancy,
      onClickTurnInWork,
      onClickAcceptWork,
      onClickBreakContract,
      onClickMakeArbitrationDecision,
      onClickCopyVersionFiles,
      onClickOpenOpcChat,
      onClickOpenStageChat,
      onCreateDataRequest,
      showInfoModal,
      userId,
      isExpert,
    } = this.props;
    const { id, userId: objectOwnerId, stages } = object;
    const isOwner = objectOwnerId === userId;
    const isChiefEngineer = Object.keys(stages).some(stageId => stages[stageId].chiefEngineer?.userId === userId);
    const { stageEngineers, projectComponents } = object.stages[stageId];
    const stageMenu = projectComponents.map(({ id, processedCode }) => ({
      id,
      title: processedCode,
    }));

    if (projectComponents.length > 0 && !projectComponent) {
      return <Redirect to={ROUTES.notFound} />;
    }

    return <div className="stage-layout-container">
      <Header
        onClickChangeProjectComposition={onClickChangeProjectComposition}
        onClickRemarks={onClickRemarks}
        onClickOpenChat={onClickOpenStageChat}
        isOwnerOrChief={isOwner || isChiefEngineer}
        badgeCounter={projectComponent.remarksTotalCount}
        isObjectStage
        objectId={object.id}
        stageId={stageId}
      />
      {projectComponent && <Menu items={stageMenu} currentProjectComponentId={projectComponent.id} />}
      <ObjectInfo
        object={object}
        isOwner={isOwner}
      />
      <div className="divider" />
      <EngineerList
        engineers={stageEngineers}
        onClickCreateEngineerVacancy={onClickCreateEngineerVacancy}
        onClickBreakContract={onClickBreakContract}
        objectId={id}
        userId={userId}
        isOwner={isOwner}
      />
      <div className="divider" />
      {projectComponent?.id && <ProjectComponentList
        userId={userId}
        isExpert={isExpert}
        stageId={stageId}
        objectId={id}
        selectedProjectComponentId={selectedProjectComponentId}
        projectComponentId={projectComponent.id}
        items={projectComponent.flattenedProjectComponents}
        onClickCreatePerformerVacancy={onClickCreatePerformerVacancy}
        onClickDeleteVacancy={onClickDeleteVacancy}
        onClickTurnInWork={onClickTurnInWork}
        onClickAcceptWork={onClickAcceptWork}
        onClickBreakContract={onClickBreakContract}
        onClickMakeArbitrationDecision={onClickMakeArbitrationDecision}
        onClickCopyVersionFiles={onClickCopyVersionFiles}
        onClickOpenChat={onClickOpenOpcChat}
        onCreateDataRequest={onCreateDataRequest}
        showInfoModal={showInfoModal}
      />}
    </div>;
  }

  static propTypes = {
    object: PropTypes.object.isRequired,
    stageId: PropTypes.number.isRequired,
    selectedProjectComponentId: PropTypes.number.isRequired,
    projectComponent: PropTypes.object.isRequired,
    onClickChangeProjectComposition: PropTypes.func,
    onClickRemarks: PropTypes.func.isRequired,
    onClickCreateEngineerVacancy: PropTypes.func,
    onClickCreatePerformerVacancy: PropTypes.func,
    onClickDeleteVacancy: PropTypes.func.isRequired,
    onClickTurnInWork: PropTypes.func,
    onClickAcceptWork: PropTypes.func,
    onClickBreakContract: PropTypes.func,
    onClickMakeArbitrationDecision: PropTypes.func,
    onClickCopyVersionFiles: PropTypes.func.isRequired,
    onClickOpenOpcChat: PropTypes.func,
    onClickOpenStageChat: PropTypes.func,
    onCreateDataRequest: PropTypes.func.isRequired,
    showInfoModal: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    isExpert: PropTypes.bool,
  };

  static defaultProps = {
    projectComponent: null,
    isExpert: false,
    onClickChangeProjectComposition() {},
    onClickCreateEngineerVacancy() {},
    onClickCreatePerformerVacancy() {},
    onClickTurnInWork() {},
    onClickAcceptWork() {},
    onClickBreakContract() {},
    onClickMakeArbitrationDecision() {},
    onClickOpenOpcChat() {},
    onClickOpenStageChat() {},
  };
}
