import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

import { ICONS } from '../../../utils/icons';

import './index.scss';

const HintIcon = ICONS.hint;
// TODO: DRY with overflowTip
const popperConfig = {
  modifiers: [
    {
      name: 'flip',
      enabled: true,
      options: {
        fallbackPlacements: ['top', 'right'],
        flipVariations: false,
      },
    },
  ],
};

export default class Hint extends Component {
  state = {
    isHovered: false,
  };

  textElement = React.createRef();

  handleMouseOver = () => {
    this.setState({
      isHovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isHovered: false,
    });
  };

  render () {
    const { isHovered } = this.state;
    const { children, title, placement, className } = this.props;

    return <>
      <Overlay
        placement={placement}
        container={document.body}
        show={isHovered}
        target={this.textElement.current}
        popperConfig={popperConfig}
      >
        <Tooltip className={classNames('hint-tooltip', { [className]: !!className })} id="hintTooltip">
          <div className="hint-tooltip-title">{title}</div>{children}
        </Tooltip>
      </Overlay>
      <HintIcon
        ref={this.textElement}
        className="hint-icon"
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      />
    </>;
  }

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    title: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    className: PropTypes.string,
    placement: PropTypes.string,
  };

  static defaultProps = {
    children: '',
    className: '',
    title: '',
    placement: 'auto',
  }
}
