import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';

import { WORK_PHASES } from '../../../hints';
import Hint from '../../../base/hint';
import FormGroupField from '../../../base/formGroup';
import { getFormattedNumber } from '../../../../utils/numbers';

export default class PaymentTerms extends React.PureComponent {
  fieldPropsMap = null;

  getFieldProps = name => {
    const { isReadonly } = this.props;

    return this.fieldPropsMap && hasIn(this.fieldPropsMap, name)
      ? get(this.fieldPropsMap, name)
      : {
        component: FormGroupField,
        type: isReadonly ? 'text' : 'number',
        placeholder: 'Ввести значение',
        isDisabled: isReadonly,
        isBordered: isReadonly,
        min: 0,
        maxLength: 3,
        ...(isReadonly && { format: value => getFormattedNumber(value || 0) }),
      };
  };

  setFieldConfig = ({ color, message }) => ({
    ...(this.props.isReadonly && !this.props.isCompetition && {
      ...(color && { fieldClassName: `colored ${color}` }),
      inputWarning: message,
    }),
  });

  render() {
    const { showPhasesTermsOfPayment, isPerformer, isReadonly, paymentTermsProps, isExpertiseRequired } = this.props;
    const fieldPercentProps = {
      max: 100,
      step: 0.1,
      ...(isReadonly && { format: value => `${getFormattedNumber(value || 0)}%` }),
    };

    this.fieldPropsMap = mapValues(paymentTermsProps, ({ color, message, ...props }) => ({
      ...(Object.keys(props).length > 0
        ? {
          ...mapValues(props, data => ({
            ...this.getFieldProps(),
            ...this.setFieldConfig(data),
          })),
        }
        : {
          ...this.getFieldProps(),
          ...this.setFieldConfig({ color, message }),
        }
      ),
    }));

    return <>
      {showPhasesTermsOfPayment && <>
        <h6>Распределение суммы договора по этапам{WORK_PHASES}</h6>
        <Field
          {...this.getFieldProps('phase0.rate')}
          {...fieldPercentProps}
          name="paymentTerms.phase0.rate"
          label={<>
            Этап 0 (Аванс), % от суммы договора
            <Hint title="Этап 0 (Аванс)">
              Данный пункт оговаривает размер авансирования. Цифра ноль будет означать отсутствие аванса и
              перераспределение суммы договора на следующие этапы
            </Hint>
          </>}
        />
        <Field
          {...this.getFieldProps('phase1.rate')}
          {...fieldPercentProps}
          name="paymentTerms.phase1.rate"
          label={<>
            Этап I, Размер оплаты после приёмки выполненных работ, загруженных в раздел,<br />% от суммы договора
            <Hint title="Этап I">
              Данный пункт оговаривает размер оплаты, которая будет произведена сразу после приёмки заказчиком
              работ у всех исполнителей по всем разделам проектируемой стадии. Цифра ноль будет означать
              перераспределение оставшейся суммы договора на следующие этапы.
            </Hint>
          </>}
        />
        {isExpertiseRequired && <Field
          {...this.getFieldProps('phase2.rate')}
          {...fieldPercentProps}
          name="paymentTerms.phase2.rate"
          label={<>
            Этап II, Размер оплаты выполненных работ по отработке замечаний экспертизы, загруженных в раздел,<br />
            % от суммы договора
            <Hint title="Этап II">
              Данный пункт оговаривает размер оплаты, которая будет произведена, сразу после приёмки заказчиком работ,
              по результатам отработки замечаний экспертизы, у всех исполнителей по всем разделам проектируемой стадии.
              Цифра ноль будет означать перераспределение оставшейся суммы договора на следующие этапы.
            </Hint>
          </>}
        />}
        <Field
          {...this.getFieldProps('phase3.rate')}
          {...fieldPercentProps}
          name="paymentTerms.phase3.rate"
          label={<>Этап III, Размер окончательной оплаты, % от суммы договора
            <Hint title="Этап III">
              Данный пункт оговаривает размер оплаты, которая будет произведена, сразу после приёмки заказчиком работ,
              по результатам отработки замечаний заключительного этапа, у всех исполнителей по всем разделам
              проектируемой стадии. Цифра ноль означает перераспределение 100% суммы договора на предыдущие этапы.
            </Hint>
          </>}
        />
      </>}
      <h6>Временные ограничения</h6>
      {showPhasesTermsOfPayment && <Field
        {...this.getFieldProps('phase0.termLimit')}
        name="paymentTerms.phase0.termLimit"
        label={<>Срок оплаты Заказчиком гарантийного депозита перед началом работ,<br /> не более календарных дней с
          момента выбора победителя</>}
      />}
      {isPerformer && <Field
        {...this.getFieldProps('remarksFixTermLimit')}
        name="paymentTerms.remarksFixTermLimit"
        label={<>Срок отработки замечаний исполнителем по всем этапам, с момента выставления замечаний,<br /> не более календарных дней</>}
      />}
      <Field
        {...this.getFieldProps('remarksCreateTermLimit')}
        name="paymentTerms.remarksCreateTermLimit"
        label={isPerformer
          ? <>Срок выставления замечаний / принятия выполненных работ, загруженных в раздел, <br />
            не более, календарных дней</>
          : <>Срок выставления замечаний исполнителям, <br />
            с момента загрузки документации по всем разделам, не более, календарных дней</>}
      />
      {showPhasesTermsOfPayment && isExpertiseRequired && <Field
        {...this.getFieldProps('phase2.termLimit')}
        name="paymentTerms.phase2.termLimit"
        label={<>Срок, в который Заказчик обязуется зайти на экспертизу и выставить замечания (Этап II),<br /> не более,
          календарных дней</>}
      />}
      {showPhasesTermsOfPayment && <Field
        {...this.getFieldProps('phase3.termLimit')}
        name="paymentTerms.phase3.termLimit"
        label={<>Максимальная продолжительность завершающего Этапа III,<br /> не более,
          календарных дней</>}
      />}
      {!showPhasesTermsOfPayment && <Field
        {...this.getFieldProps('month.paymentTermLimit')}
        name="paymentTerms.month.paymentTermLimit"
        label={<>Срок оплаты текущего месяца,<br /> не более календарных дней с момента наступления срока</>}
      />}
      <h6>Штрафные санкции</h6>
      {!showPhasesTermsOfPayment && <Field
        {...this.getFieldProps('month.paymentDelayFine')}
        {...fieldPercentProps}
        name="paymentTerms.month.paymentDelayFine"
        label="Пеня за просрочку ежемесячной оплаты , % от суммы договора"
      />}
      {isExpertiseRequired && <Field
        {...this.getFieldProps('phase2.negativeExpertiseFine')}
        {...fieldPercentProps}
        name="paymentTerms.phase2.negativeExpertiseFine"
        label={<>Штраф за отрицательное заключение экспертизы (Этап II),<br /> % от суммы договора</>}
      />}
      <Field
        {...this.getFieldProps('worksDelayPenalty')}
        {...fieldPercentProps}
        name="paymentTerms.worksDelayPenalty"
        label={<>Пеня за просрочку выполнения работ и снятия замечаний исполнителем,
          <br /> % от суммы договора в день
        </>}
      />
    </>;
  }

  static propTypes = {
    showPhasesTermsOfPayment: PropTypes.bool.isRequired,
    isPerformer: PropTypes.bool.isRequired,
    isExpertiseRequired: PropTypes.bool.isRequired,
    paymentTermsProps: PropTypes.object,
    isReadonly: PropTypes.bool,
    isCompetition: PropTypes.bool,
  };

  static defaultProps = {
    paymentTermsProps: null,
    isReadonly: false,
    isCompetition: false,
  };
}
