import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import Container from '../../../../base/container';
import Button from '../../../../base/button';
import ProcessedCode from '../../../../base/processedCode';
import { ICONS } from '../../../../../utils/icons';

import './index.scss';

export default class StageItem extends PureComponent {
  itemExpandedStates = {};

  handleClickAdd = () => {
    const { onClickAdd, data, level } = this.props;
    onClickAdd(data, level + 1);
  };

  handleClickRemove = () => {
    const { onClickRemove, data } = this.props;
    onClickRemove(data);
  };

  handleClickEdit = parentProjectComponent => {
    const { onClickEdit, data, level } = this.props;
    onClickEdit(data, level, parentProjectComponent);
  };

  setItemState = index => isExpanded => {
    this.itemExpandedStates[index] = isExpanded;
  };

  getItemState = index => () => !!this.itemExpandedStates[index];

  renderHeader = ({
    name,
    code,
    processedCode,
    childType,
    projectComponents,
    hasVacancies,
  }, parentProjectComponent) => <div className="project-component-header">
    <h6>{name}&nbsp;{code && `(${code})`}</h6>
    <div className="project-component-code-and-buttons">
      <ProcessedCode isLoading={!processedCode}>
        <h6>{processedCode}</h6>
      </ProcessedCode>
      <div className="action-buttons">
        <div>
          <Button
            onClick={() => this.handleClickEdit(parentProjectComponent)}
            IconComponent={ICONS.pencil}
            isBorderless
            isDisabled={projectComponents?.length > 0}
          />
        </div>
        <div>
          {childType && <Button
            onClick={this.handleClickAdd}
            IconComponent={ICONS.circlePlus}
            isBorderless
          />}
        </div>
        <div>
          {!hasVacancies && <Button
            onClick={this.handleClickRemove}
            IconComponent={ICONS.trash}
            isBorderless
          />}
        </div>
      </div>
    </div>
  </div>;

  render() {
    const {
      data,
      parentProjectComponent,
      level,
      onClickAdd,
      onClickEdit,
      onClickRemove,
      setItemState,
      getItemState,
    } = this.props;
    const hasChildProjectComponents = get(data, 'projectComponents.length') > 0;

    return <div
      className={classNames(
        'project-composition-project-component-wrapper',
        `level${level}`,
      )}
    >
      <Container
        id={data.id}
        isDefaultExpanded={getItemState()}
        shareExpandedState={setItemState}
        className="project-component"
        headerContent={this.renderHeader(data, parentProjectComponent)}
        showToggle={hasChildProjectComponents}
      >
        {hasChildProjectComponents && data.projectComponents.map((dataItem, index) =>
          <StageItem
            key={index}
            level={level + 1}
            data={dataItem}
            parentProjectComponent={data}
            onClickAdd={onClickAdd}
            onClickEdit={onClickEdit}
            onClickRemove={onClickRemove}
            getItemState={this.getItemState(index)}
            setItemState={this.setItemState(index)}
          />,
        )}
      </Container>
    </div>;
  }

  static propTypes = {
    level: PropTypes.number.isRequired,
    data: PropTypes.object,
    parentProjectComponent: PropTypes.object,
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickRemove: PropTypes.func,
    setItemState: PropTypes.func,
    getItemState: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    parentProjectComponent: {},
    onClickAdd() {},
    onClickEdit() {},
    onClickRemove() {},
    setItemState() {},
    getItemState() {},
  };
}
