import { branch, compose } from 'recompose';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';

import withUserBalanceReferencesSubscription from '../../graphql/hoc/userBalanceReferences';
import { ROUTES } from '../../utils/routes';

import Finance from './index';

const mapStateToProps = ({ session }, { location }) => ({
  userId: session.user?.id,
  objectId: matchPath(location.pathname, ROUTES.objectContracts)?.params.id,
  isContractRequisitesPage: !!matchPath(location.pathname, ROUTES.financeContractRequisites),
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  branch(
    ({ objectId }) => !objectId,
    withUserBalanceReferencesSubscription,
  ),
)(Finance);
