import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';
import { showError } from '../../api/error';
import { getBalance } from '../model/user';

const ON_PROFILE_AGGREGATION_UPDATE_SUBSCRIPTION = loader('../../graphql/queries/profile/aggregationSubscription.graphql');

export default graphql(ON_PROFILE_AGGREGATION_UPDATE_SUBSCRIPTION, {
  name: 'userAggregationSub',
  skip: ({ userId }) => !userId,
  options: ({ userId }) => ({
    variables: {
      userId,
    },
  }),
  props: ({ userAggregationSub: { user = [{}], error, loading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      balance: getBalance(user[0]?.transactions),
      isProfileAggregationSubscriptionLoading: loading,
    };
  },
});
