import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { prepareInvitationToFavoriteTitle } from '../common';
import ProfileLink from '../../../base/profileLink';

const SEND_INVITATION_SCHEMA = {
  title: 'Пользователь с таким e-mail не зарегистрирован в системе! Отправить приглашение?',
  options: [
    {
      buttonTitle: 'Да, отправить приглашение',
      hint: `На указанный вами e-mail придет приглашение,
        в случае регистрации он будет добавлен в ваш список избранных`,
      action() {},
    },
    {
      buttonTitle: 'Нет, не отправлять приглашение',
      hint: 'Приглашение не будет отправлено',
      action() {},
    },
    // {
    //   buttonTitle: 'Отправить заполненный профиль',
    //   hint: `В этом случае приглашенный получит приглашение с уже заполненным профилем,
    //     который сможет редактировать на свое усмотрение`,
    //   action() {},
    // },
  ],
};

const INVITATION_TO_FAVORITES_SCHEMA = {
  getTitle: function getTitle(id, name, type) {
    return <>Пользователь <ProfileLink userId={id} text={name} />
      &nbsp;хочет добавить вас к себе в избранные контакты как {type}
    </>;
  },
  description: 'Указанный вами e-mail в профиле не совпадает с тем, на который выслано данное приглашение',
  options: [
    {
      buttonTitle: 'Разрешить, добавить в избранное',
      hint: 'Приглашающий будет знать другой ваш e-mail',
      action() {},
    },
    {
      buttonTitle: 'Отказать, не добавлять в избранное',
      hint: 'В случае отказа пользователь не будет знать, что вы отказали, приглашение исчезнет из его списка',
      action() {},
    },
    {
      buttonTitle: 'Создать профиль с новым e-mail',
      hint: `При создании еще одного профиля ваш наработанный рейтинг в системе не мигрирует,
        вам придется нарабатывать рейтинг нового профиля с нуля`,
      action() {},
    },
  ],
  hideCloseButton: true,
};

export const getSchema = (initSchema, actions) => {
  const schema = cloneDeep(initSchema);
  schema.options = schema.options
    .filter((option, index) => actions[index])
    .map((option, index) => ({
      ...option,
      action: actions[index],
    }));

  return schema;
};

export const getSendInvitationSchema = (...actions) => getSchema(SEND_INVITATION_SCHEMA, actions);

export const getInvitationToFavorites = (invitingUser, isPerformer, ...actions) => {
  const schema = getSchema(INVITATION_TO_FAVORITES_SCHEMA, actions);
  schema.title = prepareInvitationToFavoriteTitle(isPerformer, schema.getTitle, invitingUser);
  return schema;
};
