import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Rating from '../rating';

import './index.scss';

class UserRating extends PureComponent {
  render() {
    return <div className="rating-layout">
      {Object.keys(this.props.ratingSettings).map((key, index) => <div key={index}>
        <span>{key}</span>
        <Rating value={this.props.ratingSettings[key]} />
      </div>)}
    </div>;
  }
}

UserRating.propTypes = {
  ratingSettings: PropTypes.object.isRequired,
};

export default UserRating;
