import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, matchPath } from 'react-router-dom';

import NotImplementedYet from '../../components/notImplementedYet';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';
import { ROUTES } from '../../utils/routes';
import TasksHeader from '../../components/tasks/header';
import TaskModal from '../../components/modals/task/container';
import { getTasksMenuChildren } from '../../utils/menu';
import InfoModal from '../../components/modals/info';
import { HAS_NO_OBJECT_USERS } from '../../utils/messages';
import Confirm2OptionsModal from '../../components/modals/confirm/2options';
import { getRemoveTaskSchema } from '../../components/modals/confirm/2options/schemas';
import CopyFileModal from '../../components/modals/copyFile/container';

export default class TasksMenu extends PureComponent {
  state = {
    selectedTask: {},
    selectedFileId: null,
    isMessage: false,
    isEdit: false,
    isAnswer: false,
    isReassign: false,
  };

  objectRoute = matchPath(this.props.location.pathname, ROUTES.objectTasks);
  tasksMenu = getTasksMenuChildren(!!this.objectRoute);

  createTaskModalRef = React.createRef();
  objectUsersInfoModalRef = React.createRef();
  closeTaskModalRef = React.createRef();
  removeTaskModalRef = React.createRef();
  copyFileModalRef = React.createRef();

  handleOpenTaskModal = (task, isMessage = false, isEdit = false, isAnswer = false, isReassign = false) => {
    if (this.objectRoute && !this.props.hasUsers) {
      this.objectUsersInfoModalRef.current.toggleModal();
    } else {
      if (task || this.objectRoute) {
        this.setState({
          selectedTask: task || { objectId: Number(this.objectRoute.params.id) },
          isMessage,
          isEdit,
          isAnswer,
          isReassign,
        });
      }

      this.createTaskModalRef.current.toggleModal();
    }
  };

  handleCloseTaskModal = () => {
    this.setState({
      selectedTask: {},
      isMessage: false,
      isEdit: false,
      isAnswer: false,
      isReassign: false,
    });
  };

  goToTasks = () => {
    const path = this.props.location.pathname;
    this.props.history.push(this.objectRoute ? ROUTES.objectTasks : ROUTES.tasks);
    this.props.history.replace(path);
  };

  handleToggleCloseTaskModal = (task = {}) => {
    this.setState({ selectedTask: task });
    this.closeTaskModalRef.current.toggleModal();
  };

  handleCloseTask = async () => {
    await this.props.onCloseTask(this.state.selectedTask.id);
    this.goToTasks();
    this.handleToggleCloseTaskModal();
  };

  handleRemoveTask = async () => {
    await this.props.deleteTask({ variables: { id: this.state.selectedTask.id } });
    this.goToTasks();
    this.handleToggleRemoveTaskModal();
  };

  handleReadTaskMessage = async id => {
    await this.props.onReadTaskMessage(id);
  };

  handleToggleRemoveTaskModal = (task = {}) => {
    this.setState({ selectedTask: task });
    this.removeTaskModalRef.current.toggleModal();
  };

  handleCopyTaskFile = fileId => {
    this.setState({ selectedFileId: fileId });
    this.copyFileModalRef.current.toggleModal();
  };

  handleCloseCopyFileModal = () => {
    this.setState({ selectedFileId: null });
  };

  render() {
    const { isMessage, isEdit, isAnswer, isReassign, selectedTask, selectedFileId } = this.state;

    return <>
      <TasksHeader menu={this.tasksMenu} onClickAddTask={this.handleOpenTaskModal} />
      <Switch>
        {this.tasksMenu.map(({ route, name, component: Component = NotImplementedYet }, index) =>
          <WebAnalyticsRoute
            key={index}
            path={route}
            name={name}
            render={props => <Component
              objectId={Number(this.objectRoute?.params.id)}
              isOutgoing={props.match.path === (this.objectRoute ? ROUTES.objectOutgoingTasks : ROUTES.outgoingTasks)}
              isIncoming={props.match.path === (this.objectRoute ? ROUTES.objectIncomingTasks : ROUTES.incomingTasks)}
              isDelegated={props.match.path === (this.objectRoute ? ROUTES.objectDelegatedTasks : ROUTES.delegatedTasks)}
              isArchive={props.match.path === (this.objectRoute ? ROUTES.objectTasksArchive : ROUTES.tasksArchive)}
              menu={this.tasksMenu}
              onClickTaskAction={this.handleOpenTaskModal}
              onClickReadTaskMessage={this.handleReadTaskMessage}
              onClickCloseTaskModal={this.handleToggleCloseTaskModal}
              onClickRemoveTaskModal={this.handleToggleRemoveTaskModal}
              onClickCopyTaskFile={this.handleCopyTaskFile}
            />}
          />)}
        <Redirect to={this.objectRoute ? ROUTES.objectIncomingTasks : ROUTES.incomingTasks} />
      </Switch>
      <TaskModal
        onRef={this.createTaskModalRef}
        task={selectedTask}
        isMessage={isMessage}
        isEdit={isEdit}
        isAnswer={isAnswer}
        isReassign={isReassign}
        goToTasks={this.goToTasks}
        onClose={this.handleCloseTaskModal}
      />
      <Confirm2OptionsModal
        onRef={this.closeTaskModalRef}
        title="Вы действительно хотите завершить задачу?"
        dismissButtonTitle="Отмена"
        successButtonTitle="Завершить"
        onDismiss={this.handleToggleCloseTaskModal}
        onSuccess={this.handleCloseTask}
      />
      <Confirm2OptionsModal
        onRef={this.removeTaskModalRef}
        {...getRemoveTaskSchema(
          selectedTask,
          this.handleRemoveTask,
          this.handleToggleRemoveTaskModal,
        )}
      />
      <CopyFileModal
        onRef={this.copyFileModalRef}
        fileId={selectedFileId}
        onClose={this.handleCloseCopyFileModal}
      />
      {this.objectRoute && <InfoModal onRef={this.objectUsersInfoModalRef} message={HAS_NO_OBJECT_USERS} />}
    </>;
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    hasUsers: PropTypes.bool,
    onCloseTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    onReadTaskMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasUsers: false,
  };
}
