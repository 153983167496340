import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getInvitationToFavorites as get2Options } from '../../components/modals/confirm/2options/schemas';
import { getInvitationToFavorites as get3Options } from '../../components/modals/confirm/3options/schemas';
import Confirm2OptionsModal from '../../components/modals/confirm/2options';
import Confirm3OptionsModal from '../../components/modals/confirm/3options';
import { ROUTES } from '../../utils/routes';
import InfoModal from '../../components/modals/info';
import {
  API_ERROR_MESSAGES,
  UI_DEFAULT_ERROR_MESSAGES,
  UI_ERROR_MESSAGES,
} from '../../utils/messages';
import { showError } from '../../api/error';
import { checkAuthCookie } from '../../utils/session';
import AuthorizedApi from '../../api/authorized';
import TokenizedApi from '../../api/tokenized';

class CheckInvitation extends Component {
  constructor(props) {
    super(props);

    this.invitationModalRef = React.createRef();
    this.infoModalRef = React.createRef();
    this.createProfileModalRef = React.createRef();

    this.state = {
      currentUser: null,
      data: null,
      invitationInvalid: false,
    };
  }

  async componentDidMount() {
    const { isLoading, match: { params: { token } } } = this.props;

    if (isLoading) {
      return;
    }

    let hasError = false;

    const currentUser = checkAuthCookie() && await AuthorizedApi.claim();
    const data = await TokenizedApi.getInvitationData(token)
      .catch(error => {
        if (error.name === 'FavoriteError') {
          showError(error, UI_ERROR_MESSAGES[API_ERROR_MESSAGES.invitationInvalid]);
        } else {
          showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        }
        hasError = true;
      });

    this.setState({
      currentUser,
      data,
    });
    if (hasError) {
      this.props.history.goBack();
    } else {
      this[data ? 'invitationModalRef' : 'infoModalRef'].current.toggleModal();
    }
  }

  handleClose = () => {
    this.props.history.goBack();
  };

  handleSuccess = () => {
    const { match: { params: { token } } } = this.props;

    return TokenizedApi.confirmInvitation(token)
      .then(() => {
        this.invitationModalRef.current.toggleModal();
        return this.handleClose();
      })
      .catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.addToFavorites);
        throw error;
      });
  };

  handleDismiss = () => {
    const { match: { params: { token } } } = this.props;

    return TokenizedApi.declineInvitation(token)
      .then(() => {
        this.invitationModalRef.current.toggleModal();
        return this.handleClose();
      })
      .catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.addToFavorites);
        throw error;
      });
  };

  handleRelogin = () => {
    const { match: { params: { token } }, history } = this.props;
    return TokenizedApi.confirmInvitation(token, true)
      .then(() => {
        history.push(ROUTES.profile);
      })
      .catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.addToFavorites);
        throw error;
      });
  };

  render() {
    const { currentUser, data, invitationInvalidMessage } = this.state;
    let InvitationModal = Confirm2OptionsModal;
    let options = get2Options;

    if (currentUser && currentUser.id !== data?.favorite.favoriteUser.id) {
      InvitationModal = Confirm3OptionsModal;
      options = get3Options;
    }

    return <>
      {data && <InvitationModal
        onRef={this.invitationModalRef}
        {...options(
          { userId: data.userId, fullName: data.fullName },
          data.favorite.isPerformer,
          this.handleSuccess,
          this.handleDismiss,
          this.handleRelogin,
        )}
      />}
      <InfoModal
        onRef={this.infoModalRef}
        message={invitationInvalidMessage}
        onClick={this.handleClose}
      />
    </>;
  }
}

CheckInvitation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  checkAuthentication: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};

export default CheckInvitation;
