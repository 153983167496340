import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import { showError } from '../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../utils/messages';

const GET_EXPERT_USER = loader('../queries/user/getExpertUserReferences.graphql');

export default graphql(GET_EXPERT_USER, {
  name: 'getExpertUser',
  skip: ({ userId }) => !userId,
  options: ({ userId }) => ({
    variables: {
      id: userId,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getExpertUser }) => {
    const {
      user = [],
      error,
    } = getExpertUser;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      isExpert: !!user[0],
    };
  },
});
