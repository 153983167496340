import React from 'react';
import { Form, Field } from 'redux-form';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import Input from '../../base/input';
import { ICONS } from '../../../utils/icons';

import './index.scss';

export default class BreakContractModal extends React.PureComponent {
  handleClose = () => {
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleBreakContract = data => {
    const { onBreakContract, onRef, onAfterSubmit } = this.props;
    onBreakContract(data).then(() => {
      onAfterSubmit();
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  renderActionButtons = isForm => {
    const { submitting, pristine, isBreakPending } = this.props;

    return <div className="actions">
      <Button
        onClick={this.handleCancel}
        isBorderless
        isLarge
      >
        Отмена
      </Button>
      <Button
        isLarge
        isDisabled={isForm && (pristine || submitting)}
        isLoading={submitting}
        {...(isForm ? { buttonProps: { type: 'submit' } } : { onClick: this.handleBreakContract })}
      >
        {isBreakPending ? 'Изменить условия расторжения' : 'Расторгнуть договор'}
      </Button>
    </div>;
  };

  renderInfo = () => <>
    <p className="warning">
      <ICONS.infoBold />
      <span>Запуск процедуры расторжения договора закрывает нанимаемой стороне доступ к объекту</span>
    </p>
    {this.renderActionButtons()}
  </>;

  renderWithOptions = () => {
    const {
      handleSubmit,
      initiatorBreakOption,
      initiatorReason,
      options,
    } = this.props;

    return <>
      <p className="warning">
        <ICONS.infoBold />
        <span>
          {initiatorBreakOption
            ? 'При выборе отличных от предложенных второй стороной условий расторжения, будет начат процесс рассмотрения спора в арбитраже'
            : 'Запуск процедуры расторжения договора закрывает нанимаемой стороне доступ к объекту'}
        </span>
      </p>
      <Form onSubmit={handleSubmit(this.handleBreakContract)}>
        <Field
          name="contractBreakOptionId"
          component={FormGroupField}
          type="select"
          label="Выберите Ваши условия расторжения из списка"
          placeholder="Выберите условие"
          data={options}
        />
        {initiatorBreakOption
          ? <>
            <div className="form-group initiator-choice">
              <Input
                name="initiatorChoice"
                label="Позиция другой стороны"
                isDisabled
                value={initiatorBreakOption}
              />
            </div>
            <div className="form-group initiator-reason">
              <Input
                name="reason"
                label="Причина"
                isDisabled
                value={initiatorReason}
              />
            </div>
          </>
          : <Field
            name="reason"
            component={FormGroupField}
            type="text"
            label="Причина"
            isReadonly={!!initiatorReason}
            value={initiatorReason}
          />}
        {this.renderActionButtons(true)}
      </Form>
    </>;
  };

  render() {
    const {
      isBreakPending,
      ...props
    } = this.props;
    const isInWorkPhase = props.currentPhase?.id > 1;

    return <Modal
      {...props}
      className="break-contract-modal"
      title={!isBreakPending && isInWorkPhase ? 'Определение условий расторжения договора' : 'Расторжение договора'}
    >
      {isInWorkPhase ? this.renderWithOptions() : this.renderInfo()}
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    onBreakContract: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    change: PropTypes.func,
    onRef: PropTypes.object,
    initiatorBreakOption: PropTypes.string,
    initiatorReason: PropTypes.string,
    options: PropTypes.array,
    contractId: PropTypes.number,
    onAfterSubmit: PropTypes.func,
    isBreakPending: PropTypes.bool,
  };

  static defaultProps = {
    change() {},
    onRef: {
      current: null,
    },
    initiatorBreakOption: '',
    initiatorReason: '',
    options: [],
    contractId: null,
    onAfterSubmit() {},
    isBreakPending: false,
  };
}
