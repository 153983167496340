import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Menu from '../../../layout/menu/container';

export default class VacanciesHeader extends PureComponent {
  render() {
    return <div className="page-header-menu">
      <Menu items={this.props.menu} isTabMenu />
    </div>;
  }

  static propTypes = {
    menu: PropTypes.array.isRequired,
  };
}
