export const THANKS_FOR_REGISTRATION = 'Пожалуйста, проверьте указанный e-mail адрес для подтверждения регистрации';
export const INVITATION_SENT = 'Приглашение отправлено на указанный e-mail адрес';
export const FAVORITE_ADDED = 'Пользователь добавлен в избранное';
export const SELF_INVITATION = 'Пользователь не может быть добавлен в собственный список избранного';
export const RESET_PASSWORD_SENT = 'На указанный e-mail адрес отправлены инструкции по сбросу пароля';
export const PASSWORD_CHANGED_SUCCESSFULLY = 'Пароль успешно изменён';
export const CHECK_YOUR_INTERNET_CONNECTION = 'Проверьте соединение с интернетом';
export const SESSION_EXPIRED = 'Время сессии истекло. Пожалуйста, авторизуйтесь снова';
export const EMAIL_CONFIRMATION_SENT = 'Ссылка для подтверждения отправлена на указанный e-mail адрес';
export const EMAIL_EXISTS = 'В связи с тем, что Вы уже зарегистрировались в системе минуя сервис авторизации AUTH_SERVICE, необходимо использовать указанный Вами при регистрации пароль. Авторизация через AUTH_SERVICE теперь невозможна';
export const HAS_NO_TARIFF_PLAN = 'У вас отсутствует свободный слот для создания нового объекта';
export const CREATE_OBJECT_EMPTY_REQUISITES = 'Для создания объекта необходимо указать действующие реквизиты';
export const CREATE_TARIFF_PLAN_EMPTY_REQUISITES = 'Для добавления тарифа необходимо заполнить действующие реквизиты';
export const CREATE_VACANCY_EMPTY_REQUISITES = 'Для создания вакансии необходимо указать действующие реквизиты';
export const MAKE_BID_EMPTY_REQUISITES = 'Чтобы сделать ставку, необходимо указать действующие реквизиты';
export const EMPTY_CONTRACT_DETAILS_REMINDER = 'Не забудьте заполнить реквизиты для договора';
export const EMPTY_CONTRACT_DETAILS_WARNING = 'Вы не сможете приступить к работе, пока реквизиты не заполнены';
export const HAS_NO_OBJECT_USERS = 'Вы не можете добавить задачу, так как на объекте нет активных участников';
export const WITHDRAWAL_NOT_POSSIBLE = 'При отрицательном балансе вывод средств невозможен';
export const PROJECT_COMPONENT_CANNOT_BE_DELETED = 'PROJECT_COMPONENT не может быть DELETED при наличии связанных договоров/вакансий';
export const YOU_MUST_BE_AUTHORIZED = 'Для продолжения необходимо авторизоваться';

export const API_ERROR_MESSAGES = {
  unconfirmedEmailExists: 'Specified email exists but unconfirmed',
  userEmailAlreadyRegistered: 'User with this email has already been registered',
  thereIsNoAppropriateUser: 'There is no appropriate user',
  thePasswordIsIncorrect: 'The password is incorrect',
  userExistsInFavorite: 'This user already exists in your favorite',
  invitationEmailSend: 'An invitation email has already been sent to this user',
  invitationExpired: 'An invitation is expired',
  invitationInvalid: 'An invitation is invalid',
  networkError: 'Network Error',
  userBalanceIsNotEnough: 'GraphQL error: User balance is not enough',
};

export const UI_ERROR_MESSAGES = {
  [API_ERROR_MESSAGES.unconfirmedEmailExists]: 'Пользователь с указанным e-mail адресом уже зарегистрирован. ' +
    'Вы можете воспользоваться функцией восстановления пароля',
  [API_ERROR_MESSAGES.userEmailAlreadyRegistered]: 'Пользователь с указанным e-mail адресом уже зарегистрирован',
  [API_ERROR_MESSAGES.thereIsNoAppropriateUser]: 'Пользователь не найден',
  [API_ERROR_MESSAGES.thePasswordIsIncorrect]: 'Неверный пароль',
  [API_ERROR_MESSAGES.userExistsInFavorite]: 'Пользователь с указанным e-mail адресом уже присутствует в списке избранного',
  [API_ERROR_MESSAGES.invitationEmailSend]: 'Письмо с приглашением уже было отправлено на указанный e-mail адрес',
  [API_ERROR_MESSAGES.invitationExpired]: 'Приглашение недействительно',
  [API_ERROR_MESSAGES.invitationInvalid]: 'Некорректное приглашение',
  [API_ERROR_MESSAGES.networkError]: CHECK_YOUR_INTERNET_CONNECTION,
  [API_ERROR_MESSAGES.userBalanceIsNotEnough]: 'Баланс пользователя недостаточен для операции',
};

export const UI_DEFAULT_ERROR_MESSAGES = {
  dataBase: 'Ошибка базы данных',
  signIn: 'Ошибка входа',
  signUp: 'Ошибка регистрации',
  addToFavorites: 'Ошибка добавления пользователя в список избранного',
  sendInvitationToFavorites: 'Ошибка отправки приглашения',
  server: 'Ошибка сервера',
  downloadBundle: 'Ошибка скачивания архива',
};

export const LIST_MESSAGES = {
  emptyContracts: 'Список договоров пуст',
};

export const INFO_MESSAGES = {
  prepaidTariff: 'Тариф станет активным только после оплаты счета',
  postpaidTariff: 'По истечению каждого месяца с вашего баланса будет списываться стоимость данного тарифного плана ' +
    'до тех пор пока вы не отмените данную подписку',
  versionCannotBeCreatedDueToRemarks: 'Вы не можете добавить новую версию, пока не ответите на все замечания',
  workCannotBeHandedOverDueToRemarks: 'Вы не можете сдать работу, пока не ответите на все замечания',
  createNewVersionFirst: 'Вы не можете сдать работу, пока не загружена новая версия после отработки замечаний',
  bothVersionFileTypesRequired: 'Вы не можете сдать работу, пока в версию не загружены "Исходные файлы в редактируемом формате" и "PDF файл"',
  keepObjectData: 'Для недопущения потери данных вы можете прикрепить объект к любому другому тарифу со свободным слотом или воспользоваться функцией создания архива всех файлов по объекту',
  beSureSendToRegistryButtonIsClicked: 'Не забудьте перед закрытием окна браузера нажать кнопку “Отправить в реестр” иначе замечания не сохранятся!',
  addingExpertiseRemarksOnlyOnPhase2: 'Выставление замечаний по экспертизе доступно только на Этапе II',
};
