import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { DEFAULT_AUTHORIZED_ROUTE } from '../../utils/routes';
import Loading from '../../components/base/loading';
import WebAnalyticsRoute from './webAnalyticsRoute';
import { checkAuthCookie } from '../../utils/session';

class PublicRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isAuthorized: false,
    };
  }

  componentDidMount() {
    this.setState({ isAuthorized: checkAuthCookie(), isLoaded: true });
  }

  render() {
    const { component: Component, authorizedRoute, ...routeProps } = this.props;
    const { isLoaded, isAuthorized } = this.state;

    if (!isLoaded) {
      return <Loading className="auth-loading" />;
    }

    return (
      <WebAnalyticsRoute
        {...routeProps}
        render={props =>
          isAuthorized
            ? <Redirect to={{ pathname: authorizedRoute || DEFAULT_AUTHORIZED_ROUTE }} />
            : <Component {...props} />}
      />
    );
  }

  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.element,
      PropTypes.func,
    ]),
    authorizedRoute: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    component: () => {},
    authorizedRoute: null,
  };
}

const mapStateToProps = ({ session: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(PublicRoute));
