import { VACANCY_TYPE } from '../../utils/vacancyType';

const getRoleLabel = roleName => {
  let label = roleName;

  if (roleName === VACANCY_TYPE.lead) {
    label += ' (список разделов укажу ниже)';
  }

  if (roleName === VACANCY_TYPE.performer) {
    label += ' разделов или Проектная организация';
  }

  return label;
};

export const formatUserRoles = (items, selectedItemId) => (items || []).map(role => ({
  label: getRoleLabel(role.name),
  value: role,
  ...(selectedItemId === role.id && { checked: true }),
}));
