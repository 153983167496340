import find from 'lodash/find';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import { formatRating } from './rating';
import { normalizeMoney } from '../../utils/money';

export const PRIVACY_SETTINGS_FIELDS = {
  rating: [
    {
      name: 'rating.1',
      label: 'Показывать в списке ГИПов',
    },
    {
      name: 'rating.2',
      label: 'Показывать в списке Ведущих Инженеров',
    },
    {
      name: 'rating.3',
      label: 'Показывать в списке Исполнителей',
    },
    {
      name: 'rating.4',
      label: 'Показывать в списке Заказчиков',
    },
  ],
  public: [
    {
      name: 'public.personalData',
      label: 'Показывать блок "Личные данные"',
    },
    {
      name: 'public.organizationData',
      label: 'Показывать блок "Данные организации"',
    },
    {
      name: 'public.competenceConfirmation',
      label: 'Показывать блок "Подтверждение компетенции"',
    },
    {
      name: 'public.specialization',
      label: 'Показывать блок "Специализация"',
    },
  ],
};

export const formatUser = (user, userRoles) => {
  if (!user) {
    return {};
  }

  const {
    userRegions: rawUserRegions = [],
    userObjectTypes: rawUserObjectTypes = [],
    userSpecialties: rawUserSpecialties = [],
    userProjectComponents: rawUserProjectComponents = [],
    userChiefEngineerExpertiseExperience: rawUserChiefEngineerExpertiseExperience = [],
    ratings,
    privacySettings,
    role,
  } = user;

  const userRegions = rawUserRegions.map(({ id }) => id);
  const userObjectTypes = rawUserObjectTypes.map(({ id }) => id);
  const userSpecialties = rawUserSpecialties.map(({ id }) => id);
  const userProjectComponents = rawUserProjectComponents.map(({ id }) => id);
  const userChiefEngineerExpertiseExperience = rawUserChiefEngineerExpertiseExperience.map(({ id }) => id);

  const initialPrivacySettings = privacySettings || {};

  if (!privacySettings && user.isPublic) {
    flatten(values(PRIVACY_SETTINGS_FIELDS)).forEach(({ name }) => {
      setWith(initialPrivacySettings, name, true, Object);
    });
  }

  return {
    ...user,
    role: role,
    ratings: formatRating(ratings, userRoles.map(({ name }) => name)),
    privacySettings: initialPrivacySettings,
    userRegions,
    initialUserRegions: userRegions,
    userObjectTypes,
    initialUserObjectTypes: userObjectTypes,
    userSpecialties,
    initialUserSpecialties: userSpecialties,
    userProjectComponents,
    initialUserProjectComponents: userProjectComponents,
    userChiefEngineerExpertiseExperience,
    initialUserChiefEngineerExpertiseExperience: userChiefEngineerExpertiseExperience,
  };
};

export const getBalance = transactions => {
  const aggregateSum = transactions?.aggregate.sum;
  return aggregateSum ? normalizeMoney(aggregateSum.debit - aggregateSum.credit) : 0;
};

export const getGraphqlUser = ({
  email,
  firstName,
  lastName,
  patronymic,
  phone,
  timezone,
  comment,
  privacySettings,
  files,
  role,
}) => {
  const photoFile = files ? find(files, { title: 'photo' }) : null;

  return {
    email,
    firstName,
    lastName,
    patronymic,
    phone,
    timezone,
    comment,
    privacySettings,
    photoFileId: photoFile && photoFile.id,
    roleId: role && role.id,
  };
};

const getUserArrayValues = (array, propertyName, isString) => {
  let result = (array || []).map(item => item[propertyName].name);
  if (isString) {
    result = result.join(', ');
  }

  return result;
};
const getUserProjectComponents = (array, propertyName) => (array || []).map(item => `${item[propertyName].name} (${item[propertyName].code})`);

export const formatPublicProfile = (user = {}, userRoles) => {
  const {
    userRegions,
    userObjectTypes,
    userSpecialties,
    userProjectComponents,
    organizationContractDetails,
    inFavorite,
    ratings,
    role,
  } = user;

  const {
    name,
    taxSystem,
    inn,
    admissions,
    lettersOfAttorney,
  } = organizationContractDetails || {};

  return {
    ...user,
    role: role?.name,
    ratings: formatRating(ratings, userRoles),
    userRegions: getUserArrayValues(userRegions, 'region', true),
    userObjectTypes: getUserArrayValues(userObjectTypes, 'objectType', true),
    userSpecialties: getUserArrayValues(userSpecialties, 'specialty'),
    userProjectComponents: getUserProjectComponents(userProjectComponents, 'projectComponent'),
    organization: {
      name,
      taxSystem: taxSystem && taxSystem.name,
      inn,
      admissions,
      lettersOfAttorney,
    },
    isInFavorite: !!get(inFavorite, '0.isConfirmed'),
  };
};
