import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withContext, withProps } from 'recompose';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import withContractDetailsReferencesQuery from '../../../../graphql/hoc/contractDetailsReferences';
import withPhaseReferencesQuery from '../../../../graphql/hoc/phaseReferences';
import withExpertUserReferencesQuery from '../../../../graphql/hoc/expertUserReferences';

import {
  setStageEngineers,
  getObjects,
  setFlattenedStageProjectComponents,
} from '../../../../graphql/model/object';
import ObjectStage from './index';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import { GENITIVE_VACANCY_TYPE, INSTRUMENTAL_VACANCY_TYPE, VACANCY_TYPE } from '../../../../utils/vacancyType';
import { formatRemarks } from '../../../../graphql/model/remark';
import {
  getObjectProjectComponentQuery,
  OBJECT_PROJECT_COMPONENT_ORDER,
} from '../../../../graphql/model/projectComponent';
import { deepMerge } from '../../../../utils/merge';
import { withResubscribe } from '../../../../graphql/utils';
import { prepareToInsertDataRequest } from '../../../../graphql/model/dataRequest';
import { formValueSelector } from 'redux-form';

const GET_OBJECT_QUERY = loader('../../../../graphql/queries/object/get.graphql');
const ON_OBJECT_UPDATE_SUBSCRIPTION = loader('../../../../graphql/queries/object/itemSubscription.graphql');
const INSERT_WORK_ACCEPTANCE_MUTATION = loader('../../../../graphql/queries/object/insertWorkAcceptance.graphql');
const INSERT_WORK_ACCEPTANCE_SIGN_MUTATION = loader('../../../../graphql/queries/object/insertWorkAcceptanceSign.graphql');
const INSERT_DATA_REQUEST_MUTATION = loader('../../../../graphql/queries/dataRequest/insert.graphql');
const FORCE_ACCEPT_WORK_MUTATION = loader('../../../../graphql/queries/object/forceAcceptWork.graphql');

const dataRequestFormSelector = formValueSelector('dataRequestModal');

const mapStateToProps = ({ session: { user: { id } }, ...state }) => ({
  userId: id,
  dataRequestMessage: dataRequestFormSelector(state, 'request'),
});

const withInsertWorkAcceptanceMutation = graphql(INSERT_WORK_ACCEPTANCE_MUTATION, {
  name: 'insertWorkAcceptance',
});

const withInsertWorkAcceptanceSignMutation = graphql(INSERT_WORK_ACCEPTANCE_SIGN_MUTATION, {
  name: 'insertWorkAcceptanceSign',
});

const withDataRequestMutation = graphql(INSERT_DATA_REQUEST_MUTATION, {
  name: 'insertDataRequest',
});

const withForceAcceptWorkMutation = graphql(FORCE_ACCEPT_WORK_MUTATION, {
  name: 'forceAcceptWork',
});

const withGetObjectQuery = graphql(GET_OBJECT_QUERY, {
  name: 'getObject',
  skip: ({ id, stageId }) => !id || !stageId,
  options: ({ id, userId }) => ({
    variables: {
      id,
      userId,
      withFiles: true,
      withRemarks: true,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  }),
  props: ({ getObject: { object = [], loading, error } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryObject: object,
      queryLoading: loading,
    };
  },
});

const withObjectSubscription = withResubscribe(ON_OBJECT_UPDATE_SUBSCRIPTION, {
  name: 'objectSub',
  skip: ({ id, stageId }) => !id || !stageId,
  options: ({ id, userId }) => ({
    variables: {
      id: parseInt(id, 10),
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
      projectComponentWhere: getObjectProjectComponentQuery(userId),
    },
  }),
  props: ({ objectSub: { object = [], error }, ownProps: { queryObject, queryLoading, userId, isExpert, stageId, projectComponentId } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const formattedObject = queryObject[0] && getObjects([deepMerge(queryObject[0], object[0])], userId, isExpert)[0];
    let currentProjectComponent = null;

    if (formattedObject) {
      setFlattenedStageProjectComponents(formattedObject.stages, true);
      setStageEngineers(formattedObject.stages);
      formattedObject.stages = keyBy(formattedObject.stages, 'id');

      if (formattedObject.stages[stageId]) {
        const { projectComponents, chiefEngineer, vacancies } = formattedObject.stages[stageId];

        projectComponents.forEach(projectComponent => {
          projectComponent.flattenedProjectComponents.forEach(flattenedProjectComponent => {
            flattenedProjectComponent.objectId = formattedObject.id;
            flattenedProjectComponent.engineers = [
              ...flattenedProjectComponent.engineers,
              ...(chiefEngineer ? [chiefEngineer] : []),
            ];
            flattenedProjectComponent.customer = {
              userId: formattedObject.userId,
              role: {
                accusative: VACANCY_TYPE.customer,
                genitive: GENITIVE_VACANCY_TYPE.customer,
                instrumental: INSTRUMENTAL_VACANCY_TYPE.customer,
              },
            };
            flattenedProjectComponent.remarks = formatRemarks(
              flattenedProjectComponent.remarks,
              flattenedProjectComponent.performers,
              [...flattenedProjectComponent.engineers, flattenedProjectComponent.customer],
              [...vacancies, ...flattenedProjectComponent.vacancies],
              flattenedProjectComponent.customer,
              userId,
            );
          });

          projectComponent.remarksTotalCount = projectComponent.flattenedProjectComponents
            .reduce((result, { remarks }) => (result += remarks.count.total), 0);
        });

        currentProjectComponent = projectComponentId
          ? projectComponents.find(item => item.id === projectComponentId ||
            item.flattenedProjectComponents.some(subItem => subItem.id === projectComponentId))
          : projectComponents[0];
      }
    }

    return {
      object: formattedObject,
      projectComponent: currentProjectComponent,
      isLoading: queryLoading,
    };
  },
});

const mergeProps = withProps(({
  insertWorkAcceptance,
  insertWorkAcceptanceSign,
  insertDataRequest,
  match,
  dataRequestMessage,
  forceAcceptWork,
}) => ({
  id: match.params.id,
  stageId: Number(match.params.stageId),
  projectComponentId: Number(match.params.projectComponentId),
  onTurnInWork: ({ performers, lastFilesVersion }) => insertWorkAcceptance({
    variables: {
      vacancyId: performers.filter(({ isActive }) => isActive)[0].vacancyId,
      versionId: lastFilesVersion.id,
    },
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
  onAcceptWork: ({ workAcceptanceId, isCompleteness }) => insertWorkAcceptanceSign({
    variables: {
      workAcceptanceSign: {
        workAcceptanceId,
        isSourcesConfirmation: isCompleteness,
      },
    },
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
  onForceAcceptWork: contractId => forceAcceptWork({
    variables: { contractId },
  }).catch(error => {
    showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    throw error;
  }),
  onCreateDataRequest: data => insertDataRequest({
    variables: {
      dataRequest: prepareToInsertDataRequest(data, dataRequestMessage),
    },
  }),
}));

export default compose(
  withRouter,
  connect(mapStateToProps),
  withContractDetailsReferencesQuery,
  withPhaseReferencesQuery,
  withExpertUserReferencesQuery,
  withInsertWorkAcceptanceMutation,
  withInsertWorkAcceptanceSignMutation,
  withForceAcceptWorkMutation,
  withDataRequestMutation,
  mergeProps,
  withGetObjectQuery,
  withObjectSubscription,
  withContext(
    { phases: PropTypes.array.isRequired },
    ({ phases }) => ({ phases: phases?.slice(1) || [] }),
  ),
)(ObjectStage);
