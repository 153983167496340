import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

import { ICONS } from '../../../utils/icons';

import './index.scss';

const MAX_STRING_LENGTH = 255;
const MAX_TEXTAREA_LENGTH = 10000;
const MASK_CHAR = '_';
const MAX_NUMBER = 100000000000000;
const MAX_DECIMAL_PLACES = 2;
const NUMBER_AND_DOT_REGEX = /^[0-9.]+$/;

export default class Input extends PureComponent {
  inputRef = null;

  componentDidMount() {
    if (this.props.type === 'textarea') {
      this.increaseTextareaHeight();
    }
  }

  handleMaskChange = event => {
    this.props.onChange(event.target.value.replace(/\D/g, ''));
  };

  handleTextareaChange = event => {
    this.props.onChange(event.target.value);
    this.increaseTextareaHeight();
  };

  handleKeyDown = event => {
    const { key, target: { value, selectionStart } } = event;

    const dotIndex = value.indexOf('.');
    if (key.length === 1 && (!NUMBER_AND_DOT_REGEX.test(key) ||
      key === '.' && (dotIndex > -1 || value.length - selectionStart > MAX_DECIMAL_PLACES) ||
      value > MAX_NUMBER ||
      dotIndex > -1 && selectionStart > dotIndex && value.slice(dotIndex + 1).length >= MAX_DECIMAL_PLACES)
    ) {
      event.preventDefault();
    }

    return event;
  };

  handleBeforeMaskedValueChange = value => (newState, oldState, userInput) => {
    const propsValueLength = String(value).length;
    const { selection } = oldState;
    const cursorPosition = selection ? selection.start : null;
    let { value: stateValue } = newState;

    if (stateValue.length > propsValueLength && stateValue.includes(MASK_CHAR) && userInput) {
      stateValue = oldState.value.slice(0, cursorPosition) + userInput + oldState.value.slice(cursorPosition);
    } else if (stateValue.replace(/\D/g, '').length === propsValueLength && userInput) {
      stateValue = oldState.value;
    }

    return {
      value: stateValue,
      selection: newState.selection,
    };
  };

  handleRef = ref => {
    this.props.onRef(ref);
    this.inputRef = ref;
  };

  increaseTextareaHeight() {
    if (this.inputRef && this.inputRef.style) {
      setTimeout(() => {
        if (this.inputRef) {
          this.inputRef.style.cssText = '';
          this.inputRef.style.cssText = 'height:' + this.inputRef.scrollHeight + 'px';
        }
      }, 10);
    }
  }

  render() {
    const {
      className,
      placeholder,
      isDisabled,
      icon,
      label,
      invalid,
      inputWarning,
      isReadonly,
      children,
      onIconClick,
      isBordered,
      onRef, // Just removed from the 'props' variable to avoid an error
      ...props
    } = this.props;
    const SuitedInput = props.mask ? InputMask : FormControl;

    return <div
      className={classNames('custom-input', className, {
        labeled: !!label,
        'with-icon': !!icon,
        disabled: isDisabled,
        invalid,
        bordered: isBordered,
        'read-only': isReadonly,
      })}
    >
      {label && <label>{label}</label>}
      <InputGroup>
        {isReadonly
          ? <span className="string-container">{props.value}</span>
          : <SuitedInput
            {...props}
            {...(props.mask
              ? {
                maskChar: MASK_CHAR,
                onChange: this.handleMaskChange,
                beforeMaskedValueChange: this.handleBeforeMaskedValueChange(props.value),
                onBlur: noop,
              }
              : { maxLength: MAX_STRING_LENGTH })}
            ref={this.handleRef}
            placeholder={placeholder}
            disabled={isDisabled}
            {...(props.type === 'number' && { type: 'text', onKeyDown: this.handleKeyDown })}
            {...(props.type === 'textarea' && {
              as: 'textarea',
              onChange: this.handleTextareaChange,
              maxLength: MAX_TEXTAREA_LENGTH,
            })}
          />}
        {inputWarning && <InputGroup.Append>
          <InputGroup.Text>{inputWarning}</InputGroup.Text>
        </InputGroup.Append>}
        {icon && React.createElement(ICONS[icon], {
          className: classNames('icon', icon),
          ...props,
          ...(onIconClick && { onClick: onIconClick }),
        })}
        {children}
      </InputGroup>
    </div>;
  }
}

Input.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  invalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  mask: PropTypes.string,
  type: PropTypes.string,
  inputWarning: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  onRef: PropTypes.func,
  className: PropTypes.string,
  isBordered: PropTypes.bool,
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  isReadonly: PropTypes.bool,
  children: PropTypes.node,
};

Input.defaultProps = {
  placeholder: '',
  isDisabled: false,
  icon: '',
  label: '',
  invalid: false,
  mask: '',
  type: 'text',
  inputWarning: '',
  onRef() {},
  className: '',
  isBordered: false,
  onChange() {},
  onIconClick: undefined,
  isReadonly: false,
  children: '',
};
