import { loader } from 'graphql.macro';
import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { showError } from '../../../api/error';
import Tasks from './index';
import { formatTasks, getTasksQuery } from '../../../graphql/model/tasks';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const ORDER_BY = {
  createdAt: {
    title: 'Дата создания',
    graphql: { createdAt: 'desc' },
    default: true,
  },
  deadline: {
    title: 'Срок исполнения',
    graphql: { deadline: 'asc' },
  },
};

const ON_TASKS_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/task/listSubscription.graphql');
const ON_TASKS_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/task/countSubscription.graphql');

const mapStateToProps = ({ session }) => ({
  userId: session.user?.id,
});

const mergeProps = withProps(({ userId, objectId, isOutgoing, isIncoming, isDelegated, isArchive }) => ({
  where: getTasksQuery({ userId, objectId, isOutgoing, isIncoming, isDelegated, isArchive }),
}));

const withGetTasksQuery = withResubscribe(ON_TASKS_UPDATE_SUBSCRIPTION, {
  name: 'getTasks',
  skip: ({ userId }) => !userId,
  options: ({ where, offset, limit, order }) => ({
    variables: {
      where,
      offset,
      limit,
      order,
    },
  }),
  props: ({ getTasks: { tasks = [], error, loading: isLoading }, ownProps: { userId } }, previousResult) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      // we return previous messages if resubscribe was started (has 0 messages on loading moment)
      tasks: isLoading && previousResult?.tasks.length > 0 ? previousResult.tasks : formatTasks(tasks, userId),
      isLoading,
    };
  },
});

const withTasksCountSubscription = withResubscribe(ON_TASKS_COUNT_UPDATE_SUBSCRIPTION, {
  name: 'tasksCountSub',
  skip: ({ userId }) => !userId,
  options: ({ where }) => ({
    variables: {
      where,
    },
  }),
  props: ({ tasksCountSub: { count, error, loading: isCountLoading }, ownProps: { isLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      count: count ? count.aggregate.count : 0,
      isLoading: isCountLoading && isLoading,
    };
  },
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  defaultProps({
    orderBy: ORDER_BY,
    offset: 0,
    limit: 10,
  }),
  mergeProps,
  withStateHandlers(
    ({ limit, orderBy, where }) => ({ step: limit, order: getOrder(orderBy), where }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      search: (_, { where, userId, objectId, isOutgoing, isIncoming, isDelegated, isArchive }) => value => ({
        where: value ? getTasksQuery({ userId, objectId, isOutgoing, isIncoming, isDelegated, isArchive, search: value }) : where,
      }),
    },
  ),
  withGetTasksQuery,
  withTasksCountSubscription,
)(Tasks);
