import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StageItem from './item';
import Button from '../../../../base/button';
import { ICONS } from '../../../../../utils/icons';

import './index.scss';

export default class ProjectCompositionList extends PureComponent {
  itemExpandedStates = {};

  setItemState = index => isExpanded => {
    this.itemExpandedStates[index] = isExpanded;
  };

  getItemState = index => () => !!this.itemExpandedStates[index];

  render() {
    const {
      items,
      parentProjectComponent,
      onClickAdd,
      onClickEdit,
      onClickRemove,
      onClickAddStageChild,
    } = this.props;

    return <div className="project-composition-list-wrapper">
      {items.map((projectComponent, index) => <StageItem
        key={index}
        level={1}
        data={projectComponent}
        parentProjectComponent={parentProjectComponent}
        onClickAdd={onClickAdd}
        onClickEdit={onClickEdit}
        onClickRemove={onClickRemove}
        getItemState={this.getItemState(index)}
        setItemState={this.setItemState(index)}
      />)}
      <Button
        onClick={onClickAddStageChild}
        IconComponent={ICONS.circlePlus}
        isLarge
      >
        Добавить {parentProjectComponent.childType?.name}
      </Button>
    </div>;
  }

  static propTypes = {
    items: PropTypes.array,
    parentProjectComponent: PropTypes.object,
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickRemove: PropTypes.func,
    onClickAddStageChild: PropTypes.func,
  };

  static defaultProps = {
    items: [],
    parentProjectComponent: {},
    onClickAdd() {},
    onClickEdit() {},
    onClickRemove() {},
    onClickAddStageChild() {},
  };
}
