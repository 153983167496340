import { reduxForm, formValueSelector } from 'redux-form';
import { compose, withContext, withProps } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import TaskModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { getChildren, OBJECT_PROJECT_COMPONENT_ORDER } from '../../../graphql/model/projectComponent';
import { getFullName } from '../../../utils/name';
import AuthorizedApi from '../../../api/authorized';
import {
  prepareToDeleteTaskMessageFiles,
  prepareToInsertTask,
  prepareToInsertTaskMessageFiles,
  prepareToInsertTaskMessages,
} from '../../../graphql/model/tasks';
import { formatProjectComponentsForSelect } from '../../../utils/projectComponent';
import { getObjectListByUserQuery } from '../../../graphql/model/object';

const INSERT_TASK_MUTATION = loader('../../../graphql/queries/task/insert.graphql');
const INSERT_TASK_MESSAGE_MUTATION = loader('../../../graphql/queries/task/insertMessage.graphql');
const UPDATE_TASK_MUTATION = loader('../../../graphql/queries/task/update.graphql');
const UPDATE_TASK_MESSAGE_MUTATION = loader('../../../graphql/queries/task/updateTaskMessage.graphql');
const GET_OBJECTS_LIST_BY_USER_QUERY = loader('../../../graphql/queries/object/getListByUser.graphql');
const REASSIGN_TASK_MUTATION = loader('../../../graphql/queries/task/reassignTask.graphql');

const FORM_NAME = 'createTaskModal';
const formSelector = formValueSelector(FORM_NAME);

const withInsertTaskMutation = graphql(INSERT_TASK_MUTATION, {
  name: 'insertTask',
});

const withInsertTaskMessageMutation = graphql(INSERT_TASK_MESSAGE_MUTATION, {
  name: 'insertTaskMessage',
});

const withUpdateTaskMutation = graphql(UPDATE_TASK_MUTATION, {
  name: 'updateTask',
});

const withUpdateTaskMessageMutation = graphql(UPDATE_TASK_MESSAGE_MUTATION, {
  name: 'updateTaskMessage',
});

const withReassignTaskMutation = graphql(REASSIGN_TASK_MUTATION, {
  name: 'reassignTask',
});

const withGetObjectsListByUserQuery = graphql(GET_OBJECTS_LIST_BY_USER_QUERY, {
  name: 'getListByUser',
  skip: ({ userId }) => !userId,
  options: ({ userId }) => ({
    variables: {
      userId,
      where: getObjectListByUserQuery(userId),
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getListByUser, ownProps: { task, objectId, stageId, projectComponentId, toUserId, isReassign } }) => {
    const {
      items = [],
      error,
      loading: isLoading,
    } = getListByUser;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const selectedObjectId = objectId || task.objectId;
    const selectedStageId = stageId || task.stageId;
    const selectedProjectComponentId = projectComponentId || task.objectProjectComponentId;
    const selectedUserId = toUserId || task.assignedUserId;
    const objects = orderBy(
      items.map(({ name, id }) => ({ label: name, value: id, checked: selectedObjectId === id })),
      ['label'],
    );
    const selectedObject = items.find(({ id }) => selectedObjectId === id);
    let stages = [];
    let projectComponents = [];
    let users = [];

    // check for items needed only for Expert role
    if (selectedObjectId && selectedObject && items.length > 0) {
      stages = selectedObject.projectComponents
        .filter(({ parentObjectProjectComponentId }) => !parentObjectProjectComponentId);
      users = selectedObject.objectUsers;

      if (selectedStageId) {
        const selectedStage = selectedObject.projectComponents.find(({ id }) => selectedStageId === id);

        if (selectedStage) {
          projectComponents = getChildren(
            selectedObject.projectComponents.filter(({ parentObjectProjectComponentId }) => parentObjectProjectComponentId === selectedStage.id),
            selectedObject.projectComponents,
          );
          users = selectedStage.objectProjectComponentUsers;

          if (selectedProjectComponentId) {
            const selectedProjectComponent = selectedObject.projectComponents.find(({ id }) => selectedProjectComponentId === id);

            if (selectedProjectComponent) {
              users = selectedProjectComponent.objectProjectComponentUsers;
            }
          }
        }
      }

      users = orderBy(
        [...users, ...(selectedObject.user ? [{ user: selectedObject.user }] : [])]
          .map(({ user: { id, email, ...user } }) => ({
            label: getFullName(user) || email,
            value: id,
            checked: selectedUserId === id,
          })),
        ['label'],
      );
    }

    return {
      objects,
      code: selectedObject?.code,
      stages: formatProjectComponentsForSelect(stages, selectedStageId),
      projectComponents: formatProjectComponentsForSelect(projectComponents, selectedProjectComponentId),
      users,
      ...(isReassign ? { reassignmentUsers: users.filter(user => !user.checked) } : {}),
      isLoading,
    };
  },
});

const mapStateToProps = state => ({
  userId: state.session.user.id,
  objectId: formSelector(state, 'object'),
  stageId: formSelector(state, 'stage'),
  projectComponentId: formSelector(state, 'projectComponent'),
  toUserId: formSelector(state, 'toUser'),
});

const mergeProps = withProps(({
  insertTask,
  insertTaskMessage,
  updateTask,
  updateTaskMessage,
  reassignTask,
  code,
  task,
  isEdit,
  isMessage,
  isAnswer,
  isReassign,
}) => {
  let initialValues = {
    deadline: new Date(),
    code,
  };
  const isNewTask = !isEdit && !isAnswer && !isReassign;

  if (!isEmpty(task)) {
    const { taskMessage, assignedUserId, deadline, objectId, stageId, objectProjectComponentId, lastMessage } = task;
    let currentMessage = '';

    if (isEdit) {
      currentMessage = taskMessage;

      if (isMessage) {
        currentMessage = lastMessage;
      }
    }

    initialValues = {
      ...initialValues,
      name: isEdit ? '' : taskMessage?.message,
      deadline,
      message: currentMessage.message,
      object: objectId,
      stage: stageId,
      projectComponent: objectProjectComponentId,
      toUser: assignedUserId,
      files: currentMessage?.files,
    };
  }

  return {
    isRevision: !(task.isIncoming && task.isAssignedUser),
    presetObjectId: task?.objectId,
    initialValues,
    hasDeadline: isNewTask || !!task?.deadline,
    onFileUpload: AuthorizedApi.uploadFile,
    onAnswerTask: data => insertTaskMessage({
      variables: {
        messages: prepareToInsertTaskMessages({ ...data, task }),
      },
    }),
    onReassignTask: ({ message, reassignmentUser }) => reassignTask({
      variables: {
        taskId: task.id,
        reassignmentUserId: reassignmentUser,
        message: message,
      },
    }),
    onEditTask: data => {
      const { taskMessage, lastMessage } = task;
      const { message, files, deadline } = data;
      let messageId = taskMessage.id;
      let initialFiles = taskMessage.files;

      if (isMessage) {
        messageId = lastMessage.id;
        initialFiles = lastMessage.files;
      }

      return Promise.all([
        updateTask({
          variables: {
            id: task.id,
            task: {
              deadline,
            },
          },
        }),
        updateTaskMessage({
          variables: {
            id: messageId,
            taskMessage: { message },
            taskMessageFilesToInsert: prepareToInsertTaskMessageFiles(messageId, files, initialFiles),
            taskMessageFilesToDelete: prepareToDeleteTaskMessageFiles(files, initialFiles),
          },
        }),
      ]);
    },
    onCreateTask: data => insertTask({
      variables: {
        task: prepareToInsertTask(data),
      },
    }),
  };
});

export default compose(
  withInsertTaskMutation,
  withInsertTaskMessageMutation,
  withUpdateTaskMutation,
  withUpdateTaskMessageMutation,
  withReassignTaskMutation,
  connect(mapStateToProps),
  withGetObjectsListByUserQuery,
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    forceUnregisterOnUnmount: true,
    validate: getValidation([
      'name',
      'toUser',
      'object',
      'deadline',
      'message',
      'reassignmentUser',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(TaskModal);
