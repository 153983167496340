import { formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CreateObjectModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import { getSelectedStages, getObjectTypeStages } from '../../../graphql/model/object';

const FORM_NAME = 'createObjectModal';
const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, { objectTypes = [], initialValues }) => {
  const selectedProjectComponents = formSelector(state, 'projectComponents') || [];
  const selectedObjectType = formSelector(state, 'objectType') || null;
  const objectTypeStages = getObjectTypeStages(selectedObjectType, objectTypes);
  const selectedStages = getSelectedStages(objectTypeStages, selectedProjectComponents)
    .map(({ name, value }) => ({ name, id: value }));
  const selectedStageDeadline = formSelector(state, `stageDeadline-${selectedStages[0]?.id}`);
  const selectedTariffPlanId = formSelector(state, 'tariffPlan');

  return {
    selectedStages,
    selectedStageDeadline,
    selectedObjectType,
    selectedTariffPlanId,
    projectComponents: selectedObjectType ? objectTypeStages.children : [],
    initialValues: {
      paymentTerms: {
        usePhases: true,
        isExpertiseRequired: true,
        phase1: {
          deadline: selectedStageDeadline,
        },
        special: {
          isCustomersCoordinationWithServicesResponsibility: false,
          isCustomersInitialDataResponsibility: false,
        },
      },
      ...initialValues,
      isSafeDeal: true,
    },
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    form: FORM_NAME,
    validate: getValidation([
      'tariffPlan',
      'name',
      'address',
      'region',
      'code',
      'objectType',
      'projectComponents',
      'contractDetailType',
      'chiefEngineer',
      'totalAmount',
      'paymentTerms.phase0.rate',
      'paymentTerms.phase1.rate',
      'paymentTerms.phase2.rate',
      'paymentTerms.phase3.rate',
      'paymentTerms.phase0.termLimit',
      'paymentTerms.remarksCreateTermLimit',
      'paymentTerms.phase2.termLimit',
      'paymentTerms.phase3.termLimit',
      'paymentTerms.phase2.negativeExpertiseFine',
      'paymentTerms.worksDelayPenalty',
      'paymentTerms.month.paymentTermLimit',
      'paymentTerms.month.paymentDelayFine',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(CreateObjectModal);
