import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, withContext } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getObjects, setFlattenedStageProjectComponents } from '../../../../graphql/model/object';
import { setObjectExpertise } from '../../../../graphql/model/expertise';
import Expertise from './index';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import PropTypes from 'prop-types';
import AuthorizedApi from '../../../../api/authorized';
import { OBJECT_PROJECT_COMPONENT_ORDER } from '../../../../graphql/model/projectComponent';
import { deepMerge } from '../../../../utils/merge';

const GET_OBJECT_QUERY = loader('../../../../graphql/queries/object/get.graphql');
const ON_OBJECT_UPDATE_SUBSCRIPTION = loader('../../../../graphql/queries/object/expertiseSubscription.graphql');
const UPDATE_EXPERTISE_FILE = loader('../../../../graphql/queries/object/updateExpertiseFile.graphql');
const DELETE_EXPERTISE_FILE = loader('../../../../graphql/queries/object/deleteExpertiseFile.graphql');
const UPDATE_PROJECT_COMPONENT_EXPERTISE = loader('../../../../graphql/queries/object/updateProjectComponentExpertise.graphql');
const INSERT_PROJECT_COMPONENT_EXPERTISE_ACCEPTANCE = loader('../../../../graphql/queries/object/insertProjectComponentExpertiseAcceptance.graphql');
const INSERT_FILES_QUERY = loader('../../../../graphql/queries/file/insert.graphql');

const withUpdateExpertiseFileMutation = graphql(UPDATE_EXPERTISE_FILE, { name: 'updateExpertiseFile' });
const withDeleteExpertiseFileMutation = graphql(DELETE_EXPERTISE_FILE, { name: 'deleteExpertiseFile' });
const withUpdateProjectComponentExpertiseMutation = graphql(UPDATE_PROJECT_COMPONENT_EXPERTISE, { name: 'updateProjectComponentExpertise' });
const withInsertProjectComponentExpertiseAcceptanceMutation = graphql(INSERT_PROJECT_COMPONENT_EXPERTISE_ACCEPTANCE, { name: 'insertProjectComponentExpertiseAcceptance' });
const withInsertFilesMutation = graphql(INSERT_FILES_QUERY, { name: 'uploadFiles' });

const withGetObjectQuery = graphql(GET_OBJECT_QUERY, {
  name: 'getObject',
  skip: ({ match: { params: { id } } }) => !id,
  options: ({ match: { params: { id } } }) => ({
    variables: {
      id: parseInt(id, 10),
      withFiles: true,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getObject: { object = [], loading, error } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryObject: object,
      queryLoading: loading,
    };
  },
});

const withObjectSubscription = graphql(ON_OBJECT_UPDATE_SUBSCRIPTION, {
  name: 'objectExpertiseSub',
  skip: ({ match: { params: { id } } }) => !id,
  options: ({ match: { params: { id } } }) => ({
    variables: {
      id: parseInt(id, 10),
    },
  }),
  props: ({ objectExpertiseSub: { object = [], loading, error }, ownProps: { userId, queryObject, queryLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const formattedObject = queryObject[0] && getObjects([deepMerge(queryObject[0], object[0])], userId)[0];

    if (formattedObject) {
      setFlattenedStageProjectComponents(formattedObject.stages);
      setObjectExpertise(formattedObject, userId);
    }

    return {
      object: formattedObject,
      isLoading: queryLoading || loading,
    };
  },
});

const mapStateToProps = ({ session: { user: { id } } }) => ({
  userId: id,
});

const mergeProps = (
  stateToProps,
  dispatchToProps,
  {
    uploadFiles,
    updateExpertiseFile,
    deleteExpertiseFile,
    updateProjectComponentExpertise,
    insertProjectComponentExpertiseAcceptance,
    ...ownProps
  },
) => ({
  ...stateToProps,
  ...ownProps,
  onFileUpload: AuthorizedApi.uploadFile,
  onUpdateExpertiseFile: (fileId, { mimeType, originalName, remotePath, size }) => uploadFiles({
    variables: {
      filesInsert: [{
        mimeType,
        originalName,
        remotePath,
        size,
      }],
      filesDelete: [],
    },
  }).then(result => updateExpertiseFile({
    variables: {
      fileId,
      item: {
        fileId: result.data.insert_file.returning[0].id,
      },
    },
  })),
  onDeleteExpertiseFile: fileId => deleteExpertiseFile({
    variables: {
      fileId,
    },
  }),
  onChangeStatusToPositive: id => updateProjectComponentExpertise({
    variables: {
      id,
      expertise: {
        isPositive: true,
      },
    },
  }),
  onAddAcceptance: ({ objectProjectComponentExpertiseId, isAccepted, reason, userRoleId }) => insertProjectComponentExpertiseAcceptance({
    variables: {
      acceptance: {
        objectProjectComponentExpertiseId,
        isAccepted,
        reason,
        userRoleId,
      },
    },
  }),
});

export default compose(
  withInsertFilesMutation,
  withUpdateExpertiseFileMutation,
  withDeleteExpertiseFileMutation,
  withUpdateProjectComponentExpertiseMutation,
  withInsertProjectComponentExpertiseAcceptanceMutation,
  withRouter,
  connect(mapStateToProps, null, mergeProps),
  withGetObjectQuery,
  withObjectSubscription,
  withContext(
    {
      onFileUpload: PropTypes.func.isRequired,
      onUpdateExpertiseFile: PropTypes.func.isRequired,
      onDeleteExpertiseFile: PropTypes.func.isRequired,
      onChangeStatusToPositive: PropTypes.func.isRequired,
      onAddAcceptance: PropTypes.func.isRequired,
    },
    ({ onFileUpload, onUpdateExpertiseFile, onDeleteExpertiseFile, onChangeStatusToPositive, onAddAcceptance }) => ({
      onFileUpload,
      onUpdateExpertiseFile,
      onDeleteExpertiseFile,
      onChangeStatusToPositive,
      onAddAcceptance,
    }),
  ),
)(Expertise);
