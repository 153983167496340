import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RecoverPasswordForm from '../../components/recoverPasswordForm';
import InfoModal from '../../components/modals/info';
import SetPasswordForm from '../../components/setPasswordForm';
import history from '../../utils/history';
import { ROOT_ROUTE } from '../../utils/routes';
import { RESET_PASSWORD_SENT } from '../../utils/messages';

export default class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.token = props.match.params.token;
    this.recoverPasswordModalRef = React.createRef();
  }

  sendRecoverPasswordMail = params => {
    const { sendRecoverPasswordMail } = this.props;
    return sendRecoverPasswordMail(params).then(() => this.handleShowModal(this.recoverPasswordModalRef));
  };

  setPassword = params =>
    this.props.setPassword({ ...params, token: this.token }).then(() => history.push(ROOT_ROUTE));

  handleShowModal = modalRef => {
    if (modalRef.current) {
      const modal = modalRef.current.wrappedInstance || modalRef.current;
      modal.toggleModal();
    }
  };

  render() {
    return <>
      {this.token
        ? <SetPasswordForm onSubmit={this.setPassword} />
        : <RecoverPasswordForm onSubmit={this.sendRecoverPasswordMail} />}
      <InfoModal
        onRef={this.recoverPasswordModalRef}
        message={RESET_PASSWORD_SENT}
      />
    </>;
  }
}

SetPassword.propTypes = {
  match: PropTypes.object.isRequired,
  setPassword: PropTypes.func.isRequired,
  sendRecoverPasswordMail: PropTypes.func.isRequired,
};
