import React from 'react';
import PropTypes from 'prop-types';

import ProfileHeader from '../../../components/profile/view/header';
import ProfileForm from '../../../components/profile/view';
import Loading from '../../../components/base/loading';
import InviteToContractModal from '../../../components/modals/inviteToContract/container';

class Profile extends React.PureComponent {
  state = {
    invitedUserId: null,
  }

  inviteToContractModalRef = React.createRef();

  handleInviteToContract = userId => {
    this.setState({ invitedUserId: userId });
    this.inviteToContractModalRef.current.toggleModal();
  }

  render() {
    const { isLoading, isPrivate, initialValues } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    const { createdAt, ratings, privacySettings } = initialValues;

    return <div className="profile-layout">
      <ProfileHeader createdAt={createdAt} rating={ratings} isPrivate={isPrivate} />
      <ProfileForm
        initialValues={initialValues}
        isPrivate={isPrivate}
        publicSettings={privacySettings?.public}
        onClickInviteToContract={this.handleInviteToContract}
      />
      <InviteToContractModal onRef={this.inviteToContractModalRef} invitedUserId={this.state.invitedUserId} />
    </div>;
  }
}

Profile.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Profile;
