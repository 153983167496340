import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import { Field } from 'redux-form';

import Input from '../../base/input';
import FormGroupField from '../../base/formGroup';
import Hint from '../../base/hint';

import './index.scss';

export default class VacancyObjectDetails extends React.PureComponent {
  render() {
    const { object, stageName, selectedPhase1Deadline, projectComponent: { name, type } } = this.props;
    const stageDeadlineMinDate = selectedPhase1Deadline > new Date() ? selectedPhase1Deadline : new Date();
    const deadlineText = `Срок выполнения работ по ${name ? type.dativeName : 'стадии'}`;

    return <div className="vacancy-object-details">
      <div className="form-group name">
        <Input
          label="Объект"
          isDisabled
          value={object.name}
        />
      </div>
      <div className="form-group stage-name">
        <Input
          label="Стадия"
          isDisabled
          value={stageName}
        />
      </div>
      <div className="form-group code">
        <Input
          label="Шифр объекта"
          isDisabled
          value={object.code}
        />
      </div>
      <div className="form-group deadline">
        <Field
          name="deadline"
          component={FormGroupField}
          type="date"
          label={<>{deadlineText}
            {!name && <Hint title={deadlineText}>
              {`${deadlineText} не может быть раньше Даты окончания Этапа I`}
            </Hint>}
          </>}
          isMultiline
          minDate={stageDeadlineMinDate}
        />
      </div>
      {name && <div className="form-group project-component-name">
        <Input
          type="textarea"
          label={upperFirst(type.name)}
          isDisabled
          value={name}
        />
      </div>}
    </div>;
  }

  static defaultProps = {
    object: {},
    stageName: '',
    selectedPhase1Deadline: null,
    projectComponent: {
      name: '',
      type: {
        name: '',
        accusativeName: '',
        dativeName: '',
        genitiveName: '',
      },
    },
  };

  static propTypes = {
    object: PropTypes.object,
    stageName: PropTypes.string,
    selectedPhase1Deadline: PropTypes.instanceOf(Date),
    projectComponent: PropTypes.object,
  };
}
