import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { TIME_ZONES } from './timezones';

const HOURS_IN_DAY = 24;

export const getFormattedDate = (date, withTime, isLong = true) =>
  moment(date).format(`${isLong ? 'D MMMM ' : 'DD.MM.'}YYYY${withTime ? ' HH:mm по ' : ''}`) +
  (withTime ? getCurrentTimezoneCode(getCurrentTimezone()) : '');

export const getFormattedExperience = value => {
  const formattedItems = [];

  const years = Math.floor(value / 12);

  if (years) {
    formattedItems.push(getFormattedYear(years));
  }

  const months = value % 12;

  if (months) {
    formattedItems.push(getFormattedMonth(months));
  }

  return formattedItems.join(' ') || 'Меньше месяца';
};

export const getFormattedYear = value => {
  let pluralYear;

  switch (value) {
    case 1:
      pluralYear = ' год';
      break;
    case 2:
    case 3:
    case 4:
      pluralYear = ' года';
      break;
    default:
      pluralYear = ' лет';
  }

  return value + pluralYear;
};

export const getFormattedMonth = value => {
  let pluralMonth;

  switch (value) {
    case 1:
      pluralMonth = ' месяц';
      break;
    case 2:
    case 3:
    case 4:
      pluralMonth = ' месяца';
      break;
    default:
      pluralMonth = ' месяцев';
  }

  return value + pluralMonth;
};

export const getCurrentTime = timezone => momentTimezone().tz(timezone).format('HH:mm');

export const getCurrentTimezone = () => {
  const localTimezone = momentTimezone.tz.guess();
  return TIME_ZONES.some(timezone => timezone.value === localTimezone) ? localTimezone : 'Europe/Moscow';
};

export const getCurrentTimezoneNumeric = timezone => momentTimezone().tz(timezone).format('Z');

export const getCurrentTimezoneCode = timezone => momentTimezone().tz(timezone).format('z');

export const getRowWithDates = workAcceptances => workAcceptances.map(({ createdAt, updatedAt, version, ...row }) => ({
  ...row,
  phaseId: version.contract?.phaseId,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
}));

export const getTimeLeft = date => {
  const diffHours = moment(date).diff(moment(), 'hours');
  const diffSeconds = moment(date).diff(moment(), 'seconds');
  const daysLeft = diffHours / HOURS_IN_DAY;
  const hoursLeft = diffHours % HOURS_IN_DAY;

  return diffHours > 0 ? `${Math.floor(daysLeft)}дн${hoursLeft ? ` ${hoursLeft}ч` : ''}` : diffSeconds > 0 ? 'Осталось меньше 1 часа' : null;
};
