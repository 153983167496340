import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import { ICONS } from '../../../utils/icons';

export default class InlineDatePicker extends React.PureComponent {
  render() {
    const { description, value, onClick } = this.props;

    return <Button className="date-picker" onClick={onClick} IconComponent={ICONS.date} isBorderless>
      <span className="value">{value}</span><span className="description">{description}</span>
    </Button>;
  }

  static propTypes = {
    value: PropTypes.any,
    onClick: PropTypes.func,
    description: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    onClick: () => {},
    description: '',
  };
}
