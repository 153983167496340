import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DataGroup from '../dataGroup';

import './index.scss';

export default class FlexGrid extends Component {
  render() {
    const { gridProps, items, isBorderless } = this.props;

    return <div className={classNames('flex-grid', { bordered: !isBorderless, empty: items.length === 0 })}>
      {items.length > 0
        ? items.map((item, index) => (
          <div className="item-details" key={index}>
            {gridProps.map(({ className, columnName, render, key, withTooltip }) => {
              const value = render ? render(item[key], item) : item[key];

              return <div className="column" key={key}>
                <div className="column-name">{columnName}</div>
                <div className={classNames('cell', className)}>
                  {withTooltip ? <DataGroup>{value}</DataGroup> : value}
                </div>
              </div>;
            })}
          </div>
        ))
        : <div>Нет данных.</div>}
    </div>;
  }

  static propTypes = {
    items: PropTypes.array.isRequired,
    gridProps: PropTypes.array.isRequired,
    isBorderless: PropTypes.bool,
  }

  static defaultProps = {
    isBorderless: false,
  }
}
