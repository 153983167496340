import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../base/button';
import { ICONS } from '../../../../utils/icons';
import { getFormattedDate } from '../../../../utils/date';
import EditObjectModal from '../../../modals/editObject/container';
import DataGroup from '../../../base/dataGroup';

import './index.scss';

export default class ObjectInfo extends PureComponent {
  state = {
    isEditModalShown: false,
  };

  editObjectModalRef = React.createRef();

  showEditModal = () => {
    this.editObjectModalRef.current.toggleModal();
    this.setState({ isEditModalShown: true });
  };

  render() {
    const { object, isOwner } = this.props;
    const { isEditModalShown } = this.state;
    const { name, code, type, region, address, deadline } = object;

    return <div className="object-info-wrapper">
      <div className="object-info-header">
        <h5>Информация по объекту</h5>
        <DataGroup title="Срок сдачи работ">
          {getFormattedDate(deadline, true)}
        </DataGroup>
        {isOwner && <Button
          className="edit-btn"
          onClick={this.showEditModal}
          IconComponent={ICONS.pencil}
          isBorderless
        />}
      </div>
      <div className="form-card">
        <DataGroup title="Наименование объекта" isLineBreakAllowed>{name}</DataGroup>
        <DataGroup title="Шифр объекта" isLineBreakAllowed>{code}</DataGroup>
        <div className="divider" />
        <DataGroup title="Тип объекта">{type}</DataGroup>
        <DataGroup title="Регион работы">{region}</DataGroup>
        <DataGroup title="Адрес">{address}</DataGroup>
      </div>
      {isOwner && <EditObjectModal onRef={this.editObjectModalRef} data={object} isSkip={!isEditModalShown} />}
    </div>;
  }

  static propTypes = {
    isOwner: PropTypes.bool,
    object: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isOwner: false,
  };
}
