import minBy from 'lodash/minBy';

import { getNameWithInitials } from '../../utils/name';
import { PAYMENT_TERMS_TYPES } from '../../utils/paymentTermsTypes';
import { normalizeMoney } from '../../utils/money';
import { formatContract } from './contracts';
import { getObjectFiles } from './file';
import { getEmployee } from './employee';
import { VACANCY_TYPE } from '../../utils/vacancyType';
import { convertRating, formatRating } from './rating';

const BID_TYPES = {
  defined: {
    name: 'Подтвержденное',
    class: 'defined',
  },
  improved: {
    name: 'Улучшенное',
    class: 'improved',
  },
  winning: {
    name: 'Побеждающее',
    class: 'winning',
  },
  exceeded: {
    name: 'Превышающее',
    class: 'exceeded',
  },
};

export const getCompetitionQuery = ({ userId, isTenders, isInvolved, isOwn, isOpened, search }) => {
  const whereQuery = {
    _and: [
      {
        vacancyTypeId: { _in: isTenders ? [3] : [1, 2] },
      },
      {
        vacancies: { isActive: { _eq: true } },
      },
      {
        employeeUserId: { _is_null: true },
      },
      ...(isOwn ? [{ customerUserId: { _eq: userId } }] : [{ isCompetitionStarted: { _eq: true } }]),
      ...(isInvolved ? [{ bids: { userId: { _eq: userId } } }] : []),
      ...(isOpened ? [
        { applicationDeadline: { _gt: new Date().toISOString() } },
        { competitionTerms: { _contains: { isOpen: true } } },
      ] : []),
    ],
  };

  if (search && search !== '') {
    if (isNaN(search)) {
      search = `%${search}%`;
      whereQuery._and.push({
        _or: [
          { subject: { _ilike: search } },
          { customerOrganizationName: { _ilike: search } },
          { customerEmail: { _ilike: search } },
          { customerName: { _ilike: search } },
          { employeeOrganizationName: { _ilike: search } },
          { employeeEmail: { _ilike: search } },
          { employeeName: { _ilike: search } },
          { objectContract: { object: { name: { _ilike: search } } } },
          { vacancyType: { userRole: { name: { _ilike: search } } } },
        ],
      });
    } else {
      whereQuery._and.push({
        totalAmount: { _eq: parseInt(search, 10) },
      });
    }
  }

  return whereQuery;
};

export const getContractInvitationsQuery = ({ userId, isTenders, search }) => ({
  _and: [
    {
      invitedUserId: { _eq: userId },
    },
    {
      contractExpanded: getCompetitionQuery({ userId, isTenders, search }),
    },
  ],
});

export const getContractQuery = (id, isCompetition, userId) => ({
  _and: [
    { id: { _eq: id } },
    { employeeUserId: { _is_null: isCompetition } },
    ...(isCompetition
      ? [{
        _or: [
          { customerUserId: { _eq: userId } },
          { isCompetitionStarted: { _eq: true } },
        ],
      }]
      : []),
  ],
});

const getCompetitionPhase = (isCompetitionStarted, isPerformer) => {
  if (isCompetitionStarted) {
    return isPerformer ? 'Торги' : 'Конкурс';
  } else {
    return 'Редактирование условий';
  }
};

const getOfferType = (bids, totalAmount, currentValue, previousValue, userId) => {
  const minBid = minBy(bids.filter(({ userId: bidUserId }) => bidUserId !== userId), 'value')?.value;

  if (currentValue < minBid) {
    return BID_TYPES.winning;
  } else if (currentValue < previousValue) {
    return BID_TYPES.improved;
  } else if (totalAmount >= currentValue) {
    return BID_TYPES.defined;
  } else if (currentValue > totalAmount) {
    return BID_TYPES.exceeded;
  }
};

export const formatVacancies = (contracts, userId, withParticipants = true) => (contracts || [])
  .map(({
    id,
    subject,
    vacancyType: { userRole: { name: vacancyTypeName }, isPerformer },
    status,
    number,
    paymentTerms,
    objectContract,
    vacancies,
    bids,
    totalAmount,
    phase,
    deadline,
    isCompetitionStarted,
    applicationDeadline,
    employeeUserId,
    customerUserId,
    customerContractDetails,
    customer,
    customerRating,
  }) => {
    // Needs for moment when vacancy has been removed but webhook has not completed yet.
    if (vacancies.length === 0) {
      return null;
    }

    const { object } = objectContract;
    const customerSelf = customerContractDetails.self;

    if (customerSelf) {
      customerSelf.name = getNameWithInitials(customerSelf);
    }

    const stage = vacancies[0].objectProjectComponent.root.rootComponent;
    const { id: stageId, templateProjectComponent: { name: stageName }, deadline: stageDeadline } = stage;
    const projectComponents = vacancies.map(
      ({ objectProjectComponent: { id, templateProjectComponent, files, generatedCode, deadline } }) => ({
        ...templateProjectComponent,
        ...(generatedCode || { processedName: '', processedCode: '' }),
        objectProjectComponentId: id,
        files: getObjectFiles(files, userId),
        deadline,
      }),
    );

    return {
      id,
      vacancyId: vacancies[0].id,
      subject,
      vacancyTypeName,
      isPerformer,
      userId,
      paymentType: PAYMENT_TERMS_TYPES[paymentTerms?.usePhases || !paymentTerms?.usePhases ? 'phased' : 'monthly'],
      phase: getCompetitionPhase(isCompetitionStarted, isPerformer),
      isCompetitionStarted,
      deadline: new Date(vacancies[0].objectProjectComponent.deadline || deadline),
      totalAmount: normalizeMoney(totalAmount),
      lowestBid: normalizeMoney(minBy(bids, 'value')?.value || totalAmount),
      applicationDeadline: applicationDeadline ? new Date(applicationDeadline) : null,
      customer: {
        id: customerUserId,
        rating: formatRating(customerRating)[VACANCY_TYPE.customer],
        ...customerSelf,
        ...customerContractDetails.organization,
      },
      employeeUserId,
      object: {
        ...object,
        files: getObjectFiles(object.files, userId),
        type: object.objectType?.name,
        region: object.region?.name,
        stage: stageName,
        stageId,
        stageDeadline,
        projectComponents,
      },
      participants: withParticipants && bids.map(({ value, previousValue, createdAt, updatedAt, contractDetailType, user, userId, ratings }) => ({
        bid: {
          value: normalizeMoney(value),
          createdAt,
          updatedAt,
        },
        offerType: getOfferType(bids, totalAmount, value, previousValue, userId),
        userId,
        user: user
          ? {
            ...getEmployee(user, contractDetailType),
            isReadable: true,
          }
          : {
            ratings: {
              ...formatRating(ratings),
              avg: convertRating(ratings[0]?.ratingExpanded.avg || 0),
            },
          },
      })),
    };
  });

export const formatVacancy = (contract, userId) => ({
  ...formatVacancies([contract], userId)[0],
  contract: {
    ...formatContract(contract, null, userId),
  },
});
