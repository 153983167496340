import setWith from 'lodash/setWith';
import maxBy from 'lodash/maxBy';

import { getContractorName } from '../../utils/name';
import { getProjectComponentProps } from './projectComponent';
import { VACANCY_TYPE } from '../../utils/vacancyType';
import { getContractorFromVacancy, getSelectedEmployee, prepareToInsertContract } from './employee';
import { getRowWithDates } from '../../utils/date';
import { getObjectFiles } from './file';

const getVacanciesByType = (vacancies, isPerformer) => vacancies
  .filter(({ isPerformer: vacancyIsPerformer, isActive, isBreakPending }) => isPerformer
    ? vacancyIsPerformer
    : isPerformer === vacancyIsPerformer && (isActive || isBreakPending));

const getActiveVacancy = vacancies => vacancies.find(vacancy => vacancy.isActive);

const getObjectProjectComponents = (items, parentObjectProjectComponent = {}, isOwner, chiefEngineer, userId) => {
  return (items || []).filter(item => item.parentObjectProjectComponentId === parentObjectProjectComponent.id)
    .map(({
      id,
      name,
      type,
      isExpertiseRequired,
      templateProjectComponent,
      vacancies,
      files,
      deadline,
      createdAt,
      objectProjectComponentUsers,
      generatedCode,
      remarks,
      root,
      dataRequests,
    }) => {
      const projectComponentBaseFields = getProjectComponentProps({ id, name, templateProjectComponent, generatedCode });
      const objectProjectComponent = {
        ...projectComponentBaseFields,
        type,
        projectComponentId: templateProjectComponent?.id,
        deadline: new Date(deadline),
        createdAt,
        parent: parentObjectProjectComponent,
        isExpertiseRequired,
        ...(root
          ? {
            root: {
              ...root.rootComponent,
              deadline: new Date(root.rootComponent.deadline),
            },
          }
          : {}),
      };
      const vacanciesWithContractor = vacancies.map(getContractorFromVacancy);
      const projectComponents = getObjectProjectComponents(items, objectProjectComponent, isOwner, chiefEngineer, userId);
      const performers = getVacanciesByType(vacanciesWithContractor, true);
      const engineers = getVacanciesByType(vacanciesWithContractor, false);
      const activePerformerVacancy = getActiveVacancy(performers);
      const employees = [...vacanciesWithContractor, ...(chiefEngineer ? [chiefEngineer] : [])];
      const customer = employees.find(employee => employee.customer)?.customer || {};

      return {
        ...objectProjectComponent,
        performers,
        engineers,
        workAcceptances: activePerformerVacancy?.workAcceptances ? getRowWithDates(activePerformerVacancy.workAcceptances) : null,
        flattenedRemarks: remarks,
        flattenedFiles: files,
        dataRequests,
        files: files
          ? files.reduce((result, { id, type: fileType, file, user, phase, version, userId: linkOwner }) => {
            const resultFile = {
              ...file,
              id,
              fileId: file.id,
              isLinkOwner: linkOwner ? linkOwner === userId : file.userId === userId,
              version,
              objectProjectComponentId: objectProjectComponent.id,
              isOwner: file.userId === userId,
              user: user || file.user || [...employees, { ...customer, userId: customer.id }]
                .find(employee => employee.userId === file.userId),
            };

            if (phase) {
              const { id: versionId, number, createdAt, contractId } = version;
              const { key } = phase;

              if (result[contractId] &&
                result[contractId][key] &&
                result[contractId][key][number] &&
                result[contractId][key][number][fileType]
              ) {
                result[contractId][key][number][fileType].push(resultFile);
              } else if (result[contractId] &&
                result[contractId][key] &&
                result[contractId][key][number]
              ) {
                result[contractId][key][number][fileType] = [resultFile];
              } else {
                setWith(result, [contractId, key, number], { id: versionId, createdAt, [fileType]: [resultFile] }, Object);
              }
            } else if (version) {
              const { id: versionId, number, createdAt, contractId } = version;

              if (result[contractId] && result[contractId][number] && result[contractId][number][fileType]) {
                result[contractId][number][fileType].push(resultFile);
              } else if (result[contractId] && result[contractId][number]) {
                result[contractId][number][fileType] = [resultFile];
              } else {
                setWith(result, [contractId, number], { id: versionId, createdAt, [fileType]: [resultFile] }, Object);
              }
            } else if (result[fileType]) {
              result[fileType].push(resultFile);
            } else {
              result[fileType] = [resultFile];
            }

            return result;
          }, {})
          : {},
        lastFilesVersion: maxBy(files, 'version.number')?.version || { number: 0 },
        remarks,
        projectComponents,
        vacancies: vacanciesWithContractor,
        hasVacancies: vacanciesWithContractor.filter(({ isActive, userId }) => isActive && userId).length > 0 ||
          projectComponents.some(child => child.hasVacancies),
        hasInCompetitionVacancies: vacanciesWithContractor.filter(({ isActive, userId }) => isActive && !userId).length > 0 ||
          projectComponents.some(child => child.hasInCompetitionVacancies),
      };
    });
};

export const getObjects = (items, currentUserId, isExpert) => (items || []).map(({
  id,
  name,
  userId,
  createdAt,
  deadline,
  code,
  address,
  isTariffActive,
  isDemoTariff,
  isClosed,
  projectComponents = [],
  region: {
    id: regionId,
    name: region,
  },
  objectType: {
    id: objectTypeId,
    name: objectTypeName,
  },
  files,
  expertiseFiles,
}) => {
  const isOwner = userId === currentUserId;

  return {
    id,
    name,
    createdAt,
    deadline: deadline ? new Date(deadline) : null,
    code,
    address,
    type: objectTypeName,
    typeId: objectTypeId,
    regionId,
    region,
    userId,
    isTariffActive: isTariffActive || isExpert,
    isDemoTariff,
    isClosed,
    files: getObjectFiles(files, currentUserId),
    expertise: (expertiseFiles || []).map(expertise => ({
      ...expertise,
      isOwner: expertise.file.userId === currentUserId,
    })),
    stages: projectComponents
      .filter(item => !item.parentObjectProjectComponentId && (item.objectProjectComponentUsers.length > 0 || isOwner || isExpert))
      .map(({
        id: objectStageId,
        type,
        templateProjectComponent: {
          id: projectComponentId,
          name: stageName,
          icon: stageIcon,
          childType,
        },
        deadline: stageDeadline,
        vacancies,
      }) => {
        const objectProjectComponent = {
          id: objectStageId,
          projectComponentId,
          name: stageName,
          icon: stageIcon,
          type,
          deadline: new Date(stageDeadline),
          childType,
        };
        const vacanciesWithContractor = vacancies.map(getContractorFromVacancy);
        const engineers = getVacanciesByType(vacanciesWithContractor, false);
        const chiefEngineer = getActiveVacancy(engineers);

        return {
          ...objectProjectComponent,
          chiefEngineer,
          engineers,
          vacancies: vacanciesWithContractor,
          projectComponents: getObjectProjectComponents(
            projectComponents,
            objectProjectComponent,
            isOwner,
            chiefEngineer,
            currentUserId,
          ),
        };
      }),
  };
});

const setProjectComponentEngineerVacancies = (projectComponents, vacancies) => {
  projectComponents.forEach(({ id: projectComponentId, processedCode, engineers, projectComponents: children }) => {
    (engineers || []).forEach(engineer => {
      const { contractId, userId } = engineer;

      if (contractId && vacancies[contractId] && vacancies[contractId].position === VACANCY_TYPE.lead) {
        vacancies[contractId].followed.push(processedCode);
        vacancies[contractId].projectComponentIds.push(projectComponentId);
      } else if (contractId) {
        vacancies[contractId] = {
          ...engineer,
          name: userId ? getContractorName(engineer) : null,
          position: VACANCY_TYPE.lead,
          followed: [processedCode],
          projectComponentIds: [projectComponentId],
        };
      }
    });

    if (children && children.length > 0) {
      setProjectComponentEngineerVacancies(children, vacancies);
    }
  });
};

export const setStageEngineers = stages => {
  stages.forEach(stage => {
    const { engineers, id: stageId, name: stageName, projectComponents } = stage;
    const vacancies = {};

    stage.stageEngineers = engineers.map(engineer => {
      const { userId } = engineer;
      return {
        ...engineer,
        name: userId && getContractorName(engineer),
        position: VACANCY_TYPE.chief,
        followed: stageName,
        projectComponentIds: [stageId],
      };
    });

    setProjectComponentEngineerVacancies(projectComponents, vacancies);
    for (const contractId in vacancies) {
      const vacancy = vacancies[contractId];
      const { followed } = vacancy;

      stage.stageEngineers.push({
        ...vacancy,
        followed: [...followed].join(', '),
      });
    }
  });
};

const setFlattenedProjectComponents = (parentProjectComponent, flattenedProjectComponents) => {
  const { projectComponents: children, ...parentProjectComponentProps } = parentProjectComponent;
  if (children && children.length > 0) {
    children.forEach(childProjectComponent => {
      setFlattenedProjectComponents(childProjectComponent, flattenedProjectComponents);
    });
  } else {
    flattenedProjectComponents.push({
      ...parentProjectComponentProps,
    });
  }
};

export const setFlattenedStageProjectComponents = stages => {
  stages.forEach(stage => {
    stage.projectComponents.forEach(projectComponent => {
      projectComponent.flattenedProjectComponents = [];
      setFlattenedProjectComponents(projectComponent, projectComponent.flattenedProjectComponents);
    });
  });
};

export const getObjectTypeStages = (objectTypeId, objectTypes) => {
  return objectTypes.find(objectType => (objectTypeId === objectType.value));
};

export const getSelectedStages = (objectTypeStages, selectedProjectComponents) => {
  return objectTypeStages ? objectTypeStages.children.reduce((selectedStages, stage) => {
    const isStageSelected = stage.children.some(({ value: id }) => selectedProjectComponents.indexOf(id) > -1);
    if (isStageSelected) {
      selectedStages.push(stage);
    }
    return selectedStages;
  }, []) : [];
};

export const prepareToInsertObjectStages = ({
  objectType,
  projectComponents,
  contractDetailType,
  chiefEngineer,
  isSafeDeal,
  paymentTerms,
  totalAmount,
  ...restFields
}, objectId, projectComponentsTree, favoriteEngineers, customerContractDetails, vacancyTypeIds) => {
  const stages = getObjectTypeStages(objectType, projectComponentsTree);
  const stageDeadline = {};
  Object.keys(restFields).forEach(fieldName => {
    const isStageDeadlineField = fieldName.startsWith('stageDeadline-');
    if (isStageDeadlineField) {
      const stageId = Number(fieldName.replace('stageDeadline-', ''));
      stageDeadline[stageId] = restFields[fieldName];
    }
  });
  return getSelectedStages(stages, projectComponents).map(({
    value: projectComponentId,
    projectComponentTypeId: stageProjectComponentTypeId,
    children,
  }) => {
    const deadline = stageDeadline[projectComponentId];
    const stage = {
      projectComponentId,
      objectId,
      projectComponentTypeId: stageProjectComponentTypeId,
      deadline,
      children: {
        data: children.reduce((selectedProjectComponents, { value, projectComponentTypeId }) => {
          if (projectComponents.indexOf(value) > -1) {
            selectedProjectComponents.push({
              deadline,
              objectId,
              projectComponentId: value,
              projectComponentTypeId,
            });
          }
          return selectedProjectComponents;
        }, []),
      },
    };
    if (chiefEngineer) {
      const { employeeUserId, employeeContractDetails } = getSelectedEmployee(chiefEngineer, favoriteEngineers);
      stage.vacancies = {
        data: [{
          isPerformer: false,
          contract: {
            data: prepareToInsertContract({
              employeeUserId,
              employeeContractDetails,
              vacancyTypeId: vacancyTypeIds.chief,
              customerContractDetails,
              contractDetailType,
              isSafeDeal,
              paymentTerms,
              competitionTerms: {},
              totalAmount,
              deadline,
            }),
          },
        }],
      };
    }
    return stage;
  });
};

export const prepareToInsertObject = ({
  name,
  address,
  code,
  objectType,
  region,
  tariffPlan,
}) => ({
  name,
  address,
  code,
  regionId: region,
  objectTypeId: objectType,
  userTariffPlanId: tariffPlan,
});

export const isActiveEmployee = (employees, userId) => (employees || []).some(
  ({ userId: employeeUserId, isActive }) => isActive && employeeUserId === userId);

export const getActiveEmployee = employees => (employees || []).find(({ isActive }) => isActive);

export const getObjectsQuery = ({ userId, isOwner, isArbitration, isClosed, search }) => {
  const whereQuery = {
    _and: [],
  };

  if (isOwner) {
    whereQuery._and.push({
      _and: [
        { userId: { _eq: userId } },
        { isClosed: { _eq: false } },
      ],
    });
  } else if (isArbitration) {
    whereQuery._and.push({
      objectUsers: {
        objectExperts: { userId: { _eq: userId } },
      },
    });
  } else if (isClosed) {
    whereQuery._and.push({
      _and: [
        { isClosed: { _eq: true } },
        {
          _or: [
            { userId: { _eq: userId } },
            { objectUsers: { userId: { _eq: userId } } },
          ],
        },
      ],
    });
  } else {
    whereQuery._and.push({
      _and: [
        { isClosed: { _eq: false } },
        {
          objectUsers: {
            _and: [
              { userId: { _eq: userId } },
              { isActive: { _eq: true } },
            ],
          },
        },
      ],
    });
  }

  if (search && search !== '') {
    whereQuery._and.push({
      _or: [
        {
          name: { _ilike: search },
        },
        {
          code: { _ilike: search },
        },
      ],
    });
  }

  return whereQuery;
};

export const getProjectComponentChildrenQuery = userId => ({
  _or: [
    {
      objectProjectComponentUsers: {
        _and: [
          {
            userId: { _eq: userId },
          },
          {
            isActive: { _eq: true },
          },
        ],
      },
    },
    {
      objectProjectComponentExperts: {
        userId: { _eq: userId },
      },
    },
    {
      object: { userId: { _eq: userId } },
    },
  ],
});

export const getObjectMenuQuery = userId => ({
  _and: [
    {
      parentObjectProjectComponentId: { _is_null: true },
    },
    {
      ...getProjectComponentChildrenQuery(userId),
    },
  ],
});

export const getObjectListByUserQuery = (userId, onlyEngineers) => ({
  _and: [
    {
      _or: [
        {
          userId: { _eq: userId },
        },
        {
          objectUsers: {
            _and: [
              {
                userId: { _eq: userId },
              },
              {
                isActive: { _eq: true },
              },
              ...(onlyEngineers ? [{ isPerformer: { _eq: false } }] : []),
            ],
          },
        },
      ],
    },
    {
      isTariffActive: { _eq: true },
    },
  ],
});
