import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { confirmRegistration } from '../../store/actions/session';

class ConfirmRegistration extends Component {
  async componentDidMount() {
    const { match, confirmRegistration: dispatchConfirmRegistration } = this.props;
    await dispatchConfirmRegistration(match.params);
  }

  render() {
    return <div>...Loading</div>;
  }
}

ConfirmRegistration.propTypes = {
  match: PropTypes.object.isRequired,
  confirmRegistration: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { confirmRegistration })(ConfirmRegistration));
