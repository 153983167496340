import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const GET_USER_AGGREGATION_REFERENCES_QUERY = loader('../../graphql/queries/user/getAggregationReferences.graphql');

export default graphql(GET_USER_AGGREGATION_REFERENCES_QUERY, {
  name: 'getUserAggregationReferences',
  skip: props => props.isSkip,
  props: ({ getUserAggregationReferences: userAggregation }) => userAggregation,
});
