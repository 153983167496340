import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ListHeader from '../../../base/listHeader';
import { ICONS } from '../../../../utils/icons';
import { VACANCY_TYPE } from '../../../../utils/vacancyType';

export default class ContractsHeader extends PureComponent {
  menu = [
    {
      icon: ICONS.engineer,
      onClick: () => this.props.filterBy(VACANCY_TYPE.chief),
      title: 'Главный инженер',
    },
    {
      icon: ICONS.leadEngineer,
      onClick: () => this.props.filterBy(VACANCY_TYPE.lead),
      title: VACANCY_TYPE.lead,
    },
    {
      icon: ICONS.performer,
      onClick: () => this.props.filterBy(VACANCY_TYPE.performer),
      title: VACANCY_TYPE.performer,
    },
  ];

  addNewButton = {
    title: 'Предложить сотрудничество',
    onClick: this.props.onClickInviteToContract,
  };

  render() {
    const { orderBy, setOrder } = this.props;

    return <ListHeader
      menu={this.menu}
      orderBy={orderBy}
      setOrder={setOrder}
      addNewButton={this.addNewButton}
    />;
  }

  static propTypes = {
    filterBy: PropTypes.func.isRequired,
    orderBy: PropTypes.object.isRequired,
    setOrder: PropTypes.func,
    onClickInviteToContract: PropTypes.func.isRequired,
  };

  static defaultProps = {
    setOrder() {},
  };
}
