import Base from './base';
import { removeAuthCookie } from '../utils/session';

const BASE_URL = process.env.REACT_APP_SERVER_API_PATH;

export class AuthApi extends Base {
  constructor() {
    super(BASE_URL);

    this.apiClient.interceptors.response.use(response =>
      Promise.resolve(response.data ? response.data : null),
    );
  }

  setPassword = data => this.post('/user/setPassword', { data });

  recoverMessage = data => this.post('/user/recover', { data });

  signUp = data => this.post('/user/signup', { data });

  signUpWithDemo = data => this.post('/user/signup-with-demo', { data });

  confirm = data => this.post('/user/confirm', { data });

  signIn = data => {
    removeAuthCookie();
    return this.post('/user/signin', { data });
  };

  signOut = () => {
    removeAuthCookie();
    return this.post('/user/signout');
  }
}

export default new AuthApi();
