import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

import VacancyProcess from './index';
import { compose, withProps } from 'recompose';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import { denormalizeMoney } from '../../../../utils/money';
import { getBidRates } from '../../../../graphql/model/bidRate';
import { formatRating } from '../../../../graphql/model/rating';
import { getContractDetailsList } from '../../../../graphql/model/employee';
import withVacancyReferencesQuery from '../../../../graphql/hoc/vacancyReferences';
import withUserAggregationReferencesQuery from '../../../../graphql/hoc/userAggregationReferences';

const MAKE_BID_MUTATION = loader('../../../../graphql/queries/vacancy/makeBid.graphql');
const GET_BID_RATES_QUERY = loader('../../../../graphql/queries/vacancy/getBidRates.graphql');
const DELETE_BID_MUTATION = loader('../../../../graphql/queries/vacancy/deleteBid.graphql');
const CHOOSE_COMPETITION_WINNER_MUTATION = loader('../../../../graphql/queries/vacancy/chooseCompetitionWinner.graphql');

const withMakeBidMutation = graphql(MAKE_BID_MUTATION, { name: 'makeBid' });
const withDeleteBidMutation = graphql(DELETE_BID_MUTATION, { name: 'deleteBid' });
const withGetBidRatesQuery = graphql(GET_BID_RATES_QUERY, {
  name: 'getBidRates',
  props: ({ getBidRates: { bidRates } }) => ({
    bidRates: getBidRates(bidRates),
  }),
});
const withChooseCompetitionWinnerMutation = graphql(CHOOSE_COMPETITION_WINNER_MUTATION, {
  name: 'chooseCompetitionWinner',
});

const formatParticipants = (participants, userAggregation = []) => (participants || [])
  .map(({ user, userId, ...participant }) => ({
    ...participant,
    user: {
      ...user,
      objectsCount: userAggregation.find(({ userId: aggregationUserId }) => userId === aggregationUserId)?.objectsCount,
    },
    userId,
  }));

const mergeProps = withProps(({
  makeBid,
  deleteBid,
  chooseCompetitionWinner,
  contractDetails,
  userAggregation,
  vacancy,
  userId,
}) => {
  const { participants, contract } = vacancy;
  const { id: contractId, competitionTerms, vacancyTypeName } = contract;

  competitionTerms.isRatingValid = !contractDetails || !competitionTerms.minRating ||
    competitionTerms.minRating <= formatRating(contractDetails[0].ratings)[vacancyTypeName];

  return {
    userId,
    competitionTerms,
    vacancy: {
      ...vacancy,
      participants: formatParticipants(participants, userAggregation),
    },
    contractDetailTypes: getContractDetailsList(contractDetails).map(item => ({
      ...item,
      checked: item.length === 1,
    })),
    onMakeBid: ({ bidAmount, contractDetailType }) => makeBid({
      variables: {
        contractId,
        amount: denormalizeMoney(bidAmount),
        contractDetailType,
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
    onRemoveParticipant: participantUserId => {
      return deleteBid({
        variables: {
          userId: participantUserId,
          contractId,
        },
      }).then(result => {
        if (result.errors) {
          throw result.errors;
        }
      }).catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        throw error;
      });
    },
    onRemoveBid: () => {
      return deleteBid({
        variables: {
          userId,
          contractId,
        },
      }).then(result => {
        if (result.errors) {
          throw result.errors;
        }
      }).catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
        throw error;
      });
    },
    onChooseCompetitionWinner: participantUserId => chooseCompetitionWinner({
      variables: {
        userId: participantUserId,
        contractId,
      },
    }).then(result => {
      if (result.errors) {
        throw result.errors;
      }
    }).catch(error => {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
      throw error;
    }),
  };
});

export default compose(
  withVacancyReferencesQuery,
  withUserAggregationReferencesQuery,
  withMakeBidMutation,
  withDeleteBidMutation,
  withGetBidRatesQuery,
  withChooseCompetitionWinnerMutation,
  mergeProps,
)(VacancyProcess);
