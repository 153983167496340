import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';

const GET_PHASE_REFERENCES_QUERY = loader('../../graphql/queries/object/getPhaseReferences.graphql');

export default graphql(GET_PHASE_REFERENCES_QUERY, {
  name: 'getPhaseReferences',
  skip: props => props.isSkip,
  props: ({ getPhaseReferences: { workPhase } }) => ({
    phases: workPhase,
  }),
});
