import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import './index.scss';

export default class ProcessedCode extends PureComponent {
  render() {
    const { children, isLoading } = this.props;

    return isLoading ? <Spinner className="processed-code" animation="border" size="sm" /> : children;
  }

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    children: '',
    isLoading: false,
  }
}
