import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import FormGroupField from '../../../base/formGroup';
import Hint from '../../../base/hint';
import { PRIVACY_SETTINGS_FIELDS } from '../../../../graphql/model/user';

import './index.scss';

export default class PrivacySettings extends PureComponent {
  renderPrivacySection = (title, fields, enableHint) => <>
    <div className="privacy-header">
      <h6>{title}</h6>
      {enableHint && <Hint title="Настройка публичности">
        Когда вы сделали свой профиль открытым для других пользователей, у вас имеется возможность открывать и закрывать блоки вашего публичного профиля на свое усмотрение.
      </Hint>}
    </div>
    {fields.map(({ label, name }) => <Field
      key={name}
      name={`privacySettings.${name}`}
      component={FormGroupField}
      type="switch"
      labelLeft={label}
      isToggle
    />)}
  </>;

  render() {
    return <div className="form-card privacy-settings-layout">
      <h5>Настройки приватности</h5>
      {this.renderPrivacySection('Настройки участия в рейтинговой таблице', PRIVACY_SETTINGS_FIELDS.rating)}
      {this.renderPrivacySection('Настройки отображения данных в публичном профиле', PRIVACY_SETTINGS_FIELDS.public, true)}
    </div>;
  }
}
