import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/base/button';
import Logo from '../../assets/images/header-logo.svg';
import DefaultPhoto from '../../assets/images/default-photo.svg';
import { getFullName, getNameWithInitials } from '../../utils/name';
import { ROOT_ROUTE, ROUTES } from '../../utils/routes';
import HeaderTime from '../../components/headerTime';
import { getFileLink } from '../../utils/links';
import NotificationList from '../../components/notifications';
import { ICONS } from '../../utils/icons';
import PublicMenuModal from '../../components/modals/publicMenuModal';

import './index.scss';

class Header extends React.PureComponent {
  publicMenuModalRef = React.createRef();

  goToProfile = () => {
    this.props.history.push(ROUTES.profile);
  };

  goToMainPage = () => {
    this.props.history.push(ROOT_ROUTE);
  };

  goToSignUp = () => {
    this.props.history.push(ROUTES.signUp);
  };

  goToSignIn = () => {
    this.props.history.push(ROUTES.signIn);
  };

  handleOpenPublicMenu = () => {
    this.publicMenuModalRef.current.toggleModal();
  };

  handleMarkNotificationRead = async (notification, isMarkRead = false) => {
    const { updateNotification, history } = this.props;

    if (!notification.wasRead) {
      await updateNotification(notification.id, { wasRead: true });
    }

    if (isMarkRead) {
      return;
    }

    history.push(notification.link);
  };

  renderAuthorizedHeader(user, onSignOut, notifications, openSideBarMenu) {
    if (!user || Object.keys(user).length === 0) {
      return;
    }

    const { photo } = user;

    return <>
      <HeaderTime />
      <div className="info-buttons">
        <Button IconComponent={ICONS.rss} onClick={this.props.onSubscriptions} isBorderless>
          <span className="title">Подписки</span>
        </Button>
        <NotificationList notifications={notifications} onMarkRead={this.handleMarkNotificationRead} />
      </div>
      <div className="user-info">
        <Button className="burger-button" onClick={openSideBarMenu} isBorderless IconComponent={ICONS.menuHamburger} />
        <Button className="profile" onClick={this.goToProfile} isBorderless>
          <img className="photo" src={photo ? getFileLink(photo.remotePath) : DefaultPhoto} alt="" />
          <span className="user-full-name">{getFullName(user)}</span>
          <span className="user-short-name">{getNameWithInitials(user)}</span>
        </Button>
        <Button
          className="sign-out-button"
          IconComponent={ICONS.signOut}
          onClick={onSignOut}
          isBorderless
          buttonProps={{
            name: 'signOutButton',
          }}
        />
      </div>
    </>;
  }

  renderPublicHeader() {
    const isAuthPage = [ROUTES.signIn, ROUTES.signUp].includes(this.props.location.pathname);

    return <>
      <div className='public-menu-buttons'>
        <Button
          className="burger-button"
          isBorderless IconComponent={ICONS.menuHamburger}
          onClick={this.handleOpenPublicMenu}
        />
        {!isAuthPage && <>
          <Button id='sign-up-button' isBorderless onClick={this.goToSignUp}>
            Регистрация
          </Button>
          <Button id='sign-in-button' onClick={this.goToSignIn}>
            Вход
          </Button>
        </>}
      </div>
    </>;
  }

  render() {
    const { user, onSignOut, notifications, openSideBarMenu, isAuthenticated } = this.props;

    return <div className="header-layout">
      <div className="header-content">
        {/* eslint-disable-next-line */}
        <img className="logo" src={Logo} alt="logo" onClick={this.goToMainPage} />
        {isAuthenticated
          ? this.renderAuthorizedHeader(user, onSignOut, notifications, openSideBarMenu)
          : this.renderPublicHeader()}
      </div>
      <PublicMenuModal history={this.props.history} onRef={this.publicMenuModalRef} />
    </div>;
  }

  static propTypes = {
    user: PropTypes.object,
    notifications: PropTypes.object,
    history: PropTypes.object.isRequired,
    onSignOut: PropTypes.func.isRequired,
    onSubscriptions: PropTypes.func,
    updateNotification: PropTypes.func.isRequired,
    openSideBarMenu: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    user: {},
    notifications: {
      read: [],
      unread: [],
    },
    onSubscriptions() {},
  };
}

export default Header;
