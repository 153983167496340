import { compose, withProps } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Objects from './index';
import { ROUTES } from '../../utils/routes';
import { MENU_ITEMS } from '../../utils/menu';

import withUserTariffPlanReferencesQuery from '../../graphql/hoc/userTariffPlanReferences';
import withContractDetailsReferencesQuery from '../../graphql/hoc/contractDetailsReferences';
import withExpertUserReferencesQuery from '../../graphql/hoc/expertUserReferences';

const mapStateToProps = ({ session: { user } }) => ({
  userId: user.id,
});

const mergeProps = withProps(({ location, isExpert, isContractDetailsReferencesLoading, isUserTariffPlansLoading }) => {
  const menu = MENU_ITEMS.mainMenu.find(menuItem => menuItem.route === ROUTES.objects).children;

  return {
    menu: isExpert ? menu : menu.slice(0, -1),
    isArbitration: !!matchPath(location.pathname, ROUTES.arbitrationObjects),
    isOwner: !!matchPath(location.pathname, ROUTES.myObjects),
    isClosed: !!matchPath(location.pathname, ROUTES.closedObjects),
    predefinedTariffPlanId: location.state?.tariffPlanId || null,
    isLoading: isContractDetailsReferencesLoading || isUserTariffPlansLoading,
  };
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  withExpertUserReferencesQuery,
  withUserTariffPlanReferencesQuery,
  withContractDetailsReferencesQuery,
  mergeProps,
)(Objects);
