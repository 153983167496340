const NETWORK_STATUS = {
  loading: 1,
  setVariables: 2,
  fetchMore: 3,
  refetch: 4,
  Unused: 5,
  poll: 6,
  ready: 7,
  error: 8,
};

export default NETWORK_STATUS;
