import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, matchPath, withRouter } from 'react-router-dom';

import { MENU_ITEMS } from '../../utils/menu';
import NotImplementedYet from '../../components/notImplementedYet';
import { ROUTES } from '../../utils/routes';
import FavoritesHeader from '../../components/favorites/header';
import WebAnalyticsRoute from '../../layout/app/webAnalyticsRoute';

class FavoritesMenu extends PureComponent {
  favoritesMenu = MENU_ITEMS.mainMenu.find(menuItem => menuItem.route === ROUTES.favorites).children;

  render() {
    const isPerformer = !!matchPath(this.props.location.pathname, ROUTES.favoritePerformers);

    return <>
      <FavoritesHeader menu={this.favoritesMenu} isPerformer={isPerformer} />
      <Switch>
        {this.favoritesMenu.map(({ route, name, component: Component = NotImplementedYet }, index) =>
          <WebAnalyticsRoute
            key={index}
            path={route}
            name={name}
            render={props => <Component
              {...props}
              isPerformer={isPerformer}
            />}
          />)}
        <Redirect to={ROUTES.favoriteEngineers} />
      </Switch>
    </>;
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
  }
}

export default withRouter(FavoritesMenu);
