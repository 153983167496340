import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import Hint from '../../base/hint';
import { getFormattedNumber } from '../../../utils/numbers';

import './index.scss';

export default class ContractReviewModal extends React.PureComponent {
  handleClose = () => {
    this.props.reset();
    this.props.onClose();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  onSubmit = async data => {
    await this.props.onCreateReview(data);
    this.handleCancel();
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      ...props
    } = this.props;

    return <Modal
      {...props}
      className="contract-review-modal"
      title="Оценка партнёрства"
      onClose={this.handleClose}
    >
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="subject"
          component={FormGroupField}
          type="textarea"
          label="Предмет договора"
          isDisabled
        />
        <Field
          name="deadline"
          component={FormGroupField}
          type="text"
          label="Дата окончания"
          isDisabled
        />
        <Field
          name="totalAmount"
          component={FormGroupField}
          type="number"
          label="Сумма сделки"
          format={getFormattedNumber}
          isDisabled
        />
        <Field
          name="contractor"
          component={FormGroupField}
          type="text"
          label="Наименование контрагента"
          isDisabled
        />
        <div className="evaluation">
          <div className="is-recommended">
            <label>Впечатления по окончанию сотрудничества</label>
            <Field
              name="isRecommended"
              component={FormGroupField}
              type="switch"
              labelLeft={<>
                Не рекомендую сотрудничество
                <Hint title="Не рекомендую сотрудничество">
                  Выбор данного параметра повлияет на общий рейтинг пользователя и будет доступен при просмотре его публичного профиля
                </Hint>
              </>}
              labelRight={<>
                Рекомендую сотрудничество
                <Hint title="Рекомендую сотрудничество">
                  Выбор данного параметра повлияет на общий рейтинг пользователя и будет доступен при просмотре его публичного профиля
                </Hint>
              </>}
            />
          </div>
          <Field
            name="evaluation"
            component={FormGroupField}
            type="rating"
            label="Ваша оценка профессионализма контрагента"
          />
        </div>
        <Field
          name="review"
          component={FormGroupField}
          type="textarea"
          label="Письменный отзыв"
        />
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    onCreateReview: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    onClose() {},
  };
}
