import React from 'react';
import PropTypes from 'prop-types';

import InfiniteList from '../../base/infiniteList';
import Item from './item';

import './index.scss';

export default class TaskList extends React.PureComponent {
  render() {
    return <InfiniteList
      className="tasks-list-layout"
      emptyMessage='Ваш список задач пуст. Чтобы добавить в список задач нажмите "Добавить задачу"'
      itemComponent={Item}
      {...this.props}
    />;
  }

  static propTypes = {
    items: PropTypes.array,
  };

  static defaultProps = {
    items: [],
  };
}
