import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { getTaxSystems } from '../model/taxSystems';
import { getVacancyTypeIdsMap } from '../model/vacancyType';
import { getAdmissionTypes } from '../model/admissionTypes';

const GET_VACANCY_REFERENCES_QUERY = loader('../../graphql/queries/vacancy/getReferences.graphql');

export default graphql(GET_VACANCY_REFERENCES_QUERY, {
  name: 'getVacancyReferences',
  skip: props => props.isSkip,
  options: ({ userId }) => ({
    variables: { userId },
  }),
  props: result => {
    const {
      getVacancyReferences: {
        contractDetails,
        taxSystems,
        admissionTypes,
        vacancyTypes,
      },
    } = result;

    return {
      taxSystems: getTaxSystems(taxSystems),
      contractDetails,
      admissionTypes: getAdmissionTypes(admissionTypes),
      vacancyTypeIds: getVacancyTypeIdsMap(vacancyTypes),
    };
  },
});
