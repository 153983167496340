import { reduxForm } from 'redux-form';
import { compose, withContext, withProps } from 'recompose';

import CreateRemarkModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import PropTypes from 'prop-types';
import AuthorizedApi from '../../../api/authorized';
import { getFullName } from '../../../utils/name';
import { getActiveEmployee } from '../../../graphql/model/object';

const FORM_NAME = 'createRemarkModal';

const mergeProps = withProps(({
  projectComponent: { name, performers, id, objectId },
  remarkResponse,
  isRevision,
}) => ({
  projectComponentId: id,
  objectId,
  onFileUpload: AuthorizedApi.uploadFile,
  initialValues: {
    objectProjectComponent: name,
    performer: getFullName(getActiveEmployee(performers) || {}),
    ...(remarkResponse && !isRevision
      ? { isAgree: !remarkResponse.status || remarkResponse.status.kind === 'positive' }
      : {}),
    ...remarkResponse,
  },
}));

export default compose(
  mergeProps,
  withContext(
    { onFileUpload: PropTypes.func.isRequired },
    ({ onFileUpload }) => ({ onFileUpload }),
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: getValidation([
      'remark',
      'response',
      'revisionMessage',
      'reasonExplanation',
    ]),
    onSubmitFail: getSubmitValidation,
  }),
)(CreateRemarkModal);
