import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';

export default class AssignObjectToTariffModal extends PureComponent {
  handleClose = () => {
    this.props.onCloseModal();
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  onSubmit = async () => {
    this.props.onAssignObject(this.props.tariffPlanId);
    this.handleCancel();
  };

  render() {
    const {
      onRef,
      handleSubmit,
      submitting,
      pristine,
      invalid,
      objects,
    } = this.props;

    return <Modal
      onRef={onRef}
      className="assign-object-to-tariff-modal"
      title="Прикрепить объект к тарифному плану"
      onClose={() => this.handleCancel}
    >
      <Form onSubmit={handleSubmit(this.onSubmit)} className="assign-object-to-tariff-form">
        <Field
          name="object"
          component={FormGroupField}
          type="select"
          className="form-group"
          label="Наименование объекта"
          data={objects}
        />
        <div className="actions">
          <Button
            isLarge
            isDisabled={pristine || invalid}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Привязать
          </Button>
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onAssignObject: PropTypes.func.isRequired,
    objects: PropTypes.array,
    tariffPlanId: PropTypes.number,
    onCloseModal: PropTypes.func.isRequired,
    // redux form
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    onRef: PropTypes.object,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    isSelfRequisitesSelected: false,
    objects: [],
    tariffPlanId: null,
  };
}
