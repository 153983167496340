import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import upperFirst from 'lodash/upperFirst';

import Button from '../../base/button';
import Modal from '../../base/modal';
import AddFilesSection from '../../base/addFilesSection';
import { STAGE_FILE_TYPES } from '../../../utils/stage';
import { ICONS } from '../../../utils/icons';
import { PDF_FILE_TYPE, RESULTS_BUNDLED_IN_PDF_MAX_FILE_SIZE_MB } from '../../../utils/file';
import FileUploader from '../../base/fileUploader';

import './index.scss';

export default class AddProjectComponentFilesVersion extends React.PureComponent {
  state = {
    isEmpty: false,
    inProgress: false,
    addedFiles: [],
  };

  getIsSourceInEditableFormat = type => type === STAGE_FILE_TYPES.sourceInEditableFormat.type;

  handleSubmit = data => {
    const types = Object.keys(data);

    if (!types.some(type => {
      const isSourceInEditableFormat = this.getIsSourceInEditableFormat(data[type]);
      return isSourceInEditableFormat ? data[type].some(({ file }) => file?.remotePath) : data[type]?.remotePath;
    })) {
      this.setState({
        isEmpty: true,
      });

      return null;
    }

    const { uploadFiles, insertFiles, insertFilesVersion } = this.props;

    this.setState({ inProgress: true });
    return insertFilesVersion()
      .then(versionResult => Promise.all(
        types.map(type => {
          const isSourceInEditableFormat = this.getIsSourceInEditableFormat(type);
          return uploadFiles(isSourceInEditableFormat
            ? data[type].filter(({ file }) => file?.remotePath).map(({ file }) => file)
            : data[type]?.remotePath ? data[type] : {},
          ).then(result => insertFiles(
            result.data.insert_file.returning.map(file => file.id),
            type,
            versionResult.data.insert_objectProjectComponentFileVersion_one.id),
          );
        })),
      ).then(() => {
        this.setState({ inProgress: false }, this.toggleModal);
      });
  };

  toggleModal = () => {
    this.props.reset();
    this.setState({ isEmpty: false });
    this.props.onRef.current.toggleModal();
  };

  render() {
    const { handleSubmit, submitting, pristine, title, storagePath, typeName, ...props } = this.props;
    const { isEmpty, inProgress } = this.state;

    return <Modal
      className="add-section-files-version-modal"
      title={title}
      {...props}
    >
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <AddFilesSection
          name={STAGE_FILE_TYPES.sourceInEditableFormat.type}
          storagePath={storagePath}
          label={STAGE_FILE_TYPES.sourceInEditableFormat.title.replace('PROJECT_COMPONENT', upperFirst(typeName))}
          buttonText="Добавить исходники"
          multiple
        />
        <Field
          name={STAGE_FILE_TYPES.resultBundledInPdf.type}
          type="text"
          label="Добавить PDF файл"
          component={FileUploader}
          storagePath={storagePath}
          maxFileSize={RESULTS_BUNDLED_IN_PDF_MAX_FILE_SIZE_MB}
          acceptedFileTypes={PDF_FILE_TYPE}
          showInput
          showCustomNameInput
        />
        {isEmpty && <div className="info">
          <ICONS.infoBold className="info-icon" />
          <p>Для добавления новой версии необходимо загрузить хотя бы один файл в один из разделов</p>
        </div>}
        <div className="action-buttons">
          <Button onClick={this.toggleModal} isBorderless isLarge>Отмена</Button>
          <Button
            isLarge
            isDisabled={pristine || submitting}
            isLoading={inProgress}
            buttonProps={{
              type: 'submit',
            }}
          >
            Добавить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    typeName: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    insertFiles: PropTypes.func.isRequired,
    insertFilesVersion: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    storagePath: PropTypes.string.isRequired,
    reset: PropTypes.func.isRequired,
  };
}
