import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import debounce from 'lodash/debounce';
import { YMInitializer } from 'react-yandex-metrika';

import WebAnalyticsRoute from './webAnalyticsRoute';
import history from '../../utils/history';
import AuthRoute from './authRoute';
import ConfirmRegistration from '../../pages/confirmRegistration';
import CheckInvitation from '../../pages/checkInvitation/container';
import LoggedInLayoutContainer from '../loggedIn/container';
import AuthContainer from '../../pages/auth/container';
import Header from '../header/container';
import Footer from '../footer';
import { signOut } from '../../store/actions/session';
import Watermark from '../../components/base/watermark';
import SetPasswordContainer from '../../pages/setPassword/container';
import SideBar from '../sideBar/container';
import TariffPlans from '../../pages/tariffs/plans/container';
import WindowInnerWidthContext from '../../contexts/windowInnerWidth';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { ROUTES } from '../../utils/routes';
import ConfirmEmail from '../../pages/confirmEmail';
import PublicRoute from './publicRoute';

const TOAST_AUTO_CLOSE = 5000;
const ONE_SECOND_IN_MILLISECONDS = 1000;
const { REACT_APP_YANDEX_METRIKA_ACCOUNT } = process.env;
const yandexMetrikaAccount = REACT_APP_YANDEX_METRIKA_ACCOUNT ? parseInt(REACT_APP_YANDEX_METRIKA_ACCOUNT, 10) : null;

class AppLayout extends Component {
  state = {
    innerWidth: window.innerWidth,
  };

  debounced = null;

  componentDidMount() {
    this.debounced = debounce(() => {
      this.setState({ innerWidth: window.innerWidth });
    }, ONE_SECOND_IN_MILLISECONDS);
    window.addEventListener('resize', this.debounced);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounced);
    this.debounced.cancel();
  }

  render() {
    const { isAuthenticated, signOut: dispatchSignOut } = this.props;

    return <WindowInnerWidthContext.Provider value={this.state.innerWidth}>
      <div className="app-layout">
        <Watermark />
        {yandexMetrikaAccount && <YMInitializer
          accounts={[yandexMetrikaAccount]}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version="2"
        />}
        <Router history={history}>
          {isAuthenticated && <SideBar />}
          <Header
            onSignOut={dispatchSignOut}
          />
          <div className="app-content flexible">
            <Switch>
              <WebAnalyticsRoute exact path={ROUTES.signIn} component={AuthContainer} />
              <WebAnalyticsRoute exact path={ROUTES.signUp} component={AuthContainer} />
              <WebAnalyticsRoute exact path={ROUTES.signUpWithDemo} component={AuthContainer} />
              <WebAnalyticsRoute exact path={ROUTES.recover} component={SetPasswordContainer} />
              <WebAnalyticsRoute exact path={`${ROUTES.setPassword}/:token`} component={SetPasswordContainer} />
              <WebAnalyticsRoute exact path={`${ROUTES.confirmRegistration}/:token`} component={ConfirmRegistration} />
              <WebAnalyticsRoute exact path={`${ROUTES.checkInvitation}/:token`} component={CheckInvitation} />
              <WebAnalyticsRoute exact path={`${ROUTES.confirmEmail}/:token`} component={ConfirmEmail} />
              <PublicRoute exact path={ROUTES.publicTariffs} authorizedRoute={ROUTES.tariffs} component={TariffPlans} />
              <AuthRoute path='/' component={LoggedInLayoutContainer} />
            </Switch>
          </div>
          {!isAuthenticated && <Footer />}
        </Router>
        <ToastContainer
          className="toast-container"
          position="top-right"
          autoClose={TOAST_AUTO_CLOSE}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    </WindowInnerWidthContext.Provider>;
  }
}

const mapStateToProps = ({ session: { isAuthenticated } }) => ({
  isAuthenticated,
});

AppLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { signOut })(AppLayout);
