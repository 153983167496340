import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import upperFirst from 'lodash/upperFirst';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

import Loading from '../../../../components/base/loading';
import ProjectCompositionTree from '../../../../components/objects/object/projectComposition/tree';
import ProjectCompositionList from '../../../../components/objects/object/projectComposition/list';
import ObjectInfo from '../../../../components/objects/object/objectInfo';
import Menu from '../../../../components/base/menu';
import Confirm2OptionsModal from '../../../../components/modals/confirm/2options';
import ChangeProjectCompositionModal from './changeProjectCompositionModalContainer';
import { getRemoveProjectComponent } from '../../../../components/modals/confirm/2options/schemas';
import { ROUTES } from '../../../../utils/routes';
import Switch from '../../../../components/base/switch';
import { getCode } from '../../../../graphql/model/projectComponent';
import { goTo } from '../../../../utils/menu';
import { ICONS } from '../../../../utils/icons';

import './index.scss';
import InfoModal from '../../../../components/modals/info';
import { PROJECT_COMPONENT_CANNOT_BE_DELETED } from '../../../../utils/messages';

const ACTIONS = {
  insert: 'insert',
  update: 'update',
};

const defaultSelectedItem = {
  parent: {},
  item: {},
  level: null,
};

const getParents = (parent, parents) => {
  parents.push({
    name: parent.name,
    typeName: parent.type.name,
  });
  if (parent.parent) {
    getParents(parent.parent, parents);
  }
};

export default class ProjectComposition extends React.PureComponent {
  removeStageItemModalRef = React.createRef();
  changeProjectCompositionModalRef = React.createRef();
  infoModalRef = React.createRef();

  action = '';
  state = {
    isDiagram: false,
    selectedItem: defaultSelectedItem,
  };

  handleMenuClick = index => {
    const { object: { id }, templateStages } = this.props;
    goTo(ROUTES.projectCompositionStage, { id, stageId: templateStages[index].id });
  };

  handleClickRemoveProjectComponent = item => {
    const { userId, currentStage } = this.props;
    const chiefEngineerUserId = currentStage.chiefEngineer?.userId;
    const isChiefEngineer = userId === chiefEngineerUserId;

    isChiefEngineer && item.hasInCompetitionVacancies
      ? this.setState({ selectedItem: { ...defaultSelectedItem, item } }, this.toggleRemoveProjectComponentInfoModal)
      : this.setState({ selectedItem: { ...defaultSelectedItem, item } }, this.toggleRemoveProjectComponentModal);
  };

  toggleRemoveProjectComponentModal = () => {
    this.removeStageItemModalRef.current.toggleModal();
  };

  toggleRemoveProjectComponentInfoModal = () => {
    this.infoModalRef.current.toggleModal();
  };

  prepareToInsert({ projectComponentId, deadline, customName }) {
    const { selectedItem: { parent } } = this.state;
    const { object: { id } } = this.props;

    return {
      parentObjectProjectComponentId: parent.id,
      projectComponentId,
      deadline,
      name: customName,
      objectId: id,
      projectComponentTypeId: parent.childType.id,
    };
  }

  handlePresentationTypeChange = value => {
    this.setState({ isDiagram: value });
  };

  prepareToUpdate({ projectComponentId, deadline, customName }) {
    return {
      projectComponentId: projectComponentId || null,
      deadline,
      ...(!projectComponentId ? { name: customName } : {}),
    };
  }

  handleSubmit = data => {
    const {
      insertObjectProjectComponent,
      updateObjectProjectComponent,
    } = this.props;
    const { selectedItem: { parent, level, item } } = this.state;
    const { object: { id: objectId }, currentStage } = this.props;
    let promise;

    if (this.action === ACTIONS.update) {
      promise = updateObjectProjectComponent(item.id, this.prepareToUpdate(data));
    } else if (level === 1 && !currentStage.id) {
      promise = insertObjectProjectComponent({
        projectComponentId: parent.projectComponentId,
        deadline: data.deadline,
        objectId,
        projectComponentTypeId: parent.type.id,
        children: {
          data: this.prepareToInsert(data),
        },
      });
    } else {
      promise = insertObjectProjectComponent(this.prepareToInsert(data), data.code);
    }

    return promise.then(() => {
      this.changeProjectCompositionModalRef.current.toggleModal();
    }).catch(noop);
  };

  handleDelete = async () => {
    const { selectedItem: { item: { id } } } = this.state;
    await this.props.deleteObjectProjectComponent(id);
    this.toggleRemoveProjectComponentModal();
  };

  handleClickAddStageChild = () => {
    const { currentStage, selectedTemplateStageId, templateStages } = this.props;
    this.action = ACTIONS.insert;
    let parent;
    if (currentStage.projectComponentId) {
      parent = currentStage;
    } else {
      const selectedTemplateStage = templateStages.find(stage => stage.id === selectedTemplateStageId);
      parent = {
        projectComponentId: selectedTemplateStage.id,
        name: selectedTemplateStage.title,
        childType: selectedTemplateStage.childType,
        type: selectedTemplateStage.type,
      };
    }

    this.toggleModal({
      level: 1,
      parent: parent,
    });
  };

  handleClickAddProjectComponent = (parent, level) => {
    this.action = ACTIONS.insert;
    this.toggleModal({ level, parent });
  };

  prepareInitialValues() {
    const { selectedItem: { item: { initialName, name, code, deadline, projectComponentId } } } = this.state;
    const initialValues = {
      deadline,
    };

    if (projectComponentId) {
      initialValues.projectComponentId = projectComponentId;
    } else {
      initialValues.customName = initialName;
    }

    initialValues.code = this.computeCode(name, code);
    return initialValues;
  }

  handleClickEditProjectComponent = (item, level, parent) => {
    this.action = ACTIONS.update;
    this.toggleModal({ level, parent, item });
  };

  toggleModal({ parent, level, item = {} }) {
    this.setState(
      { selectedItem: { ...defaultSelectedItem, parent, level, item } },
      this.changeProjectCompositionModalRef.current.toggleModal,
    );
  }

  getExistingProjectComponentIds() {
    const { selectedItem: { parent } } = this.state;
    return (parent && parent.projectComponents ? parent.projectComponents : [])
      .map(({ projectComponentId }) => projectComponentId);
  }

  computeCode = (name, code = '') => {
    let { selectedItem: { parent: { projectComponents = [], processedCode } = {} } } = this.state;
    if (this.action === ACTIONS.insert) {
      projectComponents = [{ name, createdAt: (new Date()).toISOString() }, ...projectComponents];
    }

    let index = findIndex(projectComponents, ['name', name]);

    if (index === -1) {
      index = 0;
    }

    return getCode(code, index, processedCode);
  };

  getModalProps() {
    const { object: { name } } = this.props;
    const {
      selectedItem: { parent: { projectComponentId, deadline, root, childType, ...parent }, item: { hasVacancies } },
    } = this.state;
    const initialValues = this.action === ACTIONS.update ? this.prepareInitialValues() : {};
    const parents = [];

    if (parent.name) {
      getParents(parent, parents);
      parents.reverse();
    }

    return {
      existingProjectComponentIds: this.getExistingProjectComponentIds(),
      object: name,
      onSubmit: this.handleSubmit,
      initialValues,
      computeCode: this.computeCode,
      maxDeadline: root?.deadline || deadline,
      projectComponentId,
      parentCode: parent.processedCode,
      parents,
      childType,
      hasVacancies,
    };
  }

  getInfoMessage = ({ name, gender }) => PROJECT_COMPONENT_CANNOT_BE_DELETED
    .replace('PROJECT_COMPONENT', upperFirst(name))
    .replace('DELETED', gender === 'm' ? 'удален' : 'удалена');

  render() {
    const {
      isOwner,
      object,
      isLoading,
      isStagesLoading,
      templateStages,
      currentStage,
      selectedTemplateStageId,
    } = this.props;
    const { selectedItem: { item }, isDiagram } = this.state;

    if (isEmpty(object)) {
      if (isLoading || isStagesLoading) {
        return <Loading />;
      } else {
        return <Redirect to={ROUTES.notFound} />;
      }
    }

    const ProjectCompositionComponent = isDiagram ? ProjectCompositionTree : ProjectCompositionList;

    return <div className="project-composition-page">
      <div className="page-header-menu">
        <Menu
          items={templateStages}
          onClick={this.handleMenuClick}
          setActive={index => templateStages[index].id === selectedTemplateStageId}
          isTabMenu
        />
      </div>
      <ObjectInfo
        isOwner={isOwner}
        object={object}
      />
      <Switch
        isChecked={isDiagram}
        onChange={this.handlePresentationTypeChange}
        labelLeft="Текстовый вариант"
        labelRight="Графический вариант"
      />
      <ProjectCompositionComponent
        items={currentStage.projectComponents || []}
        parentProjectComponent={currentStage}
        onClickRemove={this.handleClickRemoveProjectComponent}
        onClickAdd={this.handleClickAddProjectComponent}
        onClickAddStageChild={this.handleClickAddStageChild}
        onClickEdit={this.handleClickEditProjectComponent}
      />
      <Confirm2OptionsModal
        onRef={this.removeStageItemModalRef}
        className="confirm-2-options-modal project-component-remove-modal"
        {...getRemoveProjectComponent(
          item,
          currentStage.name,
          this.handleDelete,
          this.toggleRemoveProjectComponentModal,
        )}
      >
        <div className="info">
          <ICONS.infoBold className="info-icon" />
          <p>Если вы удалите данный раздел, вместе с ним будут удалены все связанные с ним данные.</p>
        </div>
      </Confirm2OptionsModal>
      <ChangeProjectCompositionModal onRef={this.changeProjectCompositionModalRef} {...this.getModalProps()} />
      <InfoModal
        onRef={this.infoModalRef}
        message={item.type ? this.getInfoMessage(item.type) : ''}
      />
    </div>;
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    isStagesLoading: PropTypes.bool,
    object: PropTypes.object,
    currentStage: PropTypes.object,
    templateStages: PropTypes.array,
    selectedTemplateStageId: PropTypes.number,
    userId: PropTypes.number,
    chiefEngineerUserId: PropTypes.number,
    insertObjectProjectComponent: PropTypes.func,
    updateObjectProjectComponent: PropTypes.func,
    deleteObjectProjectComponent: PropTypes.func,
    isOwner: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    isStagesLoading: false,
    object: {},
    currentStage: {},
    templateStages: [],
    userId: null,
    chiefEngineerUserId: null,
    selectedTemplateStageId: null,
    insertObjectProjectComponent() {},
    updateObjectProjectComponent() {},
    deleteObjectProjectComponent() {},
  };
}
