import React from 'react';
import { Form, Field } from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import noop from 'lodash/noop';
import round from 'lodash/round';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';
import Hint from '../../base/hint';
import Rating from '../../base/rating';
import { getFormattedNumber } from '../../../utils/numbers';
import { TAX_SYSTEMS } from '../../../utils/taxSystem';
import { Link } from 'react-router-dom';
import { getPath } from '../../../utils/menu';
import { ROUTES } from '../../../utils/routes';

import './index.scss';

const getNormalizedAmount = value => round(value, 2);
const pirsOwnerVacancySelfRequisitesCommission = Number(process.env.REACT_APP_PIRS_OWNER_VACANCY_SELF_REQUISITES_COMMISSION);
const pirsOwnerVacancyOrgRequisitesCommission = Number(process.env.REACT_APP_PIRS_OWNER_VACANCY_ORG_REQUISITES_COMMISSION);

const getSecureDealCommissionHints = (bidRates, isPirsOwnerVacancy) => (<Hint
  title="Комиссия за “Безопасную сделку”"
  placement="right"
  className="make-bid-hint"
>
  {isPirsOwnerVacancy
    ? <>
      <p>{`• для физического лица - комиссия ${pirsOwnerVacancySelfRequisitesCommission}%`}</p>
      <p>• для юридического лица - комиссия не взимается</p>
    </>
    : bidRates.map(({ minAmount, maxAmount, rate, minCommission }, index) => {
      const isLastBidRate = index === bidRates.length - 1;
      let hint = '• ' + (index === 0
        ? `при сумме сделки менее ${getFormattedNumber(maxAmount)} рублей –`
        : `свыше ${getFormattedNumber(minAmount) + (isLastBidRate ? ' рублей' : '')} – комиссия`);
      hint += ` ${rate} %, но не менее ${getFormattedNumber(minCommission)} рублей${isLastBidRate ? '.' : ';'}`;
      return <p key={index}>{hint}</p>;
    })}
</Hint>);

export default class MakeBidModal extends React.PureComponent {
  state = {
    isSafeDeal: this.props.isSafeDeal,
    blockDirectPayments: this.props.isSafeDeal,
    isWarningDisplayed: false,
    taxSystemName: null,
  };

  getTaxSystem = contractDetailTypes => contractDetailTypes.find(({ value }) =>
    value === this.props.selectedContractDetailType)?.taxSystemName;

  handleChangeBidAmount = () => {
    const { change, bidRates, currentBidAmount, isPirsOwnerVacancy, isSelfContractDetails, contractDetailTypes } = this.props;
    const { isSafeDeal } = this.state;
    let commissionAmount = 0;
    let payoutAmount = currentBidAmount;

    if (isSafeDeal && currentBidAmount > 0) {
      const bidRate = bidRates.find(({ minAmount, maxAmount }) =>
        currentBidAmount >= minAmount && currentBidAmount < maxAmount);
      const { minCommission } = bidRate;
      let currentRate;

      this.setState({ taxSystemName: this.getTaxSystem(contractDetailTypes) });
      this.setState({ isWarningDisplayed: !this.state.taxSystemName });

      if (isPirsOwnerVacancy) {
        if (isSelfContractDetails) {
          if (this.state.taxSystemName === TAX_SYSTEMS.ndfl.name) {
            currentRate = pirsOwnerVacancySelfRequisitesCommission;
          } else {
            currentRate = 0;
          }
        } else {
          currentRate = pirsOwnerVacancyOrgRequisitesCommission;
        }
      } else {
        currentRate = bidRate.rate;
      }

      const calculatedCommission = currentBidAmount / 100 * currentRate;
      commissionAmount = calculatedCommission < minCommission && !isPirsOwnerVacancy ? minCommission : getNormalizedAmount(calculatedCommission);
      payoutAmount = currentBidAmount - commissionAmount;
      payoutAmount = payoutAmount < 0 ? 0 : getNormalizedAmount(payoutAmount);
    }

    change('commissionAmount', commissionAmount);
    change('payoutAmount', payoutAmount);
  };

  throttleChangeBidAmount = throttle(this.handleChangeBidAmount, 500, { leading: false });

  toggleIsSafeDeal = value => {
    this.setState({ isSafeDeal: value }, () => {
      this.handleChangeBidAmount();
    });
  };

  handleClose = () => {
    this.setState({
      isSafeDeal: this.props.isSafeDeal,
    });
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  handleMakeBid = data => {
    const { onMakeBid, onRef } = this.props;
    onMakeBid(data).then(() => {
      this.handleClose();
      onRef.current.toggleModal();
    }).catch(noop);
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      pristine,
      bidRates,
      currentBidAmount,
      contractDetailTypes,
      competitionTerms,
      isTaxSystemError,
      isPirsOwnerVacancy,
      selectedContractDetailType,
      isSelfContractDetails,
      ...props
    } = this.props;
    const { blockDirectPayments, isSafeDeal } = this.state;
    const isBidAmountLessMinCommission = isSafeDeal && currentBidAmount < bidRates[0]?.minCommission;
    let isCommissionDisplayed = true;

    if (isSelfContractDetails && !this.state.taxSystemName === TAX_SYSTEMS.ndfl.name || this.state.isWarningDisplayed) {
      isCommissionDisplayed = false;
    }

    return <Modal {...props} className="make-bid-modal" title="Сделать ставку">
      <Form onSubmit={handleSubmit(this.handleMakeBid)}>
        <Field
          name="bidAmount"
          component={FormGroupField}
          type="number"
          label="Введите вашу ставку"
          placeholder="Ввести значение"
          onChange={!isPirsOwnerVacancy || (isPirsOwnerVacancy && selectedContractDetailType) ? this.throttleChangeBidAmount : null}
        />
        <Field
          name="isSafeDeal"
          component={FormGroupField}
          labelLeft="Оплата напрямую"
          labelRight="Оплата через гарантийный счет"
          type="switch"
          isDisabled={blockDirectPayments}
          onChange={this.toggleIsSafeDeal}
        />
        {isCommissionDisplayed && <Field
          name="commissionAmount"
          component={FormGroupField}
          type="number"
          label={<>{this.state.taxSystemName === TAX_SYSTEMS.ndfl.name
            ? 'НДФЛ'
            : 'Комиссия'} {getSecureDealCommissionHints(bidRates, isPirsOwnerVacancy)}:</>}
          className="inline-group"
          isReadonly
        />}
        {!this.state.isWarningDisplayed && <Field
          name="payoutAmount"
          component={FormGroupField}
          type="number"
          label="Сумма выплаты:"
          className="inline-group"
          isReadonly
        />}
        <Field
          name="contractDetailType"
          component={FormGroupField}
          type="select"
          label="Используемые реквизиты для договора"
          data={contractDetailTypes}
          onChange={this.throttleChangeBidAmount}
        />
        <div className="errors">
          {isTaxSystemError && <div className={classNames('tax-error', { indent: competitionTerms.isRatingValid })}>
            Система налогообложения не соответствуют условиям конкурса
          </div>}
          {!competitionTerms.isRatingValid && <div className={classNames('rating-error', { indent: isTaxSystemError })}>
            <p>Требуемый рейтинг:</p><Rating value={competitionTerms.minRating} />
          </div>}
        </div>
        {this.state.isWarningDisplayed && <span className='modal-waning'>Для продолжения необходимо указать систему налогообложения.&nbsp;
          <Link to={{ pathname: getPath(ROUTES.financeContractRequisites) }} className="link-orange">
            Перейти к заполнению реквизитов
          </Link>
        </span>}
        {isPirsOwnerVacancy &&
        <span className="offer-link">Осуществляя ставку, Вы соглашаетесь с <a
          className="link"
          href={process.env.REACT_APP_PUBLIC_OFFER_GK_PIRS_CUSTOMER_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          офертой
        </a>
        </span>}
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={
              submitting ||
              isBidAmountLessMinCommission ||
              isTaxSystemError ||
              !competitionTerms.isRatingValid ||
              this.state.isWarningDisplayed
            }
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Принять
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    onMakeBid: PropTypes.func.isRequired,
    bidRates: PropTypes.array.isRequired,
    change: PropTypes.func,
    onRef: PropTypes.object,
    isSafeDeal: PropTypes.bool,
    currentBidAmount: PropTypes.number,
    contractDetailTypes: PropTypes.array,
    competitionTerms: PropTypes.object.isRequired,
    isTaxSystemError: PropTypes.bool,
    isPirsOwnerVacancy: PropTypes.bool.isRequired,
    isSelfContractDetails: PropTypes.bool,
    selectedContractDetailType: PropTypes.string,
  };

  static defaultProps = {
    change() {},
    onRef: {
      current: null,
    },
    bidRates: [],
    isSafeDeal: true,
    currentBidAmount: 0,
    contractDetailTypes: [],
    isTaxSystemError: false,
    isSelfContractDetails: null,
    selectedContractDetailType: null,
  };
}
