import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DataGroup from '../../../base/dataGroup';
import { getFormattedDate } from '../../../../utils/date';
import Rating from '../../../base/rating';
import Section from '../../../base/section';

export default class ContractInfo extends PureComponent {
  renderContract = ({
    objectName,
    deadline,
    objectType,
    region,
    stageName,
    involved,
    status,
    evaluation,
  }) => {
    const { isCurrent } = this.props;

    return <div className={classNames('contract', isCurrent ? 'underway' : 'completed')}>
      <Section>
        <DataGroup
          title={isCurrent ? 'Срок сдачи' : 'Статус'}
          id="deadline"
        >
          {isCurrent ? getFormattedDate(deadline) : <span className={status.kind}>{status.name}</span>}
        </DataGroup>
        {involved && <DataGroup title="Задействован в разделах" id="involved">{involved}</DataGroup>}
        <DataGroup title="Стадия" id="stage">{stageName?.replace('Стадия ', '')}</DataGroup>
      </Section>
      <Section>
        <DataGroup title="Наименование объекта" id="name">{objectName}</DataGroup>
      </Section>
      <Section>
        <DataGroup title="Тип" id="type">{objectType}</DataGroup>
        <DataGroup title="Регион работы" id="region">{region}</DataGroup>
        {!isCurrent && <DataGroup
          id="rating"
          title="Оценка"
          tooltipText={evaluation.review}
          showAlways={!!evaluation.review}
        >
          <Rating value={evaluation.value} />
        </DataGroup>}
      </Section>
    </div>;
  };

  render() {
    const { data, style } = this.props;

    return <div style={style} className="contracts-list-item">
      {this.renderContract(data)}
    </div>;
  }

  static propTypes = {
    isCurrent: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  }
}
