import difference from 'lodash/difference';

export const getGraphqlUserSpecialties = ({ userSpecialties, id }) => userSpecialties
  ? userSpecialties.map(specialtyId => ({
    specialtyId,
    userId: id,
  }))
  : [];

export const getUserSpecialtiesToDelete = ({ initialUserSpecialties, userSpecialties }) =>
  initialUserSpecialties ? difference(initialUserSpecialties, userSpecialties) : [];
