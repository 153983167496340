import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactList from 'react-list';
import AutoSizer from 'react-virtualized-auto-sizer';
import throttle from 'lodash/throttle';

import Loading from '../loading';
import NETWORK_STATUS from '../../../utils/networkStatus';

import './index.scss';

export const SCROLL_TIME_DELAY = 200;

export default class InfiniteList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isItemExpanded: false,
      expandedItemIndex: null,
    };
    this.props.onRef.current = this;
    this.listContainerRef = React.createRef();
    this.throttledHandleScroll = () => {};
  }

  componentDidMount() {
    this.throttledHandleScroll = throttle(this.handleScroll, SCROLL_TIME_DELAY, { leading: false });
  }

  componentWillUnmount() {
    this.throttledHandleScroll.cancel();
  }

  handleScroll = () => {
    const { count, items, onLoadMore } = this.props;

    if (this.listContainerRef.current && count > items.length) {
      const { scrollTop, scrollHeight, clientHeight } = this.listContainerRef.current;

      if (scrollHeight - scrollTop - clientHeight < clientHeight) {
        onLoadMore();
      }
    }
  };

  resetState = () => this.setState({
    isItemExpanded: false,
    expandedItemIndex: null,
  });

  setItemState = index => isExpanded => {
    this.setState({ isItemExpanded: isExpanded, expandedItemIndex: index });
  };

  renderListItem = (index, key, isExpanded = false) => {
    const { itemComponent: Item, ...props } = this.props;
    return <Item
      key={key}
      setItemState={this.setItemState(index)}
      data={this.props.items[index]}
      isExpanded={isExpanded}
      afterRemove={this.resetState}
      {...props}
    />;
  };

  renderList() {
    const { count, isSearchApplied, items, emptyMessage } = this.props;
    const { isItemExpanded, expandedItemIndex } = this.state;

    if (count === 0) {
      const message = isSearchApplied ? 'Ничего не найдено.' : emptyMessage;

      return <div className="empty-list-message">{message}</div>;
    } else if (items.length === 0) {
      return <Loading />;
    }

    return <AutoSizer style={{ height: isItemExpanded ? 'auto' : 0 }}>
      {({ width, height }) =>
        <>
          <div
            style={{ overflow: 'auto', width, height }}
            className={classNames({ hidden: isItemExpanded })}
            ref={this.listContainerRef}
            onScroll={this.throttledHandleScroll}
          >
            <ReactList
              itemRenderer={this.renderListItem}
              pageSize={5}
              length={items.length}
            />
            {count !== items.length && <Loading className="load-more" />}
          </div>
          {isItemExpanded && <div style={{ width, height: '100%' }}>
            {this.renderListItem(expandedItemIndex === items.length ? expandedItemIndex - 1 : expandedItemIndex, 0, true)}
          </div>}
        </>}
    </AutoSizer>;
  }

  render() {
    const { isLoading, className, networkStatus } = this.props;
    const showLoading = isLoading || networkStatus === NETWORK_STATUS.setVariables;

    return <div className={classNames('list-layout', className)}>
      {showLoading ? <Loading /> : this.renderList()}
    </div>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    items: PropTypes.array.isRequired,
    itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    count: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    isSearchApplied: PropTypes.bool,
    onLoadMore: PropTypes.func,
    emptyMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    className: PropTypes.string,
    networkStatus: PropTypes.number,
  };

  static defaultProps = {
    onRef: {
      current: {},
    },
    items: [],
    isLoading: false,
    isSearchApplied: false,
    onLoadMore() {},
    emptyMessage: 'Список пуст',
    className: '',
    networkStatus: 0,
  };
}
