import { connect } from 'react-redux';
import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';
import { withRouter, matchPath } from 'react-router-dom';

import Ratings from './index';
import { ROUTES } from '../../../utils/routes';
import { VACANCY_TYPE } from '../../../utils/vacancyType';
import { formatRatings, getRatingsQuery } from '../../../graphql/model/rating';
import AuthorizedApi from '../../../api/authorized';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import withUserAggregationReferencesQuery from '../../../graphql/hoc/userAggregationReferences';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const GET_PERFORMER_RATING_GROUPS_QUERY = loader('../../../graphql/queries/rating/getPerformerRatingGroup.graphql');
const ON_RATING_LIST_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/rating/listSubscription.graphql');
const ON_RATING_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/rating/countSubscription.graphql');

// TODO: determine this id from database
export const PERFORMER_ROLE_ID = 3;
const ORDER_BY = {
  rating: {
    title: 'Рейтинг',
    graphql: { position: 'asc' },
    reverseIcon: true,
    default: true,
  },
  experience: {
    title: 'Стаж',
    graphql: { user: { createdAt: 'asc' } },
    reverseIcon: true,
  },
  objectCount: {
    title: 'Кол-во выполненных работ',
    graphql: { user: { userAggregation: { doneWorksCount: 'asc' } } },
  },
};

const RATING_ORDER = {
  1: VACANCY_TYPE.chief,
  2: VACANCY_TYPE.lead,
  3: VACANCY_TYPE.performer,
  4: VACANCY_TYPE.customer,
};

const mapStateToProps = ({ session: { user } }) => ({
  userId: user.id,
});

const mergeProps = withProps(({ location }) => {
  const userRoleId = getUrlUserRoleId(location.pathname);

  return {
    userRoleId,
    currentRatingOrder: RATING_ORDER[userRoleId],
    where: getRatingsQuery(userRoleId, +(userRoleId === PERFORMER_ROLE_ID)),
    addToFavorites: email => AuthorizedApi.addFavorite(email, getUrlUserRoleId(location.pathname) === PERFORMER_ROLE_ID)
      .catch(error => {
        showError(error, UI_DEFAULT_ERROR_MESSAGES.addToFavorites);
        throw error;
      }),
  };
});

const getUrlUserRoleId = pathname => parseInt(matchPath(pathname, ROUTES.roleRatings)?.params.userRoleId, 10);

const withGetPerformerRatingGroups = graphql(GET_PERFORMER_RATING_GROUPS_QUERY, {
  name: 'getPerformerRatingGroups',
  skip: ({ userRoleId }) => userRoleId !== PERFORMER_ROLE_ID,
  options: () => ({
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getPerformerRatingGroups: { items = [] } }) => ({ performerRatingGroups: items }),
});

const withRatingListSub = withResubscribe(ON_RATING_LIST_UPDATE_SUBSCRIPTION, {
  name: 'ratingListSub',
  skip: ({ userRoleId, performerRatingGroups, userId }) => userRoleId === PERFORMER_ROLE_ID && performerRatingGroups.length === 0 || !userId,
  options: ({ where, offset, limit, order }) => ({
    variables: {
      where,
      offset,
      limit,
      order,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ ratingListSub: { items = [], count, loading: isLoading, variables }, ownProps: { userAggregation } }, previousResult) => ({
    // we return previous messages if resubscribe was started (has 0 messages on loading moment)
    ratings: isLoading && previousResult?.ratings.length > 0 ? previousResult.ratings : formatRatings(items, userAggregation),
    currentPerformerGroupId: parseInt(variables.where._and[1].performerRatingGroupId._eq, 10),
    isLoading,
  }),
});

const withRatingListCountSubscription = withResubscribe(ON_RATING_COUNT_UPDATE_SUBSCRIPTION, {
  name: 'ratingListCountSub',
  skip: ({ userRoleId, performerRatingGroups, userId }) => userRoleId === PERFORMER_ROLE_ID && performerRatingGroups.length === 0 || !userId,
  options: ({ where }) => ({
    variables: {
      where,
    },
  }),
  props: ({ ratingListCountSub: { count, loading: isCountLoading }, ownProps: { isLoading } }) => ({
    count: count ? count.aggregate.count : 0,
    isLoading: isLoading && isCountLoading,
  }),
});

export default compose(
  defaultProps({
    offset: 0,
    limit: 10,
    orderBy: ORDER_BY,
  }),
  withRouter,
  connect(mapStateToProps),
  mergeProps,
  withUserAggregationReferencesQuery,
  withGetPerformerRatingGroups,
  withStateHandlers(
    ({ limit, orderBy, where }) => ({ step: limit, order: getOrder(orderBy), where }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      search: (_, { where, userRoleId }) => value => ({
        where: value ? getRatingsQuery(userRoleId, null, `%${value}%`) : where,
      }),
      group: (_, { where, userRoleId }) => value => ({
        where: value ? getRatingsQuery(userRoleId, value) : where,
      }),
    },
  ),
  withRatingListSub,
  withRatingListCountSubscription,
)(Ratings);
