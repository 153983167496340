import Base from './base';

const BASE_URL = process.env.REACT_APP_SERVER_API_PATH;

class AuthorizedApi extends Base {
  constructor() {
    super(BASE_URL);
  }

  claim = () => this.get('/user/claim').then(response => response.data);

  uploadFile = async (files, path) => {
    if (files.cropResult) {
      const { name, type, cropResult } = files;
      files = await fetch(cropResult)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], name, { type }));
    }

    const data = new FormData();
    let filesArray;

    if (files.length > 0) {
      filesArray = Array.from(files);
      for (let i = 0; i < filesArray.length; i++) {
        data.append('files', filesArray[i], filesArray[i].name);
      }
    } else {
      filesArray = [files];
      data.append('files', filesArray[0], filesArray[0].name);
    }

    return this.post('/storage/upload', {
      data,
      headers: {
        'x-path': path,
      },
    })
      .then(response => response.data)
      .then(uploadedFiles => uploadedFiles.length > 1 ? uploadedFiles : uploadedFiles[0]);
  };

  checkFavoriteUser = (email, isPerformer) =>
    this.post('/checkFavoriteUser', { data: { email, isPerformer } })
      .then(response => response.data);

  addFavorite = (email, isPerformer) =>
    this.post('/favorite', { data: { email, isPerformer } }).then(response => response.data);

  updateFavorite = (id, token) => this.post(`/favorite/${id}`, { data: { token } }).then(response => response.data);

  changePassword = (userId, currentPassword, newPassword) =>
    this.post(`/user/${userId}/changePassword`, { data: { password: currentPassword, newPassword } })
      .then(response => response.data);

  confirmEmail = email =>
    this.post('/user/askEmailConfirmation', { data: { email } })
      .then(response => response.data);
}

export default new AuthorizedApi();
