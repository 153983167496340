import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import FormGroupField from '../../../base/formGroup';
import RestrictedInformation from '../../restrictedInformation';
import DataList from '../../../base/dataList';

import './index.scss';
import { VACANCY_TYPE } from '../../../../utils/vacancyType';

export default class Specialization extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      autoObjectTypes: [],
    };

    this.title = 'Специализация';
  }

  setObjectTypes(objectTypes) {
    this.setState({ autoObjectTypes: objectTypes });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedProjectComponents, projectComponents, objectTypes, change } = this.props;
    const {
      selectedProjectComponents: prevSelectedProjectComponents,
    } = prevProps;

    if (!isEqual(selectedProjectComponents, prevSelectedProjectComponents)) {
      const updatedObjectTypes = objectTypes.map(objectType => {
        const isChecked = selectedProjectComponents.some(selectedProjectComponent => {
          const filteredObjectType = projectComponents.find(projectComponent => projectComponent.value === objectType.value);
          return filteredObjectType.children.some(stage =>
            stage.children.some(projectComponent => projectComponent.value === selectedProjectComponent),
          );
        });
        return { ...objectType, checked: isChecked };
      });
      this.setObjectTypes(updatedObjectTypes);
      change('userObjectTypes', updatedObjectTypes.filter(objectType => objectType.checked).map(objectType => objectType.value));
    }
  }

  getFieldProps = props => this.props.isReadonly ? {} : props;

  getIsDisplayedForRole = (roles, isExcluded) => {
    const result = roles.includes(this.props.selectedRole?.name);
    return isExcluded ? !result : result;
  }

  isFieldHidden = field => this.props.hiddenFields.indexOf(field) > -1;

  onChangeRole = () => this.props.change('userProjectComponents', []);

  renderContent() {
    const {
      isReadonly,
      specialties,
      projectComponents,
      userSpecialties,
      userProjectComponents,
      userRoles,
      userChiefEngineerExpertiseExperience,
      selectedRole,
      selectedProjectComponents,
      chiefEngineerExpertiseExperience,
    } = this.props;
    const { autoObjectTypes } = this.state;
    const selectFieldType = isReadonly ? 'text' : 'select';
    const isPerformer = selectedRole.name === VACANCY_TYPE.performer;
    const isCustomer = selectedRole.name === VACANCY_TYPE.customer;
    const isUserProjectComponentsExist = userProjectComponents.length > 0;

    return <>
      <Field
        name="role"
        component={FormGroupField}
        className={classNames({ hidden: this.isFieldHidden('role') })}
        type={selectFieldType}
        label="Род деятельности"
        data={userRoles}
        isReadonly={isReadonly}
        onChange={this.onChangeRole}
        {...this.getFieldProps({ placeholder: 'Выбрать род деятельности' })}
      />
      {isReadonly
        ? <>
          {isUserProjectComponentsExist && <DataList
            data={userProjectComponents}
            label="Курируемые разделы"
          />}
          {userChiefEngineerExpertiseExperience.length > 0 && <DataList
            data={userChiefEngineerExpertiseExperience.map(({ chiefEngineerExpertiseExperience: { name } }) => name)}
            label="Опыт защиты ПСД в роли ГИПа"
          />}
          {(userSpecialties.length > 0) && <DataList
            data={userSpecialties}
            label="Области компетенции"
          />}

        </>
        : <>
          {this.getIsDisplayedForRole([VACANCY_TYPE.lead, VACANCY_TYPE.performer]) && <Field
            name="userProjectComponents"
            component={FormGroupField}
            className={classNames({ hidden: this.isFieldHidden('userProjectComponents') })}
            type={selectFieldType}
            label={`${isPerformer ? 'Разрабатываемые' : 'Курируемые'} разделы`}
            data={projectComponents}
            isReadonly={isReadonly}
            {...this.getFieldProps({ placeholder: 'Выбрать разделы', isMultiSelect: true })}
          />}
        </>}
      <>
        {(isReadonly && isUserProjectComponentsExist || selectedRole?.id && selectedProjectComponents.length > 0) && <Field
          name="userObjectTypes"
          component={FormGroupField}
          type={selectFieldType}
          label="Типы объектов"
          data={autoObjectTypes}
          isReadonly={isReadonly}
          {...this.getFieldProps({
            placeholder: 'Формируется автоматически согласно выбору выше',
            isMultiSelect: true,
            isDisabled: true,
          })}
        />}
        {selectedRole?.id && this.getIsDisplayedForRole([VACANCY_TYPE.chief]) && <Field
          name="userChiefEngineerExpertiseExperience"
          component={FormGroupField}
          type={selectFieldType}
          label="Опыт защиты ПСД в роли ГИПа"
          data={chiefEngineerExpertiseExperience}
          isReadonly={isReadonly}
          {...this.getFieldProps({ placeholder: 'Выбрать опыт защиты ПСД в роли ГИПа', isMultiSelect: true })}
        />}
        {selectedRole?.id && this.getIsDisplayedForRole([VACANCY_TYPE.customer], true) && <Field
          name="userSpecialties"
          component={FormGroupField}
          type={selectFieldType}
          label="Области компетенции"
          data={specialties}
          isReadonly={isReadonly}
          {...this.getFieldProps({ placeholder: 'Выбрать области компетенции', isMultiSelect: true })}
        />}
      </>
      <Field
        name="comment"
        component={FormGroupField}
        className={classNames({ hidden: this.isFieldHidden('comment') })}
        type="textarea"
        isReadonly={isReadonly}
        label={`Комментарий${isReadonly
          ? ''
          : ` (по желанию${selectedRole?.id && !isCustomer ? ' сообщение потенциальному заказчику' : ''})`
        }`}
      />
    </>;
  }

  render() {
    const { isReadonly, isPrivate } = this.props;

    if (isPrivate) {
      return <RestrictedInformation title={this.title} />;
    }

    return <div className={classNames('specialization-layout form-card', { readonly: isReadonly })}>
      <h5>{this.title}</h5>
      {this.renderContent()}
    </div>;
  }

  static propTypes = {
    specialties: PropTypes.array,
    objectTypes: PropTypes.array,
    projectComponents: PropTypes.array,
    selectedProjectComponents: PropTypes.array,
    selectedRole: PropTypes.object,
    isReadonly: PropTypes.bool,
    isPrivate: PropTypes.bool,
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
    userSpecialties: PropTypes.array,
    userProjectComponents: PropTypes.array,
    userRoles: PropTypes.array,
    userChiefEngineerExpertiseExperience: PropTypes.array,
    chiefEngineerExpertiseExperience: PropTypes.array,
    userObjectTypes: PropTypes.string,
    change: PropTypes.func,
  };

  static defaultProps = {
    selectedProjectComponents: [],
    specialties: [],
    objectTypes: [],
    projectComponents: [],
    change() {},
    isReadonly: false,
    isPrivate: false,
    hiddenFields: [],
    userSpecialties: [],
    userProjectComponents: [],
    userRoles: [],
    chiefEngineerExpertiseExperience: [],
    userChiefEngineerExpertiseExperience: [],
    userObjectTypes: '',
    selectedRole: {},
  }
}
