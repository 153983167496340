import moment from 'moment';

export const getNotifications = notifications => {
  const unread = [];
  const read = (notifications || []).filter(notification => {
    notification.formattedDate = moment(moment(notification.createdAt)).calendar(null, {
      sameDay: '[Сегодня] H:mm',
      lastDay: '[Вчера] H:mm',
      lastWeek: 'D MMMM H:mm',
      sameElse: 'D MMMM H:mm',
    });

    if (!notification.wasRead) {
      unread.push(notification);
    }

    return notification.wasRead;
  });

  return {
    read,
    unread,
  };
};
