import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import { getFormattedDate } from '../../../utils/date';
import { getNameWithInitials } from '../../../utils/name';
import Container from '../../base/container';
import DataGroup from '../../base/dataGroup';
import Button from '../../base/button';
import FileLink from '../../base/fileLink';
import ProfileLink from '../../base/profileLink';
import Section from '../../base/section';
import { ROUTES } from '../../../utils/routes';
import { ICONS } from '../../../utils/icons';
import ProjectComponentLink from '../../base/projectComponentLink';

const REASSIGNMENT_TEXT = 'Задача была перепоручена';

export default class Item extends React.PureComponent {
  state = {
    isTaskDeleted: false,
  }

  containerRef = React.createRef();

  setTaskDeleted = () => {
    this.setState({ isTaskDeleted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, items, onClickReadTaskMessage } = this.props;
    const messageData = data.authorLastMessage;
    const prevMessageData = prevProps.data.authorLastMessage;

    if (prevProps.isExpanded && prevProps.items.length !== items.length) {
      const deletedTaskIds = prevProps.items
        .filter(({ id: id1 }) => !items.some(({ id: id2 }) => id2 === id1))
        .map(({ id }) => id);

      if (deletedTaskIds.includes(prevProps.data.id)) {
        this.setTaskDeleted();
      } else {
        this.containerRef.current.handleExpandToggle(false);
      }
    }

    if (prevProps.isExpanded && prevMessageData.readAt !== messageData.readAt &&
      !messageData.readAt && data.isAssignedUser) {
      onClickReadTaskMessage(messageData.id);
    }
  }

  handleEditMessage = () => {
    const { data, onClickTaskAction } = this.props;

    onClickTaskAction(data, true, true);
  };

  handleEditTask = () => {
    const { data, onClickTaskAction } = this.props;

    onClickTaskAction(data, false, true);
  };

  handleClickAnswer = () => {
    const { data, onClickTaskAction, isIncoming } = this.props;

    onClickTaskAction({ ...data, isIncoming }, false, false, true);
  };

  handleClickReassign = () => {
    const { data, onClickTaskAction } = this.props;

    onClickTaskAction(data, false, false, false, true);
  };

  handleClickComplete = () => {
    const { data, onClickCloseTaskModal } = this.props;

    onClickCloseTaskModal(data);
  };

  handleClickRemove = () => {
    const { data, onClickRemoveTaskModal } = this.props;

    onClickRemoveTaskModal(data);
  };

  handleExpandedState = isExpanded => {
    const { data, onClickReadTaskMessage, setItemState } = this.props;
    const messageData = data.authorLastMessage;

    if (!messageData.readAt && data.isAssignedUser) {
      onClickReadTaskMessage(messageData.id);
    }

    setItemState(isExpanded);
  };

  renderTaskStatus = () => {
    const { data: { updatedAt, lastMessage, assignedUserId, authorLastMessage, isClosed } } = this.props;
    let taskStatusDate = authorLastMessage.readAt;
    let taskStatus = taskStatusDate ? 'Прочитана' : 'Не прочитана';

    if (taskStatusDate && isClosed) {
      taskStatus = 'Решена';
      taskStatusDate = updatedAt;
    } else if (taskStatusDate && lastMessage && assignedUserId === lastMessage.userId && !lastMessage.isReassignment) {
      taskStatus = 'Отвечена';
      taskStatusDate = lastMessage?.createdAt;
    }

    return <DataGroup className="task-status">
      {taskStatus}{taskStatusDate ? `: ${new Date(taskStatusDate).toLocaleDateString('ru-RU')}` : ''}
    </DataGroup>;
  };

  renderDeadline = (deadline, isExpired) => {
    return deadline
      ? `${getFormattedDate(deadline, false, false)} ${isExpired ? 'просрочено' : ''}`
      : null;
  };

  renderHeader = ({ createdAt, deadline, author, assignedUser, taskMessage, messages, isClosed }, isExpired) => {
    const { isAuthor } = taskMessage;
    const user = isAuthor ? assignedUser : author;

    return <div className="task-header">
      <DataGroup title="Дата создания" className="created">{getFormattedDate(createdAt)}</DataGroup>
      <DataGroup title={isAuthor ? 'Кому' : 'От кого'} className="user">
        <ProfileLink userId={user.id} text={getNameWithInitials(user)} />
      </DataGroup>
      <DataGroup
        title="Срок исполнения"
        className={classNames('deadline', { expired: isExpired })}
      >
        {this.renderDeadline(deadline, isExpired)}
      </DataGroup>
      {isAuthor && messages.length === 0 && !isClosed && <Button
        IconComponent={ICONS.pencil}
        onClick={this.handleEditTask}
        isBorderless
      />}
    </div>;
  };

  renderPreview = ({ taskMessage, id }) => <div className="task-preview">
    <DataGroup title={`Задача №${id}`} isLineBreakAllowed>{taskMessage.message}</DataGroup>
    {this.renderTaskStatus()}
  </div>;

  getResponseButton = (isIncoming, isAssignedUser, messages, isReassignment, isAuthor) => {
    let buttonCaption = null;
    if (isIncoming && isAssignedUser) {
      buttonCaption = 'Ответить';
    } else if (messages.length > 0) {
      buttonCaption = (isAuthor || !isReassignment) && 'Отправить на доработку';
    }

    return !!buttonCaption && <Button id="answerTaskButton" onClick={this.handleClickAnswer}>{buttonCaption}</Button>;
  };

  renderFooter = (
    {
      taskMessage: {
        isAuthor,
      },
      isAssignedUser,
      isClosed,
      messages,
      lastMessage,
      dataRequest,
    },
    isIncoming,
  ) => (!isClosed || isAuthor) && <Section className="footer">
    {!isClosed && <div>
      {this.getResponseButton(isIncoming, isAssignedUser, messages, lastMessage?.isReassignment, isAuthor)}
      {isIncoming && isAssignedUser && <Button id="reassignTaskButton" onClick={this.handleClickReassign}>Перепоручить</Button>}
      {isAuthor && <Button id="closeTaskButton" onClick={this.handleClickComplete}>Завершить задачу</Button>}
    </div>}
    <div>
      {this.renderTaskStatus()}
      {isAuthor && !dataRequest && <Button
        id="deleteTaskButton"
        IconComponent={ICONS.trash}
        isBorderless
        onClick={this.handleClickRemove}
      />}
    </div>
  </Section>;

  renderChild = data => {
    const { onClickCopyTaskFile } = this.props;
    const { description, code, taskMessage, messages, relatedTo, assignedUser } = data;
    const { message: task, files, isAuthor } = taskMessage;
    const { placeholder, objectId, stageId, projectComponentId } = relatedTo;

    return <>
      {!isAuthor && <Section>
        <DataGroup title='Ответственный' className="assigned-user">
          <ProfileLink userId={assignedUser.id} text={getNameWithInitials(assignedUser)} />
        </DataGroup>
      </Section>}
      <Section className="object-info">
        <DataGroup title="Объект" isLineBreakAllowed>{description}</DataGroup>
        <DataGroup title="Шифр объекта" isLineBreakAllowed>{code}</DataGroup>
      </Section>
      <Section>
        <DataGroup title="Задача" isLineBreakAllowed>{task}</DataGroup>
        <DataGroup title="Вложения">
          {files.length ? files.map(({ file }, index) => <div key={index} className="pair-fields">
            <FileLink file={file} showTitle />
            {isAuthor && <Button
              id="copyFileButton"
              IconComponent={ICONS.copy}
              onClick={() => onClickCopyTaskFile(file.id)}
              isBorderless
            />}
          </div>) : 'Отсутствует'}
        </DataGroup>
        <DataGroup title="Прикреплено" isLineBreakAllowed>
          <ProjectComponentLink
            name="projectComponentLink"
            text={placeholder}
            objectId={objectId}
            stageId={stageId}
            projectComponentId={projectComponentId}
          />
        </DataGroup>
      </Section>
      {messages.map(({ message, files, createdAt, isAuthor: isMessageAuthor, user, isReassignment }, messageIndex) => <Section key={messageIndex}>
        <DataGroup title="Автор" className="message-author">
          <ProfileLink userId={user.id} text={getNameWithInitials(user)} />
        </DataGroup>
        <div className="edit-section">
          <DataGroup title="Ответ" isLineBreakAllowed>{isReassignment ? `${REASSIGNMENT_TEXT}: "${message}"` : `${message}`}</DataGroup>
          {isMessageAuthor && messageIndex === messages.length - 1 && <Button
            id="editAnswerTaskButton"
            IconComponent={ICONS.pencil}
            onClick={this.handleEditMessage}
            isBorderless
          />}
        </div>
        <DataGroup title="Вложения">
          {files.length
            ? files.map(({ file }, fileIndex) => <FileLink
              key={fileIndex}
              file={file}
              showTitle
              {...(isAuthor || isMessageAuthor ? { onCopyFile: onClickCopyTaskFile } : {})}
            />)
            : 'Отсутствует'}
        </DataGroup>
        <DataGroup title="Дата ответа">{getFormattedDate(createdAt)}</DataGroup>
      </Section>)}
      {this.renderFooter(data, this.props.isIncoming)}
    </>;
  };

  render() {
    const { data, style, isExpanded } = this.props;
    const { isTaskDeleted } = this.state;
    const isExpired = new Date(data.deadline).getTime() < Date.now();
    const isUnread = !data.authorLastMessage.readAt && data.isAssignedUser && !isExpanded;

    if (isTaskDeleted) {
      return <Redirect to={ROUTES.notFound} />;
    }

    return <div style={style} className="tasks-list-item-container">
      <Container
        ref={this.containerRef}
        className={classNames('task-item', { expired: isExpired, unread: isUnread })}
        isDefaultExpanded={isExpanded}
        isExpansionDisabled
        shareExpandedState={this.handleExpandedState}
        headerContent={this.renderHeader(data, isExpired)}
        previewContent={this.renderPreview(data)}
      >
        {this.renderChild(data)}
      </Container>
    </div>;
  }

  static propTypes = {
    data: PropTypes.object,
    items: PropTypes.array,
    style: PropTypes.object,
    isExpanded: PropTypes.bool,
    isIncoming: PropTypes.bool.isRequired,
    setItemState: PropTypes.func,
    onClickTaskAction: PropTypes.func.isRequired,
    onClickReadTaskMessage: PropTypes.func.isRequired,
    onClickCloseTaskModal: PropTypes.func.isRequired,
    onClickCopyTaskFile: PropTypes.func.isRequired,
    onClickRemoveTaskModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
    items: [],
    style: {},
    setItemState() {},
    isExpanded: false,
  };
}
