import React from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import Input from '../input';

export default class MultilineDatePicker extends React.PureComponent {
  render() {
    const { onIconClick, onCustomBlur, ...props } = this.props;
    return <Input
      {...props}
      className="date-picker"
      onClick={noop}
      onFocus={noop}
      onIconClick={onIconClick}
      onBlur={onCustomBlur}
    />;
  }

  static propTypes = {
    onIconClick: PropTypes.func,
    onCustomBlur: PropTypes.func,
  };

  static defaultProps = {
    onIconClick() {},
    onCustomBlur() {},
  };
}
