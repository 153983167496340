import { formValueSelector, reduxForm } from 'redux-form';
import { compose, withProps, branch, setPropTypes, defaultProps } from 'recompose';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

import CopyFileModal from './index';
import { getSubmitValidation, getValidation } from '../../../utils/validation';
import { showError } from '../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import orderBy from 'lodash/orderBy';
import { getChildren, OBJECT_PROJECT_COMPONENT_ORDER } from '../../../graphql/model/projectComponent';
import { formatProjectComponentsForSelect } from '../../../utils/projectComponent';
import { OBJECT_FILE_TYPES } from '../../../utils/object';
import { getObjectListByUserQuery } from '../../../graphql/model/object';
import { STAGE_FILE_TYPES } from '../../../utils/stage';

const GET_OBJECTS_LIST_BY_USER_QUERY = loader('../../../graphql/queries/object/getListByUser.graphql');
const INSERT_OBJECT_FILES_MUTATION = loader('../../../graphql/queries/object/insertFiles.graphql');
const INSERT_PROJECT_COMPONENT_FILES_MUTATION = loader('../../../graphql/queries/object/insertProjectComponentFiles.graphql');

const FORM_NAME = 'CopyVersionFilesForm';
const formSelector = formValueSelector(FORM_NAME);

const withInsertObjectFilesMutation = graphql(INSERT_OBJECT_FILES_MUTATION, {
  name: 'insertObjectFiles',
});

const withInsertProjectComponentFilesMutation = graphql(INSERT_PROJECT_COMPONENT_FILES_MUTATION, {
  name: 'insertProjectComponentFiles',
});

const filterFilesByType = (files, type, fileId) => files.every(file => file.fileId !== fileId || !type || file.type !== type);

const withGetObjectsListByUserQuery = graphql(GET_OBJECTS_LIST_BY_USER_QUERY, {
  name: 'getListByUser',
  skip: ({ userId, fileId }) => !userId || !fileId,
  options: ({ userId }) => ({
    variables: {
      userId,
      where: getObjectListByUserQuery(userId),
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'network-only',
  }),
  props: ({ getListByUser, ownProps }) => {
    const { items = [], error, loading: isLoading } = getListByUser;
    const {
      selectedObjectId,
      selectedStageId,
      selectedProjectComponentId,
      selectedType,
      fileId,
      type,
    } = ownProps;

    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    const objects = orderBy(
      items
        .map(({ name, code, id }) => ({ label: `${name} ${code}`, value: id, checked: selectedObjectId === id })),
      ['label'],
    );
    let selectedObject = {};
    let selectedProjectComponent = {};
    let stages = [];
    let projectComponents = [];
    let files = [];
    const types = Object.values(OBJECT_FILE_TYPES);

    if (selectedObjectId) {
      selectedObject = items.find(({ id }) => selectedObjectId === id);
      stages = selectedObject.projectComponents.filter(({ parentObjectProjectComponentId }) => !parentObjectProjectComponentId);
      files = selectedObject.files;

      if (selectedStageId) {
        const selectedStage = selectedObject.projectComponents.find(({ id }) => selectedStageId === id);

        if (selectedStage) {
          projectComponents = getChildren(
            selectedObject.projectComponents.filter(({ parentObjectProjectComponentId }) => parentObjectProjectComponentId === selectedStage.id),
            selectedObject.projectComponents,
          );
          selectedProjectComponent = projectComponents.find(({ id }) => id === selectedProjectComponentId);

          if (type) {
            projectComponents = projectComponents.filter(({ files }) => filterFilesByType(files, type, fileId));
          }

          if (selectedProjectComponent) {
            files = files.concat(selectedProjectComponent.files);
            types.push({
              type: STAGE_FILE_TYPES.initialData.type,
              title: `${STAGE_FILE_TYPES.initialData.title.placeholder} по ${selectedProjectComponent.type.dativeName}`,
            });
            types.push({
              type: STAGE_FILE_TYPES.projectComponentAgreement.type,
              title: STAGE_FILE_TYPES.projectComponentAgreement.title.placeholder
                .replace('PROJECT_COMPONENT', selectedProjectComponent.type.dativeName),
            });
          }
        }
      }
    }

    return {
      objects,
      stages: formatProjectComponentsForSelect(stages, selectedStageId),
      projectComponents: formatProjectComponentsForSelect(projectComponents, selectedProjectComponentId),
      types: types
        .filter(({ type }) => filterFilesByType(files, type, fileId))
        .map(({ type, title }) => ({
          label: title,
          value: type,
          checked: selectedType === type,
        })),
      isLoading,
    };
  },
});

const mapStateToProps = state => ({
  userId: state.session.user.id,
  selectedObjectId: formSelector(state, 'object'),
  selectedStageId: formSelector(state, 'stage'),
  selectedProjectComponentId: formSelector(state, 'projectComponent'),
  selectedType: formSelector(state, 'type'),
});

const mergeProps = withProps(({
  insertObjectFiles,
  insertProjectComponentFiles,
  fileId,
  type,
  selectedObjectId,
  selectedProjectComponentId,
  selectedType,
}) => ({
  isObjectType: !!OBJECT_FILE_TYPES[selectedType],
  onCopyFile: () => (OBJECT_FILE_TYPES[type || selectedType] ? insertObjectFiles : insertProjectComponentFiles)({
    variables: {
      files: [{
        fileId,
        type: type || selectedType,
        ...(OBJECT_FILE_TYPES[type || selectedType]
          ? { objectId: selectedObjectId }
          : { objectProjectComponentId: selectedProjectComponentId }),
      }],
    },
  }),
}));

export default compose(
  connect(mapStateToProps),
  setPropTypes({
    isVersion: PropTypes.bool,
    type: PropTypes.oneOf([
      ...Object.values(OBJECT_FILE_TYPES),
      ...Object.values(STAGE_FILE_TYPES),
    ].map(({ type }) => type)),
  }),
  defaultProps({
    isVersion: false,
  }),
  branch(
    ({ type }) => !type,
    compose(
      withInsertObjectFilesMutation,
      withInsertProjectComponentFilesMutation,
    ),
    branch(
      ({ type }) => STAGE_FILE_TYPES[type]?.type,
      withInsertProjectComponentFilesMutation,
      withInsertObjectFilesMutation,
    ),
  ),
  withInsertProjectComponentFilesMutation,
  withGetObjectsListByUserQuery,
  mergeProps,
  reduxForm({
    form: FORM_NAME,
    validate: (values, props) => getValidation(props.type
      ? ['object', 'stage', 'projectComponent']
      : ['object', 'type'],
    )(values, props),
    onSubmitFail: getSubmitValidation,
  }),
)(CopyFileModal);
