import { loader } from 'graphql.macro';
import { compose, defaultProps, withProps, withStateHandlers } from 'recompose';
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { UI_DEFAULT_ERROR_MESSAGES } from '../../../utils/messages';
import { showError } from '../../../api/error';
import Contracts from './index';
import { formatContracts, getContractsQuery } from '../../../graphql/model/contracts';
import { ROUTES } from '../../../utils/routes';
import { goTo } from '../../../utils/menu';
import { withResubscribe } from '../../../graphql/utils';
import { getOrder } from '../../../utils/sorting';

const ORDER_BY = {
  deadline: {
    title: 'Дата сдачи работ',
    graphql: { deadline: 'desc' },
    default: true,
  },
  totalAmount: {
    title: 'Сумма',
    graphql: { totalAmount: 'desc' },
  },
};

const ON_CONTRACTS_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contract/listSubscription.graphql');
const ON_CONTRACTS_COUNT_UPDATE_SUBSCRIPTION = loader('../../../graphql/queries/contract/countSubscription.graphql');

const mapStateToProps = ({ session }) => ({
  userId: session.user?.id,
});

export const getContractProfileRoute = (isCurrent, isArbitration, objectId, contractId, inDispute) => {
  let route = '';
  if (isCurrent) {
    route = ROUTES[`current${objectId ? 'Object' : 'Finance'}ContractProfile`];
  } else if (isArbitration || inDispute) {
    route = ROUTES[`arbitration${objectId ? 'Object' : 'Finance'}ContractProfile`];
  } else {
    route = ROUTES[`closed${objectId ? 'Object' : 'Finance'}ContractProfile`];
  }
  return objectId
    ? route.replace(':id', objectId).replace(':contractId', contractId)
    : route.replace(':contractId', contractId);
};

const getIsCurrent = (location, objectId) => {
  return !!matchPath(location.pathname, objectId ? ROUTES.currentObjectContracts : ROUTES.currentFinanceContracts);
};

const getIsArbitration = (location, objectId) => {
  return !!matchPath(location.pathname, objectId ? ROUTES.arbitrationObjectContracts : ROUTES.arbitrationFinanceContracts);
};

const mergeProps = withProps(({ location, userId, objectId }) => {
  const isCurrent = getIsCurrent(location, objectId);
  const isArbitration = getIsArbitration(location, objectId);
  const inDispute = getIsArbitration(location, objectId);

  return {
    isCurrent,
    isArbitration,
    inDispute,
    where: getContractsQuery({
      userId,
      isCurrent,
      isArbitration,
      objectId,
      inDispute: isArbitration,
    }),
    goToContract: contractId => {
      goTo(getContractProfileRoute(isCurrent, isArbitration, objectId, contractId, isArbitration));
    },
  };
});

const withGetContractsQuery = withResubscribe(ON_CONTRACTS_UPDATE_SUBSCRIPTION, {
  name: 'contractsSub',
  skip: ({ userId }) => !userId,
  options: ({ where, offset, limit, order, withTransactions }) => ({
    variables: {
      where,
      offset,
      limit,
      order,
      withTransactions,
    },
  }),
  props: ({ contractsSub: { contracts = [], error, loading: isLoading }, ownProps: { userId } }, previousResult) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      // we return previous messages if resubscribe was started (has 0 messages on loading moment)
      contracts: isLoading && previousResult?.contracts.length > 0 ? previousResult.contracts : formatContracts(contracts, userId, false),
      isLoading,
    };
  },
});

const withGetContractsCountSub = withResubscribe(ON_CONTRACTS_COUNT_UPDATE_SUBSCRIPTION, {
  name: 'contractsCountSub',
  skip: ({ userId }) => !userId,
  options: ({ where }) => ({
    variables: {
      where,
    },
  }),
  props: ({ contractsCountSub: { count, error, loading: isCountLoading }, ownProps: { isLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      count: count ? count.aggregate.count : 0,
      isLoading: isCountLoading && isLoading,
    };
  },
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  defaultProps({
    orderBy: ORDER_BY,
    offset: 0,
    limit: 10,
    withTransactions: true,
  }),
  mergeProps,
  withStateHandlers(
    ({ limit, orderBy, where }) => ({ step: limit, order: getOrder(orderBy), where }),
    {
      fetchMore: ({ step }) => count => ({ limit: count + step }),
      sort: () => order => ({ order }),
      search: (_, { where, userId, objectId, isCurrent, isArbitration, inDispute }) => value => ({
        where: value ? getContractsQuery({
          userId,
          isCurrent,
          isArbitration,
          inDispute,
          objectId,
          search: `%${value}%`,
        }) : where,
      }),
    },
  ),
  withGetContractsQuery,
  withGetContractsCountSub,
)(Contracts);
