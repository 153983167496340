import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return <div className="something-went-wrong">
        <h1>Что-то пошло не так...</h1>
        <details className="error-details">
          {this.state.error && this.state.error.stack}
          <br />
          {this.state.errorInfo.componentStack}
        </details>
      </div>;
    }

    return this.props.children;
  }

  static propTypes = {
    children: PropTypes.object,
  };

  static defaultProps = {
    children: {},
  }
}
