import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';
import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';
import { ORGANIZATION_CONTRACT_DETAIL_NAME, SELF_CONTRACT_DETAIL_NAME } from '../../../utils/contractDetailType';
import { ICONS } from '../../../utils/icons';
import { INFO_MESSAGES } from '../../../utils/messages';

export default class AddTariffPlanModal extends React.Component {
  handleClose = () => {
    this.props.reset();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  onSubmit = async () => {
    const { onAddTariffPlan, contractDetails, tariffPlan, isSelfRequisitesSelected, onAfterSubmit } = this.props;
    const contractDetailsTypes = Object.keys(contractDetails);
    const contractDetailsType = contractDetailsTypes.length === 1
      ? contractDetailsTypes[0]
      : isSelfRequisitesSelected ? SELF_CONTRACT_DETAIL_NAME : ORGANIZATION_CONTRACT_DETAIL_NAME;

    return onAddTariffPlan({
      ...tariffPlan,
      contractDetailsType,
    }).then(() => {
      this.handleCancel();
      onAfterSubmit();
    });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      invalid,
      reset,
      contractDetails: { self, organization },
      tariffPlan: { name, isPrepaid },
      ...props
    } = this.props;

    const hasBothRequisites = !!(self && organization);

    return <Modal
      className="add-tariff-plan-modal"
      title="Вы действительно хотите добавить себе тариф?"
      onClose={reset}
      {...props}
    >
      <Form onSubmit={handleSubmit(this.onSubmit)} className="add-tariff-plan-form">
        <h4 className="tariff-plan-name">{name}</h4>
        <div className="info">
          <ICONS.infoBold className="info-icon" /><p>{isPrepaid ? INFO_MESSAGES.prepaidTariff : INFO_MESSAGES.postpaidTariff}</p>
        </div>
        {hasBothRequisites && <Field
          name="isSelfContractDetailsTypeSelected"
          component={FormGroupField}
          type="switch"
          className="form-group"
          labelLeft="Использовать реквизиты юридического лица"
          labelRight="Использовать реквизиты физического лица"
        />}
        <Field
          name="agreements.offer"
          component={FormGroupField}
          type="checkbox"
          label={<>Соглашаюсь с условиями <a
            className="link"
            href={process.env.REACT_APP_PUBLIC_OFFER_CUSTOMER_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Публичной оферты
          </a></>}
          onTopErrorMessage
        />
        <div className="actions">
          <Button
            isLarge
            isDisabled={pristine || invalid}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Перейти к оплате
          </Button>
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onAddTariffPlan: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    tariffPlan: PropTypes.object.isRequired,
    contractDetails: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.object,
    change: PropTypes.func.isRequired,
    onAfterSubmit: PropTypes.func.isRequired,
    isSelfRequisitesSelected: PropTypes.bool,
    // redux form
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    onRef: PropTypes.object,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    initialValues: {},
    isSelfRequisitesSelected: false,
  };
}
