import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';

import Container from '../../../../base/container';
import DataGroup from '../../../../base/dataGroup';
import { getFormattedDate } from '../../../../../utils/date';
import Button from '../../../../base/button';
import Grid from '../../../../base/grid';
import { ICONS } from '../../../../../utils/icons';
import Section from '../../../../base/section';
import { getFullName } from '../../../../../utils/name';
import FileLink from '../../../../base/fileLink';
import Input from '../../../../base/input';
import Chat from '../../../../objects/object/stageManagement/projectComponentList/chat/container';

import './index.scss';

function DescriptionInput({ initialValue, onCancelClick, onSaveClick }) {
  const [value, setValue] = useState(initialValue);

  return <>
    <div className="data-group" id="fileDescription">
      <span>Описание файла:</span>
      <Input
        name="fileDescription"
        type="textarea"
        value={value}
        onChange={setValue}
      />
    </div>
    <div className="input-action-buttons">
      <Button
        id="confirmRenaming"
        onClick={() => onSaveClick(value)}
        IconComponent={ICONS.success}
        isBorderless
      />
      <Button
        id="cancelRenaming"
        onClick={onCancelClick}
        IconComponent={ICONS.failed}
        isBorderless
      />
    </div>
  </>;
}

DescriptionInput.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

export default class ContractFileItem extends PureComponent {
  state = {
    isExpanded: false,
    editableFiles: [],
  };

  chatRef = React.createRef();

  gridProps = [
    {
      key: 'createdAt',
      columnName: 'Дата подписи',
      className: 'date',
      render: createdAt => getFormattedDate(createdAt),
    },
    {
      key: 'user',
      columnName: 'ФИО',
      className: 'name',
      render: user => getFullName(user),
      withTooltip: true,
    },
    {
      key: 'role',
      columnName: 'Роль',
      className: 'role',
    },
    {
      key: 'actionButtons',
      className: 'action-buttons',
      render: (_, { contractFileId, user, isMySign }) => isMySign && <Button
        onClick={() => this.handleRemoveFileSign(contractFileId, user.id)}
        IconComponent={ICONS.trash}
        isBorderless
      />,
    },
  ];

  handleOpenChat = () => {
    this.chatRef.current?.toggleChat();
  };

  handleSign = async id => {
    this.setState({ isSign: true });
    this.props.files.forEach(file => {
      if (file.id === id) {
        file.isSigning = true;
      }
    });
    await this.context.onSign(id);
    this.setState({ isSign: false });
  };

  handleRemoveFile = fileId => this.props.onClickRemove({ fileId, isSign: false });

  handleRemoveFileSign = (fileId, userId) => this.props.onClickRemove({ fileId, userId, isSign: true });

  handleEditFile = fileId => {
    const { editableFiles } = this.state;
    const fileIndex = editableFiles.indexOf(fileId);

    if (fileIndex > -1) {
      editableFiles.splice(fileIndex, 1);
    } else {
      editableFiles.push(fileId);
    }

    this.setState({ editableFiles: [...this.state.editableFiles] });
  };

  handleSaveFile = (fileId, value) => {
    this.context.onEditFile(fileId, value);
    this.handleEditFile(fileId);
  };

  renderHeader = () => <div className="contract-file-item-header">
    <h5>{this.props.title}</h5>
    <Button
      className="chat"
      onClick={this.handleOpenChat}
      IconComponent={ICONS.chat}
      isBorderless
    >
      Чат по разделу
    </Button>
  </div>;

  render() {
    const { type, files, objectProjectComponentId } = this.props;
    const { editableFiles } = this.state;

    return <div className="contract-file-item-wrapper">
      <Container
        id={type}
        className="contract-file-item"
        headerContent={this.renderHeader()}
      >
        {files.map(({ id, isSigning, isOwner, file, signs }, index) => <React.Fragment key={index}>
          <Section>
            <DataGroup title="Ссылка для скачивания" id="file"><FileLink file={file} /></DataGroup>
            {isOwner && <Button
              id="removeFileButton"
              className="btn-remove-file"
              onClick={() => this.handleRemoveFile(file.id)}
              IconComponent={ICONS.trash}
              isBorderless
            />}
            <DataGroup title="Дата загрузки" id="fileCreatedAt">{getFormattedDate(file.createdAt)}</DataGroup>
          </Section>
          <Section>
            {editableFiles.indexOf(file.id) > -1
              ? <DescriptionInput
                initialValue={file.title || ''}
                onSaveClick={value => this.handleSaveFile(file.id, value)}
                onCancelClick={() => this.handleEditFile(file.id)}
              />
              : <>
                <DataGroup title="Описание файла" id="fileDescription" isLineBreakAllowed>{file.title}</DataGroup>
                {isOwner && <Button
                  onClick={() => this.handleEditFile(file.id)}
                  IconComponent={ICONS.pencil}
                  isBorderless
                />}
              </>}
            {!signs.some(sign => sign.isMySign) && !isSigning && <Button onClick={() => this.handleSign(id)}>Подписать</Button>}
          </Section>
          <Section>
            <Grid
              items={signs}
              gridProps={this.gridProps}
              isBorderless
              noItemsMessage="Нет подписей"
            />
          </Section>
        </React.Fragment>)}
      </Container>
      <Chat onRef={this.chatRef} title="Чат по разделу" id={objectProjectComponentId} />
    </div>;
  }

  static contextTypes = {
    onSign: PropTypes.func.isRequired,
    onEditFile: PropTypes.func.isRequired,
  };

  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    objectProjectComponentId: PropTypes.number.isRequired,
    files: PropTypes.array,
    onClickRemove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    files: [],
    setItemState() {},
    getItemState() {},
  };
}
