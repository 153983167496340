import Base from './base';

const BASE_URL = process.env.REACT_APP_SERVER_API_PATH;

class TokenizedApi extends Base {
  constructor() {
    super(BASE_URL);
  }

  getInvitationData(token) {
    return this.get(`/favorite/invitation/${token}`).then(response => response.data);
  }

  confirmInvitation(token, relogin = false) {
    return this.post('/favorite/invitation/confirm', { data: { token, ...(relogin && { relogin }) } });
  }

  declineInvitation(token) {
    return this.post('/favorite/invitation/decline', { data: { token } });
  }
}

export default new TokenizedApi();
