export const getSpecialties = (items, selectedItems) => (items || []).map(group => ({
  label: group.name,
  expanded: group.specialties.some(specialty => {
    return selectedItems.indexOf(specialty.id) > -1;
  }),
  children: group.specialties.map(({ id: specialtyId, name: specName }) => ({
    value: specialtyId,
    label: specName,
    ...(selectedItems && {
      checked: selectedItems.indexOf(specialtyId) > -1,
    }),
  })),
}));
