import { ReactComponent as PortfolioIcon } from '../assets/icons/portfolio.svg';
import { ReactComponent as TieIcon } from '../assets/icons/tie.svg';
import { ReactComponent as StarIcon } from '../assets/icons/star.svg';
import { ReactComponent as LawIcon } from '../assets/icons/law.svg';
import { ReactComponent as CloudIcon } from '../assets/icons/cloud.svg';
import { ReactComponent as ChartIcon } from '../assets/icons/chart.svg';
import { ReactComponent as CoinIcon } from '../assets/icons/coin.svg';
import { ReactComponent as SheetIcon } from '../assets/icons/sheet.svg';
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import { ReactComponent as TableIcon } from '../assets/icons/table.svg';
import { ReactComponent as CustomerIcon } from '../assets/icons/customer.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as ContactIcon } from '../assets/icons/contact.svg';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import { ReactComponent as RefreshIcon } from '../assets/icons/refresh.svg';
import { ReactComponent as DrawingIcon } from '../assets/icons/drawing.svg';
import { ReactComponent as SortDirectionIcon } from '../assets/icons/sort-direction.svg';
import { ReactComponent as FilterIcon } from '../assets/icons/filter.svg';
import { ReactComponent as BinocularsIcon } from '../assets/icons/binoculars.svg';
import { ReactComponent as ContractsIcon } from '../assets/icons/contracts.svg';
import { ReactComponent as CorrespondenceIcon } from '../assets/icons/correspondence.svg';
import { ReactComponent as StagePIcon } from '../assets/icons/stageP.svg';
import { ReactComponent as StageRIcon } from '../assets/icons/stageR.svg';
import { ReactComponent as StageResearchIcon } from '../assets/icons/stageResearch.svg';
import { ReactComponent as TasksIcon } from '../assets/icons/tasks.svg';
import { ReactComponent as TimerIcon } from '../assets/icons/timer.svg';
import { ReactComponent as PencilIcon } from '../assets/icons/pencil.svg';
import { ReactComponent as SaveIcon } from '../assets/icons/save.svg';
import { ReactComponent as DateIcon } from '../assets/icons/date.svg';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
import { ReactComponent as RemoveFavoriteEngineerIcon } from '../assets/icons/remove-favorite-engineer.svg';
import { ReactComponent as SendLetterIcon } from '../assets/icons/send-letter.svg';
import { ReactComponent as ThumbsUpIcon } from '../assets/icons/thumbs-up.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as NotInterestedIcon } from '../assets/icons/not-interested.svg';
import { ReactComponent as CirclePlusIcon } from '../assets/icons/circle-plus.svg';
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { ReactComponent as ArrowBack } from '../assets/icons/arrow-back.svg';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowRepeatIcon } from '../assets/icons/arrow-repeat.svg';
import { ReactComponent as CompositionIcon } from '../assets/icons/composition.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as InfoBold } from '../assets/icons/info-bold.svg';
import { ReactComponent as Chat } from '../assets/icons/chat.svg';
import { ReactComponent as NoteIcon } from '../assets/icons/note.svg';
import { ReactComponent as ContractIcon } from '../assets/icons/contract.svg';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';
import { ReactComponent as MenuHamburgerIcon } from '../assets/icons/menu-hamburger.svg';
import { ReactComponent as ZoomPlus } from '../assets/icons/zoom-plus.svg';
import { ReactComponent as ZoomMinus } from '../assets/icons/zoom-minus.svg';
import { ReactComponent as NowInWorkIcon } from '../assets/icons/now-in-work.svg';
import { ReactComponent as CompletedIcon } from '../assets/icons/completed.svg';
import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg';
import { ReactComponent as FailedIcon } from '../assets/icons/failed.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user.svg';
import { ReactComponent as TechnicalTaskIcon } from '../assets/icons/technical-task.svg';
import { ReactComponent as FileSwapIcon } from '../assets/icons/file-swap.svg';
import { ReactComponent as PerformerIcon } from '../assets/icons/performer.svg';
import { ReactComponent as EngineerIcon } from '../assets/icons/engineer.svg';
import { ReactComponent as HintIcon } from '../assets/icons/hint.svg';
import { ReactComponent as LeadEngineerIcon } from '../assets/icons/lead-engineer.svg';
import { ReactComponent as OutgoingIcon } from '../assets/icons/outgoing.svg';
import { ReactComponent as IncomingIcon } from '../assets/icons/incoming.svg';
import { ReactComponent as ArchiveIcon } from '../assets/icons/archive.svg';
import { ReactComponent as DelegatedIcon } from '../assets/icons/delegated.svg';
import { ReactComponent as BusinessmanIcon } from '../assets/icons/businessman.svg';
import { ReactComponent as ClosedDoorIcon } from '../assets/icons/closed-door.svg';
import { ReactComponent as ObservableIcon } from '../assets/icons/observable.svg';
import { ReactComponent as OpenDoorIcon } from '../assets/icons/open-door.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '../assets/icons/google.svg';
import { ReactComponent as VkIcon } from '../assets/icons/vk.svg';
import { ReactComponent as FacebookRoundIcon } from '../assets/icons/facebook-round.svg';
import { ReactComponent as VkRoundIcon } from '../assets/icons/vk-round.svg';
import { ReactComponent as InstagramRoundIcon } from '../assets/icons/instagram-round.svg';
import { ReactComponent as YoutubeRoundIcon } from '../assets/icons/youtube-round.svg';
import { ReactComponent as YandexIcon } from '../assets/icons/yandex.svg';
import { ReactComponent as SentenceIcon } from '../assets/icons/sentence.svg';
import { ReactComponent as FilesIcon } from '../assets/icons/files.svg';
import { ReactComponent as CardsIcon } from '../assets/icons/cards.svg';
import { ReactComponent as PrinterIcon } from '../assets/icons/printer.svg';
import { ReactComponent as NotificationIcon } from '../assets/icons/notifications.svg';
import { ReactComponent as LetterIcon } from '../assets/icons/letter.svg';
import { ReactComponent as EnvelopeIcon } from '../assets/icons/envelope.svg';
import { ReactComponent as EnvelopeOutlinedIcon } from '../assets/icons/envelopeOutlined.svg';
import { ReactComponent as LampIcon } from '../assets/icons/lamp.svg';
import { ReactComponent as ShoppingIcon } from '../assets/icons/shopping.svg';
import { ReactComponent as CopyIcon } from '../assets/icons/copy.svg';
import { ReactComponent as RssIcon } from '../assets/icons/rss.svg';
import { ReactComponent as SignOutIcon } from '../assets/icons/sign-out.svg';
import { ReactComponent as ClipIcon } from '../assets/icons/clip.svg';
import { ReactComponent as CheckMarkSingle } from '../assets/icons/check-mark-single.svg';
import { ReactComponent as CheckMarkDouble } from '../assets/icons/check-mark-double.svg';
import { ReactComponent as PaperAndPencil } from '../assets/icons/paper-and-pencil.svg';
import { ReactComponent as BoxWithArrow } from '../assets/icons/box-with-arrow.svg';

export const ICONS = {
  archive: ArchiveIcon,
  arrow: ArrowIcon,
  arrowBack: ArrowBack,
  arrowRight: ArrowRight,
  arrowRepeat: ArrowRepeatIcon,
  add: AddIcon,
  binoculars: BinocularsIcon,
  businessman: BusinessmanIcon,
  cards: CardsIcon,
  chart: ChartIcon,
  circlePlus: CirclePlusIcon,
  clip: ClipIcon,
  cloud: CloudIcon,
  close: CloseIcon,
  closedDoor: ClosedDoorIcon,
  coin: CoinIcon,
  contact: ContactIcon,
  contract: ContractIcon,
  contracts: ContractsIcon,
  copy: CopyIcon,
  correspondence: CorrespondenceIcon,
  composition: CompositionIcon,
  customer: CustomerIcon,
  chat: Chat,
  check: CheckIcon,
  drawing: DrawingIcon,
  date: DateIcon,
  delegated: DelegatedIcon,
  engineer: EngineerIcon,
  envelope: EnvelopeIcon,
  envelopeOutlined: EnvelopeOutlinedIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  files: FilesIcon,
  fileSwap: FileSwapIcon,
  google: GoogleIcon,
  hint: HintIcon,
  incoming: IncomingIcon,
  info: Info,
  infoBold: InfoBold,
  lamp: LampIcon,
  law: LawIcon,
  leadEngineer: LeadEngineerIcon,
  menu: MenuIcon,
  menuHamburger: MenuHamburgerIcon,
  note: NoteIcon,
  nowInWork: NowInWorkIcon,
  observable: ObservableIcon,
  openDoor: OpenDoorIcon,
  outgoing: OutgoingIcon,
  stageP: StagePIcon,
  pencil: PencilIcon,
  performer: PerformerIcon,
  plus: PlusIcon,
  portfolio: PortfolioIcon,
  shopping: ShoppingIcon,
  stageR: StageRIcon,
  stageResearch: StageResearchIcon,
  refresh: RefreshIcon,
  removeFavoriteEngineer: RemoveFavoriteEngineerIcon,
  rss: RssIcon,
  notInterested: NotInterestedIcon,
  save: SaveIcon,
  search: SearchIcon,
  sendLetter: SendLetterIcon,
  sentence: SentenceIcon,
  settings: SettingsIcon,
  sheet: SheetIcon,
  technicalTask: TechnicalTaskIcon,
  sortDirection: SortDirectionIcon,
  star: StarIcon,
  completed: CompletedIcon,
  success: SuccessIcon,
  signOut: SignOutIcon,
  failed: FailedIcon,
  table: TableIcon,
  tasks: TasksIcon,
  thumbsUp: ThumbsUpIcon,
  tie: TieIcon,
  timer: TimerIcon,
  trash: TrashIcon,
  user: UserIcon,
  users: UsersIcon,
  vk: VkIcon,
  yandex: YandexIcon,
  facebookRound: FacebookRoundIcon,
  vkRound: VkRoundIcon,
  instagramRound: InstagramRoundIcon,
  youtubeRound: YoutubeRoundIcon,
  zoomPlus: ZoomPlus,
  zoomMinus: ZoomMinus,
  printer: PrinterIcon,
  notification: NotificationIcon,
  letter: LetterIcon,
  checkMarkSingle: CheckMarkSingle,
  checkMarkDouble: CheckMarkDouble,
  paperAndPencil: PaperAndPencil,
  boxWithArrow: BoxWithArrow,
};
