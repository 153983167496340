import React from 'react';

import { prepareInvitationToFavoriteTitle, prepareRemoveFromFavoriteTitle } from '../common';
import ProfileLink from '../../../base/profileLink';

import '../index.scss';

const REVOKE_VACANCY_SCHEMA = {
  getTitle: isPerformer => {
    return `Вы действительно хотите ${isPerformer ? 'снять вакансию с торгов' : 'отменить конкурс по вакансии'}?`;
  },
  successButtonTitle: 'Да',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const TURN_IN_WORK_SCHEMA = {
  title: 'Вы действительно хотите сдать работу по PROJECT_COMPONENT_TYPE_NAME?',
  hint: '*Все заинтересованные лица получат уведомление о сданной работе',
  successButtonTitle: 'Да, сдать работу',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const ACCEPT_WORK_SCHEMA = {
  title: 'Вы действительно хотите принять работу по PROJECT_COMPONENT_TYPE_NAME?',
  successButtonTitle: 'Да, принять работу',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const ACCEPT_COMPLETENESS_SCHEMA = {
  title: 'Вы подтверждаете, что в PROJECT_COMPONENT_TYPE_NAME загружен полный комплект заказаной документации, в том числе все необходимые исходники в редактируемом формате?',
  successButtonTitle: 'Да, подтверждаю',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const INVITATION_TO_FAVORITES_SCHEMA = {
  getTitle: function getTitle(id, name, type) {
    return <>Пользователь {id ? <ProfileLink userId={id} text={name} /> : name}
      &nbsp;хочет добавить вас к себе в избранные контакты как {type}
    </>;
  },
  hint: '*В случае отказа пользователь не будет знать, что вы отказали, приглашение исчезнет из его списка',
  successButtonTitle: 'Разрешить, добавить в избранное',
  dismissButtonTitle: 'Отказать, не добавлять в избранное',
  onSuccess() {},
  onDismiss() {},
};

const REMOVE_FROM_FAVORITES_SCHEMA = {
  getTitle: function getTitle(id, name, type) {
    return <>Вы действительно хотите убрать {id ? <ProfileLink userId={id} text={name} /> : name}
      &nbsp;из списка избранных {type}?
    </>;
  },
  successButtonTitle: 'Да, убрать',
  dismissButtonTitle: 'Нет, оставить',
  onSuccess() {},
  onDismiss() {},
};

const REMOVE_TASK_SCHEMA = {
  getTitle: function getTitle(id) {
    return <>Вы действительно хотите удалить Задачу №{id}?</>;
  },
  successButtonTitle: 'Да',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const REMOVE_PROJECT_COMPONENT_SCHEMA = {
  title: 'Вы действительно хотите убрать PROJECT_COMPONENT_TYPE_NAME из стадии "STAGE_NAME"?',
  successButtonTitle: 'Да, убрать',
  dismissButtonTitle: 'Нет, оставить',
  onSuccess() {},
  onDismiss() {},
};

const REMOVE_PARTICIPANT_SCHEMA = {
  title: 'Вы действительно хотите удалить участника из списка?',
  successButtonTitle: 'Да, убрать',
  dismissButtonTitle: 'Нет, оставить',
  onSuccess() {},
  onDismiss() {},
};

const REMOVE_BID_SCHEMA = {
  title: 'Вы действительно хотите отозвать предложение?',
  successButtonTitle: 'Да',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const CHOOSE_COMPETITION_WINNER_SCHEMA = {
  title: 'Вы действительно хотите назначить этого участника победителем COMPETITION_TYPE?',
  successButtonTitle: 'Да, назначить',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const WARNING_UNPIN_OBJECT_SCHEMA = {
  title: 'Вы действительно хотите открепить этот объект от тарифного плана? Все файлы объекта будут удалены без возможности восстановления',
  successButtonTitle: 'Да, открепить',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

const REMOVE_FILE_SCHEMA = {
  title: 'Вы действительно хотите удалить прикрепленный файл?',
  successButtonTitle: 'Да, удалить',
  dismissButtonTitle: 'Нет',
  onSuccess() {},
  onDismiss() {},
};

export const getInvitationToFavorites = (invitingUser, isPerformer, onSuccess, onDismiss) => {
  const schema = {
    ...INVITATION_TO_FAVORITES_SCHEMA,
    onSuccess,
    onDismiss,
  };
  schema.title = prepareInvitationToFavoriteTitle(isPerformer, schema.getTitle, invitingUser);

  return schema;
};

export const getRemoveFromFavoritesSchema = (favorite, isPerformer, onSuccess, onDismiss) => {
  const schema = {
    ...REMOVE_FROM_FAVORITES_SCHEMA,
    onSuccess,
    onDismiss,
  };
  schema.title = prepareRemoveFromFavoriteTitle(isPerformer, schema.getTitle, favorite);

  return schema;
};

export const getRemoveTaskSchema = (task, onSuccess, onDismiss) => {
  return {
    ...REMOVE_TASK_SCHEMA,
    title: REMOVE_TASK_SCHEMA.getTitle(task.id),
    onSuccess,
    onDismiss,
  };
};

export const getRemoveProjectComponent = (
  projectComponent,
  stageName,
  onSuccess,
  onDismiss,
) => {
  const { type } = projectComponent;
  const accusativeName = type ? type.accusativeName : '';
  return {
    ...REMOVE_PROJECT_COMPONENT_SCHEMA,
    title: REMOVE_PROJECT_COMPONENT_SCHEMA.title.replace('PROJECT_COMPONENT_TYPE_NAME', accusativeName).replace('STAGE_NAME', stageName),
    onSuccess,
    onDismiss,
  };
};

export const getTurnInWorkSchema = (projectComponent, onSuccess, onDismiss) => {
  const { type: { dativeName } } = projectComponent || { type: {} };
  return {
    ...TURN_IN_WORK_SCHEMA,
    title: TURN_IN_WORK_SCHEMA.title.replace('PROJECT_COMPONENT_TYPE_NAME', dativeName),
    onSuccess,
    onDismiss,
  };
};

export const getAcceptWorkSchema = (projectComponent, onSuccess, onDismiss, isCompleteness) => {
  const { type: { dativeName, accusativeName } } = projectComponent || { type: {} };
  const schema = isCompleteness ? ACCEPT_COMPLETENESS_SCHEMA : ACCEPT_WORK_SCHEMA;
  return {
    ...schema,
    title: schema.title.replace('PROJECT_COMPONENT_TYPE_NAME', isCompleteness ? accusativeName : dativeName),
    onSuccess,
    onDismiss,
  };
};

export const getRevokeVacancySchema = (isPerformer, onSuccess, onDismiss) => {
  return {
    ...REVOKE_VACANCY_SCHEMA,
    title: REVOKE_VACANCY_SCHEMA.getTitle(isPerformer),
    onSuccess,
    onDismiss,
  };
};

export const getRemoveParticipantSchema = (onSuccess, onDismiss) => {
  return {
    ...REMOVE_PARTICIPANT_SCHEMA,
    onSuccess,
    onDismiss,
  };
};

export const getRemoveBidSchema = (onSuccess, onDismiss) => {
  return {
    ...REMOVE_BID_SCHEMA,
    onSuccess,
    onDismiss,
  };
};

export const getChooseCompetitionWinnerSchema = (isTenders, onSuccess, onDismiss) => {
  return {
    ...CHOOSE_COMPETITION_WINNER_SCHEMA,
    title: CHOOSE_COMPETITION_WINNER_SCHEMA.title.replace('COMPETITION_TYPE', isTenders ? 'торгов' : 'конкурса'),
    onSuccess,
    onDismiss,
  };
};

export const getWarningUnpinObjectSchema = (onSuccess, onDismiss) => ({
  ...WARNING_UNPIN_OBJECT_SCHEMA,
  onSuccess,
  onDismiss,
});

export const getRemoveFileSchema = (onSuccess, onDismiss) => ({
  ...REMOVE_FILE_SCHEMA,
  onSuccess,
  onDismiss,
});
