import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import upperFirst from 'lodash/upperFirst';

import SignUpForm from '../../components/signUpForm';
import SignInForm from '../../components/signInForm';
import { ROUTES } from '../../utils/routes';
import { SESSION_EXPIRED, THANKS_FOR_REGISTRATION, EMAIL_EXISTS } from '../../utils/messages';
import { ICONS } from '../../utils/icons';
import InfoModal from '../../components/modals/info';
import Button from '../../components/base/button';
import { removeAuthCookie } from '../../utils/session';
import { goTo } from '../../utils/menu';

import './index.scss';

const { REACT_APP_SERVER_API_PATH } = process.env;

const clearLocationHash = () =>
  window.history.pushState('', document.title, `${window.location.pathname}${window.location.search}`);

export const oauthServices = isDisabled => {
  const goToAuthPage = strategy => () => {
    removeAuthCookie();
    window.location = `${REACT_APP_SERVER_API_PATH}/oauth/${strategy}/signin`;
  };

  return <>
    <div className="divider"><span>Или продолжить с</span></div>
    <div className="social-buttons">
      <Button
        onClick={goToAuthPage('facebook')}
        IconComponent={ICONS.facebook}
        isDisabled={isDisabled}
      >
        Войти через Facebook
      </Button>
      <Button
        onClick={goToAuthPage('google')}
        IconComponent={ICONS.google}
        isDisabled={isDisabled}
      >
        Войти через Google
      </Button>
      <Button
        onClick={goToAuthPage('vkontakte')}
        IconComponent={ICONS.vk}
        isDisabled={isDisabled}
      >
        Войти через ВКонтакте
      </Button>
      <Button
        onClick={goToAuthPage('yandex')}
        IconComponent={ICONS.yandex}
        isDisabled={isDisabled}
      >
        Войти через Яндекс
      </Button>
    </div>
  </>;
};

export default class Auth extends Component {
  modalRef = React.createRef();

  signUp = params => {
    const { signUp, location: { pathname } } = this.props;
    const isDemo = pathname === ROUTES.signUpWithDemo;
    params.isDemo = isDemo;

    return signUp(params).then(this.handleShowModal).catch(error => {
      if (error.errors) {
        throw error;
      }
    });
  };

  handleShowModal = () => {
    if (this.modalRef.current) {
      const modal = this.modalRef.current.wrappedInstance || this.modalRef.current;
      modal.toggleModal();
    }
  };

  render() {
    const { signIn, location: { pathname } } = this.props;
    const isDemo = pathname === ROUTES.signUpWithDemo;
    const sessionExpired = sessionStorage.getItem('sessionExpired');
    if (sessionExpired) {
      toast.warn(SESSION_EXPIRED);
      sessionStorage.removeItem('sessionExpired');
    }

    if (window.location.hash === '#email-exists') {
      clearLocationHash();
      toast.warn(EMAIL_EXISTS.replace(/AUTH_SERVICE/g, upperFirst(window.location.search.slice(1))), { autoClose: false });
    }

    return <div className="auth-container">
      <Tabs onSelect={goTo} defaultActiveKey={pathname} id="auth-tabs">
        <Tab
          eventKey={ROUTES.signIn}
          title="Вход"
          tabClassName="sign-in"
        >
          <SignInForm onSubmit={signIn} />
          {oauthServices()}
          <span className="info">
            Используя эту форму, вы соглашаетесь с условиями&nbsp;
            <a
              className="link"
              href={process.env.REACT_APP_PUBLIC_OFFER_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Публичной оферты
            </a> и даете согласие на&nbsp;
            <a
              className="link"
              href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              обработку своих персональных данных
            </a>
          </span>
        </Tab>
        <Tab
          eventKey={isDemo ? ROUTES.signUpWithDemo : ROUTES.signUp}
          title="Регистрация"
          tabClassName="sign-up"
        >
          <SignUpForm onSubmit={this.signUp} />
        </Tab>
      </Tabs>
      <InfoModal ref={this.modalRef} message={THANKS_FOR_REGISTRATION} />
    </div>;
  }
}

Auth.propTypes = {
  signIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

Auth.defaultProps = {
  signIn() {},
  signUp() {},
};
