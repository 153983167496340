import React, { PureComponent, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';

import Button from '../button';
import { useAccordionToggle } from 'react-bootstrap';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { sleep } from '../../../utils/async';

import './index.scss';

const ACCORDION_STATUSES = {
  expanded: '1',
  collapsed: '0',
};

// react-bootstrap wants toggle button as separate function component, otherwise:
// Invariant Violation: Invalid hook call. Hooks can only be called inside of the body of a function component.
const CustomToggle = forwardRef(({ eventKey, isExpanded, onClick, isExpansionDisabled }, ref) => {
  const accordionToggleHook = useAccordionToggle(eventKey, onClick);

  const handleClick = () => {
    onClick();
    if (!isExpansionDisabled) {
      accordionToggleHook();
    }
  };

  return <Button
    buttonProps={{
      name: 'expandContainerButton',
    }}
    className={classNames('toggle-button', { collapsed: !isExpanded })}
    IconComponent={ArrowIcon}
    isBorderless
    onClick={handleClick}
    ref={ref}
  />;
});

CustomToggle.displayName = 'customToggle';

CustomToggle.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  isExpansionDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

CustomToggle.defaultProps = {
  isExpansionDisabled: false,
  isExpanded: false,
};

export default class Container extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.isDefaultExpanded,
    };

    this.toggleRef = React.createRef();
    props.onRef.current = this;
  }

  closeContainer = () => {
    this.setState({ isExpanded: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.closeContainer();
    }
  }

  handleExpandToggle = async value => {
    const { isExpanded } = this.state;
    const { shareExpandedState, isExpansionDisabled } = this.props;
    const result = isBoolean(value) ? value : !isExpanded;

    if (isBoolean(value) ? value : isExpanded) {
      await sleep(0);
    }

    shareExpandedState(result);
    if (!isExpansionDisabled) {
      this.setState({ isExpanded: result });
    }
  };

  handleClickHeader = () => {
    this.toggleRef.current.props.onClick();
  }

  render() {
    const {
      id,
      style,
      className,
      headerContent,
      footerContent,
      previewContent,
      isExpansionDisabled,
      children,
      showToggle,
      isHeaderClickable,
      showPreviewAlways,
    } = this.props;
    const { isExpanded } = this.state;

    return <Accordion
      id={id}
      activeKey={ACCORDION_STATUSES[isExpanded ? 'expanded' : 'collapsed']}
      defaultActiveKey={ACCORDION_STATUSES[isExpanded ? 'expanded' : 'collapsed']}
      className={classNames(
        'basic-container',
        isExpanded ? 'expanded' : 'collapsed',
        className,
      )}
      style={style}
    >
      <Card>
        <Card.Header>
          <div className={classNames('accordion-header', { clickable: isHeaderClickable })}>
            <div
              className="header-content"
              {...(isHeaderClickable && { onClick: this.handleClickHeader })}
            >
              {headerContent}
            </div>
            {showToggle && <CustomToggle
              ref={this.toggleRef}
              eventKey="1"
              onClick={this.handleExpandToggle}
              isExpanded={isExpanded}
              isExpansionDisabled={isExpansionDisabled}
            />}
          </div>
          {(!isExpanded || showPreviewAlways) && previewContent && <div className="accordion-preview">{previewContent}</div>}
        </Card.Header>
        {children && <Accordion.Collapse eventKey="1">
          <Card.Body>
            {children}
          </Card.Body>
        </Accordion.Collapse>}
        {footerContent && <Card.Footer>
          <div className="footer-content">
            {footerContent}
          </div>
        </Card.Footer>}
      </Card>
    </Accordion>;
  }

  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    headerContent: PropTypes.node.isRequired,
    footerContent: PropTypes.node,
    previewContent: PropTypes.node,
    showPreviewAlways: PropTypes.bool,
    children: PropTypes.node,
    isDefaultExpanded: PropTypes.bool,
    isExpansionDisabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    shareExpandedState: PropTypes.func,
    showToggle: PropTypes.bool,
    isHeaderClickable: PropTypes.bool,
    onRef: PropTypes.object,
  };

  static defaultProps = {
    id: 'container',
    style: {},
    shareExpandedState() {},
    footerContent: null,
    previewContent: '',
    isDefaultExpanded: false,
    isExpansionDisabled: false,
    className: '',
    showToggle: true,
    isHeaderClickable: false,
    showPreviewAlways: false,
    children: '',
    onRef: {
      current: null,
    },
  };
}
