import React, { PureComponent } from 'react';

import Item from './item';
import InfiniteList from '../../../base/infiniteList';
import { LIST_MESSAGES } from '../../../../utils/messages';

import './index.scss';

export default class ProfileContractsList extends PureComponent {
  render() {
    return <InfiniteList
      className="contracts-list-wrapper"
      emptyMessage={LIST_MESSAGES.emptyContracts}
      itemComponent={Item}
      {...this.props}
    />;
  }
}
