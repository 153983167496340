import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TransactionList from '../../../base/transactionList';
import DataGroup from '../../../base/dataGroup';
import { getFormattedDate } from '../../../../utils/date';
import { getFormattedNumber } from '../../../../utils/numbers';

import './index.scss';

export default class ContractTransactionsList extends PureComponent {
  gridProps = [
    {
      key: 'id',
      columnName: 'ID Транзакции',
      className: 'id',
    },
    {
      key: 'createdAt',
      columnName: 'Дата',
      className: 'date',
      render: createdAt => getFormattedDate(createdAt),
    },
    {
      key: 'description',
      columnName: 'Обоснование',
      className: 'description',
      render: description => <span>{description}</span>,
    },
    {
      key: 'income',
      columnName: 'Поступления',
      className: 'income',
      render: income => <DataGroup className="colored green">{getFormattedNumber(income)}</DataGroup>,
    },
    {
      key: 'outcome',
      columnName: 'Списания',
      className: 'outcome',
      render: outcome => <DataGroup className="colored red">{getFormattedNumber(outcome)}</DataGroup>,
    },
    {
      key: 'accordionButton',
      className: 'empty-column',
      render() {},
    },
  ];

  render() {
    const { contract } = this.props;

    return <div className="transactions-list-wrapper">
      <TransactionList
        title="Реестр принятых/оплаченных работ"
        payments={contract.payments}
        gridProps={this.gridProps}
      />
    </div>;
  }

  static propTypes = {
    contract: PropTypes.object.isRequired,
  };
}
