import set from 'lodash/set';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';

import { VALIDATION_MESSAGES } from './validationMessages';
import { CONTRACT_PHASES } from './phase';

export const EMAIL_REGEXP =
// eslint-disable-next-line no-useless-escape
  /^[+a-zA-Z0-9_.!#$%&'*\/=?^`{|}~-]+[+a-zA-Z0-9_!#$%&'*\/=?^`{|}~-]{1}@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
// eslint-disable-next-line no-useless-escape
const CODE_REGEXP = /^[+a-zA-Zа-яА-Я0-9./\-]+$/;

const MIN_PASS_LENGTH = 5;
const PHONE_LENGTH = 11;
const PASSWORD_REQUIRED_MESSAGE = VALIDATION_MESSAGES.requiredPassword;
export const INDIVIDUAL_INN_LENGTH = 12;
export const INSURANCE_ACCOUNT_LENGTH = 11;
const ORGANIZATION_INN_LENGTH = 10;
const BANK_ACCOUNT_LENGTH = 20;
export const BANK_BIK_LENGTH = 9;
export const KPP_LENGTH = 9;
export const OGRN_LENGTH = 13;
const CORRESPONDENT_ACCOUNT_LENGTH = 20;
const PAYMENT_TERMS_TOTAL_RATE = 100;

export const getSubmitValidation = (errors, dispatch, submitError, props) => {
  if (errors) {
    const registeredFieldErrors = Object.keys(props.registeredFields)
      .filter(fieldName => get(errors, fieldName))
      .map(fieldName => ({
        fieldName,
        value: get(errors, fieldName),
      }));
    document.getElementById(registeredFieldErrors[0].fieldName).scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    throw submitError;
  }
};

export const validateDeadline = value => {
  if (!value) {
    return VALIDATION_MESSAGES.requiredDeadline;
  }
};

const getPasswordRules = field => [
  {
    type: 'Invalid',
    func: fields => fields[field] && fields[field].length < MIN_PASS_LENGTH,
    message: VALIDATION_MESSAGES.passwordTooShort,
  },
  {
    type: 'Invalid',
    func: fields => fields[field] && !/^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*[0-9])(?=.*[~!@#$%^&*_()+\-?])/.test(fields[field]),
    message: VALIDATION_MESSAGES.passwordTooWeak,
  },
];

const fieldRequiredRules = message => [
  {
    type: 'Required',
    message: message,
  },
];

const groupFieldRequiredRules = (key, message) => [
  {
    type: 'RequiredFunc',
    func: props => !get(props, key),
    message,
  },
];

const getPhasesRules = field => [{
  type: 'RequiredFunc',
  func: ({ paymentTerms: { [field]: currentPhase, phase0, phase1, phase2, phase3 } }) =>
    currentPhase && [phase0, phase1, phase2, phase3]
      .reduce((result, phase) => result + parseFloat(phase ? phase.rate : 0), 0) !== PAYMENT_TERMS_TOTAL_RATE,
  message: VALIDATION_MESSAGES.paymentTermsTotalRate,
}];

export const RULES = {
  email: [
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredEmail),
    {
      type: 'Invalid',
      func: ({ email }) => email && !email.match(EMAIL_REGEXP),
      message: VALIDATION_MESSAGES.invalidEmail,
    },
  ],
  signInPassword: fieldRequiredRules(PASSWORD_REQUIRED_MESSAGE),
  password: [
    ...fieldRequiredRules(PASSWORD_REQUIRED_MESSAGE),
    ...getPasswordRules('password'),
  ],
  repeatPassword: [
    ...fieldRequiredRules(VALIDATION_MESSAGES.repeatPassword),
    {
      type: 'Invalid',
      func: ({ password, repeatPassword }) => repeatPassword && repeatPassword !== password,
      message: VALIDATION_MESSAGES.passwordsMismatch,
    },
  ],
  newPassword: getPasswordRules('newPassword'),
  repeatNewPassword: [
    {
      type: 'RequiredFunc',
      func: ({ newPassword, repeatNewPassword }) => !!newPassword && !repeatNewPassword,
      message: VALIDATION_MESSAGES.repeatNewPassword,
    },
    {
      type: 'Invalid',
      func: ({ newPassword, repeatNewPassword }) => repeatNewPassword && repeatNewPassword !== newPassword,
      message: VALIDATION_MESSAGES.passwordsMismatch,
    },
  ],
  firstName: fieldRequiredRules(VALIDATION_MESSAGES.requiredFirstName),
  lastName: fieldRequiredRules(VALIDATION_MESSAGES.requiredLastName),
  phone: [
    {
      type: 'Invalid',
      func: ({ phone }) => phone && phone.length !== PHONE_LENGTH,
      message: VALIDATION_MESSAGES.requiredValidPhone,
    },
  ],
  name: fieldRequiredRules(VALIDATION_MESSAGES.requiredName),
  projectComponentId: fieldRequiredRules(VALIDATION_MESSAGES.requiredName),
  object: fieldRequiredRules(VALIDATION_MESSAGES.requiredObject),
  stage: fieldRequiredRules(VALIDATION_MESSAGES.requiredStage),
  projectComponent: fieldRequiredRules(VALIDATION_MESSAGES.requiredProjectComponent),
  customName: fieldRequiredRules(VALIDATION_MESSAGES.requiredCustomName),
  address: fieldRequiredRules(VALIDATION_MESSAGES.requiredAddress),
  deadline: fieldRequiredRules(VALIDATION_MESSAGES.requiredDeadline),
  objectType: fieldRequiredRules(VALIDATION_MESSAGES.requiredObjectType),
  contractDetailType: fieldRequiredRules(VALIDATION_MESSAGES.requiredContractDetailType),
  toUser: fieldRequiredRules(VALIDATION_MESSAGES.requiredEmployee),
  description: fieldRequiredRules(VALIDATION_MESSAGES.description),
  projectComponents: [{
    type: 'Required',
    func: ({ projectComponents }) => !projectComponents || projectComponents.length === 0,
    message: VALIDATION_MESSAGES.requiredProjectComponents,
  }],
  employee: [{
    type: 'Required',
    func: ({ employee }) => !employee,
    message: VALIDATION_MESSAGES.requiredEmployee,
  }],
  region: fieldRequiredRules(VALIDATION_MESSAGES.requiredRegion),
  code: [
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredCipher),
    {
      type: 'Invalid',
      func: ({ code }) => code && !CODE_REGEXP.test(code),
      message: VALIDATION_MESSAGES.invalidCipher,
    },
  ],
  start: fieldRequiredRules(VALIDATION_MESSAGES.setStart),
  expertiseFile: fieldRequiredRules(VALIDATION_MESSAGES.setExpertiseFile),
  links: {
    positive: [{
      func: ({ links }) => !links || !get(links, 'positive.length') && !get(links, 'negative.length'),
      message: VALIDATION_MESSAGES.setExpertiseLinks,
    }],
    negative: [{
      func: ({ links }) => !links || !get(links, 'positive.length') && !get(links, 'negative.length'),
      message: VALIDATION_MESSAGES.setExpertiseLinks,
    }],
  },
  individualInn: [
    {
      type: 'Invalid',
      func: ({ individualInn }) => individualInn && individualInn.toString().length !== INDIVIDUAL_INN_LENGTH,
      message: VALIDATION_MESSAGES.invalidInn,
    },
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredInn),
  ],
  organizationInn: [
    {
      type: 'Invalid',
      func: ({ organizationInn }) => organizationInn && (organizationInn.toString().length !== ORGANIZATION_INN_LENGTH &&
        organizationInn.toString().length !== INDIVIDUAL_INN_LENGTH),
      message: VALIDATION_MESSAGES.invalidInn,
    },
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredInn),
  ],
  insuranceAccount: [
    {
      type: 'Invalid',
      func: ({ insuranceAccount }) => insuranceAccount && insuranceAccount.toString().length !== INSURANCE_ACCOUNT_LENGTH,
      message: VALIDATION_MESSAGES.invalidInsuranceAccount,
    },
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredInsuranceAccount),
  ],
  kpp: [
    {
      type: 'Invalid',
      func: ({ kpp }) => kpp && kpp.toString().length !== KPP_LENGTH,
      message: VALIDATION_MESSAGES.invalidKpp,
    },
  ],
  ogrn: [
    {
      type: 'Invalid',
      func: ({ ogrn }) => ogrn && ogrn.toString().length !== OGRN_LENGTH,
      message: VALIDATION_MESSAGES.invalidOgrn,
    },
  ],
  bankAccount: [
    {
      type: 'Invalid',
      func: ({ bankAccount }) => bankAccount && bankAccount.toString().length !== BANK_ACCOUNT_LENGTH,
      message: VALIDATION_MESSAGES.invalidBankAccount,
    },
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredBankAccount),
  ],
  bankBik: [
    {
      type: 'Invalid',
      func: ({ bankBik }) => bankBik && bankBik.toString().length !== BANK_BIK_LENGTH,
      message: VALIDATION_MESSAGES.invalidBankBik,
    },
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredBankBik),
  ],
  bankName: fieldRequiredRules(VALIDATION_MESSAGES.requiredBankName),
  taxSystemId: fieldRequiredRules(VALIDATION_MESSAGES.requiredTaxSystemId),
  correspondentAccount: [
    {
      type: 'Invalid',
      func: ({ correspondentAccount }) => correspondentAccount && correspondentAccount
        .toString().length !== CORRESPONDENT_ACCOUNT_LENGTH,
      message: VALIDATION_MESSAGES.invalidCorrespondentAccount,
    },
  ],
  bidAmount: [
    ...fieldRequiredRules(VALIDATION_MESSAGES.requiredValue),
    {
      type: 'Invalid',
      func: ({ isSafeDeal, bidAmount, commissionAmount }) => isSafeDeal && bidAmount < commissionAmount,
      message: VALIDATION_MESSAGES.bidAmountLessMinCommission,
    },
  ],
  totalAmount: fieldRequiredRules(VALIDATION_MESSAGES.requiredValue),
  chiefEngineer: fieldRequiredRules(VALIDATION_MESSAGES.requiredChiefEngineer),
  contractBreakOptionId: fieldRequiredRules(VALIDATION_MESSAGES.requiredBreakContractOption),
  guiltyUserId: fieldRequiredRules(VALIDATION_MESSAGES.requiredGuiltyUserId),
  agreements: {
    offer: [
      {
        type: 'RequiredFunc',
        func: ({ agreements }) => !agreements ||
          (isBoolean(agreements.offer) && !agreements.offer) ||
          (isBoolean(agreements.personalInformation) && !agreements.personalInformation) ||
          (isBoolean(agreements.isAdult) && !agreements.isAdult) ||
          (isBoolean(agreements.newsletters) && !agreements.newsletters),
        message: VALIDATION_MESSAGES.requiredAgreements,
      },
    ],
    personalInformation: [
      {
        type: 'RequiredFunc',
        func: ({ agreements }) => !agreements || !agreements.personalInformation,
        message: VALIDATION_MESSAGES.requiredAgreements,
      },
    ],
    isAdult: [
      {
        type: 'RequiredFunc',
        func: ({ agreements }) => !agreements || !agreements.isAdult,
        message: VALIDATION_MESSAGES.requiredAgreements,
      },
    ],
    newsletters: [
      {
        type: 'RequiredFunc',
        func: ({ agreements }) => !agreements || !agreements.newsletters,
        message: VALIDATION_MESSAGES.requiredAgreements,
      },
    ],
  },
  applicationDeadline: fieldRequiredRules(VALIDATION_MESSAGES.requiredApplicationDeadline),
  paymentTerms: {
    phase0: {
      rate: [
        ...groupFieldRequiredRules(
          'paymentTerms.phase0.rate',
          VALIDATION_MESSAGES.requiredValue,
        ),
        ...getPhasesRules(CONTRACT_PHASES.phase0),
      ],
      termLimit: groupFieldRequiredRules(
        'paymentTerms.phase0.termLimit',
        VALIDATION_MESSAGES.requiredValue,
      ),
    },
    phase1: {
      rate: [
        ...groupFieldRequiredRules(
          'paymentTerms.phase1.rate',
          VALIDATION_MESSAGES.requiredValue,
        ),
        ...getPhasesRules(CONTRACT_PHASES.phase1),
      ],
      deadline: groupFieldRequiredRules(
        'paymentTerms.phase1.deadline',
        VALIDATION_MESSAGES.requiredDeadline),
    },
    phase2: {
      rate: [
        ...groupFieldRequiredRules(
          'paymentTerms.phase2.rate',
          VALIDATION_MESSAGES.requiredValue,
        ),
        ...getPhasesRules(CONTRACT_PHASES.phase2),
      ],
      termLimit: groupFieldRequiredRules(
        'paymentTerms.phase2.termLimit',
        VALIDATION_MESSAGES.requiredValue,
      ),
      negativeExpertiseFine: groupFieldRequiredRules(
        'paymentTerms.phase2.negativeExpertiseFine',
        VALIDATION_MESSAGES.requiredValue,
      ),
    },
    phase3: {
      rate: [
        ...groupFieldRequiredRules(
          'paymentTerms.phase3.rate',
          VALIDATION_MESSAGES.requiredValue,
        ),
        ...getPhasesRules(CONTRACT_PHASES.phase3),
      ],
      termLimit: groupFieldRequiredRules(
        'paymentTerms.phase3.termLimit',
        VALIDATION_MESSAGES.requiredValue,
      ),
    },
    remarksCreateTermLimit: groupFieldRequiredRules(
      'paymentTerms.remarksCreateTermLimit',
      VALIDATION_MESSAGES.requiredValue,
    ),
    month: {
      paymentTermLimit: groupFieldRequiredRules(
        'paymentTerms.month.paymentTermLimit',
        VALIDATION_MESSAGES.requiredValue,
      ),
      paymentDelayFine: groupFieldRequiredRules(
        'paymentTerms.month.paymentDelayFine',
        VALIDATION_MESSAGES.requiredValue,
      ),
    },
    worksDelayPenalty: groupFieldRequiredRules('paymentTerms.worksDelayPenalty', VALIDATION_MESSAGES.requiredValue),
    remarksFixTermLimit: groupFieldRequiredRules('paymentTerms.remarksFixTermLimit', VALIDATION_MESSAGES.requiredValue),
  },
  tariffPlan: fieldRequiredRules(VALIDATION_MESSAGES.requiredTariffPlan),
  remark: fieldRequiredRules(VALIDATION_MESSAGES.remark),
  request: fieldRequiredRules(VALIDATION_MESSAGES.request),
  response: fieldRequiredRules(VALIDATION_MESSAGES.response),
  revisionMessage: fieldRequiredRules(VALIDATION_MESSAGES.revisionMessage),
  reasonExplanation: fieldRequiredRules(VALIDATION_MESSAGES.description),
  type: fieldRequiredRules(VALIDATION_MESSAGES.requiredContractFileType),
  message: fieldRequiredRules(VALIDATION_MESSAGES.requiredTaskMessage),
  contract: fieldRequiredRules(VALIDATION_MESSAGES.requiredContract),
  reassignmentUser: fieldRequiredRules(VALIDATION_MESSAGES.reassignmentUserIsRequired),
  withdrawalAmount: [
    ...fieldRequiredRules(VALIDATION_MESSAGES.valueIsRequired),
    {
      type: 'Invalid',
      func: ({ balance, withdrawalAmount }) => parseInt(withdrawalAmount, 10) > parseInt(balance, 10),
      message: VALIDATION_MESSAGES.balanceLessValue,
    },
  ],
  documentAddress: fieldRequiredRules(VALIDATION_MESSAGES.documentAddressIsRequired),
};

export const getErrors = (rules, fields, values, isRequiredOnlyOne) => {
  let requiredErrors = {};
  const otherErrors = {};
  let isAnyRequiredFilled = false;

  fields.forEach(field => {
    let isRequiredRule = false;
    const error = get(rules, field).find(({ type, func }) => {
      isRequiredRule = type === 'Required';
      return (isRequiredRule && !values[field] && !isAnyRequiredFilled) || (func && func(values));
    });

    isAnyRequiredFilled = isRequiredOnlyOne && (isAnyRequiredFilled || values[field]);

    if (error) {
      const errors = isRequiredRule ? requiredErrors : otherErrors;
      set(errors, field, error.message || error.type);
    }
  });

  if (isAnyRequiredFilled) {
    requiredErrors = {};
  }

  return { ...requiredErrors, ...otherErrors };
};

export const getValidation = (fields, requiredOnlyOneFields) => (values, props) => {
  const filteredFields = props.registeredFields && Object.keys(props.registeredFields).length > 0
    ? (fields || []).filter(field => props.registeredFields && props.registeredFields[field])
    : fields;
  return requiredOnlyOneFields
    ? {
      ...getErrors(RULES, filteredFields, values),
      ...getErrors(RULES, requiredOnlyOneFields, values, true),
    }
    : getErrors(RULES, filteredFields, values);
};
