export const makeCancellable = promise => {
  let hasCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    const cancelledResult = { isCancelled: true };
    promise.then(
      value => hasCancelled ? reject(cancelledResult) : resolve(value),
      error => hasCancelled ? reject(cancelledResult) : reject(error),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCancelled = true;
    },
  };
};
