import { formValueSelector, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MakeBidModal from './index';
import { getValidation } from '../../../utils/validation';
import { CONTRACT_DETAILS_TYPES } from '../../../utils/contractDetails';

const FORM_NAME = 'makeBidModal';
const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, { isSafeDeal, competitionTerms, contractDetailTypes }) => {
  let isTaxSystemError = false;
  let selectedContractDetailType = formSelector(state, 'contractDetailType');

  if (selectedContractDetailType && competitionTerms.taxSystems?.length > 0) {
    selectedContractDetailType = contractDetailTypes.filter(item => item.value === selectedContractDetailType)[0];
    isTaxSystemError = !competitionTerms.taxSystems.includes(selectedContractDetailType.taxSystemId);
  }

  return {
    currentBidAmount: parseFloat(formSelector(state, 'bidAmount') || 0),
    isTaxSystemError,
    initialValues: {
      commissionAmount: 0,
      payoutAmount: 0,
      isSafeDeal,
    },
    isSelfContractDetails: selectedContractDetailType === CONTRACT_DETAILS_TYPES.self.name,
    selectedContractDetailType,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
    validate: getValidation([
      'bidAmount',
      'contractDetailType',
    ]),
  }),
)(MakeBidModal);
