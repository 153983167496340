import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'redux-form';

import Modal from '../../base/modal';
import Button from '../../base/button';
import FormGroupField from '../../base/formGroup';

import './index.scss';
import { ICONS } from '../../../utils/icons';

export default class InviteToContractModal extends React.PureComponent {
  handleClose = () => {
    const { reset, onClose } = this.props;

    reset();
    onClose();
  };

  handleCancel = () => {
    this.handleClose();
    this.props.onRef.current.toggleModal();
  };

  onSubmit = async data => {
    await this.props.onClickInviteToContract(data);
    this.handleCancel();
  }

  render() {
    const {
      onRef,
      handleSubmit,
      pristine,
      submitting,
      objects,
      contracts,
      selectedObjectId,
    } = this.props;

    return <Modal
      onRef={onRef}
      className="invite-to-contract-modal"
      title="Предложить сотрудничество"
      onClose={this.handleClose}
    >
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="info">
          <ICONS.infoBold className="info-icon" />
          <p>Пригласить специалиста можно только в заранее созданную вакансию или ЛОТ</p>
        </div>
        <Field
          name="object"
          component={FormGroupField}
          type="select"
          label="Выбор объекта"
          placeholder="Открыть список объектов"
          data={objects}
        />
        <Field
          name="contract"
          component={FormGroupField}
          type="select"
          label="Выбор лота/вакансии"
          placeholder="Открыть список лотов/вакансий"
          data={contracts}
          isDisabled={!selectedObjectId}
        />
        <div className="actions">
          <Button
            onClick={this.handleCancel}
            isDisabled={submitting}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          <Button
            isLarge
            isDisabled={pristine}
            isLoading={submitting}
            buttonProps={{
              type: 'submit',
            }}
          >
            Пригласить
          </Button>
        </div>
      </Form>
    </Modal>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    onClickInviteToContract: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    objects: PropTypes.array,
    contracts: PropTypes.array,
    selectedObjectId: PropTypes.number,
    // redux-form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onRef: {
      current: null,
    },
    onClose() {},
    objects: [],
    contracts: [],
    selectedObjectId: null,
  };
}
