import { loader } from 'graphql.macro';
import { graphql } from '@apollo/react-hoc';
import { getRegions } from '../model/region';
import { getProjectComponents } from '../model/projectComponent';

const GET_OBJECT_REFERENCES_QUERY = loader('../../graphql/queries/object/getReferences.graphql');

export default graphql(GET_OBJECT_REFERENCES_QUERY, {
  name: 'getObjectReferences',
  skip: props => props.isSkip,
  props: ({ getObjectReferences: { objectTypes, regions } }) => ({
    objectTypes: getProjectComponents(objectTypes),
    regions: getRegions(regions),
  }),
});
