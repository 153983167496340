import { graphql } from '@apollo/react-hoc';
import { loader } from 'graphql.macro';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getObjects } from '../../../../graphql/model/object';
import InitialData from './index';
import { showError } from '../../../../api/error';
import { UI_DEFAULT_ERROR_MESSAGES } from '../../../../utils/messages';
import { OBJECT_PROJECT_COMPONENT_ORDER } from '../../../../graphql/model/projectComponent';
import { deepMerge } from '../../../../utils/merge';

const GET_OBJECT_QUERY = loader('../../../../graphql/queries/object/get.graphql');
const ON_OBJECT_UPDATE_SUBSCRIPTION = loader('../../../../graphql/queries/object/initialDataSubscription.graphql');

const withGetObjectQuery = graphql(GET_OBJECT_QUERY, {
  name: 'getObject',
  skip: ({ match: { params: { id } } }) => !id,
  options: ({ match: { params: { id } } }) => ({
    variables: {
      id: parseInt(id, 10),
      withFiles: true,
      projectComponentOrder: OBJECT_PROJECT_COMPONENT_ORDER,
    },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ getObject: { object = [], loading, error } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      queryObject: object,
      queryLoading: loading,
    };
  },
});

const withObjectSubscription = graphql(ON_OBJECT_UPDATE_SUBSCRIPTION, {
  name: 'objectInitialDataSub',
  skip: ({ match: { params: { id } } }) => !id,
  options: ({ match: { params: { id } } }) => ({
    variables: {
      id: parseInt(id, 10),
    },
  }),
  props: ({ objectInitialDataSub: { object = [], loading, error }, ownProps: { userId, queryObject, queryLoading } }) => {
    if (error) {
      showError(error, UI_DEFAULT_ERROR_MESSAGES.dataBase);
    }

    return {
      object: queryObject[0] && getObjects([deepMerge(queryObject[0], object[0])], userId)[0] || {},
      isLoading: queryLoading || loading,
    };
  },
});

const mapStateToProps = ({ session: { user: { id } } }) => ({
  userId: id,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  withGetObjectQuery,
  withObjectSubscription,
)(InitialData);
