import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

export default class DataList extends PureComponent {
  render() {
    const { data, label } = this.props;

    const itemsList = data.map((item, index) => <li key={index}>{item}</li>);

    return <div className={classNames('data-list', { labeled: !!label })}>
      {label && <label>{label}</label>}
      <ul>{itemsList}</ul>
    </div>;
  }
}

DataList.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
};

DataList.defaultProps = {
  data: [],
  label: '',
};
