import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import RemarksGrid from '../../../../../components/objects/object/stageRemarks/drafts';
import Button from '../../../../../components/base/button';
import Modal from '../../../../../components/base/modal';
import { ICONS } from '../../../../../utils/icons';

export default class Drafts extends PureComponent {
  title = 'Выставление замечаний';

  state = {
    inProgress: false,
    isSendToRegistryButtonEnabled: false,
  };

  remarksGridRef = React.createRef();

  handleCancel = () => {
    const { isModal, onRef } = this.props;

    this.remarksGridRef.current.initState();
    this.enableSendToRegistry(false);

    if (isModal) {
      onRef.current.toggleModal();
    }
  };

  handleAddRemarksToRegistry = async () => {
    const { projectComponent, isExpertise, currentPhaseId, onAddRemarks } = this.props;
    const remarks = this.remarksGridRef.current.getRemarks();

    if (remarks.length > 0) {
      this.setState({ inProgress: true });
      await onAddRemarks({ remarks, projectComponent, isExpertise, currentPhaseId }).finally(() => {
        this.setState({
          inProgress: false,
        });
      });
      this.handleCancel();
    }
  };

  enableSendToRegistry = isSendToRegistryButtonEnabled => {
    this.setState({ isSendToRegistryButtonEnabled });
  };

  renderSendToRegistryButton = (isLarge = false) => <Button
    className="btn-responses-registry"
    onClick={this.handleAddRemarksToRegistry}
    isLarge={isLarge}
    isLoading={this.state.inProgress}
    isDisabled={!this.state.isSendToRegistryButtonEnabled}
  >
    Отправить в реестр
  </Button>

  renderRemarksGrid = () => <RemarksGrid
    ref={this.remarksGridRef}
    projectComponent={this.props.projectComponent}
    isExpertise={this.props.isExpertise}
    enableSendToRegistry={this.enableSendToRegistry}
  />

  render() {
    const { projectComponent, onRef } = this.props;
    const { performers } = projectComponent;
    const hasActivePerformers = performers.some(performer => performer.isActive);

    return this.props.isModal
      ? <Modal
        className="drafts-modal"
        onRef={onRef}
        title={this.title}
      >
        {this.renderRemarksGrid()}
        <div className="action-buttons">
          <Button
            onClick={this.handleCancel}
            isBorderless
            isLarge
          >
            Отмена
          </Button>
          {this.renderSendToRegistryButton(true)}
        </div>
      </Modal>
      : <div className="drafts-container">
        <h5>{this.title}</h5>
        {hasActivePerformers
          ? <>
            {this.renderRemarksGrid()}
            {this.renderSendToRegistryButton()}
          </>
          : <div className="info">
            <ICONS.infoBold className="info-icon" />
            <p>Выставление замечаний возможно только при назначенном исполнителе</p>
          </div>}
      </div>;
  }

  static propTypes = {
    onRef: PropTypes.object,
    onAddRemarks: PropTypes.func.isRequired,
    projectComponent: PropTypes.object.isRequired,
    performers: PropTypes.array,
    isExpertise: PropTypes.bool.isRequired,
    isModal: PropTypes.bool,
    currentPhaseId: PropTypes.number,
  };

  static defaultProps = {
    performers: [],
    onRef: {
      current: null,
    },
    isModal: false,
    currentPhaseId: null,
  }
}
